import React from "react";
import { ButtonPrimary } from "@components";
import { makeStyles } from "@material-ui/core";

const CreateProfile: React.FC = () => {
  const classes = useStyles();

  return (
    <section className={classes.container}>
      <div className={classes.left}>
        Crea tu Perfil de Inversión para acceder a estos beneficios
      </div>
      <div className={classes.right}>
        <ButtonPrimary
          className={classes.button}
          onClick={() => {}}
          text="Crear Perfil de Inversión"
        />
      </div>
    </section>
  );
};

export default CreateProfile;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8.6rem",
    padding: "8.8rem 0",
  },
  left: {
    width: 510,
    fontSize: "2rem",
    fontFamily: theme.typography.fontFamily,
  },
  button: {
    minWidth: 244,
  },
}));
