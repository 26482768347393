import React, { useContext } from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { exitIcon, incentive, invest } from "images/onBoarding";
import { AuthContext } from "@context";
import { navigate } from "gatsby";
import { Route } from "@interfaces";
import HomeIcon from "@material-ui/icons/Home";

interface propsHandle {
  handleDropdown?: () => void;
  index?: boolean;
}

const PerfilDropdown = ({ handleDropdown, index }: propsHandle) => {
  const classes = useStyles({ index });
  const { logout } = useContext(AuthContext);

  const handleLogout = () => {
    handleDropdown && handleDropdown();
    logout();
  };

  return (
    <div className={classes.dropdown}>
      {index && handleDropdown && (
        <>
          <div
            className={classes.container}
            onClick={() => {
              navigate(Route.onBoard);
            }}
          >
            <img src={incentive} className={classes.button} />
            <span>Incentivos</span>
          </div>
          <div className={classes.linea}></div>
          <div
            className={classes.container}
            onClick={() => {
              navigate(Route.investments);
            }}
          >
            <img src={invest} className={classes.button} />
            <span>Inversiones</span>
          </div>
          <div className={classes.linea}></div>
        </>
      )}
      <div
        className={classes.container}
        onClick={() => {
          navigate(Route.profile);
        }}
      >
        <HomeIcon className={classes.button} />
        <span>Mi Perfil</span>
      </div>
      <div className={classes.linea}></div>
      <div className={classes.container} onClick={handleLogout}>
        <img src={exitIcon} className={classes.button} />
        <span>Cerrar sesión</span>
      </div>
    </div>
  );
};

export default PerfilDropdown;

const useStyles = makeStyles<Theme, { index?: boolean }>((theme) => ({
  dropdown: {
    top: 70,
    right: 52,
    position: "absolute",
    borderRadius: 15,
    boxShadow: "4px 4px 8px 2px rgba(0, 0, 0, 0.2)",
    background: "#fff",
    padding: "10px 15px 10px 15px",
    height: ({ index }) => (index ? 180 : 135),
    display: "grid",
    "& >div:hover": {
      transition: "1s",
      background: "rgba(0, 0, 0, 0.05)",
      borderRadius: 10,
    },
  },
  button: {
    borderRadius: "100%",
    width: 24,
  },
  container: {
    display: "flex",
    alignItems: "center",
    gap: 5,
    cursor: "pointer",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: 14,
    padding: 4,
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
  linea: {
    width: "100%",
    height: 2,
    backgroundColor: "#9D9D9D",
    margin: "5px 0 5px 0",
  },
}));
