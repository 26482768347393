import { ApolloClient, gql } from "@apollo/client";
import { FileSpecificType, SignedUrlResponse } from "@interfaces";

const GET_SIGNED_URL_QUERY = gql`
  query GetIdentityFileSignedUrl($data: IdentityFileUploadUrlParams!) {
    getIdentityFileSignedUrl(data: $data) {
      url
      fields
    }
  }
`;

type QueryParams = {
  data: {
    type: FileSpecificType;
    filename: string;
  };
};

type QueryResult = {
  getIdentityFileSignedUrl: SignedUrlResponse;
};

export async function getIdentityFileSignedUrl(
  type: FileSpecificType,
  filename: string,
  client: ApolloClient<object>,
): Promise<SignedUrlResponse | null> {
  const result = await client.mutate<QueryResult, QueryParams>({
    mutation: GET_SIGNED_URL_QUERY,
    variables: { data: { type, filename } },
  });
  return result.data?.getIdentityFileSignedUrl ?? null;
}
