// type

import {
  DatedValue,
  Fund,
  InvestmentStatus,
  InvestorProfileType,
  Manager,
  PortfolioAssetCategory,
  PortfolioLocationCategory,
  Serie,
} from "@interfaces";

type EntryInfo = {
  name: string;
  code: string;
  percentage: number;
};

export type SuggestedPortfolioInfo = {
  summary: {
    countryExposures: EntryInfo[];
    instrumentExposures: EntryInfo[];
  };
  investorProfile: string;
  expectedProfitability: number;
  portfolioName: string;
  funds: {
    fundId: number;
    percentage: number;
    countryExposures: EntryInfo[];
    instrumentExposures: EntryInfo[];
  }[];
};

export enum PortfolioType {
  SUGGESTED = "SUGGESTED",
}

export interface Portfolio {
  id: number;
  name: string;
  type: PortfolioType;
  status: InvestmentStatus;
  investmentFrequency?: string;
  period?: number;
  investorProfile: InvestorProfileType;
  totalInvested: number;
  totalDeposited: number;
  currentValue: number;
  profitability: number;
  pendingInvestments: number;
  pendingWithdrawals: number;
  investmentAsset: PortfolioAssetCategory;
  investmentLocation: PortfolioLocationCategory;
}

export type SuggestedPortfolioExtendedFunds = {
  agf: Manager;
  fund: Fund;
  series: Serie[];
  percentage: number;
  countryExposures: EntryInfo[];
  instrumentExposures: EntryInfo[];
};

export type SuggestedPortfolioExtendedInfo = Omit<
  SuggestedPortfolioInfo,
  "funds"
> & {
  funds: SuggestedPortfolioExtendedFunds[];
};

export interface PortfolioHistoricalData {
  id: number;
  data: DatedValue[];
}

export interface PortfolioCombination {
  investmentAsset: PortfolioAssetCategory | null;
  investmentLocation: PortfolioLocationCategory | null;
}
