import React, { useEffect, useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import { ApolloProvider } from "@apollo";
import { LocationProvider } from "@reach/router";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "@styles";
import "./styles/style.css";
import {
  LoadingProvider,
  ProfileProvider,
  AppProvider,
  GoalsProvider,
  FiltersProvider,
  InvestmentsProvider,
  SnackbarProvider,
  NewInvestProvider,
  AuthProvider,
  RegisterProvider,
  SaveByRokinProvider,
  TokenProvider,
  MissionsProvider,
} from "@context";
import useHotjar from "react-use-hotjar";

const myCustomLogger = console.info;

export const App: React.FC = ({ children }) => {
  const { initHotjar } = useHotjar();

  useEffect(() => {
    initHotjar(Number(process.env.HOTJAR_ID) || 0, 6, false, myCustomLogger);
  }, [initHotjar]);

  return (
    <ApolloProvider>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <LocationProvider>
            <SnackbarProvider>
              <LoadingProvider>
                <AuthProvider>
                  <FiltersProvider>
                    <ProfileProvider>
                      {" "}
                      <MissionsProvider>
                        <AppProvider>
                          <InvestmentsProvider>
                            <GoalsProvider>
                              <NewInvestProvider>
                                <RegisterProvider>
                                  <SaveByRokinProvider>
                                    <TokenProvider>{children}</TokenProvider>
                                  </SaveByRokinProvider>
                                </RegisterProvider>
                              </NewInvestProvider>
                            </GoalsProvider>
                          </InvestmentsProvider>
                        </AppProvider>
                      </MissionsProvider>
                    </ProfileProvider>
                  </FiltersProvider>
                </AuthProvider>
              </LoadingProvider>
            </SnackbarProvider>
          </LocationProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};
