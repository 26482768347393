import { gql, useQuery } from '@apollo/client';
import { FinerioSubcategory } from '@interfaces';

export const GET_FINERIO_SUBCATEGORIES = gql`
  query FinerioTransactions {
    getFinerioSubcategories {
      FinerioCategory {
        id
        color
        iconColor
        iconWhite
        name
      }
      color
      description
      iconBlack
      iconColor
      id
      subcategoryId
      visible
    }
  }
`;

interface QueryResult {
  getFinerioSubcategories: FinerioSubcategory[];
}

interface HookReturn {
  finerioSubcategories: FinerioSubcategory[];
  finerioSubcategoriesLoading: boolean;
  refetchFinerioSubcategopries: () => void;
}

export const getFinerioSubcategories = (): HookReturn => {
  const { data, loading, refetch } = useQuery<QueryResult>(
    GET_FINERIO_SUBCATEGORIES
  );
  return {
    finerioSubcategories: data?.getFinerioSubcategories ?? [],
    finerioSubcategoriesLoading: loading,
    refetchFinerioSubcategopries: refetch,
  };
};
