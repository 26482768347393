import {
  budgetIlustration,
  profileIlustration,
  saving,
  creditAndDebt as cad,
  insuranceIlustration,
} from "@images";
import {
  BUDGET_ICON_WHITE,
  PROFILE_ICON_WHITE,
  RETIREMENT_ICON_WHITE,
  CREDITS_AND_DEBTS_ICON_WHITE,
  SAVINGS_AND_INVESTMENTS_ICON_WHITE,
} from "images/AWS/rokipedia/index";
import { Route } from "@interfaces";

export const MissionsInfo = {
  ProfileCategory: {
    name: "Mi Perfil",
    title: "Completa tu Perfil",
    text: "¡Gana Roks al completar tu perfil! Incluyendo tus datos tendrás una experiencia más completa y podrás invertir con nosotros.",
    categoryRoute: Route.completeProfile,
    smallImage: PROFILE_ICON_WHITE,
    largeImage: PROFILE_ICON_WHITE,
    m1: {
      id: 1,
      route: Route.profileMission1,
    },
    m2: {
      id: 2,
      route: Route.profileMission2,
    },
    m3: {
      id: 3,
      route: Route.profileMission4,
    },
    m4: {
      id: 4,
      route: Route.profileMission3,
    },
    m5: {
      id: 5,
      route: Route.profileMission5,
    },
    m6: {
      id: 6,
      route: Route.profileMission6,
    },
  },
  BudgetCategory: {
    name: "Presupuesto",
    title: "Presupuesto",
    text: "Podrás aprender cómo hacer un presupuesto, los beneficios de hacerlo y acceder a herramientas para lograrlo.",
    categoryRoute: Route.budget,
    smallImage: BUDGET_ICON_WHITE,
    largeImage: BUDGET_ICON_WHITE,
    m1: {
      id: 7,
      route: Route.budgetMission1,
    },
    m2: {
      id: 8,
      route: Route.budgetMission2,
    },
    m3: {
      id: 9,
      route: Route.budgetMission3,
    },
    m4: {
      id: 10,
      route: Route.budgetMission4,
    },
    m5: {
      id: 11,
      route: Route.budgetMission5,
    },
    m6: {
      id: 12,
      route: Route.budgetMission5,
    },
  },
  SavingsInvestmentCategory: {
    name: "Ahorro e inversión",
    title: "Ahorro e Inversión",
    text: "Encontrarás herramientas e información que te permitirán entender y manejar tu ahorro e inversión de la mejor forma.",
    categoryRoute: Route.savingsAndInvestment,
    smallImage: SAVINGS_AND_INVESTMENTS_ICON_WHITE,
    largeImage: SAVINGS_AND_INVESTMENTS_ICON_WHITE,
    m1: {
      id: 13,
      route: Route.savingsAndInvestment1A,
    },
    m2: {
      id: 14,
      route: Route.savingsAndInvestment3A,
    },
    m1_2: {
      id: 15,
      route: Route.savingsAndInvestment1B,
    },
    m2_2: {
      id: 16,
      route: Route.budgetMission4,
    },
    m3_2: {
      id: 17,
      route: Route.savingsAndInvestment3B,
    },
    m4_2: {
      id: 18,
      route: Route.budgetMission4,
    },
  },
  LoansDebtCategory: {
    name: "Créditos y deudas",
    title: "Creditos y deudas",
    text: "Aprenderás a manejar tus créditos y deudas de la mejor forma y a aprovechar al máximo tus productos bancarios.",
    categoryRoute: Route.creditsDebts,
    smallImage: CREDITS_AND_DEBTS_ICON_WHITE,
    largeImage: CREDITS_AND_DEBTS_ICON_WHITE,
    m1: {
      id: 19,
      route: Route.loansAndDebtsMissionA1,
    },
    m2: {
      id: 20,
      route: Route.loansAndDebtsMissionA2,
    },
    m3: {
      id: 21,
      route: Route.loansAndDebtsMission3,
    },
    m4: {
      id: 22,
      route: Route.loansAndDebtsMission4A,
    },
    m5: {
      id: 23,
      route: Route.loansAndDebtsMissionA5,
    },
    m1_2: {
      id: 24,
      route: Route.loansAndDebtsMissionB1,
    },
    m2_2: {
      id: 25,
      route: Route.loansAndDebtsMissionB2,
    },
    m3_2: {
      id: 26,
      route: Route.budgetMission4,
    },
    m4_2: {
      id: 27,
      route: Route.loansAndDebtsMissionB4,
    },
  },
  RetirementCategory: {
    name: "Jubilación",
    title: "Jubilación",
    text: "Tu futuro es importante: aprenderás a manejar tus ahorros para la jubilación de la mejor forma y a elegir el método que más te acomode para hacerlo.",
    categoryRoute: Route.retirement,
    smallImage: RETIREMENT_ICON_WHITE,
    largeImage: RETIREMENT_ICON_WHITE,
    m1: {
      id: 28,
      route: Route.budgetMission1,
    },
    m2: {
      id: 29,
      route: Route.budgetMission2,
    },
    m3: {
      id: 30,
      route: Route.budgetMission3,
    },
  },
};
