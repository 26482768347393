import React from "react";
import NumberFormat from "react-number-format";
import { OnChangeType } from "@interfaces";
import { SizedTextField } from ".";
import { parseNumber } from "@utils";

interface NumericInputProps {
  className?: string;
  name: string;
  label?: string;
  value?: number;
  onChange?: OnChangeType;
  onBlur?: OnChangeType;
  disabled?: boolean;
  placeholder?: string | undefined;
  max?: number | null;
  error?: boolean;
  helperText?: string;
  centered?: boolean;
  showHelperText?: boolean;
}
export const NumericInput: React.FC<NumericInputProps> = ({
  className,
  name,
  label,
  value,
  onChange,
  onBlur,
  disabled,
  placeholder,
  max,
  error,
  helperText,
  centered,
  showHelperText,
}) => {
  const someNumericValue = value !== undefined && value !== null && value > 0;
  const isMaxDefined = max !== undefined && max !== null;
  const shouldCheckMax = isMaxDefined && someNumericValue;
  const exceedMax = shouldCheckMax && value > max;
  return (
    <SizedTextField
      className={className || ""}
      name={name}
      label={label}
      value={value}
      error={error || exceedMax}
      helperText={
        showHelperText
          ? helperText
          : error
          ? helperText
          : exceedMax
          ? `Valor por sobre el máximo de ${parseNumber(max)}`
          : ""
      }
      onBlur={onBlur}
      onChange={onChange}
      variant="outlined"
      InputProps={{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        inputComponent: NumberFormatCustom as any,
      }}
      inputProps={{
        style: { textAlign: centered ? "center" : "start" },
      }}
      disabled={disabled}
      placeholder={placeholder}
    />
  );
};

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: {
    target: { name: string; value: string | number };
  }) => void;
  name: string;
}
function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: Number(values.value),
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      allowNegative={false}
      allowEmptyFormatting={true}
      prefix="$"
    />
  );
}
