import { ApolloClient, gql } from "@apollo/client";

const DELETE_EVENT = gql`
mutation DeleteEvent($deleteEventId: ID!) {
    deleteEvent(id: $deleteEventId)
  }
`;

type DeleteEventParams = {
    'deleteEventId': any
};

export async function deleteEventMutation(client: ApolloClient<object>, deleteEventId: any): Promise<boolean> {
    const result = await client.mutate<boolean, DeleteEventParams>({
        mutation: DELETE_EVENT,
        variables: { deleteEventId: deleteEventId},
        awaitRefetchQueries: true,
    });
    return !!result.data;
}
