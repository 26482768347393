import { ApolloClient, gql } from "@apollo/client";
import { GET_CURRENT_USER_QUERY } from "..";

const CREATE_VECTOR_ACC_MUT = gql`
  mutation CreateVectorAccount {
    createVectorAccount
  }
`;

type CreateUserResult = {
  createVectorAccount: boolean;
};

export async function createVectorAccount(
  refetchMe = true,
  client: ApolloClient<object>,
): Promise<boolean> {
  const result = await client.mutate<CreateUserResult>({
    mutation: CREATE_VECTOR_ACC_MUT,
    refetchQueries: refetchMe
      ? [
          {
            query: GET_CURRENT_USER_QUERY,
          },
        ]
      : undefined,
  });
  return !!result.data?.createVectorAccount;
}
