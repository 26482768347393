import { gql, useQuery } from "@apollo/client";
import { FinerioCredential } from "@interfaces";

export const GET_FINERIO_CREDENTIAL = gql`
  query FinerioCredentialByBank($bankId: Int!) {
    finerioCredentialByBank(bankId: $bankId) {
      id
      bankId
      finerioCredentialId
      enabled
    }
  }
`;

interface QueryResult {
  finerioCredentialByBank: FinerioCredential;
}

interface HookReturn {
    finerioCredential: FinerioCredential;
    finerioCredentialLoading: boolean;
    refetchFinerioCredential: () => void;
}

export const getFinerioCredentialByBank = (bankId: number): HookReturn => {

  const { data, loading, refetch } = useQuery<QueryResult>(GET_FINERIO_CREDENTIAL, {
    variables: { bankId }
  });

  return {
      finerioCredential: data?.finerioCredentialByBank ?? {},
      finerioCredentialLoading: loading,
      refetchFinerioCredential: refetch
  };
};