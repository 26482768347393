import React, { createContext, useMemo, useCallback } from "react";
import { getToken } from "@apollo";
interface TokenContextType {
  PostRegistro: () => void;
}

export const TokenContext = createContext({} as TokenContextType);

export const TokenProvider: React.FC = ({ children }) => {
  const { custToken: custToken, loading: loadingToken } = getToken();
  const PostRegistro = useCallback(async () => {
    custToken && localStorage.setItem("tookenSAVE", custToken);
  }, []);

  const context = useMemo<TokenContextType>(
    () => ({
      PostRegistro,
    }),
    [PostRegistro],
  );

  return (
    <TokenContext.Provider value={context}>{children}</TokenContext.Provider>
  );
};
