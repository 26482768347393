import React, { ChangeEvent, useState } from "react";
import {
  FormControlLabel,
  Checkbox,
  Switch,
  makeStyles,
} from "@material-ui/core";
import { Filter, FilterProps } from ".";
import { ActiveFilters, SingleFilterOptions } from "@interfaces";
import { ButtonText } from "@components/Reusables";

export interface GenericOptionsProps extends FilterProps {
  className?: string;
  selectAllText: string;
  filters: ActiveFilters;
  property: "currency" | "term" | "manager" | "fundType" | "category";
  options: SingleFilterOptions[];
  handleChange: (event: ChangeEvent) => void;
}
const GenericOptionsFilter: React.FC<GenericOptionsProps> = ({
  className,
  selectAllText,
  filters,
  property,
  options,
  handleChange,
  ...props
}) => {
  const classes = useStyles();
  const twoStates = typeof options[0]?.showingFirst !== "undefined";
  const [expanded, setExpanded] = useState(false);
  const allChecked =
    Boolean(options.length) &&
    options.every(opt => (filters[property] as string[])?.includes(opt.value));
  return (
    <Filter
      className={`${className || ""} ${classes.genericFilter}`}
      {...props}
    >
      <div className={classes.switch}>
        <span className={classes.optionText}>{selectAllText}</span>
        <Switch
          name={`switch-${property}`}
          checked={allChecked}
          onChange={handleChange}
          color="primary"
          inputProps={{
            "aria-label": `seleccionar ${selectAllText}`,
          }}
        />
      </div>
      {options
        .filter(opt => !twoStates || opt.showingFirst || expanded)
        .map(opt => (
          <div className={classes.option} key={opt.value}>
            <FormControlLabel
              className={classes.formControlLabel}
              control={
                <Checkbox
                  className={classes.checkbox}
                  name={`checkbox-${property}-${opt.value}`}
                  checked={(filters[property] as string[])?.includes(opt.value)}
                  color="primary"
                  onChange={handleChange}
                />
              }
              label={opt.name ?? opt.value}
            />
            {/* <span className="option-text">{opt.count}</span> */}
          </div>
        ))}
      {twoStates && (
        <ButtonText
          className={classes.expandToggler}
          text={expanded ? "Ver menos" : "Ver más"}
          onClick={() => setExpanded(prev => !prev)}
        />
      )}
    </Filter>
  );
};

const useStyles = makeStyles(() => ({
  genericFilter: {
    width: "100%",
  },
  switch: {
    padding: "0 18px 0 28px",
    justifyContent: "space-between",
    display: "flex",
    width: "100%",
    height: 42,
    alignItems: "center",
  },
  optionText: {
    fontSize: 14,
    letterSpacing: 0.15,
    color: "#414046",
  },
  option: {
    padding: "0 25px 0 20px",
    height: 42,
    display: "flex",
    alignItems: "center",
  },
  formControlLabel: {
    margin: 0,
    flex: "1 1 auto",
    "& .MuiFormControlLabel-label": {
      fontSize: 14,
      letterSpacing: 0.15,
      color: "#414046",
    },
  },
  checkbox: {
    width: 42,
    height: 42,
    marginRight: 16,
  },
  expandToggler: {
    margin: "5px auto",
    maxWidth: 115,
    padding: "3px 15px",
  },
}));

export { GenericOptionsFilter };
