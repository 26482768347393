import { Route } from '@interfaces';
import {
  BUDGET_ICON,
  BUDGET_ICON_COLOR,
  SEARCH_ICON,
  SEARCH_ICON_COLOR,
  RETIREMENT_ICON,
  UMBRELLA_ICON_COLOR,
  DEBT_ICON,
  PAYMENT_ICON_COLOR,
  INVEST_ICON,
  PROFITS_ICON_COLOR,
  SAVING_ICON,
  SAFEBOX_ICON_COLOR,
  BILL_ICON_COLOR,
  BILL_ICON_PRODUCTTOUR,
  GOAL_ICON,
  TREND_UP_ICON_BLUE,
  SEARCHMONEY_ICON_BLUE,
  SAFEBOX_ICON_BLUE,
  PIECHART_ICON_BLUE,
  INVOICEDOLLAR_ICON_BLUE,
  GOAL_ICON_BLUE,
  CREDITCARDS_ICON_BLUE,
  BILL_ICON_BLUE,
  TREND_UP_ICON_GREY,
  SEARCHMONEY_ICON_GREY,
  SAFEBOX_ICON_GREY,
  PIECHART_ICON_GREY,
  INVOICEDOLLAR_ICON_GREY,
  GOAL_ICON_GREY,
  CREDITCARDS_ICON_GREY,
  BILL_ICON_GREY,
} from 'images/AWS/productTour';
import { PIECHART_ICON } from 'images/GoogleCloud';
import {
  BANK_ICON,
  CALENDAR_ICON,
  PIGGY_ICON,
  SERVICE_ICON,
} from 'images/myFinances';

interface isSelectType {
  card: boolean;
  card1: boolean;
  card2: boolean;
  card3: boolean;
  card4: boolean;
  card5: boolean;
  card6: boolean;
  card7: boolean;
}

export const preferencesList = (isSelect: isSelectType) => [
  {
    id: 0,
    title: 'Rokin Plus',
    subtitle: 'Recordatorios de tus pagos. asistencia virtual y servicios.',
    img: BANK_ICON,
    imgSelect: BANK_ICON,
    select: isSelect ? isSelect.card : false,
    color: 'firstColor',
    navigate: Route.services + '?#rokinPlus',
  },
  {
    id: 1,
    title: 'Dudas OFF',
    subtitle: 'Ordenar tus deudas y organizarte',
    img: TREND_UP_ICON_GREY,
    imgSelect: TREND_UP_ICON_GREY,
    select: isSelect ? isSelect.card1 : false,
    color: 'secondColor',
    navigate: Route.services + '?#deudasOff',
  },
  {
    id: 2,
    title: 'Aumentar mi ahorro',
    subtitle: 'Objetivos, inversiones y más',
    img: PIGGY_ICON,
    imgSelect: PIECHART_ICON_BLUE,
    select: isSelect ? isSelect.card2 : false,
    color: 'thirdColor',
    navigate: Route.investments,
  },
  {
    id: 3,
    title: 'Buscar servicios',
    subtitle: 'Encontrar soporte y acompañamiento para ordenar mis finanzas',
    img: SERVICE_ICON,
    imgSelect: CREDITCARDS_ICON_BLUE,
    select: isSelect ? isSelect.card3 : false,
    color: 'fourthColor',
    navigate: Route.services,
  },
  {
    id: 4,
    title: 'Consultoría financiera',
    subtitle: 'Un asistente personal te ayudará con lo que necesites',
    img: CALENDAR_ICON,
    imgSelect: TREND_UP_ICON_BLUE,
    select: isSelect ? isSelect.card4 : false,
    color: 'firstColor',
    navigate: Route.services + '?#openServices',
  },
  {
    id: 5,
    title: 'Ordenar mis cuentas',
    subtitle: 'Arma tu presupuesto detallado',
    img: PIECHART_ICON,
    imgSelect: SAFEBOX_ICON_BLUE,
    select: isSelect ? isSelect.card5 : false,
    color: 'secondColor',
    navigate: '/rokipedia/category/budget/',
  },
];
