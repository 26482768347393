import { gql, useQuery } from "@apollo/client";
import { useAuth } from "@hooks";

const GET_CURRENT_CASH_BALANCE = gql`
  query GetUserCashBalance {
    getCurrentCashBalance
  }
`;

interface QueryResult {
  getCurrentCashBalance: number;
}

interface HookReturn {
  currentCashBalance: number;
  loading: boolean;
}

export const useCurrentCashBalance = (): HookReturn => {
  const { isLoggedIn } = useAuth();
  const { data, loading } = useQuery<QueryResult>(GET_CURRENT_CASH_BALANCE, {
    fetchPolicy: "network-only",
    skip: !isLoggedIn,
  });
  return {
    currentCashBalance: isLoggedIn ? data?.getCurrentCashBalance ?? 0 : 0,
    loading,
  };
};
