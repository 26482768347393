import { gql, useQuery } from "@apollo/client";
import { MovementsSummary } from "@interfaces";

export const GET_MOVEMENTS_SUMMARY_QUERY = gql`
  query GetMovementsSummary {
    getMovementsSummary {
      id
      userRut
      type
      status
      createdAt
    }
  }
`;

interface QueryResult {
  getMovementsSummary: MovementsSummary[];
}

interface HookReturn {
  movementsSummary: MovementsSummary[];
  loading: boolean;
}

export const getMovementsSummary = (): HookReturn => {
  const { data, loading } = useQuery<QueryResult>(GET_MOVEMENTS_SUMMARY_QUERY);
  return { movementsSummary: data?.getMovementsSummary ?? [], loading };
};
