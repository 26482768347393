import { gql, useQuery } from "@apollo/client";
import { FundWithSeries } from "@interfaces";
import { AgfFragment, FundFragment, SerieFragment } from "apollo/fragments";

interface QueryResult {
  fundsExtended: FundWithSeries[];
}

interface HookReturn {
  fundsWithSeries: FundWithSeries[];
  loading: boolean;
}

// TO-DO: divide this heavy query into minimum data (for cards array displaying)
// and full data (for details page of 1 fund)
export const useFundsWithSeries = (): HookReturn => {
  const { data, loading } = useQuery<QueryResult>(
    gql`
      query GetFundsExtended {
        fundsExtended {
          id
          agf {
            ${AgfFragment}
          }
          fund {
            ${FundFragment}
          }
          series {
            ${SerieFragment}
          }
        }
      }
    `,
  );
  return { fundsWithSeries: data?.fundsExtended ?? [], loading };
};
