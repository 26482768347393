const ROKS_ICON_PINK =
  "https://storage.googleapis.com/rokinapp-images/rokipedia/Roks%20(1).svg";
const LEVEL1 = "https://storage.googleapis.com/rokinapp-images/rokipedia/Levels/Badge%20nivel%201.svg";
const LEVEL2 = "https://storage.googleapis.com/rokinapp-images/rokipedia/Levels/Badge%20nivel%202.svg";
const LEVEL3 = "https://storage.googleapis.com/rokinapp-images/rokipedia/Levels/Badge%20nivel%203.svg";
const LEVEL4 = "https://storage.googleapis.com/rokinapp-images/rokipedia/Levels/Badge%20nivel%204.svg";
const LEVEL5 = "https://storage.googleapis.com/rokinapp-images/rokipedia/Levels/Badge%20nivel%205.svg";

export { ROKS_ICON_PINK, LEVEL1, LEVEL2, LEVEL3, LEVEL4, LEVEL5 };

