import React from "react";
import { Select } from "@components";
import { CommonSelectorProps, EXPERIENCE_LEVELS } from "..";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends CommonSelectorProps {}

export const InvestmentExperienceSelector: React.FC<Props> = ({
  className,
  name,
  label,
  ...props
}) => {
  return (
    <Select
      className={`${className || ""} altercap-investmentExperience-selector`}
      name={name || "investmentExperience"}
      label={label || "Mi experiencia invirtiendo es"}
      options={EXPERIENCE_LEVELS}
      {...props}
    />
  );
};
