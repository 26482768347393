const lowercaseRegex = /^(?=.*[a-z])/;
const uppercaseRegex = /^(?=.*[A-Z])/;
const numberRegex = /^(?=.*[0-9])/;

export interface PasswordValidationsType {
  oneLowercase: boolean;
  oneUppercase: boolean;
  oneNumber: boolean;
  length: boolean;
  noSpaces: boolean;
}

export const checkPasswordStrength = (
  password: string,
): PasswordValidationsType => {
  const validations = {
    oneLowercase: lowercaseRegex.test(password),
    oneUppercase: uppercaseRegex.test(password),
    oneNumber: numberRegex.test(password),
    length: password.length >= 8,
    noSpaces: !password.includes(" "),
  };
  return validations;
};
