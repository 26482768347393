// import { gql, useQuery } from "@apollo/client";
// import { DatedValue } from "@interfaces";
//
// const GET_HISTORICAL_PROFIT_BY_GOAL_VALUE = gql`
//   query GetHistoricalProfitByGoal($goalId: Int!) {
//     getHistoricalProfitByGoal(goalId: $goalId) {
//       date
//       value
//     }
//   }
// `;
//
// type ProfitParams = {
//     goalId: number;
// };
//
// interface QueryResult {
//   getHistoricalProfitByGoal: DatedValue | null;
// }
//
// interface HookReturn {
//   data: DatedValue | null;
//   loading: boolean;
// }
//
// export const useHistoricalProfitByGoal = (goalId: number): HookReturn => {
//   const { data, loading } = useQuery<QueryResult, ProfitParams>({
//     query: GET_HISTORICAL_PROFIT_BY_GOAL_VALUE,
//     variables: { goalId },
//   });
//   return { historicalProfitByGoal: data?.getHistoricalProfitByGoal ?? [], loading };
// };
//
// ///////////////

import { gql, useQuery } from "@apollo/client";
import { DatedValue } from "@interfaces";

const GET_HISTORICAL_PROFIT_BY_GOAL_VALUE = gql`
  query GetHistoricalProfitByGoal($goalId: Int!) {
    getHistoricalProfitByGoal(goalId: $goalId) {
      date
      value
    }
  }
`;

interface QueryParams {
  goalId?: number;
}

interface QueryResult {
  getHistoricalProfitByGoal: DatedValue | null;
}

interface HookReturn {
  data: DatedValue | null;
  loading: boolean;
}

export const useHistoricalProfitByGoal = (goalId?: any): HookReturn => {
  const { data, loading } = useQuery<QueryResult, QueryParams>(
    GET_HISTORICAL_PROFIT_BY_GOAL_VALUE,
    { variables: { goalId }, skip: !goalId },
  );
  return { historicalProfitByGoal: data?.getHistoricalProfitByGoal ?? null, loading };
};
