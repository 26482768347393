import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import { ButtonText, ButtonPrimary } from '@components';
import { InvestorProfileTypes, Route } from '@interfaces';
import ButtonBase from '@material-ui/core/ButtonBase';
import { ilustracionPerfilInversion } from '@images';
import { DiscoverProfile } from './Discover';

interface ChooseInvestorProfileProps {
  form: any;
  buttonText: string;
  handleClick: () => void;
  isDiscoverOn: () => void;
  isNotMission?: boolean;
}

export const ChooseInvestorProfile: React.FC<ChooseInvestorProfileProps> = ({
  form,
  buttonText,
  handleClick,
  isDiscoverOn,
  isNotMission,
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(min-width: 1000px)');
  const [showDiscoverProfile, setShowDiscoverProfile] =
    useState<boolean>(false);
  const [investorProfile, setInvestorProfile] = useState<string>(
    form.investorProfile ? form.investorProfile : ''
  );
  const [isDisabled, setIsDisabled] = useState<boolen>(false);

  useEffect(() => {
    form.investorProfile = investorProfile;

    if (!investorProfile) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [investorProfile]);

  const ifSelectedClass = (profile) => {
    if (investorProfile == profile) {
      return `${classes.buttonSelected} ${
        isNotMission && classes.selectedNotMision
      }`;
    } else {
      return classes.button;
    }
  };

  return !showDiscoverProfile ? (
    <>
      <section
        className={`${classes.container} ${
          isNotMission && classes.notMissionContainer
        }`}
      >
        {isNotMission ? (
          <div
            className={`${classes.form} ${isNotMission && classes.notMission}`}
          >
            <div>
              <div className={classes.botonesNotMission}>
                {InvestorProfileTypes.map((profile) => {
                  return (
                    <ButtonBase
                      key={profile}
                      className={ifSelectedClass(profile)}
                      value={investorProfile}
                      onClick={() => setInvestorProfile(profile)}
                    >
                      <span
                        className={`${classes.buttonText} ${classes.title}`}
                      >
                        {profile}
                      </span>
                      <span className={`${classes.buttonText} ${classes.text}`}>
                        {description[profile]}
                      </span>
                    </ButtonBase>
                  );
                })}
              </div>
              <div
                className={`${classes.descubrir} ${
                  isNotMission && classes.notMissionDescubrir
                }`}
              >
                ¿No sabes cual es tu perfil?
                <ButtonText
                  text="Descubrir tu perfil"
                  onClick={() => [setShowDiscoverProfile(true), isDiscoverOn()]}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.form}>
            <div className={classes.inputs}>
              <div>
                <div className={classes.botones}>
                  {InvestorProfileTypes.map((profile) => {
                    return (
                      <ButtonBase
                        key={profile}
                        className={ifSelectedClass(profile)}
                        value={investorProfile}
                        onClick={() => setInvestorProfile(profile)}
                      >
                        <span
                          className={`${classes.buttonText} ${classes.title}`}
                        >
                          {profile}
                        </span>
                        <span
                          className={`${classes.buttonText} ${classes.text}`}
                        >
                          {description[profile]}
                        </span>
                      </ButtonBase>
                    );
                  })}
                  {isMobile && (
                    <div className={classes.txtChange}>
                      Esto lo puedes cambiar más adelante
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div>
              {isMobile && (
                <img
                  src={ilustracionPerfilInversion}
                  className={classes.imagen}
                />
              )}
              <div className={classes.info}>
                {!isMobile ? (
                  <div className={classes.down}>
                    <ButtonPrimary
                      onClick={handleClick}
                      className={classes.boton}
                      text={buttonText}
                      disabled={isDisabled}
                    />
                    <div
                      className={classes.cancel}
                      onClick={() => navigate(Route.investments)}
                    >
                      Cancelar
                    </div>
                  </div>
                ) : (
                  <ButtonPrimary
                    onClick={handleClick}
                    className={classes.boton}
                    text={buttonText}
                    disabled={isDisabled}
                    secondaryVersion
                  />
                )}

                <div className={classes.descubrir}>
                  ¿No sabes cual es tu perfil?
                  <ButtonText
                    text="Descubrir tu perfil"
                    onClick={() => [
                      setShowDiscoverProfile(true),
                      isDiscoverOn(),
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  ) : (
    <>
      <section
        className={`${classes.container} ${
          isNotMission && classes.notMissionContainer
        }`}
      >
        <DiscoverProfile
          form={form}
          setShowDiscoverProfile={setShowDiscoverProfile}
          setInvestorProfile={setInvestorProfile}
          isNotMission={isNotMission}
        />
      </section>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    height: 529,
    [theme.breakpoints.down(1000)]: {
      height: 'auto',
    },
  },
  notMissionContainer: {
    height: 'auto',
  },
  arrow: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  arrowIcon: {
    width: '4.2rem',
    height: '4.2rem',
    color: '#008296',
    marginRight: 90,
  },
  form: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    width: 1000,
    [theme.breakpoints.down(1000)]: {
      width: 'auto',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: '2rem',
      gap: 23.9,
      justifyContent: 'center',
    },
  },
  inputs: {
    [theme.breakpoints.down(1000)]: {
      width: 'auto',
      display: 'flex',
      padding: '1.5rem 1.5625rem 1.5rem 1.5625rem',
      borderRadius: '20px',
      margin: '0 1rem 0 1rem',
      background: '#FFFFFF',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: 23.9,
      justifyContent: 'center',
    },
  },
  button: {
    border: '1px solid grey',
    borderRadius: '8px',
    margin: '11px 0',
    width: '428px',
    display: 'flex',
    flexDirection: 'column',
    padding: '7.5px 31px',
    textAlign: 'left',
    color: '#4a5c60',
    [theme.breakpoints.down(1000)]: {
      width: '100%',
      padding: '6px 14px',
      height: '60px',
    },
  },
  buttonSelected: {
    border: '1px solid grey',
    borderRadius: '8px',
    margin: '11px 0',
    width: '428px',
    display: 'flex',
    flexDirection: 'column',
    padding: '7.5px 31px',
    textAlign: 'left',
    color: '#fff',
    background:
      'linear-gradient(161deg, rgb(130, 80, 255) -6%, rgb(24, 19, 49) 117%)',
    [theme.breakpoints.down(1000)]: {
      border: 'none',
      width: '100%',
      padding: '6px 14px',
      height: '60px',
      background: '#008195',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
  },
  selectedNotMision: {
    background:
      'linear-gradient(161deg, rgb(0, 170, 188) 30%, rgb(5, 130, 149) 63%, rgb(10, 86, 105) 88%)',
  },
  buttonText: {
    width: '100%',
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    [theme.breakpoints.down(1000)]: {
      fontSize: '16px',
    },
  },
  text: {
    fontSize: 13,
    [theme.breakpoints.down(1000)]: {
      fontSize: 11,
    },
  },
  title1: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '2.25rem',
    fontWeight: 'bold',
    color: '#393e44',
    '& span': {
      fontFamily: 'Montserrat',
      fontSize: '1.25rem',
      fontWeight: 'normal',
      display: 'block',
      color: '#7d7c7c',
      width: 451,
    },
  },
  botones: {
    marginTop: 30,
    [theme.breakpoints.down(1000)]: {
      marginTop: 0,
    },
  },
  botonesNotMission: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: '60rem',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      alignItems: 'center',
    },

    '& button': {
      width: '18rem !important',
      margin: '1rem !important',
    },
  },
  info: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: 0,
    gap: '4rem',
    [theme.breakpoints.down(1000)]: {
      gap: '0rem',
      flexDirection: 'column-reverse',
    },
  },
  down: {
    display: 'grid',
    justifyContent: 'center',
    gap: '1rem',
  },
  boton: {
    width: 184,
    [theme.breakpoints.down(1000)]: {
      width: '18rem',
      height: 52,
      marginTop: 29,
      marginLeft: 0,
    },
  },
  cancel: {
    display: 'grid',
    justifyContent: 'center',
    fontSize: '0.875rem',
  },
  notMissionDescubrir: {
    marginLeft: '1rem !important',
  },
  descubrir: {
    fontFamily: theme.typography.fontFamily,
    paddingTop: 15,
    fontSize: '1rem',
    fontWeight: 'normal',
    width: 217,
    textAlign: 'left',
    color: theme.palette.text.primary,
    '& button': {
      fontSize: '1.25rem',
      fontWeight: 'bold',
      display: 'block',
    },
    [theme.breakpoints.down(1000)]: {
      textAling: 'center',
      color: '#646464',
      gap: '0.5rem',
      width: 'auto',
      '& button': {
        marginTop: '0.5rem',
      },
    },
  },
  imagen: {
    width: '24rem',
    height: '24rem',
    margin: '0 auto',
  },
  txtChange: {
    fontFamily: 'Montserrat',
    fontSize: 20,
    color: theme.palette.text.secondary,
    paddingTop: 20,
    [theme.breakpoints.down(1000)]: {
      textAlign: 'center',
    },
  },
  notMission: {
    width: '100% !important',
    display: 'initial',
  },
}));

const description: { [key: string]: string } = {
  Conservador: 'Riesgo mínimo, rentabilidad estable',
  'Conservador Moderado': 'Riesgo bajo y pequeños cambios en tu rentabilidad',
  Moderado: 'Equilibrio entre riesgo y cambios en tu rentabilidad',
  'Moderado Agresivo': 'Mayor riesgo, con rentabilidades a largo plazo',
  Agresivo: 'Alto riesgo con mayor rentabilidad de largo plazo',
};
