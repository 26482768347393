import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  subtitle: {
    fontFamily: "Montserrat",
    fontSize: "0.875rem",
    color: theme.palette.text.secondary,
    fontWeight: "bold",
    textTransform: "uppercase",
    margin: "0.5rem 0",
  },
  text: {
    color: "#7d7c7c",
    fontSize: "0.875rem",
    [theme.breakpoints.down(1240)]: {
      width: 300,
    },
    "& span": {
      fontWeight: "bold",
    },
  },
  container: {
    display: "grid",
    boxShadow: "4px 4px 20px 2px rgba(0, 0, 0, 0.2)",
    fontFamily: "Montserrat",
    width: "37rem",
    height: "15rem",
    borderRadius: 30,
    padding: "1.5rem 1.5rem",
    [theme.breakpoints.down(1240)]: {
      width: 340,
      height: "20rem",
      margin: "0 auto",
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down(1240)]: {
      marginTop: "2rem",
      display: "grid",
      gap: "1rem",
      justifyContent: "center",
      "& >div": {
        margin: "0 auto",
      },
    },
  },
}));
