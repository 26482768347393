import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    boxShadow: "4px 4px 20px 2px rgba(0, 0, 0, 0.2)",
    fontFamily: "Montserrat",
    width: "37rem",
    height: "15rem",
    borderRadius: 30,
    padding: "1.5rem 2rem",
    [theme.breakpoints.down(1240)]: {
      width: 340,
      height: "21rem",
      margin: "0 auto",
      padding: "1.5rem 1.3rem",
    },
    "& .upload": {
      color: "#7d7c7c",
      fontSize: "0.8rem",
      width: 204,
      marginBottom: "0.2rem",
    },
  },
  textIntroducing: {},
  title: {
    color: "#8352fd",
    fontWeight: "bold",
    fontSize: "1.25rem",
    margin: "0.3rem  0.87rem 0 0",
    fontFamily: "Montserrat",
    marginBottom: "1rem",
    [theme.breakpoints.down(1240)]: {
      margin: 0,
      paddingBottom: "1rem",
    },
  },
  subtitle: {
    fontFamily: "Montserrat",
    fontSize: "0.875rem",
    color: theme.palette.text.secondary,
    fontWeight: "bold",
    textTransform: "uppercase",
    margin: "0.5rem 0",
  },
  containerInfo: {
    display: "flex",
    gap: "2rem",
    [theme.breakpoints.up(1240)]: {
      "& >div": {
        width: "100%",
      },
    },
  },
  creditCategoryInput: {
    width: "16.5rem",
  },
  plazo: {
    width: "5.8rem",
  },
  plazoContainer: {
    alignItems: "center",
    display: "flex",
    gap: "1rem",
    color: theme.palette.text.secondary,
  },
  percentage: {
    width: "5.8rem",
  },
  porcentageContainer: {
    alignItems: "center",
    display: "flex",
    gap: "1rem",
    color: theme.palette.text.secondary,
  },
  input: {
    marginBottom: "1rem",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down(1240)]: {
      gap: "0.3rem",
    },
    [theme.breakpoints.up(1240)]: {
      display: "flex",
    },
  },
  addOther: {
    marginTop: "3rem",
    "& .add": {
      margin: "1.5rem 0",
      boxShadow: "2px 2px 7px 0 rgba(0, 0, 0, 0.3)",
      width: "10.47rem",
      height: "2.94rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 10,
      gap: "0.5rem",
      "& svg": {
        color: theme.palette.primary.main,
      },
    },
  },
  dateInput: {
    "& >div": {
      width: "11.9rem",
    },
  },
  grid: {
    display: "grid",
    justifyContent: "center",
    [theme.breakpoints.up(1240)]: {
      display: "flex",
      "& >div": {
        flexDirection: "inherit!important",
      },
      justifyContent: "space-between",
    },
  },
  checkbox: {
    marginBottom: "0.5rem",
  },
  flex: {
    display: "grid",
    gridTemplateColumns: "100%",
    gap: "4rem",
    [theme.breakpoints.up(1240)]: {
      gridTemplateColumns: "100%",
      gap: 0,
      "& >div": {},
    },
  },
  inputWidth: {
    width: "8rem",
    [theme.breakpoints.up(1240)]: {
      width: "12rem",
    },
  },
  boxes: {
    height: "3.2rem",
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    border: "1px solid #858c94",
    width: "12.8rem",
    boxShadow: "4px 4px 20px 2px rgba(0, 0, 0, 0.2)",
    borderRadius: 10,
    justifyContent: "center",
    "& .selectFile": {
      display: "flex",
    },
    "& .nameFile": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      width: 140,
      textOverflow: "ellipsis",
    },
    "& .frontside": {
      display: "flex",
      "& >div": {
        display: "flex",
        alignItems: "center",
      },
    },
  },
  disabled: {
    opacity: 0.5,
    cursor: "auto",
    pointerEvents: "none",
    "& .selectFile": {
      cursor: "auto",
      pointerEvents: "none",
    },
  },
}));
