import React, {
  useMemo,
  useState,
  useEffect,
  createContext,
  useCallback,
  useContext,
} from 'react';
import {
  AddInvestmentMovementDialog,
  InvestmentMovementsDialog,
  InvestmentsDialog,
} from '@components';
import {
  FormInvestment,
  FormMovement,
  Investment,
  InvestmentStatus,
  InvestmentType,
  InvestorProfileType,
  Portfolio,
  PortfolioAssetCategory,
  PortfolioLocationCategory,
  Route,
} from '@interfaces';
import {
  createUserInvestmentsWithMovements,
  useUserInvestments,
  deleteUserInvestments,
  deleteUserInvestmentMovements,
  createUserInvestmentMovements,
  investOnPortfolioMutation,
  reinvestOnPortfolio,
  withdrawalFromPortfolio,
} from '@apollo';
import { isEqual } from 'lodash';
import { LoadingContext } from '@context';
import moment from 'moment';
import { useApolloClient } from '@apollo/client';

interface InvestOnPortfolioParams {
  suggested?: boolean;
  amount: number;
  investmentFrequency?: string;
  period?: number;
  portfolioId: number | null;
  investorProfile?: InvestorProfileType | null;
  investmentAsset?: PortfolioAssetCategory | null;
  investmentLocation?: PortfolioLocationCategory | null;
  goalId: number | null;
}

interface PortfolioWithdrawalParams {
  amount: number;
  portfolioId: number | null;
}

interface InvestmentsContextType {
  openInvestsDialog: () => void;
  closeInvestsDialog: () => void;
  openAddMovsDialog: (investment: Investment) => void;
  openViewMovsDialog: (investments: Investment) => void;
  investments: Investment[];
  createNewInvestments: (payloads: FormInvestment[]) => void;
  deleteInvestments: (investmentsIds: number[]) => void;
  createNewMovements: (
    payloads: FormMovement[],
    investmentType: InvestmentType
  ) => void;
  deleteMovements: (movementsIds: number[]) => void;
  hasInvestmentOnGoing: (fundId: number) => boolean;
  hasInvestedOnSerie: (
    investments: Investment[],
    serieId?: number | null
  ) => boolean;
  hasInvestedOnPortfolio: (
    portfolios: Portfolio[],
    portfolioId?: number | null
  ) => boolean;
  hasInvestments: boolean;
  investOnPortfolio: (params: InvestOnPortfolioParams) => void;
  sendPortfolioWithdrawal: (params: PortfolioWithdrawalParams) => void;
}
export const InvestmentsContext = createContext({} as InvestmentsContextType);

export const InvestmentsProvider: React.FC = ({ children }) => {
  const client = useApolloClient();
  /*  HANDLING */
  const [investsDialog, setInvestsDialog] = useState<boolean>(false);
  const openInvestsDialog = useCallback(
    () => setInvestsDialog(true),
    [setInvestsDialog]
  );
  const closeInvestsDialog = useCallback(
    () => setInvestsDialog(false),
    [setInvestsDialog]
  );

  const [addMovsDialog, setAddMovsDialog] = useState<Investment | null>(null);
  const openAddMovsDialog = useCallback(
    (investment: Investment) => {
      setAddMovsDialog(investment);
    },
    [setAddMovsDialog]
  );
  const closeAddMovsDialog = useCallback(
    () => setAddMovsDialog(null),
    [setAddMovsDialog]
  );

  const [viewMovsDialog, setViewMovsDialog] = useState<Investment | null>(null);
  const openViewMovsDialog = useCallback(
    (investments: Investment) => {
      setViewMovsDialog(investments);
    },
    [setViewMovsDialog]
  );
  const closeViewMovsDialog = useCallback(
    () => setViewMovsDialog(null),
    [setViewMovsDialog]
  );

  /* DATA FROM OTHER CONTEXTS */
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const { investments } = useUserInvestments();

  const hasInvestmentOnGoing = useCallback(
    (fundId: number) => {
      const result = investments.find(
        (inv) => inv.fund.id === fundId && inv.status !== InvestmentStatus.OK
      );
      return Boolean(result);
    },
    [investments]
  );

  const hasInvestedOnSerie = useCallback(
    (investments: Investment[], serieId?: number | null) => {
      const result = investments.find((inv) => inv.serie.id === serieId);
      return Boolean(result);
    },
    []
  );

  const hasInvestedOnPortfolio = useCallback(
    (portfolios: Portfolio[], portfolioId?: number | null) => {
      const result = portfolios.find((p) => p.id === portfolioId);
      return Boolean(result);
    },
    []
  );

  // Update referenced investment to trigger rerenders
  useEffect(() => {
    if (addMovsDialog) {
      const investment = investments.find(
        (inv) => inv.serie.id === addMovsDialog.serie.id
      );
      if (investment) {
        if (!isEqual(addMovsDialog, investment)) setAddMovsDialog(investment);
      } else setAddMovsDialog(null);
    } else if (viewMovsDialog) {
      const investment = investments.find(
        (inv) => inv.serie.id === viewMovsDialog.serie.id
      );
      if (investment) {
        if (!isEqual(viewMovsDialog, investment)) setViewMovsDialog(investment);
      } else setViewMovsDialog(null);
    }
  }, [
    addMovsDialog,
    viewMovsDialog,
    investments,
    setAddMovsDialog,
    setViewMovsDialog,
  ]);

  const createNewInvestments = useCallback(
    (payloads: FormInvestment[]) => {
      showLoader('creating investments', [Route.investments]);
      createUserInvestmentsWithMovements(payloads, client).then(() => {
        hideLoader('creating investments');
      });
    },
    [client, showLoader, hideLoader]
  );

  const deleteInvestments = useCallback(
    (investmentsIds: number[]) => {
      showLoader('deleting investments', [Route.investments]);
      deleteUserInvestments(investmentsIds, true, client).then(() => {
        hideLoader('deleting investments');
      });
    },
    [client, showLoader, hideLoader]
  );

  const createNewMovements = useCallback(
    (payloads: FormMovement[], investmentType: InvestmentType) => {
      showLoader('creating movements', [Route.investments]);
      createUserInvestmentMovements(
        payloads.map((mov) => {
          console.log(mov);
          return {
            date: mov.date ?? moment().tz('America/Santiago').format(),
            amount: Math.abs(mov.amount ?? 0),
            type: mov.type,
            serieId: mov.serieId as number,
            investmentType,
          };
        }),
        true,
        client
      ).then(() => {
        hideLoader('creating movements');
      });
    },
    [client, showLoader, hideLoader]
  );

  const deleteMovements = useCallback(
    (investmentsIds: number[]) => {
      showLoader('deleting movements', [Route.investments]);
      deleteUserInvestmentMovements(investmentsIds, client).then(() => {
        hideLoader('deleting movements');
      });
    },
    [client, showLoader, hideLoader]
  );

  const investOnPortfolio = useCallback(
    (params: InvestOnPortfolioParams) => {
      if (params.portfolioId) {
        showLoader('[NewInvestContext] Reinvesting on portfolio');
        reinvestOnPortfolio(
          {
            portfolioId: params.portfolioId,
            amount: params.amount,
          },
          client
        ).then(() => {
          hideLoader('[NewInvestContext] Reinvesting on portfolio');
        });
      } else if (params.suggested) {
        showLoader('[NewInvestContext] Investing on SP');
        investOnPortfolioMutation(
          {
            amount: params.amount,
            investmentFrequency: params.investmentFrequency,
            period: params.period,
            investorProfile: params.investorProfile,
            investmentAsset: params.investmentAsset,
            investmentLocation: params.investmentLocation,
            goalId: params.goalId,
          },
          client
        ).then(() => {
          hideLoader('[NewInvestContext] Investing on SP');
        });
      }
    },
    [client, showLoader, hideLoader]
  );

  const sendPortfolioWithdrawal = useCallback(
    (params: PortfolioWithdrawalParams) => {
      if (!params.portfolioId) return;
      showLoader('[NewInvestContext] Sending withdrawal for portfolio');
      withdrawalFromPortfolio(
        {
          portfolioId: params.portfolioId,
          amount: params.amount,
        },
        client
      ).then(() => {
        hideLoader('[NewInvestContext] Sending withdrawal for portfolio');
      });
    },
    [client, showLoader, hideLoader]
  );

  const context = useMemo(
    () => ({
      openInvestsDialog,
      closeInvestsDialog,
      openAddMovsDialog,
      openViewMovsDialog,
      investments,
      createNewInvestments,
      deleteInvestments,
      createNewMovements,
      deleteMovements,
      hasInvestmentOnGoing,
      hasInvestments: investments.length > 0,
      hasInvestedOnSerie,
      hasInvestedOnPortfolio,
      investOnPortfolio,
      sendPortfolioWithdrawal,
    }),
    [
      openInvestsDialog,
      closeInvestsDialog,
      openAddMovsDialog,
      openViewMovsDialog,
      investments,
      createNewInvestments,
      deleteInvestments,
      createNewMovements,
      deleteMovements,
      hasInvestmentOnGoing,
      hasInvestedOnSerie,
      hasInvestedOnPortfolio,
      investOnPortfolio,
      sendPortfolioWithdrawal,
    ]
  );

  return (
    <InvestmentsContext.Provider value={context}>
      <>
        {children}
        <InvestmentsDialog
          open={investsDialog}
          onClose={closeInvestsDialog}
          // goal={goalInvestsDialog}
          // getParsedInvest={getParsedGoalInvestmentName}
          // editInvestmentClick={editInvestmentClick}
        />
        <AddInvestmentMovementDialog
          open={Boolean(addMovsDialog)}
          onClose={closeAddMovsDialog}
          serieId={addMovsDialog?.serie.id}
          // goal={goalInvestsDialog}
          // getParsedInvest={getParsedGoalInvestmentName}
          // editInvestmentClick={editInvestmentClick}
        />
        <InvestmentMovementsDialog
          open={Boolean(viewMovsDialog)}
          onClose={closeViewMovsDialog}
          investment={viewMovsDialog}
        />
      </>
    </InvestmentsContext.Provider>
  );
};
