/* eslint-disable react/react-in-jsx-scope */
import { Tooltip } from "@material-ui/core";
import { theme } from "@styles";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
import React from "react";
import ReactDOM from "react-dom";

const joyrideStyles = {
  options: {
    zIndex: 100000,
  },
};
export const useTour = (
  steps: Step[],
  localStorageKey: string | null,
): ReactNode => {
  const handleJoyrideCallback = useCallback((data: CallBackProps) => {
    const { status } = data;
    const finishStatues: string[] = [
      STATUS.FINISHED,
      STATUS.SKIPPED,
      STATUS.RUNNING,
    ];
  }, []);

  const tour = useMemo<ReactNode>(
    () => (
      <Joyride
        styles={{
          options: {
            arrowColor: theme.palette.primary.main,
            zIndex: 10000,
          },
        }}
        callback={handleJoyrideCallback}
        disableScrolling
        disableOverlayClose
        steps={steps}
        run={true}
        // tooltipComponent={Tooltip}
      />
    ),
    [steps, handleJoyrideCallback],
  );
  return tour;
};
