import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useStyles } from "./MRS.style";
import { useMediaQuery } from "@material-ui/core";
import { SLIDE_1, SLIDE_2, SLIDE_3 } from "images/GCP";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";

import {
  navArrowLeft,
  navArrowRight,
  navArrowLeftActive,
  navArrowRightActive,
  step_1_reg_mail,
  step_2_reg_mail,
  step_3_reg_mail,
} from "@images";

export const MailingRegisterSlider = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const slider = React.useRef(null);

  const [currentLeftArrow, setCurrentLeftArrow] = useState(navArrowLeft);
  const [currentRightArrow, setCurrentRightArrow] =
    useState(navArrowRightActive);
  // const [currentSpaceMan, setCurrentSpaceMan] = useState(SpaceMan1);
  const [currentSlide, setCurrentSlide] = useState(0);
  useEffect(() => {
    if (currentSlide == 0) {
      setCurrentLeftArrow(navArrowLeft);
      setCurrentRightArrow(navArrowRightActive);
    }
    if (currentSlide == 1) {
      setCurrentLeftArrow(navArrowLeftActive);
      setCurrentRightArrow(navArrowRightActive);
    }
    if (currentSlide == 2) {
      setCurrentLeftArrow(navArrowLeftActive);
      setCurrentRightArrow(navArrowRight);
    }
  }, [currentSlide]);

  const arrowRight = {
    width: 50,
    height: 50,
    background: `url(${currentRightArrow})`,
    margin: "0 0 3rem -1rem",
  };

  const arrowLeft = {
    width: 50,
    height: 50,
    background: `url(${currentLeftArrow})`,
    margin: "0 -1rem 3rem 0",
  };

  const ellipseDots = {
    width: "14.9px",
    height: "14.9px",
    flexGrow: 0,
    margin: "0 16.6px 0 0",
    "background-image": "linear-gradient(335deg, #8352FD 127%, #8250ff 8%)",
  };

  let dotted;
  if (isMobile) {
    dotted = false;
  } else {
    dotted = true;
  }
  const settings = {
    dots: dotted,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (current, next) => {
      setCurrentSlide(next);
    },
    dotsClass: `slick-dots ${classes.dots}`,
  };
  return (
    <div className={classes.container}>
      <div>
        <button
          onClick={() => slider?.current?.slickPrev()}
          style={arrowLeft}
        />
      </div>
      <div className={classes.sliderBlock}>
        <Slider ref={slider} {...settings}>
          <div className={classes.slide}>
            <img src={SLIDE_1} alt="step_1_reg_mail" />
          </div>
          <div className={classes.slide}>
            <img src={SLIDE_2} alt="step_2_reg_mail" />
          </div>
          <div className={classes.slide}>
            <img src={SLIDE_3} alt="step_3_reg_mail" />
          </div>
        </Slider>
      </div>
      <div>
        <button
          onClick={() => slider?.current?.slickNext()}
          style={arrowRight}
        />
      </div>
    </div>
  );
};
