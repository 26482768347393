import React, { useCallback, useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { navigate } from '@reach/router';
import { Route } from '@interfaces';
import {
  imagenProfileFinance,
  INVESTMENTS_GREEN,
  INVESTMENTS_GREY,
  MY_FINANCE_GREEN,
  MY_FINANCE_GREY,
  Rokin,
  ROKIPEDIA_GREEN,
  ROKIPEDIA_GREY,
  HOME_ICON,
  ROCKET_ICON,
  TREND_UP_ICON,
  SERVICES_ICON,
  ROKIN_LOGO,
} from '@images';
import { incentive, invest, list, photo } from 'images/onBoarding';
import PerfilDropdown from './PerfilDropdown';
import HomeIcon from '@material-ui/icons/Home';
import SearchIcon from '@material-ui/icons/Search';
import { useCurrentUser } from '@apollo';
import { SERVICES_GREEN, SERVICES_GREY } from 'images/GoogleCloud';

interface HeaderProps {
  simpleHeader?: boolean;
  activeRokipedia?: boolean;
  activeInvest?: boolean;
  activeBenefit?: boolean;
  activeFinance?: boolean;
  activeRokinpedia?: boolean;
  activeServices?: boolean;
  activeAccount?: boolean;
}

export const Header = ({
  activeRokipedia,
  activeInvest,
  activeBenefit,
  activeFinance,
  activeRokinpedia,
  activeServices,
  activeAccount,
}: HeaderProps) => {
  const classes = useStyles();
  const { user: currentUser } = useCurrentUser();
  const [profileName, setProfileName] = useState('Usuario');
  const [canInvest, setCanInvest] = useState<boolean>(true);

  const onClickInvestments = useCallback(() => {
    navigate(Route.investments);
  }, []);
  const onClickBenefits = useCallback(() => {
    navigate(Route.benefit);
  }, []);
  const onClickFinance = useCallback(() => {
    navigate(Route.finance);
  }, []);
  const onClickRokinpedia = useCallback(() => {
    navigate(Route.rokipedia);
  }, []);
  const onClickNotCompany = useCallback(() => {
    navigate(Route.investments);
  }, []);

  const [isPerfilDropdown, setIsPerfilDropdown] = useState<boolean>(false);

  const handleDropdown = () => {
    if (isPerfilDropdown) {
      setIsPerfilDropdown(false);
    } else {
      setIsPerfilDropdown(true);
    }
  };

  useEffect(() => {
    setProfileName(currentUser?.name ? currentUser?.name : '...');

    if (currentUser?.company) {
      setCanInvest(currentUser.company.canInvest);
    }
  }, [currentUser]);

  return (
    <header className={classes.header}>
      <div className={classes.headerLeft}>
        <div
          className={classes.logo}
          onClick={() => navigate(Route.financialPortal)}
        >
          <img src={ROKIN_LOGO} alt="logo" />
        </div>
      </div>
      <div className={classes.blockRight}>
        <div
          className={activeAccount ? classes.active : undefined}
          onClick={() => navigate('/myAccounts')}
        >
          {!activeAccount ? (
            <img className={classes.greyFilter} src={ROCKET_ICON} />
          ) : (
            <img className={classes.greenFilter} src={ROCKET_ICON} />
          )}
          <span>Mis cuentas</span>
        </div>
        <div
          className={activeFinance ? classes.active : undefined}
          onClick={onClickFinance}
        >
          {!activeFinance ? (
            <img src={HOME_ICON} className={classes.greyFilter} />
          ) : (
            <img src={HOME_ICON} className={classes.greenFilter} />
          )}
          <span>Mis Finanzas</span>
        </div>
        {canInvest && (
          <div
            className={activeInvest ? classes.active : undefined}
            onClick={onClickInvestments}
          >
            {!activeInvest ? (
              <img src={TREND_UP_ICON} className={classes.greyFilter} />
            ) : (
              <img src={TREND_UP_ICON} className={classes.greenFilter} />
            )}{' '}
            <span>Inversiones</span>
          </div>
        )}
        <div
          className={activeServices ? classes.active : undefined}
          onClick={() => navigate(Route.services)}
        >
          {!activeServices ? (
            <img src={SERVICES_ICON} className={classes.greyFilter} />
          ) : (
            <img src={SERVICES_ICON} className={classes.greenFilter} />
          )}
          <span>Servicios</span>
        </div>
        <div
          className={`${isPerfilDropdown ? classes.activeProfile : undefined} ${
            classes.profile
          }`}
          onClick={handleDropdown}
        >
          <div>
            <img src={imagenProfileFinance} className={classes.photoPeople} />{' '}
          </div>
          <span>{profileName}</span>
        </div>
      </div>
      {isPerfilDropdown && <PerfilDropdown />}
    </header>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    paddingRight: '2rem',
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '0 0 24px 24px', //top-left | top-right | bottom-right | bottom-left
    height: 70,
    fontFamily: theme.typography.fontFamily,
    alignItems: 'center',
    background: '#F4F3F3',
    boxShadow: '4px 4px 8px 2px rgba(0, 0, 0, 0.2)',
    position: 'fixed',
    width: '100%',
    zIndex: 999,
    '& span': {
      textTransform: 'inherit',
    },
  },
  logotxt: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    color: '#0a5669',
    fontSize: '3rem',
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    alignItems: 'end',
  },
  logoText: {
    display: 'grid',
    height: '100%',
    paddingLeft: 30,
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 210,
    '& img': {},
    '& a': {
      fontSize: 14,
      [theme.breakpoints.down(1100)]: {
        display: 'none',
      },
    },
    [theme.breakpoints.down(1440)]: {
      width: 290,
    },
  },
  blockRight: {
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
    justifyContent: 'space-evenly',
    transition: '0.3s',
    '& a': {
      cursor: 'pointer',
      height: 25,
      transition: '0.3s',
    },
    '& span': {
      padding: '13px 12px 10px',
      transition: '1s',
      color: theme.palette.text.secondary,
      display: 'block',
    },
    '& div': {
      display: 'flex',
      alignItems: 'center',
      padding: '0 10px',
      cursor: 'pointer',
      margin: '0 0 0 15px',
    },
    '& >div:last-child:hover': {
      boxShadow: 'none',
    },
    '& >div:hover': {
      transition: '1s',
      boxShadow: 'inset 0 4px 4px 0 rgba(0, 0, 0, 0.25)',
      borderRadius: 10,
    },
  },
  photoPeople: {
    borderRadius: '100%',
    width: 40,
  },
  active: {
    //boxShadow: 'inset 0 4px 4px 0 rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
    '& span': {
      color: theme.palette.primary.main,
    },
  },
  icon: {
    color: theme.palette.primary.main,
  },
  greyFilter: {
    filter:
      'invert(39%) sepia(1%) saturate(0%) hue-rotate(31deg) brightness(97%) contrast(91%)',
  },
  greenFilter: {
    filter:
      'invert(26%) sepia(93%) saturate(1930%) hue-rotate(167deg) brightness(93%) contrast(103%)',
  },
}));
