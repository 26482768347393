import "regenerator-runtime/runtime.js";
import { EffectCallback, useEffect } from "react";
import { useDeepDependencies } from "./useDeepDependencies";

export function useDeepEffect(
  callback: EffectCallback,
  dependencies: unknown[],
) {
  const deepDependencies = useDeepDependencies(dependencies);
  return useEffect(callback, deepDependencies);
}
