const DEPOSIT_ICON_PURPLE =
  'https://storage.googleapis.com/rokinapp-images/investments/Modals/depositar_icon_morado.svg';
const AHORRO_AVATAR =
  'https://storage.googleapis.com/rokinapp-images/investments/ahorroAvatar.svg';
const COMPRAR_CASA_AVATAR =
  'https://storage.googleapis.com/rokinapp-images/investments/comprarCasaAvatar.svg';
const DEPOSITO_A_PLAZO_AVATAR =
  'https://storage.googleapis.com/rokinapp-images/investments/depositoAPlazoAvatar.svg';
const DEUDAS_AVATAR =
  'https://storage.googleapis.com/rokinapp-images/investments/deudasAvatar.svg';
const FONDO_EMERGENCIA_AVATAR =
  'https://storage.googleapis.com/rokinapp-images/investments/fondoEmergenciaAvatar.svg';
const INVERSION_SP_AVATAR =
  'https://storage.googleapis.com/rokinapp-images/investments/inversionSPAvatar.svg';
const JUBILACION_AVATAR =
  'https://storage.googleapis.com/rokinapp-images/investments/jubilacionAvatar.svg';
const OTRO_AVATAR =
  'https://storage.googleapis.com/rokinapp-images/investments/otroAvatar.svg';
const VIAJAR_AVATAR =
  'https://storage.googleapis.com/rokinapp-images/investments/viajarAvatar.svg';
const CLOCK_ICON =
  'https://storage.googleapis.com/rokinapp-images/investments/clockSmall_icon.svg';
export {
  DEPOSIT_ICON_PURPLE,
  AHORRO_AVATAR,
  COMPRAR_CASA_AVATAR,
  DEPOSITO_A_PLAZO_AVATAR,
  DEUDAS_AVATAR,
  FONDO_EMERGENCIA_AVATAR,
  INVERSION_SP_AVATAR,
  JUBILACION_AVATAR,
  OTRO_AVATAR,
  VIAJAR_AVATAR,
  CLOCK_ICON,
};
