import { gql, useQuery } from "@apollo/client";
import { SubscriptionPlans } from "@interfaces";

export const GET_SUBSCRIPTION_PLANS = gql`
  query GetSubscriptionPlans {
    getSubscriptionPlans {
      id
      updatedAt
      name
      subtitle
      price
      priceDiscount
      periodicity
      image
      paymentLink
      paymentLinkDiscount
      annualPaymentLink
      annualPaymentLinkDiscount
      isRecommended
      needSchedule
      benefits
    }
  }
`;

interface QueryResult {
  getSubscriptionPlans: SubscriptionPlans[];
}

interface HookReturn {
  subscriptionPlans: SubscriptionPlans[];
  subscriptionPlansLoading: boolean;
}

export const getSubscriptionPlans = (): HookReturn => {
  const { data, loading } = useQuery<QueryResult>(GET_SUBSCRIPTION_PLANS);
  return {
    subscriptionPlans: data?.getSubscriptionPlans ?? [],
    subscriptionPlansLoading: loading,
  };
};
