import React, { useContext, useEffect, useState } from "react";
import { useStyles } from "./main2_3.style";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";
import { ingresoPromedio, ingresoFijo, ingresoVariable } from "@images";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ButtonPrimary, NumericInput } from "@components/Reusables";
import { AppContext } from "@context";

type Props = {
  handleClick: () => void;
  fixedIncomes: { name: string; amount: number }[];
  variableIncomes: {
    name: string;
    months: number;
    amounts: number[];
    average: number;
  }[];
};

const MainSectionM2: React.FC<Props> = ({
  fixedIncomes,
  variableIncomes,
  handleClick,
}) => {
  const classes = useStyles();
  const [total, setTotal] = useState(0);
  const [totalVariables, setTotalVariables] = useState(0);
  const [totalFixed, setTotalFixed] = useState(0);
  // const [fixedIncomes, setFixedIncomes] = useState([
  //   { name: "sueldo", amount: 1000 },
  //   { name: "arriendo", amount: 2000 },
  // ]);
  // // pedir promedio de ingresos al back
  // const [variableIncomes, setVariableIncomes] = useState([
  //   { name: "hipoteca", months: 1, amounts: [10], average: 10 },
  //   { name: "ingreso 2", months: 3, amounts: [20, 30, 50], average: 33 },
  // ]);
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const { openCongratsModal } = useContext(AppContext);

  useEffect(() => {
    // add all the fixed incomes and put it in the totalFixed value
    fixedIncomes.forEach((income) => {
      setTotalFixed((prev) => prev + income.amount);
      setTotal((prev) => prev + income.amount);
    });
    // add all the variable incomes and put it in the totalVariables value
    variableIncomes.forEach((income) => {
      setTotalVariables((prev) => prev + income.average);
      setTotal((prev) => prev + income.average);
    });
    // add the totalFixed and totalVariables and put it in the total value
    // setTotal(totalFixed + totalVariables);
  }, [fixedIncomes, variableIncomes]);
  return (
    <div className={classes.container}>
      <div className="mainTitle">
        <span>
          En base a la información que entregaste, tu ingreso promedio es:
        </span>
      </div>
      <div className={classes.midSection}>
        <div className={classes.totalContainer}>
          <img src={ingresoPromedio} />
          <div className="totalValue">
            {/* <span>$</span> */}

            <span>$ {total.toLocaleString("es")}</span>
          </div>
        </div>
        {!isMobile && (
          <div className={classes.text}>
            <span>
              {" "}
              Recuerda que este promedio se hace en base a tus últimos 3
              ingresos, por lo que puede variar en un plazo más largo. Además,
              tener un sueldo promedio no te asegura poder gastar ese monto, ya
              que muchas veces no vas a tener plata disponible en tu cuenta.
            </span>
          </div>
        )}
      </div>
      <div className={classes.incomesRecap}>
        <Accordion elevation={0} className={classes.accordion}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <img src={ingresoFijo} className={classes.accordionIcon} />
            <div className={classes.summaryRight}>
              <span className={classes.details}>Ingreso fijo</span>
              <NumericInput
                className={classes.mainIncomeValue}
                value={totalFixed}
                disabled
                name="fixed-total"
                onChange={() => {}}
              />
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.incomesContainer}>
              {fixedIncomes.map((income) => (
                <div>
                  <div className="incomeName">{income.name}</div>
                  <div className="incomeInput">
                    <NumericInput
                      name="incomes"
                      label=""
                      disabled
                      value={income.amount ?? 0}
                      onChange={() => {}}
                    />
                  </div>
                </div>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={0} className={classes.accordion}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <img src={ingresoVariable} className={classes.accordionIcon} />
            <div className={classes.summaryRight}>
              <span className={classes.details}>Ingreso variable</span>
              <NumericInput
                name="variable-total"
                value={totalVariables}
                className={classes.mainIncomeValue}
                disabled
                onChange={() => {}}
              />
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.incomesContainer}>
              {variableIncomes.map((income) => (
                <div>
                  <div className="incomeName">{income.name}</div>
                  <div className="incomeInput">
                    <NumericInput
                      name="incomes"
                      label=""
                      disabled
                      value={income.average ?? 0}
                      onChange={() => {}}
                    />
                  </div>
                </div>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className={classes.bottomSection}>
        {isMobile && (
          <div className={classes.text2}>
            <span>OJO</span>
            <br />
            <span>
              {" "}
              Recuerda que este promedio se hace en base a tus últimos 3
              ingresos, por lo que puede variar en un plazo más largo. Además,
              tener un sueldo promedio no te asegura poder gastar ese monto, ya
              que muchas veces no vas a tener plata disponible en tu cuenta.
            </span>
          </div>
        )}
        {/* // <span> */}
        {/* //   Si quieres analizar tu ingreso de forma automática e inteligente
          //   prueba nuestra herramienta para <span>sincronizar cuentas</span>{" "}
          // </span>> */}

        <ButtonPrimary
          className={classes.buttonPrimary}
          onClick={handleClick}
          text="Terminar misión"
          secondaryVersion
        />
      </div>
    </div>
  );
};

export default MainSectionM2;
