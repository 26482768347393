import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    // width: "70rem",
  },
  topSection: {
    maxWidth: "74rem",
    "& span": {
      fontFamily: "Montserrat",
      fontSize: "1rem",
      lineHeight: "1.88",
      textAlign: "left",
      color: theme.palette.text.primary,
    },
  },
  midSection: {
    "& >img": {
      width: "80vw",
      maxWidth: "74rem",
    },
    [theme.breakpoints.down(1240)]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      marginTop: "2rem",
      // marginLeft: "-10%",
    },
  },
  gridContainer: {
    display: "grid",
    gridTemplateRows:
      "[first] 2.3rem [second] 2.3rem [third] 3.438rem [fourth] 4.375rem [fifth] 7.875rem [last]",
    gridTemplateColumns: "[first] 20% [second] 35% [middle] 35% [last]",
    gridColumnGap: "0.875rem",
    gridRowGap: "0.563rem",
    "& .firstColumn": {
      gridColumn: "second / middle ",
      backgroundColor: "#ffffff",
      borderRadius: "0.625rem",
      boxShadow: "4px 4px 20px 2px rgba(0, 0, 0, 0.2)",
      gridRow: "first / last",
    },
    "& .secondColumn": {
      gridColumn: "middle / last ",
      borderRadius: "0.625rem",
      backgroundColor: "#ffffff",
      boxShadow: "4px 4px 20px 2px rgba(0, 0, 0, 0.2)",
      gridRow: "first / last",
    },
    "& .firstRow": {
      borderRadius: "0.625rem",

      width: "105%",
      boxShadow: "4px 4px 20px 2px rgba(0, 0, 0, 0.2)",
      backgroundColor: "#ffffff",
      opacity: 0.8,
      gridRow: "second / third",
      gridColumn: "first / last",
    },
    "& .secondRow": {
      width: "105%",
      borderRadius: "0.625rem",
      boxShadow: "4px 4px 20px 2px rgba(0, 0, 0, 0.2)",
      backgroundColor: "#ffffff",
      opacity: 0.8,
      gridRow: "third / fourth ",
      gridColumn: "first / last",
    },
    "& .thirdRow": {
      borderRadius: "0.625rem",
      width: "105%",
      boxShadow: "4px 4px 20px 2px rgba(0, 0, 0, 0.2)",
      backgroundColor: "#ffffff",
      opacity: 0.8,
      gridColumn: "first / last",
      gridRow: "fourth / fifth",
    },
    "& .fourthRow": {
      borderRadius: "0.625rem",
      width: "105%",
      boxShadow: "4px 4px 20px 2px rgba(0, 0, 0, 0.2)",
      backgroundColor: "#ffffff",
      opacity: 0.8,
      gridColumn: "first / last",
      gridRow: "fifth / last",
    },
    "& .back": {
      zIndex: 0,
    },
    "& .topAndCenter": {
      zIndex: 2,
      placeSelf: "center",
    },
    "& .creditName": {
      fontFamily: "Montserrat",
      fontSize: " 0.875rem",
      fontWeight: "bold",
      lineHeight: 2.14,
      textAlign: "left",
      color: theme.palette.text.primary,
    },
    "& .n1": {
      gridColumn: " second/ middle",
      gridRow: "first / second",
    },
    "& .n2": {
      gridColumn: " middle / last",
      gridRow: "first / second",
    },
    "& .atrributes": {
      fontFamily: "Montserrat",
      fontSize: " 0.9rem",
      width: "5rem",
      fontWeight: "normal",
      lineHeight: 1.67,
      textAlign: "left",
      color: theme.palette.text.primary,
      gridColumn: "first / second ",
      marginLeft: "1rem",
      [theme.breakpoints.down(620)]: {
        fontSize: " 0.75rem",
      },
      [theme.breakpoints.down(500)]: {
        marginLeft: "2rem",
      },
    },
    "& .a1": {
      gridRow: "second / third",
    },
    "& .a2": {
      gridRow: "third / fourth",
    },
    "& .a3": {
      gridRow: "fourth / fifth",
    },
    "& .a4": {
      gridRow: "fifth / last",
      alignSelf: "start",
      marginTop: "1rem",
    },
    "& .text": {
      fontSize: "0.8rem",
      lineHeight: 1.8,
      textAlign: "left",
      color: theme.palette.text.secondary,
      gridRow: "fifth / last",
      margin: "1.3rem 0.8rem",
      [theme.breakpoints.down(620)]: {
        fontSize: " 0.65rem",
        margin: "1.3rem 0.7rem",
      },
    },
    "& .t1": {
      gridColumn: "second / middle",
    },
    "& .t2": {
      gridColumn: "middle / last",
    },
    "& .add1": {
      gridColumn: "second / middle",
      gridRow: "second / third",
      width: "2.8rem",
      height: "2.8rem",
    },
    "& .add2": {
      gridColumn: "middle / last",
      gridRow: "third / fourth",
    },
    "& .add3": {
      gridColumn: "second / middle",
      gridRow: "fourth / fifth",
    },
    "& .less1": {
      gridColumn: "middle / last",
      gridRow: "second / third",
      width: "1.875rem",
      height: "1.875rem",
    },
    "& .less2": {
      gridColumn: "second / middle",
      gridRow: "third / fourth",
      width: "1.875rem",
      height: "1.875rem",
    },
    "& .less3": {
      gridColumn: "middle / last",
      gridRow: "fourth / fifth",
      width: "1.875rem",
      height: "1.875rem",
    },
  },
  bottomSection: {
    textAlign: "right",
    margin: "4rem 6rem 10rem 0",
    display: "flex",
    [theme.breakpoints.down(1240)]: {
      textAlign: "center",
      marginTop: "2rem",
      marginRight: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      // marginLeft: "-10%",
    },
  },
  texasContainer: {
    display: "flex",
    flexDirection: "row",
    marginRight: "3rem",
    [theme.breakpoints.down(1240)]: {
      marginTop: "1.7rem",
      marginRight: 0,
    },
    "& >img": {
      width: "1.334rem",
      height: "1.334rem",
      marginRight: "0.5rem",
    },
    "& .textSide": {
      display: "flex",
      flexDirection: "column",
      fontFamily: "Montserrat",
      fontSize: "0.875rem",
      textAlign: "left",
      width: "14.438rem",
      color: theme.palette.text.secondary,
      "& .text": {
        lineHeight: "1.43",
      },
      "& .title": {
        lineHeight: "1.71",
        fontWeight: "bold",
      },
    },
  },
  rightSide: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginLeft: "12%",
    [theme.breakpoints.down(1240)]: {
      margin: "3rem 0 0",
    },
    "& button": {
      marginBottom: "2rem",
    },

    "& >span": {
      fontFamily: "Montserrat",
      fontSize: "0.875rem",
      textAlign: "center",
      fontWeight: "bold",
      width: "14.438rem",
      color: theme.palette.text.secondary,
    },
    "& .underLined": {
      textDecoration: "underline",
    },
  },
}));
