import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "auto",
    // height: 649,
    margin: "2rem auto 10rem",
    padding: "34px 68px 49px",
    borderRadius: 30,
    boxShadow: "4px 4px 20px 2px rgba(0, 0, 0, 0.2)",
    backgroundColor: "white",
    maxWidth: 1140,
    [theme.breakpoints.down(1241)]: {
      width: "auto",
      margin: "20px auto",
      padding: 21,
    },
    "& .formContiner": {
      [theme.breakpoints.down(1241)]: {
        display: "flex",
        justifyContent: "center",
      },
    },
    "& .simulatorTitle": {
      color: theme.palette.text.primary,
      width: 321,
      height: 29,
      fontSize: 24,
      fontWeight: "bold",
      margin: "0 0 10px",
      fontFamily: "Montserrat",
    },
    "& .helpText": {
      margin: "0.625rem 0rem 2.1875rem 0",
      fontFamily: "Montserrat",
      fontSize: "1rem",
      textAlign: "left",
      color: theme.palette.text.secondary,
    },
  },
  userIncomes: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "flex-start",
    [theme.breakpoints.down(1241)]: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      alignItems: "center",
    },
  },
  incomeImage: {
    width: 80,
    height: 80,
    margin: "4px 17px 0 0",
    borderRadius: 10,
    gridColumn: "1 / 2",
    [theme.breakpoints.down(1241)]: {
      width: 60,
      height: 60,
    },
  },
  incomeDetail: {
    margin: 20,
    width: 261,
    display: "grid",
    gridTemplateColumns: "80px 174px",
    gridColumnGap: 10,
  },
  "& .incomeValues": {
    gridColumn: "2 / 3",
    "& .selectorContainer": {
      margin: "2px 0 7px",
    },
  },
  incomeInput: {
    boxShadow: "4px 4px 8px 2px rgba(0, 0, 0, 0.1)",
    height: 33,
    marginTop: 10,
    "& div": {
      height: 33,
    },
  },
  formControlSimulator: {
    margin: 10,
  },
  incomeName: {
    textTransform: "uppercase",
    width: 147,
    height: 22,
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: 400,
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  amountInput: {
    margin: "4px 0 4px",
  },
  addButton: {
    width: 167.5,
    height: 47,
    margin: "10px auto",
    boxShadow: "2px 2px 7px 0 rgba(0, 0, 0, 0.3)",
  },
  SimulatorBottomSection: {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down(1241)]: {
      justifyContent: "center",
    },
  },
  greyText: {
    "& >span": {
      fontSize: "1rem",
      fontWeight: 500,
      fontFamily: "Montserrat",
      lineHeight: 2.31,
      color: theme.palette.text.primary,
      [theme.breakpoints.down(1241)]: {
        fontSize: "0.875rem",
        textAlign: "left",
        margin: "0.5rem 0",
        lineHeight: 1.31,
      },
    },
  },
}));
