import React from "react";
import { useStyles } from "./TexasContainer.styles";

type Props = {
  link: string;
  name: string;
  type: string;
  description: string;
  hasAmount: boolean;
  amount?: number;
};

const TexasEvent: React.FC<Props> = ({
  link,
  name,
  description,
  type,
  hasAmount,
  amount,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.whiteBox}>
      <div className={classes.imageContainer}>
        <img src={link} alt="imagen" />
      </div>
      <div className={classes.textContainer}>
        <span className="title">{name}</span>
        <span className="description">{description}</span>
      </div>
      {hasAmount && (
        <div className={classes.amountContainer}>
          <span className="amount">
            {" $ "}
            {type === "payment" ? "- " : ""}
            {amount?.toLocaleString("es")}
          </span>
        </div>
      )}
    </div>
  );
};

export default TexasEvent;
