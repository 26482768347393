import { gql, useQuery } from "@apollo/client";

export const GET_COOLEBRA_BANKSLIST = gql`
  query Query {
    getCoolebraBanksList
  }
`;

interface QueryResult {
  getCoolebraBanksList: string[];
}

interface HookReturn {
  coolebraBanksList: string[];
  coolebraBanksListLoading: boolean;
  refetchCoolebraBanksList: () => void;
}

export const getCoolebraBanksList = (): HookReturn => {
  const { data, loading, refetch } = useQuery<QueryResult>(
    GET_COOLEBRA_BANKSLIST
  );

  return {
    coolebraBanksList: data?.getCoolebraBanksList ?? [],
    coolebraBanksListLoading: loading,
    refetchCoolebraBanksList: refetch,
  };
};
