export const FUNDS_SOURCE_OPTIONS = [
  "Honorarios",
  "Inversiones",
  "Propiedades",
  "Herencia",
  "Ahorro",
  "Actividad productiva",
].map(g => ({
  name: g,
  value: g,
}));
