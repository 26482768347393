import { makeStyles } from "@material-ui/core";
import React from "react";
import { SpinnerCircular } from "spinners-react";

type Props = {
  text: string;
};

const useStyles = makeStyles((theme) => ({
  container: {
    color: "#fff",
    height: "3rem",
    gap: "1rem",
    margin: "0 1rem 0 1rem",
    display: "flex",
    padding: "1rem",
    fontSize: "0.75rem",
    background: "#0a5669",
    fontFamily: theme.typography.fontFamily,
    borderRadius: "1rem",
    alignItems: "center",
    justifyContent: "flex-start",
  },
}));

export const SpinnerWithText: React.FC<Props> = ({ text }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <SpinnerCircular
        size={20}
        thickness={180}
        speed={50}
        color="rgba(255, 255, 255, 1)"
        secondaryColor="rgba(0, 0, 0, 0.15)"
      />
      <span>{text}</span>
    </div>
  );
};
