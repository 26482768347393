// ADD MOMENT
const FEEDING =
  "https://rokinapp-images.s3.amazonaws.com/myHabits/addMovement/alimentacion.svg";
const CALENDAR_ICON =
  "https://rokinapp-images.s3.amazonaws.com/myHabits/addMovement/calendar_icon.svg";
const SHOPPING =
  "https://rokinapp-images.s3.amazonaws.com/myHabits/addMovement/compras.svg";
const SPORTS_AND_HOBBIES =
  "https://rokinapp-images.s3.amazonaws.com/myHabits/addMovement/deportesYHobbies.svg";
const EDUCATION_WORK =
  "https://rokinapp-images.s3.amazonaws.com/myHabits/addMovement/educacionTrabajo.svg";
const ENTRETENIMIENT =
  "https://rokinapp-images.s3.amazonaws.com/myHabits/addMovement/entretencion.svg";
const FINANCE =
  "https://rokinapp-images.s3.amazonaws.com/myHabits/addMovement/finanzasEImpuestos.svg";
const PETS =
  "https://rokinapp-images.s3.amazonaws.com/myHabits/addMovement/mascotas.svg";
const OTHERS =
  "https://rokinapp-images.s3.amazonaws.com/myHabits/addMovement/otros.svg";
const GIFTS =
  "https://rokinapp-images.s3.amazonaws.com/myHabits/addMovement/regalos.svg";
const RESTAURANTS =
  "https://rokinapp-images.s3.amazonaws.com/myHabits/addMovement/restaurantes.svg";
const HEALTH =
  "https://rokinapp-images.s3.amazonaws.com/myHabits/addMovement/saludBelleza.svg";
const TRANSPORTATION =
  "https://rokinapp-images.s3.amazonaws.com/myHabits/addMovement/transporte.svg";
const TRIPS =
  "https://rokinapp-images.s3.amazonaws.com/myHabits/addMovement/viajes.svg";
const LIVING_PLACE =
  "https://rokinapp-images.s3.amazonaws.com/myHabits/addMovement/vivienda.svg";

export {
  LIVING_PLACE,
  TRIPS,
  TRANSPORTATION,
  HEALTH,
  RESTAURANTS,
  GIFTS,
  OTHERS,
  PETS,
  FINANCE,
  ENTRETENIMIENT,
  EDUCATION_WORK,
  SPORTS_AND_HOBBIES,
  SHOPPING,
  CALENDAR_ICON,
  FEEDING,
};
