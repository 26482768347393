import { isEqual } from "lodash";
import React, {
  createContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
  useContext,
} from "react";
import {
  GET_CURRENT_USER_QUERY,
  updateUser,
  useCurrentUser,
  saveUserFile,
  getToken,
} from "@apollo";
import {
  DEFAULT_FORM,
  FileSpecificType,
  investorProfileRelevantFields,
  InvestType,
  ProfileForm,
  Route,
  userToProfileForm,
  FundWithSeries,
} from "@interfaces";
import {
  calculateSuggestedProfile,
  getAllocationPercentages,
  getDefaultTermFilter,
} from "@utils";
import { FiltersContext } from "./FiltersContext";
import {
  ChangeInvestorProfileDialog,
  JOB_TYPE_OPTIONS,
  MARITAL_STATE_OPTIONS,
} from "@components";
import { SnackbarContext } from "./SnackbarContext";
import { LoadingContext } from "./LoadingContext";
import { uploadIdentityFile, filesUpload } from "@services";
import { useAuth } from "@hooks";
import { useApolloClient } from "@apollo/client";
interface ProfileContextType {
  profileFormFilled: boolean;
  profileForm: ProfileForm;
  updateProfileForm: (payload: Partial<ProfileForm>) => void;
  saveUserInfo: (
    payload?: Partial<ProfileForm>,
    withSnackbar?: boolean
  ) => Promise<void>;
  clearProfileForm: () => void;
  recalculateProfileForm: () => void;
  refetchCurrentUser: () => void;
  isFavoriteFilterActive: boolean;
  favoriteSeries: number[];
  handleFavoriteClick: (investId: number) => void;
  toggleFavoriteFilter: () => void;
  investType: InvestType | null;
  setInvestType: React.Dispatch<React.SetStateAction<InvestType | null>>;
  openInvestorProfileChangeDialog: () => void;
  closeInvestorProfileChangeDialog: () => void;
  investorProfileCompleted: boolean;
  profileSubpage: ProfileSubpage;
  setProfileSubpage: (param: ProfileSubpage) => void;
  setIdFundSelected: React.Dispatch<
    React.SetStateAction<FundWithSeries | undefined>
  >;
  idFundSelected: FundWithSeries | undefined;
  isFirstPersonalDataCompleted: boolean;
  isSecondPersonalDataCompleted: boolean;
  isSpouseDataCompleted: boolean;
  isSpecialDataCompleted: boolean;
  isInvestDataCompleted: boolean;
  isOnboardingCompleted: boolean;
  identityFiles: FilesType;
  liquidationFiles: {
    LIQUIDATION: FileType | null;
    DEBT: FileType | null;
  };
  saveIdentityFile: (file: File | undefined, type: "front" | "back") => void;
  saveServicesFile: (file: File | undefined, idFile: number) => void;
  saveFile: (
    file: File | undefined,
    type: "LIQUIDATION" | "DEBT",
    id: number
  ) => void;
  identityFilesServices:
    | {
        file: string;
        id: number;
      }
    | undefined;
  saveFileService: (file: File | undefined, idFile: number, id: number) => void;
  urlNoFormated: Promise<string | undefined> | undefined;
  identityFilesCompleted: boolean;
  isNotMarried: boolean;
  isBankDataCompleted: boolean;
  profileHasChanged: boolean;
  urlFile?: { DEBT: string; LIQUIDATION: string };
  setUrlFile: React.Dispatch<
    React.SetStateAction<{
      DEBT: string;
      LIQUIDATION: string;
    }>
  >;
  urlFileService?: {
    LIQUIDATION1: string;
    LIQUIDATION2: string;
    LIQUIDATION3: string;
  };
  setUrlFileService: React.Dispatch<
    React.SetStateAction<{
      LIQUIDATION1: string;
      LIQUIDATION2: string;
      LIQUIDATION3: string;
    }>
  >;
  urlNoFormatedService: Promise<string | undefined> | undefined;
}
export enum ProfileSubpage {
  basicData = "BASIC_DATA",
  investData = "INVEST_DATA",
}

export enum ProfileDialog {
  FIRST_TIME_PROFILE_INVITE = "FIRST_TIME_PROFILE_INVITE",
}

interface FileType {
  file: File;
  path: string;
}
interface FilesType {
  front: FileType | null;
  back: FileType | null;
}

export const ProfileContext = createContext({} as ProfileContextType);

export const ProfileProvider: React.FC = ({ children }) => {
  const client = useApolloClient();
  const { isLoggedIn } = useAuth();
  const { showSnackbar } = useContext(SnackbarContext);
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const { user: currentUser } = useCurrentUser();

  const scrollToTop = useCallback(() => {
    window.scrollTo({ behavior: "smooth", top: 0 });
  }, []);
  const [profileSubpage, setProfileSubpage] = useState<ProfileSubpage>(
    ProfileSubpage.basicData
  );

  const [idFundSelected, setIdFundSelected] = useState<FundWithSeries>();

  // Profile Form Data Handling
  const [profileForm, setProfileForm] = useState<ProfileForm>(DEFAULT_FORM);
  const [profileHasChanged, setProfileHasChanged] = useState(false);
  const clearProfileForm = useCallback(
    () => setProfileForm(DEFAULT_FORM),
    [setProfileForm]
  );
  const recalculateInvestorProfile = useCallback(
    () =>
      setProfileForm((prev) => {
        if (prev.manuallySelectedInvestorProfile) return prev;
        const { profile } = calculateSuggestedProfile(prev.investmentTerm, {
          risk: prev.risk,
          investmentExperience: prev.investmentExperience,
          investmentConcern: prev.investmentConcern,
          currentInvesting: prev.currentInvesting,
          investmentDropResponse: prev.investmentDropResponse,
        });
        return { ...prev, investorProfile: profile ?? prev.investorProfile };
      }),
    [setProfileForm]
  );
  // Callback for update user info from profile page (inputs and selectors) with debounce
  const updateProfileForm = useCallback(
    (payload: Partial<ProfileForm>) => {
      setProfileForm((prev) => ({ ...prev, ...payload }));
      setProfileHasChanged(true);
      // Check if investor profile must   be recalculated
      const shouldBeRecalculated = Object.keys(payload).some((key) =>
        investorProfileRelevantFields.includes(key)
      );
      if (shouldBeRecalculated) {
        recalculateInvestorProfile();
      }
    },
    [setProfileForm, recalculateInvestorProfile, setProfileHasChanged]
  );

  // Save InvestType to display on Investor Profile page
  // (Find the greatest investType percentage for the current investor profile)
  // This modifies the term criteria of filters
  const { setCurrentFiltersWithoutScroll } = useContext(FiltersContext);
  const [investType, setInvestType] = useState<InvestType | null>(null);
  useEffect(() => {
    if (profileForm.investorProfile) {
      let finalType: InvestType | null = null;
      if (!investType) {
        const percentages = getAllocationPercentages(
          profileForm.investorProfile
        );
        let max = 0;
        Object.entries(percentages).forEach(([t, p]) => {
          if (p > max) {
            max = p;
            finalType = t as InvestType;
          }
        });
        setInvestType(finalType);
      } else {
        finalType = investType;
      }
      setCurrentFiltersWithoutScroll((prev) => ({
        ...prev,
        term: getDefaultTermFilter(profileForm.investorProfile, finalType),
      }));
    }
  }, [profileForm.investorProfile, investType, setCurrentFiltersWithoutScroll]);

  // Investor Profile Manual change dialog
  const [changeInvestorProfileDialogOpen, setInvestorProfileDialog] =
    useState<boolean>(false);
  const openInvestorProfileChangeDialog = useCallback(
    () => setInvestorProfileDialog(true),
    []
  );
  const closeInvestorProfileChangeDialog = useCallback(
    () => setInvestorProfileDialog(false),
    []
  );

  /* Callback to update user info to database */
  const saveUserInfo = useCallback(
    async (payload?: Partial<ProfileForm>, withSnackbar = true) => {
      try {
        const success = await updateUser(
          { ...profileForm, ...payload },
          false,
          client
        );
        if (!success) throw new Error("error_updating_user");
        setProfileHasChanged(false);
        withSnackbar && showSnackbar("Perfil guardado", "success");
      } catch (e) {
        console.error(e);
        showSnackbar("Ha ocurrido un error", "error");
      }
    },
    [client, profileForm, setProfileHasChanged, showSnackbar]
  );

  const recalculateProfileForm = useCallback(() => {
    if (!isLoggedIn) {
      clearProfileForm();
      return;
    }
    if (currentUser) {
      const newProfileForm = userToProfileForm(currentUser);
      setProfileForm((prev) =>
        isEqual(prev, newProfileForm) ? prev : newProfileForm
      );
    }
  }, [currentUser, isLoggedIn, clearProfileForm, setProfileForm]);

  const { loading: loadingToken } = getToken();
  useEffect(
    () =>
      loadingToken
        ? showLoader("[INV PAGE] Loading data")
        : hideLoader("[INV PAGE] Loading data"),
    [loadingToken]
  );

  // Update current form with data comming from DB
  useEffect(() => {
    recalculateProfileForm();
  }, [recalculateProfileForm]);

  const refetchCurrentUser = useCallback(() => {
    showLoader("loading user data", [
      Route.preselectionsSuggested,
      Route.investorProfile,
      Route.profile,
    ]);
    client.refetchQueries({ include: [GET_CURRENT_USER_QUERY] }).then(() => {
      hideLoader("loading user data");
    });
  }, [showLoader, hideLoader]);

  /* Favorites handling */
  const [isFavoriteFilterActive, setFavoriteFilter] = useState(false);
  const [favoriteSeries, setFavoritesSeries] = useState<number[]>([]);
  const handleFavoriteClick = useCallback(
    (investId: number) => {
      setFavoritesSeries((prev) =>
        prev.includes(investId)
          ? prev.filter((id) => id !== investId)
          : [...prev, investId]
      );
    },
    [setFavoritesSeries]
  );
  const toggleFavoriteFilter = useCallback(() => {
    setFavoriteFilter((prev) => !prev);
    scrollToTop();
  }, [setFavoriteFilter, scrollToTop]);

  const [investorProfileCompleted, setInvestorProfileCompleted] =
    useState(false);
  // Calculate if investor profile is completed
  useEffect(() => {
    const { completed } = calculateSuggestedProfile(
      profileForm.investmentTerm,
      {
        risk: profileForm.risk,
        investmentExperience: profileForm.investmentExperience,
        investmentConcern: profileForm.investmentConcern,
        currentInvesting: profileForm.currentInvesting,
        investmentDropResponse: profileForm.investmentDropResponse,
      }
    );
    setInvestorProfileCompleted(completed);
  }, [
    profileForm.investmentTerm,
    profileForm.risk,
    profileForm.investmentExperience,
    profileForm.investmentConcern,
    profileForm.currentInvesting,
    profileForm.investmentDropResponse,
  ]);

  /* FILES HANDLING */
  const [identityFiles, setIdentityFiles] = useState<FilesType>({
    front: null,
    back: null,
  });
  const [identityFilesServices, setIdentityFilesServices] = useState<{
    file: string;
    id: number;
  }>();
  /* LIQUIDATION AND DEBT FILES */
  const [liquidationFiles, setLiquidationFiles] = useState<{
    LIQUIDATION: FileType | null;
    DEBT: FileType | null;
    SERVICE_LIQUIDATION: FileType | null;
  }>({
    LIQUIDATION: null,
    DEBT: null,
    SERVICE_LIQUIDATION: null,
  });

  const saveIdentityFile = useCallback(
    (file: File | undefined, type: "front" | "back") => {
      const fileType =
        type === "front"
          ? FileSpecificType.IDENTITY_FRONT
          : FileSpecificType.IDENTITY_BACK;
      setIdentityFiles((prev) => ({
        ...prev,
        [type]: file
          ? {
              file,
              path: URL.createObjectURL(file),
            }
          : null,
      }));
      if (file) {
        uploadIdentityFile(file, fileType, client);
      } else {
        saveUserFile(fileType, "", client);
      }
    },
    [client, setIdentityFiles]
  );
  const saveServicesFile = useCallback(
    (file: File | undefined, idFile: number) => {
      setIdentityFilesServices((prev) => ({
        ...prev,
        file: file ? URL.createObjectURL(file) : "",
        id: idFile,
      }));
      if (file) {
        uploadIdentityFile(file, FileSpecificType.SERVICE_LIQUIDATION, client);
      } else {
        saveUserFile(FileSpecificType.SERVICE_LIQUIDATION, "", client);
      }
    },
    [client, setIdentityFiles]
  );
  const [urlFile, setUrlFile] = useState<{
    DEBT: string;
    LIQUIDATION: string;
  }>({
    DEBT: "",
    LIQUIDATION: "",
  });

  const [urlFileService, setUrlFileService] = useState<{
    LIQUIDATION1: string;
    LIQUIDATION2: string;
    LIQUIDATION3: string;
  }>({
    LIQUIDATION1: "",
    LIQUIDATION2: "",
    LIQUIDATION3: "",
  });

  const [urlNoFormated, setUrlNoFormated] =
    useState<Promise<string | undefined>>();
  const [urlNoFormatedService, setUrlNoFormatedService] =
    useState<Promise<string | undefined>>();
  const saveFile = useCallback(
    async (
      file: File | undefined,
      type: "LIQUIDATION" | "DEBT",
      id: number
    ) => {
      const fileType =
        type === "LIQUIDATION"
          ? FileSpecificType.LIQUIDATION
          : FileSpecificType.DEBT;

      setLiquidationFiles((prev) => ({
        ...prev,
        [type]: file
          ? {
              file,
              path: URL.createObjectURL(file),
            }
          : null,
      }));
      const valuePromise = filesUpload(file, fileType, client, id);
      setUrlNoFormated(valuePromise);
      type === FileSpecificType.LIQUIDATION
        ? valuePromise?.then((res) => {
            res &&
              setUrlFile((prev) => ({
                ...prev,
                LIQUIDATION: res,
              }));
          })
        : valuePromise?.then((res) => {
            res &&
              setUrlFile((prev) => ({
                ...prev,
                DEBT: res,
              }));
          });
    },
    [client, setLiquidationFiles, urlFile, setUrlFile, urlNoFormated]
  );

  const saveFileService = useCallback(
    async (file: File | undefined, idFile: number, id: number) => {
      const fileType = FileSpecificType.SERVICE_LIQUIDATION;

      setIdentityFilesServices((prev) => ({
        ...prev,
        file: file ? URL.createObjectURL(file) : "",
        id: idFile,
      }));
      const valuePromise = filesUpload(file, fileType, client, id, idFile);
      setUrlNoFormatedService(valuePromise);
      valuePromise?.then((res) => {
        res &&
          setUrlFileService((prev) => ({
            ...prev,
            SERVICE_LIQUIDATION: res,
          }));
      });
    },
    [
      client,
      setIdentityFilesServices,
      urlFileService,
      setUrlFileService,
      setUrlNoFormatedService,
      urlNoFormatedService,
    ]
  );

  const context = useMemo<ProfileContextType>(
    () => ({
      profileFormFilled: !isEqual(profileForm, DEFAULT_FORM),
      profileForm,
      updateProfileForm, // For updating form
      saveUserInfo, // For sending the mutation to DB
      clearProfileForm,
      recalculateProfileForm,
      refetchCurrentUser,
      isFavoriteFilterActive,
      favoriteSeries,
      handleFavoriteClick,
      toggleFavoriteFilter,
      investType,
      setInvestType,
      openInvestorProfileChangeDialog,
      closeInvestorProfileChangeDialog,
      investorProfileCompleted,
      profileSubpage,
      setProfileSubpage,
      idFundSelected,
      setIdFundSelected,
      isFirstPersonalDataCompleted: isFirstPersonalDataCompleted(profileForm),
      isSecondPersonalDataCompleted: isSecondPersonalDataCompleted(profileForm),
      isSpouseDataCompleted:
        profileForm.isSpouseDataCompleted || isSpouseDataCompleted(profileForm),
      isSpecialDataCompleted:
        profileForm.isSpecialDataCompleted ||
        isSpecialDataCompleted(profileForm),
      isInvestDataCompleted: isInvestDataCompleted(profileForm),
      isOnboardingCompleted: profileForm.isOnboardingCompleted,
      identityFiles,
      liquidationFiles,
      identityFilesServices,
      saveIdentityFile,
      saveServicesFile,
      identityFilesCompleted: !!identityFiles.front && !!identityFiles.back,
      isNotMarried: profileForm.maritalState !== MARITAL_STATE_OPTIONS[1].name,
      isBankDataCompleted: isBankDataCompleted(profileForm),
      profileHasChanged,
      saveFile,
      saveFileService,
      urlFile,
      setUrlFile,
      urlNoFormated,
      setUrlFileService,
      urlFileService,
      urlNoFormatedService,
    }),
    [
      profileForm,
      updateProfileForm,
      saveUserInfo,
      clearProfileForm,
      recalculateProfileForm,
      refetchCurrentUser,
      isFavoriteFilterActive,
      favoriteSeries,
      handleFavoriteClick,
      toggleFavoriteFilter,
      investType,
      setInvestType,
      openInvestorProfileChangeDialog,
      closeInvestorProfileChangeDialog,
      investorProfileCompleted,
      profileSubpage,
      idFundSelected,
      setProfileSubpage,
      setIdFundSelected,
      idFundSelected,
      identityFiles,
      liquidationFiles,
      identityFilesServices,
      saveIdentityFile,
      saveServicesFile,
      profileHasChanged,
      saveFile,
      saveFileService,
      urlFile,
      setUrlFile,
      urlNoFormated,
      setUrlFileService,
      urlFileService,
      urlNoFormatedService,
    ]
  );

  return (
    <ProfileContext.Provider value={context}>
      <>
        {children}
        <ChangeInvestorProfileDialog
          open={changeInvestorProfileDialogOpen}
          onClose={closeInvestorProfileChangeDialog}
        />
      </>
    </ProfileContext.Provider>
  );
};

const firstPersonalDataFields: (keyof ProfileForm)[] = [
  "name",
  "paternalSurname",
  "maternalSurname",
  "birthdate",
  "rut",
  "nationality",
  "gender",
  /* "maritalState",
  "address",
  "phone",
  "fundsSource", */
];
const secondPersonalDataFields: (keyof ProfileForm)[] = [
  "jobType",
  // "workPosition", // Validation depending on jobType
  // "profession", // Validation depending on jobType
  "maritalState",
  "address",
  "phone",
];

// Validation depending on maritalState
const spousePersonalDataFields: (keyof ProfileForm)[] = [
  "spouseName",
  "spousePaternalSurname",
  "spouseMaternalSurname",
  "spouseRut",
  "spouseBirthdate",
  "spouseNationality",
  "spouseAddress",
  "spouseGender",
];
const specialDataFields: (keyof ProfileForm)[] = [
  "isUSperson",
  "isPEP",
  "isQualifiedInvestor",
  "hasAGFrelation",
  "hasEnterpriseRelation",
];
const isFirstPersonalDataCompleted = (form: ProfileForm): boolean =>
  firstPersonalDataFields.every((attribute) => !!form[attribute]);

const isSecondPersonalDataCompleted = (form: ProfileForm): boolean =>
  secondPersonalDataFields.every((attribute) => !!form[attribute]) &&
  (form.maritalState !== MARITAL_STATE_OPTIONS[1].name ||
    !!form.maritalPropertyRegime) &&
  (form.jobType !== JOB_TYPE_OPTIONS[0].value ||
    (!!form.workPosition && !!form.employerName));

const isSpouseDataCompleted = (form: ProfileForm): boolean =>
  form.maritalState !== MARITAL_STATE_OPTIONS[1].name ||
  spousePersonalDataFields.every((attribute) => !!form[attribute]);

const isSpecialDataCompleted = (form: ProfileForm): boolean =>
  specialDataFields.every((attribute) => form[attribute] !== null) &&
  form.agreement;

const isBankDataCompleted = (form: ProfileForm): boolean =>
  (["bank", "accountType", "accountNumber"] as (keyof ProfileForm)[]).every(
    (attribute) => !!form[attribute]
  );

const isInvestDataCompleted = (form: ProfileForm): boolean => {
  const personalDataCompleted =
    isFirstPersonalDataCompleted(form) && isSecondPersonalDataCompleted;
  const bankDataCompleted = isBankDataCompleted(form);
  const identityDocumentedValidated = true;
  const contractSigned = true;
  return (
    personalDataCompleted &&
    bankDataCompleted &&
    identityDocumentedValidated &&
    contractSigned
  );
};
