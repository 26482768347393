import React from "react";
import { useStyles } from "./MoneyBreakdown.style";
import IncomeCard from "../IncomeCard/IncomeCard";

interface MoneyBreakdownProps {
  data: any;
}

export const MoneyBreakdown: React.FC<MoneyBreakdownProps> = ({ data }) => {
  const classes = useStyles();

  const income = () => {
    const filtered = {};
    if (data.income !== null && data.income !== undefined) {
      if (Object.keys(data.income).length > 0) {
        const entries = [];
        Object.keys(data.income).filter((prop) => {
          if (prop !== "total") {
            filtered[prop] = data.income[prop];
          }
        });
        Object.values(filtered).forEach((element) => {
          const filteredElement = {};
          Object.keys(element).filter((prop) => {
            if (prop !== "total") {
              filteredElement[prop] = element[prop];
            }
          });
          Object.values(filteredElement).forEach((element) => {
            element.forEach((ele) => {
              entries.push(ele);
            });
          });
        });
        return entries;
      } else {
        return filtered;
      }
    } else {
      return filtered;
    }
  };

  const expense = () => {
    const filtered = {};

    if (data.expenses !== null && data.expenses !== undefined) {
      if (Object.keys(data.expenses).length > 0) {
        const entries = [];
        Object.keys(data.expenses).filter((prop) => {
          if (prop !== "total") {
            filtered[prop] = data.expenses[prop];
          }
        });
        Object.values(filtered).forEach((element) => {
          const filteredElement = {};
          Object.keys(element).filter((prop) => {
            if (prop !== "total") {
              filteredElement[prop] = element[prop];
            }
          });
          Object.values(filteredElement).forEach((element) => {
            element.forEach((entrie) => {
              if (entrie !== null && entrie?.total !== 0) {
                entries.push(entrie);
              }
            });
          });
        });
        return entries;
      } else {
        return filtered;
      }
    } else {
      return filtered;
    }
  };

  return (
    <div className={`${classes.leftSectionWrapper} leftSectionWrapper`}>
      <div className={classes.leftSection}>
        <div className={classes.sectionText}>
          Tus ingresos mensuales
          <span>Total: $ {data.income?.total?.toLocaleString("es") ?? 0}</span>
        </div>
        <div className={classes.infoBox}>
          {Object.values(income()).map((item) => {
            const porcentage = (
              ((item.average ? item.average : item.amount) /
                data.income?.total) *
              100
            ).toFixed(1);
            const valuePorcentage =
              porcentage !== null &&
              !isNaN(+porcentage) &&
              porcentage !== "Infinity" &&
              porcentage
                ? Number(porcentage)
                : 0;
            return (
              <IncomeCard
                key={item.name}
                name={item.name}
                amount={item.average ? item.average : item.amount}
                total={data.income?.total}
                percentage={valuePorcentage}
                type={"income"}
              />
            );
          })}
        </div>
      </div>
      <div className={classes.leftSection}>
        <div className={classes.sectionText}>
          Tus gastos mensuales
          <span>Total: ${data.expenses?.total?.toLocaleString("es") ?? 0}</span>
          <span style={{ fontWeight: "normal" }}>
            Tus gastos totales están divididos de la siguiente forma:
          </span>
        </div>
        <div className={classes.infoBox}>
          {Object.values(expense()).map((item) => {
            const porcentage = (
              (item.total / data.expenses?.total) *
              100
            ).toFixed(1);
            const valuePorcentage =
              porcentage !== null &&
              !isNaN(+porcentage) &&
              porcentage !== "Infinity" &&
              porcentage
                ? Number(porcentage)
                : 0;
            return (
              <IncomeCard
                key={item.name}
                name={item.name}
                amount={item.total}
                total={data.expenses?.total}
                percentage={valuePorcentage}
                type={"expense"}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
