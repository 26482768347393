export enum FundType {
  INVESTMENT = "FondoInversion",
  MUTUAL = "FondoMutuo",
  STOCKS = "Acciones",
  INTERNATIONAL = "FondoInternacional",
  ETF = "ETF",
}

export const TRANSLATE_FUND_TYPE: Record<FundType, string> = {
  [FundType.INVESTMENT]: "Fondo de Inversión",
  [FundType.MUTUAL]: "Fondo Mutuo",
  [FundType.STOCKS]: "Acciones",
  [FundType.INTERNATIONAL]: "Fondo Internacional",
  [FundType.ETF]: "ETF",
};

export enum ComparatorSelectableAssetType {
  NATIONAL = "NATIONAL",
  INTERNATIONAL = "INTERNATIONAL",
  ETF = "ETF",
  STOCK = "STOCK",
  PRODUCT_BAGS = "PRODUCT_BAGS",
}

export const TRANSLATE_ASSET_TYPE: Record<
  ComparatorSelectableAssetType,
  string
> = {
  [ComparatorSelectableAssetType.NATIONAL]: "Fondos nacionales",
  [ComparatorSelectableAssetType.INTERNATIONAL]: "Fondos internacionales",
  [ComparatorSelectableAssetType.ETF]: "ETF's",
  [ComparatorSelectableAssetType.STOCK]: "Acciones",
  [ComparatorSelectableAssetType.PRODUCT_BAGS]: "Bolsas de producto",
};
