const BUDGET_ICON_LIGHT_BLUE =
  "https://rokinapp-images.s3.amazonaws.com/rokipedia/lightBlue/presupuesto_icon_1.svg";

const BUDGET_ICON_WHITE =
  "https://rokinapp-images.s3.amazonaws.com/rokipedia/presupuesto_icon.svg";

const PROFILE_ICON_WHITE =
  "https://rokinapp-images.s3.amazonaws.com/rokipedia/perfil_icon.svg";

const RETIREMENT_ICON_WHITE =
  "https://rokinapp-images.s3.amazonaws.com/rokipedia/jubilacion_icon.svg";

const RETIREMENT_ICON_LIGHT_BLUE =
  "https://rokinapp-images.s3.amazonaws.com/rokipedia/lightBlue/jubilacion_icon_1.svg";

const CREDITS_AND_DEBTS_ICON_WHITE =
  "https://rokinapp-images.s3.amazonaws.com/rokipedia/creditosYDeudas_icon.svg";

const CREDITS_AND_DEBTS_ICON_LIGHT_BLUE =
  "https://rokinapp-images.s3.amazonaws.com/rokipedia/lightBlue/creditoDeudas_icon.svg";

const SAVINGS_AND_INVESTMENTS_ICON_WHITE =
  "https://rokinapp-images.s3.amazonaws.com/rokipedia/ahorroInversoin_icon.svg";

const SAVINGS_AND_INVESTMENTS_ICON_LIGHT_BLUE =
  "https://rokinapp-images.s3.amazonaws.com/rokipedia/lightBlue/ahorroeInversion_icon.svg";

const ALLIANCES_LIGHT_BLUE =
  "https://rokinapp-images.s3.amazonaws.com/rokipedia/alianzas_icon.svg";
const LABEL_LIGHT_BLUE =
  "https://rokinapp-images.s3.amazonaws.com/rokipedia/rokipedia_icon_gris.svg";
const ROKS_LIGHT_BLUE =
  "https://rokinapp-images.s3.amazonaws.com/rokipedia/roks_icon+(1).svg";
const TOOLS_LIGHT_BLUE =
  "https://rokinapp-images.s3.amazonaws.com/rokipedia/tools_icon.svg";
const TOOLS_LIGHT_BLUE_HOLLOW =
  "https://rokinapp-images.s3.amazonaws.com/rokipedia/tools_icon_1.svg";

export {
  BUDGET_ICON_WHITE,
  BUDGET_ICON_LIGHT_BLUE,
  PROFILE_ICON_WHITE,
  RETIREMENT_ICON_WHITE,
  RETIREMENT_ICON_LIGHT_BLUE,
  CREDITS_AND_DEBTS_ICON_WHITE,
  CREDITS_AND_DEBTS_ICON_LIGHT_BLUE,
  SAVINGS_AND_INVESTMENTS_ICON_WHITE,
  SAVINGS_AND_INVESTMENTS_ICON_LIGHT_BLUE,
  ALLIANCES_LIGHT_BLUE,
  LABEL_LIGHT_BLUE,
  ROKS_LIGHT_BLUE,
  TOOLS_LIGHT_BLUE,
  TOOLS_LIGHT_BLUE_HOLLOW,
};
