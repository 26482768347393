import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  sideMenuContainer: {
    width: "21rem",
    position: "relative",
    marginTop: "-4rem",
    paddingTop: "4rem",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    borderRadius: "1.875rem",
    backgroundColor: "rgba(240, 240, 240, 0.95)",
    boxShadow: "0.175rem 0.2875rem 0.4625rem 0 rgba(0, 0, 0, 0.25)",
    alignItems: "center",
  },
  menuHeader: {
    width: "100%",
    textAlign: "center",
  },
  menuHeaderTitle: {
    margin: "7.5rem auto 4.4rem",
    fontFamily: "Montserrat",
    fontSize: "1.75rem",
    fontWeight: "bold",
    lineHeight: 1.57,
    color: theme.palette.text.primary,
  },
  menuBody: {
    display: "flex",
    flexDirection: "column",
  },
  menuItemDisabled: {
    opacity: "0.3",
  },
  menuItem: {
    width: "14.813rem",
    height: "2.375rem",
    padding: "0.3rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    borderRadius: "0.625rem",
    "& img": {
      marginLeft: "0.5rem",
    },
  },
  menuItemTitle: {
    margin: "0 0 0 1.5rem",
    color: theme.palette.text.primary,
    fontSize: "0.875rem",
  },
  menuItemActive: {
    backgroundColor: "#F4F3F3",
  },
  separator: {
    width: "14rem",
    height: "0.06rem",
    margin: "0.9rem auto",
    backgroundColor: "#9d9d9d",
  },
}));
