import React from "react";
import { GoalForm, OnChangeType } from "@interfaces";
import { FORM_FIELDS } from "@interfaces";
import { NameInput } from "@components/Inputs";
import "./Step1.scss";

interface Step1Props {
  stepTitle: string;
  form: GoalForm;
  include?: typeof FORM_FIELDS[number][];
  handleChange: OnChangeType;
}
export const Step1: React.FC<Step1Props> = ({
  stepTitle,
  form,
  include = FORM_FIELDS,
  // formErrors,
  handleChange,
}) => {
  return (
    <div className="step goal-step-1">
      <span className="title">{stepTitle}</span>
      <span className="label">
        Nombre objetivo
        <NameInput value={form.name} onChange={handleChange} label="" />
      </span>
    </div>
  );
};
