export const AFP_TYPE_OPTIONS = [
  "Capital",
  "Coprum",
  "Habitat",
  "Modelo",
  "Panvital",
  "Provida",
  "Uno",
  "No se mi Afp"
].map(g => ({
  name: g,
  value: g,
}));
