import React from "react";

import { useMediaQuery } from "@material-ui/core";
import { LayoutOnBoarding, SEO } from "@components";

import { useAuth } from "@hooks";
import { useStyles } from "./MyProfileDesktop.style";
import MyProfileSideMenu from "../SideMenu/SideMenu";
import GeneralPersonalData from "../PersonalData/GeneralPersonalData";

type MyProfileProps = {
  currentUser: any;
};

const MyProfileDesktop = ({ currentUser }: MyProfileProps) => {
  useAuth({ redirectIfNotLogged: true });
  const classes = useStyles();
  // 0 for personal data
  // 1 for notifications
  // 2 for acount settings
  // 3 for help center
  // 4 for logout
  const [currentView, setCurrentView] = React.useState(0);

  return (
    <>
      <LayoutOnBoarding activeInvest={false}>
        <div className={classes.background} />
        <div className={classes.itemsContainer}>
          <div className={classes.leftSide}>
            <MyProfileSideMenu
              currentView={currentView}
              setCurrentView={setCurrentView}
            />
          </div>
          <div className={classes.rightSide}>
            {currentView === 0 && (
              <GeneralPersonalData currentUser={currentUser} />
            )}
            {currentView === 1 && <h1>vista notificaciones</h1>}
            {currentView === 2 && <h1>vista ajustes</h1>}
            {currentView === 3 && <h1>vista centro de ayuda</h1>}
          </div>
        </div>
      </LayoutOnBoarding>
    </>
  );
};

export default MyProfileDesktop;
