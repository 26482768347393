const CHILE_BANK =
  "https://storage.googleapis.com/rokinapp-images/MyFinances/bankImages/BChile_activo.svg";
const STATE_BANK =
  "https://storage.googleapis.com/rokinapp-images/MyFinances/bankImages/BEstado_activo.svg";
const SANTANDER_BANK =
  "https://storage.googleapis.com/rokinapp-images/MyFinances/bankImages/Santander_activo.svg";
const SCOTIA_BANK =
  "https://storage.googleapis.com/rokinapp-images/MyFinances/bankImages/Scotiabank_activo.svg";
const FALLABELLA_BANK =
  "https://storage.googleapis.com/rokinapp-images/MyFinances/bankImages/Falabella_activo.svg";
const ITAU_BANK =
  "https://storage.googleapis.com/rokinapp-images/MyFinances/bankImages/Itau_activo.svg";
const RIPLEY_BANK =
  "https://storage.googleapis.com/rokinapp-images/MyFinances/bankImages/Ripley_activo.svg";
const BCI_BANK =
  "https://storage.googleapis.com/rokinapp-images/MyFinances/bankImages/BCI_activo.svg";
const MACH_BANK =
  "https://storage.googleapis.com/rokinapp-images/MyFinances/bankImages/Mach_activo.svg";
const CONSORCIO_BANK =
  "https://storage.googleapis.com/rokinapp-images/MyFinances/bankImages/Consorcio_activo.svg";
const BICE_BANK =
  "https://storage.googleapis.com/rokinapp-images/MyFinances/bankImages/Bice_activo.svg";
const TENPO_BANK =
  "https://storage.googleapis.com/rokinapp-images/MyFinances/bankImages/Tenpo_activo.svg";
const WALLET_BANK =
  "https://storage.googleapis.com/rokinapp-images/MyFinances/bankImages/Wallet_activo.svg";
const WALLET_ICON =
  "https://storage.googleapis.com/rokinapp-images/MyFinances/bankImages/icons/wallet_icon.svg";
const BICE_ICON =
  "https://storage.googleapis.com/rokinapp-images/MyFinances/bankImages/icons/BBice_icon.svg";
const CONSORCIO_ICON =
  "https://storage.googleapis.com/rokinapp-images/MyFinances/bankImages/icons/Consorcio_icon.svg";
const MACH_ICON =
  "https://storage.googleapis.com/rokinapp-images/MyFinances/bankImages/icons/Mach_icon.svg";
const TENPO_ICON =
  "https://storage.googleapis.com/rokinapp-images/MyFinances/bankImages/icons/Tenpo_icon.svg";
const BCI_BANK_ICON =
  "https://storage.googleapis.com/rokinapp-images/myAccount/onlyBankIcon/BCI_logoSquare.svg";
const ITAU_BANK_ICON =
  "https://storage.googleapis.com/rokinapp-images/myAccount/onlyBankIcon/bancoItau_logoSquare.svg";
const RIPLEY_BANK_ICON =
  "https://storage.googleapis.com/rokinapp-images/myAccount/onlyBankIcon/bancoRipley_logoSquare.svg";
const CHILE_BANK_ICON =
  "https://storage.googleapis.com/rokinapp-images/myAccount/onlyBankIcon/BancoDeChile_logo%20Square.svg";
const CHILE_BANK_ICON_NO_BACKGROUND =
  "https://rokinapp-images.s3.amazonaws.com/myAccount/noBackground/BancoDeChile_sin+fondo.png";
const STATE_BANK_ICON =
  "https://storage.googleapis.com/rokinapp-images/myAccount/onlyBankIcon/bancoEstado_logoSquare.svg";
const SANTANDER_BANK_ICON =
  "https://storage.googleapis.com/rokinapp-images/myAccount/onlyBankIcon/santander_logoSquare.svg";
const SANTANDER_BANK_ICON_NO_BACKGROUND =
  "https://rokinapp-images.s3.amazonaws.com/myAccount/noBackground/santander_sinFondo.png";
const SCOTIA_BANK_ICON =
  "https://storage.googleapis.com/rokinapp-images/myAccount/onlyBankIcon/scotiabank_logoSquare.svg";
const SCOTIA_BANK_ICON_NO_BACKGROUND =
  "https://rokinapp-images.s3.amazonaws.com/myAccount/noBackground/scotiabank_sinFondo.png";
const FALLABELLA_BANK_ICON =
  "https://storage.googleapis.com/rokinapp-images/myAccount/onlyBankIcon/falabella_logoSquare.svg";
const FALLABELLA_BANK_ICON_NO_BACKGROUND =
  "https://rokinapp-images.s3.amazonaws.com/myAccount/noBackground/falabella_sinFondo.png";
const FINERIO_ICON =
  "https://storage.googleapis.com/rokinapp-images/icons/apibaLogo.svg";

const ALERT_ICON =
  "https://rokinapp-images.s3.amazonaws.com/myAccount/alert_icon.svg";
const CONNECTION_ICON =
  "https://rokinapp-images.s3.amazonaws.com/myAccount/connectionError_icon.svg";

const SUCCESSFUL =
  "https://rokinapp-images.s3.amazonaws.com/myAccount/Felicidades+2+2.svg";

export {
  SUCCESSFUL,
  CONNECTION_ICON,
  ALERT_ICON,
  CHILE_BANK,
  STATE_BANK,
  SCOTIA_BANK,
  SANTANDER_BANK,
  FALLABELLA_BANK,
  FINERIO_ICON,
  CHILE_BANK_ICON,
  STATE_BANK_ICON,
  SCOTIA_BANK_ICON,
  SANTANDER_BANK_ICON,
  FALLABELLA_BANK_ICON,
  CHILE_BANK_ICON_NO_BACKGROUND,
  FALLABELLA_BANK_ICON_NO_BACKGROUND,
  SANTANDER_BANK_ICON_NO_BACKGROUND,
  SCOTIA_BANK_ICON_NO_BACKGROUND,
  ITAU_BANK,
  BCI_BANK,
  RIPLEY_BANK,
  BCI_BANK_ICON,
  ITAU_BANK_ICON,
  RIPLEY_BANK_ICON,
  MACH_BANK,
  CONSORCIO_BANK,
  BICE_BANK,
  TENPO_BANK,
  WALLET_ICON,
  WALLET_BANK,
  BICE_ICON,
  CONSORCIO_ICON,
  MACH_ICON,
  TENPO_ICON,
};
