import React from 'react';
import { makeStyles, Theme, IconButton } from '@material-ui/core';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import { numberMission, home } from 'images/onBoarding';
import { navigate } from 'gatsby';
import { Route } from '@interfaces';
import { USER_ICON } from 'images/GoogleCloud';

interface LayoutNotMissionProps {
  children: React.ReactNode;
  className?: string;
  styleFooter?: string;
  title: string;
  subtitle?: string;
  step?: number;
  handleBack: () => void;
}

export const LayoutNotMissionMobile: React.FC<LayoutNotMissionProps> = ({
  className,
  children,
  title,
  subtitle,
  step,
  handleBack,
}) => {
  const classes = useStyles();
  return (
    <div className={`${className || ''} ${classes.container}`}>
      <header className={classes.header}>
        <IconButton onClick={handleBack}>
          <ArrowBackIosRoundedIcon className={classes.goBackIcon} />
        </IconButton>
        <div className={classes.titles}>
          <div className={classes.title}>
            <img src={USER_ICON} className={classes.icon} />
            COMPLETAR PERFIL
          </div>
          <div className={classes.steps}>
            <div className={classes.step}>
              {step > 0 ? (
                <div className={classes.numberSelectedPass}>1</div>
              ) : step == 0 ? (
                <div className={classes.numberSelected}>1</div>
              ) : (
                <div className={classes.number}>1</div>
              )}
            </div>
            {step > 0 ? (
              <div className={classes.linePass}></div>
            ) : (
              <div className={classes.line}></div>
            )}
            <div className={classes.step}>
              {step > 1 ? (
                <div className={classes.numberSelectedPass}>2</div>
              ) : step == 1 ? (
                <div className={classes.numberSelected}>2</div>
              ) : (
                <div className={classes.number}>2</div>
              )}
            </div>
            {step > 1 ? (
              <div className={classes.linePass}></div>
            ) : (
              <div className={classes.line}></div>
            )}
            <div className={classes.step}>
              {step > 2 ? (
                <div className={classes.numberSelectedPass}>3</div>
              ) : step == 2 ? (
                <div className={classes.numberSelected}>3</div>
              ) : (
                <div className={classes.number}>3</div>
              )}
            </div>
            {step > 2 ? (
              <div className={classes.linePass}></div>
            ) : (
              <div className={classes.line}></div>
            )}
            <div className={classes.step}>
              {step > 3 ? (
                <div className={classes.numberSelectedPass}>4</div>
              ) : step == 3 ? (
                <div className={classes.numberSelected}>4</div>
              ) : (
                <div className={classes.number}>4</div>
              )}
            </div>
          </div>

          <div className={classes.subtitle}>{title}</div>
        </div>
      </header>
      <div className={classes.children}>{children}</div>
    </div>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    overflowX: 'hidden',
    background: '#EAEAEA',
    height: '100vh',
    '& footer': {
      marginTop: '2rem',
    },
  },
  children: {
    marginTop: '-4rem',
  },
  header: {
    display: 'grid',
    justifyContent: 'center',
    height: '14rem',
    width: '100%',
    backgroundColor: '#00323C',
    '& span': {
      textTransform: 'inherit',
    },
  },
  titles: {
    display: 'grid',
    justifyContent: 'center',
    backgroundColor: '#00323C',
    marginTop: '-4rem',
    '& span': {
      textTransform: 'inherit',
    },
  },
  title: {
    color: '#FFFFFF',
    fontFamily: 'Montserrat',
    fontSize: '0.875rem',
    textAlign: 'center',
    display: 'flex',
  },
  icon: {
    width: '1.5rem',
    height: '1.5rem',
    marginTop: '-0.1rem',
    marginLeft: '1.2rem',
  },
  img: {
    width: 30,
    marginRight: 20,
  },
  goBackIcon: {
    color: '#FFFFFF',
    width: '1.375rem',
    height: '1.375rem',
    margin: '0 20rem 0 0',
  },
  subtitle: {
    margin: '15px 10px 40px 10px',
    fontFamily: 'Montserrat',
    fontWeight: 'normal',
    fontSize: '0.875rem',
    color: '#FFFFFF',
    textAlign: 'center',
  },
  steps: {
    display: 'flex',
    width: '13.4rem',
    alignItems: 'center',
  },
  step: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  number: {
    width: '1.3rem',
    height: '1.3rem',
    borderRadius: '50%',
    backgroundColor: '#0A5A64',
    border: '1px solid #00A9C7',
    color: '#FFFFFF',
    fontFamily: 'Montserrat',
    fontSize: '0.625rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  numberSelected: {
    width: '1.3rem',
    height: '1.3rem',
    borderRadius: '50%',
    backgroundColor: '##00323C',
    border: '1px solid #00A9C7',
    color: '#FFFFFF',
    fontFamily: 'Montserrat',
    fontSize: '0.625rem',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  numberSelectedPass: {
    width: '1.3rem',
    height: '1.3rem',
    borderRadius: '50%',
    backgroundColor: '#00A9C7',
    border: '1px solid #00A9C7',
    color: '#00323C',
    fontFamily: 'Montserrat',
    fontSize: '0.625rem',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  line: {
    width: '100%',
    height: '1px',
    backgroundColor: '#001E28',
  },
  linePass: {
    width: '100%',
    height: '1px',
    backgroundColor: '#00a9C7',
  },
}));
