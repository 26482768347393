import { round } from "lodash";

export const parseNumber = (number: number, min?: number, max?: number) => {
  if (!number) return 0;
  let parsenEnglish;
  if (min === undefined && max === undefined)
    parsenEnglish = number.toLocaleString("en", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  else {
    parsenEnglish = number.toLocaleString("en", {
      ...(max !== undefined ? { minimumFractionDigits: min } : {}),
      ...(max !== undefined ? { maximumFractionDigits: max } : {}),
    });
  }
  return parsenEnglish
    .replace(/\./g, "_")
    .replace(/,/g, ".")
    .replace(/_/g, ",");
};

export const formatMillions = (value: number) =>
  `$${parseNumber(value / 1000000, 2, 2)}MM`;

export const format0decimals = (value: number) =>
  "$" + String(parseNumber(value, 0, 0));

export const format1decimals = (value: number) =>
  "$" + String(parseNumber(value, 1, 1));

export const format2decimals = (value: number) =>
  "$" + String(parseNumber(value, 2, 2));

export const parseSerieCost = (cost?: number | null): string =>
  cost === null || cost === undefined ? "Sin info." : round(cost, 2) + "%";
