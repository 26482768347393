import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Switch,
} from "@material-ui/core";
import React, { Dispatch, SetStateAction, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useStyles } from "./filters.style";
import {
  REMINDER_ICON_WB,
  RECURRENT_EXPENSES_ICON_WB,
  MOVEMENT_ICON,
  RECURRENT_INCOME_ICON_WB,
} from "@images";
import { ButtonPrimary } from "@components/Reusables";

type Props = {
  showIncomes: boolean;
  setShowIncomes: (value: boolean) => void;
  showExpenses: boolean;
  setShowExpenses: (value: boolean) => void;
  showTransfers: boolean;
  setShowTransfers: (value: boolean) => void;
  showReminders: boolean;
  setShowReminders: (value: boolean) => void;
  showEstate: boolean;
  setShowEstate: (value: boolean) => void;
  showMyProducts: boolean;
  setShowMyProducts: (value: boolean) => void;
  setCurrentView: Dispatch<SetStateAction<string>>;
};

const CalendarFilter: React.FC<Props> = ({
  showIncomes,
  setShowIncomes,
  showExpenses,
  setShowExpenses,
  showTransfers,
  setShowTransfers,
  showReminders,
  setShowReminders,
  showEstate,
  setShowEstate,
  showMyProducts,
  setShowMyProducts,
  setCurrentView,
}) => {
  const [remindersOpen, setRemindersOpen] = useState(false);
  const classes = useStyles({ open: remindersOpen });

  return (
    <>
      <div className={classes.eventContainer}>
        {/**<Accordion elevation={0} className={classes.transparentAccordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >*/}

        {/**</div></AccordionSummary>
        <AccordionDetails>*/}
        <div className={classes.detailsContainer}>
          <span className={classes.title}>EVENTOS</span>
          <div className={classes.whiteBox}>
            <img src={RECURRENT_INCOME_ICON_WB} alt="" />
            <span
              className="category"
              onClick={() => {
                setCurrentView("recurrentIncomes");
              }}
            >
              Ingresos recurrentes
            </span>
            {/* <Switch
                onChange={() => {
                  setShowIncomes(!showIncomes);
                }}
              /> */}
          </div>
          <div className={classes.whiteBox}>
            <img src={RECURRENT_EXPENSES_ICON_WB} alt="" />
            <span
              className="category"
              onClick={() => {
                setCurrentView("recurrentPayments");
              }}
            >
              Gastos y pagos recurrentes
            </span>
            {/* <Switch
                value={showExpenses}
                onChange={() => {
                  setShowExpenses(!showExpenses);
                }}
              /> */}
          </div>
          {/* <div className={classes.whiteBox}>
              <img src={MOVEMENT_ICON} alt="" />
              <span className="category">Pagos efectuados</span>
              <Switch
                onChange={() => {
                  setShowTransfers(!showTransfers);
                }}
              />
            </div> */}
          <div className={classes.whiteBox}>
            <img src={REMINDER_ICON_WB} alt="" />
            <span
              className="category"
              onClick={() => {
                setCurrentView("reminders");
              }}
            >
              Recordatorios
            </span>
            {/* <Switch
                onChange={() => {
                  setShowReminders(!showReminders);
                }}
              /> */}

            {/* <ExpandMoreIcon
                onClick={() => {
                  setRemindersOpen(!remindersOpen);
                }}
              /> */}
          </div>

          {/* {remindersOpen && (
              <div className={classes.remindersContainer}>
                <div className={classes.reminder}>
                  <span className="category">Calendario estatal</span>
                  <Switch
                    value={showEstate}
                    onChange={() => {
                      setShowEstate(!showEstate);
                    }}
                  />
                </div>
                <div className={classes.reminder}>
                  <span className="category">
                    Recordatorio de tus productos
                  </span>
                  <Switch
                    onChange={() => {
                      setShowMyProducts(!showMyProducts);
                    }}
                  />
                </div>
              </div>
            )} */}
        </div>
        {/**</AccordionDetails>
      </Accordion>*/}
      </div>
    </>
  );
};

export default CalendarFilter;
