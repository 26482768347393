import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    width: "70rem",
    margin: "3rem auto",
    background: "#F4F3F3",
    padding: "4.3rem 6rem 3rem",
    borderRadius: 30,
    [theme.breakpoints.down(1240)]: {
      width: "100%",
      padding: "2rem 2rem 3rem",
    },
    "& .birthdate": {
      textAlign: "center",
    },
  },
  styleSpace: {
    width: "17.8rem",
    maxHeight: "9rem",
    height: "9rem",
    margin: "0 auto",
    border: `1px solid rgba(0, 0, 0, 0.25)`,
    borderRadius: 10,
    padding: "0.5rem",
    color: theme.palette.text.secondary,
  },
  title: {
    paddingBottom: "2rem",
    fontFamily: "Montserrat",
    color: theme.palette.text.secondary,
    width: "18rem",
    margin: "0 auto",
    fontWeight: 300,
    [theme.breakpoints.down(1240)]: {
      fontSize: "0.875rem",
    },
  },
  subtitle: {
    paddingBottom: "2rem",
    fontFamily: "Montserrat",
    color: theme.palette.text.secondary,
    width: "18rem",
    fontSize: "0.75rem",
    fontWeight: 600,
  },
  containerCard: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
  },
  card: {
    border: `1px solid #d6d6d6`,
    width: "17.8rem",
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0.87rem 1.25rem",
    "& svg": {
      color: theme.palette.text.secondary,
      width: "1.25rem",
    },
  },
  subtitleCard: {
    width: "13.2rem",
    color: theme.palette.text.secondary,
    fontSize: "0.75rem",
    fontWeight: 300,
    fontFamily: theme.typography.fontFamily,
  },
  containerSection: {
    display: "flex",
    gap: "10rem",
  },
  buttonPrimary: {
    width: "19rem",
    margin: "2.5rem auto",
    display: "flex",
    [theme.breakpoints.down(1240)]: {
      marginTop: "10rem",
    },
  },
  parrafo: {
    color: "#7d7c7c",
    fontSize: "1rem",
    fontFamily: "Montserrat",
    paddingTop: "1.75rem",
  },
  listSections: {
    display: "flex",
    "& div": {
      flexDirection: "column",
    },
  },
}));
export default useStyles;
