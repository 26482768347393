import { ApolloClient, gql } from "@apollo/client";

const CREATE_EVENT = gql`
mutation CreateEvent($params: CreateEventParams) {
    createEvent(params: $params) {
      amount
      category
      date
      description
      name
      recurrence
      showInCalendar
      type
      url
    }
  }
`;

type CreateEventParams = {
    'params': any
};

export async function createEventMutation(client: ApolloClient<object>, params: any): Promise<boolean> {
    const result = await client.mutate<boolean, CreateEventParams>({
        mutation: CREATE_EVENT,
        variables: { params: params},
        awaitRefetchQueries: true,
    });
    return !!result.data;
}



