import { useEffect, useContext, useState } from "react";
import { AppContext } from "@context";
import { FundType } from "@interfaces";

interface HookReturn {
  fundType?: FundType;
}

export const useFundType = (fundId: number | null): HookReturn => {
  const { fundsWithSeries } = useContext(AppContext);

  const [fundType, setFundType] = useState<FundType | undefined>();

  useEffect(() => {
    const entry = fundsWithSeries.find(entry => entry.fund.id === fundId);
    if (entry) setFundType(entry.fund.fundType);
  }, [fundsWithSeries, setFundType]);

  return {
    fundType,
  };
};
