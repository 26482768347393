import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "auto",
    padding: "1rem 11.25rem 2rem",
    borderRadius: "30px 30px 0 0",
    backgroundColor: "#F4F3F3",
    [theme.breakpoints.down(1240)]: {
      padding: "0",
      display: "grid",
    },
    "& .rateTextContainer": {
      display: "block",
      fontWeight: "bolder",
      textTransform: "uppercase",
      color: theme.palette.text.primary,
    },
  },
  topSection: {
    display: "grid",
    flexDirection: "row",
    justifyContent: "flex-start",
    [theme.breakpoints.down(1240)]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      // height: "16.125rem",
      marginTop: "-3rem",
      padding: "1rem 0rem 1.875rem",
      fontSize: 14,
      width: 325,
      margin: "0 auto",
    },
    "& .centrator": {
      margin: "2rem 0 4rem 0",
      width: "40rem",
    },
    "& .item": {
      marginRight: "5.5rem",
      [theme.breakpoints.down(1240)]: {
        margin: "1rem 0",
      },
      "& input": {
        height: "2rem",
        backgroundColor: "#ffffff",
      },
      "& .rateContainer": {
        display: "flex",
        width: "38.938rem",
        height: "8.625rem",
        flexGrow: 0,
        margin: "1.108rem 5.563rem 1.625rem 0rem",
        padding: "1rem 2.188rem 1.625rem 1.813rem",
        borderRadius: 30,
        boxShadow: "4px 4px 20px 2px rgba(0, 0, 0, 0.2)",
        backgroundColor: "#ffffff",
        color: theme.palette.text.secondary,
        [theme.breakpoints.down(1240)]: {
          width: "22.375rem",
          height: "12.688rem",
          margin: "0 auto",
        },
        "& >img": {
          width: "3rem",
          height: "3rem",
          margin: "0.5rem 1rem 0 0",
        },
        "& .2texts": {
          [theme.breakpoints.down(1240)]: {
            width: "18rem",
          },
          "& .rateTextContainer": {
            width: "13.938rem",
            height: "1.313rem",
            flexGrow: 0,
            margin: "0 16.563rem 0.625rem 0",
            fontFamily: "Montserrat",
            fontSize: "1rem",
            fontWeight: "bold",
            lineHeight: 1.88,
            textAlign: "left",
          },
          "& .rateTextContainer2": {
            width: "30.5rem",
            height: "4.063rem",
            flexGrow: 0,
            margin: "0.625rem 0 0",
            fontFamily: "NotoSans",
            fontSize: "0.875rem",
            lineHeight: 1.88,
            textAlign: "left",
            color: theme.palette.text.secondary,
            background: "red",
            "& strong": {
              fontWeight: "bold",
            },
            [theme.breakpoints.down(1240)]: {
              width: "18rem",
            },
          },
        },
      },
    },
  },

  middleSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "3.25rem 10rem 3.25rem 0",
    padding: "2.625rem 2.875rem 2.25rem 4.3125rem",
    borderRadius: "1.875rem",
    boxShadow: "0.25rem 0.25rem 1.25rem 0.125rem rgba(0,0,0,0.2)",
    border: "solid 2px #8352fd",
    backgroundColor: "#ffffff",
    [theme.breakpoints.down(1240)]: {
      display: "flex",
      marginRight: "0",
      flexDirection: "column",
      margin: "2.125rem 3rem",
    },
    [theme.breakpoints.down(500)]: {
      margin: "2.125rem 1.25rem",
    },
    "& .creditInfo": {
      display: "flex",
      flexDirection: "column",
      "& .description": {
        // height: "1.25rem",
        margin: "0 0 0.15rem",
        fontFamily: "Montserrat",
        fontSize: 16,
        textTransform: "uppercase",
        fontWeight: 400,
        textAlign: "left",
        color: theme.palette.text.secondary,
      },
      "& .value": {
        // height: "2.75rem",
        margin: "0.25rem 3.1875rem 0 0",
        fontFamily: "Montserrat",
        fontSize: 24,
        fontWeight: "bold",
        lineHeight: 1.83,
        textAlign: "left",
        color: theme.palette.text.primary,
      },
    },
  },
  secondMidSection: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "3.25rem",
    [theme.breakpoints.down(1240)]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: 325,
      margin: "0 auto",
    },
    "& .firstText": {
      width: "28rem",
      fontFamily: "Montserrat",
      fontSize: "0.875rem",
      lineHeight: 2.14,
      textAlign: "left",
      color: theme.palette.text.secondary,
      [theme.breakpoints.down(1240)]: {
        width: "auto",
        margin: "0 0 1.25rem",
        fontSize: "0.75rem",
      },
    },
    "& .secondText": {
      "& .texasContainer": {
        marginTop: "10rem",
        display: "flex",
        flexDirection: "row",
        height: "8.0rem",
        width: "20rem",
        marginLeft: "3rem",
        fontFamily: "Montserrat",
        fontSize: "0.875rem",
        color: theme.palette.text.secondary,
        [theme.breakpoints.down(1240)]: {
          marginLeft: "0",
          width: "auto",
          margin: "0",
        },
        "& >img": {
          width: "2.5rem",
          height: "2.5rem",
          margin: "0 1rem 0 0",
        },
        "& .textContainer": {
          display: "flex",
          flexDirection: "column",
          [theme.breakpoints.down(1240)]: {
            width: 270,
          },
          "& .title": {
            textTransform: "uppercase",
            fontWeight: "bold",
          },
          "& .description": {
            margin: "1rem 0rem",
            "& .underlined": {
              textDecoration: "underline",
            },
          },
          "& >button": {
            marginRight: "3rem",
            [theme.breakpoints.down(1240)]: {
              width: "17rem",
            },
          },
        },
      },
    },
  },
  bottomSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: "20rem",

    [theme.breakpoints.down(1240)]: {
      flexDirection: "column",
      alignItems: "center",
      marginBottom: "0rem",
      marginTop: "8rem",

      "& .down": {
        marginTop: "3rem",
        display: "flex",
        flexDirection: "column",
        "& >button": {
          margin: "2.125rem 0 ",
          width: "16.875rem",
        },
      },
    },
    "& .down": {
      "& >button": {
        marginRight: "3.5rem",
        [theme.breakpoints.down(1240)]: {
          margin: "2.125rem 0 ",
          width: "16.875rem",
        },
      },
    },
  },
  text: {
    fontFamily: "Montserrat",
    fontSize: 16,
    lineHeight: 1.88,
    textAlign: "left",
    width: "40rem",
    display: "block",
    color: theme.palette.text.secondary,
    [theme.breakpoints.down(1240)]: {
      width: "auto",
    },
    "& .style1": {
      fontWeight: "bold",
    },
  },
  textSub: {
    width: "28.25rem",
    height: "2.75rem",
    fontFamily: "Montserrat",
    fontSize: "1.5rem",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.83,
    letterSpacing: "normal",
    textSlign: "left",
    color: "#0a5669",
    paddingBottom: "4rem",
    [theme.breakpoints.down(1240)]: {
      width: "auto",
    },
  },
}));
