import React from "react";
import { useAuth } from "@hooks";
import { Route } from "@interfaces";
import { navigate } from "gatsby";
import { useStyles } from "./Header";

interface headerMobileProps {
  secondLanding?: boolean;
  isOpenMenu: boolean;
  className?: string;
}

const HeaderMobile = ({
  className,
  secondLanding,
  isOpenMenu,
}: headerMobileProps) => {
  const { isLoggedIn } = useAuth();

  const classes = useStyles({ isOpen: isOpenMenu, isLoggedIn: isLoggedIn });

  return (
    <section className={`${classes.menuSticky} ${className}`}>
      <div className="sticky">
        <div className={`${classes.item}`} onClick={() => navigate("/")}>
          Inicio Rokin
        </div>
        {!secondLanding && (
          <>
            <div
              className={`${classes.item}`}
              onClick={() => navigate(Route.business)}
            >
              {" "}
              Rokin para empresas
            </div>
            <div
              className={`${classes.item}`}
              onClick={() => navigate(Route.aboutUs)}
            >
              {" "}
              Quiénes somos
            </div>
            <div
              className={`${classes.item}`}
              onClick={() => navigate(Route.ourSolutions)}
            >
              {" "}
              Nuestras soluciones
            </div>
            <div
              className={`${classes.item}`}
              onClick={() => navigate(Route.helpCenter)}
            >
              {" "}
              Centro de ayuda
            </div>
          </>
        )}
        <hr className={`${classes.line}`} />
        <div
          className={`${classes.item} `}
          onClick={() =>
            isLoggedIn ? navigate(Route.finance) : navigate(Route.login)
          }
        >
          <img
            src={
              "https://rokinapp-images.s3.amazonaws.com/Landing/redes/login_icon+(1).svg"
            }
          />
          Entrar
        </div>
      </div>
    </section>
  );
};
export default HeaderMobile;
