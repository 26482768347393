import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  info2: {
    display: "flex",
    width: "auto",
    height: "auto",
    minHeight: 280,
    margin: "24px 0 0",
    borderRadius: 30,
    boxShadow: "4px 4px 20px 2px rgba(0,0,0,0.2)",
    backgroundColor: "#ffffff",
    flexDirection: "column",
    padding: "15px 25px 15px 25px",
  },
  titleRecommend: {
    color: "#6b6b6b",
    width: "auto",
    fontFamily: "Montserrat",
    fontSize: 18,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    display: "flex",
    alignItems: "center",
    gap: 20,
    "& img": {
      maxWidth: 40,
    },
  },
  recommendation: {
    marginTop: 10,
    display: "flex",
    alignItems: "center",
    fontSize: "0.95rem",
    fontWeight: 300,
    lineHeight: 1.67,
    fontFamily: theme.typography.fontFamily,
    color: "#6b6b6b",
  },
}));
