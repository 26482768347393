import React, { Dispatch, SetStateAction, useContext, useState } from "react";

import { useStyles } from "./MB1step3.style";
import { useMediaQuery } from "@material-ui/core";
import { TipIcon } from "@images";
import { ButtonPrimary } from "@components/index";
import { AppContext } from "@context";

type Props = {
  setPage: Dispatch<SetStateAction<number>>;
  handleClick: () => void;
};

const MB1Step3: React.FC<Props> = ({ setPage, handleClick }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const { openCongratsModal } = useContext(AppContext);

  return (
    <div className={classes.container}>
      <div className={classes.topSection}>
        <div className={classes.textsContainer}>
          <span className="text">
            Junto a los créditos te pueden ofrecer distintos
            <strong>
              {" "}
              complementos como seguros o servicios adicionales.{" "}
            </strong>
            Al contratarlos, el
            <strong> costo de tu crédito subirá </strong>, por lo que es
            conveniente revisar muy bien si es que ese complemento es necesario
            o no. Los
            <strong> créditos hipotecarios </strong>
            son los
            <strong> únicos </strong>
            que tienen
            <strong> seguros obligatorios </strong>
            (seguro de incendio y seguro de desgravamen).
          </span>
          <span className="text">
            Por lo tanto, el mejor crédito para ti
            <strong>
              {" "}
              depende de tus propias necesidades y capacidad de pago.{" "}
            </strong>
            A veces, una cuota mensual más baja pero un costo total del crédito
            más alto, puede ser mejor para ti porque te permite pagar el crédito
            en más tiempo, o quizá vas a preferir pagar cuotas más altas para
            poder pagarlo rápido y con un costo total más bajo.
          </span>
        </div>
      </div>
      <div className={classes.midSection}>
        <div className={classes.whiteBox}>
          <img src={TipIcon} />
          {!isMobile ? (
            <div className="tipTextContainer">
              <span className="tipTitle">Consejo</span>
              <br />
              <span className="text">
                Siempre es recomendable cotizar en
                <strong> varias instituciones y/o startups</strong>, las cuales
                pueden ser bancos, cooperativas y otras entidades financieras
                que ofrezcan créditos con diferentes condiciones para encontrar
                el que más te acomoda a ti. Recuerda que estas entidades
                <strong> deben estar reguladas por la CMF </strong>
                (Comisión para el Mercado Financiero).
              </span>
            </div>
          ) : (
            <>
              <span className="tipTitle">Consejo</span>
              <br />
              <span className="text">
                Siempre es recomendable cotizar en
                <strong> varias instituciones y/o startups</strong>, las cuales
                pueden ser bancos, cooperativas y otras entidades financieras
                que ofrezcan créditos con diferentes condiciones para encontrar
                el que más te acomoda a ti. Recuerda que estas entidades
                <strong> deben estar reguladas por la CMF </strong>
                (Comisión para el Mercado Financiero).
              </span>
            </>
          )}
        </div>
        <div className={classes.whiteBox}>
          <img src={TipIcon} />
          {!isMobile ? (
            <div className="tipTextContainer">
              <span className="tipTitle">Consejo</span>
              <br />
              <span className="text">
                Revisa si el crédito que estás cotizando
                <strong> incluye servicios complementarios </strong>y
                contrátalos solo si lo consideras necesario.
              </span>
            </div>
          ) : (
            <>
              {" "}
              <span className="tipTitle">Consejo</span>
              <br />
              <span className="text">
                Revisa si el crédito que estás cotizando
                <strong> incluye servicios complementarios </strong>y
                contrátalos solo si lo consideras necesario.
              </span>
            </>
          )}
        </div>
      </div>
      <div className={classes.bottomSection}>
        <ButtonPrimary
          text="Terminar misión"
          secondaryVersion
          onClick={() => {
            handleClick();
          }}
        />
      </div>
    </div>
  );
};

export default MB1Step3;
