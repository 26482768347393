import React, { useState, useEffect } from "react";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import { ButtonText } from "..";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import {
  Calendar,
  EditIcon,
  Profile,
  ProfitabilityIcon,
} from "images/Investments";
import { getUserTransactionsSummary } from "@apollo";
import { defaultDataPrimary, chartOptions } from "@components";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

interface propsProfitability {
  globals: any;
  profileForm: any;
  historicalGraphData: any;
}

const Profitability: React.FC = ({
  globals,
  profileForm,
  historicalGraphData,
}: propsProfitability) => {
  const classes = useStyles();
  const [firstDeposit, setFirstDeposit] = useState<number>(0);
  const [investorProfile, setInvestorProfile] = useState<string>("Moderado");
  const { userTransactionsSummary, loading: loadingTransactionsSummary } =
    getUserTransactionsSummary();
  const [graphData, setGraphData] = useState(defaultDataPrimary);
  const isMobile = useMediaQuery("(min-width: 1440px)");

  function getFirstDeposit() {
    const firstMovement = userTransactionsSummary.filter((obj) => {
      return obj.type === "DEPOSIT";
    })[0];

    const now = new Date();

    if (firstMovement !== undefined) {
      const date1 = new Date(firstMovement.createdAt);
      const diff = Math.abs(date1.getTime() - now.getTime());
      const diffDays = Math.ceil(diff / (1000 * 3600 * 24));

      return diffDays;
    } else {
      return 0;
    }
  }

  useEffect(() => {
    setInvestorProfile(profileForm.investorProfile);
    setFirstDeposit(getFirstDeposit());
    if (historicalGraphData) {
      const values = historicalGraphData.map((e) => e.value);
      const labels = historicalGraphData.map(
        (e) => e.date.split("-")[0] + "-" + e.date.split("-")[1]
      );

      setGraphData({
        labels: labels,
        datasets: [
          {
            fill: true,
            label: "Saldo",
            data: values,
            borderColor: "#4619b6",
            backgroundColor: "rgba(131, 82, 253, 0.3)",
            pointStyle: "circle",
            radius: 6,
            pointRadius: 1,
            lineTension: 0.3,
          },
        ],
      });
    }
  }, [globals, profileForm, historicalGraphData]);

  return (
    <section className={classes.container}>
      <div className={classes.graphic}>
        <div className={classes.saldo}>
          <div></div>Saldo
        </div>
        <Line options={chartOptions} data={graphData} />
      </div>
      <div className={classes.rightData}>
        <div className={`${classes.blockOne} ${classes.blockBase}`}>
          <img src={Calendar} />
          <p className={classes.cardText}>
            Llevo<strong> {firstDeposit} dias </strong> ahorrando
          </p>
        </div>

        <div className={`${classes.blockTwo} ${classes.blockBase}`}>
          <img src={ProfitabilityIcon} />
          <p className={classes.cardText}>
            Mi{" "}
            <strong>rentabilidad es {globals.profitability.toFixed(1)}%</strong>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Profitability;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    marginBottom: "20rem",
    justifyContent: "center",
    width: "90%",
    minWidth: 1100,
    alignItems: "center",
    color: theme.palette.text.primary,
    fontFamily: "Montserrat",
    marginLeft: "-20rem",
    [theme.breakpoints.up(1280)]: {
      position: "absolute",
      zindex: 10000,
      marginTop: "-20rem",
    },
  },
  graphic: {
    borderRadius: "1.30625rem",
    boxShadow: "0.25rem 0.25rem 0.75rem 0 rgb(0 0 0 / 30%)",
    padding: 10,
    width: 700,
    height: 425,
    display: "flex",
    alignItems: "center",
    position: "relative",
    margin: "0 13rem 0 25rem",
    backgroundColor: "#ffffff",
  },

  blockOne: {
    gridArea: "one",
  },
  daysSaving: {
    display: "block",
    margin: "0 auto",
    textAlign: "center",
    lineHeight: 1.2,
    "& strong": {
      fontSize: 20,
      fontWeight: "bold",
    },
  },
  value: {
    fontSize: 36,
    fontWeight: 600,
    fontFamily: "Nunito!important",
  },
  valuePorcetage: {
    marginTop: 12,
  },
  ttletxt: {},
  blockBase: {
    display: "flex",
    background: "rgba(240, 240, 240, 0.9)",
    boxShadow: "0.25rem 0.25rem 0.9375rem 0.3125rem rgba(0, 0, 0, 0.25)",
    width: "20.9rem",
    height: "4rem",
    padding: 19,
    alignItems: "center",
    textAlign: "center",
    lineHeight: 1.5,
    fontSize: 18,
    borderRadius: "15px",
    [theme.breakpoints.up(1000)]: {
      width: 290,
      gap: "1rem",
    },
    [theme.breakpoints.down(1280)]: {
      width: "100%",
    },

    "& div": {
      width: 230,
      textAlign: "start",
    },
    "& span": {
      display: "flex",
      height: 50,
      alignItems: "center",
    },
    "& img": {
      margin: "0 auto",
      marginBottom: 10,
    },
  },
  blockTwo: {
    gridArea: "two",
  },
  profitability: {
    fontFamily: "Nunito!important",
    fontSize: 20,
  },
  blockThree: {
    gridArea: "three",
    background: "rgba(240, 240, 240, 0.9)",
    boxShadow: "0.25rem 0.25rem 0.9375rem 0.3125rem rgba(0, 0, 0, 0.25)",
    width: 420,
    height: 114,
    padding: 19,
    display: "flex",
    borderRadius: 20,
    justifyContent: "space-between",
  },
  people: {
    display: "flex",
    alignItems: "center",
    "& img": {
      height: 64,
      marginRight: 20,
    },
    "& div": {
      display: "grid",
      "& span": {
        display: "block",
      },
    },
  },
  profileInvest: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 19,
  },
  profile: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 30,
    fontWeight: 600,
  },
  button: {
    display: "flex",
    width: 110,
    height: 24,
    justifyContent: "space-between",
    alignItems: "center",
    "& img": {
      width: 24,
    },
    "& button": {
      fontSize: "1rem",
    },
  },
  rightData: {
    width: 360,
    height: 390,
    borderRadius: 20,
    gap: 10,
    margin: "0 auto",

    "& >div": {
      margin: " 1.25rem 0",
      [theme.breakpoints.up(1000)]: {
        margin: " 1.25rem 0 0 0",
      },
    },
  },
  cardText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: "0.875rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    "& strong": {
      fontWeight: 800,
      color: "black",
    },
  },
  saldo: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: 20,
    left: 70,
    fontSize: 14,
    fontWeight: "bold",
    "& >div": {
      background: "#8250ff",
      width: 20,
      height: 20,
      borderRadius: "100%",
      marginRight: 5,
    },
  },
}));
