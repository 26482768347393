import React, { useState, useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core";
import {
  BirthdateInput,
  GenderSelector,
  NameInput,
  NationalitySelector,
  RutInput,
  ButtonPrimary,
} from "@components";
import { validate } from "rut.js";
import { is } from "date-fns/locale";

interface myDataProps {
  currentUser: any;
  isDisabled: boolean;
  form: any;
}

const MyData = ({ form, currentUser, isDisabled }: myDataProps) => {
  const classes = useStyles();

  const [isName, setName] = useState<string>(form.name ? form.name : "");
  const [isPaternal, setPaternal] = useState<string>(
    form.paternalSurname ? form.paternalSurname : ""
  );
  const [isMaternal, setMaternal] = useState<string>(
    form.maternalSurname ? form.maternalSurname : ""
  );
  const [isNationality, setNationality] = useState<string>(
    form.nationality ? form.nationality : ""
  );
  const [isBirthdate, setBirthdate] = useState<any>(
    form.birthdate ? form.birthdate : ""
  );
  const [isGender, setGender] = useState<string>(
    form.gender ? form.gender : ""
  );
  const [isRut, setRut] = useState<string>(form.rut ? form.rut : "");

  useEffect(() => {
    if (currentUser) {
      setName(currentUser.name ?? isName);
      setPaternal(currentUser.personalData.paternalSurname ?? isPaternal);
      setMaternal(currentUser.personalData.maternalSurname ?? isMaternal);
      setNationality(currentUser.personalData.nationality ?? isNationality);
      setBirthdate(currentUser.personalData.birthdate ?? isBirthdate);
      setGender(currentUser.personalData.gender ?? isGender);
      setRut(currentUser.personalData.rut ?? isRut);
    }
  }, [currentUser]);

  useEffect(() => {
    form.name = isName;
    form.paternalSurname = isPaternal;
    form.maternalSurname = isMaternal;
    form.rut = isRut;
    form.nationality = isNationality;
    form.gender = isGender;
    form.birthdate = isBirthdate;
  }, [isName, isPaternal, isNationality, isBirthdate, isGender, isRut]);

  return (
    <div className={classes.containerData}>
      <div className="element">
        <span className="label">Nombre</span>
        <NameInput
          onChange={(e) => setName(e.target.value as string)}
          disabled={isDisabled}
          value={isName}
          label=""
        />
      </div>

      <div className="element">
        <span className="label">Apellido Paterno</span>
        <NameInput
          onChange={(e) => setPaternal(e.target.value as string)}
          disabled={isDisabled}
          value={isPaternal}
          label=""
        />
      </div>
      <div className="element">
        <span className="label">Apellido Materno</span>
        <NameInput
          onChange={(e) => setMaternal(e.target.value as string)}
          disabled={isDisabled}
          value={isMaternal}
          label=""
        />
      </div>
      <div className="element">
        <span className="label">Rut</span>

        <RutInput
          onChange={(e) => setRut(e.target.value as string)}
          disabled={isDisabled}
          value={isRut}
          label=""
        />
      </div>
      <div className="smallPack">
        <div className="small-element">
          <span className="label">Fecha de nacimiento</span>
          <BirthdateInput
            label=""
            onChange={(e) => setBirthdate(e.target.value as unknown as Date)}
            value={isBirthdate}
          />
        </div>
        <div className="gap" />
        <div className="small-element">
          <span className="label">Género</span>
          <GenderSelector
            onChange={(e) => setGender(e.target.value as string)}
            disabled={isDisabled}
            value={isGender}
            label=""
          />
        </div>
      </div>
      <div className="element">
        <span className="label">Nacionalidad</span>
        <NationalitySelector
          onChange={(e) => setNationality(e.target.value as string)}
          disabled={isDisabled}
          value={isNationality}
          label=""
        />
      </div>
    </div>
  );
};
export default MyData;

const useStyles = makeStyles<Theme>((theme) => ({
  containerData: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    margin: "0 auto",
    marginTop: "2rem",
    marginBottom: "4rem",
    [theme.breakpoints.down(1240)]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    "& .smallPack": {
      width: "28%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      margin: "0.5rem 1rem",
      [theme.breakpoints.down(1241)]: {
        minWidth: "18rem",
        maxWidth: "18rem",
      },
      "& .gap": {
        width: "1rem",
      },
      "& .small-element": {
        minWidth: "25%",
        display: "flex",
        flexDirection: "column",
        "& .label": {
          marginBottom: "0.5rem",
          textTransform: "uppercase",
          fontFamily: "Montserrat",
          fontSize: "0.75rem",
          color: theme.palette.text.secondary,
          fontWeight: 600,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        },

        "&>div": {
          width: "100%",
          margin: "0.5rem 0",
          fontWeight: 500,
          color: theme.palette.text.secondary,
          "& .MuiSelect-outlined.MuiSelect-outlined": {
            fontWeight: 500,
            color: theme.palette.text.secondary,
          },
        },
      },
    },
    "& .element": {
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
      width: "28%",
      margin: "0.5rem 1rem",
      [theme.breakpoints.down(1241)]: {
        width: "auto",
        minWidth: "18rem",
        maxWidth: "30rem",
      },
      "& .label": {
        textTransform: "uppercase",
        fontFamily: "Montserrat",
        fontSize: "0.75rem",
        color: theme.palette.text.secondary,
        fontWeight: 600,
      },
      "& >div": {
        "&>div": { width: "100%" },
        "& .MuiSelect-outlined.MuiSelect-outlined": {
          fontWeight: 500,
          color: theme.palette.text.secondary,
        },
        "& input": {
          width: "100%",
          fontWeight: 500,
          color: theme.palette.text.secondary,
        },
      },
    },
  },
}));
