import {
  Divider,
  Select,
  FormControl,
  MenuItem,
  IconButton,
  Menu,
} from "@material-ui/core";
import React, { Dispatch, SetStateAction } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { navigate } from "gatsby";
import { useStyles } from "../calendar.styles";
import { Route } from "@interfaces";

type Props = {
  weekly: boolean;
  setWeekly: (arg0: boolean) => void;
  setCurrentView: Dispatch<SetStateAction<string>>;
  setCurrentDay: (arg0: Date) => void;
  getEventsInChangeDay: (date: Date) => void;
};

const CalendarTools: React.FC<Props> = ({
  weekly,
  setWeekly,
  setCurrentView,

  getEventsInChangeDay,
  setCurrentDay,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const classes = useStyles();

  const handlePeriodChange = (event: any) => {
    setWeekly(event.target.value === "Weekly");
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const todayButtonHandler = () => {
    const today = new Date();
    setCurrentDay(today);
    getEventsInChangeDay(today);
  };

  return (
    <div className="calendar-header">
      <FormControl size="small">
        <Select
          value={weekly ? "Weekly" : "Monthly"}
          onChange={(e) => handlePeriodChange(e)}
          className={classes.select}
          disableUnderline={true}
        >
          <MenuItem value={"Monthly"}>Mes</MenuItem>
          <MenuItem value={"Weekly"}>Semana</MenuItem>
        </Select>
      </FormControl>
      <button className={classes.todayButton} onClick={todayButtonHandler}>
        <div className={classes.buttonContainer}>
          <h4 className={classes.todayButtonText}>Hoy</h4>
        </div>
      </button>

      <IconButton
        onClick={handleClick}
        size="small"
        // sx={{ ml: 2 }}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <MoreVertIcon htmlColor="#ffffff" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* <MenuItem>Filtar calendario</MenuItem> */}
        <MenuItem onClick={() => setCurrentView("createPayment")}>
          Agregar gasto o pago recurrente
        </MenuItem>
        <MenuItem onClick={() => setCurrentView("createIncome")}>
          Agregar ingreso recurrente
        </MenuItem>
        <MenuItem onClick={() => setCurrentView("createReminder")}>
          Agregar recordatorio
        </MenuItem>
        {/* <MenuItem>Sincronizar con calendario</MenuItem> */}
      </Menu>
    </div>
  );
};

export default CalendarTools;
