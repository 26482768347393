import React from "react";
import { CommonInputProps } from "..";
import { usePlacesWidget } from "react-google-autocomplete";
import { ChangeEvent } from "@interfaces";
import { SizedTextField } from "@components";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends CommonInputProps {
  userUUID: string;
}

export const AddressInput: React.FC<Props> = ({
  className,
  name,
  label,
  value,
  onChange,
  userUUID,
  onBlur,
  disabled,
  size,
  error,
  required,
}) => {
  const { ref: materialRef } = usePlacesWidget({
    apiKey: process.env.GATSBY_GOOGLE_PLACES_API_KEY,
    onPlaceSelected: (place) =>
      onChange({
        target: { name: name || "address", value: place.formatted_address },
      } as ChangeEvent),
    inputAutocompleteValue: "country",
    language: "es",
    options: {
      types: ["address"],
      sessionToken: userUUID,
      componentRestrictions: { country: "CL" },
    },
  });
  return (
    <SizedTextField
      className={`${className || ""} altercap-address-input altercap-input`}
      name={name || "address"}
      label={label ?? "Dirección particular"}
      variant="outlined"
      value={value}
      onChange={onChange}
      inputRef={materialRef}
      onBlur={onBlur}
      disabled={disabled}
      size={size}
      error={error}
      required={required}
    />
  );
};
