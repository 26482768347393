import {
  CONSORCIO_BANK,
  BICE_BANK,
  MACH_BANK,
  CHILE_BANK,
  STATE_BANK,
  SCOTIA_BANK,
  SANTANDER_BANK,
  FALLABELLA_BANK,
  CHILE_BANK_ICON,
  FALLABELLA_BANK_ICON,
  SANTANDER_BANK_ICON,
  SCOTIA_BANK_ICON,
  STATE_BANK_ICON,
  ITAU_BANK,
  RIPLEY_BANK,
  BCI_BANK,
  BCI_BANK_ICON,
  ITAU_BANK_ICON,
  RIPLEY_BANK_ICON,
  TENPO_BANK,
  WALLET_ICON,
  WALLET_BANK,
  MACH_ICON,
  CONSORCIO_ICON,
  BICE_ICON,
  TENPO_ICON,
} from 'images/AWS/myAccount';

// TODO: Fix finerioBankId to our scrappers
export const finerioListBank = [
  {
    id: 0,
    finerioBankId: 0,
    apiba: false,
    name: 'Billetera en efectivo',
    img: WALLET_BANK,
    icon: WALLET_ICON,
    backgroundColor: '#0A5669',
    enabled: false,
  },
  {
    id: 1,
    finerioBankId: 1,
    apiba: true,
    name: 'Banco de Chile',
    img: CHILE_BANK,
    icon: CHILE_BANK_ICON,
    backgroundColor: '#0c1862',
    enabled: true,
  },
  {
    id: 2,
    finerioBankId: 34,
    apiba: false,
    name: 'Banco Estado',
    img: STATE_BANK,
    icon: STATE_BANK_ICON,
    backgroundColor: '#d90032',
    enabled: true,
  },
  {
    id: 3,
    finerioBankId: 31,
    apiba: false,
    name: 'Scotiabank',
    img: SCOTIA_BANK,
    icon: SCOTIA_BANK_ICON,
    backgroundColor: '#ec111a',
    enabled: true,
  },
  {
    id: 4,
    finerioBankId: 2,
    apiba: true,
    name: 'Santander',
    img: SANTANDER_BANK,
    icon: SANTANDER_BANK_ICON,
    backgroundColor: '#eb0c18',
    enabled: true,
  },
  {
    id: 5,
    finerioBankId: 3,
    apiba: false,
    name: 'Banco Falabella',
    img: FALLABELLA_BANK,
    icon: FALLABELLA_BANK_ICON,
    backgroundColor: '#015231',
    enabled: true,
  },
  {
    id: 6,
    finerioBankId: 47,
    apiba: false,
    name: 'Banco Ripley',
    img: RIPLEY_BANK,
    icon: RIPLEY_BANK_ICON,
    backgroundColor: '#007935',
    enabled: true,
  },
  {
    id: 7,
    finerioBankId: 38,
    apiba: false,
    name: 'Banco Itau',
    img: ITAU_BANK,
    icon: ITAU_BANK_ICON,
    backgroundColor: '#ED7D00',
    enabled: true,
  },
  {
    id: 8,
    finerioBankId: 52,
    apiba: false,
    name: 'Banco BCI',
    img: BCI_BANK,
    icon: BCI_BANK_ICON,
    backgroundColor: '#2C3A41',
    enabled: true,
  },
  {
    id: 9,
    finerioBankId: 54,
    apiba: false,
    name: 'MACH',
    img: MACH_BANK,
    icon: MACH_ICON,
    backgroundColor: '#6934DA',
    enabled: true,
  },
  {
    id: 10,
    finerioBankId: 56,
    apiba: false,
    name: 'Banco Consorcio',
    img: CONSORCIO_BANK,
    icon: CONSORCIO_ICON,
    backgroundColor: '#043ca4',
    enabled: true,
  },
  {
    id: 11,
    finerioBankId: 58,
    apiba: false,
    name: 'Banco BICE',
    img: BICE_BANK,
    icon: BICE_ICON,
    backgroundColor: '#045c9c',
    enabled: true,
  },
  {
    id: 12,
    finerioBankId: 60,
    apiba: false,
    name: 'TENPO',
    img: TENPO_BANK,
    icon: TENPO_ICON,
    backgroundColor: '#000000',
    enabled: true,
  },
];
