import React from 'react';
import { Dialog } from '@material-ui/core';
import { ButtonPrimary, ButtonText } from '@components/Reusables';
import { makeStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';

interface CongratsModalProps {
  open: boolean;
  onClose: () => void;
}

export const CalendarLastDays: React.FC<CongratsModalProps> = ({
  open,
  onClose,
}) => {
  const classes = useStyles();
  return (
    <>
      <Dialog open={false} onClose={onClose} className={classes.Dialog}>
        <Close
          onClick={() => {
            onClose();
          }}
          className={classes.close}
        />

        <div className={classes.container}>
          <img
            className={classes.background}
            src="https://storage.googleapis.com/rokinapp-images/rokinPlus/Fondo%20pop%20up%20ultimo%20dia%20de%20prueba%20_%20mobile.svg"
          />
          <div className={classes.containerTop}>
            <div className={`${classes.top} ${classes.notificationTop}`}>
              <img src="https://storage.googleapis.com/rokinapp-images/rokinPlus/image%201.svg" />
              <div>
                Te quedan solo [#]
                <br /> días de prueba gratis
              </div>
            </div>
          </div>
          <div className={classes.containerBottom}>
            <div className={classes.titleBottom}>Durante este mes puedes:</div>
            <div className={classes.containerItems}>
              {list.map((item) => {
                return (
                  <div className={classes.item} key={item.id}>
                    <img src={item.icon} />
                    <span>{item.text}</span>
                  </div>
                );
              })}
            </div>
            <div className={classes.white}>
              *Cuando termine este mes dejarás de tener acceso a estas funciones
            </div>
            <div className={classes.containerButtons}>
              <ButtonText
                className="outlined"
                text="Cerrar"
                onClick={() => {}}
              />
              <ButtonPrimary text="Ver opciones" onClick={() => {}} />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};
const useStyles = makeStyles(
  (theme) => ({
    Dialog: {
      '& >div': {},
      '& >div >div': {
        borderRadius: '32px',
        position: 'absolute',
        top: 95,
        maxWidth: 900,
        overflow: 'hidden',
        [theme.breakpoints.down(600)]: {
          top: 70,
        },
        [theme.breakpoints.down(715)]: {
          top: 70,
          minWidth: '360px',
          borderRadius: '20px',
        },
      },
    },
    close: {
      position: 'absolute',
      top: '2rem',
      right: '2rem',
      color: 'white',
      zIndex: 9,
    },
    container: {
      width: '42rem',
      height: '40rem',
      [theme.breakpoints.down(715)]: {
        top: 170,
        width: '100%',
        height: '41.8rem',
      },
    },
    containerBottom: {
      background: '#001218',
      width: '100%',
      height: '25rem',
      borderRadius: '16px 16px 0 0',
      marginTop: '-3.2rem',
      zIndex: 12,
      position: 'relative',
      [theme.breakpoints.down(715)]: {
        height: '24rem',
      },
    },
    containerTop: {
      width: '100%',
      background: '#008195',
      height: '18rem',
      [theme.breakpoints.down(715)]: {
        height: '19rem',
      },
    },
    top: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '2rem',
      paddingTop: '2rem',
      zIndex: 12,
      position: 'relative',
      [theme.breakpoints.down(715)]: {
        flexDirection: 'column',
        paddingTop: '1rem',
        gap: '0rem',
      },
      '& img': {
        zIndex: 12,
        position: 'relative',
        [theme.breakpoints.down(715)]: {
          width: 120,
        },
      },
      '& div': {
        maxWidth: '25rem',
        fontSize: '2.25rem',
        color: 'white',
        fontWeight: 'bold',
        fontFamily: 'Montserrat',
        zIndex: 12,
        position: 'relative',
        [theme.breakpoints.down(715)]: {
          fontSize: '18px',
          minWidth: '15rem!important',
          textAlign: 'center',
        },
      },
    },
    subtitle: {
      fontSize: '14px',
      color: 'white',
      fontWeight: 300,
      fontFamily: 'Montserrat',
      width: '276px',
      textAlign: 'center',
      margin: '0 auto',
    },
    bottom: {
      width: '25rem',
      textAlign: 'center',
      margin: '0.5rem auto 0',
      fontSize: '1.25rem',
      color: 'white',
      fontWeight: 300,
      fontFamily: 'Montserrat',
      zIndex: 12,
      position: 'relative',
      [theme.breakpoints.down(715)]: {
        fontSize: '0.875rem',
        width: '15rem',
        margin: '1rem auto 0',
      },
      '& span': {
        fontWeight: 600,
      },
    },
    bottom2: {
      width: '25rem',
      textAlign: 'center',
      margin: '2rem auto 0',
      fontSize: '1.25rem',
      color: 'white',
      fontWeight: 300,
      fontFamily: 'Montserrat',
      zIndex: 12,
      position: 'relative',
      [theme.breakpoints.down(715)]: {
        fontSize: '1rem',
        width: '22rem',
      },
      '& span': {
        fontWeight: 600,
      },
    },
    background: {
      position: 'absolute',
      zIndex: 0,
      width: '100%',
      top: 0,
    },
    titleBottom: {
      width: '90%',
      margin: '2rem auto 1rem',
      fontSize: '1.375rem',
      color: 'white',
      fontWeight: 600,
      fontFamily: 'Montserrat',
      paddingTop: '2.2rem',
      [theme.breakpoints.down(715)]: {
        fontSize: '0.75rem',
        margin: '2rem auto 1rem',
        paddingTop: '1.5rem',
      },
    },
    containerItems: {
      width: '90%',
      margin: '2rem auto 0',
      display: 'grid',
      gap: '1.5rem',
      [theme.breakpoints.down(715)]: {
        margin: '1.5rem auto 0',
        gap: '1rem',
      },
      '& >div': {},
    },
    item: {
      display: 'flex',
      gap: '1rem',
      alignItems: 'center',
      color: 'white',
      fontFamily: 'Montserrat',
      zIndex: 12,
      position: 'relative',
      [theme.breakpoints.down(715)]: {
        fontSize: '0.75rem',
      },
      '& img': {
        width: '1.5rem',
      },
    },
    white: {
      color: 'white',
      width: '90%',
      margin: '1.5rem auto 0',
      fontSize: '0.875rem',
      fontWeight: 300,
      [theme.breakpoints.down(715)]: {
        minHeight: '1rem',
      },
    },
    containerButtons: {
      display: 'flex',
      justifyContent: 'end',
      marginTop: '3rem',
      marginRight: '2rem',
      gap: '1rem',
      '& .outlined': {
        border: '1px solid #54e5ff',
        borderRadius: 10,
        width: '180px',
        [theme.breakpoints.down(715)]: {
          width: '100%',
        },
      },
      [theme.breakpoints.down(715)]: {
        flexDirection: 'column-reverse',
        width: '90%',
        margin: '1.5rem auto',
      },
      '& button': {
        textDecoration: 'none',
      },
      '& span': {
        color: 'white',
      },
    },
    /// 3   --------------
    topContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0rem',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down(715)]: {
        gap: '1.5rem',
      },
      '& >div': {
        width: '25rem',
        textAlign: 'center',
      },
    },
    containerBottomContainer: {
      height: '22rem',
      marginTop: -13,
      [theme.breakpoints.down(715)]: {
        height: '24rem',
      },
    },
    containerTopAll: {
      height: '20rem',
    },
    titleBottomContainer: {
      width: '25rem',
      margin: '0 auto',
      textAlign: 'center',
      [theme.breakpoints.down(715)]: {
        width: '19rem',
        fontSize: '0.875rem',
        paddingTop: '3rem',
      },
    },
    containerButtonsContainer: {
      flexDirection: 'column',
      width: '18rem',
      margin: '3rem auto 0',
      [theme.breakpoints.down(715)]: {
        marginTop: '7rem',
      },
    },
    notificationTop: {
      height: '100%',
      padding: '0',
      alignItems: 'center',
      fontSize: '18px',
      gap: '1rem',
    },
  }),
  { index: 1 }
);

const list = [
  {
    id: 0,
    icon: 'https://storage.googleapis.com/rokinapp-images/rokinPlus/bank_icon.svg',
    text: 'Sincronizar tus bancos y ver tus gastos categorizados',
  },
  {
    id: 1,
    icon: 'https://storage.googleapis.com/rokinapp-images/rokinPlus/piggyBank_icon%20(2).svg',
    text: 'Ahorrar automáticamente',
  },
  {
    id: 2,
    icon: 'https://storage.googleapis.com/rokinapp-images/rokinPlus/talkBubble_icon%20(1).svg',
    text: 'Acceder al Personal Trainer Financiero',
  },
];
