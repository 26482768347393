export const chartOptions = {
  responsive: true,
  scales: {
    yAxes: {
      position: "right",
      ticks: {
        callback: function(value, index, values) {
          return "$" + value.toLocaleString("es");
        },
      },
    },
    xAxes: {
      ticks: {
        autoSkip: true,
        maxTicksLimit: 10
      }
    },
  },
  interaction: {
    mode: "index",
  },
  plugins: {
    tooltip: {
      usePointStyle: true,
    },
    legend: {
      display: false,
    },
  },
};

export const chartOptionsMobile = {
  responsive: true,
  scales: {
    yAxes: {
      position: "right",
      ticks: {
        callback: function(value, index, values) {
          return "$" + value.toLocaleString("es");
        },
      },
    },
    xAxes: {
      ticks: {
        autoSkip: true,
        maxTicksLimit: 5
      }
    },
  },
  interaction: {
    mode: "index",
  },
  plugins: {
    tooltip: {
      usePointStyle: true,
    },
    legend: {
      display: false,
    },
  },
};

export const defaultDataPrimary = {
  labels: ["Sept.", "Oct", "Nov.", "Dec.", "Jan.", "Feb."],
  datasets: [
    {
      fill: true,
      label: "Saldo",
      data: [100000, 120000, 85000, 83000, 130000, 200000],
      borderColor: "#0a5669",
      backgroundColor: " #C4E6EB",
      pointStyle: "circle",
      radius: 6,
      pointRadius: 1,
      lineTension: 0.3,
    },
  ],
};

export const defaultDataSecondary = {
  labels: ["0", "2", "4", "6", "8", "10", "12", "14", "16"],
  datasets: [
    {
      fill: true,
      label: "Saldo",
      data: [30, 10, 20, 34, 45, 68, 55, 77, 92],
      borderColor: "#008296",
      backgroundColor: "rgba(0, 169, 194, 0.3)",
      pointStyle: "circle",
      radius: 6,
      pointRadius: 1,
      lineTension: 0.3,
    },
  ],
};
