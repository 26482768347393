import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    margin: "5rem -1.5625rem",
  },
  topSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.down(1241)]: {
      flexDirection: "column",
      display: "flex",
      alignItems: "center",
    },
    "& .textContainer": {
      width: "28rem",
      [theme.breakpoints.down(1241)]: {
        order: 2,
        width: "100%",
        padding: "0 2rem",
      },
      "& .text": {
        fontSize: "1rem",
        lineHeight: 1.88,
        fontFamily: "Montserrat",
        fontWeight: "normal",
        textAlign: "left",
        color: theme.palette.text.primary,
        "& .style1": {
          fontWeight: "bold",
          color: "#8352fd",
        },
        [theme.breakpoints.down(1241)]: {
          fontSize: "0.75rem",
          lineHeight: 2,
        },

      },
    },

    "& >img": {
      width: "25.625rem",
      height: "24.813rem",
      marginTop: "-12rem",
      marginRight: "3rem",
      [theme.breakpoints.down(1241)]: {
        margin: "0rem auto",
        marginTop: "-9rem",
        width: "16rem",
      },
    },
  },
  imageContainer: {
    width: "100%",
    margin: "3rem 0rem 3rem -1rem",
    [theme.breakpoints.down(1241)]: {
      display: "flex",
      justifyContent: "center",
      margin: "0rem 0rem",
    },
    [theme.breakpoints.down(567)]: {
      justifyContent: "flex-start",
      overflowX: "scroll",
    },
    "& .scrollable": {
      width: "37.06rem",
      minWidth: "37.06rem",
      [theme.breakpoints.down(552)]: {
        // overflow: "auto",
        // width: "46rem",
      },
    },
  },
  bottomSection: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: "4rem",
    [theme.breakpoints.down(1241)]: {
      justifyContent: "center",
      marginTop: "2rem",
    },
  },
}));
