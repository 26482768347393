import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { Header } from './Header';
import { Footer } from '../Landing/Footer';

interface LayoutProps {
  children: React.ReactNode;
  className?: string;
  simpleHeader?: boolean;
  styleFooter?: string;
  activeRokipedia?: boolean;
  activeInvest?: boolean;
  activeBenefit?: boolean;
  activeFinance?: boolean;
  activeRokinpedia?: boolean;
  activeServices?: boolean;
  hiddenHeader?: boolean;
  activeAccount?: boolean;
}
export const LayoutOnBoarding: React.FC<LayoutProps> = ({
  className,
  children,
  simpleHeader,
  styleFooter,
  activeRokipedia,
  activeInvest,
  activeBenefit,
  activeFinance,
  activeRokinpedia,
  activeServices,
  activeAccount,
  hiddenHeader,
}) => {
  const classes = useStyles();
  return (
    <div className={`${className || ''} ${classes.container}`}>
      {!hiddenHeader && (
        <Header
          simpleHeader={simpleHeader}
          activeRokipedia={activeRokipedia}
          activeInvest={activeInvest}
          activeBenefit={activeBenefit}
          activeFinance={activeFinance}
          activeRokinpedia={activeRokinpedia}
          activeServices={activeServices}
          activeAccount={activeAccount}
        />
      )}
      <div className={classes.children}>{children}</div>
      <Footer className={`${classes.footer} ${styleFooter}`} />
    </div>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  footer: {
    [theme.breakpoints.down(1100)]: {
      display: 'none',
    },
  },
  container: {
    overflowX: 'hidden',
    overflowY: 'hidden',
    background: '#edebeb',
  },
  children: {
    marginTop: 70,
    [theme.breakpoints.down(1200)]: {
      marginTop: 40,
    },
  },
}));
