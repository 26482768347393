import {
  ALIMENTATION_ICON,
  EDUCATION_ICON,
  ENTERTAINMENT,
  FINANCES,
  GIFTS,
  HEALTH,
  HOBBIES_ICON,
  HOME,
  OTHERS,
  TRANSFERS, //,
  RESTAURANT,
  SHOPPING_ICON,
  TRANSPORT,
  TRAVEL,
} from "@images";

const images = [
  {
    id: 2,
    name: "food",
    image: ALIMENTATION_ICON,
    label: "Alimentación",
  },
  {
    id: 9,
    name: "shopping",
    image: SHOPPING_ICON,
    label: "Compras",
  },
  {
    id: 5,
    name: "sportAndHobbies",
    image: HOBBIES_ICON,
    label: "Hobbies",
  },
  {
    id: 4,
    name: "educationAndWork",
    image: EDUCATION_ICON,
    label: "Educación",
  },
  {
    id: 7,
    name: "financeAndTaxes",
    image: FINANCES,
    label: "Finanzas",
  },
  {
    id: 11,
    name: "entertainment",
    image: ENTERTAINMENT,
    label: "Entretenimiento",
  },
  {
    id: 12,
    name: "transfers",
    image: TRANSFERS,
    label: "Transferencias",
  },
  {
    id: 13,
    name: "gifts",
    image: GIFTS,
    label: "Regalos",
  },
  {
    id: 10,
    name: "restaurant",
    image: RESTAURANT,
    label: "Restaurantes",
  },
  {
    id: 8,
    name: "healthAndBeauty",
    image: HEALTH,
    label: "Salud",
  },
  {
    id: 3,
    name: "transport",
    image: TRANSPORT,
    label: "Transporte",
  },
  {
    id: 1,
    name: "home",
    image: HOME,
    label: "Vivienda",
  },
  {
    id: 6,
    name: "travel",
    image: TRAVEL,
    label: "Viajes",
  },
  {
    id: 17,
    name: "other",
    image: OTHERS,
    label: "Otros",
  },
];
export default images;
