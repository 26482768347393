export const SUSCRIPTIONS_OPTIONS = [
  "Netflix",
  "Spotify",
  "Amazon Prime",
  "Disney+",
  "HBO",
  "Apple TV+",
  "Hulu",
  "Youtube Premium",
  "Crunchyroll",
  "Twitch Prime",
  "Uber Pass",
  "Rokin",
  "Star+",
  "Rappi Prime",
  "Cornershop",
].map(g => ({
  name: g,
  value: g,
}));