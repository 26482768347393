export interface SignedUrlResponse {
  url: string;
  fields: {
    acl: string;
    bucket: string;
    "X-Amz-Algorithm": string;
    "X-Amz-Credential": string;
    "X-Amz-Date": string;
    key: string;
    Policy: string;
    "X-Amz-Signature": string;
  };
}

export enum FileType {
  IDENTITY = "IDENTITY",
  CONTRACT = "CONTRACT",
}

export enum FileSpecificType {
  IDENTITY_FRONT = "IDENTITY_FRONT",
  IDENTITY_BACK = "IDENTITY_BACK",
  LIQUIDATION = "LIQUIDATION",
  SERVICE_LIQUIDATION = "SERVICE_LIQUIDATION",
  DEBT = "DEBT",
  CMFFILE = "USER_FILE_CMF",
}

export enum ContractFileType {
  CONTRACT = "CONTRACT",
  MANDATE = "MANDATE",
}
