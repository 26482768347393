import React, { useState, useCallback, Dispatch, SetStateAction } from "react";
import { useStyles } from "./CT2.style";
import { useMediaQuery } from "@material-ui/core";
import { navigate } from "@reach/router";
import { Route } from "@interfaces";
import {
  ButtonOutlined,
  ButtonPrimary,
  NumericInput,
  TextInput,
} from "@components";

type Props = {
  setPage: Dispatch<SetStateAction<number>>;
  term: number;
  cae: number;
  monthPayment: number;
};

const CreditTranslator2: React.FC<Props> = ({
  setPage,
  term,
  cae,
  monthPayment,
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 1300px)");

  const [values, setValues] = useState({
    amount: 0,
    time: 36,
    totalAmount: 0,
  });

  const [inputValues, setInputValues] = useState({
    rate: 0,
    category: "",
  });

  const handleChange = () => {
    navigate(Route.finance);
  };
  return (
    <div className={classes.container}>
      {!isMobile ? (
        <div className={classes.topSection}>
          <div className="item">
            <div className="description">
              <span className={classes.text}>
                Con un
                <span className="style1"> CAE </span>
                de:
              </span>
            </div>
            <div className="rateContainer">{cae}%</div>
          </div>
          {/* <div className="item">
            <div className="description">
              <span className={classes.text}>
                <span className="style1">Categoría del crédito</span>
              </span>
            </div>
            <div className="rateContainer">Cédito de consumo</div>
          </div> */}
        </div>
      ) : (
        <div className={classes.topSection}>
          <div className="centrator">
            <div className="item">
              <div className="description">
                <span className={classes.text}>
                  Con un
                  <span className="style1"> CAE </span>
                  de:
                </span>
              </div>
              <div className="rateContainer">{cae}%</div>
            </div>
            {/* <div className="item">
              <div className="description">
                <span className={classes.text}>
                  <span className="style1">Categoría del crédito</span>
                </span>
              </div>
              <div className="rateContainer">Cédito de consumo</div>
            </div> */}
          </div>
        </div>
      )}
      {!isMobile ? (
        <div className={classes.middleSection}>
          <div className="creditInfo">
            <span className="description">pagarás al mes</span>
            <span className="value">
              $ {Math.round(monthPayment).toLocaleString("es")}
            </span>
          </div>
          <div className="creditInfo">
            <span className="description">por cuanto Tiempo</span>
            <span className="value">{term} meses</span>
          </div>
          <div className="creditInfo">
            <span className="description">en total pagarás</span>
            <span className="value">
              $ {Math.round(term * monthPayment).toLocaleString("es")}
            </span>
          </div>
        </div>
      ) : (
        <div className={classes.middleSection}>
          <div className="centrator">
            <div className="creditInfo">
              <span className="description">pagarás al mes</span>
              <span className="value">
                $ {Math.round(monthPayment).toLocaleString("es")}
              </span>
            </div>
            <div className="creditInfo">
              <span className="description">en cuanto Tiempo</span>
              <span className="value">{term} meses</span>
            </div>
            <div className="creditInfo">
              <span className="description">en total pagarás</span>
              <span className="value">
                $ {Math.round(term * monthPayment).toLocaleString("es")}
              </span>
            </div>
          </div>
        </div>
      )}

      <div className={classes.bottomSection}>
        <ButtonPrimary
          text={"Guardar y volver a mis finanzas"}
          onClick={handleChange}
        />
        <ButtonOutlined
          text={"Ir atras y editar"}
          onClick={function (): void {
            setPage(1);
          }}
        />
      </div>
      {!isMobile && (
        <div className={classes.secondBottomSection}>
          <span className="textBottom">
            <strong>OJO </strong>
            Recuerda que los valores entregados son referenciales y pueden
            variar dependiendo de tu perfil financiero y las condiciones
            bancarias vigentes.
          </span>
        </div>
      )}
    </div>
  );
};

export default CreditTranslator2;
