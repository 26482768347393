import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down(1241)]: {
      margin: "0 -1.5625rem",
    },
  },
  helperText: {
    borderRadius: "1.7188rem",
    boxShadow: "0.25rem 0.25rem 0.5rem 0.125rem rgba(0, 0, 0, 0.2)",
    backgroundColor: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "33rem",
    height: "3.438rem",
    position: "absolute",
    padding: "1rem",
    marginLeft: "25rem",
    marginTop: "-5.5rem",
    [theme.breakpoints.down(1239)]: {
      width: "20rem",
      position: "relative",
      margin: "1rem auto 2rem",
    },
    "& >img": {
      width: "2.125rem",
      height: "2.125rem",
    },
    "& .text": {
      fontSize: "0.75rem",
      marginLeft: "1rem",
      textAlign: "center",
      color: "#9d9d9d",
    },
  },
  topText: {
    width: "20rem",
    fontSize: "1.25rem",
    fontWeight: "normal",
    fontFamily: "Montserrat",
    textAlign: "left",
    color: "#008296",
    marginBottom: "2.6rem",
    [theme.breakpoints.down(1239)]: {
      width: "20.938rem",
      fontSize: "0.85rem",
      textAlign: "center",
      margin: "1rem auto",
    },
  },
  srollable: {
    overflowX: "scroll",
    width: "70rem",
  },
  imagesLayout: {
    display: "flex",
    justifyContent: "space-between",
    overflowX: "auto",
    [theme.breakpoints.down(1239)]: {
      justifyContent: "flex-start",
      paddingBottom: "1rem",
    },
    "& .imageContainer": {
      width: "4.81rem",
      height: "7.25rem",
      margin: "0 1rem",
      minWidth: "4.8rem",
      "& >img": {
        width: "4.81rem",
        height: "4.81rem",
      },
      "& .text": {
        fontSize: "0.875rem",
        fontWeight: 500,
        textAlign: "center",
        color: theme.palette.text.secondary,
        marginTop: "1rem",
      },
    },
  },
  expenseContainer: {
    display: "flex",
    flexDirection: "column",
    fontFamily: "Montserrat",
    marginTop: "2.5rem",
    "& .expense": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "54rem",
      margin: "2rem auto",

      [theme.breakpoints.down(1239)]: {
        width: "auto",
        justifyContent: "space-around",
      },
      [theme.breakpoints.down(570)]: {
        justifyContent: "space-between",
      },
      "& >img": {
        width: "3.9rem",
        height: "3.9rem",
      },
      "& .rightSide": {
        [theme.breakpoints.down(1239)]: {
          marginLeft: "1.5rem",
        },
      },
      "& .first": {
        marginRight: "10rem",
      },
      "& .section": {
        display: "flex",
        flexDirection: "column",
        "& .name": {
          fontSize: "0.75rem",
          fontWeight: 500,
          textAlign: "left",
          color: theme.palette.text.secondary,
          textTransform: "uppercase",
        },
        "& .nameInput": {
          minWidth: "15rem",
        },

        "& .text": {
          // width: "15.063rem",
          margin: " 0.313rem 0 0",
          // padding: "0.375rem 5.5rem 0.5rem 1.313rem",
          borderRadius: "0.625rem",
          // border: "solid 0.0625rem #9d9d9d",
          backgroundColor: "#ffffff",
          fontSize: "1rem",
          fontWeight: "bold",
          textAlign: "left",
          color: theme.palette.text.primary,
          "& > .makeStyles-root-228 > .MuiInputBase-root > input": {
            fontWeight: "bold",
          },
        },
        "& .input": {
          display: "flex",
          alignItems: "center",
          [theme.breakpoints.down(1239)]: {
            width: "12rem",
            "& .MuiOutlinedInput-input": {
              width: "10.063rem",
            },

            "& >button": {
              marginLeft: "0rem",
            },
          },
        },
      },
    },
  },

  middleSection: {
    "& .textContainer": {
      margin: "2.5rem auto",
      width: "45rem",
      [theme.breakpoints.down(1239)]: {
        width: "auto",
        margin: "2rem 1.5rem",
      },
      "& .text": {
        fontSize: "0.85rem",
        fontWeight: "normal",
        lineHeight: 1.43,
        textAlign: "left",
        color: theme.palette.text.secondary,
      },
    },
    "& .separator": {
      width: "43.125rem",
      height: "0.188rem",
      margin: "2.406rem auto",
      border: "solid 0.1875rem #008296",
      [theme.breakpoints.down(1239)]: {
        width: "auto",
        margin: "2rem 1rem",
      },
    },
    "& .total": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      fontFamily: "Montserrat",
      fontSize: "1rem",
      fontWeight: "bold",
      textAlign: "left",
      [theme.breakpoints.down(1239)]: {
        marginRight: "2rem",
      },

      "& .text": {
        color: theme.palette.text.primary,
      },
      "& .amount": {
        width: "10em",
        height: "3rem",
        overflow: "hidden",
        color: theme.palette.text.primary,
        margin: "0 0 0 1.5rem",
        paddingLeft: "1rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        borderRadius: "0.625rem",
        border: "solid 0.0625rem #9d9d9d",
        backgroundColor: "#ffffff",
      },
    },
  },
  bottomSection: {
    marginBottom: "2.5rem",

    [theme.breakpoints.down(1239)]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  formContainer: {
    margin: "0 0.5rem",
  },
}));
