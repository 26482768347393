import { InvestorProfileType, InvestmentTermType } from './investor_profile';

export enum UseType {
  INVEST_SAVINGS = 'INVEST_SAVINGS',
  RETIREMENT = 'RETIREMENT',
  GOAL = 'GOAL',
}

export enum ProviderType {
  USER_PASSWORD = 'USER_PASSWORD',
  GOOGLE = 'GOOGLE',
}

export interface RegisterForm {
  saving: string;
  /*TO-DO pending implementation send in the upDateUser the attribute useType and investAmount */
  useType: UseType | null;
  investAmount: number;
  dontKnowInvestAmount: boolean;
  investorProfile: InvestorProfileType | null;
  goalName: string;
  goalAmount: number;
  goalTerm: number;
  name: string;
  email: string;
  password: string;
  passwordCheck: string;
  investmentObjective: string;
  investmentHorizon: string;
  investmentExperience: string;
  investmentConcern: string;
  product: string;
  affirmation: string;
  level: string;
  savings: string;
  investmentProfession: string;
  investmentDropResponse: string;
}
