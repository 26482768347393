import { ApolloClient, gql } from "@apollo/client";
import { ContractFileType } from "@interfaces";
import { base64ToURL } from "@utils";

const GET_CONTRACT_PREVIEW_QUERY = gql`
  query GenerateContractPreview($type: String!) {
    generateContractPreview(type: $type)
  }
`;

type QueryParams = {
  type: ContractFileType;
};

type QueryResult = {
  generateContractPreview: string;
};

export async function generateContractPreview(
  type: ContractFileType,
  client: ApolloClient<object>,
): Promise<string> {
  const result = await client.mutate<QueryResult, QueryParams>({
    mutation: GET_CONTRACT_PREVIEW_QUERY,
    variables: { type },
  });
  return base64ToURL(result.data?.generateContractPreview ?? "") ?? "";
}
