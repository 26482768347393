import React from "react";
import { makeStyles } from "@material-ui/core";

type FrameProps = {
  image: string;
  title?: string;
  desc?: string;
};

export const Frame: React.FC<FrameProps> = ({
  image,
  title,
  desc,
  ...props
}) => {
  const classes = useStyles();

  return (
    <div className={classes.frame} {...props}>
      <img className={`${classes.frameImage} frameImage`} src={image} alt="" />
      <div className={classes.frameTextWrapper}>
        <div className={classes.frameTitle}>{title}</div>
        <div className={classes.frameDescription}>{desc}</div>
      </div>
    </div>
  );
};

const useStyles = makeStyles(
  theme => ({
    frame: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      padding: "0 20px",
      flex: 1,
      [theme.breakpoints.down(768)]: {
        padding: "14px 20px",
        flexDirection: "row",
        justifyContent: "center",
        "&:nth-child(2n)": {
          flexDirection: "row-reverse",
          backgroundColor: "#f6f6f6",
          "& .frameImage": {
            margin: "0 0 0 20px",
          },
        },
      },
    },
    frameImage: {
      height: 100,
      marginBottom: 32,
      [theme.breakpoints.down(768)]: {
        height: "unset",
        width: 90,
        margin: "0 20px 0 0",
      },
    },
    frameTextWrapper: {},
    frameTitle: {
      fontSize: 20,
      fontWeight: "bold",
      marginBottom: 4,
      color: "#404040",
      [theme.breakpoints.down(768)]: {
        fontSize: 18,
        textAlign: "left",
      },
    },
    frameDescription: {
      fontSize: 16,
      padding: "0 24px",
      color: "#565656",
      [theme.breakpoints.down(768)]: {
        fontSize: 14,
        padding: 0,
        lineHeight: 1.2,
        textAlign: "left",
      },
    },
  }),
  { index: 1 },
);
