import React, { Dispatch, SetStateAction, useEffect } from "react";
import { LeftColumnCalendarInfo, EventsRecap } from "@components";
import { useStyles } from "./MainEventsDetail.style";

type Props = {
  type: string;
  setEdit: Dispatch<SetStateAction<boolean>>;

  setCurrentView: Dispatch<SetStateAction<string>>;
  setEditingData: Dispatch<
    SetStateAction<{
      id: number;
      showInCalendar: boolean;
      name: string;
      date: string;
      amount: number;
      type: number;
      category: number;
      recurrence: number;
      description: string;
      url: string;
    }>
  >;
  togglePage: () => void;
};

const MainEventsDetail = ({
  type,
  setCurrentView,
  setEditingData,
  setEdit,
  togglePage,
}: Props) => {
  const classes = useStyles();
  // set the text dependig if type is expense, income or reminder
  const setTitle = () => {
    switch (type) {
      case "expense":
        return "Gastos y pagos recurrentes";
      case "income":
        return "Ingresos recurrentes";
      case "reminder":
        return "Recordatorios";
      default:
        return "Recordatorios";
    }
  };

  return (
    <div className={classes.container}>
      <LeftColumnCalendarInfo
        type={type}
        title={setTitle()}
        setCurrentView={setCurrentView}
      />
      <div className={classes.recapsContainer}>
        <EventsRecap
          type={type}
          setEditingData={setEditingData}
          setCurrentView={setCurrentView}
          setEdit={setEdit}
          togglePage={togglePage}
        />
      </div>
    </div>
  );
};

export default MainEventsDetail;
