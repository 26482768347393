import { ApolloClient, gql } from '@apollo/client';

const UPDATE_USER_BANK = gql`
  mutation UpdateUserBank($data: UpdateUserBankParams!) {
    updateUserBank(data: $data)
  }
`;

type UpdateUserBankParams = {
  bank: string;
  accountType: string;
  accountNumber: string;
};

export async function updateUserBank(
  params: UpdateUserBankParams,
  client: ApolloClient<object>
) {
  const { data } = await client.mutate({
    mutation: UPDATE_USER_BANK,
    variables: { data: params },
  });

  return data;
}
