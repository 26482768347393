import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme, { color: string }>((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '5rem',
    background: 'rgba(277,277,277,0.9)',
    borderRadius: 8,
    justifyContent: 'center',
    maxWidth: '25rem',
    margin: '0 auto',
    [theme.breakpoints.down(1280)]: {
      padding: '0 1rem',
      gap: '0.4rem',
    },
  },
  iconBox: {
    width: '2.938rem',
    height: '2.938rem',
    borderRadius: 8,
    background: ({ color }) => color,
    padding: '0.6rem',
  },
  titlePercentage: {
    fontFamily: 'Montserrat',
    fontSize: '0.875rem',
    fontWeight: 300,
    textTransform: 'capitalize',
    display: 'flex',
    justifyContent: 'space-between',
    '& span': {
      fontSize: '0.875rem',
      color: '#00a9c7',
      fontWeight: 400,
    },
  },
  progress: {
    marginLeft: 18,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(1280)]: {
      width: '70%',
      maxWidth: 600,
      minWidth: 300,
      margin: '0 auto',
    },
    '& svg': {
      borderRadius: '15px',
    },
  },
  goals: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  goal: {
    fontSize: '0.75rem',
    fontWeight: 200,
    paddingTop: '0.4rem',
  },
  left: {
    fontSize: '0.75rem',
    fontWeight: 200,
    paddingTop: '0.4rem',
  },
}));
