const MISION_ROUND_ICON_1 =
  "https://rokinapp-images.s3.amazonaws.com/MisionsGeneral/N%C2%B01.svg";

const MISION_ROUND_ICON_2 =
  "https://rokinapp-images.s3.amazonaws.com/MisionsGeneral/N%C2%B02.svg";
const MISION_ROUND_ICON_3 =
  "https://rokinapp-images.s3.amazonaws.com/MisionsGeneral/N%C2%B03.svg";
const MISION_ROUND_ICON_4 =
  "https://rokinapp-images.s3.amazonaws.com/MisionsGeneral/N%C2%B04.svg";
const MISION_ROUND_ICON_5 =
  "https://rokinapp-images.s3.amazonaws.com/MisionsGeneral/N%C2%B05.svg";
const MISION_ROUND_ICON_6 =
  "https://rokinapp-images.s3.amazonaws.com/MisionsGeneral/N%C2%B06.svg";
const MISION_ROUND_ICON_7 =
  "https://rokinapp-images.s3.amazonaws.com/MisionsGeneral/N%C2%B017.svg";

export {
  MISION_ROUND_ICON_1,
  MISION_ROUND_ICON_2,
  MISION_ROUND_ICON_3,
  MISION_ROUND_ICON_4,
  MISION_ROUND_ICON_5,
  MISION_ROUND_ICON_6,
  MISION_ROUND_ICON_7,
};
