import React, { useState, useCallback, Dispatch, SetStateAction } from "react";
import { useStyles } from "./M5Step1.style";
import { useMediaQuery } from "@material-ui/core";
import { ButtonPrimary } from "@components/Reusables";
import {
  antImage,
  beerIcon,
  chocolateIcon,
  coffeIcon,
  cookieIcon,
  restaurantIcon,
} from "@images";

type Props = {
  setPage: Dispatch<SetStateAction<number>>;
};

const M5BudgetStep1: React.FC<Props> = ({ setPage }) => {
  const classes = useStyles();
  const icons = [
    {
      name: "Café",
      icon: coffeIcon,
    },
    {
      name: "Snacks",
      icon: cookieIcon,
    },
    {
      name: "Almorzar afuera",
      icon: restaurantIcon,
    },
    {
      name: "Chocolates",
      icon: chocolateIcon,
    },
    {
      name: "Cervezas",
      icon: beerIcon,
    },
  ];

  return (
    <div className={classes.container}>
      <div className={classes.topText}>
        ¿Habías escuchado de los gastos hormiga?
      </div>
      <div className={classes.middleSection}>
        <div className={classes.firstColumn}>
          <div className={classes.textContainer}>
            <span className="text">
              <strong>Pequeños gastos </strong>
              que se hacen de forma
              <strong> repetida </strong>
              en cosas que
              <strong> no son necesarias. </strong> Son montos tan bajos que no
              los consideramos realmente como gastos, pero si los sumamos todos
              en un mes podemos llegar a una suma importante.
            </span>
          </div>
          <div className={classes.iconsLayout}>
            {icons.map((icon) => (
              <div className="exampleContainer">
                <span className="title">{icon.name}</span>
                <img src={icon.icon} alt="icon" />
              </div>
            ))}
          </div>
        </div>
        <div className={classes.secondColumn}>
          <img src={antImage} alt="" />
        </div>
      </div>
      <div className={classes.bottomSection}>
        <ButtonPrimary
          text="Siguiente"
          secondaryVersion
          onClick={() => setPage(2)}
        />
      </div>
    </div>
  );
};

export default M5BudgetStep1;
