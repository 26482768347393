export type InvestorProfileType =
  | "Conservador"
  | "Conservador Moderado"
  | "Moderado"
  | "Moderado Agresivo"
  | "Agresivo";

export const InvestorProfileTypes: InvestorProfileType[] = [
  "Conservador",
  "Conservador Moderado",
  "Moderado",
  "Moderado Agresivo",
  "Agresivo",
];

export type InvestType =
  | "Money Market"
  | "Deuda"
  | "Balanceados"
  | "Alternativos"
  | "Accionarios";

export const InvestTypes: InvestType[] = [
  "Money Market",
  "Deuda",
  "Balanceados",
  "Alternativos",
  "Accionarios",
];

export type InvestmentTermType =
  | "Menos de 1 año"
  | "Entre 1 y 3 años"
  | "Entre 3 y 5 años"
  | "Más de 5 años";

export const investorProfileRelevantFields = [
  "investmentTerm",
  "risk",
  "investmentExperience",
  "investmentConcern",
  "currentInvesting",
  "investmentDropResponse",
];

export type InvestorProfileGoal =
  | "Ahorrar"
  | "Pagar deudas"
  | "Mejorar mi jubilacion"
  | "Viajar"
  | "Comprar una casa"
  | "Otro";

export const InvestorProfileGoals: InvestorProfileGoal[] = [
  "Ahorrar",
  "Pagar deudas",
  "Mejorar mi jubilacion",
  "Viajar",
  "Comprar una casa",
  "Otro",
];

export type InvestorProfileAhorro =
  | "Pimienta Conservadora"
  | "Comino Moderado"
  | "Merken Arriesgado";

export const InvestorProfileAhorros: InvestorProfileAhorro[] = [
  "Pimienta Conservadora",
  "Comino Moderado",
  "Merken Arriesgado",
];
