export interface SubscriptionPlans {
  id: number;
  updatedAt: Date;
  name: string;
  subtitle: string;
  price: number;
  periodicity: string;
  image: string;
  paymentLink: string;
  paymentLinkDiscount: string;
  annualPaymentLink: string;
  annualPaymentLinkDiscount: string; //annualPaymentLinkDiscount mensualPaymentLink
  isRecommended: boolean;
  needSchedule: boolean;
  benefits: JSON;
}

export const enum SubscriptionPeriodicityEnum {
  monthly = "mensual",
  anual = "anual",
  unique = "1 vez",
}
