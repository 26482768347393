import { ApolloClient, gql, useLazyQuery, useQuery } from "@apollo/client";

export const GET_URL_IMAGES = gql`
  query {
    getIdentityImages
  }
`;
type QueryResult = {
  getIdentityImages: string[];
};

interface Urls {
  urls: string[] | null;
  loading: boolean;
}

export const getIdentityImagesUrl = (): Urls => {
  const { data, loading } = useQuery<QueryResult>(GET_URL_IMAGES);
  return { urls: data?.getIdentityImages ?? null, loading };
};
