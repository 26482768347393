import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    // width: "auto",
    padding: "4rem 11.25rem",
    backgroundColor: "#F4F3F3",
    [theme.breakpoints.down(1300)]: {
      flexDirection: "column",
      display: "flex",
      alignItems: "center",
      padding: "2rem 0 0rem",
    },
  },
  firstSection: {
    display: "flex",
    flexDirection: "column",
    marginTop: "2rem",
    [theme.breakpoints.down(1300)]: {
      borderRadius: "1.875rem",
      boxShadow: " 0.25rem 0.25rem 1.25rem 0.125rem rgba(0,0,0,0.2)",
      backgroundColor: "#ffffff",
      padding: "2.375rem 2rem",
      width: "100%",
      marginTop: "-5rem",
      alignItems: "center",
    },
    "& .textContainer": {
      width: "30rem",
      [theme.breakpoints.down(1300)]: {
        width: "auto",
      },
    },
    "& .whiteBox": {
      height: "auto",
      width: "69.5rem",
      margin: "3rem auto",
      padding: "2.375rem 6.75rem 2.125rem 3.1875rem",
      borderRadius: "1.875rem",
      boxShadow: " 0.25rem 0.25rem 1.25rem 0.125rem rgba(0,0,0,0.2)",
      backgroundColor: "#ffffff",
      "& >img": {
        width: "25.75rem",
        height: "auto",
        position: "absolute",
        marginTop: "-18.5rem",
        marginLeft: "39.563rem",
        [theme.breakpoints.down(1300)]: {
          position: "relative",
          margin: "0 auto",
          width: "11rem",
        },
      },
      [theme.breakpoints.down(1300)]: {
        order: 2,
        borderRadius: "0",
        boxShadow: "none",
        backgroundColor: "#ffffff",
        padding: "0",
        margin: 0,
        width: "auto",
      },
      "& .formBox": {
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        height: "20rem",
        justifyContent: "space-around",
        flexWrap: "wrap",
        alignContent: "flex-start",
        [theme.breakpoints.down(1300)]: {
          flexWrap: "nowrap",
          height: "auto",
          alignItems: "flex-start",
        },

        "& .formItem": {
          margin: "1rem 8rem 0rem 0rem",
          display: "flex",
          flexDirection: "column",
          [theme.breakpoints.down(1300)]: {
            margin: "1rem 0rem 0rem 0rem",
          },
          "& .name": {
            fontFamily: "Montserrat",
            fontSize: 20,
            fontWeight: "normal",
            lineHeight: 2.2,
            textAlign: "left",
            color: theme.palette.text.primary,
            [theme.breakpoints.down(1300)]: {
              fontSize: "0.85rem",
            },
          },
          "& .area": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            "& >span": {
              fontSize: "1.5rem",
              fontWeight: 400,
              textAlign: "left",
              marginLeft: "1rem",
              color: theme.palette.text.secondary,
              [theme.breakpoints.down(1300)]: {
                fontSize: "1rem",
              },
            },
          },
          "& >input": {
            height: "3rem !important",
          },
        },
      },
      "& .buttonContainer": {
        textAlign: "right",
        [theme.breakpoints.down(1300)]: {
          textAlign: "center",
          marginTop: "2rem",
        },
      },
    },
  },
  text: {
    fontFamily: "Montserrat",
    fontSize: 16,
    lineHeight: 1.88,
    width: "30rem",
    textAlign: "left",
    [theme.breakpoints.down(1300)]: {
      fontSize: 12,
      lineHeight: 1.67,
      width: "auto",
      margin: "0",
    },
    color: theme.palette.text.secondary,
    "& .style1": {
      fontWeight: "bold",
    },
  },
}));
