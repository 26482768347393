const WALLET_IMAGE =
  "https://storage.googleapis.com/rokinapp-images/Services/customServices/wallet_image.png";
const DEPARTMENT_ICON =
  "https://storage.googleapis.com/rokinapp-images/Services/Sure/department_icon.svg";
const HOUSE_ICON =
  "https://storage.googleapis.com/rokinapp-images/Services/Sure/house_icon.svg";
const TEMPORALLY_ICON =
  "https://storage.googleapis.com/rokinapp-images/Services/Sure/southbridge.svg";
const SOUTHBRIDGE_ICON =
  "https://storage.googleapis.com/rokinapp-images/Services/Sure/southbridge%20(1).svg";
const BACK_ICON =
  "https://storage.googleapis.com/rokinapp-images/Services/Sure/back_icon%20(3).svg";
const ASSIST_CARD_ICON =
  "https://storage.googleapis.com/rokinapp-images/Services/Sure/southbridge.png";
const CHEST_COINS = 
  "https://storage.googleapis.com/rokinapp-images/Services/Sure/cofre_modal.png";
export {
  DEPARTMENT_ICON,
  HOUSE_ICON,
  TEMPORALLY_ICON,
  SOUTHBRIDGE_ICON,
  BACK_ICON,
  ASSIST_CARD_ICON,
  WALLET_IMAGE,
  CHEST_COINS
};
