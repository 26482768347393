import { isEqual } from "lodash";
import { analizeAmountSelections, followersMarks } from "@components";
import {
  RISK_LEVELS,
  SAVED_CAPITAL_OPTIONS,
  SMART_RISK_OPTIONS,
} from "@components";
import { ProfileForm, ActiveFilters, FundWithSeries, Serie } from "@interfaces";
import goalOverRisk from "./motivo_sobre_riesgo";

export const defaultFilters = {
  manager: [],
  assetType: [],
  category: [],
  fundType: [],
  amount: [0, 9],
  currency: [],
  term: [],
  redeemable: false,
  risk: null,
  isNew: undefined,
  availableToInvest: false,
  userSelectedAvailable: false,
} as ActiveFilters;

interface FilterInvestments {
  fundsWithSeries: FundWithSeries[];
  filters: ActiveFilters;
  isFavoriteFilterActive?: boolean;
  favoriteSeries?: number[];
  profileFilterActive?: boolean;
  profileForm?: ProfileForm | null;
  riskHardFilter?: boolean;
}
export const getFilteredInvestments = ({
  fundsWithSeries,
  filters,
  isFavoriteFilterActive,
  favoriteSeries,
  profileFilterActive,
  profileForm,
  riskHardFilter,
}: FilterInvestments): FundWithSeries[] => {
  // If profile filter is not active, use classic filtering
  let filteredInvestments = fundsWithSeries;
  if (profileFilterActive && profileForm) {
    const { filteredInvests } = filterByProfileData(
      profileForm,
      fundsWithSeries,
    );
    filteredInvestments = filteredInvests;
  }
  const filtered = filteredInvestments
    .filter(
      invest =>
        matchManager(invest, filters.manager) &&
        matchCategory(invest, filters.category) &&
        matchFundType(invest, filters.fundType) &&
        matchRiskLevel(invest, filters.risk, riskHardFilter) &&
        matchTerm(invest, filters.term) &&
        matchCurrency(invest, filters.currency) &&
        matchRedeemable(invest, filters.redeemable) &&
        matchEnabledInvestments(invest, filters.availableToInvest),
    )
    .map(inv => ({
      ...inv,
      series: inv.series.filter(s => {
        return (
          matchAmount(s, filters.amount) &&
          (!isFavoriteFilterActive ||
            !favoriteSeries ||
            isFavorite(s, favoriteSeries)) &&
          (!filters.fundType?.includes("APV") || s.isAPV)
        );
      }),
    }))
    .filter(inv => inv.series.length);

  return filtered;
};

interface FilterProfileReturn {
  filteredInvests: FundWithSeries[];
  maxAmountFilter?: number;
  nearRiskOptions: number[];
  riskIndexesByGoal: number[];
}
const filterByProfileData = (
  profileForm: ProfileForm,
  fundsWithSeries: FundWithSeries[],
): FilterProfileReturn => {
  const { mainGoal, savedCapital } = profileForm;

  let filteredInvests = fundsWithSeries;

  // Apply saved capital filter
  const savedCapitalOption = SAVED_CAPITAL_OPTIONS.find(
    o => o.name === savedCapital,
  );
  const maxAmountFilter = savedCapitalOption?.max;
  filteredInvests = filteredInvests.map(inv => ({
    ...inv,
    series: inv.series.filter(s => profileMatchAmount(s, maxAmountFilter)),
  }));

  // Apply +-1 risk filter
  const selectedRiskIndex =
    SMART_RISK_OPTIONS.findIndex(opt => opt.name === profileForm.risk) + 1;
  const nearRiskOptions = RISK_LEVELS.map((_, i) => i + 1).filter(
    i => Math.abs(i - selectedRiskIndex) <= 1,
  );
  filteredInvests = filteredInvests.filter(
    inv => inv.fund.risk && nearRiskOptions.includes(inv.fund.risk),
  );

  // Apply mainGoal over risk filter, only if it not produces an empty set
  const allowedRisks = profileGoalOverRisk(mainGoal);
  const riskIndexesByGoal = RISK_LEVELS.filter(risk =>
    allowedRisks.includes(risk),
  ).map(risk => RISK_LEVELS.indexOf(risk) + 1);
  const possibleFilteredInvests = filteredInvests.filter(
    inv => inv.fund.risk && riskIndexesByGoal.includes(inv.fund.risk),
  );
  if (possibleFilteredInvests.length) filteredInvests = possibleFilteredInvests;

  return {
    filteredInvests,
    maxAmountFilter,
    nearRiskOptions,
    riskIndexesByGoal,
  };
};

const profileMatchAmount = (serie: Serie, max?: number): boolean => {
  return !max || !serie.minInvest || serie.minInvest <= max;
};

const profileGoalOverRisk = (mainGoal: string): string[] => {
  if (!Object.keys(goalOverRisk).includes(mainGoal)) {
    throw new Error(`Opción '${mainGoal}' de motivo no encontrada en el JSON`);
  }
  return Object.entries(goalOverRisk[mainGoal])
    .filter(([, value]) => Boolean(value))
    .map(([risk]) => risk);
};

const matchManager = (
  invest: FundWithSeries,
  managers: ActiveFilters["manager"],
): boolean => {
  return !managers?.length || managers.includes(invest.agf.name);
};
const matchCategory = (
  invest: FundWithSeries,
  types: ActiveFilters["category"],
): boolean => {
  return (
    !types?.length ||
    !invest.fund.category ||
    types.includes(invest.fund.category)
  );
};
const matchFundType = (
  invest: FundWithSeries,
  types: ActiveFilters["fundType"],
): boolean => {
  return (
    !types?.length ||
    (types.length === 1 && types.includes("APV")) ||
    types.includes(invest.fund.fundType)
  );
};
const matchRiskLevel = (
  invest: FundWithSeries,
  level: ActiveFilters["risk"],
  riskHardFilter?: boolean,
): boolean => {
  return riskHardFilter
    ? invest.fund.risk === level
    : !invest.fund.risk || !level || invest.fund.risk <= level;
};
const matchTerm = (
  invest: FundWithSeries,
  term: ActiveFilters["term"],
): boolean => {
  return !invest.fund.term || !term?.length || term.includes(invest.fund.term);
};
const matchAmount = (
  serie: Serie,
  limits: ActiveFilters["amount"],
): boolean => {
  if (!limits) return true;
  const { firstUnselected, lastUnselected, lowerMark, upperMark } =
    analizeAmountSelections(limits);
  if (firstUnselected && lastUnselected) return true;
  const minValue = lowerMark.scaledValue;
  const maxValue = upperMark.scaledValue;
  const matchMinValue =
    firstUnselected || !serie.minInvest || minValue <= serie.minInvest;
  const matchMaValue =
    lastUnselected || !serie.minInvest || serie.minInvest <= maxValue;
  return matchMinValue && matchMaValue;
};
const matchCurrency = (
  invest: FundWithSeries,
  currencies: ActiveFilters["currency"],
): boolean => {
  return !currencies?.length || currencies.includes(invest.fund.currency);
};
const matchRedeemable = (
  invest: FundWithSeries,
  redeemable: ActiveFilters["redeemable"],
): boolean => {
  return !redeemable || invest.fund.redeemable;
};
const matchEnabledInvestments = (
  invest: FundWithSeries,
  enabledInvestments: ActiveFilters["availableToInvest"],
): boolean => {
  return !enabledInvestments || invest.fund.enabledInvestments;
};
const isFavorite = (serie: Serie, favoriteInvests: number[]) =>
  favoriteInvests.includes(serie.id);

export const areFiltersEquals = (
  filter1: ActiveFilters,
  filter2: ActiveFilters,
): boolean => {
  return (Object.keys(filter1) as (keyof ActiveFilters)[]).every(key =>
    isEqual(filter1[key], filter2[key]),
  );
};

// TODO: refactorizar ._.
export const translateSavedCapitalIntoAmmountFilterValue = (
  savedCapital: string,
): number[] => {
  const option = SAVED_CAPITAL_OPTIONS.find(opt => opt.name === savedCapital);
  // This should never happens.
  if (!option) return [0, 9];
  let mark;
  for (const m of followersMarks) {
    if (m.scaledValue <= (option.max as number)) mark = m;
    else break;
  }
  if (mark) return [0, mark.value];
  return [0, 9];
};
