import { useContext, useEffect, useState } from "react";
import { AppContext } from "@context";
import {
  FundWithSeries,
  GoalInvestmentAgfOption,
  GoalInvestmentFundOption,
  GoalInvestmentSerieOption,
} from "@interfaces";
import { flatten, isEqual, sortBy, uniqWith } from "lodash";

type GoalInvestsOptionsHookReturn = [
  GoalInvestmentAgfOption[],
  GoalInvestmentFundOption[],
  GoalInvestmentSerieOption[],
];
export const useInvestmentsOptions = (): GoalInvestsOptionsHookReturn => {
  // Get investments from context
  const { fundsWithSeries } = useContext(AppContext);
  // Save agf options
  const [agfOptions, setAgfOptions] = useState<GoalInvestmentAgfOption[]>(() =>
    generateAgfOptions(fundsWithSeries),
  );
  // Save fund options
  const [fundOptions, setFundOptions] = useState<GoalInvestmentFundOption[]>(
    () => generateFundOptions(fundsWithSeries),
  );
  // Save options on state variable
  const [serieOptions, setSerieOptions] = useState<GoalInvestmentSerieOption[]>(
    () => sortBy(generateSerieOptions(fundsWithSeries), "name"),
  );
  // Update options on investments changes
  useEffect(() => {
    setAgfOptions(generateAgfOptions(fundsWithSeries));
    setFundOptions(generateFundOptions(fundsWithSeries));
    setSerieOptions(generateSerieOptions(fundsWithSeries));
  }, [fundsWithSeries]);
  // Return options
  return [agfOptions, fundOptions, serieOptions];
};

const generateAgfOptions = (
  investments: FundWithSeries[],
): GoalInvestmentAgfOption[] =>
  sortBy(
    uniqWith(
      investments.map(inv => ({
        name: inv.agf.name,
        value: inv.agf.id,
      })),
      isEqual,
    ),
    "name",
  );
const generateFundOptions = (
  investments: FundWithSeries[],
): GoalInvestmentFundOption[] =>
  sortBy(
    uniqWith(
      investments.map(inv => ({
        agfId: inv.agf.id,
        name: inv.fund.name,
        value: inv.fund.id,
      })),
      isEqual,
    ),
    "name",
  );
const generateSerieOptions = (
  investments: FundWithSeries[],
): GoalInvestmentSerieOption[] =>
  sortBy(
    flatten(
      investments.map(inv =>
        inv.series.map(serie => ({
          agfId: inv.agf.id,
          fundId: inv.fund.id,
          name: serie.name,
          value: serie.id,
        })),
      ),
    ),
    "name",
  );
