import { ApolloClient, gql, useQuery } from '@apollo/client';
import { UserFinancialData } from '@interfaces';

export const GET_USER_FINANCIAL_DATA = gql`
  query UserIdeaFinancialData {
    userIdeaFinancialData {
      id
      averageIncome
      variableExpenses
      fixedExpenses
      debts
      savings
      defaultDebts
      variableIncome
    }
  }
`;

interface QueryResult {
  userIdeaFinancialData: UserFinancialData;
}

export const getUserIdeaFinancialData = () => {
  const { data } = useQuery<QueryResult>(GET_USER_FINANCIAL_DATA);
  return { data };
};
