import { makeStyles } from "@material-ui/core";
import { BorderRight } from "@material-ui/icons";

export const useStyles = makeStyles((theme) => ({
  weelySize: {},
  MobileCalendar: {},
  calendarContainer: {
    margin: "0rem 0 0 0",
    [theme.breakpoints.down(1280)]: {
      order: 0,
    },
  },

  container: {
    // backgroundImage: "linear-gradient(141deg, #0a5669 10%, #00a9c2 110%)",
    height: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "flex-start",
    borderRadius: "0 0 43px 43px",
    [theme.breakpoints.down(1240)]: {
      flexDirection: "column",
      height: "auto",
      margin: "0",
      padding: "0",
      borderRadius: "43px  43px 0 0 ",
    },
  },
  leftColumn: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down(1239)]: {
      display: "initial",
      margin: "0 auto",
    },
  },
  infoContainer: {
    margin: "8rem 0 0 0 ",

    borderRadius: "43px",
    height: "auto",
    [theme.breakpoints.down(1240)]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      margin: "0 ",
      boxShadow: "none",
    },
  },
  filters: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "27rem",
    backgroundColor: "#edebeb",
  },
  mobileBottomContainer: {
    backgroundColor: "#edebeb",
    BorderRadius: "30.5px 30.5px 0 0 ",
  },
  Calendarfilters: {
    display: "flex",

    flexDirection: "column",
    alignItems: "center",
    height: "auto",
    width: "32rem",
    backgroundColor: "#edebeb",
    margin: "10rem 0 3rem 0",
    borderRadius: "43px",
    [theme.breakpoints.down(1240)]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      margin: "0 ",
      boxShadow: "none",
    },
  },
  mobileContainer: {
    width: "100%",
  },
  mobileInfoContainer: {
    width: "100%",
  },
  mobileFilter: {
    width: "100%",
  },
  mobileGroup: {
    backgroundColor: "#edebeb",
    borderRadius: "43px 43px 0 0",
    width: "100%",
  },
}));
