import { Manager, Fund, Serie } from "@interfaces";

/* ENUMS */
export enum InvestmentStatus {
  OK = "OK",
  DEPOSIT_PENDING = "DEPOSIT_PENDING", //Esperando confirmación del depósito
  DEPOSIT_BEING_INVESTED = "DEPOSIT_BEING_INVESTED", //en proceso de inversion
  WITHDRAWAL_PENDING = "WITHDRAWAL_PENDING", //En proceso de venta
  WITHDRAWAL_SOLD = "WITHDRAWAL_SOLD", //Se vendieron exitosamente las cuotas y se encuentra en caja el dinero
  WITHDRAWAL_PENDING_TRANSFER = "WITHDRAWAL_PENDING_TRANSFER", //Por depositar
  WITHDRAWAL_SENT_WS = "WITHDRAWAL_SENT_WS", //En proceso de deposito
}
export enum InvestmentType {
  REAL = "REAL",
  MONITOR = "MONITOR",
}
export enum MovementType {
  DEPOSIT = "DEPOSIT",
  WITHDRAWAL = "WITHDRAWAL",
}
export enum MovementStatus {
  PENDING = "PENDING",
  SENT_WS = "SENT_WS",
  SENT_VECTOR = "SENT_VECTOR",
  BANK_WITHDRAWAL_DONE = "BANK_WITHDRAWAL_DONE",
  SUCCESSFUL = "SUCCESSFUL",
  BANK_DEPOSIT_DONE = "BANK_DEPOSIT_DONE",
}
export enum InvestorProfilePageMode {
  SEARCH_FOUNDS = "SEARCH_FOUNDS",
  FUNDS_SUGGESTED = "FUNDS_SUGGESTED",
}

/* ENTITIES */
export interface Movement {
  id: number;
  date: string;
  amount: number;
  type: MovementType;
  status: MovementStatus;
}
export interface DBInvestment {
  id: number;
  type: InvestmentType;
  agfId: number;
  fundId: number;
  serieId: number;
  goalId: number;
  totalDeposited: number;
  totalInvested: number;
  pendingInvestments: number;
  pendingWithdrawals: number;
  current: number;
  profitability: number;
  status: InvestmentStatus;
  movements: Movement[];
}
export type Investment = Omit<DBInvestment, "agfId" | "fundId" | "serieId"> & {
  agf: Manager;
  fund: Fund;
  serie: Serie;
};

/* FORMS */

export type FormInvestment = {
  type: InvestmentType;
  agfId: number | null;
  fundId: number | null;
  serieId: number | null;
  invested: number | null;
  date?: string | null;
  dontRememberDate?: boolean;
  investFrequency?: string;
  period?: number;
  goalId?: number | null;
};

export interface FormMovement {
  type: MovementType;
  date: string | null;
  amount: number | null;
  serieId: number | null;
}

/* HELPERS */
export const investmentToDBInvestment = (inv: Investment): DBInvestment => ({
  id: inv.id,
  type: inv.type,
  agfId: inv.agf.id,
  fundId: inv.fund.id,
  serieId: inv.serie.id,
  goalId: inv.goalId,
  totalInvested: inv.totalInvested,
  totalDeposited: inv.totalDeposited,
  pendingInvestments: inv.pendingInvestments,
  pendingWithdrawals: inv.pendingWithdrawals,
  current: inv.current,
  profitability: inv.profitability,
  status: inv.status,
  movements: inv.movements,
});

export interface CashBalance {
  id: number;
  date: string;
  currentValue: number;
}
