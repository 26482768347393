import React from "react";
import { Image1, Group2, Image3 } from "@images";
import { makeStyles } from "@material-ui/core";

const DetailList: React.FC = () => {
  const classes = useStyles();

  const items = [
    {
      key: "Step1",
      image: Image1,
      title: "1. Compra en los comercios como siempre",
      subtitle:
        "Presencialmente y en tiendas online con tus medios de pago preferidos",
      classImg: classes.img1,
      classTxt: classes.txt1,
    },
    {
      key: "Step2",
      image: Group2,
      title: "2. Una parte de tu compra se  transforma en inversión",
      subtitle:
        "Por ejemplo, si pagaste $20.000, un 5% se invertirá directo a tu cuenta Rokin ",
      classImg: classes.img2,
      classTxt: classes.txt2,
    },
    {
      key: "Step3",
      image: Image3,
      title: "3. Tus ahorros crecen y generas impacto social",
      subtitle: "Cada ahorro se invierte en fondos que impactan a la sociedad",
      classImg: classes.img3,
      classTxt: classes.txt3,
    },
  ];
  return (
    <section className={classes.containerImg}>
      {items.map(item => (
        <React.Fragment key={item.key}>
          <img id={item.key} className={item.classImg} src={item.image} />
          <div className={item.classTxt}>
            <div>
              <span>{item.title}</span>
              <span>{item.subtitle}</span>
            </div>
          </div>
        </React.Fragment>
      ))}
    </section>
  );
};

export default DetailList;

const useStyles = makeStyles(theme => ({
  txt1: {
    gridArea: "txt1",
  },
  txt2: {
    gridArea: "txt2",
    "& > div": {
      textAlign: "end",
      "& span:first-child": {
        color: "#625c20!important",
        [theme.breakpoints.down(1100)]: {
          width: "auto!important",
        },
      },
      [theme.breakpoints.down(1100)]: {
        textAlign: "center",
      },
    },
  },
  containerImg: {
    padding: "2rem 0 5rem",
    display: "grid",
    justifyContent: "center",
    gridTemplateAreas: `"img1 txt1"
    "txt2 img2"
    "img3 txt3"`,
    gridTemplateColumns: "500px 440px",
    gap: "3rem 8rem",
    [theme.breakpoints.down(1200)]: {
      gap: "3rem 3rem",
    },
    [theme.breakpoints.down(1100)]: {
      gridTemplateAreas: `
          "txt1"
          "img1 "
          "txt2"
          "img2"
          "txt3"
          "img3"`,
      gridTemplateColumns: 380,
    },
    [theme.breakpoints.down(400)]: {
      gridTemplateColumns: 330,
    },

    "& > div": {
      display: "grid",
      alignItems: "center",
      "& div": {
        padding: 10,
        "& :first-child": {
          fontFamily: "Montserrat",
          fontSize: 30,
          fontWeight: "bold",
          letterSpacing: 0.72,
          display: "block",
          color: theme.palette.primary.main,
          width: 440,
          [theme.breakpoints.down(1100)]: {
            fontSize: 26,
            width: "auto",
            textAlign: "center",
          },
        },
        "& :last-child": {
          fontFamily: "Montserrat",
          fontSize: 22,
          fontWeight: 500,
          lineHeight: 1.46,
          color: "#484848",
          width: 440,
          display: "block",
          [theme.breakpoints.down(1100)]: {
            width: "auto",
            textAlign: "center",
            fontSize: 16,
          },
        },
      },
    },
    "& img": {
      width: 500,
      [theme.breakpoints.down(1100)]: {
        margin: "0 auto",
      },
    },
  },
  txt3: {
    gridArea: "txt3",
  },
  img1: {
    gridArea: "img1",
  },
  img2: {
    gridArea: "img2",
  },
  img3: {
    gridArea: "img3",
  },
}));
