import { ApolloClient, gql } from "@apollo/client";

const UPDATE_EVENT = gql`
mutation UpdateEvent($updateEventId: ID!, $data: UpdateEventParams) {
    updateEvent(id: $updateEventId, data: $data) {
      amount
      category
      recurrence
      name
      date
      description
      showInCalendar
      type
      url
    }
  }
`;

type UpdateEventParams = {
    'updateEventId': any,
    'data': any
};

export async function updateEventMutation(client: ApolloClient<object>, updateEventId: any, data: any): Promise<boolean> {
    const result = await client.mutate<boolean, UpdateEventParams>({
        mutation: UPDATE_EVENT,
        variables: { updateEventId: updateEventId, data: data},
        awaitRefetchQueries: true,
    });
    return !!result.data;
}
