import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: "6rem 6rem 6rem 4rem",
    backgroundColor: "#edebeb",
    "& .email": {
      margin: "20px auto 0",
      width: "245px",
      justifyContent: "center",
      display: "flex",
    },
    "& .checkContainer": {
      display: "flex",
      alignItems: "start",
    },
    "& .check ": {
      fontSize: "0.75rem",
      display: "block",
      color: theme.palette.text.secondary,
      paddingTop: "0.5rem",
    },
  },
  topSection: {
    display: "flex",
    flexDirection: "row",
    margin: "0rem 0rem 4rem",
    justifyContent: "center",
    "& .item": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      fontFamily: "Montserrat",
      marginRight: "4.9rem",
      "& .title": {
        fontSize: "0.85rem",
        textTransform: "uppercase",
        fontWeight: 500,
        color: "#9d9d9d",
        lineHeight: 2.78,
      },
      "& .value": {
        fontSize: "1.25rem",
        fontWeight: 500,
        color: theme.palette.text.primary,
        lineHeight: 1.95,
      },
    },
  },
  middleSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  firstColumn: {
    display: "flex",
    flexDirection: "column",
    "& .firstWhite": {
      width: "39rem",
      padding: "2.063rem 1.5rem 3.438rem 3.188rem",
      opacity: 0.9,
      borderRadius: "1.875rem",
      boxShadow: "4px 4px 8px 2px rgba(0, 0, 0, 0.2)",
      backgroundColor: theme.palette.background.paper,
      "& .text": {
        fontFamily: "Montserrat",
        fontSize: "1.2rem",
        fontWeight: "normal",
        lineHeight: 2.2,
        textAlign: "left",
        color: theme.palette.text.secondary,
      },
      "& .mailTag": {
        display: "flex",
        flexDirection: "row",
        width: "16.563rem",
        height: "2.75rem",
        margin: "1.875rem 9.813rem 0 8.125rem",
        borderRadius: "0.5rem",
        border: "solid 1px #858c94",
        backgroundColor: "#ffffff",
        justifyContent: "space-around",
        alignItems: "center",
        "& >img": {
          width: "1.5rem",
          height: "1.5rem",
        },
        "& .mail": {
          fontFamily: "Montserrat",
          fontSize: "1rem",
          textAlign: "left",
          color: "#7d7d7d",
        },
      },
    },
    "& .buttonsSection": {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      marginTop: "2.5rem",
      justifyContent: "space-around",
      "& >button": {
        width: "15.5rem",
      },
    },
  },
  secondColumn: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "4rem",
    "& .purpleContainer": {
      display: "flex",
      flexDirection: "row",
      width: 592,
      justifyContent: "space-between",
      "& .box": {
        width: "15.5rem",
        padding: "2rem 1rem",

        "& .text": {
          fontSize: "1rem !important",
        },
      },
    },
    "& .purpleBox": {
      display: "flex",
      flexDirection: "row",
      width: "26rem",
      height: "8.43rem",
      // marginRight: "9.513rem",
      borderRadius: "1.875rem",
      backgroundColor: "#8352fd",
      boxShadow: "4px 4px 8px 2px rgba(0, 0, 0, 0.2)",
      padding: "2rem",
      alignItems: "center",
      marginBottom: "3rem",
      "& .text": {
        fontFamily: "Montserrat",
        fontSize: "1.25rem",
        fontWeight: "bold",
        lineHeight: 1.5,
        textAlign: "left",
        color: "#ffffff",
      },
      "& >img": {
        width: "2.5rem",
        height: "2.5rem",
        marginRight: "2.5rem",
      },
    },
    "& .secondWhite": {
      maxWidth: "35rem",
      padding: "2.063rem 1.5rem 3.438rem 3.188rem",
      borderRadius: "1.875rem",
      boxShadow: "4px 4px 8px 2px rgba(0, 0, 0, 0.2)",
      backgroundColor: theme.palette.background.paper,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "space-evenly",
      fontSize: "0.85rem",

      "& .title": {
        fontFamily: "Montserrat",
        textTransform: "uppercase",
        textAlign: "left",
        fontWeight: "bold",
        lineHeight: 2.78,
        color: theme.palette.text.secondary,
      },
      "& .text": {
        textAlign: "left",
        fontWeight: "normal",
        lineHeight: 2.71,
        color: "#7d7d7d",
      },
      "& .buttonsSection": {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
      },
    },
  },
  arrow: {
    width: 56,
    background: "white",
    height: 56,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "100%",
    boxShadow: "4px 4px 8px 2px rgba(0, 0, 0, 0.2)",
  },
  upDate: {
    marginLeft: 15,
    "& div:last-child": {
      fontSize: 14,
      textAlign: "center",
      color: theme.palette.text.primary,
      marginTop: 10,
    },
  },
}));
