import React, { useEffect, useState } from 'react';
import { useStyles } from './IncomeCard.style';
import { ProgressBarLine } from 'react-progressbar-line';
import { parseNumber } from '@utils';

interface Card {
  name: string;
  total: number;
  amount: number;
  percentage: number;
  type: string;
  goal: number;
  icon: string;
  color: string;
}

const IncomeCard = ({ name, total, amount, percentage, type, goal, icon, color }: Card) => {
  useEffect(() => {
    setGoalState(goal);
  }, [goal]);

  useEffect(() => {
    setAmountState(amount);
  }, [amount]);

  const classes = useStyles({ color: color });

  const left = Math.max(0, goal - amount);

  const [goalState, setGoalState] = useState(100);
  const [amountState, setAmountState] = useState(5);
  return (
    <>
      <div className={classes.container}>
        <img className={`${classes.iconBox}`} src={icon} />
        <div className={classes.progress}>
          <div className={classes.titlePercentage}>
            {name}
            <span>${parseNumber(amount)}</span>
          </div>
          <ProgressBarLine
            value={amountState}
            min={1}
            max={goalState}
            text=" "
            styles={{
              path: {
                stroke: color,
              },
            }}
          />
          <div className={classes.goals}>
            <div className={classes.goal}>Meta: $ {parseNumber(goal)}</div>
            <div className={classes.left}>Quedan: $ {parseNumber(left)}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IncomeCard;
