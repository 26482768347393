import React from "react";
import { Button as MuiButton, makeStyles } from "@material-ui/core";

type Props = {
  className?: string;
  variant?: 1 | 2;
  text: string;
  onClick: () => void;
  disabled?: boolean;
};
const useStyles = makeStyles(
  theme => ({
    btn: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "14px",
      letterSpacing: "0.28px",
      fontweight: 600,
      borderRadius: 8,
      //padding: '8px 64px',
      cursor: "pointer",
      whiteSpace: "nowrap",
      userSelect: "none",
      textAlign: "center",
    },
    btnPrimary: {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: "white",
      },
    },
    btnSecondary: {
      border: "solid 2px #d8d8d8",
      color: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.00)",
      },
    },
  }),
  { index: 1 },
);
export const Button: React.FC<Props> = ({
  className,
  variant = 1,
  text,
  onClick,
  disabled,
}) => {
  const classes = useStyles();
  return (
    <MuiButton
      className={`${classes.btn} ${className} ${
        variant === 1 ? classes.btnPrimary : classes.btnSecondary
      }`}
      variant={variant === 1 ? "contained" : "text"}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </MuiButton>
  );
};
