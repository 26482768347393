import React from "react";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Rokin } from "@images";

interface LoaderProps {
  open: boolean;
}

export const FullLoader: React.FC<LoaderProps> = ({ open }) => {
  const classes = useStyles();
  return (
    <Backdrop
      className={classes.Backdrop}
      open={open}
      style={{ zIndex: 10000 }}
    >
      <CircularProgress size={120} />

    </Backdrop>
  );
};
const useStyles = makeStyles(theme => ({
  Backdrop: {
    display: "flex",
    justifyContent: "center",
  },
  img:{ 
    width: 50
  }
}));
