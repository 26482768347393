import React from 'react';
import { makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import { OperationalInfo, RRSSdata } from '@data';
import { navigate } from 'gatsby';
import { Route } from '@interfaces';
import { LOGIN_WHITE_ICON } from 'images/GoogleCloud';

interface FooterProps {
  className?: string;
}

export const Footer: React.FC<FooterProps> = ({ className }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 1240px)');

  return (
    <footer className={`${className} ${classes.root}`}>
      <nav className={classes.nav}>
        <div className={classes.navDiv}>
          <div className={classes.blockIcon}>
            <img
              src={
                'https://rokinapp-images.s3.amazonaws.com/Landing/redes/Isologotipo+vertical_negativo.svg'
              }
              alt="rokin"
              className={classes.image}
            />
          </div>
          <div className={classes.redes}>
            Síguenos <br />
            <div className={classes.lineDivisor} />
          </div>{' '}
          <ul className={classes.list}>
            {' '}
            <li className={classes.listItem}>
              <a href={RRSSdata.linkedinUrl} target="_blank" rel="noreferrer">
                <img
                  className={classes.imgList}
                  src="https://rokinapp-images.s3.amazonaws.com/Landing/redes/linkedin-svgrepo-com.svg"
                  alt="Linkedin"
                />
              </a>
            </li>
            <li className={classes.listItem}>
              <a href={RRSSdata.instagramUrl} target="_blank" rel="noreferrer">
                <img
                  className={classes.imgList}
                  src="https://rokinapp-images.s3.amazonaws.com/Landing/redes/instagram-svgrepo-com+1.svg"
                  alt="Instagram"
                />
              </a>
            </li>
          </ul>
        </div>
        <div className={classes.containerFooter}>
          <div className={classes.footerGrid}>
            <ul className={classes.listUl}>
              <li className={classes.listUlLast}>
                <div className={classes.titleList}>Rokinapp</div>
                <div className={classes.lineDivisor} />
                <div
                  className={classes.ulLiItems}
                  onClick={() => navigate('/')}
                >
                  Inicio
                </div>{' '}
                <div
                  className={classes.ulLiItems}
                  onClick={() => navigate(Route.ourSolutions)}
                >
                  Nuestras soluciones
                </div>
                <div
                  className={classes.ulLiItems}
                  onClick={() => navigate(Route.aboutUs)}
                >
                  {' '}
                  Quiénes somos
                </div>{' '}
                <div
                  className={classes.ulLiItems}
                  onClick={() => navigate(Route.business)}
                >
                  {' '}
                  Rokin para empresas
                </div>{' '}
                <div className={classes.ulLiItems}>
                  <a
                    href="https://storage.googleapis.com/rokinapp-images/Services/customServices/Rokin-%20TyC%20PP.docx.pdf"
                    target="_blank"
                  >
                    Términos y Condiciones
                  </a>
                </div>{' '}
                <div className={classes.lineDivisor} /> <div />
                <div className={classes.ulLiItems}>
                  <img src={LOGIN_WHITE_ICON} />
                  Entrar
                </div>{' '}
                {/* <div className={classes.ulLiItems}>
                  {OperationalInfo.solutions}
                </div>{" "}
                <div className={classes.ulLiItems}>
                  {OperationalInfo.aboutUs}
                </div>{" "}
                <div className={classes.ulLiItems}>
                  {OperationalInfo.companies}
                </div>{" "}
                <div className={classes.ulLiItems}>Términos y Conficiones</div> */}
              </li>
            </ul>
          </div>
        </div>
        <div className={classes.containerFooter}>
          <ul className={classes.listUl}>
            <li className={classes.listUlLast}>
              <div className={classes.titleList}>¿Tienes preguntas?</div>{' '}
              <div className={classes.lineDivisor} />
              <div className={classes.ulLiItems}>
                {OperationalInfo.mainEmail}
              </div>
              <a
                className={classes.ulLiItems}
                href={`tel:${OperationalInfo.phone}`}
              >
                {OperationalInfo.phone}
              </a>
              <div className={classes.ulLiItems}>
                {OperationalInfo.direction}
              </div>{' '}
              <div
                className={classes.ulLiItems}
                onClick={() => navigate(Route.helpCenter)}
              >
                {OperationalInfo.questions}
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </footer>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    backgroundColor: '#0d3038',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    paddingLeft: '0rem',
    paddingRight: '0.5rem',
    padding: '4rem',
    paddingBottom: '19rem',
    position: 'relative',
    zIndex: 999,
    [theme.breakpoints.down(1240)]: {
      paddingBottom: '9rem',
      padding: '2rem',
    },
  },
  navbar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  image: {
    marginRight: 24,
    marginTop: 19,
    width: '8rem',
    paddingLeft: '1.1rem',
    paddingBottom: 13,
    marginLeft: '2rem',
    [theme.breakpoints.down(1240)]: {
      width: 180,
      marginLeft: '0rem',
    },
  },
  contact: {
    margin: 0,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  li: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
  },
  nav: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    [theme.breakpoints.down(1240)]: {
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
  },
  navDiv: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up(1240)]: {
      paddingRight: 150,
    },
  },
  list: {
    paddingTop: 10,
    marginBottom: 4,
    paddingLeft: '0.3rem',
    marginTop: '1rem',
    flexDirection: 'row',
    listStyle: 'none',
    padding: 0,
    display: 'flex',
    alignItems: 'flex-start',
    marginLeft: '2rem',
    [theme.breakpoints.down(768)]: {
      paddingTop: 0,
      marginBottom: 24,
      justifyContent: 'space-around',
      flexWrap: 'wrap',
      width: 100,
      textAlign: 'start',
      gap: '1rem',
      paddingLeft: '1.5rem',
      marginLeft: '0rem',
      marginTop: '0',
    },
  },
  listItem: {
    marginLeft: '1rem',
    [theme.breakpoints.down(1240)]: {
      marginLeft: 0,
    },
  },
  imgList: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  listUl: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up(768)]: {
      flexDirection: 'row',
    },
  },
  listUlLi: {
    color: 'rgba(255, 255, 255)',
    fontSize: 14,
    lineHeight: '14px',
    marginBottom: 32,
    [theme.breakpoints.up(768)]: {
      margiBottom: 0,
      marginRight: 64,
    },
    [theme.breakpoints.up(1024)]: {
      marginRight: 128,
    },
  },
  titleList: {
    fontFamily: 'Montserrat',
    color: 'rgba(255, 255, 255)',
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '16px',
    marginBottom: 8,
  },
  ulLiItems: {
    marginBottom: 4,
    color: 'rgba(255, 255, 255)',
    fontSize: '1rem',
    textDecoration: 'none',
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
    fontWeight: 100,
    fontFamily: 'Montserrat, sans-serif',
    cursor: 'pointer',
  },
  listUlLiSecond: {
    color: 'rgba(255, 255, 255)',
    fontSize: 14,
    lineHeight: '14px',
    marginBottom: 32,
    [theme.breakpoints.up(768)]: {
      margiBottom: 0,
      marginRight: 64,
    },
    [theme.breakpoints.up(1024)]: {
      marginRight: 128,
    },
  },
  listUlLast: {
    color: 'rgba(255, 255, 255)',
    fontSize: 14,
    lineHeight: 2.5,
  },
  logoText: {
    color: 'white',
    fontSize: '3rem',
    fontWeight: 600,
    height: 69,
    marginRight: '6.25rem',
    [theme.breakpoints.down(1024)]: {
      fontSize: 25,
      height: 41,
      marginTop: '2rem',
    },
  },
  blockIcon: {
    display: 'flex',
    alignItems: 'end',
    [theme.breakpoints.down(1024)]: {
      '& img': {
        width: 100,
      },
    },
  },
  redes: {
    paddingTop: 20,
    fontSize: '1rem',
    fontWeight: 500,
    color: 'white',
    fontFamily: 'Montserrat',
    paddingLeft: '1.2rem',
    marginLeft: '2rem',
    [theme.breakpoints.down(1024)]: {
      fontSize: 14,
      paddingBottom: 10,
      marginLeft: '0rem',
    },
  },
  footerGrid: {
    display: 'grid',
    gap: '2rem',
  },
  containerFooter: {
    display: 'flex',
    paddingTop: 36,
    '& >div': {
      marginRight: '13rem',
      marginTop: '2rem',
    },
    '& >div:first-child': {
      marginTop: 0,
    },
    [theme.breakpoints.down(1240)]: {
      paddingLeft: '1.5rem',
      display: 'grid',
      '& >div': {
        marginRight: 20,
      },
    },
  },
  footerRedes: {
    [theme.breakpoints.down(1240)]: {
      '& ul:first-child': {
        marginTop: '3.2rem',
        paddingLeft: '1.5rem',
      },
    },
  },
  lineDivisor: {
    borderTop: '2px solid #00A9C2',
    width: '2.5rem',
    marginBottom: '0.5rem',
  },
}));
