import { gql, useQuery } from "@apollo/client";
import { TermAndRate } from "@interfaces";

export const GET_DEPOSIT_RATES = gql`
  query GetDepositRates($data: DepositParams) {
    getDepositRates(data: $data) {
      term
      rate
    }
  }
`;

type DepositParams = {
  data: {
    bank: string;
    days: number;
    amount: number;
  };
};

interface HookReturn {
  termAndRate: TermAndRate;
  loading: boolean;
  error: any;
}

type QueryResult = {
  termAndRate: TermAndRate;
};

export const getDepositRates = (
  bank: string,
  days: number,
  amount: number
): HookReturn => {
  const { data, loading, error } = useQuery<QueryResult, DepositParams>(
    GET_DEPOSIT_RATES,
    { variables: { data: { bank, days, amount } } }
  );
  return { termAndRate: data?.getDepositRates ?? null, loading, error };
};

// const bank = "Chile";
// const days = 30;
// const amount = 80000;
// const { termAndRate } = getDepositRates(bank, days, amount);
// console.log("Rate: ", termAndRate);
