import React, { useContext, useState, useEffect } from "react";
import { Dialog, useMediaQuery } from "@material-ui/core";
import { AppContext } from "@context";
import { ButtonPrimary, ButtonOutlined } from "@components/Reusables";
import { EmailInput, RutInput } from "@components/Inputs";
import { makeStyles } from "@material-ui/core";
import { linkLogo } from "@images";
import { useForm } from "@hooks";
import { updateUser, useCurrentUser, getCompanies } from "@apollo";
import { useApolloClient } from "@apollo/client";
import { searchUserCompany } from "@utils";
import { navigate } from "gatsby";
import { Route } from "@interfaces";

interface AddCompanyModalProps {
  open: boolean;
}

export const AddCompanyModal: React.FC<AddCompanyModalProps> = ({ open }) => {
  const classes = useStyles();
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const { closeAddCompanyModal } = useContext(AppContext);
  const [form, , , handleChange] = useForm({});
  const client = useApolloClient();
  // const [rut, setRut] = useState<string>(form.rut ? form.rut : "");
  const [email, setEmail] = useState<string>(form.email ? form.email : "");
  const [step, setStep] = useState<number>(1);
  const [hasCompany, setHasCompany] = useState<any>(false);
  const { user: currentUser } = useCurrentUser();
  const { companies } = getCompanies();
  const [buttonText, setButtonText] = useState<string>("Siguiente");

  const handleClick = async () => {
    if (step == 1) {
      await setHasCompany(searchUserCompany(email, companies)); // cambiar a RUT
      await setStep(2);
    } else if (step == 2) {
      if (hasCompany) {
        try {
          console.log("hasCompany", hasCompany); // <= sale en la consola, imprime bien la indo de la company
          await updateUser(form, true, client); // <= aca se cae
          console.log("cerré el modal");
          closeAddCompanyModal();
        } catch (error) {
          console.log(error);
        }
      } else {
        navigate(Route.investments);
        setStep(1);
        setButtonText("Siguiente");
        closeAddCompanyModal();
      }
    }
  };
  // change email for rut
  useEffect(() => {
    form.email = email;
    if (!email) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [email]);

  useEffect(() => {
    form.company = hasCompany;
    if (hasCompany && step == 2) {
      setButtonText("Vincular");
    } else {
      if (step == 1) {
        setButtonText("Siguiente");
      } else {
        setButtonText("Volver a mis inversiones");
      }
    }
  }, [hasCompany, step]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setStep(1);
          setButtonText("Siguiente");
          closeAddCompanyModal();
        }}
        className={classes.Dialog}
      >
        <div className={classes.Modal}>
          {step == 1 && (
            <div className={classes.info}>
              <div className={classes.titulo}>
                <img src={linkLogo} className={classes.numberImg} />
                <div className={classes.text1}>Vincularme a una empresa</div>
              </div>
              <div className={classes.linea}></div>
              <div className={classes.text2}>
                <div className={classes.text3}>
                  Ingresa tu CORREO para poder vincularte {/* cambiar a rut */}
                </div>
                <EmailInput
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  label="Correo"
                />
                {/* <RutInput
                value={rut}
                onChange={(e) => setRut(e.target.value)}
                label="RUT"
              /> */}
              </div>
            </div>
          )}
          {step == 2 && (
            <div className={classes.info}>
              <div className={classes.titulo}>
                <img src={linkLogo} className={classes.numberImg} />
                <div className={classes.text1}>
                  {hasCompany
                    ? "Encontramos tu empresa"
                    : "Error en la vinculación"}
                </div>
              </div>
              <div className={classes.linea}></div>
              <div className={classes.text2}>
                <div className={classes.text3}>
                  {hasCompany ? (
                    <>
                      Formas parte de la empresa
                      <strong> {hasCompany.name}</strong>. Haz click en
                      “Vincular” para finalizar el proceso
                    </>
                  ) : (
                    <>Al parecer no formas parte de una empresa con Rokin</>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className={classes.textButton}>
            <div>
              {step !== 2 && (
                <ButtonOutlined
                  className={`${classes.buttonClose} ${classes.buttonOutlined}`}
                  text="Cerrar"
                  secondaryVersion
                  onClick={() => {
                    setStep(1);
                    setButtonText("Siguiente");
                    closeAddCompanyModal();
                  }}
                />
              )}
              {step == 2 && hasCompany && (
                <strong
                  className={classes.notmycompany}
                  onClick={() => {
                    closeAddCompanyModal();
                    navigate(Route.index);
                  }}
                >
                  ¿No es tu empresa?
                </strong>
              )}
              <ButtonPrimary
                className={classes.button}
                secondaryVersion
                text={buttonText}
                disabled={buttonDisabled}
                onClick={() => handleClick()}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};
const useStyles = makeStyles(
  (theme) => ({
    Dialog: {
      "& >div": {},
      "& >div >div": {
        borderRadius: "35px",
        position: "absolute",
        top: 195,
        maxWidth: 900,
        [theme.breakpoints.down(600)]: {
          top: 170,
        },
      },
    },
    Modal: {
      width: 600,
      height: 365,
      flexGrow: 0,
      padding: "10px 40px 10px 40px",
      borderRadius: 35,
      border: "1px solid #ffffff",
      [theme.breakpoints.down(600)]: {
        width: 340,
        padding: "10px 15px",
      },
    },
    info: {
      display: "grid",
    },
    titulo: {
      display: "flex",
      gap: 10,
      alignItems: "center",
      [theme.breakpoints.down(1222)]: {
        gap: 15,
      },
    },
    numberImg: {
      width: 110,
      height: "auto",
      [theme.breakpoints.down(600)]: {
        width: 85,
      },
    },
    text1: {
      fontFamily: theme.typography.fontFamily,
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: "#393e44",
      [theme.breakpoints.down(600)]: {
        fontSize: 14,
      },
    },
    linea: {
      width: "100%",
      height: 2,
      flexGrow: 0,
      backgroundColor: "#4619b6",
    },
    text2: {
      minHeight: 100,
      marginTop: 30,
      marginBottom: 20,
      display: "flex",
      flexDirection: "column",
      gap: 15,
      [theme.breakpoints.down(600)]: {
        textAlign: "center",
      },
    },
    textButton: {
      display: "grid",
      justifyContent: "right",
    },
    button: {
      width: "auto",
      height: 50,
      margin: "8px 0 0 20px",
      boxShadow: "2px 4px 5px 1px rgb(0 0 0 / 25%)",
      [theme.breakpoints.down(600)]: {
        width: 270,
      },
    },
    buttonClose: {
      width: 164,
      height: 50,
      margin: "8px 0 0 20px",
      boxShadow: "2px 4px 5px 1px rgb(0 0 0 / 25%)",
      [theme.breakpoints.down(600)]: {
        width: 270,
      },
    },
    buttonOutlined: {
      boxShadow: "1px 2px 5px 1px rgb(0 0 0 / 25%)",
      "& >span": {
        fontWeight: 300,
      },
    },
    text3: {
      fontFamily: "Montserrat",
      fontSize: "1rem",
      fontWeight: "normal",
      paddingBotton: 20,
    },
    notmycompany: {
      cursor: "pointer",
      "&:hover": {
        textDecoration: "underline",
      },
    },
  }),
  { index: 1 }
);
