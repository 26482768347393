import { ButtonPrimary } from "@components/Reusables";
import React, { Dispatch, SetStateAction } from "react";
import { useStyles } from "./M2ApvStep2.styles";
import { useMediaQuery } from "@material-ui/core";

type Props = {
  setPage: Dispatch<SetStateAction<number>>;
};
const M2ApvStep2: React.FC<Props> = ({ setPage }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 1300px)");
  const APVAarray = [
    {
      title: "como funciona",
      big: false,
      text: (
        <span className="text">
          El Estado te aportará un
          <strong> 15% adicional </strong>
          de lo que ahorres durante el año. Este aporte tiene un
          <strong> tope máximo </strong>
          de 6 Unidades Tributarias Mensuales (equivalentes a $ 357.570).
        </span>
      ),
    },
    {
      title: "Sobre impuestos",
      big: false,
      text: (
        <span className="text">
          <strong>Las rentas que genere tu ahorro </strong>
          no pagarán impuestos hasta que estas sean retiradas
        </span>
      ),
    },
    {
      title: "¿Y si retiro mi plata?",
      big: true,
      text: (
        <span className="text">
          Si te arrepientes y deseas retirar estos ahorros antes de tu
          jubilación, puedes hacerlo, pero se devolverá al Estado
          automáticamente el aporte que te hayan realizado.
        </span>
      ),
    },
  ];
  const APVBarray = [
    {
      title: "como funciona",
      big: false,
      text: (
        <span className="text">
          Lo que ahorres cada mes se va a
          <strong> descontar de tu renta imponible </strong>
          por lo que podrás
          <strong> pagar menos impuestos. </strong>
          Es decir, si ahorras en este régimen, es como si lo que ahorraste
          <strong> nunca hubiera sido parte de tu sueldo </strong>, por lo que
          no vas a pagar impuestos sobre ese monto.
        </span>
      ),
    },
    {
      title: "Sobre impuestos",
      big: false,
      text: (
        <span className="text">
          Una vez jubilado, lo que invertiste en APV tendrá impuestos cuando
          recibas tu pensión (los que, dado que estarás jubilado, deberían ser
          más bajos). Por eso, este tipo de APV es más útil para personas con
          sueldos altos.
        </span>
      ),
    },
    {
      title: "¿Y si retiro mi plata?",
      big: true,
      text: (
        <span className="text">
          Si decides retirar tus ahorros de este APV antes de jubilarte, se te
          retendrá un 15% del total de tu retiro hasta la devolución de
          impuestos en abril y ahí deberás regularizar el pago de impuesto de
          acuerdo a tu tramo del Impuesto Global Complementario, además de
          cobrarte una sobretasa extra como penalización, entre un 3% y 7%.
        </span>
      ),
    },
  ];
  return (
    <div className={classes.container}>
      <div className={classes.columnsContainer}>
        {!isMobile ? (
          <>
            <div className={classes.column}>
              <span className={classes.title}>APV A</span>

              {APVAarray.map((item) => (
                <div className="whiteBox">
                  <span className="title">{item.title}</span>
                  <br />
                  {item.text}
                </div>
              ))}
            </div>
            <div className={classes.column}>
              <span className={classes.title}>APV B</span>
              {APVBarray.map((item) => (
                <div className="whiteBox">
                  <span className="title">{item.title}</span>
                  <br />
                  {item.text}
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <span className={classes.title}>APV A</span>

            <div className={classes.column}>
              {APVAarray.map((item) => (
                <div className="whiteBox">
                  <span className="title">{item.title}</span>
                  <br />
                  {item.text}
                </div>
              ))}
            </div>
            <span className={classes.title}>APV B</span>

            <div className={classes.column}>
              {APVBarray.map((item) => (
                <div className="whiteBox">
                  <span className="title">{item.title}</span>
                  <br />
                  {item.text}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <div className={classes.bottomSection}>
        <ButtonPrimary text={"Siguiente"} onClick={() => setPage(3)} />
      </div>
    </div>
  );
};

export default M2ApvStep2;
