const MY_FINANCE_GREY =
  'https://rokinapp-images.s3.amazonaws.com/header/finanzas_icon_gris.svg';
const MY_FINANCE_GREEN =
  'https://rokinapp-images.s3.amazonaws.com/header/finanzas_icon_verde.svg';
const INVESTMENTS_GREY =
  'https://rokinapp-images.s3.amazonaws.com/header/inversiones_icon_gris.svg';
const INVESTMENTS_GREEN =
  'https://rokinapp-images.s3.amazonaws.com/header/inversiones_icon_verde.svg';
const ROKIPEDIA_GREY =
  'https://rokinapp-images.s3.amazonaws.com/header/rokipedia_icon_gris.svg';
const ROKIPEDIA_GREEN =
  'https://rokinapp-images.s3.amazonaws.com/header/rokipedia_icon_gris.svg';
const HOME_ICON =
  'https://storage.googleapis.com/rokinapp-images/sidebar/home_icon.svg';
const ROCKET_ICON =
  'https://storage.googleapis.com/rokinapp-images/sidebar/rocket_icon.svg';
const TREND_UP_ICON =
  'https://storage.googleapis.com/rokinapp-images/sidebar/trendUp_icon.svg';
const SERVICES_ICON =
  'https://storage.googleapis.com/rokinapp-images/sidebar/service_icon.svg';
const ROKIN_LOGO =
  'https://storage.googleapis.com/rokinapp-images/header/logoRokinTopBar.svg';

export {
  MY_FINANCE_GREY,
  MY_FINANCE_GREEN,
  INVESTMENTS_GREY,
  INVESTMENTS_GREEN,
  ROKIPEDIA_GREY,
  ROKIPEDIA_GREEN,
  HOME_ICON,
  ROCKET_ICON,
  TREND_UP_ICON,
  SERVICES_ICON,
  ROKIN_LOGO,
};
