import { makeStyles, Theme } from "@material-ui/core";

const useGeneralStyles = makeStyles<Theme>((theme) => ({
  container: {
    width: "70rem",
    margin: "3rem auto",
    marginBottom: "0rem",
    background: "#F4F3F3",
    padding: "3rem 5rem 4rem",
    borderRadius: 30,
    boxShadow: "4px 4px 15px 5px rgba(0, 0, 0, 0.25)",
    fontFamily: "Montserrat",
    [theme.breakpoints.down(1200)]: {
      width: "100%",
      padding: "2rem 2.5rem 3rem",
      margin: "1.5rem auto",
      minHeight: "90vh",
      marginBottom: 0,
    },
  },
  title: {
    fontFamily: "Montserrat",
    fontSize: "1rem",
    fontWeight: 300,
    color: theme.palette.text.secondary,
    marginBottom: "2rem",
    "& span": {
      fontWeight: "bold",
    },
    [theme.breakpoints.down(1200)]: {
      fontSize: "0.875rem",
    },
  },
  secondaryTitle: {
    fontFamily: "Montserrat",
    fontSize: "0.75rem",
    fontWeight: 600,
    color: theme.palette.text.secondary,
    marginBottom: "0.5rem",
  },
  principalTitle: {
    fontFamily: "Montserrat",
    fontSize: "1rem",
    fontWeight: 600,
    color: theme.palette.text.secondary,
    textTransform: "uppercase",
    marginBottom: "1.5rem",
    [theme.breakpoints.down(1200)]: {
      fontSize: "0.875rem",
    },
  },
  secondary: {
    fontFamily: "Montserrat",
    fontSize: "1rem",
    fontWeight: 300,
    color: theme.palette.text.hint,
    marginBottom: "1.5rem",
    [theme.breakpoints.down(1200)]: {
      fontSize: "0.875rem",
    },
    "& span": {
      fontWeight: "bold",
    },
  },
  noUppercase: {
    textTransform: "inherit",
  },
  uppercase: {
    textTransform: "uppercase",
  },
  safe: {
    display: "flex",
    alignItems: "center",
    gap: "2rem",
    fontFamily: theme.typography.fontFamily,
    fontSize: "1.5rem",
    color: "white",
    fontWeight: 200,
    width: "60rem",
    margin: "0 auto",

    "& button": {
      cursor: "pointer",
    },
    "& img": {
      width: "2.5rem",
      cursor: "pointer",
      [theme.breakpoints.down(1200)]: {
        width: "1.25rem",
      },
    },
    "& >img": {
      [theme.breakpoints.down(1200)]: {
        display: "flex",
        alignItems: "center",
      },
    },
    "& svg": {
      opacity: 0.5,
      [theme.breakpoints.down(1200)]: {
        width: "1rem",
      },
    },
    [theme.breakpoints.down(1200)]: {
      fontSize: "0.875rem",
      width: "100%",
      maxWidth: 370,
      margin: "0 auto",
      justifyContent: "center",
    },
    "& .noOpacity": {
      opacity: 0.5,
    },
    "& .spaceCredit": {},
  },
  containerButtons: {
    display: "flex",
    gap: "2.5rem",
    "& button": {
      width: "17.813rem",
    },
  },
  textLabel: {
    display: "flex",
    flexDirection: "column",
    color: "#6b6b6b",
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "0.75rem",
    marginBottom: "0.5rem",
    [theme.breakpoints.down(1300)]: {
      fontSize: "0.75rem",
      width: "100%",
      margin: "0 auto",
    },
  },
}));
export default useGeneralStyles;
