import React from "react";
import { Select } from "@components";
import { CommonSelectorProps, TERM_OPTIONS } from "..";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
type Props = Omit<CommonSelectorProps, "value"> & {
  value?: string | number | undefined;
};

export const InvestTermSelector: React.FC<Props> = ({
  className,
  name,
  label,
  ...props
}) => {
  return (
    <Select
      className={`${className || ""} altercap-investmentTerm-selector`}
      name={name || "investmentTerm"}
      label={label || "Retiraré mi dinero en"}
      options={TERM_OPTIONS}
      {...props}
    />
  );
};
