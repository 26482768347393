import { ApolloClient, gql } from "@apollo/client";

const VAL_EX_EMAIL_SAVE_QUERY = gql`
  query ValidateExistingEmailInSaveByRokin($email: String!) {
    validateExistingEmailInSaveByRokin(email: $email)
  }
`;

type ValidateExistingEmailParams = {
  email: string;
};

type ValidateExistingEmailResult = {
  validateExistingEmailInSaveByRokin: boolean;
};

export async function validateExistingEmailInSAVE(
  email: string,
  client: ApolloClient<object>,
): Promise<boolean> {
  const result = await client.query<
    ValidateExistingEmailResult,
    ValidateExistingEmailParams
  >({
    query: VAL_EX_EMAIL_SAVE_QUERY,
    variables: { email },
    fetchPolicy: "network-only",
  });
  return !!result.data?.validateExistingEmailInSaveByRokin;
}
