import { ButtonPrimary } from "@components/Reusables";
import React, { useState, useEffect, useContext } from "react";
import { useStyles } from "./main.style";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useMediaQuery } from "@material-ui/core";
import {
  navArrowLeft,
  navArrowRight,
  navArrowLeftActive,
  navArrowRightActive,
  QuestionMark,
  HouseIconPurple,
  WaterIconPurple,
  PlaneIconPurple,
  GlassIconPurple,
  LightIconPurple,
  ClothesIconPurple,
  SpaceMan1,
  SpaceMan2,
  SpaceMan3,
} from "@images";
import { AppContext } from "@context";

interface BudgetMission2MainTabProps {
  handleClick: () => void;
}

const BudgetMission2MainTab: React.FC<BudgetMission2MainTabProps> = ({
  handleClick,
}) => {
  const classes = useStyles();
  const slider = React.useRef(null);
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const FixedIcons = [
    { name: "Luz", icon: LightIconPurple },
    { name: "Arriendo", icon: HouseIconPurple },
    { name: "Agua", icon: WaterIconPurple },
  ];
  const VariableIcons = [
    { name: "Vacaciones", icon: PlaneIconPurple },
    { name: "Ropa", icon: ClothesIconPurple },
    { name: "Entretenimiento", icon: GlassIconPurple },
  ];
  const [currentLeftArrow, setCurrentLeftArrow] = useState(navArrowLeft);
  const [currentRightArrow, setCurrentRightArrow] =
    useState(navArrowRightActive);
  // const [currentSpaceMan, setCurrentSpaceMan] = useState(SpaceMan1);
  const [currentSlide, setCurrentSlide] = useState(0);
  const { openCongratsModal } = useContext(AppContext);
  useEffect(() => {
    if (currentSlide == 0) {
      setCurrentLeftArrow(navArrowLeft);
      setCurrentRightArrow(navArrowRightActive);
      // setCurrentSpaceMan(SpaceMan1);
    }
    if (currentSlide == 1) {
      setCurrentLeftArrow(navArrowLeftActive);
      setCurrentRightArrow(navArrowRightActive);
      // setCurrentSpaceMan(SpaceMan2);
    }
    if (currentSlide == 2) {
      setCurrentLeftArrow(navArrowLeftActive);
      setCurrentRightArrow(navArrowRight);
      // setCurrentSpaceMan(SpaceMan3);
    }
  }, [currentSlide]);

  const arrowRight = {
    width: 50,
    height: 50,
    background: `url(${currentRightArrow})`,
    margin: "0 0 7rem -1rem",
  };

  const arrowLeft = {
    width: 50,
    height: 50,
    background: `url(${currentLeftArrow})`,
    margin: "0 -1rem 7rem 0",
  };

  const ellipseDots = {
    width: "14.9px",
    height: "14.9px",
    flexGrow: 0,
    margin: "0 16.6px 0 0",
    "background-image": "linear-gradient(335deg, #8352FD 127%, #8250ff 8%)",
  };

  let dotted;
  if (isMobile) {
    dotted = false;
  } else {
    dotted = true;
  }
  const settings = {
    dots: dotted,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (current, next) => {
      setCurrentSlide(next);
    },
    dotsClass: `slick-dots ${classes.dots}`,
  };
  return (
    <div className={classes.container}>
      <div className={classes.middleSection}>
        {isMobile && (
          <div className={classes.topSection}>
            <div className={classes.arrowLeft}>
              <button
                onClick={() => slider?.current?.slickPrev()}
                style={arrowLeft}
              />
            </div>
            <div className={`${classes.spaceImage}`}>
              {currentSlide == 0 && (
                <img src={SpaceMan1} className="page1Space" alt="" />
              )}
              {currentSlide == 1 && (
                <img src={SpaceMan2} className="page2Space" alt="" />
              )}
              {currentSlide == 2 && (
                <img src={SpaceMan3} className="page3Space" alt="" />
              )}
            </div>
            <div className={classes.arrowRight}>
              <button
                onClick={() => slider?.current?.slickNext()}
                style={arrowRight}
              />
            </div>
          </div>
        )}
        <div className="slider">
          <div className={classes.riel}>
            {!isMobile && (
              <div>
                <button
                  onClick={() => slider?.current?.slickPrev()}
                  style={arrowLeft}
                />
              </div>
            )}

            <div className={classes.msgWelcome}>
              <Slider ref={slider} {...settings}>
                <div className={`${classes.sliderBox} ${classes.slide1}`}>
                  <div className="textContainer">
                    <span className="infoText">
                      ¿Sabías que uno de los
                      <span className="style1"> principales errores </span>
                      en las finanzas personales es
                      <span className="style1">
                        {" "}
                        no construir un presupuesto
                      </span>
                      ?
                    </span>
                  </div>
                  <div className={classes.cardBox}>
                    <div className={`${classes.whiteCard} ${classes.card1}`}>
                      <img src={QuestionMark} />
                      <span className="cardText">
                        Un
                        <span className="style1"> presupuesto </span>
                        es una estimación de los
                        <span className="style1"> ingresos y gastos </span>a los
                        que te enfrentas mes a mes.
                      </span>
                    </div>
                    <div className={`${classes.whiteCard} ${classes.card2}`}>
                      <div className="cardTitle">
                        <span>De esta manera puedes</span>
                      </div>
                      <div className="cardList">
                        <div className="items">
                          <span className="itemNumber">1.</span>
                          <span className="itemText">
                            Saber cuanta plata tienes disponible.
                          </span>
                        </div>
                        <div className="items">
                          <span className="itemNumber">2.</span>
                          <span className="itemText">
                            Para qué quieres usar este dinero.
                          </span>
                        </div>
                        <div className="items">
                          <span className="itemNumber">3.</span>
                          <span className="itemText">
                            Qué gastos puedes cambiar si es que es necesario.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`${classes.sliderBox} ${classes.slide2}`}>
                  <div className="textContainer">
                    <span className="infoText">
                      Algo
                      <span className="style1"> importante </span>
                      es poder diferenciar los
                      <span className="style1"> gastos fijos y variables.</span>
                    </span>
                  </div>
                  <div className={classes.cardBox}>
                    <div className={classes.secondSlideBox}>
                      <div
                        className={`${classes.whiteCard} ${classes.card1S2}`}
                      >
                        <div className="cardTitle">
                          <span>Gastos fijos</span>
                        </div>
                        <span className="cardText">
                          <span className="style1"> Gastos básicos </span>
                          que debes pagar todos los meses.
                        </span>
                      </div>
                      <div className="iconsBox">
                        {FixedIcons.map((icon) => (
                          <div className="iconLabels">
                            <span className="iconName">{icon.name}</span>
                            <img src={icon.icon} />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className={classes.secondSlideBox}>
                      <div
                        className={`${classes.whiteCard} ${classes.card1S2}`}
                      >
                        <div className="cardTitle">
                          <span>Gastos Variables</span>
                        </div>
                        <span className="cardText">
                          Gastos frecuentes pero que
                          <span className="style1"> no son escenciales.</span>
                        </span>
                      </div>
                      <div className="iconsBox">
                        {VariableIcons.map((icon) => (
                          <div className="iconLabels">
                            <span className="iconName">{icon.name}</span>
                            <img src={icon.icon} />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`${classes.sliderBox} ${classes.slide3}`}>
                  <div className="textContainer thirdSlideText">
                    <span className="infoText">
                      Si logras
                      <span className="style1">
                        {" "}
                        diferenciar cada uno de estos gastos,{" "}
                      </span>
                      va a ser mucho más fácil seguir el rastro de los que
                      consumes y reconocer cuándo una compra puede evitarse. De
                      esta forma, es mucho
                      <span className="style1">
                        {" "}
                        más probable que llegues con plata a fin de mes{" "}
                      </span>
                      y no te encuentres con una mala sorpresa.
                    </span>
                    <div className="thirdSlideBox">
                      <div className="items">
                        <span className="itemNumber">1.</span>
                        <span className="itemText">Ordenar tus gastos.</span>
                      </div>
                      <div className="items">
                        <span className="itemNumber">2.</span>
                        <span className="itemText">
                          Ahorrar con mayor facilidad.
                        </span>
                      </div>
                      <div className="items">
                        <span className="itemNumber">3.</span>
                        <span className="itemText">
                          Estar preparado para alguna emergencia.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
            {!isMobile && (
              <div>
                <button
                  onClick={() => slider?.current?.slickNext()}
                  style={arrowRight}
                />
              </div>
            )}
          </div>
        </div>
        {!isMobile && (
          <div className={`${classes.spaceImage}`}>
            {currentSlide == 0 && (
              <img src={SpaceMan1} className="page1Space" alt="" />
            )}
            {currentSlide == 1 && (
              <img src={SpaceMan2} className="page2Space" alt="" />
            )}
            {currentSlide == 2 && (
              <img src={SpaceMan3} className="page3Space" alt="" />
            )}
          </div>
        )}
      </div>
      <div className={classes.bottomSection}>
        {!isMobile ? (
          <ButtonPrimary
            text="Terminar misión"
            onClick={() => handleClick()}
            secondaryVersion
          />
        ) : (
          <div className="centerButton">
            {currentSlide == 2 ? (
              <ButtonPrimary
                text="Terminar misión"
                onClick={() => handleClick()}
                secondaryVersion
              />
            ) : (
              <div className={currentSlide == 1 ? "secondButton" : ""}>
                <ButtonPrimary
                  text="Siguente"
                  onClick={() => slider?.current?.slickNext()}
                  secondaryVersion
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default BudgetMission2MainTab;
