import { ApolloClient, gql, useQuery } from "@apollo/client";
import { Company } from "@interfaces";

export const GET_COMPANIES = gql`
  query Companies {
    companies {
      name
      owner
      rut
      id
      metadata
      employeesList
    }
  }
`;

interface QueryResult {
  companies: Company[];
}

interface HookReturn {
  companies: Company[];
  loading: boolean;
}

export const getCompanies = (): HookReturn => {
  const { data, loading, refetch } = useQuery<QueryResult>(GET_COMPANIES);

  if(!data?.companies){
    refetch();
  };

  return {
    companies: data?.companies ?? [],
    loading,
  };
};
