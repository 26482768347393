import React, { useEffect, useState } from "react";
import { Filter, FilterProps } from "..";
import { RiskSelector, RISK_LEVELS } from "@components";
import { makeStyles } from "@material-ui/core";

export interface RiskFilterProps extends Omit<FilterProps, "title"> {
  name: string;
  className?: string;
  value: number | null;
  handleChange: (
    event: React.ChangeEvent<any>,
    newValue: number | null,
  ) => void;
}
const RiskFilter: React.FC<RiskFilterProps> = ({
  name,
  className,
  value,
  handleChange,
  ...props
}) => {
  const classes = useStyles();
  const [hover, setHover] = useState("");
  useEffect(() => {
    setHover(value ? RISK_LEVELS[value - 1] : "");
  }, [value]);
  return (
    <Filter
      className={`${className || ""} ${classes.riskFilter}`}
      title="Nivel de riesgo"
      parsedSelections={hover}
      {...props}
    >
      <RiskSelector
        name={name}
        className={classes.riskSelector}
        value={value}
        handleChange={handleChange}
      />
    </Filter>
  );
};

const useStyles = makeStyles(() => ({
  riskFilter: {
    width: "100%",
  },
  riskSelector: {
    padding: "20px 30px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& .hover-text": {
      marginTop: 10,
      fontSize: 13,
    },
  },
}));

export { RiskFilter };
