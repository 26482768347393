import React, { Dispatch, SetStateAction, useState } from "react";

import { useStyles } from "./recap.styles";
import { EventCard } from "@components";
import { getDayEvents, getEventByType } from "@apollo";

type Props = {
  setCurrentView: Dispatch<SetStateAction<string>>;
  type: string;
  setEdit: Dispatch<SetStateAction<boolean>>;
  setEditingData: Dispatch<
    SetStateAction<{
      id: number;
      showInCalendar: boolean;
      name: string;
      date: string;
      nextDate: string;
      amount: number;
      type: number;
      category: number;
      recurrence: number;
      description: string;
      url: string;
    }>
  >;
  togglePage: () => void;
};

const EventsRecap = ({
  type,
  setCurrentView,
  setEditingData,
  setEdit,
  togglePage,
}: Props) => {
  const classes = useStyles();

  const setType = (type: string) => {
    switch (type) {
      case "expense":
        return 1;
      case "income":
        return 2;
      case "reminder":
        return 3;
      default:
        return 1;
    }
  };
  const events = getEventByType(setType(type)).eventByTypeData;

  // const [events, setEvents] = useState([
  //   {
  //     id: 1,
  //     name: "Netflix",
  //     date: "12/12/2020",
  //     amount: 100,
  //     category: 4,
  //     recurrence: 2,
  //     url: "https://www.netflix.com/",
  //     description: "",
  //     showInCalendar: true,
  //   },
  //   {
  //     id: 2,
  //     name: "VTR",
  //     date: "12/12/2020",
  //     amount: 100,
  //     category: 11,
  //     recurrence: 5,
  //     url: "https://www.vtr.cl/",
  //     description: "",
  //     showInCalendar: true,
  //   },
  // ]);

  const AllEvents = () => {
    if (events?.length === 0) {
      return (
        <div className={classes.empty}>
          Parece que no hay eventos... Puedes crearlos con el botón de "Agregar"
        </div>
      );
    } else {
      return events?.map((event) => (
        <EventCard
          key={event.id}
          type={type}
          name={event.name}
          date={event.date}
          nextDate={event.nextDate}
          amount={event.amount}
          description={event.description}
          category={event.category}
          recurrence={event.recurrence}
          url={event.url}
          id={event.id}
          showInCalendar={event.showInCalendar}
          setCurrentView={setCurrentView}
          setEditingData={setEditingData}
          setEdit={setEdit}
          togglePage={togglePage}
        />
      ));
    }
  };

  return <div className={classes.container}>{AllEvents()}</div>;
};

export default EventsRecap;
