import React from "react";
import { useStyles } from "./CardMission.style";
import ArrowBackIosNewIcon from "@material-ui/icons/ArrowBackIos";
import { check } from "@images";
import { continueMission } from "images/misiones/CardMission";
import { useMediaQuery } from "@material-ui/core";
import { navigate } from "gatsby";
import { Route } from "@interfaces";
import { ProgressBarLine } from "react-progressbar-line";

interface CardMissionProps {
  image: string;
  title: string;
  text: string;
  load: string;
  onContinueClick: () => void;
  timeLoad: number;
  completedMissions: number;
  allMissions: number;
}

const CardMission = ({
  image,
  title,
  text,
  load,
  onContinueClick,
  timeLoad,
  completedMissions,
  allMissions,
}: CardMissionProps) => {
  const classes = useStyles({ timeLoad });
  const isMobile = useMediaQuery("(max-width: 1240px)");

  return (
    <section className={classes.container}>
      <div className={classes.top}>
        <ArrowBackIosNewIcon
          onClick={() => navigate(Route.rokipedia)}
          className={classes.iconArrow}
        />
        <img src={image} />
      </div>
      <div className={classes.bottom}>
        <h1 className="title">{title}</h1>
        <div className="text">{text}</div>
        <div className={classes.containerLine}>
          <div className={classes.line}>
            <ProgressBarLine
              value={completedMissions}
              min={0}
              max={allMissions}
              text=" "
              styles={{
                path: {
                  stroke: "#c81ec8",
                },
              }}
            />
          </div>{" "}
          {isMobile && (
            <img
              className={classes.cursor}
              onClick={onContinueClick}
              src={continueMission}
            />
          )}
        </div>
        <div className="load">
          <img src={check} />
          {load}
        </div>
        {!isMobile && (
          <div className="button" onClick={onContinueClick}>
            <img src={continueMission} />{" "}
            <span>
              <h2> Ir a la próxima misión</h2>
            </span>
          </div>
        )}
      </div>
    </section>
  );
};

export default CardMission;
