const getTotal = (
  data: JSON,
  category: string,
  position: number,
  subCategory?: string
) => {
  const value: any[] = [];
  data?.[position].find((obj) => {
    if (obj?.name.toUpperCase() === category.toUpperCase()) {
      return obj.details.filter((detail) => {
        if (detail.text.toUpperCase() === subCategory?.toUpperCase()) {
          return value.push(detail?.amount);
        }
      });
    }
  });
  return value ?? 0;
};

export default getTotal;
