import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  weelySize: {},

  calendar: {
    minWidth: "21.25rem",
    maxWidth: "24.25rem",
    paddingTop: "1rem",
    display: "flex",
    marginBottom: "2rem",
    flexDirection: "column",
    margin: "0 auto",
    // backgroundImage: "linear-gradient(141deg, #0a5669 10%, #00a9c2 110%)",
    color: "#ffffff",
    fontFamily: "Montserrat",
    fontSize: "0.75rem",
    fontWeight: 500,

    "& .calendar-header": {
      height: "3.25rem",
      width: "100%",
      margin: "auto",
      display: "flex",
      padding: "0 1rem",
      alignItems: "center",
      justifyContent: "space-between",
    },

    "& .calendar-header button": {
      backgroundColor: "#10849a",
      borderRadius: "5px",
    },

    "& .MuiSvgIcon-root MuiSelect-icon": {
      color: "#ffffff",
    },

    "& .tools": {
      height: "3rem",
      display: "flex",
      alignItems: "center",
    },
    "& .tools button": {
      margin: "auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      boxSizing: "border-box",
      border: "none",
      color: "#ffffff",
      height: "1.5rem",
      width: "1.5rem",
    },

    "& .tools button span:hover": {
      color: "#99cccc",
    },

    "& .title h2": {
      margin: "auto",
    },

    "& .calendar-body": {
      width: " 100%",
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
    },

    "& .table-header": {
      height: "2.25rem",
      marginBottom: "1.2rem",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      // borderBottom: "1px solid rgba(244	,243	,243  , 0.5)",
    },
    "& .mainMonth": {
      fontSize: "1.25rem",
    },
    "& .weekday": {
      width: "14.28%",
      textAlign: "center",
    },

    "& .table-content": {
      width: "100%",
      flexGrow: 1,
      marginTop: "1rem",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      boxSizing: "border-box",
      alignContent: "flex-start",
    },

    "& .calendar-day": {
      width: "calc(14.28% - 6%)",
      height: "2.25rem",
      paddingTop: "0.5rem",
      margin: "0rem 3%",
      display: "flex",
      position: "relative",
      alignItems: "center",
      borderRadius: "0.6875rem",
      flexDirection: "column",
      cursor: "pointer",
    },
    "& .event-dot": {
      width: "0.2rem",
      height: "0.2rem",
      borderRadius: "50%",
      top: "1.65rem",
      backgroundColor: "#99cccc",
      position: "absolute",
    },

    [theme.breakpoints.up(1240)]: {
      "& .calendar-day:hover": {
        backgroundColor: "rgba(0,0,0,.10)",
      },
    },

    "& .today": {
      backgroundColor: "#0a5669",
      borderRadius: "50%",
      height: "2rem",
    },

    "& .calendar-day p": {
      color: "#a6a6a6",
    },

    "& .current p": {
      color: "#ffffff",
    },

    "& .event-day": {},

    "& .selected p": {
      color: "#ffffff",
      fontWeight: "bold",
    },
    "& .selected": {
      backgroundColor: "#00a9c2",
      boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
      fontWeight: "bold",
    },
  },
  select: {
    width: "6rem",
    backgroundColor: "#10869d",
    borderRadius: "0.3125rem",
    height: "1.5rem",
    fontSize: "0.85rem",
    "& .MuiInputBase-inputMarginDense": {
      padding: "0",
    },
    "& .MuiSelect-icon": {
      color: "#ffffff",
    },
    "& .MuiSelect-select": {
      paddingLeft: "0.6125rem",
      color: "#ffffff",
    },
  },
  dividerColor: {
    backgroundColor: "#F4F3F3",
  },
  todayButton: {
    margin: "0 0 0 10.3rem",
  },
  buttonContainer: {
    width: "2.563rem",
    height: "1.8rem",
    backgroundColor: "#0a5669",
    borderRadius: "5px",
    opacity: 1,
    border: "1px solid rgb(9,140,168)",
  },
  todayButtonText: {
    margin: "0.23rem 0 0 0",
    fontFamily: "Montserrat",
  },
}));
