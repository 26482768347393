import Return from "./return.svg";
import Create from "./create.svg";
import Edit from "./editar.svg";
import IlustrationGoal from "./ilustracionObjetivo.svg";
import arrowGoal from "./arrowGoal.svg";
import tooltipDesktop from "./tooltipDesktop.svg";
import tooltipMobile from "./tooltipMobile.svg";
import moneyModal from "./modalGoal.svg";
import createMobile from "./createMobile.svg";
import arrowRight from "./arrowRight.svg";
import objetivoCreado from "./objetivoCreadoIlustracion.svg";
import Ahorrar from "./Ahorrar.svg";
import ahorroTarjeta from "./ahorro_tarjeta.svg";
import casaTarjeta from "./casa_tarjeta.svg";
import Casa from "./Casa.svg";
import deudasTarjeta from "./deudas_tarjeta.svg";
import emergenciaTarjeta from "./emergencia_tarjeta.svg";
import FondoEmergencia from "./Fondo_emergencia.svg";
import jubilacionTarjeta from "./jubilacion_tarjeta.svg";
import Jubilacion from "./Jubilacion.svg";
import otroTarjeta from "./otro_tarjeta.svg";
import Otro from "./Otro.svg";
import PagarDeudas from "./Pagar_deudas.svg";
import Viajar from "./Viajar.svg";
import viajeTarjeta from "./viaje_tarjeta.svg";
import autoTarjeta from "./Auto_tarjeta.svg";
export {
  Return,
  autoTarjeta,
  Create,
  Edit,
  arrowGoal,
  IlustrationGoal,
  tooltipDesktop,
  tooltipMobile,
  moneyModal,
  createMobile,
  arrowRight,
  objetivoCreado,
  Ahorrar,
  ahorroTarjeta,
  casaTarjeta,
  Casa,
  deudasTarjeta,
  emergenciaTarjeta,
  FondoEmergencia,
  jubilacionTarjeta,
  Jubilacion,
  otroTarjeta,
  Otro,
  PagarDeudas,
  Viajar,
  viajeTarjeta,
};
