import { ButtonPrimary } from "@components/Reusables";
import { einsteinImage } from "@images";
import React, { Dispatch, SetStateAction } from "react";
import { useStyles } from "./step1.style";
import { useMediaQuery } from "@material-ui/core";
type Props = {
  setPage: Dispatch<SetStateAction<number>>;
};

const Step1SavingAndInvestmentM1: React.FC<Props> = ({ setPage }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:1239px)");
  const features = [
    "- La plata invertida va creciendo en cada periodo porque se van sumando los intereses.",
    "- La tasa de interés se aplica sobre un monto que va aumentando",
    "- Los intereses aumentan en cada periodo",
  ];
  const listItems = features.map((item) => (
    <span className="listItem">{item}</span>
  ));
  return (
    <div className={classes.container}>
      <div className={classes.leftSide}>
        <div className="helperText">
          <span>
            Alguien dijo una vez: "La octava maravilla del mundo es el interés
            compuesto. Aquel que lo comprende lo gana, aquel que no, lo paga"
          </span>
        </div>
        <div className={classes.textContainer}>
          <span className="text">
            El interés compuesto es lo que hace que tu inversión{" "}
            <strong> vaya creciendo </strong>
            con el paso del tiempo, ya que vas acumulando su rentabilidad y
            sigue aumentando mediante la re-inversión del interés sobre la plata
            que pusiste.
          </span>
        </div>
        <div className="subtitle">
          <span>Características</span>
        </div>
        <span className="listItem">
          - La plata invertida <strong>va creciendo</strong> en cada período
          porque se van sumando los intereses.
        </span>
        <span className="listItem">
          - La tasa de interés se aplica sobre un monto que
          <strong> va aumentando</strong>.
        </span>
        <span className="listItem">
          - Los <strong>intereses aumentan </strong>en cada período.
        </span>

        <div className={classes.buttonContainer}>
          <ButtonPrimary
            text="Siguiente"
            secondaryVersion
            onClick={() => setPage(2)}
          />
        </div>
      </div>
      <div className={classes.rightSide}>
        <img src={einsteinImage} alt="einstein image for mission" />
      </div>
    </div>
  );
};

export default Step1SavingAndInvestmentM1;
