import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { navigate } from 'gatsby';
import { Route } from '@interfaces';
import { arrowGoal, createMobile } from 'images/Goal';
import { DELETE_ICON, PEN_WHITE_ICON } from 'images/GoogleCloud';

interface LayoutProps {
  className?: string;
  styleFooter?: string;
  icon?: string;
  secondTitle?: string;
  children: React.ReactNode;
  pageName: string;
  stepNumber?: string;
  setStepNumber?: (v: string) => void;
  subtitle?: string;
  onBackClick?: () => void;
  countSteps?: number;
  noStep?: boolean;
  onDeleteClick?: () => void;
}
export const LayoutMobileNewVersion: React.FC<LayoutProps> = ({
  className,
  children,
  setStepNumber,
  stepNumber,
  pageName,
  subtitle,
  icon,
  onBackClick,
  noStep,
  onDeleteClick,
}) => {
  const classes = useStyles();

  const handleClick = () => {
    if (stepNumber === 0) {
      navigate(Route.investments + '/?#objectives');
    }
    if (stepNumber === 1) {
      setStepNumber(0);
    }
    if (stepNumber === 2) {
      navigate(Route.investments);
    }
    onBackClick && onBackClick();
  };
  return (
    <div className={`${classes.container} ${className || ''}`}>
      <section className={classes.header}>
        <div className="header">
          <div>
            <img
              style={{ cursor: 'pointer', marginLeft: '1rem' }}
              onClick={handleClick}
              src={arrowGoal}
            />
            <div>
              <div className={classes.titleHeader}>
                <img src={icon} />
                {pageName}
              </div>{' '}
              <div className={classes.numberpage}>
                {stepNumber !== undefined && !noStep && (
                  <>Paso {Number(stepNumber) + 1} de 2</>
                )}
                {noStep && subtitle && <>{subtitle}</>}
              </div>
            </div>
          </div>{' '}
          {onDeleteClick && (
            <img className="filter" onClick={onDeleteClick} src={DELETE_ICON} />
          )}
        </div>
      </section>
      <div className="children">{children}</div>
      <footer className={classes.footer} />
    </div>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    fontFamily: 'Montserrat',
    background: '#F4F3F3',
  },
  header: {
    background: '#00323c',
    paddingTop: 50,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'start',
    paddingBottom: 15,
    marginBottom: 0,
    height: '12rem',
    '& .header': {
      display: 'flex',
      maxWidth: 840,
      width: '100%',
      margin: '0 auto',
      minWidth: '375px',
      justifyContent: 'start',
      paddingLeft: '0',
      '& .filter': {
        filter: 'invert(100%)',
        marginRight: '1rem',
      },
      '& >div': {
        display: 'flex',
        width: '100%',
        '& div': {
          width: '100%',
          [theme.breakpoints.down(1040)]: {
            width: '85%',
          },
        },
      },
    },
    '& img': {
      width: 20,
    },
    '& >div': {
      paddingLeft: '3rem',
    },
  },
  numberpage: {
    fontSize: '0.625rem',
    color: 'rgba(277,277,277,0.5)',
    margin: '0 auto',
    fontFamily: 'Nunito',
    padding: '0.2rem 0 0 0',
    textAlign: 'center',
    [theme.breakpoints.up(1040)]: {
      textAlign: 'start',
      paddingLeft: '2.3rem',
    },
  },
  subtitle: {
    fontSize: '0.875em',
    color: 'rgba(277,277,277,0.5)',
    margin: '0 auto',
    fontFamily: 'Nunito',
    padding: '0.5rem 0 0 0',
    textAlign: 'center',
  },
  titleHeader: {
    color: 'white',
    display: 'flex',
    fontFamily: 'Montserrat',
    fontSize: '0.875rem',
    textTransform: 'uppercase',
    alignItems: 'start',
    gap: '1rem',
    minWidth: 300,
    justifyContent: 'center',
    [theme.breakpoints.up(1040)]: {
      justifyContent: 'start',
    },
  },
  txt: {
    display: 'block',
    fontSize: 14,
    fontWeight: 300,
    color: theme.palette.text.primary,
    paddingBottom: 15,
  },
  containerGoal: {
    padding: '0 20px',
    margin: '0 auto',
    minWidth: 375,
  },
  input: {
    width: 335,
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: 20,
    fontWeight: 'bold',
    paddingTop: 30,
    display: 'block',
  },
  buttons: {
    marginTop: 50,
    display: 'grid',
    '& button': {
      marginBottom: 22,
    },
  },
  footer: {
    width: 'auto',
    height: 55,
    position: 'relative',
  },
}));
