import React, { useEffect, useRef, useState } from 'react';

import { useStyles } from './GeneralPersonalData.style';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { CircularProgress, useMediaQuery } from '@material-ui/core';
import { ButtonOutlined, ButtonPrimary } from '@components/Reusables';
import { DEFAULT_PROFILE_ICON } from '@images';
import MyData from '@components/Found/MyData';
import { getIdentityImagesUrl, updateUser } from '@apollo';
import { useApolloClient } from '@apollo/client';
import MoreData from '@components/Found/MoreData';
import { useForm } from '@hooks';
import { ChooseInvestorProfile, ConfirmIdentity } from '@components';
import BankData from '@components/Found/BankData';
import { checkIcon } from '@images';

type Props = {
  currentUser: any;
  setCurrentView?: any;
};
const GeneralPersonalData = ({ currentUser, setCurrentView }: Props) => {
  const classes = useStyles();
  const client = useApolloClient();
  const [form, , , handleChange] = useForm({});

  const [profilePicture, setProfilePicture] = useState('Usuario');
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const { urls } = getIdentityImagesUrl();

  useEffect(() => {
    setProfilePicture(
      currentUser?.profilePicture
        ? currentUser?.profilePicture
        : DEFAULT_PROFILE_ICON
    );
  }, [currentUser]);

  const [isLevelList, setLevelList] = useState(0);
  const isMobile = useMediaQuery('(max-width: 1240px)');
  const [loading, setLoading] = useState(false);
  const [check, setChek] = useState(false);

  const sendImagesFromProfile = () => {};

  const handleClick = async () => {
    // set loading to true during 2 seconds, then continue with the rest of the code
    setLoading(true);
    setTimeout(async () => {
      setLoading(false);
      try {
        sendImagesFromProfile();
        await updateUser(form, false, client);
      } catch (err) {
        console.log(err);
        return;
      }
      if (isMobile) {
        setCurrentView(0);
        window.location.reload();
      } else {
        setChek(true);
        setTimeout(() => {
          setChek(false);
          window.location.reload();
        }, 1000);
      }
    }, 2000);
  };

  return (
    <div className={classes.PersonalDataContainer}>
      {isMobile ? (
        <div className={classes.topImage}>
          <img src={profilePicture} alt="profile-picture" />
        </div>
      ) : (
        <div className={classes.topButton}>
          {loading && <CircularProgress className={classes.loading} />}
          {check && <img src={checkIcon} alt="" className={classes.check} />}

          <ButtonPrimary
            text={'Guardar Cambios'}
            onClick={() => {
              handleClick();
            }}
            noDegraded
          />
        </div>
      )}
      <section className={classes.formSection}>
        <section className={classes.section}>
          <div className={classes.header}>
            <div className={classes.sectionTitle}>Tus datos</div>
            <KeyboardArrowDownIcon
              className={`${classes.arrow} ${
                isLevelList === 1 && classes.arrowRotate
              }`}
              onClick={() => {
                if (isLevelList == 1) {
                  setLevelList(0);
                } else {
                  setLevelList(1);
                }
              }}
            />
          </div>
          <div className={classes.sectionItem}>
            {isLevelList === 1 && (
              <MyData
                form={form}
                currentUser={currentUser}
                isDisabled={isDisabled}
              />
            )}
          </div>
        </section>
        <section className={classes.section}>
          <div className={classes.header}>
            <div className={classes.sectionTitle}>Algunos datos más</div>
            <KeyboardArrowDownIcon
              className={`${classes.arrow} ${
                isLevelList === 2 && classes.arrowRotate
              }`}
              onClick={() => {
                if (isLevelList == 2) {
                  setLevelList(0);
                } else {
                  setLevelList(2);
                }
              }}
            />
          </div>
          <div className={classes.sectionItem}>
            {isLevelList === 2 && (
              <MoreData
                form={form}
                currentUser={currentUser}
                isDisabled={isDisabled}
              />
            )}
          </div>
        </section>
        <section className={classes.section}>
          <div className={classes.header}>
            <div className={classes.sectionTitle} id="carnet">
              Carnet e información
            </div>
            <KeyboardArrowDownIcon
              className={`${classes.arrow} ${
                isLevelList === 3 && classes.arrowRotate
              }`}
              onClick={() => {
                if (isLevelList == 3) {
                  setLevelList(0);
                } else {
                  setLevelList(3);
                }
              }}
            />
          </div>
          <div className={classes.sectionItem}>
            {isLevelList === 3 && (
              <ConfirmIdentity
                form={form}
                buttonText="teminar"
                handleClick={() => {}}
                isNotMission
                urls={urls}
                sendImagesFromProfile={sendImagesFromProfile}
              />
            )}
          </div>
        </section>
        <section className={classes.section}>
          <div className={classes.header}>
            <div className={classes.sectionTitle}>Mi Perfil de Inversión </div>
            <KeyboardArrowDownIcon
              className={`${classes.arrow} ${
                isLevelList === 4 && classes.arrowRotate
              }`}
              onClick={() => {
                if (isLevelList == 4) {
                  setLevelList(0);
                } else {
                  setLevelList(4);
                }
              }}
            />
          </div>
          <div className={classes.sectionItem}>
            {isLevelList === 4 && (
              <ChooseInvestorProfile
                form={form}
                buttonText={'Teminar misión'}
                handleClick={handleClick}
                isDiscoverOn={() => {}}
                isNotMission
              />
            )}
          </div>
        </section>
        <section className={classes.section}>
          <div className={classes.header}>
            <div className={classes.sectionTitle}>Datos bancarios </div>
            <KeyboardArrowDownIcon
              className={`${classes.arrow} ${
                isLevelList === 5 && classes.arrowRotate
              }`}
              onClick={() => {
                if (isLevelList == 5) {
                  setLevelList(0);
                } else {
                  setLevelList(5);
                }
              }}
            />
          </div>
          <div className={classes.sectionItem}>
            {isLevelList === 5 && (
              <BankData
                currentUser={currentUser}
                isDisabled={isDisabled}
                form={form}
                setButtonDisabled={function (): void {}}
              />
            )}
          </div>
        </section>
      </section>
      {isMobile && (
        <div className={classes.bottomButton}>
          {loading && <CircularProgress className={classes.loading} />}

          <ButtonPrimary
            text={'Guardar Cambios'}
            onClick={() => {
              handleClick();
            }}
            noDegraded
          />
          <ButtonOutlined
            text="Cancelar"
            onClick={() => {
              setCurrentView(0);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default GeneralPersonalData;
