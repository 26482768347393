import React, { useCallback, useContext, useEffect } from "react";
import { useAuth, useForm } from "@hooks";
import { useStyles } from "./InformationDebt.style";
import { ButtonOutlined } from "@components/Reusables";
import { ButtonFile } from "@components/Reusables/ButtonFile";
import { useDropzone } from "react-dropzone";
import { ProfileContext } from "@context";
import { useCurrentUser } from "@apollo";
import { navigate } from "gatsby";
import { FileSpecificType } from "@interfaces";

export const InformationDebt = () => {
  const classes = useStyles();
  const { saveFile, liquidationFiles, urlNoFormated } =
    useContext(ProfileContext);
  const { user: currentUser } = useCurrentUser();
  const [form] = useForm();
  const onDrop = useCallback(
    () => (acceptedFiles: File[]) => {
      saveFile(acceptedFiles[0], "DEBT", currentUser?.id || 0);
      urlNoFormated?.then((res) => {
        form.debtReport = res;
      });
      console.log("Subio archivo2");
    },
    [currentUser, form]
  );

  const debtDropzone = useDropzone({
    accept: "application/pdf",
    onDrop: onDrop(),
  });
  useAuth({ redirectIfNotLogged: true });

  useEffect(() => {}, [liquidationFiles]);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.subtitle}>Informe de deuda (opcional)</div>
        <div className={classes.text}>
          Para obtener un análisis más completo te aconsejamos adjuntar tu
          <span className="text"> Informe de Deuda de la CMF. </span>
          Puede
          <span className="text"> descargarlo gratis </span>
          con tu
          <span className="text"> clave única </span>
          aquí:
        </div>
        <div className={classes.buttons}>
          <a
            target="_blank"
            href="https://informedeudas.cmfchile.cl/informedeudacb/aplicacion?indice=110.0"
            rel="noreferrer"
          >
            <ButtonOutlined
              onClick={() => {}}
              text="Ir al informe de deuda CMF"
            />
          </a>
          <ButtonFile type={FileSpecificType.DEBT} dropzone={debtDropzone} />
        </div>
      </div>
    </>
  );
};
