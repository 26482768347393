import { gql, useQuery } from '@apollo/client';
import { FinerioTransaction } from '@interfaces';
import _ from 'lodash';

export const GET_FINERIO_TRANSACTIONS = gql`
  query FinerioTransactions($accountId: Int) {
    finerioTransactions(accountId: $accountId) {
      id
      accountId
      amount
      balance
      date
      description
      isCharge
      newSubcategory {
        FinerioCategory {
          id
          color
          iconColor
          iconWhite
          name
        }
        color
        description
        iconBlack
        iconColor
        id
        subcategoryId
        visible
      }
      subcategoryId {
        FinerioCategory {
          id
          color
          iconColor
          iconWhite
          name
        }
        color
        description
        iconBlack
        iconColor
        id
        subcategoryId
        visible
      }
      recommendedSubcategory
      city
      currency
      monthlyPayments
    }
  }
`;

type QueryResult = {
  finerioTransactions: FinerioTransaction[];
};

type HookReturn = {
  finerioTransactions: FinerioTransaction[];
  finerioTransactionsLoading: boolean;
  refetchFinerioTransactions: () => void;
};

export const getFinerioTransactions = (accountId?: string): HookReturn => {
  const { data, loading, refetch } = useQuery<QueryResult>(
    GET_FINERIO_TRANSACTIONS,
    {
      variables: { accountId },
    }
  );
  const cloned = _.cloneDeep(data?.finerioTransactions);

  cloned?.forEach((transaction) => {
    const getUTCDate = (date: number) => {
      const d = new Date(date);
      const utcDate = new Date(
        d.getUTCFullYear(),
        d.getUTCMonth(),
        d.getUTCDate()
      );
      return utcDate;
    };

    let formattedDate = new Intl.DateTimeFormat('en-US').format(
      getUTCDate(Number(transaction.date))
    );

    // MM/DD/YYYY
    transaction.date = new Date(formattedDate);
  });
  cloned?.sort(function (a, b) {
    return new Date(b.date) - new Date(a.date);
  });

  return {
    finerioTransactions: cloned ?? [],
    finerioTransactionsLoading: loading,
    refetchFinerioTransactions: refetch,
  };
};
