import React from "react";
import { makeStyles } from "@material-ui/core";

interface Props {
  className?: string;
  firstText?: string;
  secondText?: string;
}

export const StageTitle: React.FC<Props> = ({
  className,
  firstText,
  secondText,
}) => {
  const classes = useStyles();
  return (
    <div className={`${className} ${classes.root}`}>
      <span className={classes.main}>{firstText}</span>
      <span className={classes.secondary}>{secondText}</span>
    </div>
  );
};

const useStyles = makeStyles(
  theme => ({
    root: {
      fontSize: 20,
      fontWeight: "bold",
      paddingLeft: 10,
      display: "flex",
      alignItems: "center",
    },
    main: {
      color: theme.palette.primary.main,
      paddingRight: 10,
    },
    secondary: {
      color: "rgba(11, 11, 11, 0.9)",
    },
  }),
  { index: 1 },
);
