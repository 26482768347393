import React, { useCallback, useContext } from "react";
import { Select } from "@components";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { ProfileContext, SnackbarContext } from "@context";
import { InvestorProfileTypes } from "@interfaces";

type Props = {
  className?: string;
  open: boolean;
  onClose: () => void;
};
export const ChangeInvestorProfileDialog: React.FC<Props> = ({
  open,
  onClose,
}) => {
  const classes = useStyles();
  const { profileForm, updateProfileForm, saveUserInfo } =
    useContext(ProfileContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const updateSelectedInvestorProfile = useCallback(
    e => {
      const value = e.target.value;
      const payload = {
        investorProfile: value,
        manuallySelectedInvestorProfile: true,
      };
      onClose();
      updateProfileForm(payload);
      saveUserInfo(payload, false);
      showSnackbar("Perfil de inversión actualizado", "success");
    },
    [onClose, updateProfileForm, saveUserInfo, showSnackbar],
  );
  return (
    <Dialog
      className={classes.investorProfileDialog}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        <Typography className={classes.dialogTitle}>
          Cambiar perfil de inversionista
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Select
          className={classes.selector}
          name="investorProfile"
          value={profileForm.investorProfile ?? ""}
          onChange={updateSelectedInvestorProfile}
          options={InvestorProfileTypes.map(tp => ({
            name: tp,
            value: tp,
          }))}
        />
        <div className={classes.disclaimer}>
          Estás consciente de que cambias voluntariamente tu perfil y de las
          implicancias de este cambio
        </div>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  investorProfileDialog: {
    "& .MuiPaper-root": {
      width: 345,
    },
  },
  dialogContent: {
    padding: "8px 18px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  dialogTitle: {
    fontWeight: "bold",
    fontSize: 16,
    fontFamily: "Montserrat, sans-serif",
    textAlign: "center",
    [theme.breakpoints.down(600)]: {
      fontSize: 15,
    },
  },
  selector: {
    width: "100%",
  },
  disclaimer: {
    marginTop: 20,
    fontSize: 12,
    color: "gray",
  },
}));
