const TERM_DEPOSIT_SIMULATOR_STEP1 =
  'https://storage.googleapis.com/rokinapp-images/tools/SimuladorDAP_ilustracion%20(1).svg';
const EYE_ICON =
  'https://storage.googleapis.com/rokinapp-images/tools/ojo_icon%20(1).svg';
const AVANCE_ICON =
  'https://storage.googleapis.com/rokinapp-images/tools/siguiente_icon.svg';
const UPDATE_ICON =
  'https://storage.googleapis.com/rokinapp-images/icons/actualiza_icon.svg';
const ORDER_IMG =
  'https://storage.googleapis.com/rokinapp-images/Bussiness/vectoresImagen1_compressed.svg';
const VECTOR_IMG_2 =
  'https://storage.googleapis.com/rokinapp-images/Bussiness/estabilidadYProteccion_compressed.svg';
const VECTOR_IMG_3 =
  'https://storage.googleapis.com/rokinapp-images/tools/vectoresImagen3%20(2).svg';
const SCREEN_PRINCIPAL_VIDEO =
  'https://storage.googleapis.com/rokinapp-images/tools/screenvideo';
const ROKIN_PLUS_LOGO =
  'https://storage.googleapis.com/rokinapp-images/ProductTour/rokinPlus_logoOp2%402x.png';
const LANDING_MOCKUP_CHILENOS =
  'https://storage.googleapis.com/rokinapp-images/Landing/rokinMockupPlaceYourDesignHere.svg';
const COMILLA_DER =
  'https://storage.googleapis.com/rokinapp-images/Landing/comillaizq.svg';
const COMILLA_IZQ =
  'https://storage.googleapis.com/rokinapp-images/Landing/comillader.svg';
const EXITO_IMAGE =
  'https://storage.googleapis.com/rokinapp-images/Services/exito.png';
const SERVICES_GREEN =
  'https://storage.googleapis.com/rokinapp-images/icons/service_icon_celeste.svg';
const SERVICES_GREY =
  'https://storage.googleapis.com/rokinapp-images/icons/service_icon_grey.svg';
const SUCCESSFUL_PAY_ASTRONAUT =
  'https://storage.googleapis.com/rokinapp-images/Services/payment/astronaut_success.png';
const FAILED_PAY_ASTRONAUT =
  'https://storage.googleapis.com/rokinapp-images/Services/payment/astronaut_error.png';
const BANNER_COOLEBRA_MOBILE =
  'https://storage.googleapis.com/rokinapp-images/Landing/Banner%20coolebra_mobile%403x.webp';
const BANNER_COOLEBRA_DESKTOP =
  'https://storage.googleapis.com/rokinapp-images/Landing/Banner%20Coolebra%20_%20desktop%403x.webp';
const SEARCH_ICON =
  'https://storage.googleapis.com/rokinapp-images/icons/Vector.svg';
const MASTERCARD_ICON =
  'https://storage.googleapis.com/rokinapp-images/SmartSave/Mastercard-Logo.wine%201.svg';
export {
  MASTERCARD_ICON,
  SEARCH_ICON,
  BANNER_COOLEBRA_DESKTOP,
  BANNER_COOLEBRA_MOBILE,
  COMILLA_IZQ,
  COMILLA_DER,
  ORDER_IMG,
  VECTOR_IMG_2,
  VECTOR_IMG_3,
  SCREEN_PRINCIPAL_VIDEO,
  ROKIN_PLUS_LOGO,
  EXITO_IMAGE,
  SERVICES_GREY,
  SERVICES_GREEN,
  TERM_DEPOSIT_SIMULATOR_STEP1,
  EYE_ICON,
  AVANCE_ICON,
  UPDATE_ICON,
  LANDING_MOCKUP_CHILENOS,
  SUCCESSFUL_PAY_ASTRONAUT,
  FAILED_PAY_ASTRONAUT,
};
export * from './Icons';
