import { ApolloClient, gql } from "@apollo/client";

const SEND_EMAIL = gql`
  mutation CreateContactForm($data: CreateContactFormParams) {
    createContactForm(data: $data)
  }
`;

interface sendEmailProps {
  fullname: string;
  email: string;
  phone: string;
  topic: string;
  describe: string;
}

type SendEmailParams = {
  data: sendEmailProps;
};

export async function sendEmail({
  fullname,
  email,
  phone,
  topic,
  describe,
  client,
}: sendEmailProps & { client: ApolloClient<object> }): Promise<boolean> {
  const result = await client.mutate<boolean, SendEmailParams>({
    mutation: SEND_EMAIL,
    variables: { data: { fullname, email, phone, topic, describe } },
  });
  return !!result.data;
}
