import React, { useCallback } from "react";
import { useMediaQuery } from "@material-ui/core";
import useStyles from "./YouSeek.style";
import ServiceButton from "../ServiceButton/ServiceButton";
import HeaderServices from "../Layout/Header/Header";
import { navigate } from "gatsby";
import { Route } from "@interfaces";
import { useForm } from "@hooks";
interface YouSeekProps {
  className?: string;
  setStepContinue: (value: number) => void;
}
const YouSeek = ({ className, setStepContinue }: YouSeekProps) => {
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const classes = useStyles();
  const [form] = useForm();

  const selectType = useCallback(
    (value: string) => {
      form.sure = {
        "Tipo de seguro": value,
      };
    },
    [form]
  );

  return (
    <>
      <HeaderServices
        title="SEGUROS"
        onBackClick={() => {
          navigate(Route.services);
        }}
      />
      <div className={`${className || ""} ${classes.container}`}>
        <div className={classes.title}>¿Qué seguro buscas?</div>
        <section className={classes.containerList}>
          {List.map((item) => (
            <ServiceButton
              onClick={() => {
                setStepContinue(item.id);
                selectType(item.name);
              }}
              key={item.id}
              name={item.name}
            />
          ))}
        </section>
      </div>{" "}
    </>
  );
};
export default YouSeek;

const List = [
  { id: 0, name: "Accidentes" },
  { id: 1, name: "Automotriz" },
  { id: 2, name: "Deporte" },
  { id: 3, name: "Hogar" },
  { id: 4, name: "Mascota" },
  { id: 5, name: "Oncológico" },
  { id: 6, name: "Salud" },
  { id: 7, name: "Viaje" },
  { id: 8, name: "Vida" },
];
