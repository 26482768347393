import React from "react";
import { Select } from "@components";
import { CommonSelectorProps } from "..";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends CommonSelectorProps {}
const REASON_CONTACT_OPTIONS = [
  "Quiero Cotizar",
  "Tengo una duda",
  "Necesito ayuda",
].map(r => ({
  name: r,
  value: r,
}));
export const ReasonContact: React.FC<Props> = ({
  className,
  name,
  label,
  ...props
}) => {
  return (
    <Select
      className={`${className || ""} altercap-gender-selector`}
      name={name || "reazonContact"}
      label={label || "Razón de contacto"}
      options={REASON_CONTACT_OPTIONS}
      {...props}
    />
  );
};
