import { ApolloClient, gql } from "@apollo/client";
import { DBInvestment, FormInvestment } from "@interfaces";
import { GET_USER_INVESTMENTS_QUERY } from "..";

export interface DeleteInvestmentPayload {
  type: DBInvestment["type"];
  serieId: DBInvestment["serieId"];
  date: FormInvestment["date"];
  goalId?: DBInvestment["goalId"] | null;
  investFrequency?: FormInvestment["investFrequency"];
  period?: FormInvestment["period"];
}

const CREATE_INVESTMENTS_MUTATION = gql`
  mutation DeleteInvestments($ids: [Int!]!) {
    deleteInvestments(ids: $ids) {
      id
      type
      period
      investmentFrequency
    }
  }
`;

type DeleteInvestmentsMutationParams = {
  ids: number[];
};

type DeleteInvestmentsMutationResult = {
  deleteInvestments: boolean;
};

export async function deleteUserInvestments(
  ids: number[],
  refetchInvestments = true,
  client: ApolloClient<object>,
): Promise<boolean> {
  await client.mutate<
    DeleteInvestmentsMutationResult,
    DeleteInvestmentsMutationParams
  >({
    mutation: CREATE_INVESTMENTS_MUTATION,
    variables: { ids },
    refetchQueries: refetchInvestments
      ? [
          {
            query: GET_USER_INVESTMENTS_QUERY,
          },
        ]
      : undefined,
  });
  return true;
}
