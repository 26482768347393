import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  // NativeSelect,
  ListSubheader,
  Theme,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { groupBy } from "lodash";
import { ChangeEvent, OnBlurEvent, SelectOption } from "@interfaces";
import {
  COMPONENT_SIZES,
  getPaddingValue,
  getTransformYValue,
  SizeType,
} from "@styles";
import { useResponsiveInputSize } from "@hooks";
import { CommonInputProps } from "@components/Inputs";

interface SelectProps extends CommonInputProps {
  options: SelectOption[];
  native?: boolean;
  underscore?: boolean;
  tabIndex?: number;
  disableError?: boolean
}

const groupOptions = (
  options: SelectOption[],
): Record<string, SelectOption[]> => {
  return groupBy(options, opt => opt.group);
};

export const Select: React.FC<SelectProps> = ({
  className = "",
  name,
  label,
  value,
  disabled,
  options,
  onChange,
  native,
  underscore,
  tabIndex,
  size: _size,
  error,
  onBlur,
  required,
  disableError,
}) => {
  const size = useResponsiveInputSize({ size: _size });
  const classes = useStyles({ size });
  const hasGroups = Boolean(options[0]?.group);
  const [requiredError, setRequiredError] = useState(false);

  // Display error when no input and bluring
  const onBlurWrapper = useCallback(
    (e: OnBlurEvent) => {
      onBlur && onBlur(e);
      if (!value && !disabled) setRequiredError(true);
    },
    [onBlur, value, disabled, setRequiredError],
  );

  // Remove error when writing
  const onChangeWrapper = useCallback(
    (e: ChangeEvent) => {
      onChange && onChange(e);
      const value = e.target.value;
      if (!value) setRequiredError(true);
      else setRequiredError(false);
    },
    [onChange, setRequiredError],
  );

  useEffect(() => {
    if (disabled) setRequiredError(false);
  }, [disabled, setRequiredError]);

  // eslint-disable-next-line no-constant-condition
  return native && false ? (
    <FormControl variant={underscore ? "standard" : "outlined"}>
      {label && (
        <InputLabel htmlFor={`${name}-simple-select-outlined`}>
          {label}
        </InputLabel>
      )}
      <MuiSelect
        inputProps={{
          name,
          id: `${name}-simple-select-outlined`,
          tabIndex,
        }}
        value={value}
        disabled={disabled}
        onChange={onChange}
        labelId={label ? `${name}-simple-select-outlined` : undefined}
        label={label}
        native={native}
      >
        {!hasGroups &&
          options.map((opt, i) => (
            <option key={i} disabled={opt?.disabled ?? false} value={opt.value}>
              {opt.name}
            </option>
          ))}
        {hasGroups &&
          Object.entries(groupOptions(options)).map(([group, opts]) => (
            <optgroup label={group} key={group}>
              {opts.map(opt => (
                <option key={opt.name} value={opt.value}>
                  {opt.name}
                </option>
              ))}
            </optgroup>
          ))}
      </MuiSelect>
    </FormControl>
  ) : (
    <FormControl
      variant={underscore ? "standard" : "outlined"}
      className={`${classes.root} altercap-selector ${className}`}
      disabled={disabled}
    >
      {label && (
        <InputLabel id={`${name}-simple-select-outlined-label`}>
          {label}
        </InputLabel>
      )}
      <MuiSelect
        inputProps={{ tabIndex }}
        className={classes.selector}
        id={`${name}-simple-select-outlined`}
        name={name}
        value={value}
        // disabled={disabled}
        labelId={label ? `${name}-simple-select-outlined-label` : undefined}
        label={label}
        error={disableError? false : (error || requiredError)}
        required={required}
        onBlur={onBlurWrapper}
        onChange={onChangeWrapper}
      >
        {!hasGroups &&
          options.map((opt, i) => (
            <MenuItem key={i} disabled={opt?.disabled ?? false} value={opt.value}>
              {opt.name}
            </MenuItem>
          ))}
        {hasGroups && [
          ...Object.entries(groupOptions(options)).map(([group, opts]) => [
            <ListSubheader key={group} style={{ backgroundColor: "white" }}>
              {group}
            </ListSubheader>,
            ...opts.map(opt => (
              <MenuItem key={opt.name} value={opt.value}>
                {opt.name}
              </MenuItem>
            )),
          ]),
        ]}
      </MuiSelect>
    </FormControl>
  );
};

type StyleProps = { size: SizeType };

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  root: {
    "& > .MuiInputLabel-root": {
      "& .MuInputLabel-shrink": {
        transform: "translate(14px, -6px) scale(0.75)",
      },
      "&:not(.MuiInputLabel-shrink)": {
        transform: ({ size }) =>
          `translate(14px, ${getTransformYValue(size)}px) scale(1)`,
      },
    },
    "& > .MuiFormLabel-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.20)",
    },
  },
  selector: {
    height: ({ size }) => COMPONENT_SIZES[size],
    "& > .MuiSelect-root": {
      paddingTop: ({ size }) => getPaddingValue(size),
      paddingBottom: ({ size }) => getPaddingValue(size),
    },
  },
}));
