import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Dialog, useMediaQuery } from '@material-ui/core';
import { AppContext, NewInvestContext, ProfileContext } from '@context';
import {
  ButtonPrimary,
  BankSelector,
  BankAccountTypeSelector,
  BankAccountNumberInput,
  ButtonText,
} from '@components';
import {
  makeStyles,
  IconButton,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { WithdrawIcon } from '@images';
import { navigate } from 'gatsby';
import { CongratsModalType, Route } from '@interfaces';
import { Select, TextInput, NumericInput } from '@components';
import {
  updateUser,
  useGoals,
  usePortfolios,
  useUserInvestments,
} from '@apollo';
import { useForm } from '@hooks';
import { useApolloClient } from '@apollo/client';
import { updateUserBank } from 'apollo/user/updateUserBank.mutation';

interface WithdrawFundsModalProps {
  open: boolean;
  onClose: () => void;
  selectedGoal: number;
}

export const WithdrawFundsModal: React.FC<WithdrawFundsModalProps> = ({
  open,
  onClose,
  selectedGoal,
}) => {
  const classes = useStyles();
  const client = useApolloClient();
  const { form, onChange, onContinueClick, anyErrorOnStep } =
    useContext(NewInvestContext);
  const [formBank, , , handleChange] = useForm({});
  const { portfolios } = usePortfolios();
  const { investments } = useUserInvestments();
  const { goals } = useGoals();
  const [myGoal, setMyGoal] = useState<string>('Objetivo');
  const { isBankDataCompleted } = useContext(ProfileContext);
  const [isSecond, setIsSecond] = useState<boolean>(false);
  const [fundValue, setFundValue] = useState<string>('null');
  const [radioSelected, setRadioSelected] = useState<string>('fraction');
  const [currentFundValue, setCurrentValue] = useState<number>(0);
  const [isNumericInput, setIsNumercInput] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState<string>('Aceptar');
  const [invalidAmount, setInvalidAmount] = useState<boolean>(false);
  const [fundsOptions, setFundsOptions] = useState([
    { value: 'null', name: 'No hay Fondos', seireId: 0, currentValue: 0 },
  ]);
  const [isAmount, setAmount] = useState<number>();

  useEffect(() => {
    if (isSecond) {
      if (isAmount == 0) {
        setInvalidAmount(true);
      } else {
        setInvalidAmount(false);
      }
    }
  }, [isAmount]);

  useEffect(() => {
    const goal = goals.filter((obj) => obj.id === selectedGoal);
    form.goal = selectedGoal;

    if (goal[0] !== undefined) {
      setMyGoal(goal[0].name);
    }
  }, [open, selectedGoal]);

  function getFundsOptions(data) {
    const fundsArray = [];

    if (data.length > 0) {
      data.forEach((element) => {
        if (element.__typename == 'Portfolio') {
          fundsArray.push({
            value: element.id,
            name: element.name,
            serieId: ' ',
            currentValue: element.currentValue,
            type: 'Portfolio',
          });
          setFundValue(element.id);
        }
        if (element.__typename == 'UserInvestment') {
          fundsArray.push({
            value: element.id,
            name: element.fund.name + ' - ' + element.serie.name,
            serieId: element.serie.id,
            currentValue: element.currentValue,
            type: 'UserInvestment',
          });
          setFundValue(element.id);
        }
      });
    }

    return fundsArray;
  }

  useEffect(() => {
    setFundsOptions(
      getFundsOptions(
        [...portfolios, ...investments].filter(
          (obj) => obj.goalId === selectedGoal
        )
      )
    );
  }, [selectedGoal]);

  useEffect(() => {
    if (fundValue !== 'null' || fundValue !== undefined) {
      const fundOptionsFiltered = fundsOptions.filter(
        (obj) => obj.value === fundValue
      )[0];
      setCurrentValue(fundOptionsFiltered?.currentValue);
      if (fundOptionsFiltered?.type == 'Portfolio') {
        form.portfolioId = fundValue;
        form.fund = null;
        form.serieId = null;
      } else {
        form.fund = fundValue;
        form.serie = fundOptionsFiltered?.serieId;
        form.portfolioId = null;
      }
    }
  }, [fundValue]);

  useEffect(() => {
    if (radioSelected == 'all') {
      setAmount(currentFundValue);
      setIsNumercInput(true);
    } else {
      setAmount(0);
      setIsNumercInput(false);
    }
  }, [setAmount, radioSelected]);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioSelected((event.target as HTMLInputElement).value);
  };

  const handleClick = useCallback(() => {
    setButtonText('Retirar');
    setIsSecond(true);
    setInvalidAmount(true);
    if (!isSecond) {
      updateUserBank(
        {
          bank: formBank.bank,
          accountNumber: formBank.accountNumber,
          accountType: formBank.accountType,
        },
        client
      );
    }
    if (isSecond) {
      onClose();
      setIsSecond(false);
      setInvalidAmount(false);
    }
    onContinueClick();
  }, [formBank, client, isSecond, form]);

  const handleOnClose = () => {
    onClose();
    setButtonText('Aceptar');
    setIsSecond(false);
    setInvalidAmount(false);
  };
  return (
    <>
      <Dialog open={open} onClose={handleOnClose} className={classes.Dialog}>
        <IconButton onClick={handleOnClose} className={classes.CloseIcon}>
          <CloseIcon />
        </IconButton>
        <div className={classes.Modal}>
          <div className={classes.info}>
            <div className={classes.titulo}>
              <div className={classes.numberInfo}>
                <img
                  src={
                    'https://storage.googleapis.com/rokinapp-images/investments/CreateNew/expense_icon%20(2).svg'
                  }
                  className={classes.numberImg}
                />
              </div>
              <div className={classes.text1}>Retirar Fondos</div>
            </div>
            <div className={classes.linea}></div>
            {!isSecond && (
              <div className={classes.savingOnText}>
                Confirma los datos de tu cuenta bancaria
              </div>
            )}
            {isSecond && (
              <div>
                <div className={classes.rescueTitle}>
                  Cuánto quieres restacar:
                </div>
                <div className={classes.rescueSubtitle}>Rescataremos de:</div>
              </div>
            )}
            <div className={classes.savingOn}>
              {!isSecond && (
                <>
                  <span className={classes.titleSelector}>banco</span>
                  <BankSelector
                    className={classes.selectors}
                    value={formBank.bank}
                    onChange={handleChange}
                    label=" "
                  />{' '}
                  <span className={classes.titleSelector}>cuenta</span>
                  <BankAccountTypeSelector
                    className={classes.selectors}
                    value={formBank.accountType}
                    label=" "
                    onChange={handleChange}
                  />{' '}
                  <span className={classes.titleSelector}>n° de cuenta</span>
                  <BankAccountNumberInput
                    className={classes.selectors}
                    value={formBank.accountNumber}
                    label=" "
                    onChange={handleChange}
                  />
                </>
              )}
              {isSecond && (
                <>
                  <Select
                    className={`title ${classes.selector}`}
                    name="sort"
                    label=" "
                    value={fundValue}
                    onChange={(e) => setFundValue(e.target.value)}
                    size={'large'}
                    options={fundsOptions}
                  />
                  <div className={classes.radio}>
                    <FormControl>
                      <FormLabel id="demo-controlled-radio-buttons-group"></FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={radioSelected}
                        onChange={handleRadioChange}
                      >
                        <FormControlLabel
                          value="all"
                          control={<Radio />}
                          label={`Todo`}
                        />{' '}
                        <FormControlLabel
                          value="fraction"
                          control={<Radio />}
                          label="Una parte"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className={classes.amount}>
                    <span>Monto a rescatar:</span>
                    <NumericInput
                      className="numberinput"
                      name="amount"
                      onChange={(e) => {
                        setAmount(Number(e.target.value));
                        onChange(e);
                      }}
                      label=" "
                      disabled={isNumericInput}
                      value={isAmount}
                    />
                    <span className="subtitle">
                      Disponible: ${currentFundValue?.toLocaleString('es')}
                    </span>
                  </div>
                  <div className={classes.account}>
                    <div>A tu cuenta bancaria</div>
                    <span>
                      {formBank.bank} - {formBank.accountType} -{' '}
                      {formBank.accountNumber}
                    </span>
                  </div>
                </>
              )}

              <div className={classes.amountOf}>
                {!isSecond && (
                  <div className={classes.targetText}>
                    Estás retirando de: <div>{myGoal}</div>
                  </div>
                )}
                {!isSecond && (
                  <div className={classes.center}>
                    <ButtonPrimary
                      disabled={
                        !isBankDataCompleted || anyErrorOnStep || invalidAmount
                      }
                      className={classes.button}
                      text={buttonText}
                      onClick={handleClick}
                    />
                  </div>
                )}
                {isSecond && (
                  <div className={classes.center}>
                    <ButtonPrimary
                      className={classes.button}
                      text={'Retirar'}
                      disabled={
                        !isBankDataCompleted || anyErrorOnStep || invalidAmount
                      }
                      onClick={handleClick}
                    />
                    <ButtonText
                      className={classes.button}
                      text={'Cancelar'}
                      onClick={onClose}
                    />
                  </div>
                )}
                {isSecond && (
                  <div className={classes.bottomText}>
                    <strong> OJO</strong>, te llegará el monto aproximado de $
                    {isAmount?.toLocaleString('es')}, este puede subir o bajar
                    ligeramente en los días que demore el retiro
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};
const useStyles = makeStyles(
  (theme) => ({
    Dialog: {
      '& >div >div': {
        borderRadius: '20px',
        opacity: '90%',
        maxWidth: 700,
        width: '85%',
        height: '35rem',
      },
    },
    Modal: {
      height: 560,
      flexGrow: 0,
      padding: '22px 44px 22px 44px',
      borderRadius: 20,
      border: '1px solid #ffffff',
      [theme.breakpoints.down(700)]: {
        width: 500,
      },
      [theme.breakpoints.down(600)]: {
        width: '95%',
        margin: '0 auto',
        padding: '10px 15px',
      },
    },
    info: {
      display: 'grid',
      minWidth: 275,
      width: '90%',
      margin: '0 auto',
    },
    titulo: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '15px',
      gap: '1rem',
      width: '90%',
      paddingBottom: '0.5rem',
      borderBottom: '1px solid white',
    },
    CloseIcon: {
      position: 'absolute',
      right: 20,
      top: 20,
    },
    savingOnText: {
      fontFamily: 'Montserrat',
      fontSize: '0.875rem',
      fontWeight: 400,
      padding: '4px 0px 15px 0',
      color: '#282828',
    },
    numberInfo: {
      position: 'relative',
      '& >span': {
        position: 'absolute',
        zIndex: 3,
        top: 37,
        left: 51,
        color: '#4619b6',
        fontSize: '1.5rem',
        fontWeight: 'bold',
        fontFamily: 'montserrat',
      },
      '& img': {
        width: '1.5rem',
      },
    },
    numberImg: {
      width: '110px',
      height: 'auto',
    },
    text1: {
      fontSize: '1rem',
      fontWeight: 'bold',
      color: '#393E44',
      fontFamily: 'Montserrat',
    },
    linea: {
      width: '100%',
      height: 2,
      flexGrow: 0,
      backgroundColor: '#fff',
    },
    button: {
      width: 220,
      right: 15,
      fontSize: '1rem',
      textDecoration: 'none',
      [theme.breakpoints.down(600)]: {
        width: '12rem',
        height: 50,
        display: 'flex',
        justifyContent: 'center',
        marginTop: '2rem',
      },
    },
    center: {
      margin: '0 auto',
    },
    savingOn: {
      display: 'grid',
    },
    dropdownClass: {
      width: '100%',
      boxShadow: '4px 4px 8px -3px rgb(0 0 0 / 60%)',
      height: 44,
      top: 10,
      [theme.breakpoints.down(600)]: {
        width: '96%',
      },
    },
    targetText: {
      fontFamily: 'Montserrat',
      fontSize: '0.75rem',
      fontWeight: 300,
      color: '#979799',
      [theme.breakpoints.down(600)]: {
        textAlign: 'start',
      },
      '& >div': {
        fontSize: '0.875rem',
        fontWeight: 'bold',
        color: '#008296',
      },
      '& >span': {
        fontFamily: theme.typography.fontFamily,
        fontSize: '12px',
        fontWeight: 'normal',
        color: '#008296',
      },
    },
    amountOf: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      marginTop: '1rem',
    },
    amountText: {
      boxShadow: '4px 4px 8px -3px rgb(0 0 0 / 60%)',
      top: '12px',
      width: '100%',
      [theme.breakpoints.down(600)]: {
        width: '97%',
      },
    },
    radio: {
      '& div, div': {
        flexDirection: 'initial',
        '& span': {
          fontSize: '0.875rem',
          fontFamily: 'Montserrat',
        },
        '& span:last-child': {
          marginRight: '5rem',
        },
        '& svg': {
          color: theme.palette.primary.main,
        },
      },
    },
    amount: {
      display: 'inherit',
      textTransform: 'uppercase',
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      fontSize: '0.75rem',
      marginBottom: '0.2rem',
      marginTop: '0.5rem',
      color: '#646464',
      '& span': {
        fontSize: '0.75rem',
        fontWeight: 'bold',
        marginBottom: '0.3rem',
      },
      '& >div': {
        width: '100%',
      },
      '& .subtitle': {
        fontWeight: 400,
        color: '#979799',
        fontSize: '0.875rem',
        textTransform: 'capitalize',
        margin: '0.5rem 0 1rem 0',
      },
      '& .numberinput': {
        width: '9.2rem',
      },
    },
    bottomText: {
      fontSize: 16,
      paddingBottom: '2rem',
      textAlign: 'center',
    },
    selectors: {
      marginBottom: 21.5,
      width: '95%',
    },
    titleSelector: {
      fontSize: '0.75rem',
      fontWeight: 500,
      textTransform: 'uppercase',
      color: '#646464',
    },
    rescueTitle: {
      fontFamily: 'Montserrat',
      fontSize: '1.25rem',
      fontWeight: 'bold',
      color: '#282828',
    },
    rescueSubtitle: {
      fontFamily: 'Montserrat',
      fontSize: '1rem',
      fontWeight: 'bold',
      color: '#282828',
    },
    selector: {
      margin: '1rem 0',
    },
    account: {
      '& div': {
        color: '#282828',
        fontSize: '1rem',
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
      },
      '& span': {
        color: '#282828',
        fontSize: '0.875rem',
        fontFamily: 'Nunito',
        fontWeight: 300,
      },
    },
  }),
  { index: 1 }
);
