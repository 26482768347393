import { ApolloClient, gql } from "@apollo/client";
import { ProfileForm, AltercapUser as User } from "@interfaces";
import { GET_CURRENT_USER_QUERY } from ".";

const UPDATE_USER = gql`
  mutation UpdateUser($data: UpdateUserParams!) {
    updateUser(data: $data) {
      name
      email
    }
  }
`;

type UpdateUserParams = {
  data: Partial<ProfileForm>;
};

type UpdateUserResult = {
  updateUser: User | null;
};

export async function updateUser(
  payload: Partial<ProfileForm>,
  refetchMe = true,
  client: ApolloClient<object>
): Promise<User | null> {
  // Delete unupdateable attributes
  (
    [
      "isPersonalDataCompleted",
      "isSpouseDataCompleted",
      "isSpecialDataCompleted",
      "isBankDataCompleted",
      "isIdentityValidated",
      "areDocumentsSigned",
      "isOnboardingCompleted",
      "uuid",
      "passwordRecoverToken",
      "haveVectorAccount",
    ] as (keyof ProfileForm)[]
  ).forEach((attr) => delete payload[attr]);
  let result;
  try {
    result = await client.mutate<UpdateUserResult, UpdateUserParams>({
      mutation: UPDATE_USER,
      variables: {
        data: payload,
      },
      refetchQueries: refetchMe
        ? [
            {
              query: GET_CURRENT_USER_QUERY,
            },
          ]
        : undefined,
    });
  } catch (e) {
    console.log("ERROR", e);
  }
  return result?.data?.updateUser ?? null;
}
