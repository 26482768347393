import React, { useCallback, useContext } from "react";
import { Dialog, useMediaQuery } from "@material-ui/core";
import { AppContext, MissionsContext } from "@context";
import { ButtonPrimary, ButtonOutlined } from "@components/Reusables";
import { makeStyles } from "@material-ui/core";
import { numberMission, RoksCongrats, spaceman_congrats } from "@images";
import { navigate } from "gatsby";
import { CongratsModalType, Route } from "@interfaces";
import { MissionsInfo } from "@data";

interface CongratsModalProps {
  open: boolean;
  missionInfo: CongratsModalType;
}

export const CongratsModal: React.FC<CongratsModalProps> = ({
  open,
  missionInfo,
}) => {
  const classes = useStyles();
  const { closeCongratsModal } = useContext(AppContext);

  const isMobile = useMediaQuery("(max-width: 600px)");

  const category = missionInfo;

  const myPortal = useCallback(() => {
    closeCongratsModal();
    category.category === MissionsInfo.ProfileCategory.name &&
      navigate(Route.completeProfile);
    category.category === MissionsInfo.BudgetCategory.name &&
      navigate(Route.budget);
    category.category === MissionsInfo.SavingsInvestmentCategory.name &&
      navigate(Route.savingsAndInvestment);
    category.category === MissionsInfo.LoansDebtCategory.name &&
      navigate(Route.creditsDebts);
    category.category === MissionsInfo.RetirementCategory.name &&
      navigate(Route.retirement);
  }, [category]);

  return (
    <>
      <Dialog open={open} className={classes.Dialog}>
        <div className={classes.topDialog}>
          <div className={classes.borderEx}></div>
          <img src={RoksCongrats} className={classes.roksImgBanner} />
          <div className={classes.rewardBanner}>+{missionInfo?.thisReward}</div>
          <div className={classes.textBanner}>
            Felicidades!<span>Has completado la misión con éxito</span>
          </div>
        </div>
        <div className={classes.Modal}>
          {!missionInfo ? (
            <>
              <div className={classes.ContLM}>
                <img
                  src={spaceman_congrats}
                  className={classes.ImgLastMission}
                />
                <div className={classes.infoML}>
                  <div className={classes.textLM}>
                    ¡Felicidades! Has completado todas las misiones de la
                    categoría.
                    <span>{category}</span>
                  </div>
                  <div className={classes.linea}></div>
                  <div className={classes.botonLM}>
                    <ButtonPrimary
                      className={`${classes.button} ${classes.buttonOutlined}`}
                      text="Volver a la Rokipedia"
                      onClick={() => {
                        navigate(Route.rokipedia);
                        closeCongratsModal();
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className={classes.info}>
              <div className={classes.borderEx2}></div>
              <div className={classes.titulo}>
                <div className={classes.numberInfo}>
                  <img src={numberMission} className={classes.numberImg} />
                  <span
                    className={`${classes.positionNumber} ${
                      missionInfo?.number !== 1 && classes.left
                    }`}
                  >
                    {missionInfo?.number}
                  </span>
                </div>
                <div className={classes.text1}>
                  Próxima misión<span>{missionInfo?.name}</span>
                </div>
              </div>
              <div className={classes.linea}></div>
              <div className={classes.text2}>{missionInfo?.description}</div>
              <div className={classes.textButton}>
                <div className={classes.text3}>Recompensa:</div>
                <div className={classes.textButton2}>
                  <div>
                    <img src={RoksCongrats} className={classes.roksImg} />
                    <div className={classes.text4}>
                      {missionInfo?.nextReward} Roks
                    </div>
                  </div>
                  <div>
                    <ButtonOutlined
                      className={`${classes.button} ${classes.buttonOutlined}`}
                      text="Volver a mi Portal"
                      onClick={myPortal}
                    />
                    <ButtonPrimary
                      className={classes.button}
                      text="Siguiente misión"
                      onClick={() => {
                        navigate(missionInfo.route);
                        closeCongratsModal();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
};
const useStyles = makeStyles(
  (theme) => ({
    topDialog: {
      position: "fixed",
      top: 60,
      width: 800,
      height: 145,
      border: "1px solid #fff",
      borderRadius: "35px",
      background: "linear-gradient(161deg, #8250ff -6%, #181331 117%)",
      padding: "0 0 0 15px",
      display: "flex",
      [theme.breakpoints.down(600)]: {
        width: 340,
        top: 35,
      },
    },
    Dialog: {
      "& >div": {},
      "& >div >div": {
        borderRadius: "35px",
        position: "absolute",
        top: 195,
        maxWidth: 900,
        [theme.breakpoints.down(600)]: {
          top: 170,
        },
      },
    },
    Modal: {
      width: 800,
      height: 395,
      flexGrow: 0,
      padding: "40px 44px 44px 44px",
      borderRadius: 35,
      border: "1px solid #ffffff",
      [theme.breakpoints.down(600)]: {
        width: 340,
        minHeight: 445,
        padding: "10px 15px",
      },
    },
    info: {
      display: "grid",
    },
    titulo: {
      display: "flex",
      [theme.breakpoints.down(600)]: {
        display: "grid",
        gridTemplateColumns: "22% 78%",
      },
    },
    numberInfo: {
      position: "relative",
      margin: "-20px -15px -10px -15px",

      "& >span": {
        position: "absolute",
        zIndex: 3,
        top: 37,
        left: 48,
        color: "#4619b6",
        fontSize: "1.5rem",
        fontWeight: "bold",
        fontFamily: "montserrat",
        [theme.breakpoints.down(600)]: {
          left: "0",
          top: "0",
          width: 85,
          height: 85,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      },
    },
    borderEx: {
      border: "0.5px solid white",
      opacity: 0.5,
      height: 160,
      width: 815,
      left: -8,
      top: -8,
      position: "absolute",
      borderRadius: 40,
      [theme.breakpoints.down(600)]: {
        width: 355,
      },
    },
    borderEx2: {
      /*       border: "2px solid red",
       */ height: 400,
      width: 613,
      left: -8,
      top: -8,
      position: "absolute",
      borderRadius: 40,
      [theme.breakpoints.down(600)]: {
        width: 340,
      },
    },
    numberImg: {
      width: "110px",
      height: "auto",
      [theme.breakpoints.down(600)]: {
        width: 85,
        position: "relative",
      },
    },
    roksImg: {
      width: 60,
      height: 60,
    },
    positionNumber: {
      [theme.breakpoints.down(600)]: {},
    },
    roksImgBanner: {
      width: 140,
      height: "auto",
      [theme.breakpoints.down(600)]: {
        width: 80,
      },
    },
    text1: {
      display: "grid",
      fontFamily: theme.typography.fontFamily,
      fontSize: "0.87rem",
      fontWeight: "normal",
      color: "#008296",
      marginTop: "10px",
      [theme.breakpoints.down(600)]: {
        fontSize: 14,
      },
      "& span": {
        fontFamily: theme.typography.fontFamily,
        fontSize: "1.5rem",
        fontWeight: "bold",
        color: "#393E44",
        marginTop: "-20px",
        [theme.breakpoints.down(600)]: {
          marginTop: -5,
          fontSize: 16,
        },
      },
    },
    linea: {
      width: "100%",
      height: 2,
      flexGrow: 0,
      backgroundColor: "#008296",
    },
    text2: {
      fontFamily: "Montserrat",
      fontSize: "1rem",
      fontWeight: 300,
      color: theme.palette.text.primary,
      minHeight: 93,
      marginTop: 20,
      marginBottom: 20,
    },
    textButton: {
      display: "grid",
    },
    text3: {
      fontFamily: theme.typography.fontFamily,
      fontSize: "1.25rem",
      fontWeight: "bold",
      color: "#393e44",
      marginTop: 10,
    },
    textButton2: {
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down(600)]: {
        display: "grid",
      },
      "& >div": {
        display: "flex",
      },
      "& >div:last-child": {
        [theme.breakpoints.down(600)]: {
          display: "grid",
        },
      },
    },
    text4: {
      fontFamily: "Montserrat",
      fontSize: "1rem",
      fontWeight: "normal",
      color: "#000000",
      margin: "20px 20px 0 0",
    },
    rewardBanner: {
      fontFamily: theme.typography.fontFamily,
      fontSize: "48px",
      fontWeight: "bold",
      margin: "32px 0 0 -10px",
      color: "#fff",
      [theme.breakpoints.down(600)]: {
        fontSize: 16,
        margin: "66px 0 0 -12px",
      },
    },
    textBanner: {
      fontFamily: theme.typography.fontFamily,
      fontWeight: "bold",
      color: "#fff",
      fontSize: "2.5rem",
      paddingTop: "22px",
      paddingLeft: "40px",
      [theme.breakpoints.down(600)]: {
        fontSize: 24,
      },
      "& >span": {
        position: "absolute",
        top: 82,
        left: 245,
        color: "#fff",
        fontSize: "1rem",
        fontFamily: "montserrat",
        fontWeight: 400,
        [theme.breakpoints.down(600)]: {
          fontSize: 14,
          top: 70,
          left: 140,
          width: 155,
        },
      },
    },
    button: {
      width: 164,
      height: 50,
      margin: "8px 0 0 20px",
      boxShadow: "2px 4px 5px 1px rgb(0 0 0 / 25%)",
      [theme.breakpoints.down(600)]: {
        width: 270,
      },
    },
    buttonOutlined: {
      boxShadow: "1px 2px 5px 1px rgb(0 0 0 / 25%)",
      "& >span": {
        fontWeight: 300,
      },
    },
    ImgLastMission: {
      width: "15.563rem",
      height: "21.688rem",
      [theme.breakpoints.down(600)]: {
        width: "11.063rem",
        height: "15.375rem",
        marginLeft: "4rem",
      },
    },
    ContLM: {
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down(600)]: {
        display: "grid",
      },
    },
    infoML: {
      marginTop: 70,
    },
    textLM: {
      fontFamily: "Montserrat",
      fontSize: "1rem",
      fontWeight: "bold",
      color: "#393e44",
      marginBotton: "15px",
      marginTop: 10,
      [theme.breakpoints.down(600)]: {
        fontSize: 14,
        marginTop: -67,
      },
    },
    botonLM: {
      width: 164,
      height: 50,
      margin: "16px 0 0 109px",
      [theme.breakpoints.down(600)]: {
        width: 50,
        height: "auto",
        margin: "16px 0 0 0px",
      },
    },
  }),
  { index: 1 }
);
