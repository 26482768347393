import { ApolloClient, gql, useQuery } from "@apollo/client";
import { UserPendingMission } from "@interfaces";
import { useAuth } from "@hooks";

export const GET_PENDING_MISSIONS = gql`
    query UserPendingMission {
        userPendingMission {
        mission {
          description
          shortDescription
          condition
          roks
          name
          nextMission
          estimateTimeMin
          category
          subCategory
          subCategoryNumber
        }
        state
        }
    }
`;

interface QueryResult {
  userPendingMission: UserPendingMission[];
}

interface HookReturn {
  userPendingMission: UserPendingMission[];
  loading: boolean;
}

export const getUserPendingMission = (): HookReturn => {
  const { data, loading } = useQuery<QueryResult>(GET_PENDING_MISSIONS);
  return {
    userPendingMission: data?.userPendingMission ?? [], 
    loading 
  };
};