import React, { useContext, useEffect, useState } from "react";
import { useStyles } from "./M5mainComponent.style";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  navArrowLeft,
  navArrowRight,
  navArrowLeftActive,
  navArrowRightActive,
  LightIconPurple,
  creditCardsMain,
} from "@images";
import { IconButton } from "@material-ui/core";
import { ButtonPrimary } from "@components";
import { AppContext } from "@context";

type Props = {
  handleClick: () => void;
};

const M5MainComponent: React.FC<Props> = ({ handleClick }) => {
  const classes = useStyles();
  const { openCongratsModal } = useContext(AppContext);
  const slider = React.useRef(null);
  const [currentLeftArrow, setCurrentLeftArrow] = useState(navArrowLeft);
  const [currentRightArrow, setCurrentRightArrow] =
    useState(navArrowRightActive);
  const [currentSlide, setCurrentSlide] = useState(0);

  const ellipseDots = {
    width: "14.9px",
    height: "14.9px",
    flexGrow: 0,
    margin: "0 16.6px 0 0",
    "background-image": "linear-gradient(335deg, #8352FD 127%, #8250ff 8%)",
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (current, next) => {
      setCurrentSlide(next);
    },
    dotsClass: `slick-dots ${classes.dots}`,
  };
  useEffect(() => {
    if (currentSlide == 0) {
      setCurrentLeftArrow(navArrowLeft);
      setCurrentRightArrow(navArrowRightActive);
    }
    if (currentSlide == 1) {
      setCurrentLeftArrow(navArrowLeftActive);
      setCurrentRightArrow(navArrowRight);
    }
  }, [currentSlide]);

  const values1 = [
    {
      id: "1.",
      title: "Interés por cuotas",
      text: (
        <span className="text">
          Generalmente al comprar en cuotas vas a tener que pagar un
          <strong> monto extra al banco por el uso de las cuotas. </strong>
          Hay casos en los que el banco ofrece cuotas sin interés en algunos
          lugares:
          <strong>
            {" "}
            ¡siempre pregunta las condiciones o revisa tu banco antes de comprar
            en cuotas!{" "}
          </strong>
        </span>
      ),
      secondText: (
        <span className="text2">
          <strong>Revisa las condiciones del banco </strong>y pregunta en la
          tienda si ofrecen
          <strong> coutas sin interés.</strong>
        </span>
      ),
    },
    {
      id: "2.",
      title: "Cobro por mantención mesual",
      text: (
        <span className="text">
          Dentro de las condiciones de tu cuenta es probable que exista un
          <strong> cobro por cada mes que uses la tarjeta. </strong>
          Te recomendamos
          <strong> comparar los cobros de las distintas opciones, </strong>para
          asegurarte que estás usando la que no te cobra de más.
        </span>
      ),
      secondText: (
        <span className="text2">
          Si tienes mas de una tarjeta,
          <strong> revisa si los beneficios son mayores al cobro </strong>
          antes de usarlas todas.
        </span>
      ),
    },
  ];
  const values2 = [
    {
      id: "3.",
      title: "Interés Rotativo",
      text: (
        <span className="text">
          Cobro extra que se te exige si no pagas el
          <strong> monto total de tu cuota mensual.</strong>
        </span>
      ),
      secondText: (
        <span className="text2">
          Pon
          <strong> recordatorios </strong>
          para que no se te pase la fecha de pago y
          <strong> evita pagar el mínimo.</strong>
        </span>
      ),
    },
    {
      id: "4.",
      title: "Interés por Avance",
      text: (
        <span className="text">
          Cobro extra que se hace al
          <strong> retirar plata en efectivo de un cajero automático. </strong>
        </span>
      ),
      secondText: (
        <span className="text2">
          Prefiere
          <strong> retirar plata de un cajero </strong>
          con tarjeta de débito y
          <strong> evita hacerlo con la de crédito.</strong>
        </span>
      ),
    },
  ];
  return (
    <div className={classes.container}>
      <div className={classes.mainText}>
        <span className="text">
          Estos son
          <strong> cobros </strong>
          que pueden estar haciéndote a
          <strong> través de tu tarjeta y quizás no sabes </strong>. La tarjeta
          de crédito
          <strong> tiene 4 intereses o cobros extra: </strong>
        </span>
      </div>
      <div className={classes.midSection}>
        <div className={classes.sliderContainer}>
          <div>
            <IconButton onClick={() => slider?.current?.slickPrev()}>
              <img src={currentLeftArrow} alt="left arrow" />
            </IconButton>
          </div>
          <div className={classes.slider}>
            <Slider ref={slider} {...settings}>
              <div className={classes.slide1}>
                {values1.map((value) => (
                  <div className={classes.slideColumn}>
                    <div className={classes.whiteBox}>
                      <div className="topS">
                        <span className="id">{value.id}</span>
                        <span className="title">{value.title}</span>
                      </div>
                      {value.text}
                    </div>
                    <div className={classes.smallWhiteBox}>
                      <img src={LightIconPurple} />
                      {value.secondText}
                    </div>
                  </div>
                ))}
              </div>
              <div className={classes.slide1}>
                {values2.map((value) => (
                  <div className={classes.slideColumn}>
                    <div className={classes.whiteBox}>
                      <div className="topS">
                        <span className="id">{value.id}</span>
                        <span className="title">{value.title}</span>
                      </div>
                      {value.text}
                    </div>
                    <div className={classes.smallWhiteBox}>
                      <img src={LightIconPurple} />
                      {value.secondText}
                    </div>
                  </div>
                ))}
              </div>
            </Slider>
          </div>
          <div>
            <IconButton onClick={() => slider?.current?.slickNext()}>
              <img src={currentRightArrow} alt="right arrow" />
            </IconButton>
          </div>
        </div>
        <div className={classes.rightColumn}>
          <img src={creditCardsMain} />
          <ButtonPrimary
            text={"Terminar misión"}
            onClick={() => {
              handleClick();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default M5MainComponent;
