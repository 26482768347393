import { useCallback, useEffect, useState } from "react";
import { OnChangeType } from "@interfaces";
import { GoalForm } from "@interfaces";
import { checkRequiredFields, checkValidForm } from "@utils";
import { INVEST_FREQUENCY_OPTIONS } from "@components";

interface useGoalFormProps {
  formFields?: string[];
}
type FormReturn = [
  GoalForm,
  React.Dispatch<React.SetStateAction<GoalForm>>,
  boolean,
  OnChangeType,
  () => void,
  () => boolean,
];
export const useGoalForm = ({ formFields }: useGoalFormProps): FormReturn => {
  // const { profileForm, setProfileForm } = useContext(ProfileContext);
  const [form, setForm] = useState<GoalForm>({ ...DEFAULT_GOAL_FORM });
  // const [formErrors, setFormErrors] = useState<ProfileFormErrors>(
  //   DEFAULT_FORM_ERRORS
  // );
  const [disableSubmit, setDisableSubmit] = useState(false);

  const handleChange = useCallback(e => {
    const name = e?.target?.name as keyof GoalForm | undefined;
    const value = e?.target?.value as string | number | undefined;
    if (!name) return;
    // setFormErrors(prev => ({
    //   ...prev,
    //   [name]: false,
    // }));
    setForm(prev => {
      return { ...prev, [name]: value };
    });
  }, []);

  const resetForm = useCallback(() => setForm({ ...DEFAULT_GOAL_FORM }), []);

  const validate = useCallback(() => {
    const { hasError } = checkValidForm(form, formFields);
    // setFormErrors(errors);
    return !hasError;
  }, [form, formFields]);

  useEffect(() => {
    setDisableSubmit(
      !checkRequiredFields(form, formFields),
      // || Object.values(formErrors).some(Boolean)
    );
  }, [form, formFields]);

  // TODO: return object and use memoization
  return [form, setForm, disableSubmit, handleChange, resetForm, validate];
};

export const DEFAULT_GOAL_FORM: GoalForm = {
  name: "",
  goal: 5000000,
  period: 2,
  investmentFrequency: "Mensualmente",
};
