import React, { useCallback, useState } from "react";
import { IconButton, InputAdornment } from "@material-ui/core";
import { CommonInputProps } from "..";
import ShowIcon from "@material-ui/icons/Visibility";
import HideIcon from "@material-ui/icons/VisibilityOff";
import { SizedTextField } from "@components";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends CommonInputProps {
  //autocomplete?:"on" | "off" | undefined
}

export const PasswordInput: React.FC<Props> = ({
  className,
  name,
  label,
  ...props
  //autocomplete
}) => {
  const [showPass, setShowPass] = useState(false);
  const handleToggleVisibility = useCallback(
    () => setShowPass(prev => !prev),
    [setShowPass],
  );
  return (
    <SizedTextField
      className={`${className || ""} altercap-password-input altercap-input`}
      name={name || "password"}
      label={label ?? "Contraseña"}
      {...props}
      //autocomplete={autocomplete}
      type={!showPass ? "password" : "text"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton size="small" onClick={handleToggleVisibility}>
              {showPass ? <HideIcon /> : <ShowIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
