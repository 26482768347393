import React, { useCallback, useContext, useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  IconButton,
} from "@material-ui/core";
import { AppContext, LoadingContext, ProfileContext } from "@context";
import { ButtonPrimary } from "@components/Reusables";
import { NameInput, EmailInput, ReasonsForContactSelector } from "@components";
import { makeStyles } from "@material-ui/core";
import { ChangeEvent } from "@interfaces";
import CloseIcon from "@material-ui/icons/Close";
import { sendContactForm } from "@apollo";
import { useApolloClient } from "@apollo/client";
interface ContactDialogProps {
  open: boolean;
}
export const ContactDialog: React.FC<ContactDialogProps> = ({ open }) => {
  const classes = useStyles();
  const client = useApolloClient();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const { closeContactDialog, openContactDgFinished, closeContactDgFinished } =
    useContext(AppContext);
  const { profileForm } = useContext(ProfileContext);
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  // const [phone, setPhone] = useState("");
  const [topic, setTopic] = useState("");
  const [describe, setDescribe] = useState("");
  const [wrongEmail, setWrongEmail] = useState(false);
  const updateTopic = useCallback((e) => setTopic(e.target.value), []);
  //   const [modalInfo, setModalInfo] = useState(false);

  const onSend = useCallback(() => {
    showLoader("contact modal");
    sendContactForm(
      {
        email,
        fullname,
        // phone,
        topic,
        describe,
      },
      client
    ).then(() => {
      hideLoader("contact modal");
      closeContactDialog();
      openContactDgFinished();
      setTimeout(() => {
        closeContactDgFinished();
      }, 2500);
    });
  }, [fullname, email, describe]);

  const changeEmail = useCallback(
    (e: ChangeEvent) => {
      const value = e.target.value as string;
      setEmail(value);
    },
    [setEmail]
  );

  const onEmailErrorChange = useCallback(
    (error: boolean) => {
      setWrongEmail(error);
    },
    [setWrongEmail]
  );

  useEffect(() => {
    const { name, paternalSurname, maternalSurname } = profileForm;
    setFullname(
      `${name || ""}${paternalSurname ? " " + paternalSurname : ""}${
        maternalSurname ? " " + maternalSurname : ""
      }`
    );

    if (profileForm.email) setEmail(profileForm.email);
  }, [
    profileForm.name,
    profileForm.email,
    profileForm.paternalSurname,
    profileForm.maternalSurname,
  ]);

  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <Dialog open={open} onClose={closeContactDialog} fullScreen={isMobile}>
      <IconButton onClick={closeContactDialog} className={classes.CloseIcon}>
        <CloseIcon />
      </IconButton>
      <DialogTitle>
        <div className={classes.dialogTitle}>Contáctanos</div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div id="modal" className="relative">
          <p className={classes.subtitle}>
            Escríbenos en qué te podemos ayudar
          </p>
          <div>
            <NameInput
              className={`${classes.styleSpace} ${classes.input}`}
              value={fullname}
              onChange={(e) => setFullname(e.target.value as string)}
              label="Nombre completo"
            />
            <EmailInput
              className={`${classes.styleSpace} ${classes.input}`}
              value={email}
              onChange={changeEmail}
              onErrorChange={onEmailErrorChange}
              name="email"
              label="Email"
              disableValidation
            />
            {/* <input
              className="border border-gray-600 py-2 px-4 rounded-md w-full mb-3"
              value={phone}  
              onChange={e => setPhone(e.target.value)}
              placeholder="Teléfono"
            ></input> */}
            <ReasonsForContactSelector
              className={`${classes.styleReasonsContact} ${classes.styleSpace}`}
              value={topic}
              onChange={updateTopic}
              label="Motivo"
              // placeholder="Motivo"
            />
            <textarea
              className={`${classes.styleSpace}`}
              value={describe}
              onChange={(e) => setDescribe(e.target.value)}
              placeholder="Comentario"
            ></textarea>
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <ButtonPrimary
          className={classes.buttonPrimary}
          text="Enviar"
          onClick={onSend}
          disabled={!(fullname && email && topic && describe) || wrongEmail}
        ></ButtonPrimary>
      </DialogActions>
    </Dialog>
  );
};
const useStyles = makeStyles(
  (theme) => ({
    styleReasonsContact: {
      width: "100%",
      "& div": {
        "& div": {
          display: "flex",
          alignItems: "center",
          height: 30,
        },
      },
    },
    styleSpace: {
      margin: "10px 0",
    },
    CloseIcon: {
      position: "absolute",
      right: 15,
      top: 8,
    },
    dialogTitle: {
      color: theme.palette.primary.main,
      fontSize: 24,
      lineHeight: "24px",
      fontWeight: 600,
      textAlign: "center",
    },
    dialogContent: {
      padding: "8px 48px",
      "& input": {
        width: "100%",
        borderColor: "rgba(144, 144, 144, 1)",
        "& > div": {
          paddingTop: 11.5,
          paddingBottom: 11.5,
        },
      },
      "& textarea": {
        marginBottom: 0,
        borderWidth: 1,
        borderColor: "rgba(75, 85, 99, 1)",
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
        borderRadius: 6,
        width: "100%",
      },
      "& input, & textarea": {
        color: "rgba(11, 11, 11, 0.9)",
      },
    },
    dialogActions: {
      justifyContent: "center",
      padding: "14px 0",
    },
    buttonPrimary: {
      minWidth: 200,
    },
    input: {
      borderWidth: 1,
      borderColor: "rgba(75, 85, 99, 1)",
      paddingBottom: 8,
      borderRadius: 6,
      width: "100%",
      marginBottom: 12,
    },
    subtitle: {
      color: "rgba(75, 85, 99, 1)",
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "14px",
      textAlign: "center",
      marginBottom: 32,
    },
  }),
  { index: 1 }
);
