import React from "react";
import { Select } from "@components";
import { CommonSelectorProps } from "..";
import { SelectOption } from "@interfaces";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends CommonSelectorProps {}
const FOUND_OPTIONS = [
  "Ahorrar",
  "Pagar deudas",
  "Mejorar mi jubilación",
  "Viajar",
  "Comprar una casa",
  "Otro",
].map(r => ({
  name: r,
  value: r,
}));
export const FoundSelector: React.FC<Props> = ({
  className,
  name,
  label,
  ...props
}) => {
  return (
    <Select
      className={`${className || ""} altercap-goal-selector`}
      name={name || "goal"}
      label={label || "En qué fondos quieres invertir"}
      options={FOUND_OPTIONS}
      {...props}
    />
  );
};
