import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#edebeb",
    borderRadius: "1.875rem 1.875rem 0 0",
    paddingBottom: "4.3rem",
    "& .email": {
      margin: "20px auto 0",
      width: "245px",
      justifyContent: "center",
      display: "flex",
    },
    "& .checkContainer": {
      display: "flex",
      alignItems: "start",
    },
    "& .check ": {
      fontSize: "0.75rem",
      display: "block",
      color: theme.palette.text.secondary,
      paddingTop: "0.5rem",
    },
  },
  topSection: {
    flexDirection: "column",
    margin: "-3rem 0rem 0",
    padding: "3rem 0rem 0",
    height: "11.5rem",
    borderRadius: "1.875rem",
    backgroundColor: theme.palette.background.paper,
    "& .itemsContainer": {
      height: "4rem",
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      justifyContent: "center",
      overflowX: "auto",
      [theme.breakpoints.down(836)]: {
        justifyContent: "flex-start",
      },
      "& .item": {
        flexDirection: "column",
        width: "10rem",
        minWidth: "9.188rem",
        fontFamily: "Montserrat",
        marginLeft: "1.1rem",
        "& .title": {
          fontSize: "0.65rem",
          textTransform: "uppercase",
          fontWeight: 500,
          color: "#9d9d9d",
          lineHeight: 2.78,
        },
        "& .value": {
          fontSize: "0.85rem",
          fontWeight: 500,
          color: theme.palette.text.primary,
          lineHeight: 1.95,
        },
      },
    },
    "& .purpleContainer": {
      width: "20rem",
      alignSelf: "center",
      margin: "2rem auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      "& .box": {
        width: "20rem",
        padding: "1rem",
        height: "5rem",
        marginBottom: "1rem",

        "& .text": {
          fontSize: "1rem !important",
        },
        "& >img": {
          width: "1.8rem",
          height: "1.8rem",
          marginRight: "1rem",
        },
      },
    },
    "& .purpleBox": {
      display: "flex",
      flexDirection: "row",
      width: "20rem",
      height: "8.43rem",
      margin: "0rem auto",
      borderRadius: "1.875rem",
      backgroundColor: "#8352fd",
      boxShadow: "4px 4px 8px 2px rgba(0, 0, 0, 0.2)",
      padding: "2rem",
      alignItems: "center",
      marginBottom: "3rem",
      "& .text": {
        fontFamily: "Montserrat",
        fontSize: "1.25rem",
        fontWeight: "bold",
        lineHeight: 1.5,
        textAlign: "left",
        color: "#ffffff",
      },
      "& >img": {
        width: "2.5rem",
        height: "2.5rem",
        marginRight: "2.5rem",
      },
    },
  },
  middleSection: {
    display: "flex",
    flexDirection: "column",
    "& .secondWhite": {
      width: "100%",
      padding: "2.063rem",
      borderRadius: "1.875rem",
      boxShadow: "4px 4px 8px 2px rgba(0, 0, 0, 0.2)",
      backgroundColor: theme.palette.background.paper,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "space-evenly",
      fontSize: "0.75rem",

      "& .title": {
        fontFamily: "Montserrat",
        textTransform: "uppercase",
        textAlign: "left",
        fontWeight: "bold",
        lineHeight: 2.78,
        color: theme.palette.text.secondary,
      },
      "& .text": {
        textAlign: "left",
        fontWeight: "normal",
        lineHeight: 2,
        color: "#7d7d7d",
      },
      "& .buttonsSection": {
        width: "100%",
        marginTop: "2rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& >button": {
          width: "15rem",
        },
      },
    },
  },
  space: {
    height: "5.5rem",
  },
  firstColumn: {
    display: "flex",
    flexDirection: "column",
    "& .firstWhite": {
      width: "330px",
      margin: "5rem auto",
      padding: "2.063rem 1.5rem 2rem 1.5rem",
      opacity: 0.9,
      borderRadius: "1.875rem",
      boxShadow: "0.1625rem 0.1625rem 0.325rem 0.0813rem rgba(0, 0, 0, 0.2)",
      backgroundColor: theme.palette.background.paper,
      "& .text": {
        fontFamily: "Montserrat",
        fontSize: "0.85rem",
        fontWeight: "normal",
        lineHeight: 1.7,
        textAlign: "left",
        color: theme.palette.text.secondary,
      },
      "& .mailTag": {
        display: "flex",
        flexDirection: "row",
        width: "16.563rem",
        height: "2.75rem",
        margin: "1.875rem auto 0",
        borderRadius: "0.5rem",
        border: "solid 1px #858c94",
        backgroundColor: "#ffffff",
        justifyContent: "space-around",
        alignItems: "center",
        "& >img": {
          width: "1.5rem",
          height: "1.5rem",
        },
        "& .mail": {
          fontFamily: "Montserrat",
          fontSize: "1rem",
          textAlign: "left",
          color: "#7d7d7d",
        },
      },
    },
    "& .buttonsSection": {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      justifyContent: "space-around",
      marginTop: "2.5rem",
      alignItems: "center",
      "& >button": {
        width: "15.5rem",
        marginBottom: "1.5rem",
      },
    },
  },
  arrow: {
    width: 56,
    background: "white",
    height: 56,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "100%",
    boxShadow: "4px 4px 8px 2px rgba(0, 0, 0, 0.2)",
  },
  upDate: {
    marginLeft: 15,
    "& div:last-child": {
      fontSize: 14,
      textAlign: "center",
      color: theme.palette.text.primary,
      marginTop: 10,
    },
  },
}));
