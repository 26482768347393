import React, { useContext } from "react";
import { useAuth } from "@hooks";
import { useStyles } from "../../components/RefinanceCredit/Step1/InformationPeople/InformationPeople.style";
import { IconButton } from "@material-ui/core";
import { ProfileContext } from "@context";
import { arrowTop, CheckInput, Image } from "@images";
import { FileSpecificType } from "@interfaces";

interface propsInformation {
  dropzone: any;
  disabled?: boolean;
  type: string;
}

export const ButtonFile = ({ dropzone, disabled, type }: propsInformation) => {
  const classes = useStyles();

  const { liquidationFiles } = useContext(ProfileContext);

  useAuth({ redirectIfNotLogged: true });
  const typeFile =
    type === FileSpecificType.LIQUIDATION
      ? !liquidationFiles.LIQUIDATION
      : !liquidationFiles.DEBT;
  const noTypeFile =
    type === FileSpecificType.LIQUIDATION
      ? liquidationFiles.LIQUIDATION
      : liquidationFiles.DEBT;
  return (
    <>
      <div className={`${classes.boxes} ${disabled && classes.disabled}`}>
        <div className="dropzone" {...dropzone.getRootProps()}>
          <div className="side frontside">
            {typeFile && !dropzone.isDragActive && (
              <span className="selectFile">
                <img src={arrowTop} />
                Elegir archivo
              </span>
            )}
            {typeFile && dropzone.isDragActive && (
              <span>Suelta el archivo aqui</span>
            )}
            {noTypeFile && (
              <div className={classes.df}>
                <span className="nameFile">
                  {type === FileSpecificType.LIQUIDATION
                    ? liquidationFiles?.LIQUIDATION?.file.name.slice(0)
                    : liquidationFiles?.DEBT?.file?.name.slice(0)}
                </span>
              </div>
            )}
            {(type === FileSpecificType.LIQUIDATION
              ? liquidationFiles.LIQUIDATION
              : type === FileSpecificType.DEBT && liquidationFiles.DEBT) && (
              <IconButton
                className={classes.iconDelete}
                onClick={(e) => {
                  e.preventDefault();
                }}
                disabled={disabled}
              >
                <img src={CheckInput} />
              </IconButton>
            )}
          </div>
          <input {...dropzone.getInputProps()} />
        </div>
      </div>
    </>
  );
};
