import React, { memo, useContext, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
// import Button from "@material-ui/core/Button";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import {
  Investment,
  Movement,
  MovementType,
  ProfileDialogProps,
} from "@interfaces";
import {
  // DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
// import { GoalInvestmentsTable } from "@components/PageGoals";
import { isEqual } from "lodash";
import "./InvestmentMovementsDialog.scss";
import { parseNumber } from "@utils";
import moment from "moment-timezone";
import { InvestmentsContext } from "@context";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface InvestmentMovementsDialogProps extends ProfileDialogProps {
  investment: Investment | null;
}
const _InvestmentMovementsDialog: React.FC<InvestmentMovementsDialogProps> = ({
  className,
  open,
  onClose,
  investment,
}) => {
  const { deleteMovements } = useContext(InvestmentsContext);
  const [movements, setMovements] = useState<Movement[]>([]);
  useEffect(() => {
    setMovements(
      investment?.movements.sort((a, b) =>
        moment(a.date, "DD/MM/YYYY").diff(moment(b.date, "DD/MM/YYYY"))
      ) ?? []
    );
  }, [investment]);

  // Calculate media to display dialog on fullScreen
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (window.matchMedia("(max-width: 1080px)").matches) setIsMobile(true);
  }, []);
  return (
    <Dialog
      maxWidth="xl"
      className={`${className || ""} investment-movements-dialog ${
        !isMobile ? "desktop-mode" : "mobile-mode"
      }`}
      open={open}
      onClose={onClose}
      fullScreen={isMobile}
    >
      <IconButton className="close-button" onClick={onClose}>
        <CloseRoundedIcon />
      </IconButton>
      <DialogTitle>Todos los movimientos</DialogTitle>
      <DialogContent>
        <div className="movements-grid">
          <div className="cell header">Fecha</div>
          <div className="cell header">Tipo</div>
          <div className="cell header">Monto</div>
          <div className="cell header"></div>
          {movements.map(mov => (
            <React.Fragment key={mov.id}>
              <div className="cell">{mov.date}</div>
              <div className="cell">
                {mov.type === MovementType.DEPOSIT ? "Depósito" : "Retiro"}
              </div>
              <div className="cell">
                {"$" + parseNumber(Math.abs(mov.amount))}
              </div>
              <div className="cell">
                <IconButton
                  className="delete-investment-button"
                  onClick={() => deleteMovements([mov.id])}
                >
                  <DeleteRoundedIcon />
                </IconButton>
              </div>
            </React.Fragment>
          ))}
        </div>
      </DialogContent>
      {/* <DialogActions>
        <Button
          className="next-button"
          variant="contained"
          onClick={() => null}
          disabled={false}
        >
          Cerrar
        </Button>
      </DialogActions> */}
    </Dialog>
  );
};
export const InvestmentMovementsDialog = memo(
  _InvestmentMovementsDialog,
  isEqual
);
