import React, { Dispatch, SetStateAction, useContext } from 'react';

import { useMediaQuery } from '@material-ui/core';
import { useStyles } from './SideMenu.style';
import {
  LOGOUT_ICON,
  NOTIFICATION_ICON,
  PERSONAL_DATA_ICON,
  SETTINGS_ICON,
} from '@images';
import { AuthContext } from '@context';
import { HELP_ICON } from 'images/AWS/profile';

type SideMenuProps = {
  currentView: number;
  setCurrentView: Dispatch<SetStateAction<number>>;
};
const MyProfileSideMenu = ({ currentView, setCurrentView }: SideMenuProps) => {
  const classes = useStyles();
  const { logout } = useContext(AuthContext);

  return (
    <div className={classes.sideMenuContainer}>
      <div className={classes.menuHeader}>
        <div className={classes.menuHeaderTitle}>Mi Perfil</div>
      </div>
      <div className={classes.menuBody}>
        <div
          className={`${classes.menuItem} ${
            currentView === 0 ? classes.menuItemActive : ''
          }`}
          onClick={() => setCurrentView(0)}
        >
          <img src={PERSONAL_DATA_ICON} alt="personal-data" />
          <div className={classes.menuItemTitle}>Datos Personales</div>
        </div>
        <div className={classes.separator} />
        <div
          className={`${classes.menuItem} ${
            currentView === 1 ? classes.menuItemActive : ''
          } ${classes.menuItemDisabled}`}
          onClick={() => {}} // setCurrentView(1)
        >
          <img src={NOTIFICATION_ICON} alt="notifications" />
          <div className={classes.menuItemTitle}>Notificaciones</div>
        </div>
        <div className={classes.separator} />
        <div
          className={`${classes.menuItem} ${
            currentView === 2 ? classes.menuItemActive : ''
          } ${classes.menuItemDisabled}`}
          onClick={() => {}} // setCurrentView(2)
        >
          <img src={SETTINGS_ICON} alt="settings" />

          <div className={classes.menuItemTitle}>Ajustes De Cuenta</div>
        </div>
        <div className={classes.separator} />
        <div
          className={`${classes.menuItem} ${
            currentView === 3 ? classes.menuItemActive : ''
          } ${classes.menuItemDisabled}`}
          onClick={() => {}} // setCurrentView(3)
        >
          <img src={HELP_ICON} alt="settings" />

          <div className={classes.menuItemTitle}>Centro de Ayuda</div>
        </div>
        <div className={classes.separator} />
        <div className={`${classes.menuItem} `} onClick={() => logout()}>
          <img src={LOGOUT_ICON} alt="logout" />
          <div className={classes.menuItemTitle}>Salir</div>
        </div>
      </div>
    </div>
  );
};

export default MyProfileSideMenu;
