import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    [theme.breakpoints.down(1240)]: {
      flexDirection: "column",
      alignItems: "center",
      flexWrap: "nowrap",
    },
  },
  empty: {
    minWidth: "42rem",
    height: "10rem",
    fontFamily: "Montserrat",
    fontSize: "1rem",
    fontWeight: 500,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down(1240)]: {
      minWidth: "0rem",
    },
  },
}));
