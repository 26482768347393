import React from "react";
import { ButtonPrimary } from "@components/Reusables";
import { makeStyles } from "@material-ui/core";

interface Props {
  className?: string;
  onContinueClick: () => void;
  disabledButton?: boolean;
}

export const SimpleFooter: React.FC<Props> = ({
  className = "",
  onContinueClick,
  disabledButton,
}) => {
  const classes = useStyles();
  return (
    <>
      <div className={`${classes.toolbarStyle} ${className}`}>
        <ButtonPrimary
          className={classes.button}
          text="Continuar"
          onClick={onContinueClick}
          disabled={disabledButton}
        />
      </div>
    </>
  );
};

const useStyles = makeStyles(
  theme => ({
    toolbarStyle: {
      position: "absolute",
      bottom: 0,
      height: 85,
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      backgroundColor: theme.palette.primary.main,
    },
    button: {
      marginLeft: 20,
      height: 35,
      padding: "0 10px",
      width: 180,
      backgroundColor: "white",
      color: "#0c5769",
      right: 126,
      "&:hover": {
        backgroundColor: "rgb(200, 200, 200)",
      },
      "&.Mui-disabled": {
        backgroundColor: "transparent",
        color: "rgba(0, 0, 0, 0.3)",
      },

      [theme.breakpoints.down("sm")]: {
        right: 16,
      },
    },
  }),
  { index: 1 },
);
