import { ApolloClient, gql } from '@apollo/client';

const UPDATE_USER_SUBSCRIPTION = gql`
  mutation Mutation($params: UpdateUserSubscriptionParams) {
    updateUserSubscription(params: $params)
  }
`;

type UpdateUserSubscriptionParams = {
  id?: number;
};

export async function updateUserSubscription(
  client: ApolloClient<any>,
  params: UpdateUserSubscriptionParams
): Promise<boolean> {
  console.log('params', params);
  const { data } = await client.mutate({
    mutation: UPDATE_USER_SUBSCRIPTION,
    variables: { params: params },
  });

  return data;
}
