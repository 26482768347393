import React from "react";
import {
  FormControlLabel,
  makeStyles,
  styled,
  Switch,
  SwitchProps,
} from "@material-ui/core";
import { ButtonPrimary } from "..";
const GraphSavings: React.FC = () => {
  const classes = useStyles();

  return (
    <section className={classes.container}>
      <div className={classes.graphContainer}>
        <div className={classes.containerLeft}>
          <div className={classes.cardProfits}>
            <span>Ver ganancias si realizas todas las tareas</span>
            <FormControlLabel
              control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
              label=""
            />
          </div>
        </div>
        <div className={classes.right}>
          <span>Tus ahorros de este período</span>
          <ButtonPrimary text="Revisar mis inversiones" onClick={() => {}} />
        </div>
      </div>
      <div className={classes.revisionContainer}></div>
    </section>
  );
};

export default GraphSavings;

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: "4rem",
  },
  cardProfits: {
    marginLeft: 90,
    padding: "13px 24px",
    width: 263,
    background: "white",
    display: "flex",
    gap: "1rem",
    borderRadius: 14,
    position: "absolute",
    top: -20,
    "& >span": {
      color: "#7d7c7c",
      display: "block",
      width: 145,
    },
  },
  right: {
    "& >span": {
      display: "block",
      fontFamily: theme.typography.fontFamily,
      fontSize: "1.25rem",
      marginBottom: 38,
      color: theme.palette.primary.dark,
    },
    "& button": {
      width: 260,
      fontSize: "1.25rem",
      background:
        "linear-gradient(161deg, #8250ff -6%, #4619b6 117%)!important",
    },
  },
  containerLeft: {
    width: 685,
    height: 428,
    border: `solid 1.6px ${theme.palette.primary.dark}`,
    boxShadow: "4px 4px 12px 0 rgba(0, 0, 0, 0.75)",
    borderRadius: 20,
  },
  graphContainer: {
    display: "flex",
    gap: "2rem",
    justifyContent: "center",
    position: "relative",
  },
  revisionContainer: {},
}));

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.primary.dark === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.primary.dark === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor:
      theme.palette.primary.dark === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
