import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "center",
    "& >img": {
      margin: "0 auto",
      width: "14rem",
    },
  },
  mainText: {
    width: "auto",
    height: "13.75rem",
    margin: "1.25rem -0.5625rem ",
    borderRadius: "1.875rem",
    fontFamily: "Montserrat",
    boxShadow: "4px 4px 20px 2px rgba(0,0,0,0.2)",
    backgroundColor: "#ffffff",
    padding: "2.938rem 1.938rem 2.125rem 1.438rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "space-around",
    "& .title": {
      fontSize: "1rem",
      fontWeight: "bold",
      color: theme.palette.text.secondary,
    },
    "& .text": {
      fontSize: "0.875rem",
      fontWeight: 600,
      lineHeight: 1.79,
      color: theme.palette.text.secondary,
    },
  },
  dots: {
    bottom: 0,
    "& li.slick-active button::before": {
      color: "#8352FD",
    },
    "& li": {
      "& button::before": {
        fontSize: theme.typography.pxToRem(14),
        color: "#9D9D9D",
        opacity: 0.5,
      },
    },
  },
  sliderContainer: {
    width: "auto",
  },
  slider: {
    width: "auto",
    margin: "0 -1.5625rem",
  },
  slideColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    margin: "1rem 1rem 2rem",
  },
  whiteBox: {
    margin: "1rem 0 0 0",
    padding: "2.75rem 3.25rem 4.25rem 2.813rem",
    backgroundColor: "#ffffff",
    borderRadius: "1.875rem",
    textAlign: "center",

    "& .topS": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
      marginBottom: "2rem",
      "& >img": {
        width: "1.5rem",
        height: "1.5rem",
      },
      "& :nth-child(2)": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },

      "& .id": {
        width: "2.813rem",
        height: " 2.813rem",
        margin: "0rem 0 2rem",
        padding: " 0.625rem 0.813rem 0.688rem 1.125rem",
        boxShadow: "4px 4px 8px 2px rgba(0,0,0,0.2)",
        backgroundColor: "#4619b6",
        borderRadius: "50%",
        color: "#ffffff",
        fontFamily: "Montserrat",
        fontSize: "1.25rem",
        fontWeight: "bold",
        textAlign: "left",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirction: "column",
      },
      "& .title": {
        fontFamily: "Montserrat",
        fontSize: "1rem",
        fontWeight: 600,
        color: "#8352fd",
        textAlign: "center",
      },
    },
    "& .text": {
      fontFamily: "Montserrat",
      fontSize: "1rem",
      fontWeight: "normal",
      textAlign: "center",
      lineHeight: "1.56",
      color: theme.palette.text.primary,
    },
  },
  smallWhiteBox: {
    width: "auto",
    height: "6.25rem",
    margin: "1.25rem 0 0 0",
    borderRadius: "1.875rem",
    boxShadow: "4px 4px 20px 2px rgba(0,0,0,0.2)",
    backgroundColor: "#ffffff",
    display: "flex",
    padddingRight: "3rem",
    flexDirection: "row",
    alignItems: "center",
    "& img": {
      width: "2.413rem",
      height: "2.413rem",
      margin: "0 1.05rem 0 1.675rem",
    },

    "& .text2": {
      fontSize: "0.875rem",
      fontWeight: "bold",
      lineHeight: 1.71,
      textAlign: "left",
      marginRight: "1rem",
      color: theme.palette.text.secondary,
    },
  },
}));
