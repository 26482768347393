import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme, { state: boolean }>((theme) => ({
  footer: {},
  containerSafe: {
    paddingTop: "6rem",
    [theme.breakpoints.down(1200)]: {
      paddingTop: "2rem",
    },
  },
  container: {
    background:
      "linear-gradient(151deg, rgb(10, 86, 105) 6%, rgb(0, 169, 194) 203%)",
    minHeight: "100vh",
  },

  children: {},
  title: {
    opacity: ({ state }) => (state ? 0.5 : ""),
    [theme.breakpoints.down(1200)]: {
      textAlign: "center",
      fontSize: "0.875rem",
      minWidth: "13rem",
    },
  },
  RoutePath: {
    [theme.breakpoints.down(1200)]: {
      fontSize: "0.875rem",
    },
    "& .noOpacity": {
      opacity: 0.5,
    },
    "& .spaceCredit": {
      paddingLeft: "2rem",
    },
  },
}));
export default useStyles;
