import { getIdentityFileSignedUrl } from "../apollo";
import { ApolloClient } from "@apollo/client";
import { FileSpecificType } from "../interfaces";
import AWS from "aws-sdk";
import { useState } from "react";

export const filesUpload = async (
  file: File | undefined,
  fileType: FileSpecificType,
  client: ApolloClient<object>,
  id: number | undefined,
  idFile: number | undefined
): Promise<string | undefined> => {
  const defaultUrl =
    "https://altercap-refinancing-staging.s3.us-east-2.amazonaws.com";
  let fullUrl;
  try {
    if (file) {
      const data = getIdentityFileSignedUrl(
        FileSpecificType.IDENTITY_BACK,
        file.name,
        client
      );
      if (!data) return undefined;

      AWS.config.update({
        accessKeyId: process.env.GATSBY_AWS_ACCESS_KEY,
        secretAccessKey: process.env.GATSBY_AWS_SECRET_ACCESS_KEY,
      });

      const myBucket = new AWS.S3({
        params: {
          Bucket: "altercap-refinancing-staging",
        },
        region: "us-east-2",
      });

      const params = {
        Body: file,
        Bucket: "altercap-refinancing-staging",
        Key:
          id + "_" + `${fileType}${idFile}` + "." + file.name.split(".").pop(),
      };
      myBucket
        .putObject(params)
        .on("httpUploadProgress", (evt) => {})
        .send((err) => {
          if (err) console.log(err);
        });
    }
    fullUrl = `${defaultUrl}/${id}_${fileType}${
      idFile ? `${idFile}.` : "."
    }${file?.name.split(".").pop()}`;
    return fullUrl;
  } catch (e) {
    return undefined;
  }
};
