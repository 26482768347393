import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {},
  topSection: {},
  textsContainer: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down(1240)]: {
      display: "flex",
      flexDirection: "column",
      margin: "0.5rem 0rem 0rem",
    },
    "& .text": {
      marginRight: "6rem",
      fontFamily: "Montserrat",
      fontSize: "1rem",
      lineHeight: 1.88,
      textAlign: "left",
      color: theme.palette.text.secondary,
      [theme.breakpoints.down(1240)]: {
        margin: "2rem 0rem 0rem",
        fontSize: "0.75rem",
        lineHeight: 1.67,
      },
    },
  },
  midSection: {
    display: "flex",
    flexDirection: "column",
    marginRight: "6rem",
    [theme.breakpoints.down(1240)]: {
      margin: "1.5rem 0rem 0rem",
    },
  },
  whiteBox: {
    width: "100%",
    margin: "2.625rem 0rem 0rem",
    padding: "1.2rem 1.2rem 2rem",
    borderRadius: "1.875rem",
    boxShadow: "0.25rem 0.25rem 1.25rem 0.125rem rgba(0, 0, 0, 0.2)",
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down(1240)]: {
      flexWrap: "wrap",
      alignItems: "center",
      padding: "0.938rem 2rem 1.875rem",
      margin: "0rem 0rem 2rem",
    },
    "& >img": {
      width: "2.438rem",
      height: "2.438rem",
    },
    "& .tipTitle": {
      fontFamily: "Montserrat",
      fontSize: "0.875rem",
      fontWeight: "bold",
      lineHeight: 1.71,
      color: theme.palette.text.secondary,
      [theme.breakpoints.down(1240)]: {
        fontFamily: theme.typography.fontFamily,
        lineHeight: 1.43,
        marginRight: "10rem",
      },
    },
    "& .text": {
      fontSize: "0.875rem",
      lineHeight: 1.71,
      textAlign: "left",
      color: theme.palette.text.secondary,
      [theme.breakpoints.down(1240)]: {
        fontFamily: theme.typography.fontFamily,
        lineHeight: 1.43,
        fontSize: "0.75rem",
        marginTop: "0.8rem",
      },
    },
  },
  bottomSection: {
    margin: "6.375rem 0 6rem 0",
    textAlign: "left",
    [theme.breakpoints.down(1240)]: {
      textAlign: "center",
      margin: "3.375rem 0 0 0",
    },
  },
}));
