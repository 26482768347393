import React from "react";
import {
  IconButton,
  makeStyles,
  Theme,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import { ButtonPrimary, Footer, HeaderSaveByRokin } from "@components";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import { theme } from "@styles";

interface LayoutProps {
  children: React.ReactNode;
  className?: string;
  simpleHeader?: boolean;
  title?: string;
  widthContainerTitle?: string;
  widthContainerButton?: string;
  textButton?: string;
  onClick: () => void;
  personalData?: boolean;
  noRow?: boolean;
  noButton?: boolean;
  hiddenHeader?: boolean;
  disabled?: boolean;
  onPageBackClick?: () => void;
}
export const RegisterStepLayout: React.FC<LayoutProps> = ({
  className,
  children,
  simpleHeader,
  title,
  textButton,
  personalData,
  onClick,
  widthContainerTitle,
  widthContainerButton,
  noRow,
  noButton,
  hiddenHeader,
  disabled,
  onPageBackClick,
}) => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <div className={`${className || ""}`}>
        {!hiddenHeader && <HeaderSaveByRokin simpleHeader={simpleHeader} />}
        {personalData ? (
          <div className={`${classes.title} ${widthContainerTitle}`}>
            {!noRow && (
              <IconButton
                className={classes.backButton}
                onClick={onPageBackClick}
              >
                <ArrowBackRoundedIcon />
              </IconButton>
            )}

            <span>{title}</span>
          </div>
        ) : (
          <div className={`${classes.title} ${widthContainerTitle}`}>
            {title}
          </div>
        )}

        {children}
        <div
          className={`${classes.buttonContinue} ${
            widthContainerButton || widthContainerTitle
          }`}
        >
          {!noButton && (
            <ButtonPrimary
              className={classes.buttonContinueButton}
              text={textButton || "Continuar"}
              onClick={onClick}
              disabled={disabled}
            />
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  title: {
    padding: "40px 0 5px",
    margin: "1em 0",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    textAlign: "center",
    fontSize: 18,
    display: "flex",
    alignItems: "center",
  },
  buttonContinue: {
    position: "relative",
    textAlign: "right",
    justifyContent: "flex-end",
  },
  buttonContinueButton: {
    minWidth: 200,
    marginTop: 35,
    [theme.breakpoints.down("xs")]: {
      marginTop: 50,
    },
  },
  backButton: {
    marginRight: 12,
    [theme.breakpoints.down(700)]: {
      marginRight: 0,
    },
    [theme.breakpoints.down(600)]: {
      marginRight: 12,
      width: 36,
      height: 36,
      "& .MuiSvgIcon-root": {
        width: 20,
        height: 20,
      },
    },
  },
}));
