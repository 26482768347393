import { gql, useQuery } from "@apollo/client";
import { PortfolioCombination } from "@interfaces";

export const GET_PORTFOLIO_COMBINATIONS_QUERY = gql`
  query GetPortfolioCombinations {
    getPortfolioCombinations {
      investmentAsset
      investmentLocation
    }
  }
`;

interface QueryResult {
  getPortfolioCombinations: PortfolioCombination[];
}

interface HookReturn {
  data: PortfolioCombination[] | null;
  loading: boolean;
}

export const usePortfolioCombination = (): HookReturn => {
  const { data, loading } = useQuery<QueryResult>(
    GET_PORTFOLIO_COMBINATIONS_QUERY,
  );
  return {
    data: data?.getPortfolioCombinations ?? null,
    loading,
  };
};
