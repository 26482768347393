import { ApolloClient, gql } from "@apollo/client";
import {
  InvestorProfileType,
  PortfolioAssetCategory,
  PortfolioLocationCategory,
} from "@interfaces";
import { GET_PORTFOLIOS_NAMES_QUERY, GET_PORTFOLIOS_QUERY } from "..";

const INVEST_ON_PORTFOLIO = gql`
  mutation InvestOnPortfolio($params: InvestOnPortfolioParams!) {
    investOnPortfolio(params: $params)
  }
`;

type InvestOnPortfolioParams = {
  params: {
    amount: number;
    investmentFrequency?: string;
    period?: number;
    investorProfile?: InvestorProfileType | null;
    investmentAsset?: PortfolioAssetCategory | null;
    investmentLocation?: PortfolioLocationCategory | null;
    goalId?: number | null;
  };
};

export async function investOnPortfolioMutation(
  params: InvestOnPortfolioParams["params"],
  client: ApolloClient<object>,
): Promise<boolean> {
  const result = await client.mutate<boolean, InvestOnPortfolioParams>({
    mutation: INVEST_ON_PORTFOLIO,
    variables: { params },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_PORTFOLIOS_NAMES_QUERY,
      },
      {
        query: GET_PORTFOLIOS_QUERY,
      },
    ],
  });
  return !!result.data;
}
