import React from "react";
import { Slider, makeStyles } from "@material-ui/core";
import { Filter, FilterProps } from "..";
import { ActiveFilters, SingleFilterOptions } from "@interfaces";
import { parseNumber } from "@utils";

export interface AmountFilterProps extends FilterProps {
  className?: string;
  filters: ActiveFilters;
  property: "currency" | "term" | "manager" | "fundType" | "amount";
  options: SingleFilterOptions[];
  handleChange: (
    event: React.ChangeEvent<any>,
    newValue: number | number[],
  ) => void;
}
const AmountFilter: React.FC<AmountFilterProps> = ({
  className,
  filters,
  property,
  options,
  handleChange,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Filter
      className={`${className || ""} ${classes.sliderFilter}`}
      parsedSelections={getParsedSelections(filters.amount)}
      {...props}
    >
      <div className={classes.amountSlider}>
        <Slider
          value={filters.amount}
          onChange={handleChange}
          valueLabelDisplay="off"
          aria-labelledby="range-slider"
          min={0}
          step={1}
          max={9}
        />
      </div>
      <div className={classes.values}>
        <span>${parseNumber(getMarkValue(filters.amount?.[0]))}</span>
        <span>${parseNumber(getMarkValue(filters.amount?.[1]))}</span>
      </div>
    </Filter>
  );
};

const analizeAmountSelections = (value: number[]) => {
  const firstUnselected = (value[0] === followersMarks[0].value) as boolean;
  const lastUnselected = (value[1] ===
    followersMarks[followersMarks.length - 1].value) as boolean;
  const lowerMark = followersMarks.find(
    mark => mark.value === value[0],
  ) as AmountMark;
  const upperMark = followersMarks.find(
    mark => mark.value === value[1],
  ) as AmountMark;
  return { firstUnselected, lastUnselected, lowerMark, upperMark };
};

const getParsedSelections = (value: number[] | undefined) => {
  if (!value) return "";
  const { firstUnselected, lastUnselected, lowerMark, upperMark } =
    analizeAmountSelections(value);
  if (firstUnselected && lastUnselected) return "";
  if (firstUnselected) return "hasta " + upperMark?.label;
  if (lastUnselected) return "desde " + lowerMark?.label;
  return `${lowerMark?.label} a ${upperMark?.label}`;
};

const getMarkValue = (value?: number) =>
  followersMarks.find(mark => mark.value === value)?.scaledValue || 0;

interface AmountMark {
  value: number;
  scaledValue: number;
  label: string;
}
const followersMarks: AmountMark[] = [
  {
    value: 0,
    scaledValue: 1000,
    label: "1k",
  },
  {
    value: 1,
    scaledValue: 10000,
    label: "10k",
  },
  {
    value: 2,
    scaledValue: 100000,
    label: "100k",
  },
  {
    value: 3,
    scaledValue: 250000,
    label: "250k",
  },
  {
    value: 4,
    scaledValue: 500000,
    label: "500k",
  },
  {
    value: 5,
    scaledValue: 1000000,
    label: "1M",
  },
  {
    value: 6,
    scaledValue: 5000000,
    label: "5M",
  },
  {
    value: 7,
    scaledValue: 10000000,
    label: "10M",
  },
  {
    value: 8,
    scaledValue: 20000000,
    label: "20M",
  },
  {
    value: 9,
    scaledValue: 30000000,
    label: "+30M",
  },
];

const useStyles = makeStyles(() => ({
  sliderFilter: {
    width: "100%",
  },
  amountSlider: {
    padding: "5px 30px",
  },
  values: {
    padding: "5px 30px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 13,
  },
}));

export { AmountFilter, followersMarks, analizeAmountSelections };
