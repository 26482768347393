import React, { Dispatch, SetStateAction } from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { numberMission, home } from "images/onBoarding";
import { navigate } from "gatsby";
import { Route } from "@interfaces";
import { Confetti } from "@components";

interface LayoutMissionProps {
  children: React.ReactNode;
  className?: string;
  styleFooter?: string;
  title: string;
  subtitle?: string;
  number: number;
  secondLayoutForShortcuts?: boolean;
  redirectLink?: string;
  setPage?: Dispatch<SetStateAction<number>>;
  pageNumber?: number;
  confettiFire: boolean;
  titleAdjust?: boolean;
  onHouseClick?: () => void;
}
export const LayoutMissionMobile: React.FC<LayoutMissionProps> = ({
  className,
  children,
  title,
  number,
  secondLayoutForShortcuts,
  redirectLink,
  subtitle,
  pageNumber,
  setPage,
  confettiFire,
  titleAdjust,
  onHouseClick,
}) => {
  const classes = useStyles();
  return (
    <div className={`${className || ""} ${classes.container}`}>
      <Confetti confettiFire={confettiFire}/>
      <Header
        title={title}
        secondLayoutForShortcuts={secondLayoutForShortcuts}
        redirectLink={redirectLink}
        setPage={setPage}
        pageNumber={pageNumber}
        titleAdjust={titleAdjust}
        onHouseClick={onHouseClick}
      />
      <div className={classes.subtitle}>{subtitle}</div>
      <div className={classes.children}>{children}</div>
      <Footer number={number} />
    </div>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    overflowX: "hidden",
    background: "#F4F3F3",
    "& footer": {
      marginTop: "2rem",
    },
  },
  children: {
    padding: "0 1.5625rem 0 1.5625rem",
  },
  subtitle: {
    margin: "15px 10px 0 10px",
    fontFamily: "Montserrat",
    fontWeight: "normal",
    fontSize: "1.15rem",
    color:theme.palette.text.secondary,
    textAlign: "center",
  },
}));
