import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  cardContiner: {},
  whiteBox: {
    width: "22rem",
    backgroundColor: "#ffffff",
    borderRadius: "0.65rem",
    boxShadow: "0.25rem 0.25rem 0.5rem 0.125rem rgba(0, 0, 0, 0.2)",
    padding: "1rem",
    margin: "1rem 1.5rem",
    [theme.breakpoints.down(1240)]: {
      margin: "1rem auto",
    },
  },
  CardTopSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    "& .title": {
      fontSize: "1rem",
      fontWeight: "bold",
      textAlign: "left",
      color: theme.palette.text.primary,
      lineHeight: 1.5,
      fontFamily: "Montserrat",
    },
  },
  cardMiddleSection: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginTop: "0.5rem",
  },
  reminderSection: {
    display: "flex",
    flexDirection: "column",
  },
  element: {
    width: "10rem",
    display: "flex",
    flexDirection: "column",
    fontSize: "0.75rem",
    margin: "0.5rem 0",
    justifyContent: "space-evenly",
    fontWeight: 500,
    textAlign: "left",
    fontFamily: "Montserrat",
    "& .label": {
      color: theme.palette.text.secondary,
      textTransform: "uppercase",
    },
    "& .value": {
      color: theme.palette.text.primary,
    },
  },
  cardBottomSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
}));
