import {
  ChangeEvent,
  DEFAULT_REGISTER_SAVE_BY_ROKIN,
  OnChangeType,
  SaveByRokinRegisterForm,
} from "@interfaces";
import React, {
  createContext,
  useState,
  useMemo,
  useCallback,
  useContext,
} from "react";
import { useApolloClient } from "@apollo/client";
import { saveEmailSaveByRokin } from "apollo/save_by_rokin";
import { LoadingContext } from "./LoadingContext";
import { SnackbarContext } from "@context";

interface SaveByRokinContextType {
  form: SaveByRokinRegisterForm;
  setForm: React.Dispatch<React.SetStateAction<SaveByRokinRegisterForm>>;
  handleChange: OnChangeType;
  finishRegister: () => void;
  isAlreadyCreated?: boolean;
}

export const SaveByRokinContext = createContext({} as SaveByRokinContextType);

export const SaveByRokinProvider: React.FC = ({ children }) => {
  const [form, setForm] = useState<SaveByRokinRegisterForm>(
    DEFAULT_REGISTER_SAVE_BY_ROKIN,
  );
  const { showSnackbar } = useContext(SnackbarContext);
  const client = useApolloClient();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const [isAlreadyCreated, setAlreadyCreated] = useState(false);

  const handleChange = useCallback(
    (event: ChangeEvent) => {
      const data = event.target.name as keyof SaveByRokinRegisterForm;
      const value = String(event.target.value);
      setForm(prev => ({ ...prev, [data]: value }));
      setAlreadyCreated(false);
    },
    [form, isAlreadyCreated],
  );
  const finishRegister = useCallback(async () => {
    try {
      const newUser = await saveEmailSaveByRokin(form.emailUser, client);
      if (!newUser) {
        showSnackbar("Ha ocurrido un error", "error");
        setAlreadyCreated(false);
        return null;
      }
      setAlreadyCreated(true);
    } catch (error) {
      console.error(error);
      setAlreadyCreated(false);
    }
  }, [form, showLoader, hideLoader, client]);

  const context: SaveByRokinContextType = useMemo(
    () => ({
      form,
      setForm,
      handleChange,
      finishRegister,
      isAlreadyCreated,
    }),
    [form, setForm, handleChange, finishRegister, isAlreadyCreated],
  );
  return (
    <SaveByRokinContext.Provider value={context}>
      {children}
    </SaveByRokinContext.Provider>
  );
};
