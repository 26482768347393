import React from "react";
import { GoogleIcon } from "@images";
import { SizeType } from "@styles";
import { ButtonOutlined } from ".";

type Props = {
  className?: string;
  text?: string;
  onClick?: () => void;
  disabled?: boolean;
  size?: SizeType; // 30, 36, 44, 48, 56
};

export const ButtonGoogle: React.FC<Props> = ({
  className,
  text = "Ingresar con Google",
  onClick,
  disabled,
  size,
}) => {
  return (
    <ButtonOutlined
      className={className}
      text={text}
      startIcon={<GoogleIcon />}
      onClick={onClick}
      disabled={disabled}
      size={size}
    />
  );
};
