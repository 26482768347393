import React, { Dispatch, SetStateAction } from "react";
import { useStyles } from "./leftColumn.styles";
import {
  RECURRENT_EXPENSES_ICON,
  ARROW_BACK_ICON,
  RECURRENT_INCOME_ICON,
  REMINDER_ICON,
  ADD_EVENT_ICON,
} from "@images";
import { Icon, IconButton } from "@material-ui/core";
type Props = {
  type: string;
  title: string;
  setCurrentView: Dispatch<SetStateAction<string>>;
};

const LeftColumnCalendarInfo = ({ type, title, setCurrentView }: Props) => {
  const classes = useStyles();
  // set the image dependig if type is expense, income or reminder
  const setImage = () => {
    switch (type) {
      case "expense":
        return RECURRENT_EXPENSES_ICON;
      case "income":
        return RECURRENT_INCOME_ICON;
      case "reminder":
        return REMINDER_ICON;
      default:
        return RECURRENT_EXPENSES_ICON;
    }
  };

  const setText = () => {
    switch (type) {
      case "expense":
        return "Agregar otro gasto recurrente";
      case "income":
        return "Agregar otro ingreso recurrente";
      case "reminder":
        return "Agregar otro recordatorio";
      default:
        return "Agregar otro gasto recurrente";
    }
  };
  const setStyle = () => {
    switch (type) {
      case "expense":
        return classes.expense;
      case "income":
        return classes.income;
      case "reminder":
        return classes.reminder;
      default:
        return classes.expense;
    }
  };

  const setRedirect = () => {
    switch (type) {
      case "expense":
        return "createPayment";
      case "income":
        return "createIncome";
      case "reminder":
        return "createReminder";
      default:
        return "createExpense";
    }
  };
  return (
    <div className={classes.container}>
      <div className={`${classes.topSection} ${setStyle()}`}>
        <div
          className="backArrow"
          onClick={() => {
            [setCurrentView("calendar")];
          }}
        >
          <img src={ARROW_BACK_ICON} alt="arrow_back" />
        </div>
        <div className="mainIcon">
          <img src={setImage()} alt="" />
        </div>
      </div>
      <div className={classes.bottomSection}>
        <div className="title">{title}</div>
        <div className="addElement">
          <div className="icon">
            <IconButton
              onClick={() => {
                setCurrentView(setRedirect());
              }}
            >
              <Icon>
                <img src={ADD_EVENT_ICON} alt="" />
              </Icon>
            </IconButton>
          </div>
          <div className="text">{setText()}</div>
        </div>
      </div>
    </div>
  );
};

export default LeftColumnCalendarInfo;
