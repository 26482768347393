import React, { useCallback } from "react";
import { CommonInputProps } from "@components";
import { makeStyles, Theme } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { ChangeEvent } from "@interfaces";
import moment from "moment-timezone";
import { useResponsiveInputSize } from "@hooks";
import {
  COMPONENT_SIZES,
  getPaddingValue,
  getTransformYValue,
  SizeType,
} from "@styles";

type Props = Pick<
  CommonInputProps,
  "className" | "name" | "label" | "onChange" | "size"
> & {
  value?: Date | null;
  noBirthday?: boolean;
  disabled?: boolean;
};

export const BirthdateInput: React.FC<Props> = ({
  className,
  name,
  label,
  value,
  onChange,
  size: _size,
  noBirthday,
  disabled,
}) => {
  const size = useResponsiveInputSize({ size: _size });
  const classes = useStyles({ size });

  const handleDateChange = useCallback(
    (date: Date | null) => {
      onChange({
        target: { value: date, name: name || "birthdate" },
      } as ChangeEvent);
    },
    [name, onChange]
  );

  // Scroll year list to display near 1990 at start
  const onOpen = useCallback(() => {
    setTimeout(() => {
      const list = document.querySelector(".MuiPickersYearSelection-container");
      if (list) list.scrollTop = list.scrollHeight - 40 * 12;
    }, 100);
  }, []);

  // const minDate = moment().tz("America/Santiago").subtract(100, "years");
  const maxDate = moment().tz("America/Santiago").subtract(18, "years");

  return (
    <DatePicker
      className={`${classes.inputDate} ${className}`}
      name={name || "birthdate"}
      label={label ?? "Fecha de nacimiento"}
      value={value}
      onChange={handleDateChange}
      inputVariant="outlined"
      format="dd/MM/yyyy"
      openTo="year"
      disableFuture={!noBirthday}
      maxDate={noBirthday ? undefined : maxDate}
      cancelLabel="Cancelar"
      maxDateMessage={!noBirthday || "Debes tener al menos 18 años"}
      onOpen={onOpen}
      disabled={disabled ?? false}
    />
  );
};

type StyleProps = { size: SizeType };
const useStyles = makeStyles<Theme, StyleProps>({
  inputDate: {
    height: ({ size }) => COMPONENT_SIZES[size],
    width: 300,
    "& > .MuiInputBase-root > input": {
      paddingTop: ({ size }) => getPaddingValue(size),
      paddingBottom: ({ size }) => getPaddingValue(size),
    },
    "& > .MuiInputLabel-root": {
      "& .MuInputLabel-shrink": {
        transform: "translate(14px, -6px) scale(0.75)",
      },
      "&:not(.MuiInputLabel-shrink)": {
        transform: ({ size }) =>
          `translate(14px, ${getTransformYValue(size)}px) scale(1)`,
      },
    },
  },
});
