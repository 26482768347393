import { useCallback, useContext, useEffect, useState } from "react";
import { ProfileContext } from "@context";
import {
  DEFAULT_FORM_ERRORS,
  FORM_FIELDS,
  ProfileForm,
  ProfileFormErrors,
  OnChangeType,
} from "@interfaces";
import { checkRequiredFields, checkValidForm } from "@utils";

interface useFormProps {
  formFields?: typeof FORM_FIELDS[number][];
}
type FormReturn = [
  ProfileForm,
  ProfileFormErrors,
  boolean,
  OnChangeType,
  () => boolean,
];
export const useForm = ({ formFields }: useFormProps = {}): FormReturn => {
  const { profileForm, updateProfileForm } = useContext(ProfileContext);
  const [formErrors, setFormErrors] =
    useState<ProfileFormErrors>(DEFAULT_FORM_ERRORS);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const handleChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: any, checked?: any) => {
      const name = e?.target?.name as keyof ProfileForm | undefined;
      const value = e?.target?.value as string | number | undefined;
      if (!name) return;
      // Disable any error
      setFormErrors((prev: ProfileFormErrors) => ({
        ...prev,
        [name]: false,
      }));
      // Check if there is a checkbox
      if (typeof checked === "boolean") {
        updateProfileForm({ [name]: checked });
      } else {
        updateProfileForm({ [name]: value });
      }
    },
    [updateProfileForm, setFormErrors],
  );

  const validate = useCallback(() => {
    const { errors, hasError } = checkValidForm(profileForm, formFields);
    setFormErrors(errors);
    return !hasError;
  }, [profileForm, formFields, setFormErrors]);

  useEffect(() => {
    setDisableSubmit(
      !checkRequiredFields(profileForm, formFields) ||
        Object.values(formErrors).some(Boolean),
    );
  }, [profileForm, formFields, formErrors, setDisableSubmit]);

  return [profileForm, formErrors, disableSubmit, handleChange, validate];
};
