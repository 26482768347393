// Position of options does matter for disabling some other inputs
export const JOB_TYPE_OPTIONS = [
  "Dependiente",
  "Independiente",
  "Estudiante",
  "Sin trabajo",
  "Retirado/a",
  "Otro",
].map(g => ({
  name: g,
  value: g,
}));
