import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    "& .flexContainer": {
      display: "flex",
      flexDirection: "row",
      [theme.breakpoints.down(1239)]: {
        flexDirection: "column",
      },
    },
  },
  formContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    minWidth: "26rem",
    maxWidth: "34rem",
    marginRight: "2rem",
    [theme.breakpoints.down(1250)]: {
      marginLeft: "-2rem",
    },
    [theme.breakpoints.down(1239)]: {
      justifyContent: "center",
      margin: "0px auto",
      minWidth: "0rem",
      maxWidth: "100%",
      flexDirection: "column",
      flexWrap: "nowrap",
    },
    "& .mobileFlex": {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      maxWidth: "39rem",
      justifyContent: "center",
    },
    "& .text": {
      fontFamily: "Montserrat",
      lineHeight: 1.9,
      textAlign: "left",
      margin: "1rem 0",
      [theme.breakpoints.down(1239)]: {
        textAlign: "center",
        margin: "1rem 0rem",
      },
    },
    "& .blueText": {
      color: "#0a5669",
      fontWeight: 500,
      fontSize: "1.25rem",
      [theme.breakpoints.down(1239)]: {
        fontSize: "0.875rem",
        lineHeight: 1.71,
      },
    },
    "& .smallText": {
      color: theme.palette.text.secondary,
      fontWeight: "normal",
      fontSize: "1rem",
    },
  },
  formItem: {
    display: "flex",
    flexDirection: "column",
    // marginRight: "1rem",
    width: "13rem",
    [theme.breakpoints.down(1240)]: {
      margin: "1rem 0rem 0rem 0rem",
    },
    "& .name": {
      fontFamily: "Montserrat",
      fontSize: "0.85rem",
      marginTop: "2rem",
      fontWeight: "normal",
      lineHeight: 1.5,
      textAlign: "left",
      textTransform: "uppercase",
      color: theme.palette.text.primary,
      [theme.breakpoints.down(1240)]: {
        fontSize: "0.85rem",
      },
    },
    "& .area": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "& >span": {
        fontSize: "1.5rem",
        fontWeight: 400,
        textAlign: "left",
        marginLeft: "1rem",
        color: theme.palette.text.secondary,
        [theme.breakpoints.down(1240)]: {
          fontSize: "1rem",
        },
      },
    },
    "& .interest": {
      "& div": {
        width: "4rem",
      },
    },
    "& div": {
      borderRadius: "0.5rem",
      height: "3rem",
      width: "12.5rem",
    },
    "& input": {
      // height: "1rem",
      backgroundColor: theme.palette.background.paper,
      fontWeight: "bold",
      borderRadius: "0.5rem",
    },
    "& .MuiOutlinedInput-input": {
      padding: "0.9rem",
    },
    "& .addYear": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      borderRadius: "0.5rem",
      border: "solid 1px #858c94",
      backgroundColor: theme.palette.background.paper,
      "& .button": {
        width: "3rem",
      },
      "& .leftButton": {
        borderRight: "1px solid",
        borderRadius: "0",
        "& img": {
          margin: "auto",
          marginTop: "0.5rem",
        },
      },
      "& .rightButton": {
        borderLeft: "1px solid",
        borderRadius: "0",
      },
      "& .yearInput": {
        width: "3rem",
        "& div": {
          width: "3rem",
        },
        "& .MuiInput-underline::before .MuiInput-underline::after": {
          display: "none",
        },
        "& input": {
          fontFamily: "Montserrat",
          fontSize: "1rem",
          textAlign: "center",
          width: "3rem",
        },
      },
    },
  },
  chartContainer: {
    border: "1px solid #E5E5E5",
    width: "37rem",
    marginRight: "7rem",
    order: 2,
    padding: "1rem",
    [theme.breakpoints.down(1239)]: {
      width: "auto",
      margin: "0px auto",
      maxWidth: "39rem",
      border: "none",
      padding: "0px",
    },
    [theme.breakpoints.down(650)]: {
      margin: "1px",
      maxWidth: "none",
    },
    "& .chart": {
      width: "100%",
      border: "1px solid #red",
    },
  },
  totals: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    padding: "1rem",
    fontFamily: "Montserrat",
    justifyContent: "space-between",
  },
  total: {
    width: "44%",
    margin: "1rem",
    borderRadius: "0.9375rem",
    display: "flex",
    height: "4rem",
    alignItems: "center",
    justifyContent: "space-around",
    fontWeight: "bold",
    [theme.breakpoints.down(1239)]: {
      margin: "1rem 0px",
    },
  },
  green: {
    boxShadow: "0.15rem 0.15rem 0.15rem 0 rgba(0, 0, 0, 0.2)",
    border: "solid 0.0875rem #008296",
    margin: "1rem 0rem",
    padding: "0.5rem",
    width: "49%",
    fontSize: "1rem",
    color: theme.palette.text.primary,
    [theme.breakpoints.down(1239)]: {
      flexDirection: "column",
      minWidth: "48%",
    },
  },
  purple: {
    boxShadow: "0.15rem 0.15rem 0.15rem 0 rgba(0, 0, 0, 0.2)",
    border: "solid 0.0875rem #8352fd",
    margin: "1rem 0rem",
    padding: "0.2rem",
    fontSize: "1rem",
    width: "49%",
    color: theme.palette.text.primary,
    [theme.breakpoints.down(1239)]: {
      flexDirection: "column",
      minWidth: "48%",
    },
  },
  main: {
    width: "100%",
    backgroundColor: "#4619b6",
    color: "#fff",
    fontSize: "1.25rem",
    margin: "1rem 0",
    [theme.breakpoints.down(1239)]: {
      fontSize: "1rem",
      padding: "1rem 0.5rem",
    },
  },
  buttonsSection: {
    height: "10rem",
    width: "16rem",
    marginTop: "3rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down(1239)]: {
      margin: "2rem auto",
      height: "auto",
      width: "12rem",
    },
  },
  leftSide: {
    marginLeft: "1rem",
    [theme.breakpoints.down(500)]: {
      marginLeft: "0rem",
    },
  },
  mobileButton: {
    margin: "2rem auto",
    order: 3,
  },
  helperText: {
    fontSize: "0.5rem",
    paddingTop: "0.5rem",
  },
}));
