import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  background: {
    width: "100%",
    position: "relative",
    height: "50rem",
    marginTop: "-4rem",
    paddingTop: "4rem",
    backgroundImage: "linear-gradient(165deg, #0a5669 3%, #00a9c2 343%)",
  },
  itemsContainer: {
    display: "flex",
    marginTop: "-46rem",
  },
  leftSide: {},
  rightSide: {
    margin: "2.25rem 2rem 4rem 2.25rem",
    width: "100%",
    backgroundColor: "#F4F3F3",
    zIndex: 2,
    borderRadius: "1.875rem",
    padding: "3.25rem",
  },
}));
