import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  makeStyles,
  Theme,
} from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { BankTransferData, ButtonPrimary } from "@components";
import { VectorInfo } from "@data";
import "./VectorInvestDataDialog.scss";

interface Props {
  className?: string;
  open: boolean;
  onClose: () => void;
}
export const VectorInvestDataDialog: React.FC<Props> = ({
  className,
  open,
  onClose,
}) => {
  const isMobile = useMediaQuery("(max-width: 800px)");
  const classes = useStyles();
  return (
    <Dialog
      className={`vector-invest-data-dialog ${className || ""}`}
      open={open}
      onClose={onClose}
      fullScreen={isMobile}
    >
      <IconButton className="close-button" onClick={onClose}>
        <CloseRoundedIcon />
      </IconButton>
      <DialogTitle>
        <div className={classes.title}>Datos para transferencia</div>
      </DialogTitle>
      <DialogContent className="px-24">
        <p>¡Transfiere a esta cuenta para finalizar tu inversión!</p>
        <BankTransferData
          className="vector-invest-data-dialog__bank-data"
          data={VectorInfo}
        />
        <p>
          Detectaremos automáticamente el movimiento bancario y actualizaremos
          tu inversión
        </p>
      </DialogContent>
      <DialogActions>
        <ButtonPrimary text="Listo!" onClick={onClose} />
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  title: {
    color: theme.palette.primary.main,
  },
}));
