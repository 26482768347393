import { ApolloClient, gql } from "@apollo/client";
import { DBInvestment, FormInvestment } from "@interfaces";
import { GET_USER_INVESTMENTS_QUERY } from "..";

export interface CreateInvestmentPayload {
  type: DBInvestment["type"];
  serieId: DBInvestment["serieId"];
  date: FormInvestment["date"];
  goalId?: DBInvestment["goalId"] | null;
  investmentFrequency?: FormInvestment["investFrequency"];
  period?: FormInvestment["period"];
}

const CREATE_INVESTMENTS_MUTATION = gql`
  mutation CreateInvestments($investments: [CreateInvestmentsParams!]) {
    createInvestments(investments: $investments) {
      id
      type
      period
      investmentFrequency
    }
  }
`;

// TO-DO: refactorizar
interface userInvestment {
  id: number;
  type: string;
  period: number;
  investmentFrequency: string;
  createdAt: Date;
  updatedAt: Date;
}

type CreateInvestmentsMutationParams = {
  investments: CreateInvestmentPayload[];
};

type CreateInvestmentsMutationResult = {
  createInvestments: userInvestment | null;
};

export async function createUserInvestments(
  investmentsPayloads: CreateInvestmentPayload[],
  refetchInvestments = true,
  client: ApolloClient<object>,
): Promise<boolean> {
  await client.mutate<
    CreateInvestmentsMutationResult,
    CreateInvestmentsMutationParams
  >({
    mutation: CREATE_INVESTMENTS_MUTATION,
    variables: { investments: investmentsPayloads },
    refetchQueries: refetchInvestments
      ? [
          {
            query: GET_USER_INVESTMENTS_QUERY,
          },
        ]
      : undefined,
  });
  return true;
}
