import React, { useCallback } from "react";
import { CommonInputProps } from "..";
import { SizedTextField } from "@components";
import { ChangeEvent } from "@interfaces";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends CommonInputProps {}

export const BankAccountNumberInput: React.FC<Props> = ({
  className,
  name,
  label,
  onChange,
  ...props
}) => {
  const onChangeWrapper = useCallback(
    (e: ChangeEvent) => {
      const { name, value } = e.target;
      const regex = /[A-Z]/gi;
      onChange({
        target: { name, value: value.replace(regex, "") },
      } as ChangeEvent);
    },
    [onChange],
  );

  return (
    <SizedTextField
      className={`${
        className || ""
      } altercap-accountNumber-input altercap-input`}
      name={name || "accountNumber"}
      label={label || "Número de cuenta"}
      {...props}
      onChange={onChangeWrapper}
    />
  );
};
