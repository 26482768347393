import React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { Footer } from "../Mission/LayoutMissionMobile/Footer";
import { Header } from "./Header";

interface LayoutProps {
  children: React.ReactNode;
  className?: string;
  styleFooter?: string;
  title: string;
  subtitle?: string;
  number: number;
  classNameNumberFooter?: string;
}
export const LayoutDiscover: React.FC<LayoutProps> = ({
  className,
  children,
  title,
  number,
  subtitle,
  classNameNumberFooter,
}) => {
  const classes = useStyles();
  return (
    <div className={`${className || ""} ${classes.container}`}>
      <Header subtitle={subtitle} title={title} />
      <div className={classes.children}>{children}</div>
      <Footer className={classNameNumberFooter} number={number} />
    </div>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    overflowX: "hidden",
    background: "#F4F3F3",
    "& footer": {
      marginTop: "2rem",
    },
  },
  children: {
    paddingTop: "4rem",
  },
}));
