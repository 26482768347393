import React, { useContext, useState, useEffect } from 'react';
import { Dialog } from '@material-ui/core';
import { NewInvestContext } from '@context';
import { ButtonPrimary, NumericInput } from '@components/Reusables';
import { makeStyles, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useMediaQuery } from '@material-ui/core';
import { MovementType, PortfolioLocationCategory } from '@interfaces';
import { Select } from '@components';
import { useInvestmentHelperText, useFundType } from '@hooks';
import {
  useCurrentUser,
  useGoals,
  usePortfolios,
  useUserInvestments,
} from '@apollo';
interface AddFundsModalProps {
  open: boolean;
  onClose: () => void;
  selectedTarget: number;
  disabledFound?: boolean;
  firstFundSelection?: string;
  depositValue?: number;
}

export const AddFundsModal: React.FC<AddFundsModalProps> = ({
  open,
  onClose,
  selectedTarget,
  disabledFound,
  firstFundSelection,
  depositValue,
}) => {
  const classes = useStyles();
  const { form, onChange, onContinueClick, validInvestedAmount } =
    useContext(NewInvestContext);
  const { user } = useCurrentUser();

  const { portfolios, loading: loadingPortfolios } = usePortfolios();
  const { investments, loading: loadingInvestments } = useUserInvestments();
  const isMobile = useMediaQuery('(max-width: 1279px)');
  const [investmentsAndPortfolios, setInvestmentsAndPortfolios] =
    useState(undefined);
  const [invalidAmount, setInvalidAmount] = useState<boolean>(false);

  const { goals } = useGoals();
  //form for new investment
  const [selectDisable, setSelectDisable] = useState<boolean>(false);

  const [goalValue, setGoalValue] = useState<number>(selectedTarget);
  const [fundValue, setFundValue] = useState<string>('null');
  const [targetOptions, setTargetOptions] = useState([
    { value: 'null', name: 'No hay objetivo' },
  ]);
  const [fundsOptions, setFundsOptions] = useState([
    { value: 'null', name: 'No hay Fondos' },
  ]);
  const { startFlow } = useContext(NewInvestContext);

  function getTargetOptions(targetData) {
    const targetArray = [];

    targetData.forEach((element) => {
      targetArray.push({ value: element.id, name: element.name });
    });

    setGoalValue(targetArray[0].value);

    return targetArray;
  }

  function getFundsOptions(data) {
    const fundsArray = [];
    if (data.length == 0) {
      form.suggestedPortfolio = true;
    } else {
      form.suggestedPortfolio = false;
    }
    if (form.suggestedPortfolio) {
      setFundValue('Conservador');
      fundsArray.push({ value: 'Conservador', name: 'Conservador' });
      fundsArray.push({ value: 'Moderado', name: 'Moderado' });
      fundsArray.push({ value: 'Agresivo', name: 'Agresivo' });
      fundsArray.push({ value: 'S&P 500', name: 'S&P 500' });
    } else {
      data.forEach((element) => {
        if (element.__typename == 'Portfolio') {
          fundsArray.push({ value: element.id, name: element.name });
        } else {
          fundsArray.push({ value: element.id, name: element.fund.name });
        }
        form.fund = element?.id;
        setFundValue(element.id);
      });
    }

    return fundsArray;
  }

  useEffect(() => {
    if (goals.length !== 0) {
      setTargetOptions(getTargetOptions(goals));
    }
    if (selectedTarget) {
      setGoalValue(selectedTarget);
      setSelectDisable(true);
    } else {
      setSelectDisable(false);
    }
  }, [open, selectedTarget]);

  useEffect(() => {
    const selectedGoal = goals.find((obj) => obj.id === goalValue);
    if (selectedGoal?.info?.hasInvestment || selectedGoal?.info?.hasPortfolio) {
      startFlow({
        isReinvest: true,
        movementType: MovementType.DEPOSIT,
      });
    } else {
      startFlow({
        suggestedPortfolio: true,
        investmentLocation: PortfolioLocationCategory.CHILE_AND_WORLD,
        movementType: MovementType.DEPOSIT,
        isReinvest: false,
        newInvestorProfile: firstFundSelection,
        suggestedPortfolioAmount: depositValue,
      });
    }

    setInvestmentsAndPortfolios([...portfolios, ...investments]);
    setFundsOptions(
      getFundsOptions(
        [...portfolios, ...investments].filter(
          (obj) => obj.goalId === goalValue
        )
      )
    );
  }, [goalValue]);

  const { fundType } = useFundType(form.fund);

  const { notValidAmount, helperText } = useInvestmentHelperText(
    form.amount,
    form.serieMinimum,
    form.serieShareValue,
    form.suggestedPortfolio,
    fundType
  );

  useEffect(() => {
    if (form.amount == 0) {
      setInvalidAmount(true);
    } else {
      setInvalidAmount(false);
    }
  }, [form.amount]);

  const handleOnClick = () => {
    if (form.suggestedPortfolio) {
      form.goal = goalValue;
      form.investorProfile = fundValue;
      form.investmentAsset = 'SINGULAR';
      onContinueClick();
    } else {
      const investmentArray = investmentsAndPortfolios.filter(
        (obj) => obj.id === fundValue
      )[0];
      //If suggestedPortfolio is newInvestment in
      if (investmentArray.__typename == 'Portfolio') {
        form.goal = goalValue;
        form.portfolioId = investmentArray.id;
        form.portfolioName = investmentArray.name;
        onContinueClick();
      } else {
        form.agf = investmentArray.agf.id;
        form.goal = goalValue;
        form.serie = investmentArray.serie.id;
        onContinueClick();
      }
    }

    onClose();
  };
  return (
    <>
      <Dialog open={open} onClose={onClose} className={classes.Dialog}>
        <IconButton onClick={onClose} className={classes.CloseIcon}>
          <CloseIcon htmlColor="#0a5669" />
        </IconButton>
        <div>
          <div className={classes.Modal}>
            <div className={classes.info}>
              <div className={classes.titulo}>
                <div className={classes.numberInfo}>
                  <img
                    src={
                      'https://storage.googleapis.com/rokinapp-images/finances/SandP/expense_icon.svg'
                    }
                    className={classes.numberImg}
                  />
                </div>
                <div className={classes.titleText}>Depositar</div>
              </div>
              <div className={classes.linea}></div>
              <div className={classes.savingOn}>
                <div className={classes.columner}>
                  <div className={classes.savingText}>Ahorraré en:</div>
                  <div className={classes.savingText2}>OBJETIVO</div>
                  <Select
                    className={classes.targetSelect}
                    name="sort"
                    value={goalValue}
                    onChange={(e) => setGoalValue(e.target.value)}
                    size={'large'}
                    disabled={disabledFound}
                    options={targetOptions}
                  />
                </div>
                <div>
                  <div className={classes.savingText}>La cantidad de:</div>
                  <div className={classes.savingText2}>MONTO A INVERTIR</div>
                  <NumericInput
                    className={classes.amountText}
                    name="amount"
                    label=""
                    value={form.amount}
                    onChange={onChange}
                    error={notValidAmount}
                    showHelperText
                    helperText={helperText}
                  />
                </div>
                <div className={classes.columner}>
                  <div className={classes.savingText3}>Se invertirá en:</div>
                  <Select
                    className={classes.targetSelect}
                    name='sort'
                    value={fundValue}
                    onChange={(e) => [
                      (form.fund = e.target.value),
                      setFundValue(e.target.value),
                    ]}
                    size={'large'}
                    options={fundsOptions}
                  ></Select>
                </div>
              </div>
              <div className={classes.amountOf}>
                <ButtonPrimary
                  className={classes.button}
                  noDegraded
                  text="Listo"
                  disabled={invalidAmount}
                  onClick={handleOnClick}
                />
              </div>
              <div className={classes.text2}>
                Recuerda que invertirás a través de Vector Corredora de Bolsa
                S.A (i)
                <span>
                  Recuerda que rentabilidades pasadas no aseguran rentabilidades
                  futuras* 'Infórmese de las características esenciales de la
                  inversión en este Fondo Mutuo, las que se encuentran
                  contenidas en su reglamento interno y contrato de suscripción
                  de cuotas. Además, recuerde que la rentabilidad o ganancia
                  obtenida en el pasado por este fondo no garantiza que ella se
                  repita en el futuro.'
                </span>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};
const useStyles = makeStyles(
  (theme) => ({
    outerBorder: {
      background: 'rgba(0,0,0,0)',
      border: '1px solid white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 20,
      width: 'auto',
      height: 'auto',
      padding: '0.25rem',
    },
    Dialog: {
      '& >div >div': {
        borderRadius: '20px',
        background: 'rgba(0,0,0,0)',
        maxWidth: 900,
        margin: 15,
        width: '90%',
        [theme.breakpoints.up(1040)]: {
          width: '100%',
          maxWidth: 700,
        },
      },
    },
    Modal: {
      flexGrow: 0,
      borderRadius: 20,
      backgroundColor: 'rgba(237, 235, 235, 0.9)',
      minWidth: '310px',
      width: '100%',
      height: 'auto',
      minHeight: 500,
      padding: '0',
      [theme.breakpoints.up(1040)]: {
        width: '100%',
        maxWidth: 700,
      },
    },
    info: {
      display: 'grid',
      borderRadius: 20,
      backgroundColor: 'rgba(237, 235, 235, 0.9)',
    },
    titulo: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      margin: '1.6rem 0 1rem 0rem',
      paddingLeft: '2rem',
    },
    CloseIcon: {
      position: 'absolute',
      right: 20,
      top: 13,
    },
    investorProfile: {
      fontFamily: 'Montserrat',
      fontSize: '0.875rem',
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      paddingLeft: '1rem',
      [theme.breakpoints.up(1040)]: {
        paddingLeft: '2.5rem',
      },
    },
    numberInfo: {
      '& >span': {
        zIndex: 3,
        top: 37,
        left: 51,
        color: '#4619b6',
        fontSize: '1.5rem',
        fontWeight: 'bold',
        fontFamily: 'montserrat',
        position: 'relative',
      },
    },
    numberImg: {},
    roksImg: {
      width: 60,
      height: 'auto',
    },
    titleText: {
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      color: '#393E44',
      marginLeft: '1rem',
      fontSize: 18,
    },
    text1: {
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      color: '#393E44',
      marginTop: '20px',
      fontSize: 18,
    },
    linea: {
      height: 2,
      flexGrow: 0,
      backgroundColor: '#fff',
      width: '90%',
      marginLeft: '5%',
    },
    text2: {
      fontFamily: 'Montserrat',
      fontSize: '0.875rem',
      fontWeight: 400,
      color: '#393E44',
      display: 'grid',
      margin: '1rem 0 2rem 0',
      padding: '0 1rem 0 1rem',
      [theme.breakpoints.up(1040)]: {
        padding: '0 3rem 0 3rem',
      },
      '& >span': {
        color: '#393E44',
        fontSize: '0.75rem',
        fontWeight: 'normal',
        fontFamily: 'Nunito',
        marginTop: '0px',
      },
    },
    textButton: {
      display: 'grid',
    },
    text3: {
      fontFamily: theme.typography.fontFamily,
      fontSize: '1.25rem',
      fontWeight: 'bold',
      color: '#393e44',
      marginBottom: 10,
    },
    textButton2: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    text4: {
      fontFamily: 'Montserrat',
      fontSize: '1rem',
      fontWeight: 'normal',
      color: '#000000',
      margin: '20px 20px 0 0',
    },
    roks: {
      display: 'flex',
    },
    button: {
      height: '3rem',
      fontSize: '1rem',
      width: '60%',
      marginTop: '1rem',
      marginLeft: '20%',
      right: 0,
    },
    left: {
      left: '48px!important',
    },
    columner: {
      width: '100%',
    },
    savingOn: {
      display: 'flex',
      paddingBottom: 0,
      flexDirection: 'column',
      gap: '1.5rem',
      paddingTop: 10,
      [theme.breakpoints.up(1040)]: {
        padding: '2rem 3rem 0 3rem',
      },
    },
    targetSelect: {
      boxShadow: '4px 4px 8px -3px rgb(0 0 0 / 60%)',
      height: 44,
      top: 10,
      color: '#0a5a64',
      fontWeight: 'bold',
      width: '90%',
      margin: '0 5% 0 3%',
      [theme.breakpoints.up(1040)]: {
        width: '100%',
        maxWidth: 500,
      },
    },
    targetText: {
      fontFamily: theme.typography.fontFamily,
      fontSize: '12px',
      fontWeight: 'normal',
      color: '#008296',
      padding: '15px 0 0 5px',
      marginTop: '2rem',
    },
    amountOf: {
      paddingTop: 40,
      paddingBottom: 20,
      display: 'grid',
      gap: '1rem',
      padding: 0,
      margin: '1rem 0 0rem',
    },
    savingText: {
      fontFamily: 'Nunito',
      fontWeight: 600,
      color: '#6b6b6b',
      position: 'relative',
      fontSize: '0.75rem',
      top: 0,
      paddingLeft: '1rem',
      [theme.breakpoints.up(1040)]: {
        paddingLeft: '2.5rem',
      },
      '&>div': {
        width: '100%',
      },
    },
    savingText2: {
      fontFamily: 'Montserrat',
      fontWeight: 500,
      color: '#6b6b6b',
      position: 'relative',
      paddingLeft: '1rem',
      fontSize: '0.75rem',
      marginTop: '0rem',
      top: 0,
      [theme.breakpoints.up(1040)]: {
        paddingLeft: '2.5rem',
      },
      '&>div': {
        width: '100%',
      },
    },
    savingText3: {
      color: '#393e44',
      fontFamily: 'Montserrat',
      fontWeight: 600,
      position: 'relative',
      marginTop: '0rem',
      fontSize: '0.75rem',
      top: 0,
      paddingLeft: '1rem',
      '&>div': {
        width: '100%',
      },
    },
    savingText4: {
      fontSize: '1rem',
      color: '#393e44',
      fontFamily: 'Montserrat',
      fontWeight: 600,
      marginTop: '1.5rem',
      position: 'relative',
      top: 0,
      '&>div': {
        width: '100%',
      },
    },
    amountText1: {
      position: 'absolute',
      fontSize: 14,
      fontFamily: 'Montserrat',
      fontWeight: 600,
      width: 120,
      top: 260,
      [theme.breakpoints.up(1040)]: {
        width: 120,
        top: 260,
      },
    },
    amountText: {
      boxShadow: '4px 4px 8px -3px rgb(0 0 0 / 60%)',
      top: '12px',
      margin: '0 5% auto 3%',
      width: '90%',
      maxWidth: '10rem',
      [theme.breakpoints.up(1040)]: {
        width: '100%',
        maxWidth: 500,
      },
    },
  }),
  { index: 1 }
);
