import { ApolloClient, gql, useQuery } from "@apollo/client";
import { Event } from "@interfaces";

export const GET_EVENTS_BY_TYPE = gql`
  query EventByType($type: Int!) {
    eventByType(type: $type) {
      id
      amount
      category
      date
      nextDate
      description
      name
      recurrence
      showInCalendar
      type
      url
    }
  }
`;

type QueryResult = {
  eventByType: Event[];
};

interface HookReturn {
  eventByTypeData: Event[] | null;
  loading: boolean;
}

export const getEventByType = (type: number): HookReturn => {
  const { data, loading } = useQuery<QueryResult>(
    GET_EVENTS_BY_TYPE,
    {
      variables: { type },
      skip: !type,
    }
  );
  return { eventByTypeData: data?.eventByType ?? null, loading };
};
