import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { navigate } from '@reach/router';
import moment from 'moment-timezone';
import { useLocation } from '@reach/router';
import {
  ChangeEvent,
  Investment,
  InvestmentType,
  MovementType,
  OnChangeType,
  PortfolioAssetCategory,
  PortfolioLocationCategory,
  Route,
  Portfolio,
  ProfileForm,
} from '@interfaces';
import { AddFundsModal, WithdrawFundsModal } from '@components';
import {
  AppContext,
  SnackbarContext,
  InvestmentsContext,
  ProfileContext,
} from '@context';
import { createVectorAccount } from '@apollo';
import { round } from 'lodash';
import { useApolloClient } from '@apollo/client';

const SHARE_VALUE_INCREASE_PERCENTAGE = Number(
  process.env.GATSBY_SHARE_VALUE_INCREASE_PERCENTAGE ?? 0.4
);

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface NewInvestContextType {
  pageStep: number;
  onPageBackClick: () => void;
  form: NewInvestFormType;
  onChange: OnChangeType;
  startFlow: (params: StartInvestFlowType) => void;
  openAddFundsModal: (
    value,
    firstFundSelection?: string,
    depositValue?: number,
    disabled?: boolean
  ) => void;
  openWithdrawFundsModal: (value) => void;
  onErrorChange: (key: string, error: boolean) => void;
  resetVars: () => void;
  onContinueClick: () => void;
  anyErrorOnStep: boolean;
  isLastPage: boolean;
  validInvestedAmount: boolean;
  finishFlow: () => void;
  refetchCurrentUser: () => void;
  saveUserInfo: (
    payload?: Partial<ProfileForm> | undefined,
    withSnackbar?: boolean | undefined
  ) => Promise<void>;
  // parseAmountOverMinimum: (amount: number) => number;
}

interface NewInvestFormType {
  agf: number | null;
  fund: number | null;
  serie: number | null;
  amount: number;
  investFrequency: string;
  period: number;
  goal: number | null;
  partialWithdrawal: boolean;
  serieMinimum: number | null;
  suggestedPortfolio?: boolean;
  portfolioId?: number | null;
  serieShareValue: number | null;
  portfolioName?: string | null;
  investorProfile?: string | null;
  investmentAsset: PortfolioAssetCategory | null;
  investmentLocation: PortfolioLocationCategory | null;
}

export interface StartInvestFlowType {
  isReinvest: boolean;
  agfId?: number;
  fundId?: number;
  serieId?: number;
  movementType?: MovementType;
  comingFrom?: string;
  suggestedPortfolio?: boolean;
  portfolioId?: number;
  portfolioName?: string;
  suggestedPortfolioAmount?: number;
  investmentAsset?: PortfolioAssetCategory | null;
  investmentLocation?: PortfolioLocationCategory | null;
  investments?: Investment[];
  portfolios?: Portfolio[];
  newInvestorProfile?: string;
}

enum FlowType {
  ONBOARDING = 'ONBOARDING',
  FIRST_SERIE_INVEST = 'FIRST_SERIE_INVEST',
  REINVEST = 'REINVEST',
  WITHDRAWAL = 'WITHDRAWAL',
}

const DEFAULT_FORM = {
  agf: null,
  fund: null,
  serie: null,
  amount: 0,
  investFrequency: 'Mensualmente',
  period: 1,
  goal: null,
  partialWithdrawal: true,
  serieMinimum: 0,
  suggestedPortfolio: false,
  portfolioId: null,
  serieShareValue: 0,
  portfolioName: null,
  investmentAsset: null,
  investmentLocation: null,
};

export const NewInvestContext = createContext({} as NewInvestContextType);

export const NewInvestProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const client = useApolloClient();
  const { enableSnackbar, disableSnackbar } = useContext(SnackbarContext);
  const {
    saveUserInfo,
    isNotMarried,
    isOnboardingCompleted,
    refetchCurrentUser,
    profileForm: { investorProfile },
  } = useContext(ProfileContext);
  const {
    createNewInvestments,
    createNewMovements,
    hasInvestedOnSerie,
    hasInvestedOnPortfolio,
    investOnPortfolio,
    sendPortfolioWithdrawal,
  } = useContext(InvestmentsContext);
  const { fundsWithSeries } = useContext(AppContext);
  const [comingFrom, setComingFrom] = useState('/comparador');
  const [isWithdrawal, setIsWithdrawal] = useState(false);
  const [pageStep, setPageStep] = useState(0);
  const [activeFlow, setActiveFlow] = useState(false);
  const [form, setForm] = useState<NewInvestFormType>(DEFAULT_FORM);
  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [flowType, setFlowType] = useState<FlowType | undefined>();
  const validInvestedAmount =
    form.amount > 0 &&
    !(pageStep >= 0 && form.serieMinimum && form.amount < form.serieMinimum);
  const anyErrorOnStep = Object.values(errors).some(Boolean);

  const getFlowTypeRoutes = useCallback(() => {
    if (flowType === FlowType.ONBOARDING) return onboardingRoutes;
    if (flowType === FlowType.FIRST_SERIE_INVEST) return investRoutes;
    if (flowType === FlowType.REINVEST) return reinvestRoutes;
    if (flowType === FlowType.WITHDRAWAL) return withdrawalRoutes;
    return [];
  }, [flowType]);

  // Navigate on routes based on flow type and page step
  // useEffect(() => {
  //   const routes = getFlowTypeRoutes();
  //   if (activeFlow && routes.length) {
  //     navigate(routes[pageStep]);
  //   }
  // }, [activeFlow, getFlowTypeRoutes, pageStep]);

  // Save serie minimum invest amount
  useEffect(() => {
    if (fundsWithSeries && fundsWithSeries.length && form.fund && form.serie) {
      const fund = fundsWithSeries.find((fund) => fund.fund.id === form.fund);
      const serie = fund?.series.find((serie) => serie.id === form.serie);
      if (!serie) return;
      // Save the minimum amount to invest and the share value.
      // Increment share value for security
      const incrementFactor = 1 + SHARE_VALUE_INCREASE_PERCENTAGE / 100;
      const serieShareValue = serie.shareValueCLP
        ? round(serie.shareValueCLP * incrementFactor)
        : null;
      const shouldIncrementMinimum = serie.minInvest === serie.shareValueCLP;
      const serieMinimum = serie.minInvest
        ? round(
            serie.minInvest * (shouldIncrementMinimum ? incrementFactor : 1)
          )
        : null;
      setForm((prev) => ({
        ...prev,
        serieShareValue,
        serieMinimum,
      }));
    }
  }, [fundsWithSeries, form.fund, form.serie, setForm]);

  // Disable snackbar when active flow (collision with footer)
  useEffect(() => {
    if (activeFlow) {
      disableSnackbar();
    } else {
      enableSnackbar();
    }
  }, [activeFlow, enableSnackbar, disableSnackbar]);

  // To save errors during form
  const onErrorChange = useCallback(
    (key: string, error: boolean) =>
      setErrors((prev) => ({ ...prev, [key]: error })),
    [setErrors]
  );

  const isLastPage = useMemo(
    () => pageStep === getFlowTypeRoutes().length - 1,
    [pageStep, getFlowTypeRoutes]
  );

  // Navigate away if entering directly to the /invertir page
  // useEffect(() => {
  //   if (location.pathname.includes(Route.invest) && !form.serie) {
  //     navigate(comingFrom);
  //   }
  // }, [form.serie, location.pathname, comingFrom]);
  // Fallando porque se ejecutaba en el finishFlow
  // useEffect(() => {
  //   if (location.pathname.includes("invertir") && !form.serie) {
  //     onCancelClick();
  //   }
  // }, [location.pathname, form.serie, onCancelClick]);

  /**
   * CALLBACKS COMMON TO EVERY FLOW
   */
  const onChange: OnChangeType = useCallback(
    (e: ChangeEvent) => {
      const name = e?.target?.name as keyof NewInvestFormType;
      const value = e?.target?.value as string | number;
      const nullifyGoal = name === 'goal' && value === 0;
      if (name)
        setForm((prev) => ({
          ...prev,
          [name]: nullifyGoal ? null : value,
        }));
    },
    [setForm, form]
  );

  const resetVars = useCallback(() => {
    setActiveFlow(false);
    setPageStep(0);
    setForm(DEFAULT_FORM);
    setErrors({});
    setFlowType(undefined);
  }, [setActiveFlow, setPageStep, setForm, setErrors, setFlowType]);

  const onCancelClick = useCallback(() => {
    setTimeout(() => resetVars(), 1000);
    navigate(comingFrom);
  }, [comingFrom, resetVars]);

  const startFlow = useCallback(
    ({
      isReinvest,
      agfId,
      fundId,
      serieId,
      movementType,
      comingFrom,
      suggestedPortfolio,
      portfolioId,
      portfolioName,
      suggestedPortfolioAmount,
      newInvestorProfile,
      investmentAsset,
      investmentLocation,
      investments = [],
      portfolios = [],
    }: StartInvestFlowType) => {
      resetVars();
      setActiveFlow(true);
      const isWithdrawal = movementType === MovementType.WITHDRAWAL;
      setIsWithdrawal(isWithdrawal);
      setForm((prev) => ({
        ...prev,
        agf: agfId ?? null,
        fund: fundId ?? null,
        serie: serieId ?? null,
        portfolioId: portfolioId ?? null,
        portfolioName: portfolioName ?? null,
        suggestedPortfolio: Boolean(suggestedPortfolio),
        amount: suggestedPortfolioAmount ?? 0,
        investorProfile: newInvestorProfile ?? null,
        investmentLocation: investmentLocation ?? null,
        investmentAsset: investmentAsset ?? null,
      }));
      if (comingFrom) setComingFrom(comingFrom);
      // Determine if user has invested on this serie
      const hasInvestedThisSerie = hasInvestedOnSerie(investments, serieId);
      const hasInvestedThisPortfolio = hasInvestedOnPortfolio(
        portfolios,
        portfolioId
      );
      // console.log(
      //   { hasInvestedThisSerie, isOnboardingCompleted },
      //   'But Reinvest is ' + isReinvest
      // );
      // Determine flow type
      if (isWithdrawal) setFlowType(FlowType.WITHDRAWAL);
      else if (!isOnboardingCompleted) setFlowType(FlowType.ONBOARDING);
      else if (hasInvestedThisSerie || hasInvestedThisPortfolio || isReinvest)
        setFlowType(FlowType.REINVEST);
      else setFlowType(FlowType.FIRST_SERIE_INVEST);
    },
    [
      isOnboardingCompleted,
      hasInvestedOnSerie,
      hasInvestedOnPortfolio,
      resetVars,
      setActiveFlow,
      setIsWithdrawal,
      setForm,
      setPageStep,
      setComingFrom,
      setFlowType,
    ]
  );
  const finishFlow = useCallback(() => {
    // Handle case of investing on new portfolio
    if (form.suggestedPortfolio) {
      investOnPortfolio({
        suggested: true,
        amount: form.amount,
        investmentFrequency: form.investFrequency,
        period: form.period,
        portfolioId: form.portfolioId ?? null,
        investorProfile: form.investorProfile ?? investorProfile,
        investmentAsset: form.investmentAsset,
        investmentLocation: form.investmentLocation,
        goalId: form.goal,
      });
    }
    // Handle portfolio cases
    else if (form.portfolioId) {
      if (flowType === FlowType.WITHDRAWAL) {
        sendPortfolioWithdrawal({
          portfolioId: form.portfolioId,
          amount: form.amount,
        });
        setTimeout(() => resetVars(), 5000);
        navigate(Route.successfulWithdraw);
        return;
      } else {
        investOnPortfolio({
          amount: form.amount,
          investmentFrequency: form.investFrequency,
          period: form.period,
          portfolioId: form.portfolioId ?? null,
          investorProfile,
          investmentAsset: form.investmentAsset,
          investmentLocation: form.investmentLocation,
        });
      }
    }
    // Handle single serie cases
    else if (!form.portfolioId) {
      if (flowType === FlowType.WITHDRAWAL) {
        createNewMovements(
          [
            {
              type: MovementType.WITHDRAWAL,
              date: moment().tz('America/Santiago').toString(),
              amount: form.amount,
              serieId: form.serie,
            },
          ],
          InvestmentType.REAL
        );
        setTimeout(() => resetVars(), 5000);
        navigate(Route.successfulWithdraw);
        return;
      } else {
        createNewInvestments([
          {
            agfId: form.agf,
            fundId: form.fund,
            serieId: form.serie,
            invested: form.amount,
            dontRememberDate: true,
            goalId: form.goal,
            investFrequency: form.investFrequency,
            period: form.period,
            type: InvestmentType.REAL,
          },
        ]);
      }
    }
    setTimeout(() => resetVars(), 5000);
    navigate(
      Route.deposit +
        `?amount=${form.amount}&fundId=${form.fund}&goalId=${form.goal}`
    );
  }, [
    flowType,
    setForm,
    form,
    investorProfile,
    resetVars,
    createNewMovements,
    createNewInvestments,
    investOnPortfolio,
    sendPortfolioWithdrawal,
  ]);

  const onContinueClick = useCallback(() => {
    const routes = getFlowTypeRoutes();
    // Check if need to save user data to DB
    if (USER_DATA_STEPS.includes(routes[pageStep])) {
      saveUserInfo();
    }
    // Check if must finish the flow
    if (pageStep === routes.length - 1) {
      finishFlow();
      refetchCurrentUser();
    } else {
      setPageStep((prev) => {
        //If user has finished filling the profile, then try to create the vector account
        if (prev === 5) {
          console.log('Should create vector account');
          // createVectorAccount(true, client);
        }
        // Skip spouse data step if not married
        if (prev === 3 && isNotMarried) {
          return prev + 2;
        }
        return prev + 1;
      });
    }
  }, [
    client,
    form,
    saveUserInfo,
    pageStep,
    isNotMarried,
    getFlowTypeRoutes,
    finishFlow,
    refetchCurrentUser,
    setPageStep,
  ]);

  // Callback to navigate back on flow
  const onPageBackClick = useCallback(() => {
    if (flowType === FlowType.ONBOARDING && pageStep === 5 && isNotMarried) {
      // Skip spouse data step if not married
      setPageStep((prev) => prev - 2);
    } /*else if (
      flowType === FlowType.WITHDRAWAL &&
      pageStep === 1 &&
      isBankDataCompleted
    ) {
      // Skip bank data step if already completed
      setPageStep(prev => prev - 2);
    }*/ else {
      setPageStep((prev) => prev - 1);
    }
  }, [pageStep, flowType, isNotMarried, setPageStep /*isBankDataCompleted,*/]);

  // Check if need to cancel the flow
  useEffect(() => {
    if (pageStep < 0) {
      onCancelClick();
    }
  }, [pageStep, onCancelClick]);

  /*ADD FUNDS MODAL HANDLING */
  const [addFundsModalGoalId, setAddFundsModalGoalId] =
    useState<number>(undefined);

  const [addFundsModal, setAddFundsModal] = useState(false);
  const [firstFundSelection, setFirstFundSelection] = useState<string>(null);
  const [depositValue, setDepositValue] = useState<number>(null);
  const [isDisabled, setDisabled] = useState(false);
  const openAddFundsModal = useCallback(
    (value, firstFundSelection, depositValue, disabled) => {
      setDisabled(disabled);
      if (!isNaN(value)) {
        setAddFundsModalGoalId(value);
      }
      if (firstFundSelection) {
        setFirstFundSelection(firstFundSelection);
      }
      if (depositValue) {
        setDepositValue(depositValue);
      }
      setAddFundsModal(true);
    },
    [setAddFundsModal]
  );
  const closeAddFundsModal = useCallback(() => {
    setAddFundsModal(false);
    resetVars();
  }, [setAddFundsModal]);

  /*WITHDRAW FUNDS MODAL HANDLING */
  const [withdrawFundsModalGoalId, setWithdrawFundsModalGoalId] =
    useState<number>(undefined);
  const [withdrawFundsModal, setWithdrawFundsModal] = useState(false);
  const openWithdrawFundsModal = useCallback(
    (value) => {
      if (!isNaN(value)) {
        setWithdrawFundsModalGoalId(value);
      }
      setWithdrawFundsModal(true);
    },
    [setWithdrawFundsModal]
  );
  const closeWithdrawFundsModal = useCallback(() => {
    setWithdrawFundsModal(false);
    setWithdrawFundsModalGoalId(undefined);
    resetVars();
  }, [setWithdrawFundsModal]);

  // Check route changes to stop the flow
  useEffect(() => {
    if (![Route.invest].some((route) => location.pathname.includes(route))) {
      resetVars();
    }
  }, [location.pathname, resetVars]);

  const context = useMemo(
    () => ({
      pageStep,
      onPageBackClick,
      form,
      onChange,
      startFlow,
      openAddFundsModal,
      openWithdrawFundsModal,
      onErrorChange,
      // parseAmountOverMinimum,
      resetVars,
      onContinueClick,
      anyErrorOnStep,
      isLastPage,
      validInvestedAmount,
      finishFlow,
      refetchCurrentUser,
      saveUserInfo,
    }),
    [
      pageStep,
      onPageBackClick,
      form,
      onChange,
      startFlow,
      openAddFundsModal,
      openWithdrawFundsModal,
      onErrorChange,
      // parseAmountOverMinimum,
      resetVars,
      onContinueClick,
      anyErrorOnStep,
      isLastPage,
      validInvestedAmount,
      finishFlow,
      refetchCurrentUser,
      saveUserInfo,
    ]
  );

  return (
    <NewInvestContext.Provider value={context}>
      <>{children}</>
      <AddFundsModal
        open={addFundsModal}
        onClose={closeAddFundsModal}
        selectedTarget={addFundsModalGoalId}
        disabledFound={isDisabled}
        firstFundSelection={firstFundSelection}
        depositValue={depositValue}
      />
      <WithdrawFundsModal
        open={withdrawFundsModal}
        onClose={closeWithdrawFundsModal}
        selectedGoal={withdrawFundsModalGoalId}
      />
    </NewInvestContext.Provider>
  );
};

const onboardingRoutes = ['/invertir'];
const investRoutes = ['/invertir/confirmar'];
const reinvestRoutes = ['/invertir/reinvertir'];
const withdrawalRoutes = ['/Investments', '/Investments'];

const USER_DATA_STEPS = [
  '/invertir/perfil-1',
  '/invertir/perfil-2',
  '/invertir/conyuge',
  '/invertir/perfil-3',
  '/invertir/datos-bancarios',
];
