import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { Goal, GoalForm, Route } from "@interfaces";
import { CreateGoalsDialog, GoalDialogInitialData } from "@components";
import { createGoal, updateGoal as APIupdateGoal } from "@apollo";
import { LoadingContext } from "./LoadingContext";
import { DEFAULT_GOAL_FORM } from "@hooks";
import { useApolloClient } from "@apollo/client";

interface GoalsContextType {
  addGoal: (goalForm: GoalForm) => void;
  updateGoal: (goalId: number, payload: Partial<GoalForm>) => void;
  openGoalDialog: () => void;
  openGoalDialogOnGoalStep: (
    goalId: number,
    initialStep: number,
    goals: Goal[],
  ) => void;
}

export const GoalsContext = createContext({} as GoalsContextType);

export const GoalsProvider: React.FC = ({ children }) => {
  const client = useApolloClient();
  const { showLoader, hideLoader } = useContext(LoadingContext);

  const [initialDialogData, setInitialDialogData] =
    useState<GoalDialogInitialData>({ ...DEFAULT_INITIAL_DATA });

  const [goalDialogOpen, setGoalDialogOpen] = useState(false);
  const openGoalDialog = useCallback(() => setGoalDialogOpen(true), []);

  const openGoalDialogOnGoalStep = useCallback(
    (goalId: number, initialStep: number, goals: Goal[]) => {
      const goal = goals.find(g => g.id === goalId) as Goal;
      setInitialDialogData({
        goalId,
        goalForm: transformGoalIntoForm(goal),
        step: initialStep,
      });
      setGoalDialogOpen(true);
    },
    [setInitialDialogData, setGoalDialogOpen],
  );

  const closeGoalDialog = useCallback(() => {
    setGoalDialogOpen(false);
    setInitialDialogData({ ...DEFAULT_INITIAL_DATA });
  }, []);

  const addGoal = useCallback(
    (goalForm: GoalForm) => {
      showLoader("creating goal", [Route.goals]);
      createGoal(goalForm, client).then(() => {
        hideLoader("creating goal");
      });
    },
    [client, showLoader, hideLoader],
  );

  const updateGoal = useCallback(
    (goalId: number, payload: Partial<GoalForm>) => {
      APIupdateGoal(goalId, payload, client);
    },
    [client],
  );

  const context = useMemo(
    () => ({
      addGoal,
      updateGoal,
      openGoalDialog,
      openGoalDialogOnGoalStep,
    }),
    [addGoal, updateGoal, openGoalDialog, openGoalDialogOnGoalStep],
  );

  return (
    <GoalsContext.Provider value={context}>
      {children}
      <CreateGoalsDialog
        open={goalDialogOpen}
        onClose={closeGoalDialog}
        initialDialogData={initialDialogData}
      />
    </GoalsContext.Provider>
  );
};

const DEFAULT_INITIAL_DATA = {
  goalId: null,
  goalForm: null,
  step: null,
};

const transformGoalIntoForm = (
  goal?: Goal,
  defaultNull = true,
): GoalForm | null =>
  goal
    ? {
        name: goal.name,
        goal: goal.goal,
        investmentFrequency: goal.info.investmentFrequency,
        period: goal.info.period,
      }
    : defaultNull
    ? null
    : DEFAULT_GOAL_FORM;
