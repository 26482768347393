import { ApolloClient, gql, useLazyQuery, useQuery } from "@apollo/client";
import { Event } from "@interfaces";

export const GET_ALL_EVENTS = gql`
  query {
    allUserEvents
  }
`;
type QueryResult = {
  allUserEvents: string[];
};

interface Events {
  eventsData: string[] | null;
  loading: boolean;
  allEvents: any;
  refetchAllEvents: () => void;
}

export const getAllEvents = (): Events => {
  const [allEvents, { data, loading, refetch }] =
    useLazyQuery<QueryResult>(GET_ALL_EVENTS);
  return { eventsData: data?.allUserEvents ?? null, loading, allEvents, refetchAllEvents: refetch };
};
