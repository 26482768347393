import React, { useContext } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import { ButtonPrimary } from "@components/Reusables";
import { AppContext } from "@context";

interface ContactDialogFinishedProps {
  open: boolean;
}
export const ContactDialogFinished: React.FC<ContactDialogFinishedProps> = ({
  open,
}) => {
  const { closeContactDgFinished } = useContext(AppContext);
  const classes = useStyles();
  return (
    <Dialog
      className={classes.contactDialogFinished}
      open={open}
      onClose={closeContactDgFinished}
    >
      <DialogTitle>
        <div className={classes.titleDialogFinished}>
          Te contactaremos pronto
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        Te contactaremos a la brevedad.
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <ButtonPrimary
          className={classes.textButton}
          text="Cerrar"
          onClick={closeContactDgFinished}
        />
      </DialogActions>
    </Dialog>
  );
};
const useStyles = makeStyles(
  theme => ({
    contactDialogFinished: {
      width: "100%",
    },
    titleDialogFinished: {
      color: theme.palette.primary.main,
      fontFamily: "Montserrat, sans-serif",
      fontSize: 24,
      textAlign: "center",
    },
    textButton: {
      color: "white",
    },
    dialogActions: {
      justifyContent: "center",
      padding: "14px 0",
    },
    dialogContent: {
      padding: "8px 48px 20px",
      textAlign: "center",
    },
  }),
  { index: 1 },
);
