import { useMediaQuery } from "@material-ui/core";
import { SizeType } from "@styles";
import { useEffect, useState } from "react";

interface HookParams {
  size?: SizeType;
}

type HookResults = SizeType;

export const useResponsiveInputSize = ({
  size: externalSize,
}: HookParams): HookResults => {
  const [size, setSize] = useState<SizeType>(externalSize || "large");
  const isMobile = useMediaQuery("(max-width: 770px)");
  useEffect(() => {
    setSize(externalSize ?? (isMobile ? "large" : "xlarge"));
  }, [externalSize, isMobile, setSize]);

  return size;
};
