import { tableMA2Desktop, tableMA2Mobile, TCvsLC } from "@images";
import React, { useContext } from "react";
import { useStyles } from "./MA2step1.style";
import { useMediaQuery } from "@material-ui/core";
import { ButtonPrimary } from "@components/Reusables";
import { AppContext } from "@context";

type Props = {
  handleClick: () => void;
};

const MA2: React.FC<Props> = ({ handleClick }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const { openCongratsModal } = useContext(AppContext);
  return (
    <div className={classes.container}>
      <div className={classes.topSection}>
        <div className="textContainer">
          <span className="text">
            La
            <span className="style1"> tarjeta de crédito </span>
            es con lo que
            <strong> haces las compras. </strong>
            Por su parte, la
            <span className="style1"> línea de crédito </span>
            es
            <strong> plata que te presta el banco </strong>
            cuando te quedas sin saldo en tu cuenta, evitando que algún pago
            rebote por no tener saldo disponible.
            <br />
            <br />
            <strong>Ten cuidado con la línea de crédito </strong>
            porque tiene varios costos asociados que podrás ver en la siguiente
            tabla:
          </span>
        </div>
        <img src={TCvsLC} />
      </div>
      <div className={classes.imageContainer}>
        {isMobile ? (
          <div className="scrollable">
            <img src={tableMA2Mobile} />
          </div>
        ) : (
          <img src={tableMA2Desktop} />
        )}
      </div>
      <div className={classes.bottomSection}>
        <ButtonPrimary
          text={"Terminar Misión"}
          onClick={() => handleClick()}
          secondaryVersion
        />
      </div>
    </div>
  );
};

export default MA2;
