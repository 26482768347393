export const useURLParams = () => {
  if(typeof window !== 'undefined'){
    const queryString = (window.location.search).substr(1);
    const queryArray = queryString.split('&')
    const params = []
    queryArray.forEach(element => { 
      const paramAndValue = element.split("=")
      const jsonToParse = `{ "${paramAndValue[0]}": "${paramAndValue[1]}" }`
      params.push(JSON.parse(jsonToParse))
    });

    if(!queryString){
      return undefined
    }
    return params
  } else {
    return []
  }
};
