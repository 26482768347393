import React, { useContext, useEffect, useState } from "react";
import { makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import { Route } from "@interfaces";
import { ButtonPrimary, ButtonText } from "@components/Reusables";
import { RokinComplete, MenuIconito } from "@images";
import { useAuth } from "@hooks";
import { ProfileContext } from "@context";
import PerfilDropdown from "../OnBoarding/PerfilDropdown";
import { navigate } from "gatsby";
import HeaderMobile from "./HeaderMobile";

interface HeaderProps {
  simpleHeader?: boolean;
  impactRokin?: boolean;
  ourSolutions?: boolean;
  aboutUs?: boolean;
  helpCenter?: boolean;
  secondLanding?: boolean;
}

export const Header: React.FC<HeaderProps> = ({
  ourSolutions,
  impactRokin,
  helpCenter,
  aboutUs,
  secondLanding,
}: HeaderProps) => {
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const { isLoggedIn } = useAuth();
  const { profileForm } = useContext(ProfileContext);
  const [isOpenMenu, setOpenMenu] = useState(false);
  const classes = useStyles({ isOpen: isOpenMenu, isLoggedIn: isLoggedIn });
  const [isPerfilDropdown, setIsPerfilDropdown] = useState<boolean>(false);
  const [profileName, setProfileName] = useState("Usuario");
  const handleDropdown = () => {
    if (isPerfilDropdown) {
      setIsPerfilDropdown(false);
    } else {
      setIsPerfilDropdown(true);
    }
  };

  useEffect(() => {
    setProfileName(profileForm.name);
  }, [profileForm]);

  return (
    <header className={classes.header}>
      <div className={classes.headerLeft} onClick={() => navigate("/")}>
        <div className={classes.logo}>
          <img src={RokinComplete} alt="logo" />
        </div>
      </div>
      <div className={classes.blockRight}>
        <div className={classes.menuSites}>
          {/*  <div>
            <a>Visita otros sitios</a>
          </div> */}
        </div>
        {!isMobile && !secondLanding && (
          <>
            <div
              className={`${classes.impacted} ${
                impactRokin ? classes.active : undefined
              }`}
              onClick={() => navigate(Route.business)}
            >
              Rokin para empresas
            </div>
            <div
              className={`${classes.impacted} ${
                aboutUs ? classes.active : undefined
              }`}
              onClick={() => navigate(Route.aboutUs)}
            >
              Quiénes somos
            </div>
            <div
              className={`${classes.impacted} ${
                ourSolutions ? classes.active : undefined
              }`}
              onClick={() => navigate(Route.ourSolutions)}
            >
              Nuestras soluciones
            </div>{" "}
            {
              <ButtonText
                className={classes.ButtonOutlined}
                onClick={() =>
                  isLoggedIn ? navigate(Route.finance) : navigate(Route.login)
                }
                text="Entrar"
              />
            }
          </>
        )}
        <section className={classes.menu}>
          <ButtonPrimary
            className={classes.ButtonPrimary}
            onClick={() => {
              navigate(Route.register);
            }}
            text="Empezar"
            noDegraded
          />
        </section>
        {isMobile && (
          <section className={classes.menuMobile}>
            <div
              className={classes.imgMenu}
              onClick={() => setOpenMenu(!isOpenMenu)}
            >
              {isOpenMenu && isMobile ? (
                <img src="https://rokinapp-images.s3.amazonaws.com/Landing/redes/Navigation+_+close.svg" />
              ) : (
                <img src={MenuIconito} />
              )}
            </div>
          </section>
        )}{" "}
        {isPerfilDropdown && (
          <div className={classes.drop}>
            <PerfilDropdown handleDropdown={handleDropdown} index />
          </div>
        )}
        {isMobile && (
          <HeaderMobile isOpenMenu={isOpenMenu} secondLanding={secondLanding} />
        )}
      </div>
    </header>
  );
};

export const useStyles = makeStyles<
  Theme,
  { isOpen: boolean; isLoggedIn: boolean }
>((theme) => ({
  listItemText: {
    fontFamily: "Nunito",
    fontSize: 16,
    lineHeight: "1.5px",
    letterSpacing: "0.09px",
    color: "rgba(0, 0, 0, 0.87)",
  },
  imageProfileFinance: {
    width: 40,
  },
  listItem: {
    "& .selected": {
      backgroundColor: "rgba(0, 0, 0, 0.085)",
    },
    "& .hidden": {
      display: "none",
    },
  },
  login: {
    marginBottom: "0.5rem",
  },
  listItemRoot: {
    display: "flex",
    justifyContent: "center",
  },
  authMenuButton: {
    fontSize: 14,
    lineHeight: "1rem",
    fontWeight: 600,
    display: ({ isLoggedIn }) => (isLoggedIn ? "none" : "block"),
  },
  avatarIcon: {
    "& .MuiAvatar-img": {
      width: 24,
      height: 24,
    },
  },
  menuItem: {
    height: 52,
    "& div": {
      "& span": {
        fontSize: 14,
      },
    },
  },
  impacted: {
    padding: "0 20px",
    cursor: "pointer",
    color: theme.palette.text.disabled,
  },
  active: {
    borderBottom: `4px solid ${theme.palette.primary.main}`,
    color: theme.palette.text.primary,
  },
  modifiedButton: {
    display: "none",
  },
  nameUserLoggedIn: {
    fontSize: 14,
  },
  listItemIcon: {
    minWidth: "unset",
    width: 35,
    marginRight: 15,
    height: 35,
    display: "flex",
    justifyContent: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    height: 79,
    alignItems: "center",
    background: "#F4F3F3",
    boxShadow: "4px 4px 8px 2px rgba(0, 0, 0, 0.2)",
    position: "fixed",
    width: "100%",
    zIndex: 999,
    "& span": {
      textTransform: "inherit",
      [theme.breakpoints.down(1240)]: {},
    },
    [theme.breakpoints.down(1240)]: {
      paddingTop: 25,
    },
  },
  sectionMenuLanding: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up(1280)]: {
      display: "none",
    },
  },
  menuButton: {
    display: "block",
    width: 56,
    height: 56,
    color: "black",
  },
  logotxt: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    color: "#0a5669",
    fontSize: "3rem",
    [theme.breakpoints.down(1240)]: {
      fontSize: 19.7,
      marginTop: "0.5rem",
    },
  },
  blockRight: {
    display: "flex",
    alignItems: "center",
    minWidth: 900,
    fontFamily: "Nunito",
    paddingRight: "2rem",
    justifyContent: "inherit",
    transition: "0.3s",
    [theme.breakpoints.down(1240)]: {
      paddingRight: "2rem",
    },
    [theme.breakpoints.down(950)]: {
      minWidth: "auto!important",
      width: "auto!important",
    },
    "& a": {
      cursor: "pointer",
      [theme.breakpoints.down(1240)]: {
        height: 25,
      },
    },
    [theme.breakpoints.down(1240)]: {
      width: 150,
      padding: 0,
    },
  },
  ButtonOutlined: {
    minWidth: 148,
    borderLeft: `1px solid #d6d6d6`,
    borderRadius: 0,
    marginLeft: "2rem",
    textDecoration: "none",
    color: "#393e44",
    fontWeight: 400,
    fontFamily: "Nunito",
  },
  ButtonPrimary: {
    minWidth: 170,
    fontFamily: "Nunito",
    "& >span": {
      height: 10,
    },
    [theme.breakpoints.down(1240)]: {
      minWidth: 83,
      height: 33,
      fontSize: 12,
      fontWeight: "normal",
      borderRadius: 4,
      marginRight: 10,
      marginBottom: 2,
    },
  },
  logo: {
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    alignItems: "center",
    "& img": {},
  },
  headerLeft: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 300,
    [theme.breakpoints.down(1240)]: {
      justifyContent: "start",
      width: ({ isLoggedIn }) => (isLoggedIn ? "auto" : 160),
      alignItems: "start",
      height: 71,
    },
    "& img": {
      width: 180,
      padding: "10px 0",
      [theme.breakpoints.down(1240)]: {
        width: 110,
        paddingBottom: 5,
      },
    },
    "& a": {
      fontSize: 14,
      [theme.breakpoints.down(1100)]: {
        display: "none",
      },
    },
  },
  button: {
    textTransform: "none",
    fontWeight: 600,
    marginRight: 80,
  },
  buttonRegister: {
    color: theme.palette.primary.main,
    textTransform: "none",
    fontWeight: 600,
    marginRight: 50,
  },
  imgMenu: {
    height: "4rem",
    cursor: "pointer",
    marginLeft: "1rem",
    width: "45px!important",
    [theme.breakpoints.down(1240)]: {
      right: "auto",
      width: "37px!important",
    },
  },

  menu: {
    display: "flex",
    alignItems: "inherit",
    "& >div": {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      margin: "0 20px",
      "& >span": {
        paddingLeft: 10,
      },
    },
    "& >div:last-child": {
      padding: 0,
    },
    "&:hover": {
      "& .subMenu": {
        transition: "3s",
        width: 305,
        display: "block!important",
        position: "absolute",
        top: 60,
        background: "white",
        right: 0,
        borderRadius: 15,
        lineHeight: 3,
        [theme.breakpoints.down(1240)]: {
          right: "auto",
        },
        "& li": {
          borderBottom: "1px solid black",
        },
        "& li:last-child": {
          borderBottom: "none",
        },
      },
    },
    position: "relative",
  },
  menuSites: {
    "&>div": {
      padding: "20px 0",
    },
    "&:hover": {
      "& div>a": {
        transition: "0.3s",
        borderBottom: "2px solid #47d3a0",
      },
      "& .subMenuSites": {
        transition: "3s",
        display: "block!important",
        position: "absolute",
        top: 60,
        background: "white",
        right: 0,
        borderRadius: 15,
        lineHeight: 5,
        "& li": {
          borderBottom: "1px solid black",
          width: 85,
          "& img:hover": {
            background: "#F4F3F3",
          },
        },

        "& li:last-child": {
          borderBottom: "none",
        },
      },
    },
    position: "relative",
  },
  submenu: {
    display: "none",
    transition: "3s",
    padding: "15px 35px",
    "& li": {
      margin: "5px 0",
    },
  },
  submenusites: {
    display: "none",
    transition: "3s",
    padding: "10px 20px",
    "& img": {
      padding: 15,
      margin: "5px 0",
      borderRadius: 15,
    },
  },
  logoText: {
    display: "grid",
    height: "100%",
    paddingLeft: 30,
    [theme.breakpoints.down(1240)]: {
      paddingLeft: 10,
    },
  },
  logoTextBottom: {
    color: theme.palette.primary.main,
    fontWeight: 100,
    fontSize: "1rem",
    fontFamily: "Montserrat",
  },
  logoTextTop: {
    fontSize: "2rem",
    height: 35,
    fontWeight: 600,
    color: theme.palette.primary.main,
    fontFamily: "Montserrat",
  },
  personIcon: {
    color: theme.palette.primary.main,
    objectFit: "contain",
    flexGrow: 0,
    width: 37,
    height: 37,
  },
  drop: {
    position: "absolute",
    width: 220,
    right: -35,
    top: 0,
  },
  person: {},
  menuMobile: {
    display: "flex",
    "& >div": {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      margin: "0 10px",
      "& img": {
        width: 90,
      },
      "& >span": {
        paddingLeft: 10,
      },
    },
    "& >div:last-child": {
      padding: 0,
    },
    "&:hover": {
      "& .subMenu": {
        transition: "3s",
        width: 305,
        display: "block!important",
        position: "absolute",
        top: 60,
        background: "white",
        right: 0,
        borderRadius: 15,
        lineHeight: 3,
        [theme.breakpoints.down(1240)]: {
          right: "0",
        },
        "& li": {
          borderBottom: "1px solid black",
        },
        "& li:last-child": {
          borderBottom: "none",
        },
      },
    },
    position: "relative",
  },
  menuSticky: {
    width: "100%",
    height: "100vh",
    background: "#F4F3F3",
    position: "absolute",
    top: "4.8rem",
    left: ({ isOpen }) => (!isOpen ? "30rem" : "0rem"),
    transition: "all .4s",
    zIndex: 99999,
    borderTop: "1px solid #d6d6d6",
    opacity: ({ isOpen }) => (!isOpen ? "0" : "1"),
    visibility: ({ isOpen }) => (!isOpen ? "hidden" : "visible"),
    "& .sticky": {
      position: "sticky",
      width: "100%",
      height: "100vh",
      background: "#F4F3F3",
      zIndex: 99999,
      display: "flex",
      flexDirection: "column",
      gap: "2rem",
      paddingTop: "2rem",
      paddingLeft: "2rem",
    },
  },
  item: {
    fontWeight: 400,
    display: "flex",
    gap: "1rem",
  },
  line: {
    color: "#d6d6d6",
    border: `1px solid #d6d6d6`,
    width: "95%",
  },
}));
