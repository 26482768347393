/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useState } from "react";
import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  from,
  NormalizedCacheObject,
  ApolloProvider as ApolloProviderHooks,
} from "@apollo/client";
import { setContext } from "apollo-link-context";
import { onError, ErrorResponse } from "@apollo/link-error";
import { CachePersistor } from "apollo-cache-persist";
import { PersistedData, PersistentStorage } from "apollo-cache-persist/types";
import { GraphQLError } from "graphql";
import fetch from "isomorphic-fetch";
import { getAuth } from "firebase/auth";
import { useDeepEffect } from "@hooks";

export * from "./email";
export * from "./goals";
export * from "./user_investments";
export * from "./user";
export * from "./fund";
export * from "./files";
export * from "./save_by_rokin";
export * from "./contact_form";
export * from "./portfolio";
export * from "./movements";
export * from "./transactions";
export * from "./mission";
export * from "./term_deposit";
export * from "./company";
export * from "./finerio";
export * from "./calendar";
export * from "./coolebra";
export * from './subscriptions';
export * from './user_financial_data';

interface LinkContext {
  headers: {
    authorization?: string;
    "transaction-id": string;
    useremail: string;
  };
}

export const GlobalApolloClient = { logout: () => {} };

let apolloClient: ApolloClient<NormalizedCacheObject>;

const waitForUser = () => {
  return new Promise((res) => {
    if (getAuth().currentUser) {
      return res(true);
    }
    if (typeof window !== "undefined") {
      const unsuscribe = getAuth().onAuthStateChanged(() => {
        unsuscribe();
        res(true);
      });
    } else {
      res(true);
    }
  });
};

const getToken = async () => {
  await waitForUser();
  const currentUser = getAuth().currentUser;
  if (!currentUser) {
    return "";
  }
  return currentUser.getIdToken();
};

const clearToken = () => {
  return getAuth().signOut();
};

const createClient = async () => {
  const httpLink = new HttpLink({
    fetch,
    uri: `${process.env.GATSBY_API_URL}/graphql`,
  });

  const authMiddleware = setContext(async (req, { headers }: LinkContext) => {
    const currentToken = await getToken();
    if (currentToken) {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${currentToken}`,
        },
      };
    }
    return { headers };
  });

  const errorLink = onError(({ graphQLErrors }: ErrorResponse) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ extensions }: GraphQLError) => {
        const invalidAuth =
          extensions?.response?.body?.errors?.some((e: any) =>
            /invalid_token/.exec(e.extensions?.message)
          ) ||
          extensions?.response?.body?.errors?.some(
            (e: any) => e.extensions?.code === "UNAUTHENTICATED"
          );

        if (invalidAuth) {
          // GlobalApolloClient.logout();
        }
      });
    }
  });

  const linkWithAuthenticationHeader = from(
    [authMiddleware, errorLink, httpLink].filter(Boolean) as ApolloLink[]
  );

  const cache = new InMemoryCache();
  let persistor: CachePersistor<typeof window.localStorage>;

  if (typeof window !== "undefined") {
    persistor = new CachePersistor<typeof window.localStorage>({
      cache,
      storage: window.localStorage as PersistentStorage<PersistedData<Storage>>,
    });
    await persistor.persist();
  }

  const apolloClient = new ApolloClient({
    link: linkWithAuthenticationHeader,
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
    },
  });

  if (typeof window !== "undefined") {
    GlobalApolloClient.logout = async () => {
      const currentToken = await getToken();
      // TODO: writeQuery to remove viewerInfo data
      // apolloClient.writeQuery({
      //   query: GET_USER_QUERY,
      //   data: { viewerInfo: null },
      // });
      if (currentToken) {
        clearToken();
        await apolloClient.cache.reset();
        if (persistor) {
          await persistor.purge();
          await persistor.persist();
        }
      }
    };
  }

  return apolloClient;
};

export async function initializeApollo(initialState: any = null) {
  const _apolloClient = apolloClient ?? (await createClient());

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

type ProviderProps = {
  children: React.ReactNode;
  initialApolloState?: any;
};
export const ApolloProvider = ({
  children,
  initialApolloState,
}: ProviderProps) => {
  const clientRef = useRef(
    new ApolloClient({
      uri: `${process.env.GATSBY_API_URL}/graphql`,
      cache: new InMemoryCache(),
    })
  );
  const [initialized, setInitialized] = useState(false);

  useDeepEffect(() => {
    const createAndSetClient = async () => {
      const newClient = await initializeApollo(initialApolloState);
      clientRef.current = newClient;
      setInitialized(true);
    };
    createAndSetClient();
  }, [initialApolloState]);

  if (
    typeof window === "undefined" ||
    (typeof window !== "undefined" && initialized)
  )
    return (
      <ApolloProviderHooks client={clientRef.current}>
        {children}
      </ApolloProviderHooks>
    );
  return null;
};
