import React from "react";
import { Select } from "@components";
import { SelectOption } from "@interfaces";
import { FIRST_FUND_OPTIONS, CommonSelectorProps } from "..";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends CommonSelectorProps {
  options: SelectOption[];
}
export const FirstFundSelector: React.FC<Props> = ({
  className,
  name,
  options,
  ...props
}) => {
  return (
    <Select
      className={`${className || ""} altercap-investorProfile-selector`}
      name={name || "investorProfile"}
      options={options || FIRST_FUND_OPTIONS}
      {...props}
    />
  );
};
