import React from "react";
import { Select } from "@components";
import { CommonSelectorProps, INVEST_FREQUENCY_OPTIONS } from "..";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends CommonSelectorProps {
  hideLabel?: boolean;
}

export const InvestFrequencySelector: React.FC<Props> = ({
  className,
  name,
  label,
  hideLabel,
  ...props
}) => {
  return (
    <Select
      className={`${className || ""} altercap-investFrequency-selector`}
      name={name || "investFrequency"}
      label={hideLabel ? undefined : label || "Invertiré"}
      options={INVEST_FREQUENCY_OPTIONS}
      {...props}
    />
  );
};
