import SaveIcon from "./saveIcon.svg";
import regimenA from "./regimenA_icon.svg";
import regimenB from "./regimenB_icon.svg";
import regimenMixto from "./regimenMixto_icon.svg";
import sinApv from "./sinAPV_icon.svg";
import estrella from "./estrela_icon.svg";
import eye from "./ojo_icon.svg";

export {
  SaveIcon,
  regimenA,
  regimenMixto,
  regimenB,
  sinApv,
  estrella,
  eye,
}