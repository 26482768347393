import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  inputContainer: {
  },
  inputBar: {
    width: "100%",
    height: "2.25rem",
    borderRadius: "17.9px",
    boxShadow: "3.4px 3.4px 6.8px 1.7px rgba(0, 0, 0, 0.2)",
    fontSize: "0.875rem",
    fontFamily: "Montserrat",
    fontWeight: "normal",
    textAlign: "left",
    color: "#6B6B6B",
    paddingLeft: "1rem",
    "& ::placeholder": {
      color: "blue",
    },
  },
  searchButton: {
    width: "2.72rem",
    height: "2.24rem",
    borderRadius: "17.9px",
    backgroundColor: "#0A5669",
  },
}));
