import { ApolloClient } from "@apollo/client";
import { gql } from "@apollo/client";

const CREATE_FINERIO_CREDENTIAL = gql`
  mutation CreateFinerioCredential(
    $username: String!
    $password: String!
    $bankId: Int!
  ) {
    createFinerioCredential(
      username: $username
      password: $password
      bankId: $bankId
    )
  }
`;

type CreateFinerioCredentialParams = {
  username: string;
  password: string;
  bankId: number;
};

type CreateFinerioCredentialResult = {
  createFinerioCredential: boolean;
};

export async function createFinerioCredential(
  client: ApolloClient<any>,
  params: CreateFinerioCredentialParams
): Promise<CreateFinerioCredentialResult> {
  const { data } = await client.mutate({
    mutation: CREATE_FINERIO_CREDENTIAL,
    variables: params,
  });

  return data;
}
