import React, { useState, useCallback, Dispatch, SetStateAction } from "react";
import { useStyles } from "./M3mainComponent.style";
import { useMediaQuery } from "@material-ui/core";
import { ButtonPrimary } from "@components";
import { refinanciarCredito_ilustracion } from "@images";

type Props = {
  setPage: Dispatch<SetStateAction<number>>;
  adjustDesktop?: React.CSSProperties;
};

const M3MainComponent: React.FC<Props> = ({ setPage, adjustDesktop }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 1240px)");
  return (
    <>
      {isMobile ? (
        <div className={classes.container} style={adjustDesktop}>
          <div className={classes.secondSection}>
            <div className="imageContainer">
              <img src={refinanciarCredito_ilustracion} />
            </div>
          </div>
          <div className={classes.firstSection}>
            <div>
              <div className={classes.textSub}>Refinanciamiento de crédito</div>
              <span className={classes.text}>
                El refinanciamiento consiste en solicitar un crédito con nuevas
                condiciones para pagar el préstamo original. En ocasiones
                también se pueden pagar dos o más créditos. De esta forma puedes
                hacer tu deuda más cómoda a tu capacidad de pago. Una vez que el
                refinanciamiento es aprobado, el o los préstamos anteriores
                quedan saldados y te quedas sólo con el nuevo crédito.
              </span>
            </div>
            <div className="buttonContainer">
              <ButtonPrimary
                text="Siguente"
                onClick={() => setPage(2)}
                secondaryVersion
              />
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.container} style={adjustDesktop}>
          <div className={classes.firstSection}>
            <div>
              <div className={classes.textSub}>Refinanciamiento de crédito</div>
              <span className={classes.text}>
                El refinanciamiento consiste en solicitar un crédito con nuevas
                condiciones para pagar el préstamo original. En ocasiones
                también se pueden pagar dos o más créditos. De esta forma puedes
                hacer tu deuda más cómoda a tu capacidad de pago. Una vez que el
                refinanciamiento es aprobado, el o los préstamos anteriores
                quedan saldados y te quedas sólo con el nuevo crédito.
              </span>
            </div>
            <div className="buttonContainer">
              <ButtonPrimary
                text="Siguente"
                onClick={() => setPage(2)}
                secondaryVersion
              />
            </div>
          </div>
          <div className={classes.secondSection}>
            <div className="imageContainer">
              <img src={refinanciarCredito_ilustracion} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default M3MainComponent;
