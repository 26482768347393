import { gql, useQuery } from "@apollo/client";
interface Token {
  custToken?: string;
  loading: boolean;
}
export const GET_TOKEN = gql`
  query GetToken {
    getToken {
      custToken
    }
  }
`;

interface QueryResult {
  getToken: {
    custToken?: string;
  };
}

export const getToken = (): Token => {
  const { data, loading } = useQuery<QueryResult>(GET_TOKEN, {
    fetchPolicy: "network-only",
  });
  data?.getToken.custToken &&
    localStorage.setItem("tookenSAVE", data.getToken.custToken);
  return { custToken: data?.getToken.custToken, loading };
};
