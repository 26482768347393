import React from "react";
import { useMediaQuery } from "@material-ui/core";
import useStyles from "./ServiceButton.style";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

interface YouSeekProps {
  name: string | JSX.Element;
  onClick: () => void;
  title?: string | JSX.Element;
  disabled?: boolean;
}
const ServiceButton = ({ name, onClick, title, disabled }: YouSeekProps) => {
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const classes = useStyles();

  return (
    <div
      onClick={onClick}
      className={`${classes.container} ${disabled ? classes.disabled : ""}`}
    >
      <div>
        {title && <span className={classes.title}>{title}</span>}
        <span>{name}</span>
      </div>
      <div>{<ArrowForwardIosIcon />}</div>
    </div>
  );
};
export default ServiceButton;
