import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    "& .mainTitle": {
      width: 675,
      height: 58,
      margin: "15px 76px 11px 0px",
      fontFamily: "Montserrat",
      fontSize: 24,
      fontWeight: "bold",
      textAlign: "left",
      color: "#7d7c7c",
      [theme.breakpoints.down(1240)]: {
        width: 303,
        height: 61,
        margin: "40px auto 12px",
        fontSize: 14,
        lineHeight: 1.43,
      },
    },
    [theme.breakpoints.down(1240)]: {
      textAlign: "center",
    },
  },
  midSection: {
    display: "flex",
    alignItems: "flex-end",
    [theme.breakpoints.down(1240)]: {
      justifyContent: "center",
      textAlign: "center",
    },
  },
  totalContainer: {
    width: 675,
    height: 133,
    margin: "29px 0px 0 0",
    padding: "49px 256.3px 49px 71px",
    borderRadius: "30px",
    boxShadow: "4px 4px 20px 2px rgba(0, 0, 0, 0.2)",
    backgroundColor: "#8352fd",
    "& .totalValue": {
      [theme.breakpoints.down(1240)]: {
        margin: "160px 19px 0 15px",
      },
    },
    "& span": {
      width: 347.7,
      height: 35,
      fontFamily: "Montserrat",
      fontSize: "36px",
      fontWeight: "bold",
      textAlign: "left",
      color: "#ffffff",
      [theme.breakpoints.down(1240)]: {
        color: theme.palette.text.primary,
        width: 268,
        height: 30,
      },
    },
    "& >img": {
      width: 168,
      height: 131,
      margin: "-78px 101px 31px 325px",
      borderRadius: "10px",
      position: "absolute",
      [theme.breakpoints.down(1240)]: {
        width: 300,
        height: 160,
        margin: "0px 0px 27px 0px",
        borderRadius: 15,
        boxShadow: "none",
      },
    },
    [theme.breakpoints.down(1240)]: {
      backgroundColor: "#ffffff",
      width: "19.75rem",
      height: "264px",
      padding: "0.6875rem 0.75rem 2.625rem",
      display: "flex",
      flexDirection: "column",
      textAlign: "left",
      marginTop: "0rem",
    },
  },
  mainIncomeValue: {
    background: "#ffffff",
    fontWeight: "bold",
    color: "#393e44",
  },
  text: {
    width: 381,
    margin: "22px 0px 3px 76px",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: 2,
    textAlign: "left",
    color: "#7d7c7c",
    [theme.breakpoints.down(1380)]: {
      width: "18.5rem",

      wordWrap: "break-word",
    },
    [theme.breakpoints.down(1300)]: {
      width: "17.5rem",
    },
  },
  text2: {
    width: 336,
    maring: "58px auto 62px",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: 2,
    wordWrap: "break-word",
    textAlign: "left",
    color: "#7d7c7c",
  },
  incomesRecap: {
    width: 700,
    margin: "22px 143px 65px 26px",
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down(1240)]: {
      flexDirection: "column",
      width: 301,
      margin: "22px auto 10px",
    },
  },
  accordion: {
    background: "#F4F3F3",
    border: "none",
    width: 301,
    "& .MuiAccordion-root:before": {
      height: "0px",
      diplay: "none",
    },
  },
  incomesContainer: {
    display: "flex",
    flexDirection: "column",
    "& .incomeInput": {
      boxShadow: "4px 4px 8px 2px rgba(0, 0, 0, 0.1)",
      height: 33,
      width: 167.5,
      "& div": {
        height: 33,
      },
    },
    "& .incomeName": {
      textTransform: "uppercase",
      marginTop: 16,
      width: 147,
      height: 22,
      fontFamily: "Montserrat",
      fontSize: 12,
      fontWeight: 400,
      textAlign: "left",
      color: theme.palette.text.secondary,
    },
  },
  accordionIcon: {
    width: 70,
    height: 70,
    margin: "4px 17px 0 0",
    borderRadius: 10,
  },
  details: {
    fontSize: 14,
    textTransform: "uppercase",
    width: 147,
    height: 22,
    fontFamily: "Montserrat",
    fontWeight: 400,
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  summaryRight: {
    display: "flex",
    flexDirection: "column",
  },
  bottomSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    "& >button": {
      marginTop: "1rem",
    },
    "& >span": {
      width: 467,
      height: 48,
      fontFamily: "Montserrat",
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.71,
      textAlign: "left",
      color: "#7d7c7c",
      "& >span": {
        fontWeight: "bold",
        textDecoration: "underline",
      },
    },
    [theme.breakpoints.down(1240)]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  buttonPrimary: {
    width: 184,
    height: 53,
    alignItems: "center",
    padding: "12px 16px",
    borderRadius: 10,
    backgroundColor: "#4619b6",
    boxShadow: "2px 2px 7px 0 rgba(0, 0, 0, 0.3)",
    margin: "0 auto",
    [theme.breakpoints.down(1240)]: {
      display: "flex",
      marginTop: 62,
    },
  },
}));
