import React, { useCallback, useContext, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
// import Button from "@material-ui/core/Button";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
// import { Stepper } from "@components";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
// import { Step3 } from "./Step3";
import "./GoalCreateDialog.scss";
import {
  GoalForm,
  // FormInvestment,
  ProfileDialogProps,
} from "@interfaces";
import {
  // DEFAULT_GOAL_FORM,
  useGoalForm,
} from "@hooks";
import { GoalsContext } from "@context";
import { IconButton } from "@material-ui/core";
import { ButtonPrimary } from "@components/Reusables";

export interface GoalDialogInitialData {
  goalId: number | null;
  goalForm: GoalForm | null;
  step: number | null;
}

interface CreateGoalsDialogProps extends ProfileDialogProps {
  initialDialogData: GoalDialogInitialData;
}

export const CreateGoalsDialog: React.FC<CreateGoalsDialogProps> = ({
  className,
  open,
  onClose,
  initialDialogData,
}) => {
  const [step, setStep] = useState(1);
  const [form, setForm, disableSubmit, handleChange, resetForm, validate] =
    useGoalForm({
      formFields: STEP_REQUIRED[step],
    });
  const { addGoal, updateGoal } = useContext(GoalsContext);

  const nextStep = useCallback(() => {
    setStep(prev => Math.min(prev + 1, 3));
  }, []);

  const closeAndReset = useCallback(() => {
    onClose();
    resetForm();
    setStep(1);
  }, [onClose, resetForm]);

  const createAndFinish = useCallback(() => {
    // Save new goal
    addGoal(form);
    closeAndReset();
  }, [form, addGoal, closeAndReset]);

  // Boolean that indicates if the modal is beeing used to update something
  const singleUseStep = Boolean(initialDialogData.goalId); // && initialDialogData.step === step;
  const updatingName = singleUseStep;

  const updateAndFinish = useCallback(() => {
    if (initialDialogData.goalId) {
      if (initialDialogData.step === 1)
        updateGoal(initialDialogData.goalId, { name: form.name });
      closeAndReset();
    }
  }, [initialDialogData, form.name, updateGoal, closeAndReset]);

  const editGoalName = useCallback(() => {
    setStep(1);
  }, []);

  // Initial Data setting, when modal needs to be used to update something
  // useEffect(() => {
  //   if (initialDialogData.goalId) {
  //     setStep(initialDialogData.step as number);
  //     const investments = initialDialogData.newInvestment
  //       ? DEFAULT_GOAL_FORM.investments
  //       : (initialDialogData.goalForm as GoalForm).investments;
  //     setForm({ ...(initialDialogData.goalForm as GoalForm), investments });
  //   }
  // }, [initialDialogData]);

  const stepTitle =
    step === 1
      ? updatingName
        ? "Cambia el nombre de tu objetivo"
        : "¿Qué objetivo quieres lograr?"
      : step === 2
      ? "Define tu objetivo"
      : "";
  const nextButtonText =
    step === 1
      ? updatingName
        ? "Actualizar"
        : "Continuar"
      : step === 2
      ? "Finalizar"
      : null;
  const nextButtonCallback =
    step === 1
      ? singleUseStep
        ? updateAndFinish
        : nextStep
      : step === 2
      ? singleUseStep
        ? updateAndFinish
        : createAndFinish
      : undefined;

  // Calculate media to display dialog on fullScreen
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (window.matchMedia("(max-width: 1080px)").matches) setIsMobile(true);
  }, []);
  return (
    <Dialog
      maxWidth="xl"
      className={`${className || ""} goals-dialog ${
        !isMobile ? "desktop-mode" : "mobile-mode"
      }`}
      open={open}
      onClose={closeAndReset}
      fullScreen={isMobile}
    >
      <IconButton className="close-button" onClick={closeAndReset}>
        <CloseRoundedIcon />
      </IconButton>
      {/* {step < 4 && <Stepper step={step} maxSteps={3} />} */}
      {step === 1 ? (
        <Step1 {...{ stepTitle, form, handleChange }} />
      ) : step === 2 ? (
        <Step2 {...{ stepTitle, form, handleChange, editGoalName }} />
      ) : null}
      {
        <div className={`buttons-container on-step-${step}`}>
          <ButtonPrimary
            className="next-button"
            text={nextButtonText || ""}
            onClick={nextButtonCallback || (() => null)}
            disabled={disableSubmit}
          />
        </div>
      }
    </Dialog>
  );
};

const STEP_REQUIRED = {
  1: ["name"],
  2: ["investmentFrequency", "goal", "period"],
} as Record<number, string[]>;
