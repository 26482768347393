import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "432px",
    boxShadow: "0.25rem 0.25rem 1.25rem 0.125rem rgba(0, 0, 0, 0.2)",
    margin: "5rem",
    borderRadius: "1.25rem",
    [theme.breakpoints.down(1390)]: {
      margin: "5rem 1rem",
    },
    [theme.breakpoints.down(1240)]: {
      margin: "0rem auto",
      boxShadow: "none",
      width: "100%",
    },
  },
  topSection: {
    display: "flex",
    flexDirection: "row",
    padding: "3rem",
    borderRadius: "1.25rem 1.25rem 0rem 0rem",
    alignItems: "center",
    [theme.breakpoints.down(1240)]: {
      justifyContent: "space-around",
    },
    "& .backArrow": {
      cursor: "pointer",
      [theme.breakpoints.down(1240)]: {
        width: "2.5rem",
      },
    },
    "& .mainIcon": {
      marginLeft: "4rem",
      "& img": {
        height: "7rem",
        [theme.breakpoints.down(1240)]: {
          height: "5rem",
        },
      },
    },
  },
  bottomSection: {
    display: "flex",
    flexDirection: "column",
    padding: "3rem",
    borderRadius: "1.25rem",

    backgroundColor: "#F4F3F3",
    marginTop: "-1.3rem",
    [theme.breakpoints.down(1240)]: {
      borderRadius: "1.25rem 1.25rem 0rem 0rem",
      alignItems: "center",
      padding: "2rem",
      paddingBottom: "0rem",
    },

    "& .title": {
      fontSize: "2rem",
      fontWeight: "bold",
      textAlign: "left",
      color: theme.palette.text.primary,
      lineHeight: 1.38,
      fontFamily: "Montserrat",
      [theme.breakpoints.down(1240)]: {
        fontSize: "1.5rem",
      },
    },
    "& .addElement": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginTop: "1rem",
      "& .icon": {
        marginRight: "1rem",
        "& img": {
          width: "2rem",
          height: "2rem",
        },
      },
      "& .text": {
        fontSize: "0.85rem",
        fontWeight: "normal",
        textAlign: "left",
        color: theme.palette.text.primary,
      },
    },
  },
  expense: {
    backgroundColor: "#4619b6",
  },
  income: {
    backgroundColor: "#0a5669",
  },
  reminder: {
    backgroundColor: "#8352fd",
  },
}));
