import { useMediaQuery } from "@material-ui/core";
import {
  LayoutMissionDesktop,
  LayoutMissionMobile,
  M2ApvStep1,
  M2ApvStep2,
  M2ApvStep3,
} from "@components";
import React, { useState } from "react";

const MainComponentM2ApvRetirement = () => {
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const isMobileBig = useMediaQuery("(max-width: 1300px)");

  const [page, setPage] = useState(1);

  return (
    <div>
      {page === 1 ? (
        <>
          {!isMobile ? (
            <LayoutMissionDesktop title={"¿Qué es un APV?"} number={2}>
              <M2ApvStep1 setPage={setPage} />
            </LayoutMissionDesktop>
          ) : (
            <LayoutMissionMobile title={"¿Qué es un APV?"} number={3}>
              <M2ApvStep1 setPage={setPage} />
            </LayoutMissionMobile>
          )}
        </>
      ) : page === 2 ? (
        <>
          {!isMobileBig ? (
            <LayoutMissionDesktop
              title={"¿Qué es un APV?"}
              number={2}
              secondLayoutForShortcuts
              setPage={setPage}
              pageNumber={1}
            >
              <M2ApvStep2 setPage={setPage} />
            </LayoutMissionDesktop>
          ) : (
            <LayoutMissionMobile
              title={"¿Qué es un APV?"}
              number={3}
              secondLayoutForShortcuts
              setPage={setPage}
              pageNumber={1}
            >
              <M2ApvStep2 setPage={setPage} />
            </LayoutMissionMobile>
          )}
        </>
      ) : (
        page === 3 && (
          <>
            {!isMobileBig ? (
              <LayoutMissionDesktop
                title={"¿Qué es un APV?"}
                number={2}
                secondLayoutForShortcuts
                setPage={setPage}
                pageNumber={2}
              >
                <M2ApvStep3 />
              </LayoutMissionDesktop>
            ) : (
              <LayoutMissionMobile
                title={"¿Qué es un APV?"}
                number={3}
                secondLayoutForShortcuts
                setPage={setPage}
                pageNumber={2}
              >
                <M2ApvStep3 />
              </LayoutMissionMobile>
            )}
          </>
        )
      )}
    </div>
  );
};

export default MainComponentM2ApvRetirement;
