import React, { createContext, useCallback, useMemo, useState } from "react";
import { FullLoader } from "@components";
import { Route } from "@interfaces";
import { getFinerioAccounts, getFinerioTransactions } from "@apollo";
import { useAuth } from "@hooks";

interface LoadingContextType {
  loading: boolean;
  showLoader: (name: string, paths?: Route[]) => void;
  hideLoader: (name: string) => void;
}

export const LoadingContext = createContext({} as LoadingContextType);

export const LoadingProvider: React.FC = ({ children }) => {
  const [loaders, setLoaders] = useState<{ name: string; paths?: Route[] }[]>(
    []
  );

  const { finerioAccountsLoading } = getFinerioAccounts();
  const { finerioTransactionsLoading } = getFinerioTransactions(); 

  const loading = useCallback(() => {
    return finerioAccountsLoading && finerioTransactionsLoading
  }, [finerioAccountsLoading])


  const showLoader = useCallback((name: string, paths?: Route[]) => {
    setLoaders((prev) => [...prev, { name, paths }]);
  }, []);

  const hideLoader = useCallback((name) => {
    setLoaders((prev) => prev.filter((f) => f.name !== name));
  }, []);

  const context = useMemo(
    () => ({
      loading: loading(),
      showLoader,
      hideLoader,
    }),
    [loading(), loaders, showLoader, hideLoader]
  );

  // console.log(
  //   `[LOADER] Loader is ${loading()}`,
  //   { loaders }
  // );

  return (
    <LoadingContext.Provider value={context}>
      <>
        {children}
        <FullLoader open={useAuth().isLoggedIn? loading() : false} />
      </>
    </LoadingContext.Provider>
  );
};
