import React, { Dispatch, SetStateAction, useState } from "react";
import { Route } from "@interfaces";
import { useStyles } from "./cards.style";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { EventsDeleteModal } from "@components";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useApolloClient } from "@apollo/client";
import {
  EventTypesConstants,
  EventRecurrencesConstants,
  EventTypesConstantsEnum,
  EventRecurrencesConstantsEnum,
  EventCategoriesConstants,
  EventCategoriesConstantsEnum,
} from "../../../../../../constants";
import { updateEventMutation } from "@apollo";
import { navigate } from "gatsby";
// import {
//   EventTypesConstants,
//   EventRecurrencesConstants,
//   EventTypesConstantsEnum,
//   EventRecurrencesConstantsEnum,
//   EventCategoriesConstants,
//   EventCategoriesConstantsEnum,
// } from "@constants";

type Props = {
  type: string;
  name: string;
  date: string;
  nextDate: string;
  amount: number;
  description: string;
  category: number;
  recurrence: number;
  showInCalendar: boolean;
  url: string;
  id: number;
  setCurrentView: Dispatch<SetStateAction<string>>;

  setEdit: Dispatch<SetStateAction<boolean>>;
  setEditingData: Dispatch<
    SetStateAction<{
      id: number;
      showInCalendar: boolean;
      name: string;
      date: string;
      nextDate: string;
      amount: number;
      type: number;
      category: number;
      recurrence: number;
      description: string;
      url: string;
    }>
  >;
  togglePage: () => void;
};

const EventCard = ({
  id,
  type,
  name,
  date,
  nextDate,
  description,
  amount,
  category,
  recurrence,
  url,
  showInCalendar,
  setEdit,
  setEditingData,
  setCurrentView,
  togglePage,
}: Props) => {
  const classes = useStyles();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [show, setShow] = React.useState(showInCalendar);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const setType = () => {
    switch (type) {
      case "expense":
        return 1;
      case "income":
        return 2;
      case "reminder":
        return 3;
      default:
        return 2;
    }
  };

  const setRedirect = () => {
    switch (type) {
      case "expense":
        return "createPayment";
      case "income":
        return "createIncome";
      case "reminder":
        return "createReminder";
      default:
        return "createReminder";
    }
  };
  const updateEditingData = () => {
    setEdit(true);
    setEditingData({
      type: setType(),
      id,
      showInCalendar,
      name,
      date,
      nextDate,
      amount,
      category,
      recurrence,
      description,
      url,
    });
    setCurrentView(setRedirect());
  };

  const setCategory = (category: number) => {
    // find the id in the EventCategoriesConstantsEnum enum and return the name
    return EventCategoriesConstantsEnum[category];
  };
  const setRecurrence = (recurrence: number) => {
    return EventRecurrencesConstantsEnum[recurrence];
  };
  const client = useApolloClient();

  const updateEvent = async (id: number) => {
    const data = {
      showInCalendar: !showInCalendar,
    };
    setShow(!show);
    const res = await updateEventMutation(client, id, data);
  };
  return (
    <div className={classes.cardContiner}>
      <div className={classes.whiteBox}>
        <div className={classes.CardTopSection}>
          <div className="title">{name}</div>
          <IconButton onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem onClick={() => updateEditingData()}>Editar</MenuItem>
            <MenuItem onClick={() => setOpenDeleteModal(true)}>
              Eliminar
            </MenuItem>
          </Menu>
        </div>
        <EventsDeleteModal
          openModal={openDeleteModal}
          setOpenModal={setOpenDeleteModal}
          id={id}
          name={name}
          type={type}
          setCurrentView={setCurrentView}
        />
        {type === "expense" && (
          <div className={classes.cardMiddleSection}>
            <div className={classes.element}>
              <div className="label">Categoría</div>
              <div className="value">{setCategory(category)}</div>
            </div>
            <div className={classes.element}>
              <div className="label">próxima fecha de pago</div>
              <div className="value">{nextDate}</div>
            </div>
            <div className={classes.element}>
              <div className="label">monto</div>
              <div className="value">$ {amount.toLocaleString("es")}</div>
            </div>
            <div className={classes.element}>
              <div className="label">recurrencia</div>
              <div className="value">{setRecurrence(recurrence)}</div>
            </div>
          </div>
        )}
        {type === "income" && (
          <div className={classes.cardMiddleSection}>
            <div className={classes.element}>
              <div className="label">Tipo de ingreso</div>
              <div className="value">{setCategory(category)}</div>
            </div>
            <div className={classes.element}>
              <div className="label">próxima fecha de pago</div>
              <div className="value">{nextDate}</div>
            </div>
            <div className={classes.element}>
              <div className="label">monto</div>
              <div className="value">$ {amount.toLocaleString("es")}</div>
            </div>
            <div className={classes.element}>
              <div className="label">recurrencia</div>
              <div className="value">{setRecurrence(recurrence)}</div>
            </div>
          </div>
        )}
        {type === "reminder" && (
          <div className={classes.reminderSection}>
            <div className={classes.element}>
              <div className="label">próxima fecha</div>
              <div className="value">{nextDate}</div>
            </div>
            <div className={classes.element}>
              <div className="label">enlace</div>
              <div className="value">{url}</div>
            </div>
          </div>
        )}
        {type === "expense" && (
          <div className={classes.element}>
            <div className="label">Enlace de pago</div>
            <div className="value">{url}</div>
          </div>
        )}
        <div className={classes.cardBottomSection}>
          <IconButton onClick={() => [updateEvent(id)]}>
            {show ? <Visibility /> : <VisibilityOff />}
          </IconButton>
          <span className="iconText">Ver en calendario</span>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
