import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {},
  columnsContainer: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down(1300)]: {
      flexDirection: "column",
    },
  },
  title: {
    fontFamily: "Montserrat",
    fontSize: "2rem",
    fontWeight: "bold",
    marginLeft: "3.4rem",
    color: "#8352fd",
    [theme.breakpoints.down(1300)]: {
      marginLeft: "1.4rem",
    },
  },
  column: {
    display: "flex",
    flexDirection: "column",
    "& .whiteBox": {
      width: "30.813rem",
      height: "32rem",
      margin: "1.186rem 2.28rem 0 0",
      padding: "2.125rem 3.65rem ",
      borderRadius: "1.25rem",
      fontFamily: "Montserrat",
      boxShadow: "0.25rem 0.25rem 0.5rem 0.125rem rgba(0,0,0,0.2)",
      backgroundColor: theme.palette.background.paper,
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down(1300)]: {
        width: "auto",
        margin: "2rem 0",
        height: "auto",
      },
      "& img": {
        alignSelf: "center",
        width: "3.5rem",
        height: "3.5rem",
      },
      "& .title": {
        fontSize: "1.25rem",
        fontWeight: "bold",
        lineHeight: 1.5,
        textAlign: "center",
        textTransform: "uppercase",
        color: theme.palette.text.primary,
      },
      "& .text": {
        fontSize: "0.85rem",
        fontWeight: 600,
        lineHeight: 2.14,
        textAlign: "left",
        color: theme.palette.text.secondary,
        [theme.breakpoints.down(1300)]: {
          textAlign: "center",
        },
      },
    },
  },
  bottomSection: {
    textAlign: "right",
    margin: "7.186rem 2.28rem 7rem 0",
    [theme.breakpoints.down(1300)]: {
      textAlign: "center",
      margin: "3rem auto",
    },
  },
}));
