import { gql, useQuery } from "@apollo/client";
import { CoolebraData, CoolebraFiltersTypes } from "@interfaces";

export const GET_COOLEBRA_DATA = gql`
  query GetCoolebraData($filters: CoolebraDataFilters) {
    getCoolebraData(filters: $filters) {
      data {
        id
        title
        image
        description
        url
        week_days
        date_end
        category
        discount {
          condition
          type
          value
        }
        emitter {
          logo
          name
        }
        store {
          image
          logo
          name
        }   
        interm {
          image
          logo
          name
        }  
      }
    }
  }
`;

interface QueryResult {
  getCoolebraData: CoolebraData;
}

interface HookReturn {
  coolebraData: CoolebraData;
  coolebraDataLoading: boolean;
  refetchCoolebraData: () => void;
}

export const getCoolebraData = (filters?: CoolebraFiltersTypes): HookReturn => {
  const { data, loading, refetch } = useQuery<QueryResult>(
    GET_COOLEBRA_DATA,
    {
      variables: {filters}
    }
    );
    
  return {
    coolebraData: data?.getCoolebraData.data ?? [],
    coolebraDataLoading: loading,
    refetchCoolebraData: refetch,
  };
};
