export enum Route {
  investorProfile = 'perfil-inversionista',
  ownInvestmentDetails = 'inversion',
  ownInvestmentMovements = '/movimientinversionos',
  suggestedPortfolioFundDetails = '/perfil-inversionista/portafolio-sugerido/detalle',
  preselectionsSuggested = '/perfil-inversionista/portafolio-sugerido/preseleccion',
  producTour = '/productTour',
  index = '/',
  impacted = '/',
  business = '/business',
  helpCenter = '/helpCenter',
  //CATEGORY
  budget = '/rokipedia/category/budget',
  completeProfile = '/rokipedia/category/completeProfile',
  creditsDebts = '/rokipedia/category/creditsDebts',
  retirement = '/rokipedia/category/retirement',
  savingsAndInvestment = '/rokipedia/category/savingsAndInvestment',

  //GOAL
  createGoal = '/goals/createGoal',
  editGoal = '/goals/editGoal',
  slideGoal = '/goals',

  //REGISTRATION
  register = '/registration', //"/registration",
  // REGISTRATION BY ONBOARGING MAIL
  registerByOnboarding = '/registrationMailing',

  //FOUND
  deposit = '/found',
  successfulWithdraw = '/found/successfulWithdrawal',

  withdraw = '/found/successfulWithdrawal',

  //Profile
  profile = '/profile',
  UserInformation = '/profile/userInformation',

  //MISSIONS

  mision1 = '/misiones/mision1',
  mision2 = '/misiones/mision2',
  mision3 = '/misiones/mision3',
  mision4 = '/misiones/mision4',
  mision5 = '/misiones/mision5',
  mision6 = '/misiones/mision6',
  mision7 = '/misiones/mision7',
  mision8 = '/misiones/mision8',

  //Budget
  //mission1
  BudgetMission1 = '/missions/budget/mission1',
  //mission2
  BudgetMission2 = '/missions/budget/mission2/',
  BudgetMission2b = '/missions/budget/mission2/mission2b',
  BudgetMission2c = '/missions/budget/mission2/mission2c',

  budget2_1 = '/missions/budget/mission2/',
  budget2_2 = '/missions/budget/mission2/mission2b',
  budget2_3 = '/missions/budget/mission2/mission2c',
  //mission3
  BudgetMission3 = '/missions/budget/mission3',
  //mission4
  BudgetMission4 = '/missions/budget/mission4',
  BudgetMission4b = '/missions/budget/mission4/mission4b',

  presupuestoMision4_2 = '/missions/budget/mission4/mission4b',
  presupuestoMision4 = '/missions/budget/mission4',

  budgetMission5 = '/missions/budget/mission5',

  //Profile
  //mission1
  profileMission1 = '/missions/profile/M1PersonalInfo',
  profileMission2 = '/missions/profile/M2MoreInfo',
  profileMission3 = '/missions/profile/M3ConfirmIdentity',
  profileMission4 = '/missions/profile/M4InvestorProfile',
  profileMission5 = '/missions/profile/M5Goal',
  profileMission6 = '/missions/profile/M6Pack',

  //Budget
  //mission1
  budgetMission1 = '/missions/budget/mission1',
  budgetMission2 = '/missions/budget/mission2',
  budgetMission3 = '/missions/budget/mission3',
  budgetMission4 = '/missions/budget/mission4',

  // Loans And Debts
  //mission A
  loansAndDebtsMissionA1 = '/missions/loans-debt/mission1A',
  loansAndDebtsMissionA2 = '/missions/loans-debt/mission2A',
  loansAndDebtsMission4A = '/missions/loans-debt/mission4A',
  loansAndDebtsMissionA5 = '/missions/loans-debt/mission5',

  //mission B
  loansAndDebtsMissionB1 = '/missions/loans-debt/mission1B',
  loansAndDebtsMissionB2 = '/missions/loans-debt/mission2B',
  loansAndDebtsMissionB4 = '/missions/loans-debt/mission4',
  loansAndDebtsMission3 = '/missions/loans-debt/mission3',

  // SAVINGS AND INVESTMENT
  savingsAndInvestment1A = '/missions/saving-investment/mission1A',
  savingsAndInvestment1B = '/missions/saving-investment/mission1B',
  savingsAndInvestment3B = '/missions/saving-investment/mission3B',
  savingsAndInvestment3A = '/missions/saving-investment/mission3A',
  //RETIREMENT
  //mission1
  retirementMission1 = '/missions/retirement',

  //FINANCIAL PORTAL
  financialPortal = '/myFinances',
  habitsDetails = '/myFinances/habitsDetails/',
  accountDetails = '/myFinances/accountDetails/',

  // MIS NUEVAS FINANZAS
  calendar = '/calendar',

  //PRINCIPAL PAGES
  rokipedia = '/rokipedia',
  ourSolutions = '/ourSolutions',
  aboutUs = '/aboutUs',
  finance = '/myFinances',
  login = '/login',
  investments = '/Investments',
  benefit = '/benefits',
  onBoard = '/rokipedia',

  //TOOLS
  toolsRetirementMission1 = '/tools/retirement',
  toolsCreditsAndDebtsMission1 = '/tools/creditsAndDebts/step1/creditSimulator',
  toolsSavingAndInvestMission1 = '/tools/creditsAndDebts/step3/profileInvestTest',
  toolsCalculatorMission1 = '/tools/creditsAndDebts/step3',
  toolsCalculatorSteps = '/tools/budgetCalculator',
  toolsCreditTrasnlator = '/tools/creditTranslator',
  toolsDeposit = '/tools/termDepositSimulator/',
  toolsRefinanceCredit = '/tools/refinance/refinanceCredit',
  recategorizeMovement = '/financialPortal/movements/recategorizeMovement',

  //Services
  services = '/services/',
  hiredServices = '/services/hiredServices',
  security = '/services/security',
  credits = '/services/credits',
  refinance = '/services/refinance',
  recurringPayments = '/services/recurringPayments',
  advisory = '/services/advisory',
  addMovement = '/myAccounts/movements/addMovement',
  myAccounts = '/myAccounts/',
  editMovement = '/myFinances/movements/editMovement',
  servicesInvestments = '/services/Investments',
  coolebra = '/rokipedia/coolebra',

  investSandP = '/S&P/',

  // smartSave
  dashboardSmartSave = '/smartSave/dashboard/',
  createNewRule = '/smartSave/create/newRule/',
  editRuleSmartSave = '/smartSave/edit/',
  smartSaveDetails = '/smartSave/details/',
}

export const translateRoutes = (routes: Route[]): string[] =>
  routes.map((r) => {
    switch (r) {
      default:
        return 'none';
    }
  });
