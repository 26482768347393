// change mail for RUT
export const searchUserCompany = (email: string, companies) => {
  const company = companies.filter((obj) =>
    obj["employeesList"].includes(email)
  ); // <= change email for RUT
  const hasCompany = company.length > 0;

  if (company.length > 0) {
    return company[0];
  } else {
    return false;
  }
};
