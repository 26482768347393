const COOLEBRA_HEADER =
  "https://storage.googleapis.com/rokinapp-images/rokipedia/Coolebra/coolebra_header.png";
const COOLEBRA_LOGO = 
  "https://storage.googleapis.com/rokinapp-images/rokipedia/Coolebra/logo.svg";
const COOLEBRA_LOGO_WHITE =
  "https://storage.googleapis.com/rokinapp-images/rokipedia/Coolebra/Logo_Coolebra_blanco.svg";
const COOLEBRA_DESTACADOS_ICON =
  "https://storage.googleapis.com/rokinapp-images/rokipedia/Coolebra/destacados_icon.svg";
const COOLEBRA_FILTRO_ICON =
  "https://storage.googleapis.com/rokinapp-images/rokipedia/Coolebra/filtro_icon.svg";
const COOLEBRA_FILTRO_ICON_WHITE =
  "https://storage.googleapis.com/rokinapp-images/rokipedia/Coolebra/filter_icon_white.svg";
const COOLEBRA_PANORAMAS_ICON =
  "https://storage.googleapis.com/rokinapp-images/rokipedia/Coolebra/panoramas_icon.svg";
const COOLEBRA_PROMOCIONES_ICON =
  "https://storage.googleapis.com/rokinapp-images/rokipedia/Coolebra/promociones_icon.svg";
const COOLEBRA_SABORES_ICON =
  "https://storage.googleapis.com/rokinapp-images/rokipedia/Coolebra/sabores_icon.svg";
const COOLEBRA_SALUD_BELLEZA_ICON =
  "https://storage.googleapis.com/rokinapp-images/rokipedia/Coolebra/saludYBelleza_icon.svg";
const COOLEBRA_SERVICIOS_ICON =
  "https://storage.googleapis.com/rokinapp-images/rokipedia/Coolebra/servicios_icon.svg";
const COOLEBRA_SHOPPING_ICON =
  "https://storage.googleapis.com/rokinapp-images/rokipedia/Coolebra/shopping_icon.svg";
const COOLEBRA_VIAJES_ICON =
  "https://storage.googleapis.com/rokinapp-images/rokipedia/Coolebra/viajes_icon.svg";
const COOLEBRA_IMAGE =
  "https://storage.googleapis.com/rokinapp-images/rokipedia/Coolebra/Imagen_coolebra.png";

export {
  COOLEBRA_HEADER,
  COOLEBRA_DESTACADOS_ICON,
  COOLEBRA_FILTRO_ICON,
  COOLEBRA_PANORAMAS_ICON,
  COOLEBRA_PROMOCIONES_ICON,
  COOLEBRA_SABORES_ICON,
  COOLEBRA_SALUD_BELLEZA_ICON,
  COOLEBRA_SERVICIOS_ICON,
  COOLEBRA_SHOPPING_ICON,
  COOLEBRA_VIAJES_ICON,
  COOLEBRA_FILTRO_ICON_WHITE,
  COOLEBRA_LOGO,
  COOLEBRA_LOGO_WHITE,
  COOLEBRA_IMAGE,
};
