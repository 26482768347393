import React from "react";
import { useMediaQuery } from "@material-ui/core";
import { useStyles } from "./BudgetCalculator.style";
import {
  FlechaBudget,
  IngresoBudget,
  GastoBudget,
  StartBudget,
  ingresoPromedio,
  averageSpending,
  budgetIlustration,
} from "@images";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import {
  ButtonPrimary,
  MoneyBreakdown,
  BudgetRecommendations,
} from "@components";

ChartJS.register(ArcElement, Tooltip, Legend);

interface BudgetCalculatorProps {
  handleClick: () => void;
  data: JSON;
  step: number;
  setStep: () => void;
  isTool: boolean;
}

export const BudgetCalculator: React.FC<BudgetCalculatorProps> = ({
  handleClick,
  data,
  step,
  setStep,
  isTool,
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 1280px)");

  if (step == 0) {
    return (
      <>
        <section className={classes.container}>
          <div className={classes.form}>
            <div className={classes.incomeSpent}>
              <div className={classes.income}>
                <div className={classes.text}>Tus ingresos totales</div>
                <div className={classes.amount}>
                  <img src={IngresoBudget} />${" "}
                  {data.income?.total.toLocaleString("es")}
                  <img className={classes.img} src={ingresoPromedio} />
                </div>
                <div className={classes.fixedVariable}>
                  <div>
                    INGRESO FIJO
                    <div className={classes.amountArrow}>
                      <div className={classes.amount2}>
                        $ {data.income?.fixedIncome.total.toLocaleString("es")}
                      </div>
                      <img src={FlechaBudget} />
                    </div>
                  </div>
                  <div>
                    INGRESO VARIABLE
                    <div className={classes.amountArrow}>
                      <div className={classes.amount2}>
                        ${" "}
                        {data.income?.variableIncome.total.toLocaleString("es")}
                      </div>
                      <img src={FlechaBudget} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.income}>
                <div className={classes.text}>Tus gastos totales</div>
                <div className={classes.amount}>
                  <img src={GastoBudget} />${" "}
                  {data.expenses?.total.toLocaleString("es")}
                  <img className={classes.img} src={averageSpending} />
                </div>
                <div className={classes.fixedVariable}>
                  <div>
                    GASTO FIJO
                    <div className={classes.amountArrow}>
                      <div className={classes.amount2}>
                        $ {data.expenses?.fixed.total.toLocaleString("es")}
                      </div>
                      <img src={FlechaBudget} />
                    </div>
                  </div>
                  <div>
                    GASTO VARIABLE
                    <div className={classes.amountArrow}>
                      <div className={classes.amount2}>
                        $ {data.expenses?.variable.total.toLocaleString("es")}
                      </div>
                      <img src={FlechaBudget} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.box}>
              <div className={classes.button} onClick={() => setStep(1)}>
                <img src={StartBudget} alt="" />
                Calcular presupuesto
              </div>
            </div>
          </div>
        </section>
      </>
    );
  } else if (step == 1) {
    return (
      <>
        <section className={classes.container}>
          <div className={classes.form}>
            <div className={classes.inputs}>
              <div className={classes.informacion}>
                <div className={classes.titulo}>MONTO MENSUAL DISPONIBLE:</div>
                <div className={classes.info1}>
                  <div className={classes.presupuesto}>
                    ${" "}
                    {(data.income?.total - data.expenses?.total).toLocaleString(
                      "es"
                    )}
                  </div>
                  <div className={classes.imagen}>
                    <img src={budgetIlustration} alt="" />
                  </div>
                </div>
                <div className={classes.breakdown}>
                  <BudgetRecommendations data={data} />
                  {isMobile && <MoneyBreakdown data={data} />}
                </div>
                {!isMobile && (
                  <div className={classes.savingBox}>
                    {isTool ? (
                      <ButtonPrimary
                        onClick={() =>
                          handleClick(data.income?.total - data.expenses?.total)
                        }
                        className={classes.boton}
                        text={"Guardar y volver a Mis Finanzas"}
                      />
                    ) : (
                      <ButtonPrimary
                        onClick={() =>
                          handleClick(data.income?.total - data.expenses?.total)
                        }
                        className={classes.boton}
                        text={"Terminar misión"}
                        secondaryVersion
                      />
                    )}
                  </div>
                )}
              </div>
              {!isMobile && <MoneyBreakdown data={data} />}
            </div>
            {isMobile && (
              <div
                className={`${classes.savingBox} ${classes.savingBoxMobile}`}
              >
                {isTool ? (
                  <ButtonPrimary
                    onClick={() =>
                      handleClick(data.income?.total - data.expenses?.total)
                    }
                    className={classes.boton}
                    text={"Guardar y volver a Mis Finanzas"}
                  />
                ) : (
                  <ButtonPrimary
                    onClick={() =>
                      handleClick(data.income?.total - data.expenses?.total)
                    }
                    className={classes.boton}
                    text={"Terminar misión"}
                    secondaryVersion
                  />
                )}{" "}
              </div>
            )}
            <div className={classes.bottom} />
          </div>
        </section>
      </>
    );
  }
};
