import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    margin: "20px auto 10px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-evenly",
    maxWidth: 1140,
    [theme.breakpoints.down(1241)]: {
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      marginBottom: "10px",
      justifyContent: "space-evenly",
      flexDirection: "column",
      alignContent: "center",
    },
    "& .textContainer": {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      fontFamily: "Montserrat",
      color: theme.palette.text.secondary,
      [theme.breakpoints.down(1241)]: {
        order: 2,
      },
    },
  },
  colorGray: {
    color:theme.palette.text.secondary,
  },
  mainText: {
    // width: 573,
    // height: 148,
    // margin: "19 67 44 50",
    fontSize: 16,
    wordWrap: "break-word",
    maxWidth: "45rem",
    [theme.breakpoints.down(1241)]: {
      width: "18.5rem",
      height: "8.375rem",
      margin: "0px auto", // top left-right bottom
      fontSize: 12,
    },
  },
  textStyle: {
    fontWeight: "bold",
  },
  examplesBox: {
    marginLeft: 20,
    // marginRight: 20,
    display: "grid",
    [theme.breakpoints.down(1241)]: {
      order: 1,
      marginTop: 10,
    },
    "& >span": {
      textAlign: "left",
      // width: 334,
      // height: 17,
      margin: "0 0 16px 6px",
      fontFamily: "Montserrat",
      fontSize: 14,
      fontWeight: "bold",
      color:theme.palette.text.secondary,
    },
  },
  examples: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down(1241)]: {
      justifyContent: "space-between",
    },
    "& .example": {
      width: 86,
      height: 104,
      boxShadow: "4px 4px 20px 2px rgba(0, 0, 0, 0.2)",
      background: "white",
      margin: "10px 10px 10px 10px",
      textAlign: "center",
      borderRadius: 20,
      "& .ExampleIncomeTag": {
        color: theme.palette.text.secondary,
        fontWeight: 500,
      },
      [theme.breakpoints.down(1241)]: {
        width: 86,
        background: "none",
        height: 81,
        margin: "0",
        boxShadow: "none",
      },
      "& img": {
        width: 50,
        height: 50,
        marginTop: "10px",
        marginBottom: 10,
        marginLeft: "auto",
        display: "block",
        marginRight: "auto",
        borderRadius: 10,
        [theme.breakpoints.down(1241)]: {
          width: 47,
          height: 47,
        },
      },
    },
  },
}));
