import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: "6rem",
    [theme.breakpoints.down(1241)]: {
      marginLeft: "0rem",
    },
  },
  firstSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "6rem",
    [theme.breakpoints.down(1240)]: {
      justifyContent: "center",
    },
    "& .video": {
      width: "52rem",
      height: "25.5rem",
      "& iframe": {
        width: "-webkit-fill-available",
        height: "-webkit-fill-available",
      },
      [theme.breakpoints.down(1241)]: {
        width: "100vw",
        margin: "0 1rem",
        maxWidth: "52rem",
        maxHeight: "25.5rem",
        height: "50vw",
        "& iframe": {
          height: "100%",
          width: "100%",
        },
      },
    },
  },
  middleSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "8rem",
    justifyContent: "space-between",
    marginTop: "3rem",
    [theme.breakpoints.down(1241)]: {
      flexDirection: "column",
      height: "auto",
      alignItems: "center",
      margin: "0rem auto",
      maxWidth: "52rem",
    },

    "& .info1": {
      width: "17.625rem",
      height: "100%",

      [theme.breakpoints.down(1241)]: {
        width: "100%",
        height: "auto",
      },
    },
    "& .info2": {
      width: "32.375rem",
      height: "100%",

      [theme.breakpoints.down(1241)]: {
        width: "100%",
        height: "auto",
      },
    },
  },
  lastSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down(1241)]: {
      maxWidth: "52rem",
      margin: "0rem auto",
    },

    "& .info3": {
      width: "52.25rem",
      height: "auto",
      margin: "3rem auto 0rem",
      [theme.breakpoints.down(1241)]: {
        width: "100%",
        margin: "3rem 0rem 0rem",
      },
    },
  },

  whiteBox: {
    padding: "1rem",
    borderRadius: "1.25rem",
    boxShadow: "0.25rem 0.25rem 1.25rem 0.125rem rgba(0, 0, 0, 0.2)",
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down(1241)]: {
      marginTop: "3rem",
    },
    "& .texasContainer": {
      display: "flex",
      "& .icon": {
        width: "3.5rem",
        marginRight: "1rem",
        minWidth: "3.5rem",
        [theme.breakpoints.down(1241)]: {
          width: "2.5rem",
          minWidth: "2.5rem",
        },
      },
      "& .rightSide": {
        display: "flex",
        flexDirection: "column",
        color: theme.palette.text.secondary,

        "& .title": {
          fontFamily: "Montserrat",
          fontSize: "0.875rem",
          fontWeight: "bold",
          lineHeight: 1.71,
          textAlign: "left",
        },
        "& .text": {
          fontSize: "0.875rem",
          lineHeight: 1.71,
          textAlign: "left",
          [theme.breakpoints.down(1240)]: {
            fontSize: "0.75rem",
          },
        },
      },
    },
  },

  topSection: {
    display: "flex",
    flexDirection: "row",
  },
  bottomSection: {
    textAlign: "right",
    width: "50rem",
    margin: "5rem auto 10rem",
    "& .centerButton": {
      textAlign: "center",
      marginTop: "15rem",
      marginBottom: "-4rem",
      [theme.breakpoints.down(1241)]: {
        marginTop: "5rem",
      },
    },
    [theme.breakpoints.down(1241)]: {
      width: "auto",
    },
  },
}));
