import { Button } from "@components/Reusables";
import React, { useState } from "react";
import { useCallback } from "react";
import { makeStyles } from "@material-ui/core";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {
  className?: string;
  title: string;
  description?: string;
  buttonText: string;
  files: string[];
}

export const FileInput: React.FC<Props> = ({
  className,
  title,
  description,
  buttonText,
  files,
}) => {
  // const [_files, setFiles] = useState();
  // const onFileLoaded = useCallback(e => {
  //   console.log(e.target.value);
  //   console.log(e.target.files);
  // }, []);
  const classes = useStyles();

  return (
    <div className={`${className || ""} file-input ${classes.fileInput}`}>
      <div className={classes.fileInputTitle}>{title}</div>
      {description && (
        <div className={`${classes.fileInputDescription}`}>{description}</div>
      )}
      {files.map(file => (
        <div key={file} className={`${classes.fileInputFilename}`}>
          {file}
        </div>
      ))}
      <Button
        className={`${classes.fileInputButton}`}
        text={buttonText}
        onClick={() => null}
      />
      {/* <input type="file" onChange={onFileLoaded} multiple={true}></input> */}
    </div>
  );
};
const useStyles = makeStyles(
  theme => ({
    fileInput: {
      display: "flex",
      flexDirection: "column",
      fontFamily: "Montserrat, sans-serif",
      color: "rgba(0, 0, 0, 0.92)",
      height: "100%",
    },
    fileInputButton: {
      marginTop: "auto",
      height: 34,
      [theme.breakpoints.down(770)]: {
        maxWidth: 300,
      },
    },
    fileInputTitle: {
      fontSize: 16,
      fontWeight: 600,
      marginBottom: 15,
    },
    fileInputDescription: {
      fontSize: 14,
      marginBottom: 15,
    },
    fileInputFilename: {
      fontSize: 14,
      "&:last-of-type": {
        marginBottom: 30,
      },
    },
  }),
  { index: 1 },
);
