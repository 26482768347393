const SLIDE_1 =
  'https://storage.googleapis.com/rokinapp-images/onBording/registrationMailing/slide1.svg';
const SLIDE_2 =
  'https://storage.googleapis.com/rokinapp-images/onBording/registrationMailing/slide%202.svg';
const SLIDE_3 =
  'https://storage.googleapis.com/rokinapp-images/onBording/registrationMailing/slide%203.svg';
const APPLE_ICON =
  ' https://storage.googleapis.com/rokinapp-images/login/apple_icon.svg';

export { SLIDE_1, SLIDE_2, SLIDE_3, APPLE_ICON };
