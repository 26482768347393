import React from "react";
import { makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import { finance } from "@images";

interface cardProps {
  title: string;
  reward: string;
  count: number;
  disable?: boolean;
}

const CardMissionsBuisiness = ({
  title,
  reward,
  count,
  disable,
}: cardProps) => {
  const classes = useStyles({ count });
  const isMobile = useMediaQuery("(max-width: 1280px)");

  return (
    <div className={classes.dcont}>
      <section
        className={`${classes.container} ${disable && classes.disable} disable`}
      >
        <img src={finance} />
        <div>
          <div className={classes.title}>{title}</div>
          <div className={classes.reward}>
            <strong>Recompensa:</strong> {reward}
          </div>
          {!isMobile && (
            <>
              <div className={classes.box}>
                <div className={classes.line}></div>
              </div>
              {count !== 0 && (
                <span className={classes.count}>
                  {count} millones en ventas
                </span>
              )}
            </>
          )}
        </div>
      </section>
      {isMobile && (
        <div className={classes.lineBox}>
          <div className={classes.box}>
            <div className={classes.line}></div>
          </div>
          {count !== 0 && (
            <span className={classes.count}>{count} millones en ventas</span>
          )}
        </div>
      )}
    </div>
  );
};

export default CardMissionsBuisiness;

const useStyles = makeStyles<Theme, { count: number }>((theme) => ({
  dcont: {
    position: "relative",
  },
  container: {
    marginTop: "2rem",
    width: 1200,
    height: 210,
    margin: "0 auto",
    borderRadius: 20,
    boxShadow: "8px 8px 20px 4px rgba(0,0,0,0.2)",
    display: "flex",
    color: theme.palette.text.primary,
    padding: "0px 20px",
    background: "white",
    position: "relative",
    [theme.breakpoints.down(1280)]: {
      width: 360,
      padding: 0,
      alignItems: "start",
    },
    "& >img": {
      marginTop: 15,
      [theme.breakpoints.down(1280)]: {
        width: 100,
      },
    },
  },
  lineBox: {
    position: "absolute",
    bottom: 10,
    width: 360,
    marginLeft: 15,
  },
  title: {
    fontFamily: "Montserrat",
    fontSize: 24,
    fontWeight: "bold",
    padding: "33px 0px 10px",
    [theme.breakpoints.down(1280)]: {
      width: 300,
      fontSize: 20,
    },
  },
  reward: {
    fontWeight: 300,
    "& strong": {
      fontWeight: 500,
    },
  },
  box: {
    height: 10,
    width: 940,
    borderRadius: 35,
    background: "#F4F3F3",
    boxShadow: "8px 8px 20px 4px rgba(0,0,0,0.1)",
    margin: "20px 0",
    [theme.breakpoints.down(1280)]: {
      width: 350,
    },
  },
  line: {
    height: 10,
    width: ({ count }) => `${count}%`,
    maxWidth: 940,
    borderRadius: 35,
    background:
      "linear-gradient(161deg, rgb(0, 170, 188) 30%, rgb(5, 130, 149) 63%, rgb(10, 86, 105) 88%)",
  },
  count: {
    display: "flex",
    justifyContent: "center",
    color: theme.palette.text.disabled,
  },
  disable: {
    filter: "grayscale(100%)",
    boxShadow: "none",
    background: "linear-gradient(183deg, #80808000 0%, white 70%)",
    opacity: 0.7,
    flexGrow: 0,
    [theme.breakpoints.down(1280)]: {
      background: "#F4F3F3",
    },
    "& img": {
      backgroundImage: "linear-gradient(183deg, #80808000 0%, white 70%)",
    },
  },
}));
