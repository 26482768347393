import React from "react";
import { makeStyles } from "@material-ui/core";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {
  className?: string;
  children?: React.ReactNode;
}

export const InputsGrid: React.FC<Props> = ({ className, children }) => {
  const classes = useStyles();
  return (
    <div className={`${className || ""} ${classes.inputsGrid}`}>{children}</div>
  );
};
const useStyles = makeStyles(
  theme => ({
    inputsGrid: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      columnGap: "50px",
      rowGap: 20,
      [theme.breakpoints.down(770)]: {
        gridTemplateColumns: " 1fr",
        columnGap: 25,
        rowGap: 15,
        "& .altercap-input,& .altercap-selector": {
          margin: "auto",
          minWidth: "unset",
        },
      },
      "& .altercap-input,& .altercap-selector": {
        width: "100%",
        maxWidth: 300,
        minWidth: 300,
        margin: 0,
      },
    },
  }),
  { index: 1 },
);
