import React, { useCallback, useState, useEffect } from 'react';
import { format, validate } from 'rut.js';
import { debounce } from 'lodash';
import {
  makeStyles,
  InputAdornment,
  CircularProgress,
  Theme,
} from '@material-ui/core';
import { CommonInputProps } from '..';
import { ChangeEvent, ExistenceValidationType } from '@interfaces';
import { validateExistingRut } from '@apollo';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import { SizedTextField } from '@components';
import { useApolloClient } from '@apollo/client';

interface Props extends CommonInputProps {
  onErrorChange?: (error: boolean) => void;
  disableValidation?: boolean;
  validateExistence?: ExistenceValidationType;
}

const MUST_EXISTS_ERROR_MESSAGE = 'Este RUT no está registrado';
const MUST_NOT_EXISTS_ERROR_MESSAGE = 'Este RUT ya está en uso';
const FORMAT_ERROR_MESSAGE = 'Formato de RUT no válido';
const DEBOUNCE_TIME = 800;

const validateRut = (value: string) => {
  if (value.length && (![11, 12].includes(value.length) || !validate(value))) {
    return false;
  }
  return true;
};

export const RutInput: React.FC<Props> = ({
  className,
  name,
  label,
  value,
  onChange,
  onErrorChange,
  error,
  helperText,
  disableValidation = false,
  validateExistence,
  ...props
}) => {
  const client = useApolloClient();
  // Variable to track API request and display loader inside input
  const [validatingAsync, setValidatingAsync] = useState(false);
  // Variable to track between the user enters a letter and the request begins (while DEBOUNCE_TIME)
  const [pendingAsyncValidation, setPendingAsyncValidation] = useState(false);
  // Variable to save error on email format
  const [formatError, setFormatError] = useState(false);
  // Variable to save error of email-in-use
  const [existenceError, setExistenceError] = useState(false);

  const debouncedValidateFormat = useCallback(
    debounce(
      (value: string) => setFormatError(value !== '' && !validateRut(value)),
      DEBOUNCE_TIME
    ),
    [setFormatError]
  );

  const debouncedValidateExistence = useCallback(
    debounce((value: string) => {
      if (!value) return;
      setValidatingAsync(true);
      setPendingAsyncValidation(false);
      validateExistingRut(value, client).then((exists) => {
        const error =
          (validateExistence === ExistenceValidationType.MUST_EXISTS &&
            !exists) ||
          (validateExistence === ExistenceValidationType.MUST_NOT_EXISTS &&
            exists);
        setExistenceError(error);
        setValidatingAsync(false);
      });
    }, DEBOUNCE_TIME),
    [
      validateExistence,
      setValidatingAsync,
      setExistenceError,
      setPendingAsyncValidation,
    ]
  );

  const onChangeInner = useCallback(
    async (e: ChangeEvent) => {
      const val = (e.target.value as string).toLowerCase();
      const value = val.length > 0 && val !== '-' ? format(val) : '';
      setFormatError(false);
      debouncedValidateFormat(value);
      onChange({
        target: {
          name: e.target.name,
          value,
        },
      } as ChangeEvent);
      if (validateExistence) {
        setExistenceError(false);
        setPendingAsyncValidation(true);
        debouncedValidateExistence(value);
      }
    },
    [
      setFormatError,
      setExistenceError,
      setPendingAsyncValidation,
      debouncedValidateFormat,
      onChange,
      validateExistence,
      debouncedValidateExistence,
    ]
  );

  const innerError = formatError || existenceError;
  const displayError = !disableValidation && (error || innerError);

  const classes = useStyles({
    validatingAsync,
    pendingAsyncValidation,
    disableValidation,
    displayError,
    noValue: value === '',
  });

  useEffect(() => {
    if (onErrorChange) {
      onErrorChange(innerError || pendingAsyncValidation || validatingAsync);
    }
  }, [onErrorChange, innerError, pendingAsyncValidation, validatingAsync]);

  return (
    <SizedTextField
      className={`${className || ''} altercap-rut-input altercap-input`}
      name={name || 'rut'}
      label={label ?? 'Rut'}
      {...props}
      value={value}
      onChange={onChangeInner}
      error={displayError}
      helperText={
        displayError
          ? helperText ??
            (formatError
              ? FORMAT_ERROR_MESSAGE
              : existenceError
              ? validateExistence === ExistenceValidationType.MUST_NOT_EXISTS
                ? MUST_NOT_EXISTS_ERROR_MESSAGE
                : MUST_EXISTS_ERROR_MESSAGE
              : '')
          : undefined
      }
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <CircularProgress className={classes.inputLoader} size={25} />
            <CheckRoundedIcon className={classes.inputCheck} />
          </InputAdornment>
        ),
      }}
    />
  );
};

type StyleProps = {
  validatingAsync: boolean;
  pendingAsyncValidation: boolean;
  disableValidation: boolean;
  displayError: boolean;
  noValue: boolean;
};
const useStyles = makeStyles<Theme, StyleProps>(() => ({
  inputLoader: {
    display: ({ validatingAsync }) => (validatingAsync ? 'inherit' : 'none'),
  },
  inputCheck: {
    display: ({ validatingAsync }) => (!validatingAsync ? 'inherit' : 'none'),
    visibility: ({
      disableValidation,
      displayError,
      pendingAsyncValidation,
      noValue,
    }) =>
      disableValidation || displayError || pendingAsyncValidation || noValue
        ? 'hidden'
        : 'inherit',
  },
}));
