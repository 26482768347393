import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {},
  mainText: {
    width: "44.813rem",
    margin: "1.2rem",
    "& .text": {
      height: "4.813rem",
      fontFamily: "Montserrat",
      fontSize: "1rem",
      fontWeight: 600,
      lineHeight: 1.88,
      textAlign: "left",
      color: theme.palette.text.secondary,
    },
  },
  midSection: {
    display: "flex",
    flexDirection: "row",
  },
  dots: {
    bottom: 0,
    "& li.slick-active button::before": {
      color: "#8352FD",
    },
    "& li": {
      "& button::before": {
        fontSize: theme.typography.pxToRem(14),
        color: "#9D9D9D",
        opacity: 0.5,
      },
    },
  },
  sliderContainer: {
    marginLeft: "-8rem",
    width: "60rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    marginBottom: "3rem",
  },
  slider: {
    width: "50rem",
  },
  slide1: {
    display: "flex !important",
    flexDirection: "row",
    justifyContent: "space-around",
    marginBottom: "2rem",
  },
  slideColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  whiteBox: {
    width: "21.938rem",
    height: "26rem",
    margin: "1rem 0 0 0",
    padding: "2.75rem 3.25rem 4.25rem 2.813rem",
    borderRadius: " 1.875rem",
    boxShadow: "4px 4px 20px 2px rgba(0,0,0,0.2)",
    backgroundColor: "#ffffff",
    textAlign: "center",
    "& .topS": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: "1.5rem",
      "& .id": {
        position: "absolute",
        width: "2.813rem !important",
        height: " 2.813rem !important",
        margin: "0 1.25rem 0rem 0",
        boxShadow: "4px 4px 8px 2px rgba(0,0,0,0.2)",
        backgroundColor: "#4619b6",
        borderRadius: "50%",
        color: "#ffffff",
        fontFamily: "Montserrat",
        fontSize: "1.25rem",
        fontWeight: "bold",
        textAlign: "left",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      "& .title": {
        fontFamily: "Montserrat",
        fontSize: "1rem",
        fontWeight: 600,
        textAlign: "left",
        color: "#8352fd",
        marginLeft: "4rem",
      },
    },
    "& .text": {
      fontFamily: "Montserrat",
      fontSize: "1rem",
      fontWeight: "normal",
      textAlign: "center",
      lineHeight: "1.56",
      color: theme.palette.text.primary,
    },
  },
  smallWhiteBox: {
    width: "21.938rem",
    height: "6.25rem",
    margin: "1.25rem 0rem 0 0",
    borderRadius: "1.875rem",
    boxShadow: "4px 4px 20px 2px rgba(0,0,0,0.2)",
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& img": {
      width: "2.413rem",
      height: "2.413rem",
      margin: "0 1.15rem 0 1.675rem",
    },

    "& .text2": {
      fontSize: "0.875rem",
      fontWeight: "bold",
      lineHeight: 1.71,
      textAlign: "left",
      marginRight: "0.5rem",
      color: theme.palette.text.secondary,
    },
  },
  rightColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& >img": {
      width: "18.438rem",
      height: "15.088rem",
      margin: "7.75rem 0",
      objectFit: "contain",
    },
  },
}));
