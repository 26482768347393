import React, { useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import useStyles from "./index.style";
import { ButtonPrimary } from "@components/Reusables";
import { BirthdateInput } from "@components/Inputs";
import { useForm } from "@hooks";
import useSendForm from "@components/Services/Hooks/SaveData";

interface YouSeekProps {
  className?: string;
  setFinishSave: (value: any) => void;
  onSend: () => void;
  secondOption?: boolean;
}
const AccidentPersonalizedAnalysis = ({
  className,
  setFinishSave,
  onSend,
  secondOption,
}: YouSeekProps) => {
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const classes = useStyles(1);
  const [isDate, setDate] = useState<Date>();
  const [form] = useForm();
  const [describe, setDescribe] = useState("");
  return (
    <section className={`${className || ""} ${classes.container}`}>
      <div className={classes.title}>
        {secondOption
          ? "Para analizar los seguros, cuéntanos que deporte haces con mayor frecuencia:"
          : " Para realizar el análisis necesitamos la siguiente información:"}
      </div>
      <div className="birthdate">
        {/*  <span className={classes.subtitle}>Fecha de nacimiento</span>
        <br /> */}
        {!secondOption ? (
          <BirthdateInput
            label="Fecha de nacimiento"
            className="input"
            onChange={(e) => {
              setDate(e.target.value as Date);
            }}
            value={isDate || new Date()}
          />
        ) : (
          <textarea
            className={`${classes.styleSpace}`}
            value={describe}
            onChange={(e) => setDescribe(e.target.value as string)}
            placeholder="Comentario"
          ></textarea>
        )}
      </div>
      <ButtonPrimary
        text="Enviar"
        disabled={!secondOption ? !isDate : !(describe.length > 0)}
        onClick={() => {
          secondOption
            ? (form.sure = {
                "Tipo de seguro": "Seguro personalizado",
                "Fecha de nacimiento": isDate,
              })
            : (form.sure = {
                "Tipo de seguro": "Seguro personalizado",
                "Deporte practicado": describe,
              });
          setFinishSave({
            Notification2448: true,
            BallCharge: false,
          });
          onSend();
        }}
        noDegraded
        className={classes.buttonPrimary}
      />
    </section>
  );
};
export default AccidentPersonalizedAnalysis;
