import React, { Dispatch, SetStateAction } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { numberMission, home, returnIcon } from 'images/onBoarding';
import { navigate } from 'gatsby';
import { Route } from '@interfaces';
import { Footer } from '../Landing/Footer';

interface LayoutProps {
  children: React.ReactNode;
  className?: string;
  styleFooter?: string;
  title: string;
  subtitle?: string;
  number: number;
  secondLayoutForShortcuts?: boolean;
  redirectLink?: string;
  numberAdjust?: boolean;
  setPage?: Dispatch<SetStateAction<number>>;
  pageNumber?: number;
}
export const LayoutBildRegistration: React.FC<LayoutProps> = ({
  className,
  children,
  styleFooter,
  title,
  subtitle,
  number,
  secondLayoutForShortcuts,
  redirectLink,
  numberAdjust,
  setPage,
  pageNumber,
}) => {
  const classes = useStyles();
  return (
    <div className={`${className || ''} ${classes.container}`}>
      <section>
        <div className={classes.headerLayout}>
          <div className={classes.numberMissionContainer}>
            <img src={numberMission} />
            {!numberAdjust ? (
              <span className={classes.numberMission}>{number}</span>
            ) : (
              <span
                className={`${classes.numberMission} ${classes.numberAdjusted}`}
              >
                {number}
              </span>
            )}
          </div>
          <div className={classes.txts}>
            <span className={classes.title}>{title}</span>
            <span className={classes.subtitle}>{subtitle}</span>
          </div>
        </div>
      </section>
      <section className={classes.containerData}>
        <div>
          <div className={classes.return}>
            <img onClick={() => navigate(Route.onBoard)} src={home} />
            {secondLayoutForShortcuts ? 'Salir' : 'Volver'}
          </div>
          {secondLayoutForShortcuts && (
            <div className={classes.return}>
              <img
                onClick={() => {
                  if (setPage && pageNumber) setPage(pageNumber);
                  else navigate(`${redirectLink}`);
                }}
                src={returnIcon}
              />
              Volver
            </div>
          )}
        </div>
        <div className={classes.children}>{children}</div>
      </section>

      <Footer className={`${classes.footer} ${styleFooter}`} />
    </div>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  footer: {
    [theme.breakpoints.down(1100)]: {
      display: 'none',
    },
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: '2.25rem',
    fontWeight: 'bold',
    color: '#393e44',
    display: 'block',
  },
  subtitle: {
    fontFamily: 'Montserrat',
    fontSize: '1.25rem',
    fontWeight: 'normal',
    display: 'block',
    color: theme.palette.text.secondary,
  },
  container: {
    overflowX: 'hidden',
    background: '#F4F3F3',
  },
  children: {
    marginLeft: 15,
    minHeight: 650,
  },
  headerLayout: {
    display: 'flex',
    gap: '2rem',
    alignItems: 'center',
    paddingLeft: 50,
    paddingTop: 20,
    '& img': {
      width: 140,
      height: 140,
    },
  },
  txts: {
    paddingTop: '1rem',
    '& span': {
      display: 'block',
    },
  },
  numberMissionContainer: {
    position: 'relative',
  },
  numberMission: {
    position: 'absolute',
    top: 45,
    left: 60,
    zIndex: 99,
    color: '#4619b6',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    fontSize: 33,
  },
  numberAdjusted: {
    marginLeft: '0.3125rem',
  },
  containerData: {
    display: 'flex',
    paddingLeft: 50,
    '& > img': {
      cursor: 'pointer',
    },
  },
  return: {
    minWidth: 140,
    paddingTop: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'Montserrat',
    color: theme.palette.text.secondary,
    '& img': {
      width: 55,
      marginLeft: 8,
      cursor: 'pointer',
    },
  },
}));
