import React from "react";
import { CommonInputProps } from "..";
import { SizedTextField } from "@components";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends CommonInputProps {}

export const SurnamesInput: React.FC<Props> = ({
  className,
  name,
  label,
  ...props
}) => {
  return (
    <SizedTextField
      className={`${className || ""} altercap-${name}-input altercap-input`}
      name={name || "surnames"}
      label={label || "Apellidos"}
      {...props}
    />
  );
};
