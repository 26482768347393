import { ButtonPrimary } from "@components";
import { eye } from "@images";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useContext } from "react";
import { AppContext } from "@context";
import { useStyles } from "./M2ApvStep3.styles";

const M2ApvStep3 = () => {
  const isMobile = useMediaQuery("(max-width: 1300px)");
  const { openCongratsModal } = useContext(AppContext);
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.columnsContainer}>
        <div className={classes.column}>
          <span className={classes.title}>APV A</span>
          <div className="whiteBox">
            <img src={eye} />
            <span className="title">En que me fijo</span>
            <br />
            <span className="text">
              <strong>Si retiras $100.000 </strong>
              en diciembre,
              <strong>
                {" "}
                de los cuales un 15% fue aportado por el Estado, recibirás
                $85.000 en tu cuenta,{" "}
              </strong>
              ya que los $15.000 restantes son el aporte que te entregó el
              Estado y se devolverán automáticamente.
            </span>
          </div>
        </div>
        <div className={classes.column}>
          <span className={classes.title}>APV B</span>
          <div className="whiteBox">
            <img src={eye} />
            <span className="title">En que me fijo</span>
            <br />
            <span className="text">
              Si retiras $100.000
              <strong> en diciembre, </strong>a tu cuenta solo te llegarán
              $85.000 dado que se te retuvo un 15%
              <strong>. Al llegar la devolución de impuestos en abril </strong>
              se usará lo retenido para pagar
              <strong> lo que corresponda de </strong>
              impuestos.
              <strong> Asi, </strong>
              si por tu tramo pagas 8%
              <strong>
                {" "}
                de impuesto y suponemos que te cobran una sobretasa de 3%* (lo
                que suma un impuesto del 11%),{" "}
              </strong>
              se te devolverá el 4% restante,
              <strong> equivalente a $4.000. </strong>
              Si tu impuesto
              <strong> más la sobretasa </strong>
              es mayor a 15%, deberás pagar<strong> la diferencia </strong>
              desde tu bolsillo...
            </span>
          </div>
          {!isMobile && <div></div>}
        </div>
      </div>
      <div className={classes.bottomSection}>
        <ButtonPrimary
          text={"Terminar"}
          onClick={() => {
            openCongratsModal();
          }}
        />
      </div>
    </div>
  );
};

export default M2ApvStep3;
