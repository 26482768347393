import React from "react";
import { ButtonPrimary } from "@components";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import { Player } from "video-react";
import { navigate } from "gatsby";
import { Route } from "@interfaces";

const Higher: React.FC = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 1240px)");

  return (
    <section className={classes.container}>
      <div className="capa_verde"></div>
      <div className={classes.left}>
        <div className={classes.containerLeft}>
          <h1 className={classes.title}>
            <h1 className="top">
              Una <strong>solución global</strong> para las
            </h1>
            <span className="medium"> finanzas personales</span>
            <br />
            <span className="bottom"> de tus colaboradores</span>
          </h1>
          <h2 className={classes.subtitle}>
            Te ofrecemos una <strong>plataforma integral</strong> con
            herramientas modernas y digitales, para{" "}
            <strong>acompañar cada decisión financiera</strong> de tus
            colaboradores y mejorar el <strong>bienestar</strong> en tu empresa.
          </h2>
        </div>{" "}
        <a href="#Form">
          <ButtonPrimary
            noDegraded
            className={classes.button}
            text="Quiero ser parte"
            onClick={() => {
              navigate(Route.login);
            }}
          />
        </a>
      </div>
      {!isMobile ? (
        <div className={classes.right}>
          <div className={classes.division}></div>
          <div className={classes.img}>
            <Player
              loop={true}
              playsInline
              muted
              autoPlay={true}
              src="https://rokinapp-images.s3.amazonaws.com/videos/Video+desktop+(2).mp4"
            />
          </div>
        </div>
      ) : (
        <div className={classes.videoMobile}>
          <Player
            loop={true}
            playsInline
            muted
            autoPlay={true}
            src="https://rokinapp-images.s3.amazonaws.com/videos/Portada+Landing+mobile+.mp4"
          />
          {/*           <img src={borrar} />
           */}{" "}
        </div>
      )}{" "}
    </section>
  );
};

export default Higher;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "50% 50%",
    justifyContent: "center",
    background: "black",
    "& .capa_verde": {
      width: "100%",
      height: 779,
      opacity: 0.7,
      position: "absolute",
      zIndex: 1,
    },
    [theme.breakpoints.down(1240)]: {
      gridTemplateColumns: "100%",
      height: 600,
      background: "none",
    },
    [theme.breakpoints.down(500)]: {
      gridTemplateColumns: "100%",
      maxHeight: 560,
    },
  },
  img: {
    width: "100%",
    padding: "10px 15px",
    zIndex: 900,
    "& video": {
      maxWidth: 1100,
      position: "absolute",
      right: 0,
      top: 0,
      paddingTop: 70,
      backgroundColor: "rgba(0,0,0,0.8)",
    },
    "&>div": {
      padding: "30px!important",
      "& button, div": {
        display: "none",
      },
    },
  },
  containerLeft: {
    [theme.breakpoints.down(1240)]: {
      width: 500,
      height: 465,
      mixBlendMode: "hard-light",
      background: "rgba(8, 69, 84, 0.8)",
    },
  },
  left: {
    paddingTop: "6.75rem",
    paddingLeft: "15%",
    color: theme.palette.text.primary,
    zIndex: 9,
    height: 778.5,
    //background: "#0a5669",
    [theme.breakpoints.down(1240)]: {
      width: 373,
      marginTop: "4rem",
      paddingTop: 26,
      paddingLeft: 0,
      margin: "0 auto",
      height: 300,
    },
    "& >div": {
      width: "100%",
    },
  },
  right: {
    width: "100%",
    background: "black",
    [theme.breakpoints.down(1240)]: {
      display: "none",
    },
  },
  title: {
    fontSize: 36,
    width: "670px",
    fontFamily: "Montserrat",
    color: "white",
    "& .top": {
      fontSize: "2.875rem",
      [theme.breakpoints.down(1240)]: {
        fontSize: 16,
      },
    },
    "& .medium": {
      color: "#8352fd",
      fontWeight: "bold",
      fontSize: 64,
      [theme.breakpoints.down(1240)]: {
        fontSize: 36,
      },
    },
    "& .bottom": {
      [theme.breakpoints.down(1240)]: {
        fontSize: 16,
      },
    },
    "& .right": {
      [theme.breakpoints.down(1240)]: {
        fontSize: 24,
      },
    },
    [theme.breakpoints.down(1240)]: {
      color: "#F4F3F3",
      fontSize: 40,
      width: 336,
      marginLeft: 20,
      "& span": {
        fontSize: "4rem",
      },
    },
  },
  subtitle: {
    display: "block",
    color: "#F4F3F3",
    width: "31.5rem",
    fontSize: "1.25rem",
    paddingTop: "1.5rem",
    lineHeight: 1.75,
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.down(1240)]: {
      color: "#F4F3F3",
      fontSize: 14,
      width: 298,
      marginLeft: 20,
    },
  },
  button: {
    marginTop: "4rem",
    width: 177,
    background: "#0A5669",
    [theme.breakpoints.down(1240)]: {
      width: 186,
      marginTop: "4rem",
      marginLeft: 20,
    },
  },
  videoMobile: {
    position: "relative",
    zIndex: 0,
    top: -376,
    "& video,img": {
      width: "100%",
      maxWidth: 500,
      display: "flex",
      margin: "0 auto",
      filter: "brightness(0.7)",
      borderRadius: " 0 0 30px 30px",
    },
    "&>div": {
      padding: "30px!important",
      [theme.breakpoints.down(1240)]: {
        padding: "0px!important",
      },
      "& button, div": {
        display: "none",
      },
    },
  },
  division: {
    background:
      "linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 17%, rgba(10,86,105,0.6009978991596639) 62%, rgba(136,173,182,0.3085609243697479) 90%, rgba(255,255,255,0) 100%, rgba(255,255,255,0.2) 95%)",
    width: 1320,
    height: 778,
    position: "absolute",
    zIndex: 3,
    right: 0,
  },
}));
