import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    // width: "70rem",
  },
  topSection: {
    width: "60rem",
    [theme.breakpoints.down(1240)]: {
      justifyContent: "center",
      display: "flex",
      width: "100%",
    },
    "& .text": {
      // margin: "0.313rem 1.563rem 0.5rem 4.25rem",

      fontFamily: "Montserrat",
      fontSize: "1rem",
      lineHeight: "1.88",
      textAlign: "left",
      color: theme.palette.text.secondary,
      width: 800,
      display: "block",
      [theme.breakpoints.down(1240)]: {
        width: 340,
        textAlign: "center",
      },
    },
  },
  midSection: {
    width: "75rem",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: "2rem",
    [theme.breakpoints.down(1240)]: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      alignItems: "center",
      width: "auto",
    },
    "& .whiteBox": {
      width: "29.688rem",
      margin: "0.748rem 2.4rem 1.25rem 0rem",
      padding: "1rem 1.313rem 1rem 1.813rem",
      borderRadius: "1.875rem",
      boxShadow: "0.25rem 0.25rem 1.25rem 0.125rem rgba(0, 0, 0, 0.2)",
      backgroundColor: "#ffffff",
      display: "flex",
      flexDirection: "row",
      [theme.breakpoints.down(1240)]: {
        width: "21rem",
        marginRight: "0rem",
      },
      "& .image": {
        width: "4.125rem",
        height: "4.125rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      "& .info": {
        display: "flex",
        flexDirection: "column",
        margin: "0 1rem",
        "& .name": {
          fontFamily: "Montserrat",
          fontSize: "1rem",
          fontWeight: "bold",
          lineHeight: 1.88,
          textTransform: "uppercase",
          textAlign: "left",
          color: theme.palette.text.primary,
        },
        "& .text": {
          fontSize: "0.875rem",
          fontWeight: "bold",
          lineHeight: 1.71,
          textAlign: "left",
          color: theme.palette.text.secondary,
        },
      },
    },
  },
  bottomSection: {
    margin: "6.375rem 0 0 0",
    textAlign: "left",
    [theme.breakpoints.down(1240)]: {
      textAlign: "center",
      margin: "3.375rem 0 0 0",
    },
  },
}));
