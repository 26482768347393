export const SerieFragment = `
  id
  name
  bloombergCode
  participants
  institucionalParticipants
  dailyShareCirculation
  dailyShareValue
  cost
  thirdMonthProfit
  twelfthMonthProfit
  ytdProfit
  variableCost
  showing
  tributaryBenefits
  lastDailyDate
  isBase
  historicalProfit
  lastYearProfit
  sharpeRatio
  isAPV
  factSheetUrl
  minInvest
  minInvestParsed
  shareValue
  profitabilities {
    historical
    last12months
    YTD
    lastYear
  }
  shareValueCLP
`;

export const FundFragment = `
  id
  rut
  name
  investArea
  #investType
  #investTerm
  #redeem
  risk
  internalRegulationUrl
  linkFactSheet
  termShareValue
  currency
  fundType
  riskDisclaimer
  category
  term
  redeemable
  isNew
  enabledInvestments
`;

export const AgfFragment = `
  id
  rut
  name
  #webUrl
  #showing
  #showingFirst
  #position}
`;
