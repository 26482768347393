import { ApolloClient } from "@apollo/client";
import { gql } from "@apollo/client";
import { GET_FINERIO_ACCOUNTS } from "./finerioAccounts.query";

const CREATE_MANUAL_ACCOUNT = gql`
  mutation CreateManualAccount($bankId: Int!) {
    createManualAccount(bankId: $bankId)
  }
`;

type CreateManualAccountParams = {
  bankId: number;
};

type CreateManualAccountResult = {
  createManualAccount: boolean;
};

export async function createManualAccount(
  client: ApolloClient<any>,
  params: CreateManualAccountParams
): Promise<CreateManualAccountResult> {

  const { data } = await client.mutate({
    mutation: CREATE_MANUAL_ACCOUNT,
    variables: params,
    refetchQueries: [
      {
        query: GET_FINERIO_ACCOUNTS,
      },
    ],
  });

  return data;
}
