import { Button } from "@components/Reusables";
import { ChangeEvent, OnChangeType } from "@interfaces";
import { Checkbox } from "@material-ui/core";
import React, { useState } from "react";
import { useCallback } from "react";
import { makeStyles } from "@material-ui/core";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {
  className?: string;
  name: string;
  checked: boolean | null;
  onChange: (e: ChangeEvent, checked?: boolean) => void;
  children: React.ReactNode;
  hideFalse?: boolean;
  mobile?: boolean;
}

export const CheckboxInput: React.FC<Props> = ({
  className,
  name,
  checked,
  onChange,
  children,
  hideFalse,
  mobile,
}) => {
  const handleFalsyChange = useCallback(
    (_e, checked: boolean) => {
      onChange({ target: { name } } as ChangeEvent, !checked);
    },
    [name, onChange]
  );
  const classes = useStyles();
  return (
    <div className={`${classes.checkboxInput} ${className || ""}`}>
      {mobile && (
        <span className={`${classes.checkboxInputText}`}>{children}</span>
      )}
      <div>
        {!hideFalse && (
          <>
            {mobile && "No"}
            <Checkbox
              className={`${classes.checkboxInputBox} ${classes.checkboxInputFalsyBox}`}
              name={name}
              checked={checked === false}
              onChange={handleFalsyChange}
            />
          </>
        )}
        {mobile && "Si"}
        <Checkbox
          className={`${classes.checkboxInputBox} ${classes.checkboxInputTruthyBox}`}
          name={name}
          checked={checked === true}
          onChange={onChange}
        />
        {!mobile && (
          <span className={`${classes.checkboxInputText}`}>{children}</span>
        )}
      </div>
    </div>
  );
};
const useStyles = makeStyles(
  (theme) => ({
    checkboxInput: {
      color: "#666666",
      fontFamily: "Montserrat, sans-serif",
      fontSize: 16,
      display: "grid",
      alignItems: "center",
      "& >div": {
        display: "flex",
        marginLeft: "1rem",
        alignItems: "center",
      },
      [theme.breakpoints.down(770)]: {
        gridColumn: 1,
        fontSize: 14,
      },
    },
    checkboxInputBox: {},
    checkboxInputTruthyBox: {
      "&:first-child": {
        marginLeft: 42,
      },
    },
    checkboxInputFalsyBox: {},
    checkboxInputText: {
      marginLeft: 10,
      display: "block",
      [theme.breakpoints.down(600)]: {
        width: 200,
      },
    },
  }),
  { index: 1 }
);
