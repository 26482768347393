import axios from "axios";

const facebookAPI = axios.create({
  baseURL: "https://graph.facebook.com",
});

const API_VERSION = "v11.0";
const PIXEL_ID = process.env.FACEBOOK_PIXEL_ID;
const TOKEN = process.env.FACEBOOK_TOKEN;
///https://graph.facebook.com/{API_VERSION}/{PIXEL_ID}/events?access_token={TOKEN}

interface SendEventParams {
  event: string;
  customData?: {
    [key: string]: string | number;
  };
}
export const sendEvent = async ({
  event,
  customData,
}: SendEventParams): Promise<void> => {
  const payload = {
    data: [
      {
        event_name: event,
        event_time: Math.round(new Date().getTime() / 1000),
        action_source: "website",
        user_data: {
          em: [null],
          ph: [null],
        },
        ...(customData
          ? {
              custom_data: customData,
            }
          : {}),
      },
    ],
  };
  await facebookAPI.post(
    `${API_VERSION}/${PIXEL_ID}/events?access_token=${TOKEN}`,
    payload,
  );
  console.log("New event sent with payload", payload);
};
