import React from "react";
import { Select } from "@components";
import { CommonSelectorProps, MARITAL_STATE_OPTIONS } from "..";
import { useContext } from "react";
import { ProfileContext } from "@context";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends CommonSelectorProps {}

export const MaritalStateSelector: React.FC<Props> = ({
  className,
  name,
  label,
  onChange,
  ...props
}) => {
  const { updateProfileForm } = useContext(ProfileContext);

  return (
    <Select
      className={`${className || ""} altercap-maritalState-selector`}
      name={name || "maritalState"}
      label={label ?? "Estado civil"}
      onChange={(e) => {
        onChange(e);
        updateProfileForm({ maritalPropertyRegime: "" });
      }}
      options={MARITAL_STATE_OPTIONS}
      {...props}
    />
  );
};
