import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down(1241)]: {
      margin: "0 -1.5625rem",
    },
  },
  topText: {
    width: "30rem",
    fontSize: "1.25rem",
    fontWeight: "normal",
    fontFamily: "Montserrat",
    textAlign: "left",
    color: "#008296",
    marginBottom: "2.6rem",
    [theme.breakpoints.down(1239)]: {
      width: "20.938rem",
      fontSize: "0.85rem",
      textAlign: "center",
      marginBottom: "0.5rem",
      paddingLeft: "2rem",
    },
  },
  middleSection: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down(1239)]: {
      display: "flex",
      flexDirection: "column",
    },
  },

  firstColumn: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    [theme.breakpoints.down(1239)]: {
      order: 2,
      width: "100%",
      marginTop: "1.25rem",
    },
  },
  textContainer: {
    [theme.breakpoints.down(1239)]: {
      padding: "0 1.5625rem",
    },
    "& .text": {
      fontSize: "1rem",
      fontFamily: "Montserrat",
      textAlign: "left",
      color: theme.palette.text.secondary,
      [theme.breakpoints.down(1239)]: {
        fontSize: "0.75rem",
        lineHeight: 1.67,
      },
    },
  },
  secondColumn: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down(1239)]: {
      order: 1,
      margin: "0 auto",
      "& > img": {
        width: "9.15rem",
        height: "auto",
        transform: "rotate(-60deg)",
      },
    },
  },
  iconsLayout: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.down(1239)]: {
      justifyContent: "space-evenly",
    },
    [theme.breakpoints.down(556)]: {
      justifyContent: "flex-start",
      overflowX: "auto",
    },
    "& .exampleContainer": {
      width: "5.313rem",
      height: "6.125rem",
      margin: "3rem 0.8125rem",
      padding: "0rem 2rem",
      borderRadius: "1.25rem",
      boxShadow: "0.25rem 0.25rem 0.5rem 0.125rem rgba(0, 0, 0, 0.2)",
      backgroundColor: theme.palette.background.paper,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      alignItems: "center",
      "& .title": {
        fontSize: "0.875rem",
        textAlign: "center",
        lineHeight: "1rem",
        color: "#8352fd",
      },
      "& >img": {
        width: "1.563rem",
        height: "1.563rem",
      },
    },
  },
  bottomSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    [theme.breakpoints.down(1239)]: {
      justifyContent: "center",
      marginTop: "7rem",
    },
  },
}));
