import React from "react";
import { makeStyles } from "@material-ui/core";

interface propsCard {
  title: string;
  subtitle: string;
  img: string;
}
const Card = ({ title, subtitle, img }: propsCard) => {
  const classes = useStyles();

  return (
    <section className={classes.container}>
      <img src={img} alt={title} />
      <div>
        <span>{title}</span>
        <span>{subtitle}</span>
      </div>
    </section>
  );
};

export default Card;

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    "& >div": {
      position: "absolute",
      height: 110,
      bottom: -80,
      background: "white",
      borderRadius: 30,
      width: 180,
      "& span": {
        display: "block",
      },
      "& span:last-child": {
        color:theme.palette.text.secondary,
        padding: "0 18px",
        fontSize: 14,
        fontWeight: "normal",
      },
      "& >span": {
        color:theme.palette.text.secondary,
        fontWeight: "bold",
        padding: "12px 18px",
      },
    },
    "& img": {
      width: 180,
      height: 160,
      borderRadius: "20px 20px 0 0",
    },
  },
}));
