import { createTheme } from "@material-ui/core/styles";
import { esES } from "@material-ui/core/locale";

const theme = createTheme(
  {
    palette: {
      text: {
        primary: "#393e44",
        secondary: "#6b6b6b",
        disabled: "#9d9d9d",
        hint: "#7d7c7c",
      },
      primary: {
        light: "#46d2a0",
        main: "#008296",
        dark: "#0a5669",
        contrastText: "#fff",
      },
      secondary: {
        light: "#d8d8d8",
        main: "#4619b6",
        dark: "#ba000d",
        contrastText: "#f0f0f0",
      },
    },
    typography: {
      fontFamily: "Nunito",
    },
  },
  esES
);

export default theme;
