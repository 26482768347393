import { ApolloClient, gql } from "@apollo/client";
import { GET_USER_INVESTMENTS_QUERY } from "apollo/user_investments";

const DELETE_MOVEMENTS_MUTATION = gql`
  mutation DeleteUserInvestmentMovements($ids: [Int!]!) {
    deleteUserInvestmentMovements(ids: $ids) {
      id
      name
      email
    }
  }
`;

type MutationParams = {
  ids: number[];
};

type MutationResult = {
  deleteUserInvestmentMovements: boolean;
};

export async function deleteUserInvestmentMovements(
  ids: number[],
  client: ApolloClient<object>,
): Promise<boolean> {
  const result = await client.mutate<MutationResult, MutationParams>({
    mutation: DELETE_MOVEMENTS_MUTATION,
    variables: { ids },
    refetchQueries: [
      {
        query: GET_USER_INVESTMENTS_QUERY,
      },
    ],
  });
  return !!result.data?.deleteUserInvestmentMovements;
}
