import { gql, useQuery } from "@apollo/client";
import { InvestmentHistoricalData } from "@interfaces";

const GET_CURRENT_INVESTMENT_VALUE = gql`
  query GetInvestmentHistoricalValues($id: Int!) {
    getInvestmentHistoricalValues(id: $id) {
      id
      data {
        date
        value
      }
    }
  }
`;

interface QueryParams {
  id?: number;
}

interface QueryResult {
  getInvestmentHistoricalValues: InvestmentHistoricalData | null;
}

interface HookReturn {
  data: InvestmentHistoricalData | null;
  loading: boolean;
}

export const useInvestmentHistoricalValues = (id?: any): HookReturn => {

  const { data, loading } = useQuery<QueryResult, QueryParams>(
    GET_CURRENT_INVESTMENT_VALUE,
    { variables: { id }, skip: !id },
  );
  return { data: data?.getInvestmentHistoricalValues ?? null, loading };

  // return { data: data?.getInvestmentHistoricalValues ?? null, loading };
};
