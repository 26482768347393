import React, { useContext, useEffect, useState } from 'react';
import { useStyles } from './ProfileMobileMenu.style';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import { ArrowLeft, Menu } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import {
  DEFAULT_PROFILE_ICON,
  LOGOUT_ICON,
  NOTIFICATION_ICON,
  PERSONAL_DATA_ICON,
  SETTINGS_ICON,
} from '@images';
import { AuthContext } from '@context';
import { HELP_ICON } from 'images/AWS/profile';

type MenuProps = {
  currentView: number;
  setCurrentView: (value: number) => void;
  currentUser: any;
};

const MobileProfileMenu = ({
  currentView,
  setCurrentView,
  currentUser,
}: MenuProps) => {
  const classes = useStyles();
  const [profileName, setProfileName] = useState('Usuario');
  const [welcomeMessage, setWelcomeMessage] = useState('Bienvenido');
  useEffect(() => {
    setProfileName(currentUser?.name ? currentUser?.name : '...');
    setWelcomeMessage(
      currentUser?.personalData?.gender
        ? currentUser.personalData.gender === 'Femenino'
          ? 'Bienvenida'
          : currentUser.personalData.gender === 'Otro'
          ? 'Bienvenid@'
          : 'Bienvenido'
        : 'Bienvenido'
    );
  }, [currentUser]);
  const { logout } = useContext(AuthContext);

  return (
    <>
      <div className={classes.background} />

      <div className={classes.container}>
        <div className={classes.header}>
          <span className="profile">Mi Perfil</span>
          <IconButton>
            <Menu htmlColor="white" />
          </IconButton>
        </div>
        <div className={`${classes.separator} ${classes.opacitySeparator}`} />
        <div className={classes.welcome}>
          <div className="texasContainer">
            <div className="imageContainer">
              <img src={DEFAULT_PROFILE_ICON} alt="profile-icon" />
            </div>
            <div className="textContainer">
              <span className="welcome">{welcomeMessage}</span>
              <span className="name">{profileName}</span>
            </div>
          </div>
        </div>
        <div className={`${classes.separator} ${classes.opacitySeparator}`} />
        <div className={classes.menuContainer}>
          <div className={classes.menu}>
            <div className={classes.menuItem} onClick={() => setCurrentView(1)}>
              <div className="left">
                <img src={PERSONAL_DATA_ICON} />
                <span className="text">Datos Personales</span>
              </div>
              <KeyboardArrowRightRoundedIcon />
            </div>
            <div className={classes.separator} />
            <div
              className={`${classes.menuItem} ${classes.menuItemDisabled} `}
              onClick={() => {}} //setCurrentView(2)
            >
              <div className="left">
                <img src={NOTIFICATION_ICON} />
                <span className="text">Notificaciones</span>
              </div>
              <KeyboardArrowRightRoundedIcon />
            </div>
            <div className={classes.separator} />
            <div
              className={`${classes.menuItem} ${classes.menuItemDisabled} `}
              onClick={() => {}} //setCurrentView(3)
            >
              <div className="left">
                <img src={SETTINGS_ICON} />
                <span className="text">Ajustes de cuenta</span>
              </div>
              <KeyboardArrowRightRoundedIcon />
            </div>
            <div className={classes.separator} />
            <div
              className={`${classes.menuItem} ${classes.menuItemDisabled} `}
              onClick={() => {}} // setCurrentView(4)
            >
              <div className="left">
                <img src={HELP_ICON} />
                <span className="text">Centro de ayuda</span>
              </div>
              <KeyboardArrowRightRoundedIcon />
            </div>
            <div className={classes.separator} />
            <div className={classes.menuItem} onClick={() => logout()}>
              <div className="left">
                <img src={LOGOUT_ICON} />
                <span className="text">Salir</span>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileProfileMenu;
