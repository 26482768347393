import { ApolloClient, gql } from "@apollo/client";
import { FileSpecificType } from "@interfaces";

const SAVE_USER_FILE_MUTATION = gql`
  mutation SaveUserFile($type: String!, $url: String!) {
    saveUserFile(type: $type, url: $url)
  }
`;

type MutationParams = {
  type: FileSpecificType;
  url: string;
};

type MutationResult = {
  saveUserFile: boolean;
};

export async function saveUserFile(
  type: FileSpecificType,
  url: string,
  client: ApolloClient<object>,
): Promise<boolean> {
  const result = await client.mutate<MutationResult, MutationParams>({
    mutation: SAVE_USER_FILE_MUTATION,
    variables: { type, url },
  });
  return !!result.data?.saveUserFile;
}
