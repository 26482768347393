import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ActiveFilters } from "@interfaces";
import { defaultFilters } from "@utils";
import { isEqual } from "lodash";

interface FiltersContextType {
  currentFilters: ActiveFilters;
  tempFilters: ActiveFilters;
  areFiltersActive: boolean;
  setCurrentFilters: React.Dispatch<React.SetStateAction<ActiveFilters>>;
  setCurrentFiltersWithoutScroll: React.Dispatch<
    React.SetStateAction<ActiveFilters>
  >;
  setTempFilters: React.Dispatch<React.SetStateAction<ActiveFilters>>;
  cleanFilters: () => void;
  mobileFiltersOpen: boolean;
  openMobileFilters: () => void;
  closeMobileFilters: (apply: boolean, scroll: boolean) => void;
}

export const FiltersContext = createContext({} as FiltersContextType);

export const FiltersProvider: React.FC = ({ children }) => {
  const scrollToTop = useCallback(() => {
    window.scrollTo({ behavior: "smooth", top: 0 });
  }, []);

  const [currentFilters, setCurrentFilters] =
    useState<ActiveFilters>(defaultFilters);
  const [tempFilters, setTempFilters] = useState<ActiveFilters>(
    () => currentFilters,
  );

  const setCurrentFiltersScroll = useCallback(
    (filters: ActiveFilters) => {
      setCurrentFilters(filters);
      scrollToTop();
    },
    [setCurrentFilters, scrollToTop],
  ) as React.Dispatch<React.SetStateAction<ActiveFilters>>;

  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const openMobileFilters = useCallback(
    () => setMobileFiltersOpen(true),
    [setMobileFiltersOpen],
  );
  const closeMobileFilters = useCallback(
    (apply: boolean, scroll: boolean) => {
      setMobileFiltersOpen(false);
      if (apply) {
        scroll
          ? setCurrentFiltersScroll(tempFilters)
          : setCurrentFilters(tempFilters);
      } else setTempFilters(currentFilters);
    },
    [
      currentFilters,
      tempFilters,
      setCurrentFilters,
      setCurrentFiltersScroll,
      scrollToTop,
      setTempFilters,
      setMobileFiltersOpen,
    ],
  );
  const cleanFilters = useCallback(() => {
    setTempFilters(defaultFilters);
    setCurrentFiltersScroll(defaultFilters);
    setMobileFiltersOpen(false);
  }, [
    setTempFilters,
    setCurrentFiltersScroll,
    setMobileFiltersOpen,
    scrollToTop,
  ]);

  // Sync temp filters with current filters
  useEffect(() => {
    setTempFilters(currentFilters);
  }, [currentFilters, setTempFilters]);

  const context = useMemo(
    () => ({
      currentFilters,
      tempFilters,
      areFiltersActive: !isEqual(currentFilters, defaultFilters),
      setCurrentFilters: setCurrentFiltersScroll,
      setCurrentFiltersWithoutScroll: setCurrentFilters,
      setTempFilters,
      cleanFilters,
      mobileFiltersOpen,
      openMobileFilters,
      closeMobileFilters,
    }),
    [
      currentFilters,
      tempFilters,
      setCurrentFiltersScroll,
      setCurrentFilters,
      setTempFilters,
      cleanFilters,
      mobileFiltersOpen,
      openMobileFilters,
      closeMobileFilters,
    ],
  );

  return (
    <FiltersContext.Provider value={context}>
      <>{children}</>
    </FiltersContext.Provider>
  );
};
