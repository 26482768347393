import { ApolloClient, gql } from "@apollo/client";
const SAVE_EMAIL_BY_ROKIN = gql`
  mutation CreateUserSaveByRokin($emailUser: String!) {
    saveEmailSaveByRokin(emailUser: $emailUser)
  }
`;

type MutationParams = {
  emailUser: string;
};
type MutationResult = {
  saveEmailSaveByRokin: boolean;
};

export async function saveEmailSaveByRokin(
  emailUser: string,
  client: ApolloClient<object>,
): Promise<boolean> {
  const result = await client.mutate<MutationResult, MutationParams>({
    mutation: SAVE_EMAIL_BY_ROKIN,
    variables: { emailUser },
  });
  return !!result.data?.saveEmailSaveByRokin;
}
