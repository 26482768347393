import React, {
  useState,
  useCallback,
  Dispatch,
  SetStateAction,
  useContext,
} from "react";
import { useStyles } from "./M3mainComponent2.style";
import { Icon, useMediaQuery } from "@material-ui/core";
import {
  ButtonOutlined,
  ButtonPrimary,
  NumericInput,
  TextInput,
} from "@components";
import { goIcon, targetIcon, hourGlass_icon, calendar_icon } from "@images";
import { navigate } from "gatsby";
import { Route } from "@interfaces";

type Props = {
  handleClick: () => void;
  setPage: Dispatch<SetStateAction<number>>;
  adjustDesktop?: React.CSSProperties;
};

const M3MainComponent2: React.FC<Props> = ({
  setPage,
  adjustDesktop,
  handleClick,
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 1240px)");
  return (
    <>
      {isMobile ? (
        <div className={classes.container} style={adjustDesktop}>
          <div className={classes.topSection}>
            <div>
              <div className={classes.textSub}>Requisitos para refianaciar</div>
              <span className={classes.text}>
                Los requisitos para refinanciar un crédito pueden variar
                dependiendo de la institución financiera, pero en general hay
                dos aspectos que te van a exigir:
              </span>
            </div>
            <div className="centrator">
              <div className="item">
                <div className="rateContainer">
                  <img src={calendar_icon} />
                  <div className="2texts">
                    <div className="rateTextContainer">Buen Pagador</div>
                    <span className="rateTextContainer2">
                      Debes haber{" "}
                      <strong>pagado a tiempo todas tus cuotas</strong> del
                      crédito a refinanciar, ya que este es un beneficio que se
                      otorga cuando la persona cuenta con un buen historial
                      crediticio, es decir, ha demostrado ser un buen pagador.
                    </span>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="rateContainer">
                  <img src={hourGlass_icon} />
                  <div>
                    <div className="rateTextContainer">
                      Antigüedad de crédito
                    </div>
                    <span className="rateTextContainer2">
                      El crédito debe tener <strong>cierta antigüedad,</strong>{" "}
                      generalmente puedes solicitar el refinanciamiento a partir
                      de los <strong>seis meses</strong> o al haber pagado{" "}
                      <strong>cierto porcentaje</strong> del crédito.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.secondMidSection}>
            <div className="secondText">
              <div className="texasContainer">
                <img src={targetIcon} />
                <div className="textContainer">
                  <span className="title">
                    ¿Ya tienes un crédito y quieres saber si refinanciar?
                  </span>
                  <span className="description">
                    Aprovecha nuestra{" "}
                    <strong className="underlined">
                      {" "}
                      Herramienta de Refinanciamiento{" "}
                    </strong>{" "}
                    para ver si te conviene refinanciar tu crédito.
                  </span>
                  <ButtonOutlined
                    text="Ir a la Herramienta"
                    startIcon={
                      <Icon>
                        <img src={goIcon} />
                      </Icon>
                    }
                    onClick={() => navigate(Route.toolsRefinanceCredit)}
                  />
                </div>
              </div>
            </div>
            <div className={classes.bottomSection}>
              <ButtonPrimary
                text={"Terminar misión"}
                onClick={handleClick}
                secondaryVersion
              />
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.container} style={adjustDesktop}>
          <div className={classes.topSection}>
            <div>
              <div className={classes.textSub}>Requisitos para refianaciar</div>
              <span className={classes.text}>
                Los requisitos para refinanciar un crédito pueden variar
                dependiendo de la institución financiera, pero en general hay
                dos aspectos que te van a exigir:
              </span>
            </div>
            <div className="centrator">
              <div className="item">
                <div className="rateContainer">
                  <img src={calendar_icon} />
                  <div>
                    <div className="rateTextContainer">Buen Pagador</div>
                    <span className="rateTextContainer2">
                      Debes haber{" "}
                      <strong>pagado a tiempo todas tus cuotas</strong> del
                      crédito a refinanciar, ya que este es un beneficio que se
                      otorga cuando la persona cuenta con un buen historial
                      crediticio, es decir, ha demostrado ser un buen pagador.
                    </span>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="rateContainer">
                  <img src={hourGlass_icon} />
                  <div>
                    <div className="rateTextContainer">
                      Antigüedad de crédito
                    </div>
                    <span className="rateTextContainer2">
                      El crédito debe tener cierta antigüedad, generalmente
                      puedes solicitar el refinanciamiento a partir de los{" "}
                      <strong>seis meses</strong> o al haber pagado{" "}
                      <strong>cierto porcentaje</strong> del crédito.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.bottomSection}>
              <ButtonPrimary
                text={"Terminar misión"}
                onClick={handleClick}
                secondaryVersion
              />
            </div>
          </div>
          <div className={classes.secondMidSection}>
            <div className="secondText">
              <div className="texasContainer">
                <img src={targetIcon} />
                <div className="textContainer">
                  <span className="title">
                    ¿Ya tienes un crédito y quieres saber si refinanciar?
                  </span>
                  <span className="description">
                    Aprovecha nuestra{" "}
                    <strong className="underlined">
                      {" "}
                      Herramienta de Refinanciamiento{" "}
                    </strong>{" "}
                    para ver si te conviene refinanciar tu crédito.
                  </span>
                  <ButtonOutlined
                    text="Ir a la Herramienta"
                    startIcon={
                      <Icon>
                        <img src={goIcon} />
                      </Icon>
                    }
                    onClick={() => navigate(Route.toolsRefinanceCredit)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default M3MainComponent2;
