import React, { useContext, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  useMediaQuery,
} from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { AppContext, ProfileContext } from "@context";
import { Button } from "@components";
import { useCallback } from "react";
// import { SubscriptionFrequency, SubscriptionType } from "@interfaces";
import { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { ShipProfileWelcomeImage } from "@images";

interface Props {
  open: boolean;
}
export const FeatureComingSoonDialog: React.FC<Props> = ({ open }) => {
  const classes = useStyles();
  const { profileForm } = useContext(ProfileContext);
  const { closeFeatureDialog } = useContext(AppContext);
  const [email, setEmail] = useState<string>("");

  const handleClose = useCallback(() => {
    closeFeatureDialog();
  }, [setEmail, closeFeatureDialog]);

  const handleSubmit = useCallback(() => {
    // TO-DO: re-implement as graphql mutation
    // saveSubscription(
    //   email,
    //   SubscriptionType.DIRECT_INVESTS_FEATURE_READY,
    //   SubscriptionFrequency.ONE_TIME,
    // );
    setTimeout(() => {
      closeFeatureDialog();
    }, 700);
  }, [email, setEmail, closeFeatureDialog]);

  const isMobile = useMediaQuery("(max-width: 768px)");

  useEffect(() => setEmail(profileForm.email), [profileForm.email]);

  return (
    <Dialog
      className={classes.root}
      open={open}
      onClose={handleClose}
      fullScreen={isMobile}
    >
      <img className={classes.image} src={ShipProfileWelcomeImage} alt="" />
      <IconButton className={classes.closeButton} onClick={handleClose}>
        <CloseRoundedIcon />
      </IconButton>
      <DialogTitle>
        <div className={classes.dialogTitle}>¡Próximamente!</div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <p>Pronto podrás invertir directamente en el fondo que quieras.</p>
        <p>Deja tu correo y te contactaremos cuando esté disponible.</p>
        <TextField
          className={classes.input}
          label="Correo"
          variant="outlined"
          name="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          type="email"
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={!isMobile}
        />
        <img
          className={classes.innerImage}
          src={ShipProfileWelcomeImage}
          alt=""
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          className={classes.button}
          text="Cerrar"
          variant={2}
          onClick={handleClose}
        />
        <Button
          className={classes.button}
          text="Avísenme"
          variant={1}
          onClick={handleSubmit}
          disabled={!email}
        />
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(
  theme => ({
    root: {
      position: "relative",
    },
    image: {
      position: "absolute",
      top: "10px",
      left: "15px",
      height: "50px",
      [theme.breakpoints.down(768)]: {
        display: "none",
      },
    },
    closeButton: {
      display: "none",
      position: "absolute",
      top: "5px",
      right: "20px",
      width: "35px",
      height: "35px",
      [theme.breakpoints.down(768)]: {
        display: "block",
      },
    },
    dialogTitle: {
      fontSize: "24px",
      fontWeight: 600,
      textAlign: "center",
    },
    dialogContent: {
      padding: "8px 48px 20px",
      textAlign: "center",
      "& p": {
        marginBottom: 8,
      },
      [theme.breakpoints.down(768)]: {
        padding: "8px 20px 20px",
        display: "flex",
        flexDirection: "column",
      },
    },
    input: {
      maxWidth: "350px",
      margin: "30px auto 0",
    },
    innerImage: {
      display: "none",
      [theme.breakpoints.down(768)]: {
        display: "block",
        margin: "auto 0",
      },
    },
    dialogActions: {
      justifyContent: "center",
      padding: "20px 0",
      [theme.breakpoints.down(768)]: {
        padding: "15px",
      },
    },
    button: {
      width: "160px",
      [theme.breakpoints.down(768)]: {
        width: "140px",
        height: "36px",
        fontSize: "14px",
      },
    },
  }),
  { index: 1 },
);
