import { gql, useQuery } from "@apollo/client";
import { FinerioTransaction } from "@interfaces";

export const GET_FINERIO_TRANSACTION = gql`
query FinerioTransaction($finerioTransactionId: ID!) {
    finerioTransaction(id: $finerioTransactionId) {
      id
      accountId
      amount
      balance
      date
      description
      enabled
      isCharge
      userId
      newSubcategory {
        FinerioCategory {
          icon
          id
          name
        }
        description
        icon
        subcategoryId
        id
      }
      subcategoryId {
        FinerioCategory {
          icon
          id
          name
        }
        description
        id
        icon
        subcategoryId
      }
      recommendedSubcategory
    }
  }
`;

type QueryResult = {
    finerioTransaction: FinerioTransaction;
};

type HookReturn = {
    finerioTransaction: FinerioTransaction;
    loading: boolean;
};

export const getFinerioTransaction = (finerioTransactionId: string): HookReturn => {
    const { data, loading } = useQuery<QueryResult>(GET_FINERIO_TRANSACTION, {
        variables: { finerioTransactionId }
    });
    return {
        finerioTransaction: data?.finerioTransaction ?? {},
        loading
    };
};
