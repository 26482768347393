// Position of options does matter for disabling some other inputs
export const MARITAL_STATE_OPTIONS = [
  "Soltero/a",
  "Casado/a",
  "Viudo/a",
  "Divorciado/a",
  "Conviviente civil",
].map(g => ({
  name: g,
  value: g,
}));
