import { getAuth, User } from "firebase/auth";
import { navigate } from "gatsby";
import { useEffect, useMemo, useState } from "react";

interface UseAuthParams {
  redirectIfNotLogged?: boolean;
}

interface UseAuthResults {
  isLoggedIn: boolean;
  user: User | null;
}
export const useAuth = ({
  redirectIfNotLogged,
}: UseAuthParams = {}): UseAuthResults => {
  const [user, setUser] = useState<null | User>(null);
  const [loading, setLoading] = useState(true);
  const memoizedUser = useMemo(() => user, [user?.email]);

  useEffect(() => {
    const onAuthStateChange = () => {
      return getAuth().onAuthStateChanged((firebaseUser) => {
        if (firebaseUser) {
          setUser(firebaseUser);
        } else {
          setUser(null);
        }
        setLoading(false);
      });
    };
    const unsubscribe = onAuthStateChange();
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (loading) return;
    if (redirectIfNotLogged && !user) {
      navigate("/");
    }
  }, [loading, user, redirectIfNotLogged]);

  return {
    isLoggedIn: !!user,
    user: memoizedUser,
  };
};
