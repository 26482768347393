import React from "react";
import { Select } from "@components";
import { CommonSelectorProps } from "..";
import { SelectOption } from "@interfaces";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends CommonSelectorProps {
  options: SelectOption[];
}

export const GoalSelector: React.FC<Props> = ({
  className,
  name,
  label,
  ...props
}) => {
  return (
    <Select
      className={`${className || ""} altercap-goal-selector`}
      name={name || "goal"}
      label={label || "Objetivo"}
      {...props}
    />
  );
};
