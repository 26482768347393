import React, { Dispatch, SetStateAction } from "react";
import { makeStyles, IconButton } from "@material-ui/core";
import { home } from "@images";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import { navigate } from "gatsby";
import { Route } from "@interfaces";

interface HeaderProps {
  title: string;
  secondLayoutForShortcuts?: boolean;
  redirectLink?: string;
  setPage?: Dispatch<SetStateAction<number>>;
  pageNumber?: number;
  titleAdjust?: boolean;
  onHouseClick?: () => void;
}

export const Header = ({
  title,
  secondLayoutForShortcuts,
  redirectLink,
  setPage,
  pageNumber,
  titleAdjust,
  onHouseClick,
}: HeaderProps) => {
  const classes = useStyles();
  return (
    <header className={classes.header}>
      {secondLayoutForShortcuts && (
        <IconButton
          onClick={() => {
            if (setPage && pageNumber != undefined) setPage(pageNumber);
            else navigate(`${redirectLink}`);
          }}
        >
          <ArrowBackIosRoundedIcon className={classes.goBackIcon} />
        </IconButton>
      )}
      {titleAdjust ? (
        <div className={`${classes.shortTitle} ${classes.title}`}>{title}</div>
      ) : (
        <div className={classes.title}>{title}</div>
      )}
      <div onClick={onHouseClick ? onHouseClick : () => navigate(Route.index)}>
        <img className={classes.img} src={home} />
      </div>
    </header>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    height: 90,
    alignItems: "center",
    width: "100%",
    backgroundColor: "#F4F3F3",
    boxShadow: "8px 8px 12px 0 rgba(0, 0, 0, 0.1)",
    borderRadius: "0 0 30px 30px",
    "& span": {
      textTransform: "inherit",
    },
  },
  shortTitle: {
    width: "16.5rem",
  },
  title: {
    color: theme.palette.text.primary,
    fontFamily: "Montserrat",
    fontSize: 20,
    fontWeight: "bold",
    marginLeft: 20,
  },
  img: {
    width: 40,
    marginRight: 20,
  },
  goBackIcon: {
    color: theme.palette.primary.main,
    width: 30,
    height: 30,
  },
}));
