import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    height: "100vh",
    backgroundColor: "#F4F3F3",
    [theme.breakpoints.down(1240)]: {
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
    },
  },
  recapsContainer: {
    padding: "3rem",
    marginRight: "3rem",
    marginTop: "5rem",
    backgroundColor: "#F4F3F3",
    borderRadius: "1.875rem",
    minWidth: "54rem",
    boxShadow: "0.25rem 0.25rem 0.5rem 0.125rem rgba(0, 0, 0, 0.2)",
    [theme.breakpoints.down(1390)]: {
      padding: "2rem",
    },
    [theme.breakpoints.down(1240)]: {
      padding: "1rem",
      minWidth: "0rem",
      margin: "0rem auto",
      borderRadius: "0rem 0rem 1.25rem 1.25rem",
      boxShadow: "none",
      width: "100%",
    },
  },
}));
