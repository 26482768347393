import React, { useContext, useState } from "react";
import { EmailInput, ButtonPrimary } from "@components";
import { SaveByRokinContext } from "@context";
import { ExistenceValidationType } from "@interfaces";
import { makeStyles } from "@material-ui/core";

const EmailContent: React.FC = () => {
  const { form, handleChange, isAlreadyCreated, finishRegister } =
    useContext(SaveByRokinContext);
  const [invalidEmail, setIsInvalidEmail] = useState(false);
  const disableContinueButton =
    invalidEmail || !form.emailUser || isAlreadyCreated;
  const classes = useStyles();

  return (
    <>
      <EmailInput
        name="emailUser"
        value={form.emailUser}
        onChange={handleChange}
        onErrorChange={setIsInvalidEmail}
        validateExistenceInSAVE={ExistenceValidationType.MUST_NOT_EXISTS}
      />
      <ButtonPrimary
        onClick={finishRegister}
        disabled={disableContinueButton}
        text="Listo"
      />
      {isAlreadyCreated && (
        <section className={classes.mensaje}>
          ¡Ya estás inscrito para el lanzamiento de{" "}
          <span className={classes.part1}>SA</span>
          <span className={classes.part2}>V</span>
          <span className={classes.part1}>E</span>!
        </section>
      )}{" "}
    </>
  );
};

export default EmailContent;

const useStyles = makeStyles(theme => ({
  mensaje: {
    position: "absolute",
    fontFamily: "Montserrat",
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1.46,
    color: "#625c20",
    width: 350,
    bottom: 45,
    [theme.breakpoints.down(1300)]: {
      fontSize: 16,
      width: 300,
      bottom: 60,
    },
    [theme.breakpoints.down(1210)]: {
      bottom: 90,
    },
    [theme.breakpoints.down(1100)]: {
      bottom: -25,
      width: 350,
    },
  },
  containerRegister: {
    [theme.breakpoints.down(1300)]: {
      width: 360,
    },
    [theme.breakpoints.down(400)]: {
      width: 330,
    },
  },
  part1: {
    color: theme.palette.primary.main,
  },
  part2: {
    color: "#625c20",
  },
}));
