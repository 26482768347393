export * from "./form";
export * from "./parsing";
export * from "./validations";
export * from "./productionRoute";
export * from "./file.util";
export * from "./asset-allocation";
export * from "./passwordStrength";
export * from "./filtering";
export * from "./groupArray";
export * from "./missions";
export * from "./searchUserCompany";
export * from "./getAmountTotal";
export * from "./finerioTransactionsByCategory";
export * from "./financialAnalysis";

export const isBrowser = () => typeof window !== "undefined";
export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
