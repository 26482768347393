import React from "react";
import { CommonInputProps } from "..";
import { SizedTextField } from "@components";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends CommonInputProps {}

export const ProfessionInput: React.FC<Props> = ({
  className,
  name,
  label,
  ...props
}) => {
  return (
    <SizedTextField
      className={`${className || ""} altercap-profession-input altercap-input`}
      name={name || "profession"}
      label={label || "Profesión / Estudios"}
      {...props}
    />
  );
};

/*
import { Autocomplete } from "@material-ui/lab";
const emptyOption = { name: "", value: "" };

const handleProfessionChange = useCallback(
  (_, option) => {
    handleChange({
      target: {
        name: "profession",
        value: option?.value || "",
      },
    });
  },
  [handleChange]
);

<Autocomplete
  className={`form-profession ${big ? "big" : ""}`}
  id="insurance-outlined-address-input"
  options={[emptyOption, ...PROFESSION_OPTIONS]}
  getOptionLabel={option => option.name}
  openOnFocus
  value={
    PROFESSION_OPTIONS.find(a => a.value === form.profession) ||
    emptyOption
  }
  onChange={handleProfessionChange}
  renderInput={params => (
    <TextField
      {...params}
      inputProps={{
        ...params.inputProps,
        tabIndex: include.findIndex(v => v === "profession") + 1,
      }}
      label="Profesión / Estudios"
      variant="outlined"
      placeholder="Escribe aquí"
    />
  )}
  getOptionSelected={(option, selected) =>
    option.value === selected.value
  }
  noOptionsText="Sin resultados"
/>
*/
