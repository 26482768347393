import { Route } from "@interfaces";
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AppContext } from "./AppContext";
interface MissionsContextType {
  isStepMission: {
    route: Route;
    number: number;
  };
  isOnBoardModal: boolean;
  setStepMission: Dispatch<SetStateAction<{ route: Route; number: number }>>;
  setOnBoardModal: (value: boolean) => void;
}

export const MissionsContext = createContext({} as MissionsContextType);

export const MissionsProvider: React.FC = ({ children }) => {
  const [isStepMission, setStepMission] = useState<{
    route: Route;
    number: number;
  }>({ route: Route.mision1, number: 1 });
  const [isOnBoardModal, setOnBoardModal] = useState(true);

  const context = useMemo(
    () => ({
      isStepMission,
      isOnBoardModal,
      setStepMission,
      setOnBoardModal,
    }),
    [isStepMission, isOnBoardModal, setStepMission, setOnBoardModal],
  );

  return (
    <MissionsContext.Provider value={context}>
      {children}
    </MissionsContext.Provider>
  );
};
