import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {},
  topText: {
    width: "30rem",
    fontSize: "1.25rem",
    fontWeight: "normal",
    fontFamily: "Montserrat",
    textAlign: "left",
    color: "#008296",
    marginBottom: "2.6rem",
    [theme.breakpoints.down(1239)]: {
      fontSize: "0.85rem",
      textAlign: "center",
      margin: "1rem auto",
      width: "auto",
    },
  },
  middleSection: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "50rem",
    [theme.breakpoints.down(1239)]: {
      margin: "0px auto",
    },
  },
  whiteBox: {
    backgroundColor: "#ffffff",
    boxShadow: "0.25rem 0.25rem 1.25rem 0.125rem rgba(0, 0, 0, 0.2)",
  },
  bigBox: {
    padding: "2.4rem 2rem 2.4rem 2.8rem",
    fontFamily: "Montserrat",
    textAlign: "left",
    borderRadius: "1.875rem",
    // height: "12rem",
    [theme.breakpoints.down(1239)]: {
      padding: "2rem",
    },
    "& .title": {
      fontSize: "1.25rem",
      fontWeight: "bold",
      marginBottom: "1rem",
      color: theme.palette.text.primary,
      [theme.breakpoints.down(1239)]: {
        fontSize: "1rem",
      },
    },
    "& .text": {
      fontSize: "1rem",
      fontWeight: "normal",
      lineHeight: 1.8,
      color: theme.palette.text.secondary,
      [theme.breakpoints.down(1239)]: {
        fontSize: "0.75rem",
      },
    },
  },
  smallBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    marginTop: "2rem",
    borderRadius: "0.9375rem",
    paddingRight: "1.1rem",
    [theme.breakpoints.down(1239)]: {
      width: "100% !important",
      maxWidth: "34.375rem",
    },
    "& >img": {
      height: "6.25rem",
      width: "5rem !important",
      marginTop: "1rem",
      marginBottom: "0.4rem",
      marginLeft: "1rem",
      [theme.breakpoints.down(1239)]: {
        width: "4.25rem !important",
        margin: "0 0 0 1rem",
      },
    },
    "& .text": {
      fontSize: "0.85rem",
      lineHeight: 1.4,
      textAlign: "left",
      color: "#7d7c7c",
      [theme.breakpoints.down(1239)]: {
        fontSize: "0.75rem",
      },
    },
  },
  advises: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginBottom: "2.5rem",
    [theme.breakpoints.down(1239)]: {
      justifyContent: "center",
    },
    "& :nth-child(1)": {
      width: "25.5rem",
    },
    "& :nth-child(2)": {
      width: "21.5rem",
    },
    "& :nth-child(3)": {
      width: "25.5rem",
    },
    "& :nth-child(4)": {
      width: "21.5rem",
    },
  },
  bottomSection: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: "3rem",
    [theme.breakpoints.down(1239)]: {
      justifyContent: "center",
    },
  },
}));
