import React, { useCallback, useContext, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import {
  FormInvestment,
  InvestmentType,
  ProfileDialogProps,
} from "@interfaces";
import { IconButton } from "@material-ui/core";
import { DialogContent } from "@material-ui/core";
import { useInvestmentsOptions } from "@hooks";
import "./InvestmentsDialog.scss";
import { DialogTitle } from "@material-ui/core";
import { DialogActions } from "@material-ui/core";
import { NumericInput, Select, Button } from "@components";
import { InvestmentsContext } from "@context";
import { ButtonOutlined, ButtonPrimary } from "@components/Reusables";

const EMPTY_ENTRY: FormInvestment = {
  type: InvestmentType.MONITOR,
  agfId: null,
  fundId: null,
  serieId: null,
  invested: 0,
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface InvestmentsDialogProps extends ProfileDialogProps {}

export const InvestmentsDialog: React.FC<InvestmentsDialogProps> = ({
  className,
  open,
  onClose,
}) => {
  // Calculate media to display dialog on fullScreen
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (window.matchMedia("(max-width: 1080px)").matches) setIsMobile(true);
  }, []);

  const [agfOptions, fundOptions, serieOptions] = useInvestmentsOptions();
  const { createNewInvestments } = useContext(InvestmentsContext);
  const [investments, setInvestments] = useState<FormInvestment[]>([
    { ...EMPTY_ENTRY },
  ]);

  const addRow = useCallback(
    () => setInvestments(prev => [...prev, EMPTY_ENTRY]),
    [setInvestments],
  );
  const updateRow = useCallback(
    (index: number, payload: Partial<FormInvestment>) =>
      setInvestments(prev =>
        prev.map((inv, i) =>
          i === index
            ? {
                ...inv,
                ...payload,
              }
            : inv,
        ),
      ),
    [setInvestments],
  );
  const deleteRow = useCallback(
    (index: number) =>
      setInvestments(prev =>
        prev.slice(0, index).concat(prev.slice(index + 1)),
      ),
    [setInvestments],
  );

  const resetAndClose = useCallback(() => {
    setInvestments([{ ...EMPTY_ENTRY }]);
    onClose();
  }, [setInvestments, onClose]);

  const ready = useCallback(() => {
    createNewInvestments(investments);
    resetAndClose();
  }, [investments, createNewInvestments, resetAndClose]);

  const validInvestments = investments.every(
    inv => inv.serieId && inv.invested,
  );

  return (
    <Dialog
      maxWidth="xl"
      className={`${className || ""} investments-dialog ${
        !isMobile ? "desktop-mode" : "mobile-mode"
      }`}
      open={open}
      onClose={resetAndClose}
      fullScreen={isMobile}
    >
      <IconButton className="close-button" onClick={resetAndClose}>
        <CloseRoundedIcon />
      </IconButton>
      <DialogTitle>
        Agrega tus inversiones para seguirlas en un solo lugar
      </DialogTitle>
      <DialogContent>
        <div className="inputs-container">
          {investments.map((investment, index) => (
            <React.Fragment key={index}>
              <div className="investment-title-container">
                <div className="investment-number">
                  Inversión N° {index + 1}
                </div>
                <IconButton
                  className="delete-investment-button"
                  onClick={() => deleteRow(index)}
                >
                  <DeleteRoundedIcon />
                </IconButton>
              </div>
              <Select
                label="Administradora"
                name="agf"
                value={investment.agfId ?? ""}
                onChange={({ target: { value } }) => {
                  updateRow(index, {
                    agfId: value as number,
                    fundId: null,
                    serieId: null,
                  });
                }}
                options={agfOptions}
              />
              <Select
                label="Fondo"
                name="fund"
                value={investment.fundId ?? ""}
                onChange={({ target: { value } }) =>
                  updateRow(index, {
                    fundId: value as number,
                    serieId: null,
                  })
                }
                options={fundOptions.filter(f => investment.agfId === f.agfId)}
                disabled={!investment.agfId}
              />
              <Select
                label="Serie"
                name="serie"
                value={investment.serieId ?? ""}
                onChange={({ target: { value } }) =>
                  updateRow(index, {
                    serieId: value as number,
                  })
                }
                options={serieOptions.filter(
                  s =>
                    // investment.agfId === s.agfId &&
                    investment.fundId === s.fundId,
                )}
                disabled={!investment.fundId}
              />
              <NumericInput
                name="invested"
                label="Monto a la fecha de hoy"
                value={investment.invested ?? 0}
                onChange={({ target: { value } }) =>
                  updateRow(index, {
                    invested: value as number,
                  })
                }
              />
              <IconButton
                className="delete-investment-button"
                onClick={() => deleteRow(index)}
              >
                <DeleteRoundedIcon />
              </IconButton>
            </React.Fragment>
          ))}
        </div>
        <div className="add-investment-button">
          <ButtonOutlined text={`Agregar`} onClick={addRow} fullWidth />
        </div>
      </DialogContent>
      <DialogActions>
        <div className="submit-button">
          <ButtonPrimary
            text="Listo"
            onClick={ready}
            disabled={!validInvestments}
            fullWidth
          />
        </div>
      </DialogActions>
    </Dialog>
  );
};
