import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { LayoutDesktop } from './LayoutDesktop';
import { LayoutMobile } from './LayoutMobile';
import { EditGoal } from './EditGoal';
import { LayoutMobileNewVersion } from './NewVersion/LayoutMobile';
import { Goal, Route } from '@interfaces';
import { navigate } from 'gatsby';

interface LayoutProps {
  className?: string;
  styleFooter?: string;
  icon: string;
  buttonContinue: string;
  title: string;
  secondTitle?: string;
  goal?: number;
  goalId?: number;
  goalPeriod?: number;
  goalType?: string;
  allGoal?: () => Goal | undefined;
}
export const LayoutGoal = ({
  className,
  icon,
  buttonContinue,
  title,
  secondTitle,
  goal,
  goalId,
  goalPeriod,
  goalType,
  allGoal,
}: LayoutProps) => {
  const isMobile = useMediaQuery('(max-width: 1280px)');
  return (
    <LayoutMobileNewVersion
      className={className}
      icon={
        'https://storage.googleapis.com/rokinapp-images/investments/CreateNew/safebox_icon%20(3).svg'
      }
      pageName={'Editar Objetivo'}
      onBackClick={() => navigate(Route.investments)}
    >
      <EditGoal
        className={className}
        icon={icon}
        buttonContinue={buttonContinue}
        title={title}
        secondTitle={secondTitle}
        goal={goal}
        goalId={goalId}
        goalPeriod={goalPeriod}
        goalType={goalType}
      />
    </LayoutMobileNewVersion>
  );
};
