import React from "react";
import { useStyles } from "./TopSection2_2.style";
import { useMediaQuery } from "@material-ui/core";

const TopSection2_2: React.FC = () => {
  const isSmallMobile = useMediaQuery("(max-width: 700px)");

  const classes = useStyles();

  return (
    <div className={classes.container}>
      {isSmallMobile ? (
        <>
          <div className={classes.paragraph}>
            <span className={classes.text}>
              Los
              <span className={classes.textStyle}> ingresos variables </span>
              son los fondos que no se reciben de manera constante, sino que
              <span className={classes.textStyle}>
                {" "}
                dependen de actividades específicas.{" "}
              </span>
              A veces el ingreso depende del rendimiento, el volumen de ventas,
              las comisiones, etc. Además, puede ser que en tu caso existan
              bonos o incentivos que te puedes ganar, pero no se reciben todos
              los meses.
            </span>
          </div>
          <div className={`${classes.paragraph} ${classes.p2}`}>
            <span className={classes.text}>
              Si tu
              <span className={classes.textStyle}> sueldo es variable </span>
              te ayudaremos a calcular tu
              <span className={classes.textStyle}> sueldo promedio </span>, para
              ser más realistas con el nivel de ingresos que tienes en el año.
              La mejor manera de estimar lo que podrías recibir en el futuro es
              basarte en tus ingresos anteriores, para eso te sugerimos revisar
              tu cartola e incluir en el simulador
              <span className={classes.textStyle}>
                {" "}
                tus últimos 3 ingresos en cada categoría{" "}
              </span>
              de aporte.
            </span>
          </div>
        </>
      ) : (
        <>
          <div className={classes.paragraph}>
            <span className={classes.text}>
              Los
              <span className={classes.textStyle}> ingresos variables </span>
              son los fondos que no se reciben de manera constante, sino que
              <span className={classes.textStyle}>
                {" "}
                dependen de actividades específicas.{" "}
              </span>
              A veces el ingreso depende del rendimiento, el volumen de ventas,
              las comisiones, etc. Además, puede ser que en tu caso existan
              bonos o incentivos que te puedes ganar, pero no se reciben todos
              los meses.
            </span>
          </div>
          <div className={`${classes.paragraph} ${classes.p2}`}>
            <span className={classes.text}>
              Si tu
              <span className={classes.textStyle}> sueldo es variable </span>
              te ayudaremos a calcular tu
              <span className={classes.textStyle}> sueldo promedio </span>, para
              ser más realistas con el nivel de ingresos que tienes en el año.
              La mejor manera de estimar lo que podrías recibir en el futuro es
              basarte en tus ingresos anteriores, para eso te sugerimos revisar
              tu cartola e incluir en el simulador
              <span className={classes.textStyle}>
                {" "}
                tus últimos 3 ingresos en cada categoría{" "}
              </span>
              de aporte.
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default TopSection2_2;
