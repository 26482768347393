import { ApolloClient } from "@apollo/client";
import { gql } from "@apollo/client";

const DISABLE_ENABLE_FINERIO_CREDENTIAL = gql`
  mutation DisableFinerioCredential($credentialId: Int!) {
    disableFinerioCredential(credentialId: $credentialId) {
      enabled
    }
  }
`;

type DisableEnableFinerioCredentialParams = {
  credentialId: number;
};

type DisableEnableFinerioCredentialResult = {
  disableEnableFinerioCredential: boolean;
};

export async function disableEnableFinerioCredential(
  client: ApolloClient<any>,
  params: DisableEnableFinerioCredentialParams
): Promise<DisableEnableFinerioCredentialResult> {
  console.log(params)
  const { data } = await client.mutate({
    mutation: DISABLE_ENABLE_FINERIO_CREDENTIAL,
    variables: params,
  });
  return data;
}