import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles<Theme, { timeLoad: number }>((theme) => ({
  container: {
    height: 800,
    position: "relative",
    fontFamily: "Montserrat",
    color: theme.palette.text.primary,
    [theme.breakpoints.down(1240)]: {
      height: 405,
    },
  },
  top: {
    display: "flex",
    width: 375,
    height: 300,
    paddingTop: 30,
    borderRadius: 20,
    boxShadow: "4px 4px 15px 5px rgba(0, 0, 0, 0.25)",
    zIndex: 1,
    position: "relative",
    background:
      "linear-gradient(269deg, rgb(0, 170, 188) 30%, rgb(5, 130, 149) 63%, rgb(10, 86, 105) 88%)",
    [theme.breakpoints.down(1240)]: {
      width: "100%",
      borderRadius: 0,
    },
    "& img": {
      width: 180,
      height: 180,
      marginLeft: 30,
    },
    justifyContent: "center",
  },
  iconArrow: {
    color: "white",
    background: theme.palette.text.primary,
    height: 45,
    cursor: "pointer",
    width: 45,
    padding: "5px 0 5px 14px",
    display: "flex",
    justifyContent: "center",
    borderRadius: 5,
    marginTop: 40,
  },
  bottom: {
    position: "absolute",
    borderRadius: 30,
    width: 375,
    height: "auto",
    boxShadow: "4px 4px 15px 5px rgba(0, 0, 0, 0.25)",
    marginBottom: 20,
    top: 150,
    zIndex: 4,
    background: "white",
    padding: "20px 27px",
    [theme.breakpoints.down(1240)]: {
      width: "100%",
      background: "#edebeb",
      boxShadow: "none",
    },
    "& .title": {
      fontSize: 24,
      fontWeight: "bold",
    },
    "& .text": {
      fontSize: "0.875rem",
      fontWeight: 300,
      padding: "10px 0",
    },
    "& .load": {
      display: "flex",
      fontSize: 14,
      alignItems: "center",
      color: theme.palette.text.secondary,
      [theme.breakpoints.down(1240)]: {
        width: 375,
        margin: "0 auto",
      },
      "& img": {
        marginRight: 10,
      },
    },
    "& .button": {
      padding: "25px 15px",
      boxShadow: "1px 1px 5px 5px rgba(0, 0, 0, 0.1)",
      display: "flex",
      position: "relative",
      margin: "20px 0",
      cursor: "pointer",
      "& img": {
        position: "absolute",
        top: -10,
        left: 0,
      },
      "& span": {
        marginLeft: 80,
      },
    },
  },
  line: {
    width: "100%",
    height: 12,
    margin: "30px 0 15px",
    "& svg": {
      borderRadius: "15px",
      boxShadow: "1px 1px 5px 5px rgba(0, 0, 0, 0.25)",
    },
    [theme.breakpoints.down(1240)]: {
      width: 350,
      margin: "0 auto",
    },
  },
  containerLine: {
    [theme.breakpoints.down(1240)]: {
      display: "flex",
      margin: "0 auto",
      alignItems: "center",
    },
  },
  cursor: {
    cursor: "pointer",
  },
}));
