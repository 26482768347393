const WALLET_ICON =
  'https://storage.googleapis.com/rokinapp-images/SmartSave/Wallet%201.svg';
const FLAG_LIGHT_GREEN_ICON =
  'https://storage.googleapis.com/rokinapp-images/SmartSave/flag_icon_light_green.svg';
const GRAPH_ASCENDING_ICON =
  'https://storage.googleapis.com/rokinapp-images/SmartSave/graph_ascending_icon.svg';
const ALERT_GREEN_ICON =
  'https://storage.googleapis.com/rokinapp-images/SmartSave/Group%201385%20(5).svg';
const CAR_BACKGROUND_GREEN_ICON =
  'https://storage.googleapis.com/rokinapp-images/SmartSave/typeRule/car.svg';
const CERDO_BACKGROUND_GREEN_ICON =
  'https://storage.googleapis.com/rokinapp-images/SmartSave/typeRule/cerdo.svg';
const MONEY_BACKGROUND_GREEN_ICON =
  'https://storage.googleapis.com/rokinapp-images/SmartSave/typeRule/money.svg';
const CAR_BACKGROUND_DARK_GREEN_ICON =
  'https://storage.googleapis.com/rokinapp-images/SmartSave/iconsDarkGreen/car.svg';
const CERDO_BACKGROUND_DARK_GREEN_ICON =
  'https://storage.googleapis.com/rokinapp-images/SmartSave/iconsDarkGreen/cerdo.svg';
const MONEY_BACKGROUND_DARK_GREEN_ICON =
  'https://storage.googleapis.com/rokinapp-images/SmartSave/iconsDarkGreen/money.svg';

const CAR_SILHOUETTE_GREY_ICON =
  'https://storage.googleapis.com/rokinapp-images/SmartSave/iconsSilhouette/Layer_1.svg';
const CERDO_SILHOUETTE_GREY_ICON =
  'https://storage.googleapis.com/rokinapp-images/SmartSave/iconsSilhouette/piggyBank_icon.svg';
const MONEY_SILHOUETTE_GREY_ICON =
  'https://storage.googleapis.com/rokinapp-images/SmartSave/iconsSilhouette/cashback_icon.svg';

const CAR_SILHOUETTE_DARK_WHITE_ICON =
  'https://storage.googleapis.com/rokinapp-images/SmartSave/iconsSilhouette/buy_icon (1).svg';
const CERDO_SILHOUETTE_WHITE_ICON =
  'https://storage.googleapis.com/rokinapp-images/SmartSave/iconsSilhouette/piggyBank_icon (1).svg';
const MONEY_SILHOUETTE_WHITE_ICON =
  'https://storage.googleapis.com/rokinapp-images/SmartSave/iconsSilhouette/cashback_icon (1).svg';

const CALENDAR_GREEN_ICON =
  'https://storage.googleapis.com/rokinapp-images/icons/calendar_green_icon.svg';
const MONEY_GREEN_ICON =
  'https://storage.googleapis.com/rokinapp-images/icons/money_green_icon.svg';
const FINTOC_ICON =
  'https://storage.googleapis.com/rokinapp-images/SmartSave/fintoncLogo%201.svg';

const CHECK_SQUARE_COLOR_ICON =
  'https://storage.googleapis.com/rokinapp-images/SmartSave/checkazulbackground.svg';

export {
  CHECK_SQUARE_COLOR_ICON,
  CAR_SILHOUETTE_DARK_WHITE_ICON,
  CERDO_SILHOUETTE_WHITE_ICON,
  MONEY_SILHOUETTE_WHITE_ICON,
  CERDO_SILHOUETTE_GREY_ICON,
  MONEY_SILHOUETTE_GREY_ICON,
  CAR_SILHOUETTE_GREY_ICON,
  FINTOC_ICON,
  CALENDAR_GREEN_ICON,
  MONEY_GREEN_ICON,
  CAR_BACKGROUND_DARK_GREEN_ICON,
  CERDO_BACKGROUND_DARK_GREEN_ICON,
  MONEY_BACKGROUND_DARK_GREEN_ICON,
  CAR_BACKGROUND_GREEN_ICON,
  WALLET_ICON,
  FLAG_LIGHT_GREEN_ICON,
  GRAPH_ASCENDING_ICON,
  ALERT_GREEN_ICON,
  CERDO_BACKGROUND_GREEN_ICON,
  MONEY_BACKGROUND_GREEN_ICON,
};
