import React from "react";
import { makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import { Header, LayoutOnBoarding } from "@components";
import { Footer } from "../../Structural/Landing/Footer";
import { useAuth } from "@hooks";
import { LayoutMobileInvestments } from "@components/Investments/Layout";
interface LayoutProps {
  children: React.ReactNode;
  className?: string;
  simpleHeader?: boolean;
  styleFooter?: string;
  impactRokin?: boolean;
  ourSolutions?: boolean;
  aboutUs?: boolean;
  helpCenter?: boolean;
  secondLanding?: boolean;
}
export const LayoutBild: React.FC<LayoutProps> = ({
  className,
  children,
  simpleHeader,
  styleFooter,
  ourSolutions = false,
  impactRokin = false,
  aboutUs,
  helpCenter,
  secondLanding,
}) => {
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const { isLoggedIn } = useAuth();
  const classes = useStyles({ isLoggedIn });

  return (!isMobile && !isLoggedIn) || (isMobile && !isLoggedIn) ? (
    <div className={`${className || ""} ${classes.container}`}>
      <Header
        impactRokin={impactRokin}
        ourSolutions={ourSolutions}
        simpleHeader={simpleHeader}
        aboutUs={aboutUs}
        helpCenter={helpCenter}
        secondLanding={secondLanding}
      />{" "}
      <div className={classes.children}>{children}</div>
      <Footer className={`${classes.footer} ${styleFooter}`} />
    </div>
  ) : (
    <div className={`${className || ""} ${classes.container}`}>
      <Header
        impactRokin={impactRokin}
        ourSolutions={ourSolutions}
        simpleHeader={simpleHeader}
        aboutUs={aboutUs}
        helpCenter={helpCenter}
        secondLanding={secondLanding}
      />{" "}
      <div className={classes.children}>{children}</div>
      <Footer className={`${classes.footer} ${styleFooter}`} />
    </div>
  );
};

const useStyles = makeStyles<Theme, { isLoggedIn: boolean }>((theme) => ({
  footer: {},
  container: {
    overflowX: "hidden",
    background: "#F4F3F3",
  },
  children: {
    marginTop: 70,
  },
}));
