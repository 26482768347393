import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  whiteBox: {
    display: "flex",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "0.65rem",
    padding: "0.8rem",
    boxShadow: "0.25rem 0.25rem 0.5rem 0.125rem rgba(0, 0, 0, 0.2)",
    width: "21rem",
    margin: "1rem 0",
    [theme.breakpoints.down(1240)]: {
      width: "100%",
    },
  },
  imageContainer: {
    marginRight: "0.5rem",
    "& img": {
      width: "2.125rem",
      height: "2.125rem",
    },
  },
  textContainer: {
    fontFamily: "Montserrat",
    fontSize: "0.75rem",
    fontWeight: 500,
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    padding: "0 0 0 0.5rem",
    "& .title": {
      color: theme.palette.text.secondary,
      textTransform: "uppercase",
    },
    "& .description": {
      color: theme.palette.text.primary,
    },
  },
  amountContainer: {
    marginLeft: "auto",
    fontSize: "0.875rem",
    fontWeight: "bold",
    textAlign: "right",
    color: theme.palette.text.primary,
    display: "flex",
    alignItems: "center",
  },
}));
