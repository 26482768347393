import React, { useCallback } from "react";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import {
  GENDER_OPTIONS,
  INCOMES_OPTIONS,
  MAIN_GOAL_OPTIONS,
  MONTHLY_SAVING_OPTIONS,
  SMART_RISK_OPTIONS,
  SAVED_CAPITAL_OPTIONS,
  TERM_OPTIONS,
  cropOptions,
  EXPERIENCE_LEVELS,
  CONCERNS_OPTIONS,
  CURRENT_INVESTMENTS_OPTIONS,
  DROP_RESPONSE_OPTIONS,
  Select,
} from "@components";
import {
  FORM_FIELDS,
  GoalForm,
  ProfileForm,
  ProfileFormErrors,
} from "@interfaces";
import { DateInput } from "@components";
import { useState } from "react";
import ShowIcon from "@material-ui/icons/Visibility";
import HideIcon from "@material-ui/icons/VisibilityOff";

interface ProfileFieldsProps {
  form: ProfileForm | GoalForm;
  errors?: ProfileFormErrors;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleChange: (e: any) => void;
  include?: typeof FORM_FIELDS[number][];
  exclude?: typeof FORM_FIELDS[number][];
  native?: boolean;
  big?: boolean;
}
export const ProfileFields: React.FC<ProfileFieldsProps> = ({
  form,
  errors,
  handleChange,
  include = FORM_FIELDS,
  exclude,
  native,
  big,
}) => {
  const display = Object.assign(
    {},
    ...FORM_FIELDS.map((field) => ({
      [field]: include.includes(field) && !exclude?.includes(field),
    }))
  ) as Record<typeof FORM_FIELDS[number], boolean>;
  const [showPass, setShowPass] = useState(false);
  const handleToggleVisibility = useCallback(() => {
    setShowPass((prev) => !prev);
  }, [setShowPass]);

  return (
    <>
      {display.name && "name" in form && (
        <TextField
          className={`form-name ${big ? "big" : ""}`}
          label="Nombre"
          variant="outlined"
          name="name"
          value={form.name}
          onChange={handleChange}
          inputProps={{ tabIndex: include.findIndex((v) => v === "name") + 1 }}
        />
      )}
      {display.email && "email" in form && (
        <TextField
          className={`form-email ${big ? "big" : ""}`}
          label="Correo"
          variant="outlined"
          name="email"
          value={form.email}
          onChange={handleChange}
          type="email"
          error={errors?.email}
          inputProps={{ tabIndex: include.findIndex((v) => v === "email") + 1 }}
        />
      )}
      {display.password && "password" in form && (
        <TextField
          className={`form-password ${big ? "big" : ""}`}
          label="Contraseña"
          variant="outlined"
          name="password"
          value={form.password}
          onChange={handleChange}
          type={!showPass ? "password" : "text"}
          error={errors?.password}
          inputProps={{
            tabIndex: include.findIndex((v) => v === "password") + 1,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton size="small" onClick={handleToggleVisibility}>
                  {showPass ? <HideIcon /> : <ShowIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
      {display.birthdate && "birthdate" in form && (
        <DateInput
          className={`form-birthdate ${big ? "big" : ""}`}
          name="birthdate"
          label="Fecha nacimiento (dd/mm/yyyy)"
          value={form.birthdate}
          onChange={handleChange}
          error={errors?.birthdate}
          tabIndex={include.findIndex((v) => v === "birthdate") + 1}
        />
      )}
      {display.gender && "gender" in form && (
        <Select
          className={`form-gender ${big ? "big" : ""}`}
          label="Género"
          name="gender"
          value={form.gender}
          onChange={handleChange}
          options={GENDER_OPTIONS}
          native={native}
          tabIndex={include.findIndex((v) => v === "gender") + 1}
        />
      )}
      {display.profession && "profession" in form && (
        <TextField
          className={`form-profession ${big ? "big" : ""}`}
          label="Profesión / Estudios"
          variant="outlined"
          name="profession"
          value={form.profession}
          onChange={handleChange}
          inputProps={{
            tabIndex: include.findIndex((v) => v === "profession") + 1,
          }}
        />
      )}
      {display.incomes && "incomes" in form && (
        <Select
          className={`form-incomes ${big ? "big" : ""}`}
          label="Rango ingresos"
          name="incomes"
          value={form.incomes}
          onChange={handleChange}
          options={INCOMES_OPTIONS}
          native={native}
        />
      )}
      {display.monthlySaving && "monthlySaving" in form && (
        <Select
          className={`form-monthlySaving ${big ? "big" : ""}`}
          label="Ahorro mensual"
          name="monthlySaving"
          value={form.monthlySaving}
          onChange={handleChange}
          options={MONTHLY_SAVING_OPTIONS}
          native={native}
        />
      )}
      {display.savedCapital && "savedCapital" in form && (
        <Select
          className={`form-savedCapital ${big ? "big" : ""}`}
          label="Capital ahorrado"
          name="savedCapital"
          value={form.savedCapital}
          onChange={handleChange}
          options={cropOptions(SAVED_CAPITAL_OPTIONS)}
          native={native}
        />
      )}
      {display.mainGoal && "mainGoal" in form && (
        <Select
          className={`form-mainGoal ${big ? "big" : ""}`}
          label="Principal objetivo"
          name="mainGoal"
          value={form.mainGoal}
          onChange={handleChange}
          options={MAIN_GOAL_OPTIONS}
          native={native}
        />
      )}
      {display.investmentTerm && "investmentTerm" in form && (
        <Select
          className={`form-investmentTerm ${big ? "big" : ""}`}
          label="Plazo de inversión"
          name="investmentTerm"
          value={form.investmentTerm ?? ""}
          onChange={handleChange}
          options={TERM_OPTIONS}
          native={native}
        />
      )}
      {display.risk && "risk" in form && (
        <Select
          className={`form-risk ${big ? "big" : ""}`}
          label="Si invierto 1 millon quiero:"
          name="risk"
          value={form.risk}
          onChange={handleChange}
          options={SMART_RISK_OPTIONS}
          native={native}
        />
      )}
      {display.investmentExperience && "investmentExperience" in form && (
        <Select
          className={`form-risk ${big ? "big" : ""}`}
          label="Mi experiencia invirtiendo es:"
          name="investmentExperience"
          value={form.investmentExperience}
          onChange={handleChange}
          options={EXPERIENCE_LEVELS}
          native={native}
        />
      )}
      {display.investmentConcern && "investmentConcern" in form && (
        <Select
          className={`form-risk ${big ? "big" : ""}`}
          label="Cuando invierto"
          name="investmentConcern"
          value={form.investmentConcern}
          onChange={handleChange}
          options={CONCERNS_OPTIONS}
          native={native}
        />
      )}
      {display.currentInvesting && "currentInvesting" in form && (
        <Select
          className={`form-risk ${big ? "big" : ""}`}
          label="Actualmente invierto en"
          name="currentInvesting"
          value={form.currentInvesting}
          onChange={handleChange}
          options={CURRENT_INVESTMENTS_OPTIONS}
          native={native}
        />
      )}
      {display.investmentDropResponse && "investmentDropResponse" in form && (
        <Select
          className={`form-risk ${big ? "big" : ""}`}
          label="Si mi inversión cae un 25%, tu: "
          name="investmentDropResponse"
          value={form.investmentDropResponse}
          onChange={handleChange}
          options={DROP_RESPONSE_OPTIONS}
          native={native}
        />
      )}
    </>
  );
};
