import React, { useCallback, useState, useEffect } from "react";
import { debounce } from "lodash";
import { InputAdornment, CircularProgress, Theme } from "@material-ui/core";
import { CommonInputProps } from "..";
import { ChangeEvent, ExistenceValidationType } from "@interfaces";
import { validateExistingEmail, validateExistingEmailInSAVE } from "@apollo";
import { validateEmail } from "@utils";
import { makeStyles } from "@material-ui/core";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import { SizedTextField } from "@components";
import { useApolloClient } from "@apollo/client";

interface Props extends CommonInputProps {
  onErrorChange?: (error: boolean) => void;
  disableValidation?: boolean;
  validateExistenceInSAVE?: ExistenceValidationType;
  validateExistence?: ExistenceValidationType;
}

const MUST_NOT_EXISTS_ERROR_MESSAGE = "Este email ya está en uso";
const MUST_EXISTS_ERROR_MESSAGE = "Este email no está registrado";
const FORMAT_ERROR_MESSAGE = "Formato de email no válido";
const DEBOUNCE_TIME = 800;

export const EmailInput: React.FC<Props> = ({
  className,
  name,
  label,
  value,
  onChange,
  onErrorChange,
  error,
  helperText,
  disableValidation = false,
  validateExistence,
  validateExistenceInSAVE,
  ...props
}) => {
  const client = useApolloClient();
  // Variable to track API request and display loader inside input
  const [validatingAsync, setValidatingAsync] = useState(false);
  // Variable to track between the user enters a letter and the request begins (while DEBOUNCE_TIME)
  const [pendingAsyncValidation, setPendingAsyncValidation] = useState(false);
  // Variable to save error on email format
  const [formatError, setFormatError] = useState(false);
  // Variable to save error of email-in-use
  const [existenceError, setExistenceError] = useState(false);

  const debouncedValidateFormat = useCallback(
    debounce(
      (value: string) => setFormatError(value !== "" && !validateEmail(value)),
      DEBOUNCE_TIME,
    ),
    [setFormatError],
  );
  const debouncedValidateExistence = useCallback(
    debounce((value: string) => {
      if (!value) return;
      setValidatingAsync(true);
      setPendingAsyncValidation(false);
      validateExistingEmail(value, client).then(exists => {
        const error =
          (validateExistence === ExistenceValidationType.MUST_EXISTS &&
            !exists) ||
          (validateExistence === ExistenceValidationType.MUST_NOT_EXISTS &&
            exists);
        setExistenceError(error);
        setValidatingAsync(false);
      });
    }, DEBOUNCE_TIME),
    [
      validateExistenceInSAVE,
      setValidatingAsync,
      setExistenceError,
      setPendingAsyncValidation,
    ],
  );
  const debouncedValidateExistenceInSAVE = useCallback(
    debounce((value: string) => {
      if (!value) return;
      setValidatingAsync(true);
      setPendingAsyncValidation(false);
      validateExistingEmailInSAVE(value, client).then(exists => {
        const error =
          (validateExistenceInSAVE === ExistenceValidationType.MUST_EXISTS &&
            !exists) ||
          (validateExistenceInSAVE ===
            ExistenceValidationType.MUST_NOT_EXISTS &&
            exists);
        setExistenceError(error);
        setValidatingAsync(false);
      });
    }, DEBOUNCE_TIME),
    [
      validateExistenceInSAVE,
      setValidatingAsync,
      setExistenceError,
      setPendingAsyncValidation,
    ],
  );

  const onChangeInner = useCallback(
    async (e: ChangeEvent) => {
      const value = (e.target.value as string).toLowerCase();
      setFormatError(false);
      debouncedValidateFormat(value);
      onChange({
        target: {
          name: e.target.name,
          value,
        },
      } as ChangeEvent);
      if (validateExistence) {
        setExistenceError(false);
        setPendingAsyncValidation(true);
        debouncedValidateExistence(value);
      }
      if (validateExistenceInSAVE) {
        setExistenceError(false);
        setPendingAsyncValidation(true);
        debouncedValidateExistenceInSAVE(value);
        debouncedValidateExistence(value);
      }
    },
    [
      setFormatError,
      setExistenceError,
      setPendingAsyncValidation,
      debouncedValidateFormat,
      onChange,
      validateExistenceInSAVE,
      validateExistence,
      validateExistenceInSAVE,
      debouncedValidateExistence,
      debouncedValidateExistenceInSAVE,
    ],
  );

  const innerError = formatError || existenceError;
  const displayError = !disableValidation && (error || innerError);

  const classes = useStyles({
    validatingAsync,
    pendingAsyncValidation,
    disableValidation,
    displayError,
    noValue: value === "",
  });

  useEffect(() => {
    if (onErrorChange) {
      onErrorChange(innerError || pendingAsyncValidation || validatingAsync);
    }
  }, [onErrorChange, innerError, pendingAsyncValidation, validatingAsync]);

  return (
    <SizedTextField
      className={`altercap-email-input altercap-input ${className || ""} `}
      name={name || "email"}
      label={label || "Correo"}
      {...props}
      value={value}
      error={displayError}
      helperText={
        displayError
          ? helperText ??
            (formatError
              ? FORMAT_ERROR_MESSAGE
              : existenceError
              ? validateExistence === ExistenceValidationType.MUST_NOT_EXISTS ||
                validateExistenceInSAVE ===
                  ExistenceValidationType.MUST_NOT_EXISTS
                ? MUST_NOT_EXISTS_ERROR_MESSAGE
                : MUST_EXISTS_ERROR_MESSAGE
              : "")
          : undefined
      }
      onChange={onChangeInner}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <CircularProgress className={classes.emailInputLoader} size={25} />
            <CheckRoundedIcon className={classes.emailInputCheck} />
          </InputAdornment>
        ),
      }}
    />
  );
};

type StyleProps = {
  validatingAsync: boolean;
  pendingAsyncValidation: boolean;
  disableValidation: boolean;
  displayError: boolean;
  noValue: boolean;
};
const useStyles = makeStyles<Theme, StyleProps>(() => ({
  emailInputLoader: {
    display: ({ validatingAsync }) => (validatingAsync ? "inherit" : "none"),
  },
  emailInputCheck: {
    display: ({ validatingAsync }) => (!validatingAsync ? "inherit" : "none"),
    visibility: ({
      disableValidation,
      displayError,
      pendingAsyncValidation,
      noValue,
    }) =>
      disableValidation || displayError || pendingAsyncValidation || noValue
        ? "hidden"
        : "inherit",
  },
}));
