import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles<Theme>((theme) => ({
  PersonalDataContainer: {
    minWidth: "47.5rem",
    [theme.breakpoints.down(1241)]: {
      minWidth: "100%",
    },
  },

  topImage: {
    "& img": {
      margin: "0 auto",
    },
  },
  topButton: {
    display: "flex",
    justifyContent: "flex-end",
  },
  bottomButton: {
    margin: "3rem 0",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    "& button": {
      margin: "1rem auto",
      width: "14rem",
    },
  },
  formSection: {},
  section: {
    // width: "46rem !important",
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `0.063rem solid #d6d6d6`,
    color: theme.palette.text.primary,
    fontSize: "1rem",
    fontWeight: 400,
    paddingTop: "2rem",
    fontFamily: "Montserrat",
    paddingBottom: "0.6rem",
    "& svg": {
      color: theme.palette.primary.main,
      margin: "0 10px",
    },
  },
  sectionTitle: {},
  arrow: {
    transform: "rotate(-90deg)",
    cursor: "pointer",
  },
  arrowRotate: {
    transition: "0.5s",
    transform: "rotate(0deg)",
  },
  loading: {
    marginRight: "1rem",
    [theme.breakpoints.down(1241)]: {
      margin: "0 auto",
    },
  },
  check: {
    // make animation during 1s and repeat it 3 times (3s), scale from 0 to 1
    animation: "$check 1s alternate infinite ease-in-out",

    marginRight: "1rem",

    filter:
      " brightness(0) saturate(100%) invert(40%) sepia(70%) saturate(5863%) hue-rotate(168deg) brightness(89%) contrast(101%)",
  },
  "@keyframes check": {
    "0%": { transform: "scale(0.8)" },
    "50%": { transform: "scale(1.3)" },
    "100%": { transform: "scale(0.8)" },
  },

  sectionItem: {},
}));
