import { gql, useQuery } from "@apollo/client";
import { PortfolioHistoricalData } from "../../interfaces";

const GET_PORTFOLIO_HISTORICAL_VALUES = gql`
  query GetPortfolioHistoricalValues($portfolioId: Int!) {
    getPortfolioHistoricalValues(portfolioId: $portfolioId) {
      id
      data {
        date
        value
      }
    }
  }
`;

interface QueryParams {
  portfolioId: number;
}

interface QueryResult {
  getPortfolioHistoricalValues: PortfolioHistoricalData | null;
}

interface HookReturn {
  data: PortfolioHistoricalData | null;
  loading: boolean;
}

export const usePortfolioHistoricalValues = (
  portfolioId?: number,
): HookReturn => {
  if (!portfolioId) return { data: null, loading: false };
  const { data, loading } = useQuery<QueryResult, QueryParams>(
    GET_PORTFOLIO_HISTORICAL_VALUES,
    { variables: { portfolioId }, fetchPolicy: "network-only" },
  );
  return { data: data?.getPortfolioHistoricalValues ?? null, loading };
};
