import React from "react";
import { makeStyles } from "@material-ui/core";
import { arrowRight } from "images/Goal";
import { navigate } from "gatsby";
import { Route } from "@interfaces";

interface HeaderProps {
  title: string;
  subtitle?: string;
}

export const Header = ({ title, subtitle }: HeaderProps) => {
  const classes = useStyles();
  return (
    <header className={classes.header}>
      <div>
        <img
          className={classes.img}
          src={arrowRight}
          onClick={() => navigate(Route.index)}
        />
      </div>
      <div>
        <span className={classes.subtitle}>{subtitle}</span>
        <div className={classes.title}>{title}</div>
      </div>
    </header>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "start",
    height: 90,
    alignItems: "center",
    position: "fixed",
    width: "100%",
    zIndex: 999,
    backgroundColor: "#F4F3F3",
    boxShadow: "8px 8px 12px 0 rgba(0, 0, 0, 0.1)",
    borderRadius: "0 0 30px 30px",
    "& span": {
      textTransform: "inherit",
    },
    "& img": {
      marginLeft: "1rem",
      cursor: "pointer",
    },
  },
  title: {
    color: theme.palette.text.primary,
    fontFamily: "Montserrat",
    fontSize: 20,
    fontWeight: "bold",
    marginLeft: 20,
  },
  subtitle: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontFamily: "Montserrat",
    fontWeight: 400,
    marginLeft: 20,
    display: "block",
  },
  img: {
    width: 30,
    marginRight: 20,
  },
}));
