export const FINANCIAL_PRODUCTS_OPTIONS = [
  "Tarjeta de crédito",
  "Cuenta Corriente",
  "Cuenta Vista",
  "Seguro de vida",
  "Seguro contra accidentes",
  "Seguro de salud",
  "Seguro de hogar",
  "Seguro de mascota",
  "Seguro automotriz",
  "Seguro deportivo",
].map((g) => ({
  name: g,
  value: g,
}));