import { ApolloClient } from "@apollo/client";
import { gql } from "@apollo/client";

const DISABLE_ENABLE_FINERIO_ACCOUNT = gql`
  mutation DisableEnableFinerioAccount($accountId: Int!) {
    disableEnableFinerioAccount(accountId: $accountId) {
      enabled
    }
  }
`;

type DisableEnableFinerioAccountParams = {
  accountId: number;
};

type DisableEnableFinerioAccountResult = {
  disableEnableFinerioAccount: boolean;
};

export async function disableEnableFinerioAccount(
  client: ApolloClient<any>,
  params: DisableEnableFinerioAccountParams
): Promise<DisableEnableFinerioAccountResult> {
  const { data } = await client.mutate({
    mutation: DISABLE_ENABLE_FINERIO_ACCOUNT,
    variables: params,
  });
  return data;
}
