import { ApolloClient, gql } from "@apollo/client";
import { GET_PORTFOLIOS_NAMES_QUERY, GET_PORTFOLIOS_QUERY } from "..";

const INVEST_ON_PORTFOLIO = gql`
  mutation ReinvestOnPortfolio($params: ReinvestOnPortfolioParams!) {
    reinvestOnPortfolio(params: $params)
  }
`;

type ReinvestOnPortfolioParams = {
  params: {
    portfolioId: number;
    amount: number;
  };
};

export async function reinvestOnPortfolio(
  params: ReinvestOnPortfolioParams["params"],
  client: ApolloClient<object>,
): Promise<boolean> {
  const result = await client.mutate<boolean, ReinvestOnPortfolioParams>({
    mutation: INVEST_ON_PORTFOLIO,
    variables: { params },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_PORTFOLIOS_NAMES_QUERY,
      },
      {
        query: GET_PORTFOLIOS_QUERY,
      },
    ],
  });
  return !!result.data;
}
