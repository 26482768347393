import React from "react";
import {
  FacebookLogo,
  InstagramLogo,
  LinkedinLogo,
  TwitterLogo,
} from "@images";
import { makeStyles, Theme } from "@material-ui/core";
import { RRSSdata } from "@data";

interface SocialIconsProps {
  isMobile?: boolean;
}

export const SocialIcons: React.FC<SocialIconsProps> = ({ isMobile }: SocialIconsProps) => {
const classes = useStyles();
  return (
    <ul className={classes.list}>
        <li className={`${isMobile && classes.listItem}`}>
            <a href={RRSSdata.facebookUrl} target="_blank" rel="noreferrer">
                <img
                    className={classes.imgList}
                    src={FacebookLogo}
                    alt="Facebook"
                />
            </a>
        </li>
        <li className={classes.listItem}>
            <a href={RRSSdata.instagramUrl} target="_blank" rel="noreferrer">
                <img
                    className={classes.imgList}
                    src={InstagramLogo}
                    alt="Instagram"
                />
            </a>
        </li>
        <li className={classes.listItem}>
            <a href={RRSSdata.linkedinUrl} target="_blank" rel="noreferrer">
                <img
                    className={classes.imgList}
                    src={LinkedinLogo}
                    alt="Linkedin"
                />
            </a>
        </li>
        <li className={classes.listItem}>
            <a href={RRSSdata.twitterUrl} target="_blank" rel="noreferrer">
                <img
                    className={classes.imgList}
                    src={TwitterLogo}
                    alt="Twitter"
                />
            </a>
        </li>
    </ul>
  );
};

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  li: {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 600,
  },
  list: {
    paddingTop: 10,
    marginBottom: 4,
    paddingLeft: "1.5rem",
    flexDirection: "row",
    listStyle: "none",
    padding: 0,
    display: "flex",
    alignItems: "flex-start",
    [theme.breakpoints.down(768)]: {
      paddingTop: 0,
      marginBottom: 24,
      justifyContent: "space-around",
      flexWrap: "wrap",
      width: 100,
      textAlign: "start",
      gap: "1rem",
    },
  },
  listItem: {
    marginLeft: "1rem",
    [theme.breakpoints.down(1240)]: {
      marginLeft: 0,
    },
  },
  imgList: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  listUl: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up(768)]: {
      flexDirection: "row",
    },
  },
  listUlLi: {
    color: "rgba(255, 255, 255)",
    fontSize: 14,
    lineHeight: "14px",
    marginBottom: 32,
    [theme.breakpoints.up(768)]: {
      margiBottom: 0,
      marginRight: 64,
    },
    [theme.breakpoints.up(1024)]: {
      marginRight: 128,
    },
  },
}));
