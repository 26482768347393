import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Button as MuiButton } from "@material-ui/core";
import { COMPONENT_SIZES, SizeType } from "@styles";

type Props = {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
  fullWidth?: boolean;
  startIcon?: React.ReactNode;
  size?: SizeType; // 30, 36, 44, 48, 56
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  secondaryVersion?: boolean;
  noDegraded?: boolean;
};

export const ButtonPrimary: React.FC<Props> = ({
  className,
  text,
  onClick,
  disabled,
  fullWidth,
  startIcon,
  size = "large",
  onMouseEnter,
  onMouseLeave,
  secondaryVersion,
  noDegraded,
}) => {
  const classes = useStyles({ size, secondaryVersion, disabled, noDegraded });
  return (
    <MuiButton
      className={`${className} ${classes.root}`}
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      fullWidth={fullWidth}
      startIcon={startIcon}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disableRipple
    >
      {text}
    </MuiButton>
  );
};

const useStyles = makeStyles<
  Theme,
  {
    size: Props["size"];
    secondaryVersion?: boolean;
    disabled?: boolean;
    noDegraded?: boolean;
  }
>((theme) => ({
  root: {
    textTransform: "none",
    fontWeight: "normal",
    background: ({ secondaryVersion }) =>
      secondaryVersion
        ? theme.palette.secondary.main
        : theme.palette.primary.main,
    opacity: ({ disabled }) => (disabled ? 0.6 : 1),
    color: "white!important",
    borderRadius: 10,
    minWidth: 177,
    height: ({ size }) => COMPONENT_SIZES[size ?? "large"],
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      boxShadow: "none",
    },
  },
}));
