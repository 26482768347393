import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { SeoImage } from "@images";
import { DEFAULT_TITLE } from "@data";

const activeEnv = (
  process.env.GATSBY_ACTIVE_ENV ||
  process.env.NODE_ENV ||
  "development"
).trim();

interface SEOProps {
  title?: string;
  description?: string;
  lang?: string;
  keywords?: string[];
  url?: string;
}
export const SEO: React.FC<SEOProps> = ({
  title,
  description,
  lang = "es",
  keywords = [],
  url,
}) => {
  const metaTitle = title || DEFAULT_TITLE;
  const metaDescription = description || "";
  const metaAuthor = "";

  const [disableIndexingMeta] = useState(() =>
    activeEnv !== "production" ? [{ name: "robots", content: "noindex" }] : []
  );

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      defer={false}
      title={metaTitle}
      meta={[
        {
          name: "facebook-domain-verification",
          content: "oxitbjd72onymygo00jnqrerrgjdv4",
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: "og:url",
          content: url ? url : "https://altercap.cl",
        },
        {
          property: "og:image",
          content: `https://altercap.cl${SeoImage}`,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: `https://altercap.cl${SeoImage}`,
        },
        {
          name: `twitter:site`,
          content: `https://altercap.cl`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: metaAuthor,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(disableIndexingMeta)}
    >
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  );
};

export default SEO;
