import {
  ARROW_BACK_ICON,
  RECURRENT_EXPENSES_ICON,
  RECURRENT_INCOME_ICON,
  REMINDER_ICON,
} from "@images";
import images from "./images";
import { Button } from "@material-ui/core";
import React, { Dispatch, SetStateAction } from "react";

import { useStyles } from "./createEvents.style";
import { updateEventMutation, createEventMutation } from "@apollo";
import { useApolloClient } from "@apollo/client";

type Props = {
  type: string;
  title: string;
  setEditing: Dispatch<SetStateAction<boolean>>;
  setCurrentView: Dispatch<SetStateAction<string>>;
  editingData: {
    id: number;
    showInCalendar: boolean;
    name: string;
    recurrence: number;
    amount: number;
    description: string;
    category: number;
    date: string;
    url: string;
  };
  editing: boolean;
};

// const amount = useRef();

const CreateEvent = ({
  type,
  title,
  setCurrentView,
  editingData,
  setEditing,
  editing,
}: Props) => {
  const classes = useStyles();
  const client = useApolloClient();
  const [selectedCategory, setSelectedCategory] = React.useState(
    editingData.category ? editingData.category : type === "income" ? 14 : 1
  );

  const [recurrence, setRecurrence] = React.useState(
    editing ? editingData.recurrence : 1
  );

  const [amount, setAmount] = React.useState(
    editing ? `$ ${editingData.amount}` : `$ 0`
  );

  const [date, setDate] = React.useState<String>(
    editing ? editingData.date : ""
  );

  const [name, setName] = React.useState(editing ? editingData.name : "");

  const [description, setDescription] = React.useState(
    editing ? editingData.description : ""
  );

  const [url, setUrl] = React.useState(
    editingData.url && editingData.url !== "www." && editing
      ? editingData.url
      : "www."
  );

  const [incorrectName, setIncorrectName] = React.useState(false);
  const [incorrectAmount, setIncorrectAmount] = React.useState(false);
  const [incorrectDate, setIncorrectDate] = React.useState(false);

  const setStyle = () => {
    switch (type) {
      case "expense":
        return classes.expense;
      case "income":
        return classes.income;
      case "reminder":
        return classes.reminder;
      default:
        return classes.expense;
    }
  };

  const setType = () => {
    switch (type) {
      case "expense":
        return 1;
      case "income":
        return 2;
      case "reminder":
        return 3;
      default:
        return 4;
    }
  };

  const setImage = () => {
    switch (type) {
      case "expense":
        return RECURRENT_EXPENSES_ICON;
      case "income":
        return RECURRENT_INCOME_ICON;
      case "reminder":
        return REMINDER_ICON;
      default:
        return RECURRENT_EXPENSES_ICON;
    }
  };

  const changeAmount = (e: string) => {
    setAmount(e);
  };
  const handleRecurrenceChange = (e: any) => {
    setRecurrence(e);
  };

  const handleCategoryChange = (e: any) => {
    setSelectedCategory(e);
  };
  const checkRequiredFields = async () => {
    if (name === "") {
      setIncorrectName(true);
      return false;
    } else {
      setIncorrectName(false);
    }
    if (
      setType() != 3 &&
      (amount === "$ " ||
        amount === "$" ||
        isNaN(Number(amount.replace("$", ""))))
    ) {
      setIncorrectAmount(true);
      return false;
    } else {
      setIncorrectAmount(false);
    }

    if (date === "") {
      setIncorrectDate(true);
      return false;
    } else {
      setIncorrectDate(false);
    }

    return true;
  };

  const sendData = async () => {
    if (await checkRequiredFields()) {
      if (editing) {
        const dataToSend = {
          amount: Number(amount.replace("$", "")),

          category: Number(selectedCategory),
          recurrence: Number(recurrence),
          name,
          date,
          description,
          type: setType(),
          url,
        };
        const res = await updateEventMutation(
          client,
          editingData.id,
          dataToSend
        );
        return res;
      } else {
        const dataToSend = {
          amount: Number(amount.replace("$", "")),
          category: Number(selectedCategory),
          recurrence: Number(recurrence),
          name,
          date,
          description,

          showInCalendar: true,
          type: setType(),
          url,
        };
        console.log(dataToSend);
        const res = await createEventMutation(client, dataToSend);
        return res;
      }
    }
  };

  const options = () => {
    return (
      <select
        name="recurrence"
        id="recr"
        onChange={(e) => handleRecurrenceChange(e.target.value)}
      >
        <option
          value={1}
          selected={editingData.recurrence === 1 ? true : false}
        >
          -
        </option>
        <option
          value={2}
          selected={editingData.recurrence === 2 ? true : false}
        >
          Diario
        </option>
        <option
          value={3}
          selected={editingData.recurrence === 3 ? true : false}
        >
          Semanal
        </option>
        <option
          value={4}
          selected={editingData.recurrence === 4 ? true : false}
        >
          Quincenal
        </option>
        <option
          value={5}
          selected={editingData.recurrence === 5 ? true : false}
        >
          Mensual
        </option>
        <option
          value={6}
          selected={editingData.recurrence === 6 ? true : false}
        >
          Bimestral
        </option>
        <option
          value={7}
          selected={editingData.recurrence === 7 ? true : false}
        >
          Trimestral
        </option>
        <option
          value={8}
          selected={editingData.recurrence === 8 ? true : false}
        >
          Semestral
        </option>
        <option
          value={9}
          selected={editingData.recurrence === 9 ? true : false}
        >
          Anual
        </option>
      </select>
    );
  };

  const incomeForm = () => {
    return (
      <form action="">
        <div className={classes.displayer}>
          <div className="element">
            <div className="label">
              <span>* Nombre del ingreso</span>
            </div>
            <div className="input">
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value as string)}
              />
            </div>
            {incorrectName && (
              <span className={classes.error}> * Campo requerido</span>
            )}
          </div>
          <div className="element">
            <div className="label">
              <span>* se repite</span>
            </div>
            <div className="input">{options()}</div>
          </div>
          <div className="element">
            <div className="label">
              <span>* Última fecha de pago</span>
            </div>
            <div className="input">
              <input
                type="date"
                value={date}
                onChange={(e) => {
                  setDate(e.target.value as unknown as String);
                }}
              />
            </div>
            {incorrectDate && (
              <span className={classes.error}> * Campo requerido</span>
            )}
          </div>
          <div className="element">
            <div className="label">
              <span>tipo de ingreso</span>
            </div>
            <div className="input">
              <select
                name="recurrence"
                id="recr"
                onChange={(e) => handleCategoryChange(e.target.value)}
              >
                <option value={14} selected={editingData.category === 14}>
                  Sueldo
                </option>
                <option value={15} selected={editingData.category === 15}>
                  Mesada
                </option>
                <option value={16} selected={editingData.category === 16}>
                  Arriendo
                </option>
                <option value={17} selected={editingData.category === 17}>
                  Otro
                </option>
              </select>
            </div>
          </div>
          <div className="element">
            <div className="label">
              <span>Monto</span>
            </div>
            <div className="input">
              <input
                type="numeric"
                value={amount}
                onChange={(e) => changeAmount(e.target.value)}
              />
            </div>
            {incorrectAmount && (
              <span className={classes.error}> * Formato inválido</span>
            )}
          </div>
        </div>
        <Button
          onClick={async () => {
            setEditing(false);
            const success = await sendData();
            if (success) {
              setCurrentView("recurrentIncomes");
            }
          }}
          className={`${classes.submitButton} ${setStyle()}`}
        >
          {editing ? "Editar ingreso recurrente" : "Crear ingreso recurrente"}
        </Button>
      </form>
    );
  };

  const expensesForm = () => {
    return (
      <form>
        <div className={classes.displayer}>
          <div className="element">
            <div className="label">
              <span>* Nombre del gasto</span>
            </div>
            <div className="input">
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value as string)}
              />
            </div>
            {incorrectName && (
              <span className={classes.error}> * Campo requerido</span>
            )}
          </div>
          <div className="element">
            <div className="label">
              <span>* se repite</span>
            </div>
            <div className="input">{options()}</div>
          </div>
          <div className="element">
            <div className="label">
              <span>* Última fecha de pago</span>
            </div>
            <div className="input">
              <input
                type="date"
                value={date}
                onChange={(e) => {
                  setDate(e.target.value as unknown as String);
                }}
              />
            </div>
            {incorrectDate && (
              <span className={classes.error}> * Campo requerido</span>
            )}
          </div>
          <div className="element">
            <div className="label">
              <span>Monto</span>
            </div>
            <div className="input">
              <input
                type="currency"
                value={amount}
                onChange={(e) => changeAmount(e.target.value)}
              />
            </div>
            {incorrectAmount && (
              <span className={classes.error}> * Formato inválido</span>
            )}
          </div>
          <div className={classes.categoriesSection}>
            <div className="title">
              <span>Categoría</span>
            </div>
            <div className="categories">
              {images.map((img, index) => {
                return (
                  <div
                    className={`category ${
                      img.id === selectedCategory ? "selected" : "unselected"
                    }`}
                    onClick={() => handleCategoryChange(img.id)}
                  >
                    <img src={img.image} alt="category" />
                    <span className="imgDescription">{img.label}</span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="element">
            <div className="label">
              <span>Enlace de pago</span>
            </div>
            <div className="input">
              <input
                type="text"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
            </div>
          </div>
        </div>
        <Button
          onClick={async () => {
            setEditing(false);
            const success = await sendData();
            if (success) {
              setCurrentView("recurrentPayments");
            }
          }}
          className={`${classes.submitButton} ${setStyle()}`}
        >
          {editing ? "Editar gasto recurrente" : "Crear gasto recurrente"}
        </Button>
      </form>
    );
  };

  const reminderForm = () => {
    return (
      <form action="">
        <div className={classes.displayer}>
          <div className="element">
            <div className="label">
              <span>* Nombre del recordatorio</span>
            </div>
            <div className="input">
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value as string)}
              />
            </div>
            {incorrectName && (
              <span className={classes.error}> * Campo requerido</span>
            )}
          </div>
          <div className="element">
            <div className="label">
              <span>* Fecha</span>
            </div>
            <div className="input">
              <input
                type="date"
                value={date}
                onChange={(e) => {
                  setDate(e.target.value as unknown as String);
                }}
              />
            </div>
            {incorrectDate && (
              <span className={classes.error}> * Campo requerido</span>
            )}
          </div>
          <div className="element">
            <div className="label">
              <span>* Descripción</span>
            </div>
            <div className="input">
              <textarea
                name="description"
                id="desc"
                cols={30}
                rows={10}
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              >
                Descripción
              </textarea>
            </div>
          </div>
          <div className="element">
            <div className="label">
              <span>Se repite</span>
            </div>
            <div className="input">{options()}</div>
          </div>
        </div>
        <Button
          onClick={async () => {
            setEditing(false);
            const success = await sendData();
            if (success) {
              setCurrentView("reminders");
            }
          }}
          className={`${classes.submitButton} ${setStyle()}`}
        >
          {editing ? "Editar recordatorio" : "Crear recordatorio"}
        </Button>
      </form>
    );
  };

  return (
    <div className={classes.container}>
      <div className={`${classes.topSection} ${setStyle()}`}>
        {type === "expense" && (
          <div
            className="backArrow"
            onClick={() => {
              setEditing(false);
              setCurrentView("recurrentPayments");
            }}
          >
            <img src={ARROW_BACK_ICON} alt="arrow_back" />
          </div>
        )}
        {type === "income" && (
          <div
            className="backArrow"
            onClick={() => {
              setEditing(false);
              setCurrentView("recurrentIncomes");
            }}
          >
            <img src={ARROW_BACK_ICON} alt="arrow_back" />
          </div>
        )}
        {type === "reminder" && (
          <div
            className="backArrow"
            onClick={() => {
              setEditing(false);
              setCurrentView("reminders");
            }}
          >
            <img src={ARROW_BACK_ICON} alt="arrow_back" />
          </div>
        )}
        <span className="title">{title}</span>
        <div className="mainIcon">
          <img src={setImage()} alt="" />
        </div>
      </div>
      <div className={classes.formSection}>
        {type == "expense" && expensesForm()}
        {type == "income" && incomeForm()}
        {type == "reminder" && reminderForm()}
      </div>
    </div>
  );
};

export default CreateEvent;
