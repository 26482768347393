// contantes para evitar crear modelos de datos en la base de datos.

// CALENDAR

const EventTypesConstants = {
  expense: 1,
  income: 2,
  reminder: 3,
  movement: 4,
  estate: 5,
};
enum EventTypesConstantsEnum {
  expense = 1,
  income,
  reminder,
  movement,
  estate,
}

const EventRecurrencesConstants = {
  once: 1,
  daily: 2,
  weekly: 3,
  biweekly: 4,
  monthly: 5,
  bimonthly: 6,
  quarterly: 7,
  semester: 8,
  annual: 9,
};

// enum EventRecurrencesConstantsEnum {
//   once = 1,
//   daily,
//   weekly,
//   biweekly,
//   monthly,
//   bimonthly,
//   quarterly,
//   semester,
//   annual,
// }
enum EventRecurrencesConstantsEnum {
  "Una vez" = 1,
  "Diario",
  "Semanal",
  "Quincenal",
  "Mensual",
  "Bimestral",
  "Trimestral",
  "Semestral",
  "Anual",
}
const EventCategoriesConstants = {
  // para gastos
  home: 1,
  food: 2,
  transport: 3,
  educationAndWork: 4,
  sportAndHobbies: 5,
  travel: 6,
  financeAndTaxes: 7,
  healthAndBeauty: 8,
  shopping: 9,
  restaurant: 10,
  entertainment: 11,
  transfers: 12,
  gifts: 13,
  // para ingresos
  salary: 14,
  allowance: 15,
  rental: 16,
  other: 17,
};
enum EventCategoriesConstantsEnum {
  // para gastos
  "Vivienda" = 1,
  "Alimentación",
  "Transporte",
  "Educación y Trabajo",
  "Deporte y Hobbies",
  "Viajes",
  "Finanzas",
  "Salud",
  "Compras",
  "Restaurantes",
  "Entretenimiento",
  "Transferencias",
  "Regalos",
  // para ing,
  "Sueldo",
  "Mesada",
  "Arriendo",
  "Otros",
}
export {
  EventTypesConstants,
  EventRecurrencesConstants,
  EventTypesConstantsEnum,
  EventRecurrencesConstantsEnum,
  EventCategoriesConstants,
  EventCategoriesConstantsEnum,
};
