import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: "6rem",
    [theme.breakpoints.down(1600)]: {
      marginLeft: "2rem",
    },
    [theme.breakpoints.down(1500)]: {
      marginLeft: "0rem",
    },
  },
  arrow: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    opacity: 0,
  },
  arrowIcon: {
    width: "4.2rem",
    height: "4.2rem",
    color: "#008296",
    marginRight: 90,
  },
  form: {
    paddingLeft: "0rem",
    minHeight: 600,
    [theme.breakpoints.down(1100)]: {
      margin: "0 auto",
    },
  },
  riel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    marginTop: "6rem",
    marginLeft: "-5rem",
    width: "50rem",
    // overflowX: "scroll",
    zIndex: 2,
    [theme.breakpoints.down(1230)]: {
      marginTop: "2rem",
      marginLeft: 0,
    },
    [theme.breakpoints.down(1100)]: {
      marginTop: "-2rem",
    },
  },
  msgWelcome: {
    fontFamily: theme.typography.fontFamily,
    color: "#393e44",
    fontSize: "2rem",
    fontWeight: "normal",
    display: "block",
    width: 700,
    height: "33.125rem",
    margin: "0 2.481rem",
    [theme.breakpoints.down(1100)]: {
      margin: "0 0.5rem",
      height: "33.125rem",
    },
  },
  textImg: {
    display: "flex",
    [theme.breakpoints.down(1100)]: {
      justifyContent: "center",
      top: -265,
      position: "relative",
    },
  },

  arrowIcon2: {
    width: "3rem",
    height: "3rem",
    color: "#008296",
  },
  dots: {
    bottom: 0,
    "& li.slick-active button::before": {
      color: "#8352FD",
    },
    "& li": {
      "& button::before": {
        fontSize: theme.typography.pxToRem(14),
        color: "#9D9D9D",
        opacity: 0.5,
      },
    },
  },
  middleSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down(1241)]: {
      flexDirection: "column",
      alignItems: "center",
      height: "60rem",
    },
  },
  sliderBox: {
    width: "42rem",
    height: "30rem",
    [theme.breakpoints.down(1241)]: {
      height: "auto",
    },
    "& .textContainer": {
      textAlign: "center",
      display: "flex",
      alignItems: "center",

      "& .infoText": {
        width: "38rem",
        margin: "0 3.375rem 2rem 6rem",
        fontFamily: "Montserrat",
        fontSize: 20,
        lineHeight: 1.84,
        textAlign: "center",
        color: theme.palette.text.primary,
        [theme.breakpoints.down(1241)]: {
          fontSize: 16,
          lineHeight: 1.5,
          margin: "1.5rem auto",
          width: "17.5rem",
        },
        "& .style1": {
          fontWeight: "bold",
        },
      },
    },
  },
  slide1: {},
  slide2: {},
  whiteCard: {
    width: "16.875rem",
    height: "17.188rem",
    // margin: "2rem 0 3.438rem 6.175rem",
    padding: "1.6rem 1.25rem 1.6rem 1.25rem",
    borderRadius: "1.875rem",
    boxShadow: "0.25rem 0.25rem 0.5rem 0.125rem rgba(0, 0, 0, 0.2)",
    backgroundColor: "#ffffff",
  },
  cardBox: {
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down(1241)]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-around",
      flexWrap: "nowrap",
    },
  },
  card1: {
    textAlign: "center",
    padding: "1.8rem 1.3125rem",
    [theme.breakpoints.down(1241)]: {
      display: "flex",
      flexDirection: "row",
      width: "auto",
      height: "9rem",
      alignItems: "center",
      margin: "0.875rem 1rem 0.6875rem",
      padding: "1.6rem 1.25rem 1.6rem 1.25rem",
      borderRadius: "1.125rem",
      boxShadow: "0.25rem 0.25rem 0.5rem 0.125rem rgba(0, 0, 0, 0.2)",
    },
    "& img": {
      margin: "0 auto",
      width: "3.125rem",
      height: "3.125rem",
      [theme.breakpoints.down(1241)]: {
        width: "1.875rem",
        height: "1.875rem",
        margin: "1.3125rem 1.25rem 1.5rem 0",
      },
    },
    "& .cardText": {
      width: "14.3rem",
      margin: "1.2rem 0 0",
      fontSize: 16,
      lineHeight: 1.88,
      fontFamily: "Montserrat",
      textAlign: "center",
      color: theme.palette.text.primary,
      "& .style1": {
        fontWeight: "bold",
      },
      [theme.breakpoints.down(1241)]: {
        width: "14.3rem",
        margin: "0 0 1.25rem",
        fontSize: 14,
        marginLeft: "1.25rem",
        lineHeight: 1.7,
        fontFamily: "Montserrat",
        textAlign: "left",
      },
    },
  },
  card2: {
    display: "flex",
    flexDirection: "column",
    padding: "1.375rem 1.9375rem 1.9375rem",
    [theme.breakpoints.down(1241)]: {
      width: "auto",
      height: "17.1875rem",
      margin: "1.875rem 1rem 1.6875rem",
      padding: "1.375rem 2rem 1.5rem 2.625rem",
      borderRadius: "1.875rem",
      boxShadow: "0.25rem 0.25rem 0.5rem 0.125rem rgba(0, 0, 0, 0.2)",
    },
    "& .cardTitle": {
      textAlign: "center",
      "& span": {
        fontFamily: "Montserrat",
        fontSize: 16,
        fontWeight: "bold",
        lineHeight: 1.88,
        textAlign: "center",
        color: "#8352fd",
      },
    },
    "& .cardList": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      "& .items": {
        display: "flex",
        margin: "0.5rem 0",
        flexDirection: "row",
        "& .itemNumber": {
          width: "30px",
          height: "30px",
          margin: "0.3125rem 1rem 0.3125rem 0",
          padding: "0 0.625rem 0 0.6875rem",
          boxShadow: "0.25rem 0.25rem 0.5rem 0.125rem rgba(0,0,0,0.2)",
          backgroundColor: "#4619b6",
          // fontFamily: "Inter",
          fontSize: 12,
          fontWeight: 500,
          lineHeight: 2.5,
          textAlign: "center",
          color: "#ffffff",
          borderRadius: "50%",
        },
        "& .itemText": {
          width: "10.875rem",
          height: "2.5rem",
          margin: "0 0 0 1rem",
          fontFamily: "Montserrat",
          fontSize: 14,
          lineHeight: 1.43,
          textAlign: "left",
          color: theme.palette.text.primary,
        },
      },
    },
  },
  card1S2: {
    textAlign: "center",
    padding: "1.8rem 1.3125rem",
    [theme.breakpoints.down(1241)]: {
      // width: "auto",
      // height: "9rem",
      alignItems: "center",
      margin: "0.8125rem 0 1.125rem",
      padding: "1.0625rem 3.625rem 1.5rem 3.5625rem",
      borderRadius: "1.125rem",
      boxShadow: "0.25rem 0.25rem 0.5rem 0.125rem rgba(0, 0, 0, 0.2)",
    },
    "& .cardText": {
      width: "14.3rem",
      margin: "1.2rem 0 0",
      fontSize: 16,
      lineHeight: 1.88,
      fontFamily: "Montserrat",
      textAlign: "center",
      color: theme.palette.text.primary,
      "& .style1": {
        fontWeight: "bold",
      },
    },
  },
  topSection: {
    display: "flex",
    flexDirection: "row",
  },
  arrowLeft: {
    [theme.breakpoints.down(1241)]: {
      marginTop: "6.25rem",
    },
  },
  arrowRight: {
    [theme.breakpoints.down(1241)]: {
      marginTop: "6.25rem",
    },
  },
  secondSlideBox: {
    display: "flex",
    flexDirection: "column",
    height: "11.0625rem",
    [theme.breakpoints.down(1241)]: {
      height: "auto",
    },
    "& .cardTitle": {
      textAlign: "center",
      "& span": {
        fontFamily: "Montserrat",
        fontSize: 16,
        fontWeight: "bold",
        lineHeight: 1.88,
        textAlign: "center",
        color: "#8352fd",
      },
    },
    "& .iconsBox": {
      display: "flex",
      height: "4.375rem",
      flexDirection: "row",
      margin: "1.875rem 0 4.1875rem 0",
      justifyContent: "space-around",
      [theme.breakpoints.down(1241)]: {
        margin: "0.875rem 0",
      },
      "& .iconLabels": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& .iconName": {
          fontSize: 12,
          lineHeight: 2.5,
          textAlign: "center",
          color: "#8352fd",
          [theme.breakpoints.down(1241)]: {
            fontSize: 10,
            lineHeight: 3,
          },
        },
        "& img": {
          height: "1.5625rem",
          width: "1.5625rem",
        },
      },
    },
  },
  slide3: {
    "& .thirdSlideText": {
      flexDirection: "column",
    },
    "& .thirdSlideBox": {
      display: "flex",
      flexDirection: "row",
      width: "41.6875rem",
      height: "9.75rem",
      margin: "1.9375rem 2.25rem 4.6875rem 3.6125rem",
      padding: "2.0625rem 2.3125rem 2.125rem 2.875rem",
      borderRadius: "1.25rem",
      boxShadow: "0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.25)",
      backgroundColor: "#ffffff",
      [theme.breakpoints.down(1241)]: {
        flexDirection: "column",
        width: "auto",
        height: "auto",
      },
      "& .items": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.down(1241)]: {
          flexDirection: "row",
        },
        "& .itemNumber": {
          width: "1.875rem",
          height: "1.875rem",
          boxShadow: "0.25rem 0.25rem 0.5rem 0.125rem rgba(0,0,0,0.2)",
          backgroundColor: "#4619b6",
          // fontFamily: "Inter",
          fontSize: 12,
          fontWeight: 500,
          lineHeight: 2.5,
          textAlign: "center",
          color: "#ffffff",
          borderRadius: "50%",
        },
        "& .itemText": {
          width: "10.875rem",
          height: "2.5rem",
          margin: "1.125rem 0",
          fontFamily: "Montserrat",
          fontSize: 14,
          lineHeight: 1.43,
          textAlign: "center",
          color: theme.palette.text.primary,
          [theme.breakpoints.down(1241)]: {
            textAlign: "left",
            margin: "1.125rem 0 1.125rem 1rem",
          },
        },
      },
    },
  },
  spaceImage: {
    [theme.breakpoints.down(1241)]: {
      height: "15rem",
      width: "15rem",
    },
    "& .page1Space": {
      marginTop: "3rem",
      maxHeight: "25.25rem",
      [theme.breakpoints.down(1241)]: {
        width: "11.5625rem",
        height: "8.25rem",
      },
    },
    "& .page2Space": {
      // marginTop: "3rem",
      // width: "17.875rem",
      // height: "22rem",
      maxHeight: "25rem",
      marginLeft: "5rem",
      [theme.breakpoints.down(1241)]: {
        width: "7.5625rem",
        height: "9.3125rem",
      },
    },
    "& .page3Space": {
      // width: "31.875rem",
      // height: "33.4375rem",
      maxHeight: "33rem",
      marginLeft: "3.5rem",
      [theme.breakpoints.down(1241)]: {
        width: "10.5rem",
        height: "12.125rem",
        marginTop: "2rem",
      },
    },
  },
  bottomSection: {
    textAlign: "right",
    width: "50rem",
    margin: "0rem auto 10rem",
    "& .centerButton": {
      textAlign: "center",
      marginTop: "15rem",
      marginBottom: "-4rem",
      [theme.breakpoints.down(1241)]: {
        marginBottom: "0rem",
        marginTop: "0rem",
      },
      "& .secondButton": {
        marginTop: "29rem",
        [theme.breakpoints.down(1241)]: {
          marginTop: "15rem",
        },
      },
    },
    [theme.breakpoints.down(1241)]: {
      width: "auto",
    },
  },
}));
