import React, { useState, useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { InvestorProfileTypes } from "@interfaces";
import {
  BankAccountNumberInput,
  BankAccountTypeSelector,
  BankSelector,
} from "..";
interface investProps {
  currentUser: any;
  isDisabled: boolean;
  form: any;
  setButtonDisabled: () => void;
}

const BankData = ({
  currentUser,
  isDisabled,
  form,
  setButtonDisabled,
}: investProps) => {
  const classes = useStyles();
  const [bank, setBank] = useState<string>(
    currentUser ? currentUser.bankData.bank : form.bank ? form?.bank : ""
  );
  const [accountType, setAccountType] = useState<string>(
    currentUser
      ? currentUser.bankData.accountType
      : form.accountType
      ? form?.accountType
      : ""
  );
  const [accountNumber, setAccountNumber] = useState<string>(
    currentUser
      ? currentUser.bankData.accountNumber
      : form.accountNumber
      ? form?.accountNumber
      : ""
  );

  useEffect(() => {
    if (form) {
      form.bank = bank;
      form.accountType = accountType;
      form.accountNumber = accountNumber;
    }

    if (bank && accountType && accountNumber) {
      if (setButtonDisabled) {
        setButtonDisabled(false);
      }
    } else {
      if (setButtonDisabled) {
        setButtonDisabled(true);
      }
    }
  }, [bank, accountType, accountNumber]);

  return (
    <div className={classes.conteinerData}>
      <div className={classes.sT}>
        <BankSelector
          value={bank}
          onChange={(e) => setBank(e.target.value)}
          disabled={isDisabled}
        />
        <BankAccountTypeSelector
          value={accountType}
          onChange={(e) => setAccountType(e.target.value)}
          disabled={isDisabled}
        />
      </div>
      <div className={classes.dv}>
        <BankAccountNumberInput
          value={accountNumber}
          onChange={(e) => setAccountNumber(e.target.value)}
          disabled={isDisabled}
        />
      </div>
    </div>
  );
};
export default BankData;

const useStyles = makeStyles<Theme>((theme) => ({
  conteinerData: {
    marginTop: "2rem",
    gap: "4rem",
    display: "flex",
    [theme.breakpoints.down(1000)]: {
      flexDirection: "column",
    },
  },
  dv: {
    width: "100%",
    "& div": {
      width: "100%",
    },
  },
  sT: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
  },
}));
