import { ApolloClient, gql } from "@apollo/client";

const SEND_CONTACT_FORM_MUTATION = gql`
  mutation CreateContactFormBild($data: CreateContactFormBildParams!) {
    createContactFormBild(data: $data)
  }
`;

type CreateUserParams = {
  data: {
    name: string;
    email: string;
    sector?: string;
    topic: string;
    surname: string;
    companyName: string;
  };
};

type CreateUserResult = {
  createContactForm: boolean;
};

export async function sendContactForm(
  payload: CreateUserParams["data"],
  client: ApolloClient<object>
): Promise<boolean> {
  console.log(payload, "---");
  const result = await client.mutate<CreateUserResult, CreateUserParams>({
    mutation: SEND_CONTACT_FORM_MUTATION,
    variables: { data: payload },
  });
  return !!result.data?.createContactForm;
}
