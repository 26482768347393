import React, { useContext, useEffect, useState } from "react";
import { useAuth } from "@hooks";
import CardMission from "@components/Missions/CardMission/CardMission";
import SliderMissions from "@components/Missions/SliderMissions/SliderMissions";
import { makeStyles } from "@material-ui/core";
import { timer } from "@images";
import { getMissionTime, currentMissionModalInfo } from "@utils";
import { AppContext } from "@context";

interface CategoryViewProps {
  missions: any;
  categoryInfo: any;
}

export const CategoryView: React.FC<CategoryViewProps> = ({
  missions,
  categoryInfo,
}) => {
  useAuth({ redirectIfNotLogged: true });
  const classes = useStyles();
  const { openMissionsModal } = useContext(AppContext);
  // Descomentar cuando se puedas
  const [doubleMissions, setDoubleMissions] = useState(false);
  const [missionsA, setMissionsA] = useState([]);
  const [missionsB, setMissionsB] = useState([]);

  missions.sort((a, b) => {
    return a.mission.id - b.mission.id;
  });
  // function that check if the missions have more than one type of subCategoria
  // and in case of true it will split the missions in two arrays
  useEffect(() => {
    const firstSubCategory = missions[0]?.mission.subCategory;
    // check if the missions have more than one type of subCategoria
    setDoubleMissions(() =>
      missions.some(
        (mission) => mission.mission.subCategory !== firstSubCategory
      )
    );
    if (doubleMissions) {
      missions.map((mission) => {
        if (mission.mission.subCategory == firstSubCategory) {
          // check if mission is not in the missionsA array
          if (!missionsA.some((m) => m.mission.id == mission.mission.id)) {
            setMissionsA((missionsA) => [...missionsA, mission]);
          }
        } else {
          // check if mission is not in the missionsB array
          if (!missionsB.some((m) => m.mission.id == mission.mission.id)) {
            setMissionsB((missionsB) => [...missionsB, mission]);
          }
        }
      });
    }
  }, [missions]);

  return (
    <>
      <div className={classes.containerAll}>
        <CardMission
          image={categoryInfo?.largeImage}
          title={categoryInfo?.title}
          text={categoryInfo?.text}
          load={`Faltan ${
            missions?.filter((obj) => obj.state == "PENDING").length
          } misiones para completar`}
          onContinueClick={() =>
            openMissionsModal(currentMissionModalInfo(missions))
          }
          completedMissions={
            missions?.filter((obj) => obj.state == "COMPLETED").length
          }
          allMissions={missions?.length}
        />
        <section className={classes.containerCards}>
          <h2 className={classes.title}>
            Misiones
            {/*<span>
              <img src={timer} />
              {getMissionTime(missions)}
            </span>*/}
          </h2>
          {!doubleMissions ? (
            <SliderMissions card={missions} categoryInfo={categoryInfo} />
          ) : (
            <>
              <SliderMissions card={missionsA} categoryInfo={categoryInfo} />
              <SliderMissions card={missionsB} categoryInfo={categoryInfo} />
            </>
          )}
        </section>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  containerAll: {
    display: "grid",
    gridTemplateColumns: "30% 45%",
    justifyContent: "center",
    margin: "10rem auto",
    maxWidth: 1600,
    [theme.breakpoints.down(1430)]: {
      gridTemplateColumns: "30% 50%",
    },
    [theme.breakpoints.down(1310)]: {
      gridTemplateColumns: "35% 50%",
    },
    [theme.breakpoints.down(1240)]: {
      gridTemplateColumns: "100%",
      marginTop: 0,
    },
  },
  containerCards: {
    padding: "30px",
    boxShadow: "4px 4px 20px 2px rgba(0, 0, 0, 0.2)",
    borerRadius: 30,
    marginBottom: "4rem",
    borderRadius: "30px",
    [theme.breakpoints.down(1240)]: {
      boxShadow: "none",
    },
    [theme.breakpoints.down(800)]: {
      padding: "15px",
    },
    "& .slick-track": {
      height: "auto!important",
    },
  },
  title: {
    display: "flex",
    width: "50%",
    fontSize: 24,
    fontWeight: "bold",
    justifyContent: "space-between",
    marginLeft: 20,
    color: "#393e44",
    [theme.breakpoints.down(1430)]: {
      width: 300,
    },
    "& span": {
      display: "flex",
      alignItems: "center",
      fontWeight: 300,
      fontSize: 14,
    },
  },
}));
