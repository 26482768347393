import React, { useState } from "react";
import { Link } from "gatsby";
import { rokin } from "@images";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core";

interface Props {
  className?: string;
}

export const HeaderStep: React.FC<Props> = ({ className }) => {
  const classes = useStyles();
  const [searching] = useState(false);
  return (
    <>
      <div className={`${className} ${classes.header}`}>
        <Toolbar
          className={`${classes.toolbarStyle} ${
            searching ? "active-search" : ""
          }`}
        >
          <Link to="/" className={classes.headerLogo}>
            <img className="" src={rokin} alt="logo" />{" "}
          </Link>
        </Toolbar>
      </div>
    </>
  );
};

const useStyles = makeStyles(
  () => ({
    header: {
      "& div": {
        marginBottom: "0.5em",
      },
    },
    toolbarStyle: {
      width: "100%",
      justifyContent: "space-between",
      "--tw-bg-opacity": "1",
      "background-color": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      height: "5rem",
    },
    headerLogo: {
      marginRight: 50,
      width: 130,
      minWidth: 130,
    },
  }),
  { index: 1 },
);
