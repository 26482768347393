import React, { useContext } from "react";
import { Dialog, useMediaQuery } from "@material-ui/core";
import { AppContext } from "@context";
import { ButtonPrimary, ButtonOutlined } from "@components/Reusables";
import { makeStyles } from "@material-ui/core";
import { numberMission, RoksCongrats, AlertIcon } from "@images";
import { navigate } from "gatsby";
import { Route } from "@interfaces";

interface NoGoalWarningModalProps {
  open: boolean;
}

export const NoGoalWarningModal: React.FC<NoGoalWarningModalProps> = ({
  open,
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const { closeNoGoalWarningModal } = useContext(AppContext);

  return (
    <>
      <Dialog
        open={open}
        onClose={closeNoGoalWarningModal}
        className={classes.Dialog}
      >
        <div className={classes.Modal}>
          <div className={classes.info}>
            <div className={classes.titulo}>
              <img src={AlertIcon} className={classes.numberImg} />
              <div className={classes.text1}>Aún no tienes un objetivo</div>
            </div>
            <div className={classes.linea}></div>
            <div className={classes.text2}>Primero debes crear un objetivo</div>
            <div className={classes.textButton}>
              <div>
                <ButtonOutlined
                  className={`${classes.button} ${classes.buttonOutlined}`}
                  text="Cerrar"
                  onClick={() => {
                    closeNoGoalWarningModal();
                  }}
                />
                <ButtonPrimary
                  className={classes.button}
                  text="Crear objetivo"
                  onClick={() => {
                    navigate(Route.createGoal);
                    closeNoGoalWarningModal();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};
const useStyles = makeStyles(
  (theme) => ({
    Dialog: {
      "& >div": {},
      "& >div >div": {
        borderRadius: "35px",
        position: "absolute",
        top: 195,
        maxWidth: 900,
        [theme.breakpoints.down(600)]: {
          top: 170,
        },
      },
    },
    Modal: {
      width: 800,
      height: 395,
      flexGrow: 0,
      padding: "40px 44px 44px 44px",
      borderRadius: 35,
      border: "1px solid #ffffff",
      [theme.breakpoints.down(600)]: {
        width: 340,
        padding: "10px 15px",
      },
    },
    info: {
      display: "grid",
    },
    titulo: {
      display: "flex",
      gap: 30,
      alignItems: "center",
      [theme.breakpoints.down(1222)]: {
        gap: 15,
      },
    },
    numberImg: {
      width: "110px",
      height: "auto",
      [theme.breakpoints.down(600)]: {
        width: 85,
      },
    },
    text1: {
      fontFamily: theme.typography.fontFamily,
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: "#008296",
      [theme.breakpoints.down(600)]: {
        fontSize: 14,
      },
    },
    linea: {
      width: "100%",
      height: 2,
      flexGrow: 0,
      backgroundColor: "#008296",
    },
    text2: {
      fontFamily: "Montserrat",
      fontSize: "1.3rem",
      fontWeight: "normal",
      color: theme.palette.text.primary,
      minHeight: 100,
      marginTop: 30,
      marginBottom: 20,
      [theme.breakpoints.down(600)]: {
        textAlign: "center",
      },
    },
    textButton: {
      display: "grid",
      justifyContent: "right",
    },
    button: {
      width: 164,
      height: 50,
      margin: "8px 0 0 20px",
      boxShadow: "2px 4px 5px 1px rgb(0 0 0 / 25%)",
      [theme.breakpoints.down(600)]: {
        width: 270,
      },
    },
    buttonOutlined: {
      boxShadow: "1px 2px 5px 1px rgb(0 0 0 / 25%)",
      "& >span": {
        fontWeight: 300,
      },
    },
  }),
  { index: 1 }
);
