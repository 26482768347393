import React from "react";
import { makeStyles } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { PasswordValidationsType } from "@utils";

interface Props {
  className?: string;
  focused: boolean;
  validations: PasswordValidationsType;
}

export const PasswordValidations: React.FC<Props> = ({
  className,
  focused,
  validations,
}) => {
  const classes = useStyles();
  return (
    <div
      className={`${classes.root} ${!focused ? classes.hidden : ""} ${
        className || ""
      }`}
    >
      <div className={classes.row}>
        {validations.length ? (
          <CheckIcon className={classes.icon} color="primary" />
        ) : (
          <ClearIcon className={classes.icon} color="error" />
        )}
        <span>Al menos 8 caracteres</span>
      </div>
      <div className={classes.row}>
        {validations.oneLowercase ? (
          <CheckIcon className={classes.icon} color="primary" />
        ) : (
          <ClearIcon className={classes.icon} color="error" />
        )}
        <span>Al menos 1 letra minúscula</span>
      </div>
      <div className={classes.row}>
        {validations.oneUppercase ? (
          <CheckIcon className={classes.icon} color="primary" />
        ) : (
          <ClearIcon className={classes.icon} color="error" />
        )}
        <span>Al menos 1 letra mayúscula</span>
      </div>
      <div className={classes.row}>
        {validations.oneNumber ? (
          <CheckIcon className={classes.icon} color="primary" />
        ) : (
          <ClearIcon className={classes.icon} color="error" />
        )}
        <span>Al menos 1 número</span>
      </div>
    </div>
  );
};

const useStyles = makeStyles(
  theme => ({
    root: {
      minWidth: 250,
      marginLeft: 20,
      fontSize: 12,
      //border: "1px solid black",
      //borderRadius: 5,
      marginBottom: -20,
      padding: "0 0 2px 10px",
      [theme.breakpoints.down(600)]: {
        margin: "0 0 30px 0",
      },
    },
    hidden: {
      display: "none",
    },
    row: {
      display: "flex",
      alignItems: "center",
    },
    icon: {
      width: 20,
      margin: "0 5px 1px 0",
    },
  }),
  { index: 1 },
);
