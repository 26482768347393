import React from "react";
import { useStyles } from "./BudgetRecommendations.style";
import { budgetRecommendations } from "@data";
import { recomendationIcon, TipIconSecond } from "@images";

interface BudgetRecommendationsProps {
  data: any;
  fromFinerio?: boolean;
}

export const BudgetRecommendations: React.FC<BudgetRecommendationsProps> = ({
  data,
  fromFinerio,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.info2}>
      {!fromFinerio && (
      <div className={classes.titleRecommend}>
        <img src={recomendationIcon} alt="" />
        Análisis y tips
      </div>
      )}
      {budgetRecommendations(data, fromFinerio).map((item, index) => (
        <div key={index} className={classes.recommendation}>
          <img src={TipIconSecond} />
          <div>{item.text}</div>
        </div>
      ))}
    </div>
  );
};
