import React, { useState, useEffect } from "react";
import { makeStyles, Theme, withWidth } from "@material-ui/core";
import {
  AddressInput,
  BirthdateInput,
  GenderSelector,
  JobTypeSelector,
  MaritalPropertyRegimeSelector,
  MaritalStateSelector,
  NameInput,
  PhoneInput,
  RutInput,
  TextInput,
} from "@components";
interface moreDataProps {
  currentUser: any;
  isDisabled: boolean;
  form: any;
}

const MoreData = ({ currentUser, form, isDisabled }: moreDataProps) => {
  const classes = useStyles();
  const [isNameEmployer, setNameEmployer] = useState<string>("");
  const [isState, setState] = useState("");
  const [isJob, setJob] = useState("");
  const [isAddress, setAddress] = useState("");
  const [isPosition, setPosition] = useState("");
  const [isProfession, setProfession] = useState("");
  const [isEstate, setEstate] = useState("");
  const [isPhone, setPhone] = useState("");
  //If married
  const [isSpouseName, setSpouseName] = useState<string>("");
  const [isSpousePaternalSurname, setSpousePaternalSurname] =
    useState<string>("");
  const [isSpouseMaternalSurname, setSpouseMaternalSurname] =
    useState<string>("");
  const [isSpouseRut, setSpouseRut] = useState<string>("");
  const [isSpouseBirthdate, setSpouseBirthdate] = useState<Date>(new Date());
  const [isSpouseGender, setSpouseGender] = useState<string>("");

  const [disabledEmployer, setIsDisabledEmployer] = useState(false);
  const [hasMaritalState, setHasMaritalState] = useState(false);

  useEffect(() => {
    if (isJob === "Dependiente") {
      setIsDisabledEmployer(true);
    } else {
      setNameEmployer("");
      setIsDisabledEmployer(false);
    }
  }, [isJob]);

  useEffect(() => {
    if (isState === "Casado/a") {
      setHasMaritalState(true);
    } else {
      setHasMaritalState(false);
    }
  }, [isState]);

  useEffect(() => {
    if (currentUser) {
      setNameEmployer(currentUser.personalData.employerName ?? isNameEmployer);
      setState(currentUser.personalData.maritalState ?? isState);
      setJob(currentUser.personalData.jobType ?? isJob);
      setAddress(currentUser.personalData.address ?? isAddress);
      setPosition(currentUser.personalData.workPosition ?? isPosition);
      setProfession(currentUser.personalData.profession ?? isProfession);
      setEstate(currentUser.personalData.maritalPropertyRegime ?? isEstate);
      setPhone(currentUser.personalData.phone ?? isPhone);
      setSpouseName(currentUser.personalData.spouseName ?? isSpouseName);
      setSpousePaternalSurname(
        currentUser.personalData.spousePaternalSurname ??
          isSpousePaternalSurname
      );
      setSpouseMaternalSurname(
        currentUser.personalData.spouseMaternalSurname ??
          isSpouseMaternalSurname
      );
      setSpouseRut(currentUser.personalData.spouseRut);
      setSpouseGender(currentUser.personalData.spouseGender ?? isSpouseGender);
      setSpouseBirthdate(
        currentUser.personalData.spouseBirthdate ?? isSpouseBirthdate
      );
    }
  }, [currentUser]);

  useEffect(() => {
    form.jobType = isJob;
    form.employerName = isNameEmployer;
    form.address = isAddress;
    form.maritalState = isState;
    form.workPosition = isPosition;
    form.profession = isProfession;
    form.phone = isPhone;
    form.maritalPropertyRegime = isEstate;
    form.spouseName = isSpouseName;
    form.spousePaternalSurname = isSpousePaternalSurname;
    form.spouseMaternalSurname = isSpouseMaternalSurname;
    form.spouseRut = isSpouseRut;
    form.spouseBirthdate = isSpouseBirthdate;
    form.spouseGender = isSpouseGender;
  }, [
    isNameEmployer,
    isState,
    isJob,
    isAddress,
    isPosition,
    isProfession,
    isEstate,
    isPhone,
    isSpouseName,
    isSpousePaternalSurname,
    isSpouseMaternalSurname,
    isSpouseRut,
    isSpouseBirthdate,
    isSpouseGender,
  ]);

  return (
    <div className={classes.containerData}>
      <div className="element">
        <span className="label">Tipo de trabajador</span>

        <JobTypeSelector
          onChange={(e) => setJob(e.target.value as string)}
          disabled={isDisabled}
          value={isJob}
          label=""
        />
      </div>
      <div className="element">
        <span className="label">Nombre Empleador (sólo dependiente)</span>

        <TextInput
          label=""
          onChange={(e) => setNameEmployer(e.target.value as string)}
          disabled={!disabledEmployer}
          value={isNameEmployer}
        />
      </div>

      <div className="element">
        <span className="label">Dirección Particular</span>
        <AddressInput
          label=""
          userUUID={""}
          onChange={(e) => setAddress(e.target.value as string)}
          disabled={isDisabled}
          value={isAddress}
        />
      </div>
      <div className="element">
        <span className="label">Cargo</span>
        <TextInput
          label=""
          onChange={(e) => setPosition(e.target.value as string)}
          disabled={isDisabled}
          value={isPosition}
        />
      </div>
      <div className="element">
        <span className="label">Profesión</span>
        <TextInput
          label=""
          onChange={(e) => setProfession(e.target.value as string)}
          disabled={isDisabled}
          value={isProfession}
        />
      </div>

      <div className="element">
        <span className="label">Teléfono / Celular</span>
        <PhoneInput
          onChange={(e) => setPhone(e.target.value as string)}
          disabled={isDisabled}
          value={isPhone}
          label=""
        />
      </div>
      <div className="element">
        <span className="label">Estado Civil</span>

        <MaritalStateSelector
          label=""
          onChange={(e) => setState(e.target.value as string)}
          disabled={isDisabled}
          value={isState}
        />
      </div>
      {hasMaritalState && (
        <>
          <span className={classes.dataCon}>Datos cónyuge</span>
          <div className="element">
            <span className="label">Nombre cónyuge</span>
            <NameInput
              value={isSpouseName}
              onChange={(e) => setSpouseName(e.target.value as string)}
              label=""
            />
          </div>
          <div className="element">
            <span className="label">Apellido paterno cónyuge</span>
            <NameInput
              value={isSpousePaternalSurname}
              onChange={(e) =>
                setSpousePaternalSurname(e.target.value as string)
              }
              label=""
            />
          </div>
          <div className="element">
            <span className="label">Apellido materno cónyuge</span>
            <NameInput
              value={isSpouseMaternalSurname}
              onChange={(e) =>
                setSpouseMaternalSurname(e.target.value as string)
              }
              label=""
            />
          </div>
          <div className="element">
            <span className="label">Rut cónyuge</span>
            <RutInput
              value={isSpouseRut}
              onChange={(e) => setSpouseRut(e.target.value as string)}
              label=""
            />
          </div>
          <div className="element">
            <span className="label">Fecha de nacimiento cónyuge</span>
            <BirthdateInput
              value={isSpouseBirthdate}
              onChange={(e) => setSpouseBirthdate(e.target.value)}
              label=""
            />
          </div>

          <div className="element">
            <span className="label">Género cónyuge</span>
            <GenderSelector
              value={isSpouseGender}
              onChange={(e) => setSpouseGender(e.target.value as string)}
              label=""
            />
          </div>
          <div className="element">
            <span className="label">Régimen de Bienes</span>

            <MaritalPropertyRegimeSelector
              onChange={(e) => setEstate(e.target.value as string)}
              disabled={isDisabled}
              value={isEstate}
              label=""
            />
          </div>
        </>
      )}
    </div>
  );
};
export default MoreData;

const useStyles = makeStyles<Theme>((theme) => ({
  containerData: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    margin: "0 auto",
    marginTop: "2rem",
    marginBottom: "4rem",
    [theme.breakpoints.down(1240)]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .element": {
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
      width: "28%",
      margin: "0.5rem 1rem",

      [theme.breakpoints.down(1241)]: {
        width: "auto",
        minWidth: "18rem",
        maxWidth: "30rem",
      },
      "& .label": {
        textTransform: "uppercase",
        fontFamily: "Montserrat",
        fontSize: "0.75rem",
        color: theme.palette.text.secondary,
        fontWeight: 600,

        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
      },

      "& >div": {
        width: "100%",
        "&>div": { width: "100%" },
        "& .MuiSelect-outlined.MuiSelect-outlined": {
          fontWeight: 500,
          color: theme.palette.text.secondary,
        },
        "& input": {
          width: "100%",
          fontWeight: 500,
          color: theme.palette.text.secondary,
        },
      },
    },
  },
  dataCon: {
    width: "100%",
    margin: "1rem",
    color: "#6b6b6b",
    fontSize: "1.25rem",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    [theme.breakpoints.down(1241)]: {
      width: "18rem",
    },
  },
}));
