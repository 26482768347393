import { gql, useQuery } from "@apollo/client";
import { Investment } from "@interfaces";
import { AgfFragment, FundFragment, SerieFragment } from "../fragments";
import { useAuth } from "@hooks";

export const GET_USER_INVESTMENTS_QUERY = gql`
  query GetInvestments {
    getInvestments {
      id
      type
      #period
      #investmentFrequency
      totalDeposited
      totalInvested
      pendingInvestments
      pendingWithdrawals
      totalWithdrawn
      currentValue
      profitability
      status
      goalId
      movements {
        id
        date
        amount
        type
        status
      }
      agf {
        ${AgfFragment}
      }
      fund {
        ${FundFragment}
      }
      serie {
        ${SerieFragment}
      }
    }
  }
`;

interface QueryResult {
  getInvestments: Investment[] | null;
}

interface HookReturn {
  investments: Investment[];
  loading: boolean;
}

export const useUserInvestments = (): HookReturn => {
  const { isLoggedIn } = useAuth();
  const { data, loading } = useQuery<QueryResult>(GET_USER_INVESTMENTS_QUERY, {
    fetchPolicy: "cache-and-network",
    skip: !isLoggedIn,
  });
  return { investments: isLoggedIn ? data?.getInvestments ?? [] : [], loading };
};
