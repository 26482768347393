import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    height: "37.5rem",
    alignItems: "center",
    [theme.breakpoints.down(1240)]: {
      flexWrap: "nowrap",
      height: "auto",
    },
  },
  mainText: {
    width: "33rem",
    [theme.breakpoints.down(1240)]: {
      width: "auto",
      margin: "0 0.6rem",
    },
    "& .text": {
      fontFamily: "Montserrat",
      fontSize: "1rem",
      lineHeight: 1.71,
      textAlign: "left",
      color: theme.palette.text.primary,
      [theme.breakpoints.down(1240)]: {
        fontSize: "0.85rem",
      },
    },
  },
  mainImage: {
    width: "33rem",
    [theme.breakpoints.down(1240)]: {
      width: "auto",
      margin: "5rem -1rem",
      order: 3,
    },
  },
  whiteBox: {
    width: "27.188rem",
    height: "23.875rem",
    margin: "0rem 4rem 6.063rem",
    padding: "1.938rem 2.938rem 4.25rem",
    borderRadius: "1.875rem",
    boxShadow: "0.25rem 0.25rem 1.25rem 0.125rem rgba(0,0,0,0.2)",
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    [theme.breakpoints.down(1240)]: {
      order: 2,
      width: "auto",
      margin: "2rem 0",
      textAlign: "center",
      height: "auto",
    },
    "& img": {
      width: "3.438rem",
      height: "3.438rem",
      alignSelf: "center",
      [theme.breakpoints.down(1240)]: {
        margin: "1.803rem auto",
      },
    },
    "& .title": {
      fontFamily: "Montserrat",
      fontSize: "1.25rem",
      fontWeight: "bold",
      lineHeight: 1.5,
      textAlign: "left",
      color: theme.palette.text.primary,
      [theme.breakpoints.down(1240)]: {
        textAlign: "center",
        fontSize: "1rem",
        marginBottom: "1.5rem",
      },
    },
    "& .text": {
      fontFamily: "Montserrat",
      fontSize: "1rem",
      lineHeight: 1.88,
      textAlign: "left",
      color: theme.palette.text.secondary,
      [theme.breakpoints.down(1240)]: {
        textAlign: "center",
        margin: "0 10%",
      },
      [theme.breakpoints.down(400)]: {
        textAlign: "center",
        margin: "0rem",
      },
    },
  },
  nextButton: {
    order: 4,
  },
}));
