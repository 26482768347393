import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  optionsContainer: {
    width: "100%",
    minHeight: "100vh",

    position: "absolute",
    borderRadius: "1.875rem",
    top: 0,
    backgroundImage: "linear-gradient(165deg, #0a5669 3%, #00a9c2 343%)",
  },
  header: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "2rem",
    height: "7.4rem",
    "& >img": {
      width: "2.5rem",
    },
    "& .title": {
      fontFamily: "Montserrat",
      fontSize: "1rem",
      color: "#ffffff",
      lineHeight: "1.5rem",
    },
  },
  option: {
    width: "100%",
    backgroundColor: "#F4F3F3",
    minHeight: "100vh",
    padding: "1.8rem",
    borderRadius: "1.875rem",
    paddingBottom: "8rem",
  },
}));
