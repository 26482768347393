import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "100%",
    position: "relative",
    minHeight: 529,
  },
  form: {
    paddingRight: "15rem",
    [theme.breakpoints.down(1280)]: { paddingRight: "0" },
  },
  incomeSpent: {
    marginTop: 25,
    display: "flex",
    gap: 135,
    [theme.breakpoints.down(1135)]: {
      flexDirection: "column",
      gap: 60,
    },
  },
  income: {
    marginBottom: 22,
    fontSize: 24,
    fontFamily: "Montserrat",
    fontWeight: "bold",
    [theme.breakpoints.down(486)]: {
      fontSize: 18,
    },
  },
  text: {
    color: "#393e44",
    marginBottom: 20,
  },
  breakdown: {
    display: "flex",
    justifyContent: "space-between",

    [theme.breakpoints.down(1280)]: {
      flexFlow: "column",
      width: 355,
      justifyContent: "center",
      margin: "0 auto",
      "& .leftSectionWrapper div": {
        color: theme.palette.text.primary,
      },
    },
  },
  amount: {
    gap: 11,
    display: "flex",
    background: "#8352fd",
    boxShadow: "4px 4px 20px 2px rgb(0 0 0 / 20%)",
    borderRadius: 20,
    padding: "19px 15px 19px 28px",
    color: "#fff",
    width: 478,
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down(1135)]: {
      width: "auto",
    },
  },
  fixedVariable: {
    marginTop: 20,
    display: "flex",
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: 500,
    color: "#6b6b6b",
    justifyContent: "space-around",
    [theme.breakpoints.down(452)]: {
      flexDirection: "column",
      gap: 15,
    },
  },
  amountArrow: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down(452)]: {
      justifyContent: "space-around",
    },
  },
  amount2: {
    width: 170,
    margin: "5px 0 0",
    padding: "11px 14.5px 8px 21px",
    borderRadius: 10,
    boxShadow: "4px 4px 8px 2px rgba(0, 0, 0, 0.1)",
    border: "solid 1px #9d9d9d",
    backgroundColor: "#ffffff",
    fontSize: 16,
    fontWeight: "bold",
    color: "#393e44",
    alignItems: "center",
    [theme.breakpoints.down(452)]: {
      width: "100%",
    },
  },
  box: {
    marginTop: 40,
    height: 200,
    borderRadius: 20,
    boxShadow: "4px 4px 20px 2px rgba(0,0,0,0.2)",
    backgroundColor: "#8352fd",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  },
  button: {
    cursor: "pointer",
    width: 420,
    height: 100,
    padding: "22px 22px 22px",
    borderRadius: 50,
    boxShadow: "4px 4px 20px 2px rgba(0,0,0,0.2)",
    backgroundColor: "#ffffff",
    display: "flex",
    fontSize: 24,
    fontWeight: "bold",
    color: "#393e44",
    alignItems: "center",
    [theme.breakpoints.down(520)]: {
      fontSize: 20,
    },
  },
  img: {
    width: "8.75rem",
    margin: "-75px 0 0 60px",
    [theme.breakpoints.down(452)]: {
      display: "none",
    },
  },

  inputs: {
    display: "flex",
    marginTop: 50,
    [theme.breakpoints.down(1280)]: {
      justifyContent: "center",
      marginTop: 0,
    },
  },
  informacion: {
    display: "grid",
    gap: 20,
  },
  titulo: {
    flexGrow: 0,
    fontFamily: "Montserrat",
    fontSize: "1.5rem",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#7d7c7c",
    [theme.breakpoints.down(1280)]: {
      fontSize: "1.1rem",
      paddingLeft: "0.5rem",
    },
  },
  info1: {
    display: "flex",
    width: 614,
    height: 133,
    flexGrow: 0,
    padding: "49px 19px 49px 60px",
    borderRadius: 30,
    boxShadow: "4px 4px 20px 2px rgba(0,0,0,0.2)",
    backgroundColor: "#008296",
    [theme.breakpoints.down(1280)]: {
      width: 370,
      padding: "23px 19px 29px 30px",
    },
  },
  presupuesto: {
    width: 347.7,
    height: 35,
    flexGrow: 0,
    fontFamily: "Montserrat",
    fontSize: 36,
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#ffffff",
    [theme.breakpoints.down(1280)]: {
      height: "auto",
      display: "flex",
      alignItems: "center",
    },
  },
  imagen: {
    width: 209,
    height: 176,
    flexGrow: 0,
    margin: "-120px 51px 24px 300px",
    position: "absolute",
    "& img": {
      [theme.breakpoints.down(1280)]: {
        margin: 0,
        height: 85,
        width: 100,
        flexGrow: 0,
      },
    },
    [theme.breakpoints.down(1280)]: {
      margin: 0,
      width: 315,
      justifyContent: "end",
      display: "flex",
    },
  },
  boton: {
    width: "auto",
  },
  savingBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  savingBoxMobile: {
    [theme.breakpoints.down(1280)]: {
      justifyContent: "center",
      marginTop: "2rem",
    },
  },
  bottom: {
    marginBottom: 100,
  },
}));
