import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "50rem",
    zIndex: 2,
    margin: "0 auto",
    [theme.breakpoints.down(1241)]: {
      width: "100%",
    },
  },
  slide: {
    width: "30rem",
    height: "20rem",
    margin: "0 ",
    [theme.breakpoints.down(1241)]: {
      height: "auto",
      padding: "1rem",
    },

    "& >img": {
      margin: "0 auto",
      width: "21rem",
      [theme.breakpoints.down(1241)]: {
        width: "auto",
      },
    },
  },
  sliderBlock: {
    display: "block",
    width: "30rem",
    height: "15.125rem",
    [theme.breakpoints.down(1241)]: {
      width: "100%",
    },
  },
  dots: {
    bottom: 0,
    "& li.slick-active button::before": {
      color: "#8352FD",
    },
    "& li": {
      "& button::before": {
        fontSize: theme.typography.pxToRem(14),
        color: "#9D9D9D",
        opacity: 0.5,
      },
    },
  },
}));
