import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    // width: "auto",
    padding: "2rem 11.25rem",
    backgroundColor: "#F4F3F3",
    [theme.breakpoints.down(1240)]: {
      flexDirection: "column",
      display: "flex",
      alignItems: "center",
      padding: "2rem 0",
    },
  },
  firstSection: {
    display: "flex",
    flexDirection: "column",
    width: "30rem",
    [theme.breakpoints.down(1240)]: {
      borderRadius: "1.875rem",
      boxShadow: " 0.25rem 0.25rem 1.25rem 0.125rem rgba(0,0,0,0.2)",
      backgroundColor: "#ffffff",
      padding: "2.375rem 1.75rem",
      marginTop: "5rem",
      height: "20.875rem",
      marginBottom: "2.5rem",
      width: "22.438rem",
    },
    "& .buttonContainer": {
      paddingTop: "6rem",
      // paddingRight: "4.375rem",
      [theme.breakpoints.down(1240)]: {
        marginTop: "4rem",
        textAlign: "center",
        padding: 0,
      },
    },
  },
  secondSection: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "38.0063rem",
    marginLeft: "6.25rem",
    [theme.breakpoints.down(1240)]: {
      width: "auto",
      margin: "2rem 0",
      alignItems: "center",
    },
    "& .imageContainer": {
      // width: "38.0063rem",
      // height: "22.75rem",
      [theme.breakpoints.down(1240)]: {
        width: "21.0625rem",
        height: "10.625rem",
        marginTop: "-3.625rem",
        marginBottom: "2.625rem",
      },
    },
  },
  text: {
    width: "27.625rem",
    height: "18.438rem",
    fontFamily: "Montserrat",
    fontSize: "1rem",
    lineHeight: 1.88,
    textAlign: "left",
    [theme.breakpoints.down(1240)]: {
      fontSize: 12,
      lineHeight: 1.67,
    },
    color: theme.palette.text.secondary,
    "& .style1": {
      fontWeight: "bold",
    },
  },
  textSub: {
    width: "28.25rem",
    height: "2.75rem",
    fontFamily: "Montserrat",
    fontSize: "1.5rem",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.83,
    letterSpacing: "normal",
    textSlign: "left",
    color: "#0a5669",
    paddingBottom: "4rem",
    [theme.breakpoints.down(1240)]: {
      fontSize: "1rem",
    },
  },
}));
