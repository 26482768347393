import { FormInvestment, MovementType } from "@interfaces";
import moment from "moment";
import {
  CreateInvestmentPayload,
  createUserInvestments,
} from "./createUserInvestments.mutation";
import { CreateMovementPayload, createUserInvestmentMovements } from "..";
import { GET_USER_INVESTMENTS_QUERY } from "@apollo";
import { ApolloClient } from "@apollo/client";

export async function createUserInvestmentsWithMovements(
  payloads: Omit<FormInvestment, "agfId" | "fundId">[],
  client: ApolloClient<object>,
): Promise<boolean> {
  const investmentsPayloads: CreateInvestmentPayload[] = [];
  const movementsPayloads: CreateMovementPayload[] = [];
  payloads.forEach(payload => {
    const date = moment().tz("America/Santiago").format();
    const amount = Math.abs(payload.invested ?? 0);
    investmentsPayloads.push({
      type: payload.type,
      serieId: payload.serieId as number,
      date,
      goalId: payload.goalId,
      investmentFrequency: payload.investFrequency as string,
      period: payload.period as number,
    });
    movementsPayloads.push({
      type: amount > 0 ? MovementType.DEPOSIT : MovementType.WITHDRAWAL,
      serieId: payload.serieId as number,
      amount,
      date,
      investmentType: payload.type,
    });
  });
  await createUserInvestments(investmentsPayloads, false, client);
  await createUserInvestmentMovements(movementsPayloads, false, client);
  client.refetchQueries({
    include: [GET_USER_INVESTMENTS_QUERY],
  });
  return true;
}
