import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    width: "70rem",
    margin: "3rem auto",
    background: "#F4F3F3",
    padding: "4.3rem 6rem 8rem",
    borderRadius: 30,
    [theme.breakpoints.down(1240)]: {
      width: "100%",
      padding: "2rem 3rem 4rem",
    },
  },
  title: {
    paddingBottom: "2rem",
    fontFamily: "Montserrat",
    [theme.breakpoints.down(1240)]: {
      textAlign: "center",
    },
  },
  containerCard: {
    display: "flex",
    flexDirection: "column",
  },
  card: {
    border: `1px solid #d6d6d6`,
    width: "17.8rem",
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0.87rem 1.25rem",
    marginBottom: "2rem",
    [theme.breakpoints.down(1240)]: {
      margin: "0 auto 1rem",
    },
    "& svg": {
      color: theme.palette.text.secondary,
      width: "1.25rem",
    },
  },
  subtitleCard: {
    width: "13.2rem",
    color: theme.palette.text.secondary,
    fontSize: "0.75rem",
    fontWeight: 300,
    fontFamily: theme.typography.fontFamily,
  },
  containerSection: {
    display: "flex",
    gap: "10rem",
    [theme.breakpoints.down(1240)]: {
      flexDirection: "column-reverse",
      gap: "2rem",
    },
  },
  buttonPrimary: {
    width: "14rem",
    height: "4.8rem",
    fontFamily: theme.typography.fontFamily,
    fontSize: "1.2rem",
    fontWeight: 300,
    [theme.breakpoints.down(1240)]: {
      margin: "0 auto",
      display: "flex",
    },
  },
  parrafo: {
    color: "#7d7c7c",
    fontSize: "1rem",
    fontFamily: "Montserrat",
    paddingTop: "1.75rem",
    width: "22rem",
    [theme.breakpoints.down(1240)]: {
      width: "280px",
      margin: "0 auto",
      fontSize: "0.75rem",
    },
  },
}));
export default useStyles;
