import React from "react";
import { Select } from "@components";
import { CommonSelectorProps, REASONS_CONTACT_OPTIONS } from "..";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends CommonSelectorProps {}

export const ReasonsForContactSelector: React.FC<Props> = ({
  className,
  name,
  label,
  ...props
}) => {
  return (
    <Select
      className={`${className || ""} altercap-reason-selector`}
      name={name || "reason"}
      label={label || "Motivo"}
      options={REASONS_CONTACT_OPTIONS}
      {...props}
    />
  );
};
