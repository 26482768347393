//This will get you the Minutes left on the missions
export const getMissionTime = (missions) => {
  let pendingMissions = missions?.filter((obj) => obj.state == "PENDING")
  let minRemainingArray = []
  pendingMissions.map((map) => minRemainingArray.push(map.mission.estimateTimeMin))
  if(minRemainingArray.length > 0) {
    return `${minRemainingArray.reduce((a, b) => a + b)} Minutos`
  } else {
    return "Completado"
  }
}
