import React from "react";
import { FormControl, InputLabel, OutlinedInput } from "@material-ui/core";
import { OnChangeType } from "@interfaces";
import MaskedInput from "react-text-mask";

interface DateInputProps {
  className?: string;
  name: string;
  label?: string;
  value?: string;
  onChange: OnChangeType;
  error?: boolean;
  enabled?: boolean;
  tabIndex?: number;
}
export const DateInput: React.FC<DateInputProps> = ({
  className,
  name,
  label,
  value,
  onChange,
  enabled,
  error,
  tabIndex,
}) => {
  return (
    <FormControl
      disabled={enabled}
      className={`${className || ""}`}
      variant="outlined"
    >
      {label && (
        <InputLabel htmlFor={`formatted-text-mask-input-${name}`}>
          {label}
        </InputLabel>
      )}
      <OutlinedInput
        id={`formatted-text-mask-input-${name}`}
        name={name}
        label={label}
        value={value}
        onChange={onChange}
        inputComponent={BirthdateMaskedInput as any}
        error={error}
        inputProps={{ tabIndex }}
      />
    </FormControl>
  );
};

const MASK = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];
interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}
function BirthdateMaskedInput(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={MASK}
      placeholderChar={"\u2000"}
    />
  );
}
