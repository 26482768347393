import { ButtonPrimary } from "@components/Reusables";
import React, { useState, useEffect, useContext } from "react";
import { useStyles } from "./M4mainComponent.style";
import { useMediaQuery } from "@material-ui/core";
import { tipIcon, infoIcon, videoM4ALoansAndDebts } from "@images";
import { AppContext } from "@context";

interface M4MainComponentProps {
  handleClick: () => void;
}

const M4MainComponent: React.FC<M4MainComponentProps> = ({ handleClick }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const videoUrl = "48rUr6JEQag";

  return (
    <div className={classes.container}>
      <div className={classes.firstSection}>
        <div className="video">
          <iframe
            width="auto"
            height="auto"
            src={`https://www.youtube.com/embed/${videoUrl}?modestbranding=1&rel=0`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </div>
      </div>
      <div className={classes.middleSection}>
        <div className={` ${classes.whiteBox} info1`}>
          <div className="texasContainer">
            <div className="icon">
              <img src={infoIcon} />
            </div>
            <div className=" rightSide">
              <h1 className="title">PAGO MÍNIMO</h1>
              <span className="text">
                Monto que fue fijado al haber abierto tu tarjeta.
              </span>
            </div>
          </div>
        </div>
        <div className={` ${classes.whiteBox} info2`}>
          <div className="texasContainer">
            <div className="icon">
              <img src={infoIcon} />
            </div>
            <div className="rightSide">
              <h1 className="title">INTERÉS ROTATIVO</h1>
              <span className="text">
                Interés que se aplica <strong> cada vez que no pagues </strong>{" "}
                el total de lo que te facturan en la tarjeta, y
                <strong> se suma a lo que quedas debiendo.</strong>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.lastSection}>
        <div className={` ${classes.whiteBox} info3`}>
          <div className="texasContainer">
            <div className="icon">
              <img src={tipIcon} />
            </div>
            <div className="rightSide">
              <h1 className="title">CONSEJO</h1>
              <span className="text">
                Fija un <strong> recordatorio </strong> días antes a la fecha de
                pago para asegurarte que puedas pagar todo lo que corresponda y
                <strong> no se te cobre el pago mínimo </strong> solamente.
                Además
                <strong> evita siempre pagar el mínimo.</strong>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.bottomSection}>
        {!isMobile ? (
          <ButtonPrimary
            text="Terminar misión"
            onClick={() => handleClick()}
            secondaryVersion
          />
        ) : (
          <div className="centerButton">
            <ButtonPrimary
              text="Terminar misión"
              onClick={() => handleClick()}
              secondaryVersion
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default M4MainComponent;
