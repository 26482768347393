import { ApolloClient, gql, useQuery } from "@apollo/client";
import { UserPendingMission } from "@interfaces";
import { useAuth } from "@hooks";

export const GET_USER_MISSIONS = gql`
    query UserMissions {
      userMissions {
        state
        data
        mission {
          id
          description
          shortDescription
          condition
          roks
          name
          nextMission
          estimateTimeMin
          subCategory
          category
          subCategoryNumber
        }
        updatedAt
      }
    }
`;

interface QueryResult {
  userMissions: UserPendingMission[];
}

interface HookReturn {
  userMissions: UserPendingMission[];
  loading: boolean;
}

export const getUserMissions = (): HookReturn => {
  const { data, loading } = useQuery<QueryResult>(GET_USER_MISSIONS);
  return {
    userMissions: data?.userMissions ?? [],
    loading
  };
};
