import { gql, useQuery } from "@apollo/client";
import { Portfolio } from "@interfaces";

export const GET_PORTFOLIOS_NAMES_QUERY = gql`
  query GetPortfolios {
    getPortfolios {
      id
      name
    }
  }
`;

interface QueryResult {
  getPortfolios: Pick<Portfolio, "id" | "name">[];
}

interface HookReturn {
  portfolios: Pick<Portfolio, "id" | "name">[];
  loading: boolean;
}

export const usePortfoliosNames = (): HookReturn => {
  const { data, loading } = useQuery<QueryResult>(GET_PORTFOLIOS_NAMES_QUERY);
  return { portfolios: data?.getPortfolios ?? [], loading };
};
