export * from './myAccount';
export * from './Investments';
export * from './habits';
export * from './Rokipedia';
export * from './myFinances';
export * from './registrationMailing';
export * from './Services';
export * from './Benefits';
export * from './Coolebra';
export * from './Services';
export * from './SmartSave';
//AppfrontPage
const APP_FRONT_PAGE =
  'https://storage.googleapis.com/rokinapp-images/ProductTour/appFrontPage.png';
const APP_FRONT_PAGE_CUT =
  'https://storage.googleapis.com/rokinapp-images/ProductTour/appFrontPageCut.png';
// About Us / quienes somos
const WHY_ROKIN_TITLE =
  'https://storage.googleapis.com/rokinapp-images/aboutUs/tituloPorQueRokin.svg';
const THE_TEAM =
  'https://storage.googleapis.com/rokinapp-images/aboutUs/tituloElEquipo%20(1).svg';
const MARION_FRAME =
  'https://storage.googleapis.com/rokinapp-images/aboutUs/marionM_frame.png';
const TU_FRAME =
  'https://storage.googleapis.com/rokinapp-images/aboutUs/tu_frame.png';
const VICENTE_FRAME =
  'https://storage.googleapis.com/rokinapp-images/aboutUs/vicenteO_frame.png';
const WANDA_FRAME =
  'https://storage.googleapis.com/rokinapp-images/aboutUs/wandaS_frame.png';
const CRISTOBAL_FRAME =
  'https://storage.googleapis.com/rokinapp-images/aboutUs/cristobalO_frame.png';
const JOAQUIN_E_FRAME =
  'https://storage.googleapis.com/rokinapp-images/aboutUs/joaquinE_frame.png';
const JOAQUIN_S_FRAME =
  'https://storage.googleapis.com/rokinapp-images/aboutUs/joaquinS_frame.png';
const JOSE_FRAME =
  'https://storage.googleapis.com/rokinapp-images/aboutUs/joseO_frame.png';
const AGUSTIN_FRAME =
  'https://storage.googleapis.com/rokinapp-images/aboutUs/agustinZ_frame.png';
const ALEJANDRO_FRAME =
  'https://storage.googleapis.com/rokinapp-images/aboutUs/alejandroR_frame.png';
const CARLOS_FRAME =
  'https://storage.googleapis.com/rokinapp-images/aboutUs/carlosV_frame.png';
const NICOLE_FRAME =
  'https://storage.googleapis.com/rokinapp-images/aboutUs/nicoleT_frame.png';
const WHY_ROKIN_TITLE_2LINES =
  'https://storage.googleapis.com/rokinapp-images/aboutUs/porQueRokin_title.svg';
const PEOPLE_WHITE_ICON =
  'https://storage.googleapis.com/rokinapp-images/icons/use_icon_white.svg';
const WARNING_WHITE_ICON =
  'https://storage.googleapis.com/rokinapp-images/icons/warning_white.svg';
const WARNING_GREEN_ICON =
  'https://storage.googleapis.com/rokinapp-images/icons/warning_green.svg';
export {
  WARNING_GREEN_ICON,
  WARNING_WHITE_ICON,
  PEOPLE_WHITE_ICON,
  WHY_ROKIN_TITLE,
  WHY_ROKIN_TITLE_2LINES,
  THE_TEAM,
  MARION_FRAME,
  TU_FRAME,
  VICENTE_FRAME,
  WANDA_FRAME,
  CRISTOBAL_FRAME,
  JOAQUIN_E_FRAME,
  JOAQUIN_S_FRAME,
  JOSE_FRAME,
  AGUSTIN_FRAME,
  ALEJANDRO_FRAME,
  CARLOS_FRAME,
  NICOLE_FRAME,
  APP_FRONT_PAGE,
  APP_FRONT_PAGE_CUT,
};
