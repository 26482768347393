import React from "react";
import { Snackbar as MuiSnackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core";

export type AlertType = "success" | "error";
interface Props {
  open: boolean;
  onClose: () => void;
  message?: string;
  type?: AlertType;
}
export const Snackbar: React.FC<Props> = ({ open, onClose, message, type }) => {
  const classes = useStyles();
  return (
    <MuiSnackbar
      className={classes.snackbar}
      key={message}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      autoHideDuration={3000}
      transitionDuration={{ enter: 500, exit: 0 }}
      open={open}
      onClose={onClose}
    >
      <Alert className={classes.alert} severity={type} onClose={onClose}>
        {message}
      </Alert>
    </MuiSnackbar>
  );
};
const useStyles = makeStyles(
  theme => ({
    snackbar: {
      borderRadius: 8,
    },
    alert: {
      borderRadius: 8,
    },
  }),
  { index: 1 },
);
