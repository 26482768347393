import { gql, useQuery } from "@apollo/client";
import { DatedValue } from "@interfaces";

const GET_HISTORICAL_PROFIT_VALUE = gql`
  query GetHistoricalProfit {
    getHistoricalProfit {
      date
      value
    }
  }
`;

interface QueryResult {
  getHistoricalProfit: DatedValue | null;
}

interface HookReturn {
  data: DatedValue | null;
  loading: boolean;
}

export const useHistoricalProfit = (): HookReturn => {
  const { data, loading } = useQuery<QueryResult>(GET_HISTORICAL_PROFIT_VALUE);
  // console.log("data2 ENQUERY", data?.getHistoricalProfit)
  return { historicalProfit: data?.getHistoricalProfit ?? null, loading };
};
