//1
const SYNC_ICON =
  "https://rokinapp-images.s3.amazonaws.com/ourSolutions/icons/sync_icon+(1).svg";
const BUDGET_ICON =
  "https://rokinapp-images.s3.amazonaws.com/ourSolutions/icons/presupuesto_icon+(2).svg";
const CREDIT_OUR_ICON =
  "https://rokinapp-images.s3.amazonaws.com/ourSolutions/icons/deudasYCreditos_icon+(1).svg";
const BALANCE =
  "https://rokinapp-images.s3.amazonaws.com/ourSolutions/icons/balance_icon+(1).svg";
const HABITS_ICON =
  "https://rokinapp-images.s3.amazonaws.com/ourSolutions/icons/habitos_icon.svg";
//2
const DECISION_ICON =
  "https://rokinapp-images.s3.amazonaws.com/ourSolutions/icons/decisiones_icon.svg";
const ENTIENDE_ICON =
  "https://rokinapp-images.s3.amazonaws.com/ourSolutions/icons/entiende_icon+(1).svg";
const INVEST_ICON =
  "https://rokinapp-images.s3.amazonaws.com/ourSolutions/icons/invest_icon.svg";
const PRODUCTOS_ICON =
  "https://rokinapp-images.s3.amazonaws.com/ourSolutions/icons/productos_icon.svg";
//3
const AUTOMATIZA_ICON =
  "https://rokinapp-images.s3.amazonaws.com/ourSolutions/icons/automatiza_icon.svg";
const PROYECTA_ICON =
  "https://rokinapp-images.s3.amazonaws.com/ourSolutions/icons/proyecta_icon+(1).svg";
const OBJETIVOS_ICON =
  "https://rokinapp-images.s3.amazonaws.com/ourSolutions/icons/objetivos_icon+(1).svg";
const MICROAHORRO_ICON =
  "https://rokinapp-images.s3.amazonaws.com/ourSolutions/icons/microahorro_icon+(1).svg";
const MONITOREA_ICON =
  "https://rokinapp-images.s3.amazonaws.com/ourSolutions/icons/monitorea_icon+(1).svg";

//mockup
const SOLUTION_1_MOCKUP =
  "https://rokinapp-images.s3.amazonaws.com/ourSolutions/mockups/solucion1_desktop.svg";
const SOLITION_1_MOCKUP_DESKTOP =
  "https://storage.googleapis.com/rokinapp-images/ourSolutions/mockups/SolutionMockup_desktop";
const SOLUTION_2_MOCKUP =
  "https://rokinapp-images.s3.amazonaws.com/ourSolutions/mockups/solucion2_desktop.svg";
const SOLUTION_3_MOCKUP =
  "https://storage.googleapis.com/rokinapp-images/ourSolutions/mockups/solucion3_desktop.svg";
const BANNER_ICON =
  "https://rokinapp-images.s3.amazonaws.com/ourSolutions/banner/bannerRokin_desktop+(1).svg";
const CLOCK_ICON =
  "https://storage.googleapis.com/rokinapp-images/ourSolutions/icons/clock_icon.svg";
const INVOICE_DOLLAR_ICON =
  "https://storage.googleapis.com/rokinapp-images/ourSolutions/icons/invoiceDollar_icon.svg";
const TRENDUP_ICON =
  "https://storage.googleapis.com/rokinapp-images/ourSolutions/icons/trendUp_icon.svg";
const SAFEBOX_ICON =
  "https://storage.googleapis.com/rokinapp-images/ourSolutions/icons/safebox_icon.svg";
const ADMINISTRATION_MOCKUP_DESKTOP =
  "https://storage.googleapis.com/rokinapp-images/ourSolutions/banner/administraMockupdesktop%20(1).webp";
const FINANCIAL_PERSONAL_ICON =
  "https://storage.googleapis.com/rokinapp-images/ourSolutions/mockups/serviciosMockup_desktop.webp";
const MONEY_ICON =
  "https://storage.googleapis.com/rokinapp-images/ourSolutions/icons/money_icon.svg";
const BUILB_ICON =
  "https://storage.googleapis.com/rokinapp-images/ourSolutions/icons/bulb_icon.svg";
const TALK_BUBBLE_ICON =
  "https://storage.googleapis.com/rokinapp-images/ourSolutions/icons/talkBubble_icon.svg";
const SUPPORT_ICON =
  "https://storage.googleapis.com/rokinapp-images/ourSolutions/icons/support_icon.svg";
const COOLEBRA_MOCKUP =
  "https://storage.googleapis.com/rokinapp-images/ourSolutions/mockups/coolebraMockup_desktop%403x.webp";
const COOLEBRA_ICON2 =
  "https://storage.googleapis.com/rokinapp-images/ourSolutions/mockups/coolebra_iconBox.png";
export {
  COOLEBRA_ICON2,
  COOLEBRA_MOCKUP,
  SUPPORT_ICON,
  TALK_BUBBLE_ICON,
  BUILB_ICON,
  MONEY_ICON,
  FINANCIAL_PERSONAL_ICON,
  ADMINISTRATION_MOCKUP_DESKTOP,
  INVOICE_DOLLAR_ICON,
  TRENDUP_ICON,
  SAFEBOX_ICON,
  CLOCK_ICON,
  SOLITION_1_MOCKUP_DESKTOP,
  BANNER_ICON,
  SOLUTION_1_MOCKUP,
  SOLUTION_2_MOCKUP,
  SOLUTION_3_MOCKUP,
  OBJETIVOS_ICON,
  MICROAHORRO_ICON,
  MONITOREA_ICON,
  PRODUCTOS_ICON,
  INVEST_ICON,
  ENTIENDE_ICON,
  DECISION_ICON,
  SYNC_ICON,
  BUDGET_ICON,
  CREDIT_OUR_ICON,
  BALANCE,
  AUTOMATIZA_ICON,
  HABITS_ICON,
  PROYECTA_ICON,
};
