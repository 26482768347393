import React, { useCallback, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import {
  ButtonPrimary,
  Select,
  INVESTMENT_OBJECTIVE_OPTIONS,
  INVESTMENT_HORIZON_OPTIONS,
  INVESTMENT_EXPERIENCE_OPTIONS,
  INVESTMENT_PRODUCT_OPTIONS,
  INVESTMENT_AFFIRMATION_OPTIONS,
  INVESTMENT_CONCERNS_OPTIONS,
  INVESTMENT_LEVEL_OPTIONS,
  INVESTMENT_SAVINGS_OPTIONS,
  INVESTMENT_PROFESSION_OPTIONS,
} from '@components';
import { calculateSuggestedProfile2 } from '@utils';
import { readSync } from 'fs';

interface DiscoverProfileProps {
  setShowDiscoverProfile: () => void;
  setInvestorProfile: () => void;
  form: any;
  isNotMission?: boolean;
}

export const DiscoverProfile: React.FC<DiscoverProfileProps> = ({
  setShowDiscoverProfile,
  setInvestorProfile,
  form,
  isNotMission,
}) => {
  const classes = useStyles();
  const [investmentObjective, setInvestmentObjective] = useState<string>(
    form.investmentObjective ? form.investmentObjective : ''
  );
  const [investmentHorizon, setInvestmentHorizon] = useState<string>(
    form.investmentHorizon ? form.investmentHorizon : ''
  );
  const [product, setProduct] = useState<string>(
    form.product ? form.product : ''
  );
  const [affirmation, setAffirmation] = useState<string>(
    form.affirmation ? form.affirmation : ''
  );
  const [experience, setExperience] = useState<string>(
    form.investmentExperience ? form.investmentExperience : ''
  );
  const [concern, setConcern] = useState<string>(
    form.investmentConcern ? form.investmentConcern : ''
  );
  const [level, setLevel] = useState<string>(form.level ? form.level : '');
  const [savings, setSavings] = useState<string>(
    form.savings ? form.savings : ''
  );
  const [profession, setProfession] = useState<string>(
    form.investmentProfession ? form.investmentProfession : ''
  );

  const handleDiscoverClick = useCallback(() => {
    const result = calculateSuggestedProfile2(
      investmentObjective,
      investmentHorizon,
      product,
      affirmation,
      experience,
      concern,
      level,
      savings,
      profession
    );
    if (result.completed) {
      form.investmentObjective = investmentObjective;
      form.investmentHorizon = investmentHorizon;
      form.product = product;
      form.affirmation = affirmation;
      form.investmentExperience = experience;
      form.investmentConcern = concern;
      form.level = level;
      form.savings = savings;
      form.investmentProfession = profession;
    }
    setInvestorProfile(result.profile);
    setShowDiscoverProfile(false);
  }, [
    form,
    setInvestorProfile,
    setShowDiscoverProfile,
    investmentObjective,
    investmentHorizon,
    product,
    affirmation,
    experience,
    concern,
    level,
    savings,
    profession,
  ]);

  return (
    <>
      <section className={classes.containerData}>
        <div
          className={`${classes.children} ${
            isNotMission && classes.notMissionChildren
          }`}
        >
          <div className={classes.selectors}>
            <Select
              className={classes.input}
              name={'investmentObjective'}
              label={'¿Cuál es el principal objetivo de sus inversiones?'}
              options={INVESTMENT_OBJECTIVE_OPTIONS}
              value={investmentObjective}
              onChange={(e) => setInvestmentObjective(e.target.value as string)}
            />
            <Select
              className={classes.input}
              name={'investmentHorizon'}
              label={
                '¿Cuál de estas alternativas representa mejor su horizonte de inversión?'
              }
              value={investmentHorizon}
              options={INVESTMENT_HORIZON_OPTIONS}
              onChange={(e) => setInvestmentHorizon(e.target.value as string)}
            />
            <Select
              className={classes.input}
              name={'investmentExperience'}
              label={
                '¿Podría indicarnos qué grado de conocimientos financieros posee?'
              }
              value={experience}
              options={INVESTMENT_EXPERIENCE_OPTIONS}
              onChange={(e) => setExperience(e.target.value as string)}
            />
            <Select
              className={classes.input}
              name={'product'}
              label={
                'Indique el producto más sofisticado en el cual ha invertido o invierte:'
              }
              value={product}
              options={INVESTMENT_PRODUCT_OPTIONS}
              onChange={(e) => setProduct(e.target.value as string)}
            />
            <Select
              className={classes.input}
              name={'affirmation'}
              label={
                '¿Con cuál de las siguientes afirmaciones se identifica mejor?'
              }
              value={affirmation}
              options={INVESTMENT_AFFIRMATION_OPTIONS}
              onChange={(e) => setAffirmation(e.target.value as string)}
            />
          </div>
          <div className={classes.selectors}>
            <Select
              className={`${classes.input} ${classes.specialInput}`}
              name={'investmentConcern'}
              label={
                'Si tiene una inversión con una rentabilidad de 30% y producto de variaciones en el mercado tiene una baja de 20% quedándole un 10% a favor, ¿qué haría?'
              }
              value={concern}
              options={INVESTMENT_CONCERNS_OPTIONS}
              onChange={(e) => setConcern(e.target.value as string)}
            />
            <Select
              className={classes.input}
              name={'level'}
              label={
                '¿Cuál es su nivel de renta líquida mensual considerando todas sus fuentes de ingreso?'
              }
              value={level}
              options={INVESTMENT_LEVEL_OPTIONS}
              onChange={(e) => setLevel(e.target.value as string)}
            />
            <Select
              className={classes.input}
              name={'savings'}
              label={'¿Cuánto de sus ahorros está dispuesto a invertir?'}
              value={savings}
              options={INVESTMENT_SAVINGS_OPTIONS}
              onChange={(e) => setSavings(e.target.value as string)}
            />
            <Select
              className={classes.input}
              name={'investmentConcern'}
              label={'Tu profesión, respecto a los temas financieros:'}
              value={profession}
              options={INVESTMENT_PROFESSION_OPTIONS}
              onChange={(e) => setProfession(e.target.value as string)}
            />
          </div>
        </div>
        <div className={classes.buttonSection}>
          <ButtonPrimary
            text="Continuar"
            onClick={handleDiscoverClick}
            disabled={
              !investmentObjective ||
              !investmentHorizon ||
              !product ||
              !affirmation ||
              !experience ||
              !concern ||
              !level ||
              !savings ||
              !profession
            }
            className={`${classes.buttonPrimary} ${
              isNotMission && classes.notMissionButton
            }`}
          />
        </div>
      </section>
    </>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  children: {
    marginTop: '3rem',
    display: 'flex',
    gap: '5rem',
    alignItems: 'start',
    [theme.breakpoints.down(1000)]: {
      flexDirection: 'column',
      gap: 0,
      marginTop: '2rem',
      padding: '1.5rem 1.5625rem 1.5rem 1.5625rem',
      borderRadius: '20px',
      margin: '0',
      background: '#FFFFFF',
      flexWrap: 'wrap',
    },
  },
  notMissionChildren: {
    flexWrap: 'wrap',
    gap: 0,
    justifyContent: 'space-around',
    alignItems: 'start',
    '& >div': {
      marginLeft: '0',
    },
  },
  selectors: {
    marginLeft: 30,
    marginTop: '3rem',
    display: 'grid',
    '& >div': {
      width: 470,
      margin: '1rem 0',
    },
    [theme.breakpoints.down(1000)]: {
      marginLeft: 0,
      marginTop: 0,
      '& >div': {
        width: '268px',
        maxWidth: '100%',
        margin: '1rem 0',
      },
    },
  },
  containerData: {
    display: 'block',
  },
  input: {
    width: 335,
    margin: '10px auto',
    '& >label': {
      fontSize: '0.8rem',
      maxWidth: '93%',
    },
    [theme.breakpoints.down(1000)]: {
      '& >label': {
        fontSize: '0.57rem',
        maxWidth: '90%',
        left: '-0.5rem',
      },
    },
  },
  specialInput: {
    '& >label': {
      top: '-0.7rem',
      [theme.breakpoints.down(1000)]: {
        top: '-0.3rem',
      },
    },
  },
  buttonPrimary: {
    width: 185,
    margin: '0 auto',
    marginTop: '3rem',
    [theme.breakpoints.down(1000)]: {
      width: '18rem',
      height: 52,
    },
  },
  buttonSection: {
    display: 'flex',
  },
}));
