import React, { Dispatch, SetStateAction } from "react";
import { useStyles } from "./M2ApvStep1.styles";
import { ApvIlustration, eye } from "@images";
import { ButtonPrimary } from "@components/Reusables";

type Props = {
  setPage: Dispatch<SetStateAction<number>>;
};

const M2ApvStep1: React.FC<Props> = ({ setPage }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.mainText}>
        <span className="text">
          El
          <strong> A</strong>horro
          <strong> P</strong>revisional
          <strong> V</strong>oluntario es una
          <strong> inversión adicional y complementaria </strong>a la cotización
          obligatoria en una AFP, y tiene por
          <strong>
            {" "}
            objetivo aumentar tu futura pensión o compensar lagunas
            previsionales{" "}
          </strong>
          . Lo pueden hacer trabajadores dependientes e independientes.
        </span>
      </div>
      <div className={classes.mainImage}>
        <img src={ApvIlustration} />
      </div>
      <div className={classes.whiteBox}>
        <img src={eye} />
        <span className="title">¿Comó funciona la APV?</span>
        <span className="text">
          Para incentivar a que las personas hagamos APV, el estado nos da
          beneficios. Así,
          <strong> existen dos regímenes distintos, APV A y APV B, </strong>
          cada uno con
          <strong> beneficios diferentes.</strong>
        </span>
      </div>
      <div className={classes.nextButton}>
        <ButtonPrimary text={"Siguiente"} onClick={() => setPage(2)} />
      </div>
    </div>
  );
};

export default M2ApvStep1;
