import { ButtonOutlined, ButtonPrimary } from "@components/Reusables";
import { DELETE_EVENT_ICON } from "@images";
import { Dialog } from "@material-ui/core";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { useStyles } from "./deleteModal.style";
import { deleteEventMutation } from "@apollo";

type Props = {
  openModal: boolean;
  setCurrentView: Dispatch<SetStateAction<string>>;
  setOpenModal: (value: boolean) => void;
  id: number;
  name: string;
  type: string;
};

export const EventsDeleteModal = ({
  openModal,
  id,
  name,
  setCurrentView,
  type,
  setOpenModal,
}: Props) => {
  const classes = useStyles();

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const client = useApolloClient();

  const setType = () => {
    switch (type) {
      case "income":
        return "ingreso";
      case "expense":
        return "gasto";
      case "reminder":
        return "recordatorio";
      default:
        return "evento";
    }
  };
  const deleteEvent = async () => {
    const res = await deleteEventMutation(client, id);
    return res;
  };

  return (
    <>
      <Dialog open={openModal} onClose={handleClose} className={classes.dialog}>
        <div className={classes.container}>
          <div className="pantalla">
            <div className={classes.topSection}>
              <div className="icon_container">
                <img src={DELETE_EVENT_ICON} alt="delete" />
              </div>
              <div className="title">
                <span>Eliminar</span>
              </div>
            </div>
            <div className={classes.separator}></div>
            <div className={classes.textSection}>
              <span className="name">
                {name} {" :"}
              </span>
              <span className="text">
                ¿Estás seguro que quieres eliminar este {setType()}? No volverá
                a aparecer en tu calendario.
              </span>
            </div>
            <div className={classes.buttonSection}>
              <ButtonPrimary
                text="Eliminar"
                onClick={async () => {
                  const eliminado = await deleteEventMutation(client, id);
                  if (eliminado === true) {
                    setCurrentView("calendar");
                  } else {
                    window.alert("No se pudo eliminar el evento");
                  }
                }}
              />
              <ButtonOutlined text="Cancelar" onClick={handleClose} />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default EventsDeleteModal;
