const PERSONAL_DATA_ICON =
  "https://rokinapp-images.s3.amazonaws.com/Profile/datos_icon.svg";

const NOTIFICATION_ICON =
  "https://rokinapp-images.s3.amazonaws.com/Profile/notification_icon.svg";

const SETTINGS_ICON =
  "https://rokinapp-images.s3.amazonaws.com/Profile/settings_icon.svg";

const HELP_ICON =
  "https://rokinapp-images.s3.amazonaws.com/Profile/help_icon.svg";

const DEFAULT_PROFILE_ICON =
  "https://rokinapp-images.s3.amazonaws.com/Profile/fotoPerfilDefault.svg";

const LOGOUT_ICON =
  "https://rokinapp-images.s3.amazonaws.com/Profile/logout_icon.svg";

const IMAGE_ICON =
  "https://rokinapp-images.s3.amazonaws.com/Profile/image_icon.svg";

const CHECK_ICON =
  "https://rokinapp-images.s3.amazonaws.com/Profile/check_icon.svg";

export {
  PERSONAL_DATA_ICON,
  NOTIFICATION_ICON,
  SETTINGS_ICON,
  HELP_ICON,
  DEFAULT_PROFILE_ICON,
  LOGOUT_ICON,
  IMAGE_ICON,
  CHECK_ICON,
};
