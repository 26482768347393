import React from "react";

import { useStyles } from "../calendar.styles";

const WeeklyCalendarDays = (props: {
  day: {
    getFullYear: () => number;
    getMonth: () => number;
    getDate: () => number;
    getDay: () => number;
    toDateString: () => string;
  };
  eventDays: string[] | null;
  changeCurrentDay: (arg0: {
    currentMonth: boolean;
    date: Date;
    month: number;
    number: number;
    selected: boolean;
    year: number;
  }) => void;
  togglePage: () => void;
}) => {
  const today = new Date();

  // get the first day of the week
  let firstDayOfWeek = new Date(
    props.day.getFullYear(),
    props.day.getMonth(),
    props.day.getDate()
  );

  let weekdayOfFirstDay = firstDayOfWeek.getDay();
  let currentDays = [];

  for (let day = 0; day < 7; day++) {
    if (day === 0 && weekdayOfFirstDay === 0) {
      firstDayOfWeek.setDate(firstDayOfWeek.getDate() - 7);
    } else if (day === 0) {
      firstDayOfWeek.setDate(
        firstDayOfWeek.getDate() + (day - weekdayOfFirstDay)
      );
    } else {
      firstDayOfWeek.setDate(firstDayOfWeek.getDate() + 1);
    }

    let calendarDay = {
      currentMonth: firstDayOfWeek.getMonth() === props.day.getMonth(),
      date: new Date(firstDayOfWeek),
      month: firstDayOfWeek.getMonth(),
      number: firstDayOfWeek.getDate(),
      selected: firstDayOfWeek.toDateString() === props.day.toDateString(),
      year: firstDayOfWeek.getFullYear(),
    };

    currentDays.push(calendarDay);
  }

  return (
    <div className="table-content">
      {currentDays.map((day) => {
        return (
          <div
            className={
              "calendar-day current" +
              (day.selected ? " selected" : "") +
              (day.date.toDateString() === today.toDateString()
                ? " today"
                : "") +
              (props.eventDays?.includes(day.date.toISOString().split("T")[0])
                ? " event-day"
                : "")
            }
            onClick={() => props.changeCurrentDay(day)}
          >
            <p>{day.number}</p>
            {props.eventDays?.includes(
              day.date.toISOString().split("T")[0]
            ) && <div className="event-dot"></div>}
          </div>
        );
      })}
    </div>
  );
};

export default WeeklyCalendarDays;
