import React, {
  useState,
  useCallback,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import { useStyles } from "./M5Step2.style";
import { IconButton, useMediaQuery } from "@material-ui/core";
import { ButtonPrimary, NumericInput } from "@components/Reusables";
import {
  barsImage,
  deliveryImage,
  foodImage,
  giftImage,
  otherImage,
  shoppingImage,
  transportImage,
  clickIcon,
} from "@images";
import { NameInput } from "@components/Inputs";
// import { CloseIcon } from "images/Investments/Goals";
import CloseIcon from "@material-ui/icons/Close";

type Props = {
  setPage: Dispatch<SetStateAction<number>>;
  antExpenses: { name: string; amount: number; category: string }[];
  setAntExpenses: Dispatch<
    SetStateAction<{ name: string; amount: number; category: string }[]>
  >;
};

const M5BudgetStep2: React.FC<Props> = ({
  setPage,
  antExpenses,
  setAntExpenses,
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 1239px)");
  const images = [
    {
      name: "Compras",
      icon: shoppingImage,
    },
    {
      name: "Delivery",
      icon: deliveryImage,
    },
    {
      name: "Comida",
      icon: foodImage,
    },
    {
      name: "Taxi",
      icon: transportImage,
    },
    {
      name: "Regalos",
      icon: giftImage,
    },
    {
      name: "Bares",
      icon: barsImage,
    },
    {
      name: "Otros",
      icon: otherImage,
    },
  ];

  const createExpense = useCallback(
    async (category) => {
      await setAntExpenses((prev) => [
        ...prev,
        { name: "", amount: 0, category: category },
      ]);
      console.log(antExpenses);
    },
    [setAntExpenses, antExpenses]
  );

  const removeExpense = useCallback(
    async (index) => {
      await setAntExpenses((prev) => {
        const newExpenses = [...prev];
        newExpenses.splice(index, 1);
        return newExpenses;
      });
    },
    [setAntExpenses]
  );

  const handleChange = useCallback(
    (type, value, index) => {
      // find the income with the index and then update the name or amount depending of the type
      const newExpense = antExpenses[index];
      if (type === "name") {
        newExpense.name = value;
      } else {
        newExpense.amount = value;
      }
      setAntExpenses((prev) => [
        ...prev.slice(0, index),
        newExpense,
        ...prev.slice(index + 1, antExpenses.length),
      ]);
    },
    [antExpenses, setAntExpenses]
  );
  return (
    <div className={classes.container}>
      {isMobile && <div className={classes.topText}>Simulador</div>}
      <div className={classes.helperText}>
        <img src={clickIcon} alt="click icon" />
        <span className="text">
          Haz click sobre cualquier símbolo para agregar un gasto hormiga al
          simulador
        </span>
      </div>
      {!isMobile && <div className={classes.topText}>Simula tus gastos</div>}
      {/* <div className={classes.srollable}> */}
      <div className={classes.imagesLayout}>
        {images.map((image, index) => (
          <div
            className="imageContainer"
            onClick={() => {
              createExpense(image.name);
            }}
          >
            <img src={image.icon} alt={image.name} />
            <div className="text">{image.name}</div>
          </div>
        ))}
      </div>
      {/* </div> */}

      <div className={classes.middleSection}>
        <div className={classes.expenseContainer}>
          <form id="form1" className={classes.formContainer}>
            {antExpenses.map((expense, index) => (
              <>
                {!isMobile ? (
                  <div className="expense">
                    <img
                      src={
                        images.find(
                          (image) => image.name === expense.category
                        ) &&
                        images.find((image) => image.name === expense.category)
                          ?.icon
                      }
                      alt={expense.category}
                    />
                    <div className="section first">
                      <span className="name">Nombre</span>

                      <NameInput
                        className="text"
                        required
                        value={expense.name}
                        onChange={({ target: { value } }) =>
                          handleChange("name", value as string, index as number)
                        }
                        label=""
                      />
                    </div>
                    <div className="amount section">
                      <span className="name">Monto</span>
                      <div className="input">
                        <NumericInput
                          className="text"
                          value={expense.amount}
                          onChange={({ target: { value } }) =>
                            handleChange(
                              "amount",
                              value as number,
                              index as number
                            )
                          }
                          name={""}
                        />
                        <IconButton
                          onClick={() => {
                            removeExpense(index);
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="expense">
                    <img
                      src={
                        images.find(
                          (image) => image.name === expense.category
                        ) &&
                        images.find((image) => image.name === expense.category)
                          ?.icon
                      }
                      alt={expense.category}
                    />
                    <div className="rightSide">
                      <div className="section first">
                        <span className="name">Nombre</span>

                        <NameInput
                          className="text nameInput"
                          required
                          value={expense.name}
                          onChange={({ target: { value } }) =>
                            handleChange(
                              "name",
                              value as string,
                              index as number
                            )
                          }
                          label=""
                        />
                      </div>
                      <div className="amount section">
                        <span className="name">Monto</span>
                        <div className="input">
                          <NumericInput
                            className="text"
                            value={expense.amount}
                            onChange={({ target: { value } }) =>
                              handleChange(
                                "amount",
                                value as number,
                                index as number
                              )
                            }
                            name={""}
                          />
                          <IconButton
                            onClick={() => {
                              removeExpense(index);
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ))}
          </form>
        </div>
        <div className="separator"></div>
        <div className="total">
          <div className="text">Total</div>
          <div className="amount">
            ${" "}
            {antExpenses
              .reduce((acc, curr) => acc + curr.amount, 0)
              .toLocaleString("es")}
          </div>
        </div>
        <div className="textContainer">
          <span className="text">
            Te recomendamos
            <strong> anotar </strong>
            esta semana tus gastos hormiga y volver a completar esta
            calculadora, para saber realmente
            <strong> cuánta plata estas gastando </strong>
            en esas compras pequeñas que uno no considera generalmente.
          </span>
        </div>
      </div>
      <div className={classes.bottomSection}>
        <button form="form1" onClick={() => setPage(3)}>
          <ButtonPrimary
            text={"Siguiente"}
            secondaryVersion
            onClick={() => {}}
          />
        </button>
      </div>
    </div>
  );
};

export default M5BudgetStep2;
