import { gql, useQuery } from '@apollo/client';
import { FinerioCategory } from '@interfaces';

export const GET_FINERIO_CATEGORIES = gql`
  query FinerioTransactions($name: String) {
    getFinerioCategories(name: $name) {
      id
      name
      color
      iconColor
      iconWhite
    }
  }
`;

interface QueryResult {
  getFinerioCategories: FinerioCategory[];
}

interface HookReturn {
  finerioCategories: FinerioCategory[];
  finerioCategoriesLoading: boolean;
  refetchFinerioCategopries: () => void;
}

export const getFinerioCategories = (name?: string): HookReturn => {
  const { data, loading, refetch } = useQuery<QueryResult>(
    GET_FINERIO_CATEGORIES,
    {
      variables: { name },
    }
  );
  return {
    finerioCategories: data?.getFinerioCategories ?? [],
    finerioCategoriesLoading: loading,
    refetchFinerioCategopries: refetch,
  };
};
