import React, { useState, useCallback, Dispatch, SetStateAction } from "react";
import { IconButton, useMediaQuery } from "@material-ui/core";
import { useStyles } from "./Simulator2_2.style";
import {
  FormControl,
  FormControlLabel,
  Button,
  Select,
  MenuItem,
  Checkbox,
} from "@material-ui/core";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import { comisiones } from "@images";
import { NumericInput, NameInput, ButtonPrimary } from "@components";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import CircleChecked from "@material-ui/icons/CheckCircleOutline";
import { TimeGapIncomes } from "@components/Inputs/SingleSelectors/TimeVariableIncomesSelector";
import { Route } from "@interfaces";
import { navigate } from "gatsby";
import { useForm } from "@hooks";

type Props = {
  setPage?: Dispatch<SetStateAction<number>>;
  // recibe el estado de variableIncomes de tipo array con diccionarios
  variableIncomes: {
    name: string;
    months: number;
    amounts: number[];
    average: number;
  }[];
  // recibe la función setVariableIncomes para actualizar el estado
  setVariableIncomes: Dispatch<
    SetStateAction<
      { name: string; months: number; amounts: number[]; average: number }[]
    >
  >;
};

const Simulator2_2: React.FC<Props> = ({
  setPage,
  variableIncomes,
  setVariableIncomes,
}) => {
  const classes = useStyles();

  const [variableIncome, setVariableIncome] = useState<boolean>(false);
  const [addingIncome, setAddingIncome] = useState<boolean>(false);
  // const [incomes, setIncomes] = useState([
  //   { name: "honorarios", months: 1, amounts: [0] },
  // ]);
  const [temp, setTemp] = useState({
    name: "",
    months: 1,
    amounts: [0],
    average: 0,
  });

  const isMobile = useMediaQuery("(max-width: 1300px)");

  const handleTimeChange = useCallback(
    async (index, amount) => {
      var newIncome = variableIncomes[index];
      newIncome.months = amount;
      newIncome.amounts = Array.from({ length: amount }, (_, i) => 0);
      setVariableIncomes((prev) => [
        ...prev.slice(0, index),
        newIncome,
        ...prev.slice(index + 1, variableIncomes.length),
      ]);
    },
    [setVariableIncomes, variableIncomes]
  );

  const updateIncome = useCallback(
    (i, index, amount) => {
      var newIncome = variableIncomes[index];
      newIncome.amounts[i] = amount;
      newIncome.average =
        newIncome.amounts.reduce((a, b) => a + b, 0) / newIncome.months;
      setVariableIncomes((prev) => [
        ...prev.slice(0, index),
        newIncome,
        ...prev.slice(index + 1, variableIncomes.length),
      ]);
    },
    [setVariableIncomes, variableIncomes]
  );
  const createNewIncome = async () => {
    // calculates the average amount of the temp variable income
    await setVariableIncomes((prev) => [...prev, temp]);
    await setTemp({ name: "", months: 1, amounts: [0], average: 0 });
    setAddingIncome(false);
  };

  const addIncome = useCallback(
    (type, value) => {
      if (type === "name") {
        setTemp({
          name: value,
          months: temp.months,
          amounts: temp.amounts,
          average: temp.average,
        });
      }
    },
    [setTemp, temp]
  );

  const handeClick = () => {
    if (variableIncome) {
      // set all the variables incomes values to 0
      setVariableIncomes(
        variableIncomes.map((income) => ({
          name: income.name,
          months: 1,
          amounts: [0],
          average: 0,
        }))
      );
    }

    if (setPage) {
      setPage(3);
    } else navigate(Route.budget2_3);
  };

  return (
    <>
      <div className={classes.container}>
        <span className="simulatorTitle">Simulador</span>
        <br />
        <span className="helpText">
          Te ayudaremos a armar tu presupuesto. Identifica tu(s) ingresos(s)
          variables(s):
        </span>
        <div className="formContiner">
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleChecked />}
                  onChange={() => setVariableIncome(!variableIncome)}
                />
              }
              className={classes.greyText}
              label="No tengo ingreso variable"
            />
          </FormControl>
        </div>
        <div className={classes.userIncomes}>
          {variableIncomes.map((income, index) => (
            <div className={classes.incomeDetail}>
              <img src={comisiones} className={classes.incomeImage} />
              <div className="incomeValues">
                <div className={classes.incomeName}>{income.name}</div>
                {/* <TimeGapIncomes onChange={() => {}}/> */}
                <div className="selectorContainer">
                  <Select
                    value={income.months}
                    label="Intervalo de tiempo"
                    onChange={({ target: { value } }) =>
                      handleTimeChange(index, value as number)
                    }
                  >
                    <MenuItem value={1}>Último mes</MenuItem>
                    <MenuItem value={3}>Últimos 3 meses</MenuItem>
                    <MenuItem value={6}>Últimos 6 meses</MenuItem>
                  </Select>
                </div>

                {income.amounts.map((amount, i) => (
                  <div className={classes.incomeInput}>
                    <NumericInput
                      name="incomes"
                      disabled={variableIncome}
                      value={amount ?? 0}
                      onChange={({ target: { value } }) =>
                        updateIncome(i, index, value as number)
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
          {addingIncome && (
            <>
              <NameInput
                className={classes.formControlSimulator}
                value={temp.name}
                disabled={variableIncome}
                onChange={({ target: { value } }) =>
                  addIncome("name", value as string)
                }
              />
              <IconButton onClick={createNewIncome}>
                <AddRoundedIcon />
              </IconButton>
            </>
          )}
          <Button
            startIcon={<AddRoundedIcon />}
            variant="outlined"
            onClick={() => setAddingIncome(!addingIncome)}
            className={classes.addButton}
            disabled={variableIncome}
          >
            {"Agregar otro"}
          </Button>
        </div>
        {!isMobile && (
          <div className={classes.SimulatorBottomSection}>
            <ButtonPrimary
              // className={classes.buttonPrimary}
              onClick={handeClick}
              text="Siguiente"
              secondaryVersion
            />
          </div>
        )}
      </div>
      {isMobile && (
        <div className={classes.SimulatorBottomSection}>
          <ButtonPrimary
            // className={classes.buttonPrimary}
            onClick={handeClick}
            text="Siguiente"
            secondaryVersion
          />
        </div>
      )}
    </>
  );
};

export default Simulator2_2;
