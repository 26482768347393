import { FinerioAccount } from '@interfaces';
import { useMemo } from 'react';
import { FinerioGlobals } from '@interfaces';
import { cloneDeep, lowerCase, toLower } from 'lodash';

interface HookParams {
  finerioAccounts: FinerioAccount[];
  bankNotSelected?: number[];
}

type HookResults = FinerioGlobals;

export const useFinerioAccountsTotals = ({
  finerioAccounts,
  bankNotSelected,
}: HookParams): HookResults => {
  const globals = useMemo(() => {
    if (finerioAccounts.length !== 0) {
      const finerioAccountsClone = cloneDeep(finerioAccounts); //Cloning accounts array to be able to modify it
      finerioAccountsClone.sort((a, b) => b.accountBalance - a.accountBalance); //Sorting from highest to lowest

      let total = 0;
      let totalUsed = 0;
      let totalCreditLimit = 0;
      let totalCreditUsed = 0;
      let totalCreditBalance = 0;

      //Deleting account with enabled = false
      const filteredAccounts = finerioAccountsClone.filter(
        (account) => account.enabled === true
      );

      //Deleting aditional account
      const uniqueAccounts = filteredAccounts.reduce((acc, current) => {
        const x = acc.find((item) => {
          if (
            item.accountType == 'Crédito' &&
            current.accountType == 'Crédito'
          ) {
            return item.accountName === current.accountName;
          } else {
            return;
          }
        });
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      uniqueAccounts.forEach((account) => {
        if (!bankNotSelected?.includes(Number(account.bankId))) {
          if (account.accountType !== '') {
            if (
              account.accountType === 'Crédito' ||
              account.accountType === 'Tarjeta de crédito'
            ) {
              if (toLower(account.accountName).includes('adicional')) return;
              totalUsed += account.accountBalance;
              totalCreditUsed += account.credit
                ? JSON.parse(account.credit).usedAmount
                : 0;
              totalCreditLimit += account.credit
                ? JSON.parse(account.credit).totalAmount
                : 0;
              totalCreditBalance += account.credit
                ? JSON.parse(account.credit).availableAmount
                : 0;
            } else {
              if (!toLower(account.accountType).includes('línea de crédito')) {
                total += account.accountBalance;
              }
            }
            const CreditJson = { creditLimit: 0 };
            totalCreditLimit += CreditJson.creditLimit;
          }
        }
      });

      const totalBalance = total;
      const totalUsedBalance = totalUsed;
      const amountAfterPayingCards = totalBalance - totalCreditUsed;

      return {
        totalBalance,
        totalUsedBalance,
        totalCreditBalance,
        amountAfterPayingCards,
      };
    } else {
      const totalBalance = 0;
      const totalUsedBalance = 0;
      const totalCreditBalance = 0;
      const amountAfterPayingCards = totalBalance - totalUsedBalance;

      return {
        totalBalance,
        totalUsedBalance,
        totalCreditBalance,
        amountAfterPayingCards,
      };
    }
  }, [finerioAccounts, bankNotSelected]);
  return globals;
};
