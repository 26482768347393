import { ButtonPrimary, TexasEvent } from "@components";
import { COOLEBRA_ICON_PURPLE } from "@images";
import { Button, Divider, Menu, MenuItem } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { Dispatch, SetStateAction, useState } from "react";
import {
  AddOutlined,
  KeyboardArrowDownRounded,
  KeyboardArrowRightRounded,
} from "@material-ui/icons";

import { useStyles } from "./info.style";
import { EventRecurrencesConstantsEnum } from "@constants";

type Props = {
  setCurrentView: Dispatch<SetStateAction<string>>;

  currentDay: {
    getFullYear: () => number;
    getMonth: () => number;
    toDateString: () => string;
    getDate: () => number;
    getDay: () => number;
  };
  loading: boolean;
  recurrentPayments?: {
    name: string;
    description: string;
    image: string;
    amount: number;
    recurrence: number;
  }[];
  recurrentIncomes?: {
    name: string;
    description: string;
    image: string;
    amount: number;
    recurrence: number;
  }[];
  reminders?: {
    image: string;
    name: string;
    description: string;
    recurrence: number;
  }[];
};

const CalendarInfoContainer: React.FC<Props> = ({
  currentDay,
  loading,
  recurrentPayments,
  recurrentIncomes,
  reminders,
  setCurrentView,
}) => {
  const months = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];
  const days = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];
  const classes = useStyles();

  // add all the amounts of the recurrentPayments and recurrentIncomes
  const recurrentAmount = () => {
    let amount = 0;
    if (recurrentPayments) {
      recurrentPayments.forEach((payment) => {
        amount -= payment.amount;
      });
    }
    if (recurrentIncomes) {
      recurrentIncomes.forEach((income) => {
        amount += income.amount;
      });
    }
    return amount;
  };

  let dayDisplay;
  if (currentDay) {
    dayDisplay = `${days[currentDay.getDay()]} ${currentDay.getDate()} de ${
      months[currentDay.getMonth()]
    }`;
  }

  const getRecurrence = (recurrence: number): string => {
    return EventRecurrencesConstantsEnum[recurrence];
  };

  const [openEventPayments, setOpenEventPayments] = useState(false);
  const [openEventEarnings, setOpenEventEarnings] = useState(false);
  const [openEventReminders, setOpenEventReminders] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.EventsContainer}>
      {loading ? (
        <>
          <Skeleton variant="circle" width={40} height={40} animation="wave" />
          <Skeleton variant="rect" width={40} height={40} animation="wave" />
        </>
      ) : (
        <>
          <div className="lefter">
            <h1 className={classes.dayDisplayValue}>{dayDisplay}</h1>
          </div>
          <div className={classes.buttonMainContainer}>
            <button
              className={classes.addButton}
              onClick={handleClick}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <div className={classes.addButtonContainer}>
                <AddOutlined htmlColor="#008296" />
                <h4 className={classes.addButtonContainerTitle}>Agregar</h4>
                <KeyboardArrowDownRounded htmlColor="#008296" />
              </div>
            </button>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 1,
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {/* <MenuItem>Filtar calendario</MenuItem> */}
              <MenuItem onClick={() => setCurrentView("createPayment")}>
                Agregar gasto o pago recurrente
              </MenuItem>
              <MenuItem onClick={() => setCurrentView("createIncome")}>
                Agregar ingreso recurrente
              </MenuItem>
              <MenuItem onClick={() => setCurrentView("createReminder")}>
                Agregar recordatorio
              </MenuItem>
              {/* <MenuItem>Sincronizar con calendario</MenuItem> */}
            </Menu>
          </div>

          <div className="lefter">
            <span className={classes.eventsTitle}>pagos recurrentes</span>
            <button
              className={
                openEventPayments
                  ? classes.eventsTitleButtonOpen
                  : classes.eventsTitleButton
              }
              onClick={() => setOpenEventPayments(!openEventPayments)}
            >
              <KeyboardArrowDownRounded htmlColor="#6b6b6b" />
            </button>
          </div>
          <div
            className={
              openEventPayments ? classes.hiddenEvent : classes.regularEvent
            }
          >
            {recurrentPayments?.map((payment) => (
              <div
                key={payment.name}
                onClick={() => setCurrentView("recurrentPayments")}
              >
                <TexasEvent
                  key={payment.name}
                  type="payment"
                  link={payment.image}
                  name={payment.name}
                  description={getRecurrence(payment.recurrence)}
                  hasAmount={true}
                  amount={payment.amount}
                />
              </div>
            ))}
            <Divider variant="middle" className={classes.dividerColor} />
          </div>

          <div className="lefter">
            <span className={classes.eventsTitle}>ingresos recurrentes</span>
            <button
              className={
                openEventEarnings
                  ? classes.eventsTitleButtonOpen
                  : classes.eventsTitleButton
              }
              onClick={() => setOpenEventEarnings(!openEventEarnings)}
            >
              <KeyboardArrowDownRounded htmlColor="#6b6b6b" />
            </button>
          </div>
          <div
            className={
              openEventEarnings ? classes.hiddenEvent : classes.regularEvent
            }
          >
            {recurrentIncomes?.map((income) => (
              <div
                key={income.name}
                onClick={() => setCurrentView("recurrentIncomes")}
              >
                <TexasEvent
                  key={income.name}
                  type="income"
                  link={income.image}
                  name={income.name}
                  description={getRecurrence(income.recurrence)}
                  hasAmount={true}
                  amount={income.amount}
                />
              </div>
            ))}
            <Divider variant="middle" className={classes.dividerColor} />
          </div>

          <div className="lefter">
            <span className={classes.eventsTitle}>recordatorios</span>
            <button
              className={
                openEventReminders
                  ? classes.eventsTitleButtonOpen
                  : classes.eventsTitleButton
              }
              onClick={() => setOpenEventReminders(!openEventReminders)}
            >
              <KeyboardArrowDownRounded htmlColor="#6b6b6b" />
            </button>
          </div>
          <div
            className={
              openEventReminders ? classes.hiddenEvent : classes.regularEvent
            }
          >
            {reminders?.map((reminder) => (
              <div
                key={reminder.name}
                onClick={() => setCurrentView("reminders")}
              >
                <TexasEvent
                  key={reminder.name}
                  type="reminder"
                  link={reminder.image}
                  name={reminder.name}
                  description={reminder.description}
                  hasAmount={false}
                />
              </div>
            ))}
            <Divider variant="middle" className={classes.dividerColor} />
          </div>

          {/* No Eliminar este boton comentado, es el de CULEBRA */}
          {/* <Button className={classes.button}>
            <div className="imageContainer">
              <img src={COOLEBRA_ICON_PURPLE} alt="apv" />
            </div>
            <span>Revisa los descuentos de este día</span>
          </Button> */}
          <div className="righter">
            <span className="totalDay">Total del día</span>
            <span className="totalDayAmount">
              $ {recurrentAmount().toLocaleString("es")}
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default CalendarInfoContainer;
