import React from "react";
import { makeStyles } from "@material-ui/core";
import Card from "./Card";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { capsule, capsule2, capsule3, capsule4 } from "images/onBoarding";
const Capsule: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <section className={classes.container}>
        {" "}
        <div className={classes.cardContainer}>
          <div className={classes.titleCardContainer}>
            <span className={classes.txtPrincipal}>Cápsulas</span>
            <span className={classes.txtSecondary}>
              Completa las cápsulas disponibles y gana nuevos beneficios
            </span>
          </div>
          <div className={classes.valuesContainer}>
            {description.map((d) => (
              <Card
                key={d.title}
                title={d.title}
                subtitle={d.subtitle}
                img={d.img}
              />
            ))}
            <ArrowForwardIosIcon className={classes.arrow} />
          </div>
        </div>
      </section>{" "}
    </>
  );
};

export default Capsule;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    width: "90%",
    margin: "0 auto",
    height: "34rem",
    paddingTop: "15rem",
    marginBottom: "8rem",
  },
  cardContainer: {
    background: "rgba(255,255,255,0.2)",
    width: "100%",
    height: 345,
    boxShadow: "20px 12px 50px 0 rgba(0, 0, 0, 0.2)",
    borderRadius: 30,
    marginBottom: 100,
  },
  titleCardContainer: {
    display: "flex",
    alignItems: "center",
    padding: "50px 0 20px 7rem",
  },
  txtPrincipal: {
    fontFamily: theme.typography.fontFamily,
    fontSize: "2rem",
    fontWeight: "bold",
    borderRight: "1px solid #6b6b6b",
    padding: 10,
  },
  txtSecondary: {
    color: theme.palette.text.secondary,
    fontSize: "1.25rem",
    fontFamily: "Montserrat",
    fontWeight: "normal",
    padding: 10,
    display: "block",
  },
  valuesContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    gap: "rem",
    margin: "2rem 0 5rem",
    "& section": {
      marginLeft: "2rem",
      marginRight: "2rem",
    },
  },
  imageCard: {
    position: "relative",
    paddingRight: "2rem",
    "& span": {
      color: "white",
    },
  },
  img: {
    width: 268,
    height: 400,
    background: "red",
  },
  card: {
    width: 470,
    height: 385,
    background: "red",
  },
  arrow: {
    width: "3rem",
    height: "3rem",
    marginTop: "3rem",
    color: theme.palette.text.secondary,
  },
}));

const description = [
  {
    title: "Finanzas 101",
    subtitle: "Qué es una inversión",
    img: capsule,
  },
  {
    title: "Finanzas 201",
    subtitle: "Qué es un perfil de inversión",
    img: capsule2,
  },
  {
    title: "Gestión de gastos",
    subtitle: "Cómo saber cuánto estoy gastando",
    img: capsule3,
  },
  {
    title: "Planillas",
    subtitle: "Cómo invierto una parte de mi sueldo",
    img: capsule4,
  },
];
