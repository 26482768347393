import React from "react";
import { Switch, makeStyles } from "@material-ui/core";
import { Filter, FilterProps } from "./Filter";
import { ActiveFilters, SingleFilterOptions } from "@interfaces";
import { MiniCardText } from "@components";

export interface CardOptionsProps extends FilterProps {
  className?: string;
  selectAllText: string;
  filters: ActiveFilters;
  property: "currency" | "term" | "manager" | "fundType" | "category";
  options: SingleFilterOptions[];
  handleChange: (event: any) => void;
}
const CardOptionsFilter: React.FC<CardOptionsProps> = ({
  className,
  selectAllText,
  filters,
  property,
  options,
  handleChange,
  ...props
}) => {
  const classes = useStyles();
  const allChecked = options.every(opt =>
    filters[property]?.includes(opt.value),
  );

  return (
    <Filter className={`${className || ""} ${classes.cardsFilter}`} {...props}>
      <div className={`${classes.switch}`}>
        <span className={classes.optionText}>{selectAllText}</span>
        <Switch
          name={`switch-${property}`}
          checked={allChecked}
          onChange={handleChange}
          color="primary"
          inputProps={{
            "aria-label": `seleccionar ${selectAllText}`,
          }}
        />
      </div>
      <div className={classes.container}>
        {options.map((opt, i) => (
          <MiniCardText
            key={i}
            className={`${classes.options} ${
              filters[property]?.includes(opt.value) ? "selected" : ""
            }`}
            image={opt.image as string}
            text={opt.value}
            onClick={() =>
              handleChange({
                target: { name: `card-${property}-${opt.value}` },
              })
            }
          />
        ))}
      </div>
    </Filter>
  );
};

const useStyles = makeStyles(() => ({
  cardsFilter: {
    width: "100%",
  },
  container: {
    margin: "15px 20px",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(107px, 1fr))",
    gap: 10,
  },
  options: {
    cursor: "pointer",
    padding: "10px 4px",
    margin: "0 auto",
    width: 107,
    height: 100,
    border: "1px solid gray",
    "&.selected": {
      outline: "none",
      border: "3px solid #0c5769",
      padding: "8px 2px",
    },
    "& span": {
      fontSize: 12,
    },
  },
  genericFilter: {
    "& .MuiFormControlLabel-root": {
      flex: 1,
      margin: 0,
      "& .MuiCheckbox-root": {
        width: 42,
        height: 42,
        margin: 16,
      },
      "& .MuiFormControlLabel-label": {
        fontSize: 14,
        letterSpacing: 0.15,
        color: "#414046",
      },
    },
  },
  optionText: {
    fontSize: 14,
    letterSpacing: 0.15,
    color: "#414046",
  },
  switch: {
    padding: "0 18px 0 28px",
    justifyContent: "space-between",
    display: "flex",
    width: "100%",
    height: 42,
    alignItems: "center",
  },
}));

export { CardOptionsFilter };
