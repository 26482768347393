import React, { useContext, useState, useEffect } from 'react';
import { Link, navigate, PageProps } from 'gatsby';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import {
  SEO,
  NameInput,
  RutInput,
  GenderSelector,
  NationalitySelector,
  ButtonPrimary,
} from '@components';
import { LayoutBildRegistration } from '@components/Structural/RegistrationStep1';
import { AppContext, MissionsContext } from '@context';
import { Route } from '@interfaces';

interface UserInformationProps {
  form: any;
  buttonText: string;
  handleClick: () => void;
}

export const UserInformationComponent: React.FC<UserInformationProps> = ({
  form,
  buttonText,
  handleClick,
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 1000px)');
  const [name, setName] = useState<string>(form.name ? form.name : '');
  const [paternalSurname, setPaternalSurname] = useState<string>(
    form.paternalSurname ? form.paternalSurname : ''
  );
  const [maternalSurname, setMaternalSurname] = useState<string>(
    form.maternalSurname ? form.maternalSurname : ''
  );
  const [rut, setRut] = useState<string>(form.rut ? form.rut : '');
  const [nationality, setNationality] = useState<string>(
    form.nationality ? form.nationality : ''
  );
  const [gender, setGender] = useState<string>(form.gender ? form.gender : '');
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

  useEffect(() => {
    form.name = name;
    form.paternalSurname = paternalSurname;
    form.maternalSurname = maternalSurname;
    form.rut = rut;
    form.nationality = nationality;
    form.gender = gender;

    if (
      !name ||
      !paternalSurname ||
      !maternalSurname ||
      !rut ||
      !nationality ||
      !gender
    ) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [name, paternalSurname, maternalSurname, rut, nationality, gender]);

  return (
    <>
      <section className={classes.container}>
        <div className={classes.form}>
          <div className={classes.inputs}>
            <div className={classes.inputs1}>
              <NameInput
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <NameInput
                value={paternalSurname}
                onChange={(e) => setPaternalSurname(e.target.value)}
                label='Apellido Paterno'
              />
              <NameInput
                value={maternalSurname}
                onChange={(e) => setMaternalSurname(e.target.value)}
                label='Apellido Materno'
              />
            </div>
            <div className={classes.inputs2}>
              <RutInput
                value={rut}
                label='Rut'
                onChange={(e) => setRut(e.target.value)}
              />
              <NationalitySelector
                value={nationality}
                onChange={(e) => setNationality(e.target.value)}
                label='Nacionalidad'
              />
              <GenderSelector
                value={gender}
                label='Género'
                onChange={(e) => setGender(e.target.value)}
              />
            </div>
          </div>
          {isMobile ? (
            <div className={classes.down}>
              <ButtonPrimary
                onClick={handleClick}
                className={classes.boton}
                text={buttonText}
                disabled={buttonDisabled}
              />
              <div
                className={classes.cancel}
                onClick={() => navigate(Route.investments)}
              >
                Cancelar
              </div>
            </div>
          ) : (
            <ButtonPrimary
              onClick={handleClick}
              className={classes.boton}
              text={buttonText}
              disabled={buttonDisabled}
              secondaryVersion
            />
          )}
        </div>
      </section>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '100%',
    position: 'relative',
    height: 529,
  },
  arrow: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  arrowIcon: {
    width: '4.2rem',
    height: '4.2rem',
    color: '#008296',
    marginRight: 90,
  },
  form: {},
  inputs: {
    padding: '1.5rem 1.5625rem 1.5rem 1.5625rem',
    borderRadius: '20px',
    margin: '0 1rem 0 1rem',
    background: '#FFFFFF',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 50,
    [theme.breakpoints.down(1000)]: {
      marginTop: '2rem',
      gap: 23.9,
    },
  },
  inputs1: {
    display: 'grid',
    width: 401.5,
    gap: 23.9,
    [theme.breakpoints.down(1000)]: {
      width: '100%',
    },
  },
  inputs2: {
    display: 'grid',
    width: 401.5,
    gap: 23.9,
    marginLeft: 81.5,
    [theme.breakpoints.down(1000)]: {
      width: '100%',
      marginLeft: 0,
    },
  },
  down: {
    display: 'grid',
    justifyContent: 'center',
    gap: '1rem',
  },
  boton: {
    width: 184,
    marginLeft: 693,
    marginTop: 100,
    [theme.breakpoints.down(1000)]: {
      width: '18rem',
      height: 52,
      marginTop: 29,
      marginLeft: 0,
    },
  },
  cancel: {
    display: 'grid',
    justifyContent: 'center',
    fontSize: '0.875rem',
  },
}));
