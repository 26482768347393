import { makeStyles, Theme, Typography, IconButton } from "@material-ui/core";
import React, { useCallback } from "react";
import { FileCopy } from "@material-ui/icons";

type TransferDataProps = {
  className?: string;
  data: {
    name: string;
    rut: string;
    bank: string;
    accountType: string;
    accountNumber: string;
    email: string;
  };
};

export const BankTransferData: React.FC<TransferDataProps> = ({
  className,
  data: { name, rut, bank, accountType, accountNumber, email },
}) => {
  const classes = useStyles();
  const copyCallback = useCallback((value: string) => {
    navigator.clipboard.writeText(value);
  }, []);

  return (
    <div className={`${classes.root} ${className}`}>
      <Typography variant="subtitle1" className="data-line">
        {name}
      </Typography>
      <IconButton
        className={classes.copyButton}
        onClick={() => copyCallback(name)}
      >
        <FileCopy color="primary" />
      </IconButton>
      <Typography variant="subtitle1" className="data-line">
        {rut}
      </Typography>
      <IconButton
        className={classes.copyButton}
        onClick={() => copyCallback(rut)}
      >
        <FileCopy color="primary" />
      </IconButton>
      <Typography variant="subtitle1" className="data-line">
        {accountType}
      </Typography>
      <div className="copy"></div>
      <Typography variant="subtitle1" className="data-line">
        {bank}
      </Typography>
      <div className="copy"></div>
      <Typography variant="subtitle1" className="data-line">
        {accountNumber}
      </Typography>
      <div className="copy">
        <IconButton
          className={classes.copyButton}
          onClick={() => copyCallback(accountNumber)}
        >
          <FileCopy color="primary" />
        </IconButton>
      </div>
      <Typography variant="subtitle1" className="data-line">
        {email}
      </Typography>
      <IconButton
        className={classes.copyButton}
        onClick={() => copyCallback(email)}
      >
        <FileCopy color="primary" />
      </IconButton>
    </div>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    display: "grid",
    gridTemplateColumns: "max-content max-content",
    gap: "12px 14px",
    color: theme.palette.text.primary,
    justifyContent: "center",
    "& > h6": {
      fontSize: 18,
      fontWeight: 500,
    },
    [theme.breakpoints.down(500)]: {
      "& > h6": {
        fontSize: 15,
      },
      gridTemplateColumns: "1fr 30px",
    },
    [theme.breakpoints.down(420)]: {
      "& > h6": {
        fontSize: 13,
      },
    },
  },
  copyButton: {
    padding: 0,
  },
}));
