import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down(1240)]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  topSection: {
    display: "flex",
    flexDirection: "row",
    padding: "4rem 20rem 5rem 7rem",
    borderRadius: "0rem 0rem 1.25rem 1.25rem ",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down(1240)]: {
      padding: "2rem 2rem 5rem",
      width: "100%",
    },
    "& .mainIcon": {
      marginLeft: "4rem",
      [theme.breakpoints.down(1240)]: {
        marginLeft: "0rem",
      },
      "& img": {
        height: "7rem",
        [theme.breakpoints.down(1240)]: {
          height: "5rem",
        },
      },
    },
    "& .backArrow": {
      "& img": {
        width: "3.25rem",
        cursor: "pointer",
        [theme.breakpoints.down(1240)]: {
          width: "2.5rem",
        },
      },
    },
    "& .title": {
      fontFamily: "Montserrat",
      fontSize: "2.25rem",
      fontWeight: "bold",
      lineHeight: 1.22,
      color: "#ffffff",
      [theme.breakpoints.down(1240)]: {
        fontSize: "1rem",
        lineHeight: 1.5,
        fontWeight: "normal",
        textAlign: "center",
      },
    },
  },
  expense: {
    backgroundColor: "#4619b6",
  },
  income: {
    backgroundColor: "#0a5669",
  },
  reminder: {
    backgroundColor: "#8352fd",
  },
  formSection: {
    width: "77.4375rem",
    margin: "-3rem auto 0",
    backgroundColor: "#F4F3F3",
    boxShadow: "0.25rem 0.25rem 0.9375rem 0.3125rem rgba(0, 0, 0, 0.2)",
    borderRadius: "1.875rem",
    padding: "3rem 13rem 3rem 3rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    [theme.breakpoints.down(1240)]: {
      width: "100%",
      padding: "3rem",
      flexDirection: "column",
      alignContent: "center",
      alignItems: "center",
      minWidth: "23.4375rem",
    },
    "& .element": {
      minWidth: "20rem",
      display: "flex",
      flexDirection: "column",
      fontSize: "0.75rem",
      marginBottom: "1rem",
      "& .label": {
        fontFamily: "Montserrat",
        fontWeight: "bold",
        lineHeight: 3.25,
        color: theme.palette.text.secondary,
        textTransform: "uppercase",
      },
      "& input, select, textarea": {
        width: "17.813rem",
        height: "2.5rem",
        fontSize: "0.85rem",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        margin: "0.313rem 0 0",
        borderRadius: "8px",
        border: "solid 1px #9d9d9d",
        backgroundColor: "#ffffff",
        resize: "none",
      },
      "& textarea": {
        height: "5.625rem",
        padding: "1rem",
      },
    },
  },
  displayer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    [theme.breakpoints.down(1240)]: {
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
  },
  categoriesSection: {
    [theme.breakpoints.down(1240)]: {
      width: "90vw",
      overflowX: "scroll",
      height: "20rem",
    },
    "& .title": {
      fontFamily: "Montserrat",
      fontWeight: "bold",
      lineHeight: 3.25,
      color: theme.palette.text.secondary,
      textTransform: "uppercase",
      [theme.breakpoints.down(1240)]: {
        display: "flex",
        justifyContent: "center",
        "& span": {
          marginRight: "15rem",
          fontSize: "0.75rem",
        },
      },
    },
    "& .categories": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      width: "54.4375rem",
      cursor: "pointer",
      [theme.breakpoints.down(1240)]: {
        width: "48rem",
        margin: "0rem auto",
      },

      "& .category": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& img": {
          width: "5.2rem",
          margin: "1.25rem",
          [theme.breakpoints.down(1240)]: {
            width: "4.38rem",
            margin: "1rem",
          },
        },

        "& .imgDescription": {
          fontWeight: "normal",
          fontSize: "0.731rem",
          lineHeight: 1.6,
          color: "#7d7c7c",
          textAlign: "center",
        },
      },
      "& .unselected": {
        opacity: "0.4",
      },
      "& .category:hover": {
        [theme.breakpoints.up(1240)]: {
          boxShadow: "1px 1px 1px 1px rgb(0 0 0 / 26%)",
          borderRadius: "0.6rem",
        },
      },
    },
  },
  submitButton: {
    width: "19.813rem !important",
    height: "3.125rem !important",
    borderRadius: "0.625rem !important",
    color: "#ffffff !important",
  },
  error: {
    color: "red",
    fontSize: "0.75rem",
    paddingTop: "0.5rem",
  },
}));
