import {
  ApolloClient,
  gql,
  LazyQueryExecFunction,
  OperationVariables,
  useLazyQuery,
  useQuery,
} from "@apollo/client";
import { Event } from "@interfaces";

export const GET_DAY_EVENTS = gql`
  query DayEvents($date: String!) {
    dayEvents(date: $date) {
      type
      name
      description
      amount
      recurrence
    }
  }
`;
type QueryResult = {
  dayEvents: Event[];
};

interface Events {
  dayEventsData: Event[] | null;
  loading: boolean;
  refetch: (date: String) => void;
  dayEvents: any;
}

export const getDayEvents = (date: String): Events => {
  const [dayEvents, { data, loading, refetch }] = useLazyQuery<QueryResult>(
    GET_DAY_EVENTS,
    {
      variables: { date },
    }
  );
  return {
    dayEventsData: data?.dayEvents ?? null,
    loading,
    refetch,
    dayEvents,
  };
};
