import { ApolloClient, gql } from "@apollo/client";
import { FormInvestment } from "@interfaces";
import moment from "moment";

const MUTATION = gql`
  mutation UpdateInvestment($id: ID!, $data: UpdateInvestmentParams) {
    updateInvestment(id: $id, data: $data) {
      type
      period
      investmentFrequency
    }
  }
`;

interface userInvestment {
  id: number;
  type: string;
  period: number;
  investmentFrequency: string;
  createdAt: Date;
  updatedAt: Date;
}

type MutationParams = {
  id: number;
  data: {
    serieId?: FormInvestment["serieId"];
    invested?: FormInvestment["invested"];
    date?: FormInvestment["date"];
    goalId?: FormInvestment["goalId"];
    investFrequency?: FormInvestment["investFrequency"];
    period?: FormInvestment["period"];
  };
};

type MutationResult = {
  updateInvestment: userInvestment | null;
};

export async function updateUserInvestment(
  id: number,
  payload: Partial<FormInvestment>,
  client: ApolloClient<object>,
): Promise<userInvestment | null> {
  const result = await client.mutate<MutationResult, MutationParams>({
    mutation: MUTATION,
    variables: {
      id,
      data: {
        serieId: payload.serieId,
        invested: payload.invested,
        date: payload.dontRememberDate
          ? moment().tz("America/Santiago").format("DD/MM/YYYY")
          : payload.date,
        goalId: payload.goalId,
        investFrequency: payload.investFrequency,
        period: payload.period,
      },
    },
  });
  return result.data?.updateInvestment ?? null;
}
