import React, { useState } from "react";
import { makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import {
  ButtonOutlined,
  ButtonPrimary,
  CONCERNS_OPTIONS,
  CURRENT_INVESTMENTS_OPTIONS,
  DROP_RESPONSE_OPTIONS,
  InvestmentExperienceSelector,
  InvestmentRiskSelector,
  InvestTermSelector,
  Select,
} from "@components";
import { navigate } from "gatsby";
import { Route } from "@interfaces";
import { IlustrationDiscover } from "@images";

interface InvestmentTestProps {
  isStep: number;
  setStep: (st: number) => void;
}
const InvestmentTest = ({ isStep, setStep }: InvestmentTestProps) => {
  const classes = useStyles();
  const [isTerm, setTerm] = useState("");
  const [isRisk, setRisk] = useState("");
  const [isExperience, setExperience] = useState("");
  const isMobile = useMediaQuery("(max-width: 1280px)");
  return (
    <>
      {isStep !== 1 && (
        <div className={classes.title}>
          Así podrás descubrir inversiones que se ajusten a tus objetivos.
        </div>
      )}
      {isStep === -1 && (
        <section className={classes.containerData}>
          <InvestTermSelector
            name="investmentTerm"
            value={isTerm}
            onChange={(e) => setTerm(e.target.value as string)}
            label="Retiraré dinero en un plazo de"
          />
          <InvestmentRiskSelector
            name="investmentRisk"
            value={isRisk}
            onChange={(e) => setRisk(e.target.value as string)}
            label="Si invierto 1 millón"
          />
          <InvestmentExperienceSelector
            className={classes.input}
            name="investmentExperience"
            value={isExperience}
            onChange={(e) => setExperience(e.target.value as string)}
            label="Mi experiencia invirtiendo es:"
          />
          <div className={classes.buttonContinue}>
            <ButtonPrimary
              text="Continuar"
              onClick={() => {
                setStep(0);
              }}
            />
          </div>
        </section>
      )}
      {isStep === 0 && (
        <section className={classes.containerData}>
          <Select
            label="Cuando invierto"
            name="investmentConcern"
            onChange={() => {}}
            options={CONCERNS_OPTIONS}
          />
          <Select
            label="Actualmente invierto en"
            onChange={() => {}}
            options={CURRENT_INVESTMENTS_OPTIONS}
          />
          <Select
            label="Si mi inversión cae un 25%, tu: "
            name="investmentDropResponse"
            onChange={() => {}}
            options={DROP_RESPONSE_OPTIONS}
          />
          <div className={classes.buttonContinue}>
            <ButtonPrimary
              text="Continuar"
              onClick={() => {
                setStep(1);
              }}
            />
          </div>
        </section>
      )}
      {isStep === 1 && (
        <section className={classes.finish}>
          {!isMobile && (
            <img src={IlustrationDiscover} className={classes.ilustration} />
          )}
          <div className="right">
            <div className={"card"}>
              {isMobile && (
                <img
                  src={IlustrationDiscover}
                  className={classes.ilustration}
                />
              )}
              <div className="title">Tu perfil recomendado</div>
              <div className="profile">Moderado</div>
              <div className="text">Descripción</div>
            </div>
            <div className="buttons">
              <ButtonOutlined
                text="Ir atras y editar"
                onClick={() => {
                  setStep(0);
                }}
              />
              <ButtonPrimary
                text="Guardar y volver a Mis finanzas"
                onClick={() => {
                  navigate(Route.finance);
                }}
              />
            </div>
          </div>
        </section>
      )}
    </>
  );
};
export default InvestmentTest;

const useStyles = makeStyles<Theme>((theme) => ({
  containerData: {
    display: "grid",
    maxWidth: 1100,
    margin: "0 auto",
    boxShadow: "4px 4px 20px 2px rgba(0, 0, 0, 0.2)",
    background: "white",
    padding: "20px 40px",
    borderRadius: 30,
    marginBottom: "10rem",
    [theme.breakpoints.down(1280)]: {
      width: 375,
    },
    "& >div": {
      width: 520,
      [theme.breakpoints.down(1280)]: {
        width: 300,
      },
      margin: "15px 0",
    },
  },
  buttonContinue: {
    width: "100%!important",
    display: "flex",
    justifyContent: "end",
    [theme.breakpoints.down(1280)]: {
      justifyContent: "center",
    },
  },
  title: {
    maxWidth: 1100,
    margin: "0 auto",
    fontFamily: "Montserrat",
    paddingLeft: 50,
    marginTop: 30,
    marginBottom: 30,
    [theme.breakpoints.down(1280)]: {
      width: 375,
      paddingLeft: 0,
      textAlign: "center",
    },
  },
  finish: {
    display: "flex",
    justifyContent: "center",
    marginLeft: "5rem",
    marginTop: "2rem",
    gap: "7rem",
    marginBottom: "2rem",
    [theme.breakpoints.down(1280)]: {
      marginLeft: 0,
    },
    "& .buttons": {
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down(1280)]: {
        display: "grid",
        gap: "2rem",
        justifyContent: "center",
      },
    },
    "& .card": {
      background: "flex",
      boxShadow: "4px 4px 20px 2px rgba(0, 0, 0, 0.2)",
      borderRadius: 20,
      width: 520,
      height: 400,
      display: "grid",
      gridTemplateRows: "100px 50px 200px",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      marginBottom: "4rem",
      [theme.breakpoints.down(1280)]: {
        width: 375,
        height: 400,
        gridTemplateRows: "210px 50px 50px 50px",
        "& img": {
          width: 200,
          margin: "0 auto",
        },
      },
      "& .title": {
        fontSize: 20,
        fontWeight: 300,
        textTransform: "uppercase",
      },
      "& .profile": {
        fontFamily: "Montserrat",
        fontSize: 40,
        fontWeight: "bold",
      },
      "& .text": {
        fontWeight: 300,
      },
    },
  },
}));
