import React from "react";
import { CommonInputProps, SizedTextField } from "@components";

type Props = CommonInputProps;

export const TextInput: React.FC<Props> = ({
  className,
  name,
  label,
  value,
  onChange,
  onBlur,
  disabled,
  placeholder,
  type,
  size,
  error,
  required,
}) => {
  return (
    <SizedTextField
      className={`${className || ""} altercap-${name}-input altercap-input`}
      name={name}
      label={label}
      placeholder={placeholder}
      variant="outlined"
      value={value}
      type={type || "text"}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      size={size}
      error={error}
      required={required}
    />
  );
};
