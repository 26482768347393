// bancos que no tienen finerio pero podrian tener en un futuro
export const BANK_OPTIONS_FINERIO = [
    "Banco Bice",
    "Banco Security",
    "Banco Consorcio",
    "Banco BBVA",
    "Tenpo",
    "Mach",
    "Mercado Pago",
    "Falabella",
    "Itau",
    "Santander",
    
  ].map(g => ({
    name: g,
    value: g,
  }));
  