import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    width: "100%",
    color: theme.palette.text.primary,
    position: "relative",
  },
  slider: {
    padding: "45px 30px 30px 20px",
    [theme.breakpoints.down(1280)]: {
      padding: "45px 15px 15px 20px",
    },
  },
  arrowButton: {
    height: 40,
    justifyContent: "end",
    display: "flex",
    paddingRight: 50,
    "& button": {
      margin: 0,
      transform: "rotate(90deg)",
    },
  },
  title: {
    color: theme.palette.text.secondary,
    position: "absolute",
    top: 20,
    left: 20,
  },
  titleMobile: {
    color: theme.palette.text.secondary,
    position: "absolute",
    [theme.breakpoints.down(1240)]: {
      padding: "20px 0 0 20px",
    },
  },
  riel: {
    "& .buttonTwe": {
      margin: "0px -1rem 7rem 0px!important",
    },
  },
}));
