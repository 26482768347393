import React, { useState, useCallback, useEffect, useContext } from "react";
import { makeStyles, Theme } from "@material-ui/core";
import {
  ButtonOutlined,
  ButtonPrimary,
  Footer,
  GoalSelector,
  InvestPeriodRangeSelector,
  NumericInput,
  TextInput,
  FoundSelector,
} from "@components";
import { navigate } from "gatsby";
import { Route } from "@interfaces";
import { Return } from "images/Goal";
import { GoalsContext } from "@context";

interface LayoutNotMissionProps {
  className?: string;
  styleFooter?: string;
  icon?: string;
  children: React.ReactNode;
  title: string;
  subtitle: string;
  handleBack: () => void;
}

export const LayoutNotMissionDesktop: React.FC<LayoutNotMissionProps> = ({
  className,
  styleFooter,
  icon,
  children,
  title,
  subtitle,
  handleBack,
}) => {
  const classes = useStyles();

  return (
    <div className={`${className || ""} ${classes.container}`}>
      <div className={classes.subContainer}>
        <section>
          <div className={classes.headerLayout}>
            <div className={classes.IconMision}>
              <img src={icon} />
            </div>
            <div
              onClick={handleBack}
              className={classes.return}
            >
              <img src={Return} />
              Volver
            </div>
          </div>
        </section>
        <section className={classes.containerData}>
        <div className={classes.title} >{title}<span>{subtitle? subtitle: ""}</span></div>
        {children}
        </section>
      </div>
      <Footer className={`${classes.footer} ${styleFooter}`} />
    </div>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  footer: {
    [theme.breakpoints.down(1100)]: {
      display: "none",
    },
  },
  subContainer: {
    display: "flex",
    marginBottom: "10rem",
  },
  container: {
    overflowX: "hidden",
    background: "#F4F3F3",
  },
  title: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: "2.25rem",
    color: "#393e44",
    display: "flex",
    flexDirection: "column",
    "& span": {
      fontWeight: "normal",
      fontSize: "1.25rem",
      color:theme.palette.text.secondary,
    }
  },
  children: {},
  headerLayout: {
    display: "grid",
    gap: "2rem",
    alignItems: "center",
    paddingLeft: 106,
    paddingTop: 50,
    width: 200,
    justifyContent: "center",
    "& img": {
      width: 140,
      height: 50,
    },
  },
  IconMision: {
    "& img": {
      width: 70,
      height: 70,
    },
  },
  containerData: {
    display: "block",
    gap: "2rem",
    marginTop: 60,
    marginLeft: "3rem",
    width: "100%",
    "& > div": {
      cursor: "pointer",
      width: "100%",
    },
  },
  return: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontFamily: "Montserrat",
    color:theme.palette.text.secondary,
    cursor: "pointer",
    "& img": {
      width: 50,
      height: 50,
    },
  },
}));
