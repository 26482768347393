import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  EventsContainer: {
    marginBottom: "3rem",
    width: "23.4375rem",
    padding: "1.25rem ",
    borderRadius: "2rem",
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
    boxShadow: "4px 4px 8px 2px rgba(0, 0, 0, 0.2)",
    [theme.breakpoints.down(1280)]: {
      width: "100%",
      margin: "0",
      BorderRadius: "30.5px",
      padding: "3rem",
    },
    "& .lefter": {
      margin: "0.5rem",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .righter": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      width: "calc(100% - 1rem)",

      "& .totalDay": {
        color: theme.palette.text.secondary,
        fontSize: "0.75rem",
        fontFamily: "Montserrat",
        textTransform: "uppercase",
        textAlign: "right",
        fontWeight: 500,
        margin: "2rem 0 0 0",
        [theme.breakpoints.down(1280)]: {},
      },
      "& .totalDayAmount": {
        color: theme.palette.text.primary,
        fontSize: "0.875rem",
        fontWeight: "bold",
        textAlign: "right",
      },
    },
  },
  eventsTitleButton: {
    transition: "transform .3s",
  },
  eventsTitleButtonOpen: {
    transform: "rotate(-90deg)",
    transition: "transform .3s",
  },
  buttonMainContainer: {
    justifyContent: "start",
    width: "100%",
    margin: "0 0 1rem 0",
  },
  addButton: {
    borderRadius: "8px",
    width: "10rem",
    height: "2.25rem",
    border: "1.5px solid #008296",
    boxShadow: "5.3px 5.3px 10.5px 2.6px rgba(0, 0, 0, 0.2)",
  },
  addButtonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  addButtonContainerTitle: {},
  eventsTitle: {
    fontSize: "0.75rem",
    fontWeight: 500,
    color: theme.palette.text.secondary,
    textTransform: "uppercase",
    textAlign: "left",
    fontFamily: "Montserrat",
  },
  dividerColor: {
    backgroundColor: "#d6d6d6",
    margin: "1rem 0 0.5rem",
    width: "17rem",
  },
  button: {
    backgroundImage: "linear-gradient(85deg, #4619b6 6%, #c11ec7 94%)",
    color: "#ffffff",
    fontWeight: 400,
    margin: "2rem 0 0 0",
    textTransform: "none",
    borderRadius: "10px",
    boxShadow: "4px 4px 8px 2px rgba(0, 0, 0, 0.2)",
    [theme.breakpoints.down(1280)]: {
      margin: "2rem 0 0 0",
      width: "100%",
    },
    "& .imageContainer": {
      width: "2.063rem",
      height: "2.063rem",
      margin: "0 0.75rem 0 0",
    },
  },
  dayDisplayValue: {
    fontFamily: "Montserrat",
    fontSize: "1rem",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2.03,
    letterSpacing: "normal",
    color: "#393e44",
  },
  hiddenEvent: {
    display: "none",
    transition: "transform .3s",
  },
  regularEvent: {
    transition: "transform .3s",
    width: "100%",
    cursor: "pointer",
  },
}));
