import { gql, useQuery } from "@apollo/client";
import { Portfolio } from "@interfaces";

export const GET_PORTFOLIOS_QUERY = gql`
  query GetPortfolios {
    getPortfolios {
      id
      name
      type
      status
      investorProfile
      totalInvested
      totalDeposited
      currentValue
      profitability
      pendingInvestments
      pendingWithdrawals
      investmentAsset
      investmentLocation
      depositedUnassignedAmount
      totalWithdrawn
      goalId
    }
  }
`;

interface QueryResult {
  getPortfolios: Portfolio[];
}

interface HookReturn {
  portfolios: Portfolio[];
  loading: boolean;
}

export const usePortfolios = (): HookReturn => {
  const { data, loading } = useQuery<QueryResult>(GET_PORTFOLIOS_QUERY);
  return { portfolios: data?.getPortfolios ?? [], loading };
};
