//RESUMEN
const GRAPH_ICON =
  'https://storage.googleapis.com/rokinapp-images/investments/graph_icon.svg';

const CALENDAR_ICON_01 =
  'https://storage.googleapis.com/rokinapp-images/investments/calendar01_icon%20(1).svg';

const ADD_ICON =
  'https://storage.googleapis.com/rokinapp-images/investments/add_icon.svg';

const EXPENSE_ICON =
  'https://storage.googleapis.com/rokinapp-images/investments/expense_icon.svg';

const MONITOREAR_ICON_GREEN =
  'https://rokinapp-images.s3.amazonaws.com/investments/monitorear_icon_1.svg';

const TREND_ICON_GREEN =
  'https://rokinapp-images.s3.amazonaws.com/investments/trend_icon.svg';

const CALENDAR_ICON_GREEN =
  'https://rokinapp-images.s3.amazonaws.com/investments/calendar_icon_1.svg';
//OBJETIVOS
const DEPOSIT_ICON_WHITE =
  'https://rokinapp-images.s3.amazonaws.com/investments/depositar_icon.svg';

const ADD_GOAL_WHITE =
  'https://rokinapp-images.s3.amazonaws.com/investments/addGoal_icon.svg';

//POPUP
const TARGET_LIGHT_BLUE =
  'https://rokinapp-images.s3.amazonaws.com/investments/target_icon.svg';

const EYE_LIGHT_BLUE =
  'https://rokinapp-images.s3.amazonaws.com/investments/eye_icon.svg';

const INVERTION_LIGHT_BLUE =
  'https://rokinapp-images.s3.amazonaws.com/investments/inversiones_icon.svg';

const SAFE_LIGHT_BLUE =
  'https://rokinapp-images.s3.amazonaws.com/investments/safe_icon.svg';

//NO DATA
const ASTRONAUNT_GIF =
'https://storage.googleapis.com/rokinapp-images/investments/astronaut.json';
const ASTRONAUNT =
'https://storage.googleapis.com/rokinapp-images/investments/astronautPlaceholder.png';
const COINS_3D =
'https://storage.googleapis.com/rokinapp-images/investments/coins3D.png';

export {
  DEPOSIT_ICON_WHITE,
  ADD_GOAL_WHITE,
  MONITOREAR_ICON_GREEN,
  TREND_ICON_GREEN,
  CALENDAR_ICON_GREEN,
  TARGET_LIGHT_BLUE,
  EYE_LIGHT_BLUE,
  INVERTION_LIGHT_BLUE,
  SAFE_LIGHT_BLUE,
  GRAPH_ICON,
  CALENDAR_ICON_01,
  ADD_ICON,
  EXPENSE_ICON,
  ASTRONAUNT_GIF,
  ASTRONAUNT,
  COINS_3D,
};
