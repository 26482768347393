import React from "react";
import { ChangeEvent, OnChangeType } from "@interfaces";
import { Slider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {
  className?: string;
  name?: string;
  label?: string;
  value: number;
  hideLabel?: boolean;
  onChange: OnChangeType;
  ariaLabel?: boolean;
  valueLabelDisplay?: boolean;
  marks?: boolean;
}

export const InvestPeriodRangeSelector: React.FC<Props> = ({
  className,
  name = "period",
  label = "Durante:",
  value,
  hideLabel,
  onChange,
  ariaLabel,
  valueLabelDisplay,
  marks,
}) => {
  const classes = useStyles();

  return (
    <div
      className={`invest-period-range-selector ${
        classes.investPeriodRangeSelector
      } ${className || ""}`}
    >
      {!hideLabel && <div className="text-1">{label}</div>}
      <Slider
        onChange={(_, v) => {
          if (v !== value)
            onChange({
              target: { name, value: v },
            } as ChangeEvent);
        }}
        aria-labelledby="discrete-slider"
        getAriaValueText={(v: number) =>
          v === 0 ? "Menos de 1 año" : v === 6 ? "Más de 5 años" : `${v} años`
        }
        min={0}
        max={6}
        value={value}
        aria-label={ariaLabel && "Small steps"}
        valueLabelDisplay={valueLabelDisplay ? "auto" : "off"}
        marks={marks}

        // marks
      />
      <div className="text-2">
        {value === 0
          ? "Menos de 1 año"
          : value === 6
          ? "Más de 5 años"
          : `${value} año${value > 1 ? "s" : ""}`}
      </div>
    </div>
  );
};
const useStyles = makeStyles(
  (theme) => ({
    investPeriodRangeSelector: {
      display: "flex",
      flexDirection: "column",
      padding: "0 5px",
      "& .text-1": {
        margin: "0 -5px",
      },
      "& .MuiSlider-root": {
        "& .MuiSlider-rail": {
          height: 4,
        },
        "& .MuiSlider-track": {
          height: 4,
        },
        "& .MuiSlider-thumb": {
          marginTop: "-4px",
        },
      },
      "& .text-2": {
        margin: "0 auto",
        color: "rgba(0, 0, 0, 0.5)",
      },
    },
  }),
  { index: 1 }
);
