import React, { useState } from "react";
import { PageProps } from "gatsby";
import { makeStyles } from "@material-ui/core";
import {
  ButtonPrimary,
} from "@components";
import BankData from "@components/Found/BankData";


interface BankDataProps {
  form: any;
  buttonText: string;
  handleClick: () => void;
}

export const BankDataComponent: React.FC<BankDataProps> = ({
  form,
  buttonText,
  handleClick,
}) => {
  const classes = useStyles();
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

  return (
    <>
      <section className={classes.container}>
        <div className={classes.form}>
          <div className={classes.inputs}>
            <BankData form={form} setButtonDisabled={setButtonDisabled}/>
          </div>
          <ButtonPrimary
            onClick={handleClick}
            className={classes.boton}
            text={buttonText}
            disabled={buttonDisabled}
            secondaryVersion
          />
        </div>
      </section>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    display: "grid",
    gridTemplateColumns: "100%",
    position: "relative",
    height: 529,
  },
  form: {},
  inputs: {
    width: "auto",
    marginRight: "2rem",
  },
  boton: {
    width: 184,
    marginLeft: 693,
    marginTop: 100,
    [theme.breakpoints.down(1000)]: {
      width: "100%",
      height: 52,
      marginTop: 29,
      marginLeft: 0,
    },
  },
}));
