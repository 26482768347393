import {
  navArrowLeft,
  navArrowLeftActive,
  navArrowRight,
  navArrowRightActive,
} from "@images";
import { useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CardMissionIndividual from "../CardMissionIndividual/CardMissionIndividual";
import { useStyles } from "./SliderMissions.style";

interface SliderMissionsProps {
  title: string;
  card: any[][];
  categoryInfo: any;
}

const SliderMissions = ({ title, card, categoryInfo }: SliderMissionsProps) => {
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const classes = useStyles();
  const slider = React.useRef(null);

  const [currentLeftArrow, setCurrentLeftArrow] = useState(navArrowLeft);
  const [currentRightArrow, setCurrentRightArrow] =
    useState(navArrowRightActive);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    if (currentSlide == 0) {
      setCurrentLeftArrow(navArrowLeft);
      setCurrentRightArrow(navArrowRightActive);
    }
    if (currentSlide == 1) {
      setCurrentLeftArrow(navArrowLeftActive);
      setCurrentRightArrow(navArrowRightActive);
    }
    if (currentSlide == 2) {
      setCurrentLeftArrow(navArrowLeftActive);
      setCurrentRightArrow(navArrowRight);
    }
  }, [currentSlide]);

  const arrowRight = {
    width: 50,
    height: 50,
    background: `url(${currentRightArrow})`,
    margin: "0 0 4rem -1rem",
  };

  const arrowLeft = {
    width: 50,
    height: 50,
    background: `url(${currentLeftArrow})`,
    margin: "0 -1rem 7rem 0",
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    beforeChange: (current, next) => {
      setCurrentSlide(next);
    },
    vertical: true,
    verticalSwiping: true,
    dotsClass: `slick-dots ${classes.dots}`,
  };
  const listCards = [];
  listCards.push(
    <div className={classes.slider}>
      {card.map((c) => {
        return (
          <div key={c.mission.id}>
            <CardMissionIndividual
              enabled={true}
              text={c.mission.name}
              numberCard={c.mission.subCategoryNumber}
              numberCoins={c.mission.roks}
              numberTime={+c.mission.estimateTimeMin}
              onClick={c}
            />
          </div>
        );
      })}
    </div>
  );

  if (isMobile) {
    return (
      <section className={classes.container}>
        <div className={classes.titleMobile}>
          {card[0]?.mission.subCategory}
        </div>{" "}
        <div>{listCards}</div>
      </section>
    );
  } else {
    return (
      <section className={classes.container}>
        <div className={classes.title}>{card[0]?.mission.subCategory}</div>{" "}
        <div className={classes.riel}>
          {/**<div className={classes.arrowButton}>
            <button
              onClick={() => slider?.current?.slickPrev()}
              style={arrowLeft}
            />
    </div>*/}
          <div>
            <Slider ref={slider} {...settings}>
              {listCards}
            </Slider>
          </div>
          {/**<div className={classes.arrowButton}>
            <button
              className="buttonTwe"
              onClick={() => slider?.current?.slickNext()}
              style={arrowRight}
            />
  </div>*/}
        </div>
      </section>
    );
  }
};

export default SliderMissions;
