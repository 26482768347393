import React, { Dispatch, SetStateAction, useState } from "react";
import { useStyles } from "./MB1step1.style";
import { useMediaQuery } from "@material-ui/core";
import { CAE, CTC, monthlyShare, interestRate } from "@images";
import { ButtonPrimary } from "@components/Reusables";

type Props = {
  setPage: Dispatch<SetStateAction<number>>;
};

const MB1Step1: React.FC<Props> = ({ setPage }) => {
  const tagInfo = [
    {
      name: "Cuota Mensual",
      image: monthlyShare,
      text: (
        <span className="text">
          Es el
          <strong> monto </strong>
          que deberás pagar mensualmente para ir devolviendo lo que te
          prestaron.
        </span>
      ),
    },
    {
      name: "Tasas de interés",
      image: interestRate,
      text: (
        <span className="text">
          Es el
          <strong> cobro extra</strong>, expresado en porcentaje, que deberás
          pagar por haber usado ese préstamo.
        </span>
      ),
    },
    {
      name: "Carga anual equivalente (Cae)",
      image: CAE,
      text: (
        <span className="text">
          Es la
          <strong> tasa de interés</strong>, expresada en porcentaje, que
          incluye el préstamo y todos los costos asociados a él.
        </span>
      ),
    },
    {
      name: "costo total del crédito (ctc)",
      image: CTC,
      text: (
        <span className="text">
          {" "}
          Es el
          <strong> monto final </strong>
          que terminarás pagando al sumar todas las cuotas mensuales e intereses
          al finalizar el préstamo.
        </span>
      ),
    },
  ];
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.topSection}>
        <span className="text">
          Al cotizar créditos encontramos muchos términos que se usan para
          describir las condiciones con las que van a prestar esa plata. Acá te
          explicamos los
          <strong> conceptos más importantes: </strong>
        </span>
      </div>
      <div className={classes.midSection}>
        {tagInfo.map((tag) => (
          <div className="whiteBox">
            <div className="image">
              <img src={tag.image} />
            </div>
            <div className="info">
              <span className="name">{tag.name}</span>
              {tag.text}
              {/* <span className="text">{tag.text}</span> */}
            </div>
          </div>
        ))}
      </div>
      <div className={classes.bottomSection}>
        <ButtonPrimary
          text="Siguiente"
          secondaryVersion
          onClick={() => setPage(2)}
        />
      </div>
    </div>
  );
};

export default MB1Step1;
