import { MissionsInfo } from "@data";

//This will return the data necessary for the CongratsModal it requires the currentMission you are in and UserMissions
export const nextMissionModalInfo = (currentMission, missions) => {
  const nextMission = missions.filter(
    (obj) => obj.mission.id == currentMission.mission.nextMission
  );
  if (nextMission?.length == 0) {
    return false;
  } else {
    const category = Object.values(MissionsInfo).filter(
      (obj) => obj.name == nextMission[0].mission.category
    )[0];
    const route = Object.values(category).filter(
      (obj) => obj?.id == nextMission[0].mission.id
    )[0]?.route;

    return {
      name: nextMission[0].mission.name,
      number: nextMission[0].mission.subCategoryNumber,
      description: nextMission[0].mission.shortDescription,
      thisReward: currentMission.mission.roks,
      nextReward: nextMission[0].mission.roks,
      route: route,
      category: category.name,
    };
  }
};

export const currentMissionModalInfo = (missions) => {
  const nextMission = missions.filter((obj) => obj.state == "PENDING");

  if (nextMission?.length == 0) {
    return false;
  } else {
    const category = Object.values(MissionsInfo).filter(
      (obj) => obj.name == nextMission[0].mission.category
    )[0];
    const route = Object.values(category).filter(
      (obj) => obj.id == nextMission[0].mission.id
    )[0].route;

    return {
      name: nextMission[0].mission.name,
      number: nextMission[0].mission.subCategoryNumber,
      description: nextMission[0].mission.shortDescription,
      reward: nextMission[0].mission.roks,
      route: route,
      category: category.name,
    };
  }
};

export const oneMissionModalInfo = (mission) => {
  const category = Object.values(MissionsInfo).filter(
    (obj) => obj.name == mission.mission.category
  )[0];
  const route = Object.values(category).filter(
    (obj) => obj?.id == mission.mission.id
  )[0]?.route;
  return {
    name: mission.mission.name,
    number: mission.mission.subCategoryNumber,
    description: mission.mission.shortDescription,
    reward: mission.mission.roks,
    route: route,
    category: category.name,
  };
};
