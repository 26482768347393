import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  infoBox: {
    display: "flex",
    width: "auto",
    height: "auto",
    minHeight: "7rem",
    borderRadius: 30,
    boxShadow: "4px 4px 20px 2px rgba(0,0,0,0.2)",
    backgroundColor: "#ffffff",
    flexDirection: "column",
    justifyContent: "center",
    padding: 25,
    gap: "0.5rem",
    minWidth: 315,
    "& span": {
      color: "#6b6b6b",
      fontSize: "1.27rem",
    },
  },
  sectionText: {
    marginLeft: "1.25rem",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down(1280)]: {
      color: "white",
      marginLeft: "0.5rem",
    },
    "& >span": {
      fontSize: "1rem",
    },
  },
  leftSection: {
    display: "flex",
    flexDirection: "column",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: "1.25rem",
    color: "#393e44",
    gap: "1rem",
  },
  leftSectionWrapper: {
    marginLeft: "4rem",
    marginTop: "1rem",
    display: "flex",
    flexDirection: "column",
    gap: 50,
    [theme.breakpoints.down(1280)]: {
      margin: "1rem 0",
    },
  },
}));
