import { gql, useQuery } from '@apollo/client';
import { UserSubscriptions } from '@interfaces';

export const GET_USER_SUBSCRIPTION = gql`
  query GetUserSubscriptions {
    getUserSubscriptions {
      id
      paid
      planId {
        id
        updatedAt
        name
        subtitle
        price
        priceDiscount
        periodicity
        image
        paymentLink
        paymentLinkDiscount
        annualPaymentLink
        annualPaymentLinkDiscount
        isRecommended
        needSchedule
        benefits
      }
      updatedAt
    }
  }
`;

interface QueryResult {
  getUserSubscriptions: UserSubscriptions[];
}

interface HookReturn {
  userSubscriptions: UserSubscriptions[];
  userSubscriptionLoading: boolean;
}

export const getUserSubscriptions = (): HookReturn => {
  const { data, loading } = useQuery<QueryResult>(GET_USER_SUBSCRIPTION);
  return {
    userSubscriptions: data?.getUserSubscriptions ?? [],
    userSubscriptionLoading: loading,
  };
};
