import { initializeApp } from "firebase/app";
import { getFirestore, doc, setDoc, getDoc, addDoc, collection } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
  measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export const getCheckBannerView = async (email: string) => {
  const docRef = doc(db, "users", email);
  return await getDoc(docRef).then(response => {
    if (response.exists()) {
      return !!response.data()["bannerView"];
    } else {
      return false;
    }
  });
};

export const setCheckBannerView = async (email: string) => {
  await setDoc(doc(db, "users", email), {
    email,
    bannerView: true,
  }).then(() => {
    return true;
  });
};

export const setModelData = async (email: string, data: string, popUpName: string) => {
  await addDoc(collection(db, "popUpData"), {
    popUpName,
    email,
    data,
  }).then(() => {
    return true;
  });
}
