import React from "react";
import { Select } from "@components";
import { CommonSelectorProps, MARITAL_PROPERTY_REGIME_OPTIONS } from "..";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends CommonSelectorProps {}

export const MaritalPropertyRegimeSelector: React.FC<Props> = ({
  className,
  name,
  label,
  ...props
}) => {
  return (
    <Select
      className={`${className || ""} altercap-maritalContract-selector`}
      name={name || "maritalPropertyRegime"}
      label={label ?? "Régimen de bienes (solo casado)"}
      options={MARITAL_PROPERTY_REGIME_OPTIONS}
      {...props}
    />
  );
};
