import React from "react";
import {
  Accordion,
  AccordionSummary,
  Switch,
  makeStyles,
} from "@material-ui/core";
import { ActiveFilters } from "@interfaces";

export interface FilterProps {
  className?: string;
  filters: ActiveFilters;
  handleChange: (event: any) => void;
}
const RedeemableFilter: React.FC<FilterProps> = ({
  className,
  filters,
  handleChange,
}) => {
  const classes = useStyles();
  return (
    <Accordion
      className={`${className || ""} ${classes.switchFilter}`}
      expanded={false}
      // onClick={() => handleChange({ target: { name: "toggle-redeemable" } })}
    >
      <AccordionSummary
        className={classes.accordionSummary}
        id="panel2bh-header"
        aria-controls="panel2bh-content"
        style={{ minHeight: "unset" }}
      >
        <span className={classes.title}>Rescatable</span>
        <span>
          <Switch
            name="toggle-redeemable"
            checked={filters.redeemable}
            onChange={handleChange}
            color="primary"
            inputProps={{
              "aria-label": `alternar rescatable`,
            }}
          />
        </span>
      </AccordionSummary>
    </Accordion>
  );
};
const useStyles = makeStyles(() => ({
  switchFilter: {
    boxShadow: "none",
    borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
    "&:first-of-type": {
      borderTop: "1px solid rgba(0, 0, 0, 0.2)",
    },
    "&:before": {
      top: 0,
      height: 0,
    },
  },
  accordionSummary: {
    minHeight: "unset",
    height: 42,
    padding: "0 20px",
    fontSize: 14,
    cursor: "auto",
    "& .MuiAccordionSummary-content": {
      margin: 0,
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .MuiAccordionSummary-expandIcon": {
      padding: 9,
    },
  },
  title: {
    fontWeight: 500,
    fontSize: 16,
    color: "#343845",
  },
}));

export { RedeemableFilter };
