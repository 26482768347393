import { ApolloClient } from '@apollo/client';
import { gql } from '@apollo/client';

const UPDATE_FINERIO_ACCOUNT = gql`
  mutation UpdateFinerioAccount {
    updateFinerioAccount
  }
`;

type UpdateFinerioAccountResult = {
  updateFinerioAccount: boolean;
};

export async function updateFinerioAccount(
  client: ApolloClient<any>
): Promise<UpdateFinerioAccountResult> {
  const { data } = await client.mutate({
    mutation: UPDATE_FINERIO_ACCOUNT,
  });

  return data.updateFinerioAccount;
}
