import "regenerator-runtime/runtime.js";
import { DependencyList, useEffect, useRef } from "react";
import isEqual from "lodash/isEqual";

export function useDeepDependencies(dependencies: DependencyList) {
  const dependenciesRef = useRef(dependencies);
  useEffect(() => {
    if (!isEqual(dependencies, dependenciesRef.current)) {
      dependenciesRef.current = dependencies;
    }
  });
  return dependenciesRef.current;
}
