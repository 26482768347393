import React, { createContext, useCallback, useMemo, useState } from "react";
import { Snackbar, AlertType } from "@components";

type Entry = {
  msg: string;
  type: AlertType;
};
interface SnackbarContextType {
  showSnackbar: (msg: string, type: AlertType) => void;
  enableSnackbar: () => void;
  disableSnackbar: () => void;
}

export const SnackbarContext = createContext({} as SnackbarContextType);

export const SnackbarProvider: React.FC = ({ children }) => {
  const [activated, setActivated] = useState(true);
  const enableSnackbar = useCallback(() => setActivated(true), [setActivated]);
  const disableSnackbar = useCallback(() => setActivated(false), [
    setActivated,
  ]);
  const [messageQueue, setQueue] = useState<Entry[]>([]);
  const onClose = useCallback(() => setQueue(prev => prev.slice(1)), [
    setQueue,
  ]);

  const showSnackbar = useCallback(
    (msg: string, type: AlertType) => {
      setTimeout(() => setQueue(prev => [...prev, { msg, type }]), 300);
    },
    [setQueue]
  );

  const context = useMemo(
    () => ({
      showSnackbar,
      enableSnackbar,
      disableSnackbar,
    }),
    [showSnackbar, enableSnackbar, disableSnackbar]
  );
  return (
    <SnackbarContext.Provider value={context}>
      <>
        {children}
        <Snackbar
          open={activated && Boolean(messageQueue.length)}
          onClose={onClose}
          message={messageQueue[0]?.msg}
          type={messageQueue[0]?.type}
        />
      </>
    </SnackbarContext.Provider>
  );
};
