import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Button as MuiButton } from "@material-ui/core";
import { COMPONENT_SIZES, SizeType } from "@styles";

type Props = {
  text: string;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  size?: SizeType; // 30, 36, 44, 48, 56
  fullWidth?: boolean;
};

export const ButtonText: React.FC<Props> = ({
  text,
  onClick,
  className,
  disabled,
  size = "large",
  fullWidth,
}) => {
  const classes = useStyles({ size });

  return (
    <MuiButton
      className={`${className} ${classes.root}`}
      variant="text"
      onClick={onClick}
      disabled={disabled}
      fullWidth={fullWidth}
    >
      {text}
    </MuiButton>
  );
};

type StyleProps = Pick<Props, "size">;

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    color: theme.palette.grey[800],
    textTransform: "none",
    textDecoration: "underline",
    fontWeight: "normal",
    "&:hover": {
      backgroundColor: "transparent",
    },
    height: ({ size }) => COMPONENT_SIZES[size ?? "large"],
  },
}));
