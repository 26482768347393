import {
  CashBalance,
  Investment,
  // InvestmentStatus,
  Portfolio,
} from "@interfaces";
// import { map, sum } from "lodash";
import { useMemo } from "react";

interface HookParams {
  investments: Investment[];
  portfolios: Portfolio[];
  currentCashBalance: number;
}

type Summary = {
  totalDeposited: number;
  totalInvested: number;
  currentValue: number;
  totalWithdrawn: number;
};
type HookResults = {
  totalDeposited: number;
  totalInvested: number;
  currentValue: number;
  profitability: number;
  profits: number;
  investmentsTotals: Summary;
  portfoliosTotals: Summary;
  cashValue: number;
};

export const useInvestmentsTotals = ({
  investments,
  portfolios,
  currentCashBalance,
}: HookParams): HookResults => {
  const globals = useMemo(() => {
    const investmentsTotals = investments.reduce(
      (prev, actual) => ({
        totalDeposited: prev.totalDeposited + actual.totalDeposited,
        totalInvested: prev.totalInvested + actual.totalInvested,
        currentValue: prev.currentValue + actual.currentValue,
        totalWithdrawn: prev.totalWithdrawn + actual.totalWithdrawn,
      }),
      {
        totalDeposited: 0,
        totalInvested: 0,
        currentValue: 0,
        totalWithdrawn: 0,
      },
    );
    const portfoliosTotals = portfolios.reduce(
      (prev, actual) => ({
        totalDeposited: prev.totalDeposited + actual.totalDeposited,
        totalInvested: prev.totalInvested + actual.totalInvested,
        currentValue: prev.currentValue + actual.currentValue,
        totalWithdrawn: prev.totalWithdrawn + actual.totalWithdrawn,
      }),
      {
        totalDeposited: 0,
        totalInvested: 0,
        currentValue: 0,
        totalWithdrawn: 0,
      },
    );
    const totalDeposited =
      investmentsTotals.totalDeposited + portfoliosTotals.totalDeposited;
    const totalInvested =
      investmentsTotals.totalInvested + portfoliosTotals.totalInvested;

    // const depositedUnassignedAmount =
    //   investments
    //     .filter(inv => inv.status === InvestmentStatus.DEPOSIT_BEING_INVESTED)
    //     .reduce((prev, inv) => prev + inv.pendingInvestments, 0) +
    //   sum(map(portfolios, "depositedUnassignedAmount"));

    const currentRealValue =
      investmentsTotals.currentValue + portfoliosTotals.currentValue;

    const cashValue = currentCashBalance; /* + depositedUnassignedAmount;*/

    const currentValue = currentRealValue + cashValue; // "A la fecha"
    // const profitsBasedOnInvested = currentRealValue - totalInvested;
    const totalWithdrawn = investmentsTotals.totalWithdrawn + portfoliosTotals.totalWithdrawn;
    
    const profitsBasedOnDeposited = currentValue - totalDeposited + totalWithdrawn;

    // const profitabilityBasedOnInvested =
    //   totalInvested > 0 && currentRealValue > 0
    //     ? (currentRealValue / totalInvested - 1) * 100
    //     : 0;

    const profitabilityBasedOnDeposited =
      totalDeposited > 0 && currentValue > 0
        ? ((currentValue + totalWithdrawn) / totalDeposited - 1) * 100
        : 0;

    return {
      totalDeposited,
      totalInvested,
      currentValue,
      profitability: profitabilityBasedOnDeposited,
      profits: profitsBasedOnDeposited,
      investmentsTotals,
      portfoliosTotals,
      cashValue,
      totalWithdrawn,
    };
  }, [investments, portfolios, currentCashBalance]);
  return globals;
};
