const ADD_EVENT_ICON =
  "https://rokinapp-images.s3.amazonaws.com/MyFinances/Calendar/add_icon.svg";
const REMINDER_ICON_WB =
  "https://rokinapp-images.s3.amazonaws.com/MyFinances/Calendar/recordatorio_wbackground.svg";

const COOLEBRA_ICON =
  "https://rokinapp-images.s3.amazonaws.com/MyFinances/Calendar/coolebraLogo.svg";

const COOLEBRA_ICON_PURPLE =
  "https://rokinapp-images.s3.amazonaws.com/calendar/coolebraLogo_2.svg";

const RECURRENT_EXPENSES_ICON_WB =
  "https://rokinapp-images.s3.amazonaws.com/MyFinances/Calendar/gastoRecurrente_wbackground.svg";

const RECURRENT_INCOME_ICON_WB =
  "https://rokinapp-images.s3.amazonaws.com/MyFinances/Calendar/ingresoRecurrente_wbackground.svg";

const MOVEMENT_ICON =
  "https://rokinapp-images.s3.amazonaws.com/MyFinances/Calendar/movimientoRegistrado_wbackground.svg";

const ARROW_BACK_ICON =
  "https://rokinapp-images.s3.amazonaws.com/MyFinances/Calendar/atras_icon.svg";

const REMINDER_ICON =
  "https://rokinapp-images.s3.amazonaws.com/MyFinances/Calendar/recordatorio_icon.svg";

const RECURRENT_EXPENSES_ICON =
  "https://rokinapp-images.s3.amazonaws.com/MyFinances/Calendar/gastoRecurrente_icon.svg";

const RECURRENT_INCOME_ICON =
  "https://rokinapp-images.s3.amazonaws.com/MyFinances/Calendar/ingresoRecurrente_icon.svg";

const ALIMENTATION_ICON =
  "https://rokinapp-images.s3.amazonaws.com/MyFinances/Calendar/Categories/alimentacion_icon_wbackground.svg";

const SHOPPING_ICON =
  "https://rokinapp-images.s3.amazonaws.com/MyFinances/Calendar/Categories/compras_icon_wbackground.svg";

const HOBBIES_ICON =
  "https://rokinapp-images.s3.amazonaws.com/MyFinances/Calendar/Categories/deportesYHobbies_icon_wbackground.svg";

const EDUCATION_ICON =
  "https://rokinapp-images.s3.amazonaws.com/MyFinances/Calendar/Categories/educacion_icon_wbackground.svg";

const FINANCES =
  "https://rokinapp-images.s3.amazonaws.com/MyFinances/Calendar/Categories/finanzasEImpuestos_icon_wbackground.svg";

const ENTERTAINMENT =
  "https://rokinapp-images.s3.amazonaws.com/MyFinances/Calendar/Categories/entretenimiento_icon_wbackground.svg";

const GIFTS =
  "https://rokinapp-images.s3.amazonaws.com/MyFinances/Calendar/Categories/regalos_icon_wbackground.svg";

const RESTAURANT =
  "https://rokinapp-images.s3.amazonaws.com/MyFinances/Calendar/Categories/restaurantes_icon_wbackground.svg";

const OTHERS =
  "https://rokinapp-images.s3.amazonaws.com/MyFinances/Calendar/Categories/otros_icon_wbackground.svg";

const HEALTH =
  "https://rokinapp-images.s3.amazonaws.com/MyFinances/Calendar/Categories/saludYBelleza_icon_wbackground.svg";

const TRANSPORT =
  "https://rokinapp-images.s3.amazonaws.com/MyFinances/Calendar/Categories/transporte_icon_wbackground.svg";

const HOME =
  "https://rokinapp-images.s3.amazonaws.com/MyFinances/Calendar/Categories/vivienda_icon_wbackground.svg";

const TRAVEL =
  "https://rokinapp-images.s3.amazonaws.com/MyFinances/Calendar/Categories/viajes_icon_wbackground.svg";

const DELETE_EVENT_ICON =
  "https://rokinapp-images.s3.amazonaws.com/MyFinances/Calendar/agregarIcon.svg";

const TRANSFERS =
  "https://rokinapp-images.s3.amazonaws.com/MyFinances/Calendar/Categories/transferencias_icon_wbackground.svg";

export {
  ADD_EVENT_ICON,
  REMINDER_ICON_WB,
  COOLEBRA_ICON,
  COOLEBRA_ICON_PURPLE,
  RECURRENT_EXPENSES_ICON_WB,
  RECURRENT_INCOME_ICON_WB,
  MOVEMENT_ICON,
  ARROW_BACK_ICON,
  REMINDER_ICON,
  RECURRENT_EXPENSES_ICON,
  RECURRENT_INCOME_ICON,
  ALIMENTATION_ICON,
  SHOPPING_ICON,
  HOBBIES_ICON,
  EDUCATION_ICON,
  FINANCES,
  ENTERTAINMENT,
  GIFTS,
  RESTAURANT,
  OTHERS,
  HEALTH,
  TRANSPORT,
  HOME,
  TRAVEL,
  TRANSFERS,
  DELETE_EVENT_ICON,
};
