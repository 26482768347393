import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
} from "@material-ui/core";

export interface FilterProps {
  className?: string;
  title: string;
  parsedSelections?: string;
  expanded: boolean;
  handleExpansion: (event: any, isExpanded: boolean) => void;
}
const Filter: React.FC<FilterProps> = ({
  className,
  title,
  parsedSelections,
  expanded,
  handleExpansion,
  children,
}) => {
  const classes = useStyles();
  return (
    <Accordion
      className={`${className || ""} ${classes.standardFilter}`}
      expanded={expanded}
      onChange={handleExpansion}
    >
      <AccordionSummary
        className={classes.accordionSummary}
        id="panel2bh-header"
        aria-controls="panel2bh-content"
        style={{ minHeight: "unset" }}
        expandIcon={<ExpandMoreIcon />}
      >
        <span className={classes.title}>{title}</span>
        <span className={classes.colorPrimary}>{parsedSelections}</span>
      </AccordionSummary>
      <AccordionDetails className={`${classes.accordionDetails}`}>
        {children || "empty"}
      </AccordionDetails>
    </Accordion>
  );
};
export { Filter };
const useStyles = makeStyles(
  theme => ({
    standardFilter: {
      boxShadow: "none",
      borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
      "&:first-of-type": {
        borderTop: "1px solid rgba(0, 0, 0, 0.2)",
      },
      "&:before": {
        top: "0",
        height: "0",
      },
      "&.Mui-expanded": {
        margin: "0",
      },
    },
    accordionSummary: {
      minHeight: "unset",
      height: 42,
      padding: "0 20px",
      fontSize: 14,
      "& .MuiAccordionSummary-content": {
        margin: 0,
        justifyContent: "space-between",
        alignItems: "center",
      },
      "& .MuiAccordionSummary-expandIcon": {
        padding: 9,
      },
    },
    accordionDetails: {
      padding: "8px 0 15px",
      backgroundColor: "rgba(245, 245, 245, 0.5)",
      flexDirection: "column",
    },
    title: {
      fontWeight: 500,
      fontSize: 16,
      color: "#343845",
    },
    colorPrimary: {
      color: theme.palette.primary.main,
      fontSize: 13,
    },
  }),
  { index: 1 },
);
