import appTheme from "./theme";

export { appTheme as theme };

export type SizeType = "small" | "medium" | "large" | "xlarge" | "xxlarge";

export const COMPONENT_SIZES: Record<SizeType, number> = {
  small: 30,
  medium: 36,
  large: 44,
  xlarge: 48,
  xxlarge: 56,
};

export const getTransformYValue = (size: SizeType): number =>
  (COMPONENT_SIZES[size] - 16) / 2;

export const getPaddingValue = (size: SizeType): number =>
  (COMPONENT_SIZES[size] - 19) / 2;

// export const INPUT_PADDING: Record<SizeType, number> = {
//   small: 5.5,
//   medium: 8.5,
//   large: 12.5,
//   xlarge: 14.5,
//   xxlarge: 18.5,
// };
