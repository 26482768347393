import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles<
  Theme,
  {
    open: boolean;
  }
>((theme) => ({
  eventContainer: {
    height: "auto",
    width: "65%",
    padding: "1.5rem 0 1.5rem 0",
    [theme.breakpoints.down(1280)]: {
      width: "100%",
      padding: "3rem",
      height: "25rem",
      marginBottom: "2rem",
    },
    [theme.breakpoints.down(380)]: {
      height: "30rem",
    },
  },
  title: {
    fontFamily: "Montserrat",
    fontSize: "1rem",
    fontWeight: 500,
    fontstretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#6b6b6b",
  },
  whiteBox: {
    // backgroundColor: "#ffffff",
    borderRadius: "10.4px",
    padding: "0.5rem",
    margin: "0.5rem 0rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#ffffff",
    alignItems: "center",
    boxShadow: "4px 4px 8px 2px rgba(0,0,0,0.2)",
    cursor: "pointer",
    [theme.breakpoints.down(1280)]: {},
    "& .MuiSvgIcon-root": {
      rotate: (open) => (open ? "180deg" : "0deg"),
    },
    "& .category": {
      fontSize: "0.85rem",
      textAlign: "left",
      color: theme.palette.text.primary,
      fontWeight: "normal",
      width: "100%",
      margin: "0 0 0 1rem",
    },
  },
  reminder: {},

  detailsContainer: {
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    [theme.breakpoints.down(1280)]: {
      width: "100%",
    },
  },
  remindersContainer: {
    flexDirection: "column",
    alignItems: "center",
  },
  transparentAccordion: {
    background: "none",
  },
  "& .button": {
    width: "21rem",
  },
  finalButton: {
    width: "100%",
    margin: "1rem 0 0 0",
  },
}));
