import { ButtonPrimary } from "@components/Reusables";
import { AppContext } from "@context";
import { circle1, circle2, circle3, circle4 } from "@images";
import React, { useContext } from "react";
import { useStyles } from "./M5Step3.style";

type Props = {
  handleClick: () => void;
}

const M5BudgetStep3: React.FC<Props> = ({
  handleClick,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.topText}>Consejos</div>
      <div className={classes.middleSection}>
        <div className={`${classes.whiteBox} ${classes.bigBox}`}>
          <div className="title">Recuerda</div>
          <span className="text">
            Lo más importante es que son
            <strong> gastos que podemos reducir </strong>y empezar a ahorrar e
            invertir, y pueden hacer una diferencia real. Algunos pasos para
            poder empezar:
          </span>
        </div>
        <div className={classes.advises}>
          <div className={`${classes.whiteBox} ${classes.smallBox}`}>
            <img src={circle1} alt="" />
            <span className="text">
              Identificar
              <strong> qué gastos </strong>
              hormiga tienes en el mes y cuál es el
              <strong> monto total </strong>. Es importante elegir cuales estás
              dispuesto a<strong> eliminar o disminuir.</strong>
            </span>
          </div>
          <div className={`${classes.whiteBox} ${classes.smallBox}`}>
            <img src={circle2} alt="" />
            <span className="text">
              Incluir en el presupuesto los
              <strong> gastos que no estás dispuesto a eliminar </strong>y
              recalcular tu saldo.
            </span>
          </div>
          <div className={`${classes.whiteBox} ${classes.smallBox}`}>
            <img src={circle3} alt="" />
            <span className="text">
              Si es que tienes
              <strong> saldo restante positivo </strong>, poner una
              <strong> meta de ahorro </strong>
              mes a mes para algún objetivo que hayas creado.
            </span>
          </div>
          <div className={`${classes.whiteBox} ${classes.smallBox}`}>
            <img src={circle4} alt="" />
            <span className="text">
              Dejar algo de plata para poder
              <strong> darte un gusto extra </strong>
              cuando así lo quieras.
            </span>
          </div>
        </div>
      </div>
      <div className={classes.bottomSection}>
        <ButtonPrimary
          text={"Terminar misión"}
          secondaryVersion
          onClick={() => handleClick()}
        />
      </div>
    </div>
  );
};

export default M5BudgetStep3;
