import { ApolloClient } from "@apollo/client";
import { gql } from "@apollo/client";
import { GET_FINERIO_TRANSACTIONS } from "./finerioTransactions.query";

const CREATE_MANUAL_TRANSACTION = gql`
  mutation CreateManualTransaction($params: CreateTransactionParams) {
    createManualTransaction(params: $params)
  }
`;

type CreateManualTransactionParams = {
  amount: number,
  bankId?: number,
  description: string,
  date: string,
  isCharge: boolean,
  subcategoryId: string
};

type CreateManualTransactionResult = {
  createManualTransaction: boolean;
};

export async function createManualTransaction(
  client: ApolloClient<any>,
  params: CreateManualTransactionParams
): Promise<CreateManualTransactionResult> {
  const { data } = await client.mutate({
    mutation: CREATE_MANUAL_TRANSACTION,
    variables: { params: params },
    refetchQueries: [
      {
        query: GET_FINERIO_TRANSACTIONS,
      },
    ],
  });

  return data;
}
