import React, { useState, useCallback, Dispatch, SetStateAction } from "react";
import { useStyles } from "./Simulator.style";
import { useMediaQuery } from "@material-ui/core";
import {
  FormControl,
  FormControlLabel,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import CircleChecked from "@material-ui/icons/CheckCircleOutline";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import { NumericInput, NameInput, ButtonPrimary } from "@components";
import { comisiones } from "@images";
import { Route } from "@interfaces";
import { navigate } from "gatsby";
import { useForm } from "@hooks";

type Props = {
  setPage?: Dispatch<SetStateAction<number>>;
  // recibe el estado de fixedIncomes de tipo array con diccionarios
  fixedIncomes: { name: string; amount: number }[];
  // fixedIncomes: Route[];
  // recibe la función setFixedIncomes para actualizar el estado
  setFixedIncomes: Dispatch<SetStateAction<{ name: string; amount: number }[]>>;
  // setFixedIncomes: Dispatch<SetStateAction<{}[]>>;
};

const Simulator: React.FC<Props> = ({
  setPage,
  fixedIncomes,
  setFixedIncomes,
}) => {
  const classes = useStyles();
  const [temp, setTemp] = useState({ name: "", amount: 0 });
  // const [incomes, setIncomes] = useState([{ name: "sueldo", amount: 0 }]);
  const [addingIncome, setAddingIncome] = useState<boolean>(false);
  const [variableIncome, setVariableIncome] = useState<boolean>(false);
  const [form, , , handleChange] = useForm({});

  const isMobile = useMediaQuery("(max-width: 1240px)");

  const updateIncome = useCallback(
    (index, amount) => {
      const newIncome = fixedIncomes[index];
      newIncome.amount = amount;
      setFixedIncomes((prev) => [
        ...prev.slice(0, index),
        newIncome,
        ...prev.slice(index + 1, fixedIncomes.length),
      ]);
    },
    [setFixedIncomes, fixedIncomes]
  );

  const createNewIncome = async () => {
    await setFixedIncomes((prev) => [...prev, temp]);
    await setTemp({ name: "", amount: 0 });
    setAddingIncome(false);
  };

  const addIncome = useCallback(
    (type, value) => {
      if (type === "name") {
        setTemp({ name: value, amount: temp.amount });
      } else {
        setTemp({ name: temp.name, amount: value });
      }
    },
    [setTemp, temp]
  );

  const handeClick = () => {
    if (variableIncome) {
      // set all the fixed incomes to 0
      setFixedIncomes((prev) => {
        const newIncomes = prev.map((income) => {
          income.amount = 0;
          return income;
        });
        return newIncomes;
      });
    }
    if (setPage) {
      setPage(2);
    } else navigate(Route.budget2_2);
  };

  return (
    <>
      <div className={classes.container}>
        <span className="simulatorTitle">Simulador</span>
        <br />
        <span className="helpText">
          Te ayudaremos a armar tu presupuesto. Identifica tu(s) ingresos(s)
          fijo(s):
        </span>
        <div className={classes.centerSection}>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<CircleUnchecked />}
                  checkedIcon={<RadioButtonCheckedIcon />}
                  onChange={() => setVariableIncome(!variableIncome)}
                />
              }
              className={classes.greyText}
              label="Mi ingreso es completamente variable"
            />
          </FormControl>
          <div className={classes.userIncomes}>
            {fixedIncomes.map((income, index) => (
              <div className={classes.incomeDetail}>
                <img src={comisiones} className={classes.incomeImage} />
                <div className="incomeValues">
                  <div className="incomeName">{income.name}</div>
                  <div className="incomeInput">
                    <NumericInput
                      name="incomes"
                      disabled={variableIncome}
                      label=""
                      value={income.amount ?? 0}
                      onChange={({ target: { value } }) =>
                        updateIncome(index, value as number)
                      }
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <form>
            {addingIncome ? (
              <div className={classes.formSectiom}>
                <div className="centrator">
                  <NameInput
                    className={classes.formControlSimulator}
                    value={temp.name}
                    disabled={variableIncome}
                    onChange={({ target: { value } }) =>
                      addIncome("name", value as string)
                    }
                  />
                  <div className="axis">
                    <NumericInput
                      className={classes.formControlSimulator}
                      name="incomes"
                      label="Monto"
                      disabled={variableIncome}
                      value={temp.amount ?? 0}
                      onChange={({ target: { value } }) =>
                        addIncome("amount", value as number)
                      }
                    />
                    <IconButton onClick={createNewIncome}>
                      <AddRoundedIcon />
                    </IconButton>
                  </div>
                </div>

                <br />
              </div>
            ) : (
              <></>
            )}
            <span className={classes.incomeHelperText}>
              * solo incluir el sueldo base o 100% constante
            </span>
            <div className={classes.SimulatorBottomSection}>
              <div>
                <IconButton
                  onClick={() => setAddingIncome(!addingIncome)}
                  disabled={variableIncome}
                >
                  <AddRoundedIcon className={classes.addIcon} />
                </IconButton>
                <span className="Agregar-otro">Agregar otro</span>
              </div>
              {!isMobile && (
                <ButtonPrimary
                  className={classes.buttonPrimary}
                  onClick={handeClick}
                  text="Siguiente"
                  secondaryVersion
                />
              )}
            </div>
          </form>
        </div>
      </div>
      {isMobile && (
        <ButtonPrimary
          className={classes.buttonPrimary}
          onClick={handeClick}
          text="Siguiente"
          secondaryVersion
        />
      )}
    </>
  );
};

export default Simulator;
