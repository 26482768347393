import React, { useState, useCallback, useEffect, useContext } from "react";
import {
  CurrencyFilter,
  TermFilter,
  RedeemableFilter,
  ManagerFilter,
  FundTypeFilter,
  RiskFilter,
  CategoryFilter,
  AmountFilter,
  AvailableToInvestFilter,
} from "./IndividualFilters";
import { InvestmentCategories } from "@components";
import {
  ActiveFilters,
  FiltersOptions,
  FundType,
  TRANSLATE_FUND_TYPE,
} from "@interfaces";
import { useAGFOptions } from "@apollo";
import { makeStyles } from "@material-ui/core";

const ALL_FIELDS = [
  "manager",
  "fundType",
  "category",
  "amount",
  "currency",
  "term",
  "redeemable",
  "risk",
  "availableToInvest",
];

type Props = {
  className?: string;
  tempFilters: ActiveFilters;
  setTempFilters: React.Dispatch<React.SetStateAction<ActiveFilters>>;
  riskSelectorName: string;
  include?: string[];
  exclude?: string[];
  initialExpandedFilter?: number;
};

const MobileFilters: React.FC<Props> = ({
  className,
  tempFilters,
  setTempFilters,
  riskSelectorName,
  include = ALL_FIELDS,
  exclude,
  initialExpandedFilter,
}) => {
  const classes = useStyles();
  const [expandedFilter, setExpandedFilter] = React.useState<number | null>(
    initialExpandedFilter ?? null,
  );
  const [allOptions, setAllOptions] = useState<FiltersOptions>({
    manager: [],
    assetType: [],
    fundType: [
      {
        value: FundType.MUTUAL,
        name: TRANSLATE_FUND_TYPE[FundType.MUTUAL],
        count: 2,
      },
      {
        value: FundType.INVESTMENT,
        name: TRANSLATE_FUND_TYPE[FundType.INVESTMENT],
        count: 2,
      },
      {
        value: FundType.INTERNATIONAL,
        name: TRANSLATE_FUND_TYPE[FundType.INTERNATIONAL],
        count: 2,
      },
      { value: "APV", name: "APV", count: 2 },
      {
        value: FundType.ETF,
        name: TRANSLATE_FUND_TYPE[FundType.ETF],
        count: 2,
      },
      {
        value: FundType.STOCKS,
        name: TRANSLATE_FUND_TYPE[FundType.STOCKS],
        count: 2,
      },
    ],
    category: InvestmentCategories.map(({ icon, text }) => ({
      value: text,
      image: icon,
    })),
    currency: [
      { value: "USD", count: 2 },
      { value: "CLP", count: 3 },
      { value: "EURO", count: 4 },
      // { value: "UF", count: 5 },
    ],
    term: [
      { value: "Menos de 1 año", count: 2 },
      { value: "Entre 1 y 3 años", count: 3 },
      { value: "Entre 3 y 5 años", count: 3 },
      { value: "Más de 5 años", count: 5 },
    ],
  });

  const display = Object.assign(
    {},
    ...ALL_FIELDS.map(field => ({
      [field]: include.includes(field) && !exclude?.includes(field),
    })),
  ) as Record<string, boolean>;

  const { agfs } = useAGFOptions();

  useEffect(() => {
    setAllOptions(prev => ({
      ...prev,
      manager: agfs
        .map(m => ({
          value: m.name,
          showingFirst: m.showingFirst,
        }))
        .sort((a, b) => {
          if (a.value < b.value) return -1;
          if (a.value > b.value) return 1;
          return 0;
        }),
    }));
  }, [agfs, setAllOptions]);

  const handleExpansion =
    (panel: number) => (event: any, isExpanded: boolean) => {
      setExpandedFilter(isExpanded ? panel : null);
    };

  const handleChange = useCallback(
    ({ target: { name, checked } }) => {
      const [type, property, value] = name.split("-") as [
        string,
        (
          | "currency"
          | "term"
          | "manager"
          | "fundType"
          | "category"
          | "availableToInvest"
        ),
        string,
      ];

      if (type === "switch") {
        setTempFilters((prev: ActiveFilters) => ({
          ...prev,
          [property]: checked ? allOptions[property].map(opt => opt.value) : [],
        }));
      }
      if (type === "checkbox") {
        setTempFilters((prev: ActiveFilters) => ({
          ...prev,
          [property]: checked
            ? prev[property]?.concat([value])
            : prev[property]?.filter((opt: string) => opt !== value),
        }));
      }
      if (type === "toggle") {
        setTempFilters((prev: ActiveFilters) => ({
          ...prev,
          [property]: checked,
          userSelectedAvailable:
            property === "availableToInvest"
              ? true
              : prev.userSelectedAvailable,
        }));
      }
      if (type === "card") {
        setTempFilters((prev: ActiveFilters) => ({
          ...prev,
          [property]: prev[property]?.includes(value)
            ? prev[property]?.filter((opt: string) => opt !== value)
            : prev[property]?.concat([value]),
        }));
      }
    },
    [setTempFilters, allOptions],
  );
  const handleAmountChange = useCallback(
    (_, newValue: any) => {
      if (
        tempFilters.amount &&
        newValue[0] + newValue[1] !==
          tempFilters.amount[0] + tempFilters.amount[1]
      ) {
        setTempFilters((prev: ActiveFilters) => ({
          ...prev,
          amount: newValue as number[],
        }));
      }
    },
    [tempFilters.amount, setTempFilters],
  );
  const handleRiskChange = useCallback(
    (_, newValue: number | null) => {
      setTempFilters((prev: ActiveFilters) => ({
        ...prev,
        risk: newValue,
      }));
    },
    [setTempFilters],
  );

  return (
    <div className={`${className || ""} ${classes.filtersContainer}`}>
      {display.manager && (
        <ManagerFilter
          filters={tempFilters}
          options={allOptions.manager}
          expanded={expandedFilter === 1}
          handleExpansion={handleExpansion(1)}
          handleChange={handleChange}
        />
      )}
      {display.category && (
        <CategoryFilter
          filters={tempFilters}
          options={allOptions.category}
          expanded={expandedFilter === 2}
          handleExpansion={handleExpansion(2)}
          handleChange={handleChange}
        />
      )}
      {display.fundType && (
        <FundTypeFilter
          filters={tempFilters}
          options={allOptions.fundType}
          expanded={expandedFilter === 3}
          handleExpansion={handleExpansion(3)}
          handleChange={handleChange}
        />
      )}
      {display.risk && (
        <RiskFilter
          name={riskSelectorName}
          value={tempFilters.risk ?? null}
          handleChange={handleRiskChange}
          expanded={expandedFilter === 4}
          handleExpansion={handleExpansion(4)}
        />
      )}
      {display.term && (
        <TermFilter
          filters={tempFilters}
          options={allOptions.term}
          expanded={expandedFilter === 5}
          handleExpansion={handleExpansion(5)}
          handleChange={handleChange}
        />
      )}
      {display.amount && (
        <AmountFilter
          title="Monto a invertir"
          property="amount"
          filters={tempFilters}
          options={[]}
          expanded={expandedFilter === 6}
          handleExpansion={handleExpansion(6)}
          handleChange={handleAmountChange}
        />
      )}
      {display.currency && (
        <CurrencyFilter
          filters={tempFilters}
          options={allOptions.currency}
          expanded={expandedFilter === 7}
          handleExpansion={handleExpansion(7)}
          handleChange={handleChange}
        />
      )}
      {display.redeemable && (
        <RedeemableFilter filters={tempFilters} handleChange={handleChange} />
      )}
      {display.availableToInvest && (
        <AvailableToInvestFilter
          filters={tempFilters}
          handleChange={handleChange}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  filtersContainer: {
    width: "100%",
  },
}));

export { MobileFilters };
