import React from "react";
import { makeStyles } from "@material-ui/core";
import { ButtonText } from "..";

const Progress: React.FC = () => {
  const classes = useStyles();

  return (
    <section className={classes.container}>
      <div className={classes.cardContainer}>
        <div className={classes.titleCardContainer}>
          <span className={classes.txtPrincipal}>Progreso</span>
          <span className={classes.txtSecondary}>
            Ve los beneficios que has conseguido y descubre nuevas misiones
          </span>
        </div>

        <div className={classes.valuesContainer}>
          <div className={classes.imageCard}>
            <div className={classes.img} />
          </div>
          <div className={classes.card}>
            <span>Misiones disponibles</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Progress;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    width: "80%",
    margin: "0 auto",
    height: "34rem",
  },
  cardContainer: {
    background: "rgba(255,255,255,0.2)",
    width: "80%",
    maxWidth: 1200,
    height: 320,
    boxShadow: "20px 12px 50px 0 rgba(0, 0, 0, 0.2)",
    borderRadius: 30,
    marginBottom: 100,
    marginTop: "6rem",
  },
  titleCardContainer: {
    display: "flex",
    alignItems: "center",
    padding: "50px 0 20px 7rem",
  },
  txtPrincipal: {
    fontFamily: theme.typography.fontFamily,
    fontSize: "2rem",
    fontWeight: "bold",
    borderRight: "1px solid #6b6b6b",
    padding: 10,
  },
  txtSecondary: {
    color: theme.palette.text.secondary,
    fontSize: "1.25rem",
    fontFamily: "Montserrat",
    fontWeight: "normal",
    padding: 10,
    display: "block",
  },
  valuesContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    gap: "5rem",
  },
  imageCard: {
    position: "relative",
    paddingRight: "2rem",
    "& span": {
      color: "white",
    },
  },
  img: {
    width: 268,
    height: 400,
    background: "white",
    borderRadius: 20.9,
  },
  card: {
    width: 470,
    height: 385,
    border: "solid 1.6px #8352fd",
    padding: "33px 57px",
    borderRadius: 20.9,
    "& span": {
      display: "block",
      fontFamily: theme.typography.fontFamily,
      fontSize: "1.25rem",
    },
  },
}));
