import { gql, useQuery } from "@apollo/client";
import { FinerioAccount } from "@interfaces";

export const GET_FINERIO_ACCOUNTS = gql`
  query FinerioAccounts {
    finerioAccounts {
      accountBalance
      accountCurrency
      finerioAccountId
      accountName
      accountNumber
      accountType
      bankId
      createdAt
      credit
      debit
      enabled
      id
      updatedAt
      username
      userId
    }
  }
`;

interface QueryResult {
  finerioAccounts: FinerioAccount[];
}

interface HookReturn {
    finerioAccounts: FinerioAccount[];
    finerioAccountsLoading: boolean;
    refetchFinerioAccounts: () => void;
}

export const getFinerioAccounts = (): HookReturn => {
  const { data, loading, refetch } =
    useQuery<QueryResult>(GET_FINERIO_ACCOUNTS);

    return {
        finerioAccounts: data?.finerioAccounts ?? [],
        finerioAccountsLoading: loading,
        refetchFinerioAccounts: refetch
    };
};
