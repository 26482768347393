import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    width: "100%",
    height: 65,
    boxShadow: "4px 4px 15px 5px rgba(0, 0, 0, 0.25)",
    position: "relative",
    display: "flex",
    alignItems: "center",
    fontFamily: "Montserrat",
    justifyContent: "space-between",
    margin: "15px 0",
    cursor: "pointer",
    borderRadius: "12px",
    [theme.breakpoints.down(1240)]: {
      display: "grid",
      height: "5.2rem",
      padding: "15px 0",
    },
  },

  left: {
    display: "flex",
    alignItems: "center",
    "& img": {
      position: "absolute",
      top: -10,
      zIndex: 2,
    },
    "& .number": {
      position: "absolute",
      width: 30,
      left: 27,
      top: 19,
      textAlign: "center",
      zIndex: 5,
      fontWeight: "bold",
      color: "#4619b6",
    },
    "& .text": {
      marginLeft: 90,
      fontFamily: " Noto Sans",
      maxWidth: 250,
      fontSize: 14,
      [theme.breakpoints.down(1240)]: {
        maxWidth: "100%",
      },
    },
  },
  right: {
    display: "flex",
    alignItems: "center",
    fontFamily: theme.typography.fontFamily,
    gap: "1rem",
    "&> div": {
      display: "flex",
      marginRight: 10,
      alignItems: "center",
      fontSize: 14,
      color: "#6B6B6B",
    },
    [theme.breakpoints.down(1240)]: {
      marginRight: 0,
      marginLeft: "5.5rem",
    },
    "& .coin": {
      marginRight: 10,
      [theme.breakpoints.down(1240)]: {
        width: "0.6rem",
      },
    },
    "& .timerGreen": {
      marginRight: 10,
      [theme.breakpoints.down(1240)]: {
        width: "1.1rem",
      },
    },
  },
  enabled: {
    opacity: 0.5,
    filter: "grayscale(100%)",
    cursor: "auto",
  },
}));
