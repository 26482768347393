import { getIdentityFileSignedUrl, saveUserFile } from "@apollo";
import { ApolloClient } from "@apollo/client";
import { FileSpecificType } from "@interfaces";

import axios from "axios";

export const uploadIdentityFile = async (
  file: File,
  fileType: FileSpecificType,
  client: ApolloClient<object>
): Promise<boolean> => {
  try {
    // Get secure URL
    const data = await getIdentityFileSignedUrl(fileType, file.name, client);
    if (!data) return false;

    // Build request
    const formData = new FormData();
    formData.append("acl", data.fields.acl);
    formData.append("key", data.fields.key);
    formData.append("policy", data.fields.Policy);
    // formData.append("success_action_status", successActionStatus)
    formData.append("x-amz-algorithm", data.fields["X-Amz-Algorithm"]);
    formData.append("x-amz-credential", data.fields["X-Amz-Credential"]);
    formData.append("x-amz-date", data.fields["X-Amz-Date"]);
    formData.append("x-amz-signature", data.fields["X-Amz-Signature"]);
    formData.append("file", file);
    formData.append("Cache-Control", "no-cache");

    // Upload file
    await axios.post(data.url, formData);

    // Save URL on DB
    const fullUrl = `${data.url}/${data.fields.key}`;
    await saveUserFile(fileType, fullUrl, client);

    return true;
  } catch (e) {
    return false;
  }
};
