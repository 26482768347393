import { ApolloClient, gql } from "@apollo/client";
import { GoalForm, Goal } from "../../interfaces";
import { GET_GOALS_QUERY } from "./useGoals";

export * from "./useGoals";

const CREATE_GOAL = gql`
  mutation CreateGoal($params: CreateGoalParams) {
    createGoal(params: $params) {
      id
      name
      goal
    }
  }
`;

type CreateGoalParams = { params: GoalForm };

export async function createGoal(
  payload: CreateGoalParams["params"],
  client: ApolloClient<object>,
): Promise<Goal | null> {
  const result = await client.mutate<Goal, CreateGoalParams>({
    mutation: CREATE_GOAL,
    variables: { params: payload },
    refetchQueries: [
      {
        query: GET_GOALS_QUERY,
      },
    ],
  });
  return result.data ?? null;
}

const UPDATE_GOAL = gql`
  mutation UpdateGoal($id: ID!, $data: UpdateGoalParams) {
    updateGoal(id: $id, data: $data) {
      name
      goal
    }
  }
`;

type UpdateGoalParams = {
  id: number;
  data: Partial<GoalForm>;
};

export async function updateGoal(
  goalId: number,
  payload: UpdateGoalParams["data"],
  client: ApolloClient<object>,
): Promise<Goal | null> {
  const result = await client.mutate<Goal, UpdateGoalParams>({
    mutation: UPDATE_GOAL,
    variables: { id: goalId, data: payload },
    refetchQueries: [
      {
        query: GET_GOALS_QUERY,
      },
    ],
  });
  return result.data ?? null;
}

const DELETE_GOAL = gql`
  mutation DeleteGoal($id: ID!) {
    deleteGoal(id: $id)
  }
`;

type DeleteGoalParams = {
  id: number;
};

export async function deleteGoal(
  id: number,
  client: ApolloClient<object>,
): Promise<boolean> {
  const result = await client.mutate<boolean, DeleteGoalParams>({
    mutation: DELETE_GOAL,
    variables: { id },
    refetchQueries: [
      {
        query: GET_GOALS_QUERY,
      },
    ],
  });
  return !!result.data;
}
