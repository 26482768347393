import React, { Dispatch, SetStateAction } from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { Footer, Confetti } from "@components";
import { numberMission, home, returnIcon } from "images/onBoarding";
import { navigate } from "gatsby";
import { Route } from "@interfaces";

interface LayoutMissionProps {
  children: React.ReactNode;
  className?: string;
  styleFooter?: string;
  title: string;
  subtitle?: string;
  number: number;
  secondLayoutForShortcuts?: boolean;
  redirectLink?: string;
  numberAdjust?: number;
  setPage?: Dispatch<SetStateAction<number>>;
  pageNumber?: number;
  confettiFire: boolean;
  titleAdjust?: boolean;
  onHouseClick?: () => void;
}

export const LayoutMissionDesktop: React.FC<LayoutMissionProps> = ({
  className,
  children,
  styleFooter,
  title,
  subtitle,
  number,
  secondLayoutForShortcuts,
  redirectLink,
  numberAdjust, // valor en REM para ajustar el margin left
  pageNumber,
  setPage,
  confettiFire,
  titleAdjust,
  onHouseClick,
}) => {
  const classes = useStyles();
  return (
    <div className={`${className || ""} ${classes.container}`}>
      <Confetti confettiFire={confettiFire} />
      <section>
        <div className={classes.headerLayout}>
          <div className={classes.numberMissionContainer}>
            <img src={numberMission} />
            {!numberAdjust ? (
              <span className={classes.numberMission}>{number}</span>
            ) : (
              <span
                className={`${classes.numberMission}`}
                style={{ marginLeft: `${numberAdjust}rem` }}
              >
                {number}
              </span>
            )}
          </div>
          <div className={classes.txts}>
            {titleAdjust ? (
              <span className={`${classes.shortTitle} ${classes.title}`}>
                {title}
              </span>
            ) : (
              <span className={classes.title}>{title}</span>
            )}
            <span className={classes.subtitle}>{subtitle}</span>
          </div>
        </div>
      </section>
      <section className={classes.containerData}>
        <div>
          <div className={classes.return}>
            <img
              onClick={
                onHouseClick ? onHouseClick : () => navigate(Route.rokipedia)
              }
              src={home}
            />
            {secondLayoutForShortcuts ? "Salir" : "Volver"}
          </div>
          {secondLayoutForShortcuts && (
            <div className={classes.return}>
              <img
                onClick={() => {
                  if (redirectLink == undefined) {
                    if (setPage) setPage(pageNumber);
                  } else {
                    navigate(`${redirectLink}`);
                  }
                }}
                src={returnIcon}
              />
              Volver
            </div>
          )}
        </div>
        <div className={classes.children}>{children}</div>
      </section>

      <Footer className={`${classes.footer} ${styleFooter}`} />
    </div>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  footer: {
    [theme.breakpoints.down(1100)]: {
      display: "none",
    },
  },
  title: {
    fontFamily: "Montserrat",
    fontSize: "2.25rem",
    fontWeight: "bold",
    color: "#393e44",
    display: "block",
  },
  shortTitle: {
    width: "30rem",
  },
  subtitle: {
    fontFamily: "Montserrat",
    fontSize: "1.25rem",
    fontWeight: "normal",
    display: "block",
    color: theme.palette.text.secondary,
  },
  container: {
    overflowX: "hidden",
    background: "#F4F3F3",
  },
  children: {
    marginLeft: 15,
    minHeight: 650,
  },
  headerLayout: {
    display: "flex",
    gap: "2rem",
    alignItems: "center",
    paddingLeft: 50,
    paddingTop: 20,
    "& img": {
      width: 140,
      height: 140,
    },
  },
  txts: {
    paddingTop: "1rem",
    "& span": {
      display: "block",
    },
  },
  numberMissionContainer: {
    position: "relative",
  },
  numberMission: {
    position: "absolute",
    width: 140,
    height: 140,
    alignItems: "center",
    zIndex: 99,
    color: "#4619b6",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 33,
    top: 0,
    display: "flex",
    justifyContent: "center",
  },
  numberAdjusted: {
    marginLeft: "0.3125rem",
  },
  containerData: {
    display: "flex",
    paddingLeft: 50,
    "& > img": {
      cursor: "pointer",
    },
  },
  return: {
    minWidth: 140,
    paddingTop: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontFamily: "Montserrat",
    color: theme.palette.text.secondary,
    "& img": {
      width: 55,
      marginLeft: 8,
      cursor: "pointer",
    },
  },
}));
