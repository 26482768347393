import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "nowrap",
    color:theme.palette.text.secondary,
    justifyContent: "space-evenly",
    maxWidth: 1140,
    margin: "0 auto 10px",
    [theme.breakpoints.down(1300)]: {
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      margin: "39px 35px 10px",
      justifyContent: "space-evenly",
      flexDirection: "column",
      alignContent: "center",
    },
  },
  textStyle: {
    fontWeight: "bold",
  },
  paragraph: {
    width: 503,
    lineHeight: 2.14,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down(1300)]: {
      width: "auto",
    },
  },
  text: {
    width: "auto",
    fontSize: 16,
    wordWrap: "break-word",
  },
  p2: {},
}));
