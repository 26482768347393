import React from "react";
import {
  NumericInput,
  InvestFrequencySelector,
  InvestPeriodRangeSelector,
} from "@components";
import { GoalForm, OnChangeType } from "@interfaces";
import "./Step2.scss";

interface Step2Props {
  stepTitle: string;
  form: GoalForm;
  handleChange: OnChangeType;
  editGoalName: () => void;
}
export const Step2: React.FC<Step2Props> = ({
  stepTitle,
  form,
  handleChange,
}) => {
  return (
    <div className="step goal-step-2">
      <div className="content">
        <span className="title">{stepTitle}</span>
        <div className="goal-input-container">
          <span className="label">
            Quiero lograr el monto de
            <NumericInput
              name="goal"
              /* label="Meta" */
              value={form.goal}
              onChange={handleChange}
            />
          </span>
          <span className="label">
            Quiero lograrlo en
            <InvestPeriodRangeSelector
              className="range-selector"
              value={form.period}
              onChange={handleChange}
              hideLabel
            />
          </span>
          <span className="label">
            Invertiré
            <InvestFrequencySelector
              name="investmentFrequency"
              hideLabel={true}
              value={form.investmentFrequency}
              onChange={handleChange}
            />
          </span>
        </div>
      </div>
    </div>
  );
};
