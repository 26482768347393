const GROUP_764 =
  "https://rokinapp-images.s3.amazonaws.com/onBording/Group+764.svg";
// const SLIDE_1 =
//   "https://rokinapp-images.s3.amazonaws.com/onBording/Slide+1_image_v2.png";
const SLIDE_1 = "https://rokinapp-images.s3.amazonaws.com/onBording/Launch.svg";
// const SLIDE_2 =
//   "https://rokinapp-images.s3.amazonaws.com/onBording/Slide+2_image_v1.png";
// const SLIDE_3 =
//   "https://rokinapp-images.s3.amazonaws.com/onBording/Slide+3_image_V1.png";
const SLIDE_2 =
  "https://rokinapp-images.s3.amazonaws.com/onBording/pagarDeMas.svg";
const SLIDE_3 =
  "https://rokinapp-images.s3.amazonaws.com/onBording/aTuMedida.svg";
const BILL_ICON_PRODUCTTOUR =
  "https://rokinapp-images.s3.amazonaws.com/onBording/bill_icon.svg";
const DEBT_ICON =
  "https://rokinapp-images.s3.amazonaws.com/onBording/deudas_icon.svg";
const INVEST_ICON =
  "https://rokinapp-images.s3.amazonaws.com/onBording/invest_icon.svg";
const RETIREMENT_ICON =
  "https://rokinapp-images.s3.amazonaws.com/onBording/retirement_icon.svg";
const SAVING_ICON =
  "https://rokinapp-images.s3.amazonaws.com/onBording/saving_icon.svg";
const SEARCH_ICON =
  "https://rokinapp-images.s3.amazonaws.com/onBording/search_icon.svg";
const BUDGET_ICON =
  "https://rokinapp-images.s3.amazonaws.com/onBording/budget_icon.svg";

// const PHOTO_CARLOS =
//   "https://rokinapp-images.s3.amazonaws.com/onBording/1597361967598+1%402x.png";
// const MAIL_CARLOS =
//   "https://rokinapp-images.s3.amazonaws.com/onBording/ckrzyowt30ferhdg01kyyvh52-01-messaging-brand-mark+1%403x.png";
const PERSONAL_TRAINERS =
  "https://rokinapp-images.s3.amazonaws.com/onBording/personalTrainers_img.png";
// with color

const BILL_ICON_COLOR =
  "https://rokinapp-images.s3.amazonaws.com/onBording/iconsWithColor/016-bill+1.svg";
const SEARCH_ICON_COLOR =
  "https://rokinapp-images.s3.amazonaws.com/onBording/iconsWithColor/018-search+1.svg";
const BUDGET_ICON_COLOR =
  "https://rokinapp-images.s3.amazonaws.com/onBording/iconsWithColor/budget_icon.svg";
const PROFITS_ICON_COLOR =
  "https://rokinapp-images.s3.amazonaws.com/onBording/iconsWithColor/022-profits+1.svg";
const UMBRELLA_ICON_COLOR =
  "https://rokinapp-images.s3.amazonaws.com/onBording/iconsWithColor/025-umbrella+1.svg";
const SAFEBOX_ICON_COLOR =
  "https://rokinapp-images.s3.amazonaws.com/onBording/iconsWithColor/047-safebox+1.svg";
const PAYMENT_ICON_COLOR =
  "https://rokinapp-images.s3.amazonaws.com/onBording/iconsWithColor/049-payment+1.svg";

const ROKIN_PLUS_LOGO_Op2 =
  "https://rokinapp-images.s3.amazonaws.com/onBording/rokinPlus_logoOp2.svg";
const ROKIN_PLUS_LOGO =
  "https://rokinapp-images.s3.amazonaws.com/onBording/rokinPlus_logo.svg";
const ROKIN_PLUS_LOGO_1 =
  "https://rokinapp-images.s3.amazonaws.com/onBording/rokinPlus_logo_1.svg";
const ADMINISTRAR_ICON =
  "https://rokinapp-images.s3.amazonaws.com/onBording/administrar_icon.svg";
const PRESUPUESTO_ICON =
  "https://rokinapp-images.s3.amazonaws.com/onBording/presupuestoRecomendaciones_icon.svg";
const SYNC_ICON =
  "https://rokinapp-images.s3.amazonaws.com/onBording/sync_icon.svg";
const PERSON_ICON =
  "https://storage.googleapis.com/rokinapp-images/ProductTour/asesoria_icon.svg";
const GOAL_ICON =
  "https://storage.googleapis.com/rokinapp-images/ProductTour/goal_icon.svg";
const SALUDABLE_ICON =
  "https://storage.googleapis.com/rokinapp-images/ProductTour/saludable_icon.svg";
const AT_RISK_ICON =
  "https://storage.googleapis.com/rokinapp-images/ProductTour/atRisk.svg";
const HAS_POTENTIAL =
  "https://storage.googleapis.com/rokinapp-images/ProductTour/checkIconPT.svg";
const IS_CRITICAL =
  "https://storage.googleapis.com/rokinapp-images/ProductTour/critical.svg";
const BILL_ICON_GREY =
  "https://storage.googleapis.com/rokinapp-images/ProductTour/TarjetasIntereses/Grey/bill_icon.svg";
const CREDITCARDS_ICON_GREY =
  "https://storage.googleapis.com/rokinapp-images/ProductTour/TarjetasIntereses/Grey/creditCards_icon.svg";
const GOAL_ICON_GREY =
  "https://storage.googleapis.com/rokinapp-images/ProductTour/TarjetasIntereses/Grey/goal_icon.svg";
const INVOICEDOLLAR_ICON_GREY =
  "https://storage.googleapis.com/rokinapp-images/ProductTour/TarjetasIntereses/Grey/invoiceDollar_icon.svg";
const PIECHART_ICON_GREY =
  "https://storage.googleapis.com/rokinapp-images/ProductTour/TarjetasIntereses/Grey/pieChart_icon.svg";
const SAFEBOX_ICON_GREY =
  "https://storage.googleapis.com/rokinapp-images/ProductTour/TarjetasIntereses/Grey/safebox_icon.svg";
const SEARCHMONEY_ICON_GREY =
  "https://storage.googleapis.com/rokinapp-images/ProductTour/TarjetasIntereses/Grey/searchMoney_icon.svg";
const TREND_UP_ICON_GREY =
  "https://storage.googleapis.com/rokinapp-images/ProductTour/TarjetasIntereses/Grey/trendUp_icon.svg";
const BILL_ICON_BLUE =
  "https://storage.googleapis.com/rokinapp-images/ProductTour/TarjetasIntereses/Blue/bill_icon.svg";
const CREDITCARDS_ICON_BLUE =
  "https://storage.googleapis.com/rokinapp-images/ProductTour/TarjetasIntereses/Blue/creditCards_icon.svg";
const GOAL_ICON_BLUE =
  "https://storage.googleapis.com/rokinapp-images/ProductTour/TarjetasIntereses/Blue/goal_icon.svg";
const INVOICEDOLLAR_ICON_BLUE =
  "https://storage.googleapis.com/rokinapp-images/ProductTour/TarjetasIntereses/Blue/invoiceDollar_icon.svg";
const PIECHART_ICON_BLUE =
  "https://storage.googleapis.com/rokinapp-images/ProductTour/TarjetasIntereses/Blue/pieChart_icon.svg";
const SAFEBOX_ICON_BLUE =
  "https://storage.googleapis.com/rokinapp-images/ProductTour/TarjetasIntereses/Blue/safebox_icon.svg";
const SEARCHMONEY_ICON_BLUE =
  "https://storage.googleapis.com/rokinapp-images/ProductTour/TarjetasIntereses/Blue/searchMoney_icon.svg";
const TREND_UP_ICON_BLUE =
  "https://storage.googleapis.com/rokinapp-images/ProductTour/TarjetasIntereses/Blue/trendUp_icon.svg";
const CHECK_IMAGE =
  "https://storage.googleapis.com/rokinapp-images/ProductTour/check_image.png";
const CALENDAR_ICON =
  "https://storage.googleapis.com/rokinapp-images/ProductTour/calendar01_icon.svg";
const SETTINGS_ICON =
  "https://storage.googleapis.com/rokinapp-images/ProductTour/settings_icon.svg";
export {
  BUDGET_ICON,
  BUDGET_ICON_COLOR,
  SEARCH_ICON_COLOR,
  BILL_ICON_COLOR,
  PAYMENT_ICON_COLOR,
  SAFEBOX_ICON_COLOR,
  UMBRELLA_ICON_COLOR,
  PROFITS_ICON_COLOR,
  GROUP_764,
  SLIDE_1,
  SLIDE_2,
  SLIDE_3,
  BILL_ICON_PRODUCTTOUR,
  DEBT_ICON,
  INVEST_ICON,
  RETIREMENT_ICON,
  SAVING_ICON,
  SEARCH_ICON,
  PERSONAL_TRAINERS,
  ROKIN_PLUS_LOGO_Op2,
  ROKIN_PLUS_LOGO,
  ADMINISTRAR_ICON,
  PRESUPUESTO_ICON,
  SYNC_ICON,
  ROKIN_PLUS_LOGO_1,
  PERSON_ICON,
  GOAL_ICON,
  SALUDABLE_ICON,
  TREND_UP_ICON_BLUE,
  SEARCHMONEY_ICON_BLUE,
  SAFEBOX_ICON_BLUE,
  PIECHART_ICON_BLUE,
  INVOICEDOLLAR_ICON_BLUE,
  GOAL_ICON_BLUE,
  CREDITCARDS_ICON_BLUE,
  BILL_ICON_BLUE,
  TREND_UP_ICON_GREY,
  SEARCHMONEY_ICON_GREY,
  SAFEBOX_ICON_GREY,
  PIECHART_ICON_GREY,
  INVOICEDOLLAR_ICON_GREY,
  GOAL_ICON_GREY,
  CREDITCARDS_ICON_GREY,
  BILL_ICON_GREY,
  CHECK_IMAGE,
  CALENDAR_ICON,
  SETTINGS_ICON,
  AT_RISK_ICON,
  HAS_POTENTIAL,
  IS_CRITICAL,
};
