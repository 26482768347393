import React, { useCallback, useContext, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import {
  FormMovement,
  InvestmentType,
  MovementType,
  ProfileDialogProps,
} from "@interfaces";
import { IconButton } from "@material-ui/core";
import { DialogContent } from "@material-ui/core";
import { DialogTitle } from "@material-ui/core";
import { DialogActions } from "@material-ui/core";
import { NumericInput, Select, DateInput } from "@components";
import { InvestmentsContext } from "@context";
import { ButtonOutlined, ButtonPrimary } from "@components/Reusables";
import { makeStyles } from "@material-ui/core";

const EMPTY_ENTRY = (serieId: number) => ({
  serieId,
  type: MovementType.DEPOSIT,
  date: null,
  amount: null,
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AddInvestmentMovementDialogProps extends ProfileDialogProps {
  serieId?: number;
}

export const AddInvestmentMovementDialog: React.FC<AddInvestmentMovementDialogProps> =
  ({ className, open, onClose, serieId }) => {
    // Calculate media to display dialog on fullScreen
    const [isMobile, setIsMobile] = useState(false);
    const classes = useStyles();
    useEffect(() => {
      if (window.matchMedia("(max-width: 1080px)").matches) setIsMobile(true);
    }, []);

    const { createNewMovements } = useContext(InvestmentsContext);
    const [movements, setMovements] = useState<FormMovement[]>(
      serieId ? [{ ...EMPTY_ENTRY(serieId) }] : [],
    );

    useEffect(() => {
      if (serieId)
        setMovements(prev =>
          prev.length ? prev : [{ ...EMPTY_ENTRY(serieId) }],
        );
    }, [serieId]);

    const addRow = useCallback(
      () =>
        setMovements(prev =>
          serieId ? [...prev, EMPTY_ENTRY(serieId)] : prev,
        ),
      [serieId, setMovements],
    );
    const updateRow = useCallback(
      (index: number, payload: Partial<FormMovement>) =>
        setMovements(prev =>
          prev.map((inv, i) =>
            i === index
              ? {
                  ...inv,
                  ...payload,
                }
              : inv,
          ),
        ),
      [setMovements],
    );
    const deleteRow = useCallback(
      (index: number) =>
        setMovements(prev =>
          prev.slice(0, index).concat(prev.slice(index + 1)),
        ),
      [setMovements],
    );

    const resetAndClose = useCallback(() => {
      setMovements(serieId ? [{ ...EMPTY_ENTRY(serieId) }] : []);
      onClose();
    }, [serieId, setMovements, onClose]);

    const ready = useCallback(() => {
      createNewMovements(movements, InvestmentType.MONITOR);
      resetAndClose();
    }, [movements, createNewMovements, resetAndClose]);

    const validInvestments = movements.every(mov => mov.date && mov.amount);

    return (
      <Dialog
        maxWidth="xl"
        className={`${className || ""} add-investment-movements-dialog ${
          classes.addInvestmentMovementsDialog
        } ${!isMobile ? classes.desktopMode : classes.mobileMode}`}
        open={open}
        onClose={resetAndClose}
        fullScreen={isMobile}
      >
        <IconButton className={classes.closeButton} onClick={resetAndClose}>
          <CloseRoundedIcon />
        </IconButton>
        <DialogTitle className={classes.dialogTitle}>
          Agrega los movimientos de tu inversión
        </DialogTitle>
        <DialogContent className="dialogContent">
          <div className={classes.inputsContainer}>
            {movements.map((mov, index) => (
              <React.Fragment key={index}>
                <div className={classes.investmentTitleContainer}>
                  <div className={classes.investmentNumber}>
                    Movimiento N° {index + 1}
                  </div>
                  <IconButton
                    className={classes.deleteInvestmentButton}
                    onClick={() => deleteRow(index)}
                  >
                    <DeleteRoundedIcon />
                  </IconButton>
                </div>
                <Select
                  label="Tipo movimiento"
                  name="type"
                  value={mov.type}
                  onChange={({ target: { value } }) => {
                    updateRow(index, {
                      type: value as MovementType,
                    });
                  }}
                  options={[
                    { name: "Retiro", value: MovementType.WITHDRAWAL },
                    { name: "Depósito", value: MovementType.DEPOSIT },
                  ]}
                />
                <DateInput
                  name="date"
                  label="Fecha (dd/mm/yyyy)"
                  value={mov.date || ""}
                  onChange={({ target: { value } }) =>
                    updateRow(index, {
                      date: value as string,
                    })
                  }
                />
                <NumericInput
                  name="invested"
                  label="Monto"
                  value={mov.amount ?? 0}
                  onChange={({ target: { value } }) =>
                    updateRow(index, {
                      amount: value as number,
                    })
                  }
                />
                <IconButton
                  className={classes.deleteInvestmentButton}
                  onClick={() => deleteRow(index)}
                >
                  <DeleteRoundedIcon />
                </IconButton>
              </React.Fragment>
            ))}
          </div>
          <div className={classes.addInvestmentButton}>
            <ButtonOutlined
              className={classes.buttonOutlined}
              text={`Agregar`}
              onClick={addRow}
              fullWidth
            />
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <div className="submit-button">
            <ButtonPrimary
              text="Listo"
              onClick={ready}
              disabled={!validInvestments}
              fullWidth
            />
          </div>
        </DialogActions>
      </Dialog>
    );
  };
const useStyles = makeStyles(theme => ({
  closeButton: {
    display: "none",
    [theme.breakpoints.down(1080)]: {
      display: "block",
      position: "absolute",
      top: 5,
      right: 5,
      zIndex: 10,
      width: 40,
      height: 40,
    },
  },
  desktopMode: {
    "& .dialogContent": {
      width: 1100,
      minHeight: 470,
      overflowY: "unset",
    },
  },
  mobileMode: {},
  dialogTitle: {
    padding: 24,
    "& > h2": {
      color: "rgba(0, 0, 0, 0.92)",
      fontFamily: "Montserrat",
      fontSize: 21,
      fontWeight: "bold",
      letterSpacing: 0,
      lineHeight: "29px",
      textAlign: "center",
      [theme.breakpoints.down(1080)]: {
        fontSize: 15,
        fontWeight: "bold",
        lineHeight: 16,
      },

      [theme.breakpoints.down(1080)]: {
        padding: "20px 30px",
        paddingRight: 40,
      },
    },
  },
  addInvestmentMovementsDialog: {},

  dialogContent: {},
  inputsContainer: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1.5fr 1.5fr 1.5fr 60px",
    columnGap: 30,
    rowGap: 20,
    paddingTop: 5,
    maxHeight: 275,
    overflowY: "auto",
    [theme.breakpoints.down(1080)]: {
      marginBottom: 25,
      gridTemplateColumns: "1fr",
      maxHeight: "unset",
    },
  },
  investmentTitleContainer: {
    display: "none",
    [theme.breakpoints.down(1080)]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  deleteInvestmentButton: {
    display: "block",
    [theme.breakpoints.down(1080)]: {
      display: "block",
    },
  },
  buttonOutlined: {
    borderRadius: 10,
  },
  addInvestmentButton: {
    marginTop: 20,
    width: 225,
  },
  dialogActions: {
    display: "flex",
    justifyContent: "center",
    padding: "15px 0",
    "& .submit-button": {
      width: 225,
    },
  },
  investmentNumber: {
    [theme.breakpoints.down(1080)]: {
      color: "#777777",
      fontSize: 14,
      fontWeight: "bold",
      lineHeight: 18,
    },
  },
}));
