import React, { useState, useCallback, Dispatch, SetStateAction } from "react";
import { useStyles } from "./CT1.style";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import {
  MenuItem,
  Select,
  Slider,
  TextField,
  useMediaQuery,
} from "@material-ui/core";
import {
  ButtonPrimary,
  CreditCategorySelector,
  NumericInput,
} from "@components";
import { CreditSimulatorImage, CreditTranslator } from "@images";

type Props = {
  setPage: Dispatch<SetStateAction<number>>;
  setTerm: Dispatch<SetStateAction<number>>;
  term: number;
  setMonthPayment: Dispatch<SetStateAction<number>>;
  setCae: Dispatch<SetStateAction<number>>;
  cae: number;
  setRate: Dispatch<SetStateAction<number>>;
  rate: number;
  setAmount: Dispatch<SetStateAction<number>>;
  amount: number;
};

const CreditTranslator1: React.FC<Props> = ({
  setPage,
  setTerm,
  term,
  setMonthPayment,
  setCae,
  cae,
  setRate,
  rate,
  setAmount,
  amount,
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 1300px)");

  const [values, setValues] = useState({
    category: "none",
    term: 10, // para plazo
    amount: 0,
  });
  // const [term, setTerm] = useState(12);
  // const [cae, setCae] = useState(30);
  // const [rate, setRate] = useState(0);
  // const [amount, setAmount] = useState(0);

  function valueLabelFor(value: number) {
    return `${value} años`;
  }

  const changePage = async () => {
    const payment = await Number(
      (
        (amount * (cae / 100 / 12)) /
        (1 - (1 / (1 + cae / 100 / 12)) ** Number(term))
      ).toFixed(2)
    );
    // const amount2 = amount * rate;
    setMonthPayment(payment);
    setPage(2);
  };

  const handleChange = () => {
    console.log(values);
  };
  return (
    <div className={classes.container}>
      <div className={classes.firstSection}>
        <div className="textContainer">
          <span className={classes.text}>
            <strong>
              ¿Ya cotizaste un crédito y quieres entender bien cuánto vas a
              tener que pagar?{" "}
            </strong>
            ¡Estás en el lugar correcto! Con el traductor de créditos podrás
            descubrir cuál es el monto que pagarás al mes y el costo total del
            crédito que cotizaste de una forma sencilla.
          </span>
        </div>
        <div className="whiteBox">
          <img src={CreditTranslator} alt="Credit trasnlator" />
          <div className="formBox">
            <div className="formItem">
              <span className="name">
                <strong>Monto</strong>
              </span>
              <NumericInput
                name={""}
                value={amount}
                onChange={(e) => {
                  setAmount(e.target.value as number);
                }}
              />
            </div>
            <div className="formItem">
              <span className="name">
                <strong>Plazo (en meses)</strong>{" "}
              </span>
              <TextField
                id="outlined-basic"
                variant="outlined"
                type="number"
                value={term}
                name="term"
                onChange={(e) => {
                  setTerm(e.target.value as unknown as number);
                }}
              />
            </div>
            <div className="formItem">
              <span className="name">
                <strong>Tasa ofrecida </strong>(opcional){" "}
              </span>
              <div className="area">
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="number"
                  value={rate}
                  name="rate"
                  onChange={(e) => {
                    setRate(e.target.value as unknown as number);
                  }}
                />
                <span> %</span>
              </div>
            </div>
            <div className="formItem">
              <span className="name">
                <strong>CAE</strong>
              </span>
              <div className="area">
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="number"
                  value={cae}
                  name="cae"
                  onChange={(e) => {
                    setCae(e.target.value as unknown as number);
                  }}
                />
                <span> %</span>
              </div>
            </div>
          </div>
          <div className="buttonContainer">
            <ButtonPrimary
              text="Continuar"
              startIcon={<ArrowRightIcon />}
              onClick={() => changePage()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditTranslator1;
