import React, { Dispatch, SetStateAction, useState } from "react";

import { useStyles } from "./MB1step2.style";
import { useMediaQuery } from "@material-ui/core";
import { less, mainTable, addShadow, clock, paper } from "@images";
import { ButtonPrimary } from "@components/Reusables";
import { navigate } from "gatsby";
import { Route } from "@interfaces";

type Props = {
  setPage: Dispatch<SetStateAction<number>>;
};

const MB1Step2: React.FC<Props> = ({ setPage }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 1240px)");
  return (
    <div className={classes.container}>
      <div className={classes.topSection}>
        <span className="text">
          Cuando decidas cotizar créditos puedes
          <strong> compararlos usando el CTC y la CAE, </strong>
          pero la decisión de cuál es el mejor crédito para ti va a depender de
          tus necesidades (plazo y cuota mensual) y de tu capacidad de pago.
        </span>
      </div>
      <div className={classes.midSection}>
        {!isMobile ? (
          <img src={mainTable} />
        ) : (
          <div className={classes.gridContainer}>
            <div className="firstColumn back"></div>
            <div className="secondColumn  back"></div>
            <div className="firstRow back"></div>
            <div className="secondRow back"></div>
            <div className="thirdRow back"></div>
            <div className="fourthRow back"></div>
            <span className="creditName n1 topAndCenter">Crédito 1</span>
            <span className="creditName n2 topAndCenter">Crédito 2</span>
            <span className="atrributes a1 topAndCenter">Plazo</span>
            <span className="atrributes a2 topAndCenter">Costo Mensual</span>
            <span className="atrributes a3 topAndCenter">
              Costo totál del crédito (CTC)
            </span>
            <span className="atrributes a4 topAndCenter">Conclusión</span>
            <span className="text t1 topAndCenter">
              Pagas cuotas más altas durantes menos tiempo y terminas pagando
              menos al final
            </span>
            <span className="text t2 topAndCenter">
              {" "}
              Pagas cuotas más bajas durante más tiempo y terminas pagando más
              al final
            </span>
            <img className="add1 topAndCenter" src={addShadow} />
            <img className="add2 topAndCenter" src={addShadow} />
            <img className="add3 topAndCenter" src={addShadow} />
            <img className="less1 topAndCenter" src={less} />
            <img className="less2 topAndCenter" src={less} />
            <img className="less3 topAndCenter" src={less} />
          </div>
        )}
      </div>
      <div className={classes.bottomSection}>
        <div className={classes.texasContainer}>
          <img src={paper} />
          <div className="textSide">
            <span className="title">¿Si tienen el mismo costo total? </span>
            <span className="text">
              Elige si prefieres pagarlo en un plazo menor o con cuotas
              mensuales más bajas.
            </span>
          </div>
        </div>
        <div className={classes.texasContainer}>
          <img src={clock} />
          <div className="textSide">
            <span className="title">
              ¿Y si tienen el mismo plazo y número de cuotas?
            </span>
            <span className="text">
              Fijate siempre en el costo total del crédito menor.
            </span>
          </div>
        </div>
        <div className={classes.rightSide}>
          <ButtonPrimary
            text="Siguiente"
            secondaryVersion
            onClick={() => setPage(3)}
          />
          {!isMobile && (
            <>
              {" "}
              <span>
                <strong>¿MÁS DUDAS?</strong>
              </span>
              <span>
                Analiza tu{" "}
                <strong
                  className="underLined"
                  onClick={() => navigate(Route.toolsCreditsAndDebtsMission1)}
                >
                  simulación
                </strong>
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MB1Step2;
