import { gql, useQuery } from "@apollo/client";
import { SuggestedPortfolioExtendedHookParams } from "@hooks";
import { InvestorProfileType, SuggestedPortfolioInfo } from "@interfaces";

export const GET_SUGGESTED_PORTFOLIO_QUERY = gql`
  query GetSuggestedPortfolio($params: SuggestedPortfolioParams!) {
    getSuggestedPortfolio(params: $params) {
      summary {
        countryExposures {
          name
          code
          percentage
        }
        instrumentExposures {
          name
          code
          percentage
        }
      }
      expectedProfitability
      portfolioName
      funds {
        fundId
        percentage
        countryExposures {
          name
          code
          percentage
        }
        instrumentExposures {
          name
          code
          percentage
        }
      }
    }
  }
`;

interface QueryResult {
  getSuggestedPortfolio: SuggestedPortfolioInfo;
}

interface QueryParams {
  params?: SuggestedPortfolioExtendedHookParams & {
    investorProfile?: InvestorProfileType | null;
  };
}

interface HookReturn {
  data: SuggestedPortfolioInfo | null;
  loading: boolean;
}

export const useSuggestedPortfolio = (
  params: QueryParams["params"],
): HookReturn => {
  const { data, loading } = useQuery<QueryResult, QueryParams>(
    GET_SUGGESTED_PORTFOLIO_QUERY,
    {
      variables: { params },
    },
  );
  return { data: data?.getSuggestedPortfolio ?? null, loading };
};
