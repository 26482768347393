import { coin, numberMission, timer, timerGreen } from "@images";
import React, { useContext } from "react";
import { useStyles } from "./CardMissionIndividual.style";
import { oneMissionModalInfo } from "@utils";
import { AppContext } from "@context";

interface CardMissionIndividualProps {
  numberCoins: number;
  numberCard: number;
  text: string;
  enabled: boolean;
  onClick: string;
  numberTime: number;
}

const CardMissionIndividual = ({
  numberCoins,
  numberCard,
  text,
  enabled,
  onClick,
  numberTime,
}: CardMissionIndividualProps) => {
  const classes = useStyles();
  const { openMissionsModal } = useContext(AppContext);
  return (
    <section
      onClick={() => {
        enabled ? openMissionsModal(oneMissionModalInfo(onClick)) : undefined;
      }}
      className={`${classes.container} ${!enabled && classes.enabled}`}
    >
      <div className={classes.left}>
        <div>
          <img src={numberMission} />
          <span className="number">{numberCard}</span>
        </div>
        <h3 className="text">{text}</h3>
      </div>
      <div className={classes.right}>
        <div>
          <img className="coin" src={coin} />
          {numberCoins} roks
        </div>
        <div>
          <img className="timerGreen" src={timerGreen} />
          {numberTime} minutos
        </div>
      </div>
    </section>
  );
};

export default CardMissionIndividual;
