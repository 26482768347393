import React, { useContext } from 'react';
import { Dialog } from '@material-ui/core';
import { AppContext } from '@context';
import {
  ButtonOutlined,
  ButtonPrimary,
  NumericInput,
} from '@components/Reusables';
import { makeStyles } from '@material-ui/core';
import { navigate } from 'gatsby';
import { Route } from '@interfaces';
import { moneyModal } from 'images/Goal';

interface MissionsModalProps {
  open: boolean;
}

export const GoalModal: React.FC<MissionsModalProps> = ({ open }) => {
  const classes = useStyles();
  const { closeGoalModal } = useContext(AppContext);

  return (
    <>
      <Dialog open={open} onClose={closeGoalModal} className={classes.Dialog}>
        <div className={classes.Modal}>
          <div className={classes.info}>
            <div className={classes.titulo}>
              <img
                src={
                  'https://storage.googleapis.com/rokinapp-images/icons/goal_icon.svg'
                }
              />
              Agregar Meta
            </div>
            <span className={classes.description}>
              Ingresa el monto que quieres alcanzar para este objetivo
            </span>
            <div className={classes.line} />
            <NumericInput className={classes.numericInput} name={''} />
            <div className={classes.buttons}>
              <ButtonOutlined
                className={classes.cancel}
                onClick={() => closeGoalModal}
                text="Cancelar"
              />{' '}
              <ButtonPrimary
                className={classes.create}
                onClick={() => {
                  navigate(Route.createGoal);
                  closeGoalModal();
                }}
                text="Crear Meta"
              />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};
const useStyles = makeStyles(
  (theme) => ({
    Dialog: {
      color: theme.palette.text.primary,
      opacity: 0.9,
      maxWidth: '100%',
      '& >div >div': {
        borderRadius: 20,
      },
    },
    line: {
      width: '85%',
      position: 'absolute',
      top: 110,
      display: 'flex',
      justifyContent: 'center',
      borderBottom: '2px solid white',
    },
    Modal: {
      minWidth: 600,
      width: '100%',
      height: 380,
      flexGrow: 0,
      padding: '22px 44px 22px 44px',
      borderRadius: 20,
      [theme.breakpoints.down(700)]: {
        minWidth: 300,
        width: '90%',
        minHeight: 315,
        height: 425,
        padding: '15px 10px',
      },
      [theme.breakpoints.down(600)]: {
        width: 300,
      },
    },
    info: {
      display: 'grid',
    },
    titulo: {
      display: 'flex',
      width: '92%',
      maxWidth: 500,
      alignItems: 'center',
      fontSize: '1.2rem',
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      gap: '2rem',
      [theme.breakpoints.down(800)]: {
        fontSize: 20,
        justifyContent: 'center',
      },
    },
    description: {
      fontSize: 16,
      display: 'block',
      paddingTop: 20,
      paddingBottom: 30,
      textAlign: 'center',
      [theme.breakpoints.down(800)]: {
        fontSize: 14,
        width: '80%',
        margin: '0 auto',
      },
    },
    create: {
      width: 140,
      height: 50,
      minWidth: 140,
      [theme.breakpoints.down(600)]: {
        width: 200,
      },
    },
    cancel: {
      width: 100,
      height: 50,
      [theme.breakpoints.down(600)]: {
        width: 200,
        margin: 0,
      },
    },
    numericInput: {
      width: 160,
      height: 50,
      margin: '1rem auto 0',
      '& >div': { height: 102 },
    },
    buttons: {
      minWidth: 261,
      width: '100%',
      margin: '3rem auto 0',
      display: 'flex',
      flexDirection: 'row-reverse',
      gap: '2rem',
      justifyContent: 'center',
      '& button': {
        width: 200,
        margin: '0 auto',
      },
      [theme.breakpoints.down(600)]: {
        display: 'flex',
        justifyContent: 'center',
        gap: '1rem',
        flexDirection: 'column-reverse',
      },
    },
  }),
  { index: 1 }
);
