const BACK_ARROW_ICON =
  'https://storage.googleapis.com/rokinapp-images/icons/back_icon.svg';
const EXCLAMATION_ICON =
  'https://storage.googleapis.com/rokinapp-images/icons/Action%20_%20info%20outline%20(1).svg';
const ALFRED_ICON =
  'https://storage.googleapis.com/rokinapp-images/icons/Alfred.png';
const ACCESSORIES_ICON =
  'https://storage.googleapis.com/rokinapp-images/Services/asesoria_icon.svg';
const CREDITS_ICON =
  'https://storage.googleapis.com/rokinapp-images/Services/creditos_icon.svg';
const INVERSIONS_ICON =
  'https://storage.googleapis.com/rokinapp-images/Services/inversiones_icon%20(1).svg';
const RECURRING_PAYMENT_ICON =
  'https://storage.googleapis.com/rokinapp-images/Services/pagos%20Recurrentes_icon.svg';
const INSURANCE_ICON =
  'https://storage.googleapis.com/rokinapp-images/Services/seguros_icon%20(1).svg';
const COPY_ICON =
  'https://storage.googleapis.com/rokinapp-images/Services/copy_icon.svg';
const WEB_ICON =
  'https://storage.googleapis.com/rokinapp-images/Services/web_icon.svg';
const ATTACH_ICON =
  'https://storage.googleapis.com/rokinapp-images/icons/Editor%20_%20attach%20file.svg';
const LOGIN_WHITE_ICON =
  'https://storage.googleapis.com/rokinapp-images/icons/login_icon_white.svg';
const STATISTICS_ICON =
  'https://storage.googleapis.com/rokinapp-images/ourSolutions/icons/statistics_icon.svg';
const PIECHART_ICON =
  'https://storage.googleapis.com/rokinapp-images/ourSolutions/icons/pieChart_icon.svg';
const CREDITCARD_ICON =
  'https://storage.googleapis.com/rokinapp-images/ourSolutions/icons/creditCards_icon.svg';
const BILL_SOLUTIONS_ICON =
  'https://storage.googleapis.com/rokinapp-images/ourSolutions/icons/bill_icon%20(1).svg';
const SETTINGS_LINES_ICON =
  'https://storage.googleapis.com/rokinapp-images/ourSolutions/icons/settingsLines_icon.svg';
const ROCKET_ICON =
  'https://storage.googleapis.com/rokinapp-images/ourSolutions/icons/rocket_icon.svg';
const USER_ICON =
  'https://storage.googleapis.com/rokinapp-images/ourSolutions/icons/user_icon.svg';
const DISCOUNT_ICON =
  'https://storage.googleapis.com/rokinapp-images/ourSolutions/icons/discount_icon.svg';
const AUTOMATIC_ICON =
  'https://storage.googleapis.com/rokinapp-images/ourSolutions/icons/automatic_icon.svg';
const WALLET_ICON =
  'https://storage.googleapis.com/rokinapp-images/ourSolutions/icons/wallet_icon.svg';
const DISCOUNT_ICON_2 =
  'https://storage.googleapis.com/rokinapp-images/Services/discount_icon.svg';
const COMPUTER_ROCKET =
  'https://storage.googleapis.com/rokinapp-images/ProductTour/rocket_image.png';
const PARTNERS_02_ICON =
  'https://storage.googleapis.com/rokinapp-images/Services/Copy%20of%20partners02_icon.svg';
const DELETE_ICON =
  'https://storage.googleapis.com/rokinapp-images/Services/delete_icon.svg';
const CALENDAR_ICON =
  'https://storage.googleapis.com/rokinapp-images/icons/calendar01_icon.svg';
const PEN_ICON =
  'https://storage.googleapis.com/rokinapp-images/icons/pen_icon.svg';
const PEN_WHITE_ICON =
  'https://storage.googleapis.com/rokinapp-images/icons/pen_icon_white.svg';
export {
  PEN_WHITE_ICON,
  CALENDAR_ICON,
  WALLET_ICON,
  PEN_ICON,
  AUTOMATIC_ICON,
  DISCOUNT_ICON,
  INVERSIONS_ICON,
  RECURRING_PAYMENT_ICON,
  INSURANCE_ICON,
  CREDITS_ICON,
  ACCESSORIES_ICON,
  BACK_ARROW_ICON,
  EXCLAMATION_ICON,
  ALFRED_ICON,
  ATTACH_ICON,
  LOGIN_WHITE_ICON,
  BILL_SOLUTIONS_ICON,
  CREDITCARD_ICON,
  PIECHART_ICON,
  STATISTICS_ICON,
  SETTINGS_LINES_ICON,
  ROCKET_ICON,
  USER_ICON,
  COPY_ICON,
  WEB_ICON,
  DISCOUNT_ICON_2,
  COMPUTER_ROCKET,
  PARTNERS_02_ICON,
  DELETE_ICON,
};
