import React, { useContext } from "react";
import { Dialog, IconButton, useMediaQuery } from "@material-ui/core";
import { AppContext, MissionsContext } from "@context";
import { ButtonPrimary } from "@components/Reusables";
import { makeStyles } from "@material-ui/core";
import { numberMission, coinPro } from "@images";
import { navigate } from "gatsby";
import { CongratsModalType } from "@interfaces";
import CloseIcon from "@material-ui/icons/Close";

interface MissionsModalProps {
  open: boolean;
  missionInfo: CongratsModalType;
}

export const MissionsModal: React.FC<MissionsModalProps> = ({
  open,
  missionInfo,
}) => {
  const classes = useStyles();
  const { closeMissionsModal } = useContext(AppContext);
  return (
    <section className={classes.container}>
      <Dialog
        open={open}
        onClose={closeMissionsModal}
        className={classes.Dialog}
      >
        <div className={classes.Modal}>
          <div className={classes.info}>
            <div className={classes.titulo}>
              <section>
                <div className={classes.numberInfo}>
                  <img src={numberMission} className={classes.numberImg} />
                  <span
                    className={`${missionInfo?.number !== 1 && classes.left}`}
                  >
                    {missionInfo?.number}
                  </span>
                </div>
                <div className={classes.text1}>
                  <div>Misión {missionInfo?.number}</div>
                  <span>{missionInfo?.name}</span>
                </div>
              </section>
              <div>
                <IconButton onClick={closeMissionsModal}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
            <div className={classes.linea}></div>
            <div className={classes.text2}>{missionInfo?.description}</div>
            <div className={classes.textButton}>
              <div className={classes.text3}>Recompensa:</div>
              <div className={classes.textButton2}>
                <div className={classes.roks}>
                  <img src={coinPro} className={classes.roksImg} />
                  <div className={classes.text4}>
                    {missionInfo?.reward} Roks
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.buttonContainer}>
            <ButtonPrimary
              className={classes.button}
              secondaryVersion
              noDegraded
              text="Comenzar"
              onClick={() => {
                navigate(missionInfo.route);
                closeMissionsModal();
              }}
            />
          </div>
        </div>
      </Dialog>
    </section>
  );
};
const useStyles = makeStyles(
  (theme) => ({
    container: {
      position: "relative",
    },
    Dialog: {
      "& >div >div": {
        borderRadius: "35px",
        maxWidth: 900,
        [theme.breakpoints.down(600)]: {
          width: 330,
        },
      },
    },
    Modal: {
      width: 800,
      height: 380,
      flexGrow: 0,
      padding: "22px 44px 22px 44px",
      borderRadius: 35,
      border: "1px solid #ffffff",
      [theme.breakpoints.down(860)]: {
        width: 500,
        height: "auto",
        maxHeight: 570,
      },
      [theme.breakpoints.down(600)]: {
        width: 290,
        minHeight: 400,
        padding: "15px 10px",
        margin: "0 auto",
      },
    },
    info: {
      display: "grid",
    },
    titulo: {
      display: "flex",
      justifyContent: "space-between",
      "& >section": {
        display: "flex",
        [theme.breakpoints.down(600)]: {
          display: "grid",
          gridTemplateColumns: "22% 78%",
        },
      },
      [theme.breakpoints.down(600)]: {
        width: 290,
      },
    },
    numberInfo: {
      position: "relative",
      margin: "-20px -15px -10px -15px",
      "& img": {
        width: 92.3,
      },
      "& >span": {
        position: "absolute",
        zIndex: 3,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 92.28,
        height: 92.28,
        left: 0,
        top: 0,
        color: "#4619b6",
        fontSize: "1.5rem",
        fontWeight: "bold",
        fontFamily: "montserrat",
        [theme.breakpoints.down(860)]: {
          width: 78.66,
          height: 78.66,
          fontSize: "1.1rem",
        },
        [theme.breakpoints.down(600)]: {
          width: 83.23,
          height: 83.23,
        },
      },
    },
    numberImg: {
      width: "110px",
      height: "auto",
      [theme.breakpoints.down(860)]: {
        width: 75,
      },
    },
    roksImg: {
      width: 60,
      height: 60,
      [theme.breakpoints.down(860)]: {
        width: 80,
        height: 80,
      },
    },
    text1: {
      fontFamily: theme.typography.fontFamily,
      fontSize: "0.87rem",
      fontWeight: "normal",
      color: "#008296",
      marginTop: "10px",
      maxWidth: 600,
      [theme.breakpoints.down(860)]: {
        fontSize: 14,
        width: "100%",
        margin: 0,
        marginLeft: 5,
      },
      "& span": {
        fontFamily: theme.typography.fontFamily,
        fontSize: "1.5rem",
        fontWeight: "bold",
        color: "#393E44",
        marginTop: "-20px",
        [theme.breakpoints.down(860)]: {
          marginTop: -5,
          fontSize: 16,
          width: 190,
        },
      },
    },
    linea: {
      width: "100%",
      height: 2,
      flexGrow: 0,
      backgroundColor: "#008296",
      [theme.breakpoints.down(860)]: {
        width: "90%",
        margin: "0 auto",
      },
      [theme.breakpoints.down(600)]: {
        width: 230,
      },
    },
    text2: {
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: "normal",
      color: theme.palette.text.primary,

      marginTop: 20,
      marginBottom: 20,
      [theme.breakpoints.down(860)]: {
        padding: "0 8px",
      },
      [theme.breakpoints.down(600)]: {
        width: 264,
        paddingLeft: 14,
      },
    },
    textButton: {
      display: "grid",
      [theme.breakpoints.down(860)]: {
        padding: "0 5px",
      },
    },
    text3: {
      fontFamily: theme.typography.fontFamily,
      fontSize: "1.25rem",
      fontWeight: "bold",
      color: "#393e44",
      marginTop: 10,
    },
    textButton2: {
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down(860)]: {
        display: "grid",
      },
    },
    text4: {
      fontFamily: "Montserrat",
      fontSize: "1rem",
      fontWeight: "normal",
      color: "#000000",
      height: 80,
      display: "flex",
      alignItems: "center",
    },
    roks: {
      display: "flex",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      textAlign: "center",
    },
    button: {
      width: "70%",
      height: 50,
      margin: "8px 0 0 10px",
      [theme.breakpoints.down(860)]: {
        margin: "0 auto",
        width: "100%",
      },
    },
  }),
  { index: 1 }
);
