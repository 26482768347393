import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { useStyles } from "./step2.style";
import { Bar } from "react-chartjs-2";
import { lessWithoutShadow, add } from "@images";
import { useMediaQuery } from "@material-ui/core";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Icon, IconButton, TextField } from "@material-ui/core";
import {
  ButtonOutlined,
  ButtonPrimary,
  NumericInput,
} from "@components/Reusables";
import { AppContext } from "@context";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
type Props = {
  setPage: Dispatch<SetStateAction<number>>;
  handleClick: () => void;
};

const Step2SavingAndInvestmentM1: React.FC<Props> = ({
  setPage,
  handleClick,
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const [invalidYear, setInvalidYear] = useState(false);
  const [labels, setLabels] = useState(
    Array.from({ length: 10 }, (_, i) => i + 1)
  );
  const [total, setTotal] = useState([
    2277257.75, 3618378.39, 5026555.06, 6505140.57, 8057655.35, 9687795.87,
    11399443.42, 13196673.34, 15083764.76, 17065210.75,
  ]);
  const [interests, setInterests] = useState([
    77257.75, 218378.39000000013, 426555.0599999996, 705140.5700000003,
    1057655.3499999996, 1487795.8699999992, 1999443.42, 2596673.34, 3283764.76,
    4065210.75,
  ]);
  const [contributions, setContributions] = useState([
    2200000, 3400000, 4600000, 5800000, 7000000, 8200000, 9400000, 10600000,
    11800000, 13000000,
  ]);
  const [anualRate, setAnualRate] = useState(5);
  const [initialIncome, setInitialIncome] = useState(1000000);
  const [monthlyContribution, setMonthlyContribution] = useState(100000);
  const [years, setYears] = useState(10);
  const { openCongratsModal } = useContext(AppContext);

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Aporte",
        data: contributions,
        backgroundColor: "#008296",
      },
      {
        label: "Interés",
        data: interests,
        backgroundColor: "#8352fd",
      },
    ],
  };
  const config = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  const updateChart = async () => {
    const newTotals = [];
    const newContributions = [];
    const newInterests = [];
    setLabels(Array.from({ length: years }, (_, i) => i + 1));
    let newTotal = 0;
    const monthlyRate = (await Math.pow(1 + anualRate / 100, 1 / 12)) - 1;
    const discountFactor = await (1 - 1 / Math.pow(1 + monthlyRate, 12));
    const growFactor = await Math.pow(1 + monthlyRate, 12);
    for (let i = 0; i < years; i++) {
      if (i == 0) {
        newTotal = await Number(
          (
            initialIncome * growFactor +
            (monthlyContribution / monthlyRate) * discountFactor * growFactor
          ).toFixed(2)
        );
        await newTotals.push(newTotal);
        await newContributions.push(monthlyContribution * 12 + initialIncome);
        await newInterests.push(
          newTotal - monthlyContribution * 12 - initialIncome
        );
      } else {
        newTotal = await Number(
          (
            newTotals[i - 1] * growFactor +
            (monthlyContribution / monthlyRate) * discountFactor * growFactor
          ).toFixed(2)
        );
        await newTotals.push(newTotal);
        await newContributions.push(
          monthlyContribution * 12 * (i + 1) + initialIncome
        );
        await newInterests.push(
          newTotal - monthlyContribution * 12 * (1 + i) - initialIncome
        );
      }
    }
    setTotal(newTotals);
    setContributions(newContributions);
    setInterests(newInterests);
  };
  const changeYears = async (value: number) => {
    // check if value is a number between 1 and 100, if not, set invalidYear to true
    if (value < 1 || value > 100) {
      setInvalidYear(true);
    }
    // if value is a number between 1 and 100, set invalidYear to false and update years
    else {
      setInvalidYear(false);
      setYears(value);
    }
  };
  return (
    <div className={classes.container}>
      <main className="flexContainer">
        <div className={classes.chartContainer}>
          <div className={classes.totals}>
            <div className={`${classes.total} ${classes.main}`}>
              <h3>Balance Total</h3>
              <h3>
                $ {Math.round(total[total.length - 1]).toLocaleString("es")}
              </h3>
            </div>
            <div className={`${classes.total} ${classes.green}`}>
              <h3>Aporte Total</h3>
              <h3>
                ${contributions[contributions.length - 1].toLocaleString("es")}
              </h3>
            </div>
            <div className={`${classes.total} ${classes.purple}`}>
              <h3>Interés Total</h3>
              <h3>
                $
                {Math.round(interests[interests.length - 1]).toLocaleString(
                  "es"
                )}
              </h3>
            </div>
          </div>
          <div className="chart">
            <Bar options={config} data={data} />
          </div>
        </div>
        <form action="">
          <div className={classes.formContainer}>
            <span className="blueText text">
              Determina cuánto puede crecer tu dinero utilizando el poder del
              interés compuesto
            </span>
            <span className="smallText text">
              *La información de inversión proporcionada en esta página es solo
              para fines educativos.
            </span>
            {isMobile ? (
              <div className="mobileFlex">
                <div className={classes.formItem}>
                  <span className="name">
                    <strong>Inversión Inicial</strong>{" "}
                  </span>
                  <NumericInput
                    name="initalIncome"
                    value={initialIncome}
                    onChange={(e) => setInitialIncome(Number(e.target.value))}
                  />
                </div>
                <div className={`${classes.formItem} ${classes.leftSide}`}>
                  <span className="name">
                    <strong>Plazo (en años)</strong>{" "}
                  </span>
                  <div className="addYear">
                    <div className="leftButton button">
                      <IconButton
                        onClick={() => {
                          changeYears(years - 1);
                        }}
                      >
                        <Icon>
                          <img src={lessWithoutShadow} alt="minus" />
                        </Icon>
                      </IconButton>
                    </div>
                    <TextField
                      name="years"
                      value={years}
                      // type="number"
                      error={invalidYear}
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                      className="yearInput"
                      // inputProps={{
                      //   max: 100,
                      //   min: 10,
                      // }}
                      onChange={(e) => changeYears(Number(e.target.value))}
                    />
                    <div className="rightButton button">
                      <IconButton
                        onClick={() => {
                          changeYears(years + 1);
                        }}
                      >
                        <Icon>
                          <img src={add} alt="add" />
                        </Icon>
                      </IconButton>
                    </div>
                  </div>
                  {invalidYear && (
                    <span className={classes.helperText}>
                      Ingrese un número entre 1 y 100
                    </span>
                  )}
                </div>
                <div className={classes.formItem}>
                  <span className="name">
                    <strong>contribución mensual</strong>{" "}
                  </span>
                  <NumericInput
                    name="monthlyContribution"
                    value={monthlyContribution}
                    onChange={(e) =>
                      setMonthlyContribution(Number(e.target.value))
                    }
                  />
                </div>

                <div className={`${classes.formItem} ${classes.leftSide}`}>
                  <span className="name">
                    <strong>Tasa de Interés </strong>{" "}
                  </span>
                  <div className="area interest">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      type="number"
                      disabled
                      value={anualRate}
                      name="rate"
                      onChange={(e) => {
                        setAnualRate(e.target.value as unknown as number);
                      }}
                    />
                    <span> %</span>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className={classes.formItem}>
                  <span className="name">
                    <strong>Inversión Inicial</strong>{" "}
                  </span>
                  <NumericInput
                    name="initalIncome"
                    value={initialIncome}
                    onChange={(e) => setInitialIncome(Number(e.target.value))}
                  />
                </div>
                <div className={`${classes.formItem} ${classes.leftSide}`}>
                  <span className="name">
                    <strong>Plazo (en años)</strong>{" "}
                  </span>
                  <div className="addYear">
                    <div className="leftButton button">
                      <IconButton
                        onClick={() => {
                          changeYears(years - 1);
                        }}
                      >
                        <Icon>
                          <img src={lessWithoutShadow} alt="minus" />
                        </Icon>
                      </IconButton>
                    </div>
                    <TextField
                      name="years"
                      value={years}
                      type="number"
                      error={invalidYear}
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                      className="yearInput"
                      onChange={(e) => changeYears(Number(e.target.value))}
                    />
                    <div className="rightButton button">
                      <IconButton
                        onClick={() => {
                          changeYears(years + 1);
                        }}
                      >
                        <Icon>
                          <img src={add} alt="add" />
                        </Icon>
                      </IconButton>
                    </div>
                  </div>
                  {invalidYear && (
                    <span className={classes.helperText}>
                      Ingrese un número entre 1 y 100
                    </span>
                  )}
                </div>
                <div className={classes.formItem}>
                  <span className="name">
                    <strong>contribución mensual</strong>{" "}
                  </span>
                  <NumericInput
                    name="monthlyContribution"
                    value={monthlyContribution}
                    onChange={(e) =>
                      setMonthlyContribution(Number(e.target.value))
                    }
                  />
                </div>

                <div className={`${classes.formItem} ${classes.leftSide}`}>
                  <span className="name">
                    <strong>Tasa de Interés </strong>{" "}
                  </span>
                  <div className="area interest">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      type="number"
                      disabled
                      value={anualRate}
                      name="rate"
                      onChange={(e) => {
                        setAnualRate(e.target.value as unknown as number);
                      }}
                    />
                    <span> %</span>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className={classes.buttonsSection}>
            <ButtonOutlined
              secondaryVersion
              text="Calcular"
              onClick={updateChart}
            />
            {!isMobile && (
              <ButtonPrimary
                secondaryVersion
                text="Terminar misión"
                onClick={() => {
                  handleClick();
                }}
              />
            )}
          </div>
        </form>
        {isMobile && (
          <div className={classes.mobileButton}>
            <ButtonPrimary
              secondaryVersion
              text="Terminar misión"
              onClick={() => {
                handleClick();
              }}
            />
          </div>
        )}
      </main>
    </div>
  );
};

export default Step2SavingAndInvestmentM1;
