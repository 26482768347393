import { tablaMA1Desktop, tablaMA1Mobile, tarjetasMA1 } from "@images";
import React, { useContext } from "react";
import { useStyles } from "./MA1step1.style";
import { useMediaQuery } from "@material-ui/core";
import { ButtonPrimary } from "@components/Reusables";
import { AppContext } from "@context";
type Props = {
  handleClick: () => void;
};
const MA1step1: React.FC<Props> = ({ handleClick }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const { openCongratsModal } = useContext(AppContext);
  return (
    <div className={classes.container}>
      <div className={classes.topSection}>
        <div className="textContainer">
          <span className="text">
            Las tarjetas nos permiten
            <strong> reemplazar la plata física </strong> (billetes) en algo más
            cómodo y fácil de usar. Su principal fin es
            <strong>
              {" "}
              ayudarnos en nuestras compras, depósitos y transferencias.
            </strong>
            <br />
            <br />
            <br />
            Veamos las
            <strong> características </strong>
            que tienen estas tarjetas:
          </span>
        </div>
        <img src={tarjetasMA1} />
      </div>
      <div className={classes.imageContainer}>
        {isMobile ? (
          <div className="scrollable">
            <img src={tablaMA1Mobile} />
          </div>
        ) : (
          <img src={tablaMA1Desktop} />
        )}
      </div>
      <div className={classes.bottomSection}>
        <ButtonPrimary
          text={"Terminar Misión"}
          onClick={() => handleClick()}
          secondaryVersion
        />
      </div>
    </div>
  );
};

export default MA1step1;
