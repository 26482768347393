import { ApolloClient, gql } from "@apollo/client";
import { financialAnalysisInterface } from "@interfaces";

const UPDATE_USER_FINANCIAL_DATA = gql`
  mutation Mutation($data: UserIdeaFinancialDataInput!) {
    updateUserIdeaFinancialData(data: $data)
  }
`;

type UserFinancialDataInput = financialAnalysisInterface;

export async function updateUserIdeaFinancialData(
  client: ApolloClient<any>,
  params: UserFinancialDataInput
): Promise<boolean> {
  const { data } = await client.mutate({
    mutation: UPDATE_USER_FINANCIAL_DATA,
    variables: { data: params },
  });

  return data;
}
