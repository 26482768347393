import React from "react";
import { makeStyles } from "@material-ui/core";

import {
  MISION_ROUND_ICON_1,
  MISION_ROUND_ICON_2,
  MISION_ROUND_ICON_3,
  MISION_ROUND_ICON_4,
  MISION_ROUND_ICON_5,
  MISION_ROUND_ICON_6,
  MISION_ROUND_ICON_7,
} from "@images";

interface HeaderProps {
  number: number;
  className?: string;
}

export const Footer = ({ number, className }: HeaderProps) => {
  const classes = useStyles();

  const setImage = () => {
    switch (number) {
      case 1:
        return MISION_ROUND_ICON_1;
      case 2:
        return MISION_ROUND_ICON_2;
      case 3:
        return MISION_ROUND_ICON_3;
      case 4:
        return MISION_ROUND_ICON_4;
      case 5:
        return MISION_ROUND_ICON_5;
      case 6:
        return MISION_ROUND_ICON_6;
      case 7:
        return MISION_ROUND_ICON_7;
      default:
        return MISION_ROUND_ICON_1;
    }
  };

  return (
    <div className={classes.footerContainer}>
      <footer className={classes.footer}>
        <img src={setImage()} />
      </footer>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    marginTop: "5rem",
  },
  footer: {
    background: "white",
    MozBorderRadius: "100px 100px 0 0",
    WebkitBorderRadius: "100px 100px 0 0",
    width: 110,
    height: 55,
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
    position: "fixed",
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)",
    "& >img": {
      width: "3.5rem",
    },
  },
}));
