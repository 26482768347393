import React from "react";
import { useStyles } from "./TopSection.style";
import { useMediaQuery } from "@material-ui/core";
import { pension, arriendo, subsidio, sueldo } from "@images";

const TopSection: React.FC = () => {
  const classes = useStyles();
  const ImagesList = [
    { imagen: sueldo, text: "Sueldo" },
    { imagen: pension, text: "Pensión" },
    { imagen: subsidio, text: "Subsidio" },
    { imagen: arriendo, text: "Arriendo" },
  ];

  return (
    <div className={classes.container}>
      <div className="textContainer">
        <br />
        <div className={classes.mainText}>
          <span className="text">
            Los
            <span className={classes.textStyle}> ingresos fijos </span>
            son los que se reciben todos los meses de forma
            <span className={classes.textStyle}> constante</span>. Corresponden
            a los que están especificados en el contrato, en muchos casos como
            el sueldo base o sueldo fijo. Dos formas de identificarlos son que
            <span className={classes.textStyle}> tienen una </span>
            <span className={classes.textStyle}>
              fecha de recibo predecible{" "}
            </span>
            y que el
            <span className={classes.textStyle}> monto no </span>
            <span className={classes.textStyle}> varía </span>
            significativamente en el corto plazo.
          </span>
        </div>
      </div>
      <div className={classes.examplesBox}>
        <span>Por ejemplo:</span>
        <div className={classes.examples}>
          {ImagesList.map((imagen) => (
            <div className="example">
              <img src={imagen.imagen} />
              <span className="ExampleIncomeTag">{imagen.text}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TopSection;
