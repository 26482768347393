import React, { useCallback, useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import useStyles from "./index.style";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { ButtonPrimary } from "@components/Reusables";
import AccidentPersonalizedAnalysis from "./AccidentPersonalized";
import HeaderServices from "@components/Services/Layout/Header/Header";
import { useForm } from "@hooks";
import { useApolloClient } from "@apollo/client";
import { useCurrentUser } from "@apollo";
import { sendContactFormService } from "apollo/contact_form/sendContactFormsService.mutation";

interface YouSeekProps {
  className?: string;
  setFinishSave: (value: any) => void;
  setStepContinue: () => void;
  onSend: () => void;
}
const Categories: React.FC<YouSeekProps> = ({
  className,
  setFinishSave,
  setStepContinue,
  onSend,
}) => {
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const classes = useStyles(1);
  const [step, setStep] = useState(-1);
  const { user: currentUser } = useCurrentUser();
  const client = useApolloClient();
  const [form] = useForm({});

  let StepSearching;

  switch (step) {
    case -1:
      StepSearching = (
        <>
          <HeaderServices
            title="SEGUROS"
            steps={["Accidentes"]}
            onBackClick={setStepContinue}
          />
          <section className={`${className || ""} ${classes.container}`}>
            <div className={classes.containerSection}>
              <div className={classes.containerCard}>
                <div className={classes.title}>TIPO DE SEGURO</div>
                <div
                  className={classes.card}
                  onClick={() => {
                    form.sure = {
                      "Tipo de seguro": "Seguro con descuento",
                    };
                    setFinishSave({
                      Notification2448: false,
                      BallCharge: true,
                    });
                    onSend();
                  }}
                >
                  <div>
                    <div className={classes.titleCard}>
                      Seguro con descuento
                    </div>
                    <div className={classes.subtitleCard}>
                      Tarifa preferencial con Southbridge
                    </div>
                  </div>
                  <ArrowForwardIosIcon />
                </div>
                <div
                  className={classes.card}
                  onClick={() => {
                    setStep(0);
                  }}
                >
                  <div>
                    <div className={classes.titleCard}>
                      Análisis personalizado
                    </div>
                    <div className={classes.subtitleCard}>
                      Buscaremos alternativas según <br /> tus necesidades
                    </div>
                  </div>
                  <ArrowForwardIosIcon />
                </div>
              </div>{" "}
              <div>
                <ButtonPrimary
                  secondaryVersion
                  noDegraded
                  text="Logo convenio"
                  onClick={() => {}}
                  className={classes.buttonPrimary}
                />
                <div className={classes.parrafo}>
                  Tenemos un convenio con <strong>xxxx</strong> para una tarifa
                  preferencial. Sino, puedes pedir un análisis de diferentes
                  alternativas.
                </div>
              </div>
            </div>
          </section>
        </>
      );
      break;
    case 0:
      StepSearching = (
        <>
          <HeaderServices
            title="SEGUROS"
            steps={["Accidentes"]}
            onBackClick={() => {
              setStep(-1);
            }}
          />
          <AccidentPersonalizedAnalysis
            onSend={onSend}
            setFinishSave={setFinishSave}
          />
        </>
      );
      break;
    case 1:
      break;
    case 2:
      break;
  }

  return <>{StepSearching}</>;
};
export default Categories;
