import { ApolloClient, gql } from "@apollo/client";

const COMPLETE_MISSION = gql`
  mutation CompleteUserMission($completeUserMissionId: ID!, $data: JSON) {
    completeUserMission(id: $completeUserMissionId, data: $data)
  }
`;

type CompleteMissionParams = {
  'completeUserMissionId': number,
  'data': any
};

export async function completeMissionMutation(client: ApolloClient<object>, id: number, data= {}): Promise<boolean> {
  const result = await client.mutate<boolean, CompleteMissionParams>({
    mutation: COMPLETE_MISSION,
    variables: { completeUserMissionId: id, data: data},
    awaitRefetchQueries: true,
  });
  return !!result.data;
}