import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      borderRadius: "2.1875rem",
      border: "2px solid #ffffff",
    },
  },
  container: {
    borderRadius: "2.1875rem",
    border: "solid 2px grey",
    "& .pantalla": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "2.7rem",
      paddingTop: "0.5rem",
      borderRadius: "2.1875rem",
      border: "solid 1px #ffffff",
      backgroundColor: "#F4F3F3",
      [theme.breakpoints.down(1240)]: {
        padding: "1.5rem",
      },
    },
  },

  topSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    "& .title": {
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: theme.palette.text.primary,
      fontFamily: theme.typography.fontFamily,
      textAlign: "left",
    },
    "& img": {
      width: "6.5rem",
    },
  },
  textSection: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "felx-start",
    marginBottom: "4rem",
    "& .name , .text": {
      fontSize: "0.85rem",
      fontWeight: "normal",
      color: theme.palette.text.primary,
      fontFamily: "Montserrat",
      margin: "0.3rem 0",
    },
  },
  buttonSection: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around",
    "& button": {
      width: "12rem",
      [theme.breakpoints.down(1240)]: {
        margin: "1rem",
      },
    },
    [theme.breakpoints.down(1240)]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  separator: {
    width: "30rem",
    height: "0.125rem",
    backgroundColor: "#ffffff",
    margin: "1rem auto",
    [theme.breakpoints.down(1240)]: {
      width: "100%",
      margin: "1rem 2rem",
    },
  },
}));
