export enum PortfolioAssetCategory {
  REAL_ESTATE = "REAL_ESTATE",
  SUSTAINABLE = "SUSTAINABLE",
  MAIN_COMPANIES = "MAIN_COMPANIES",
  DEBT = "DEBT",
  MIXED = "MIXED",
  SINGULAR = "SINGULAR",
}
export enum PortfolioLocationCategory {
  CHILE_AND_WORLD = "CHILE_AND_WORLD",
  OUTSIDE_CHILE = "OUTSIDE_CHILE",
  CHILE = "CHILE",
}

export const portfolioAssetCategories = [
  PortfolioAssetCategory.REAL_ESTATE,
  PortfolioAssetCategory.SUSTAINABLE,
  PortfolioAssetCategory.MAIN_COMPANIES,
  PortfolioAssetCategory.DEBT,
  PortfolioAssetCategory.MIXED,
  PortfolioAssetCategory.SINGULAR,
];

export const portfolioLocationCategories = [
  PortfolioLocationCategory.CHILE_AND_WORLD,
  PortfolioLocationCategory.OUTSIDE_CHILE,
  PortfolioLocationCategory.CHILE,
];

export interface SuggestedPortfolioData {
  investmentAsset: PortfolioAssetCategory | null;
  investmentLocation: PortfolioLocationCategory | null;
  initial: number;
  monthly: number;
  period: number;
  expectedProfitability: number | null;
}

export const DEFAULT_SUGGESTED_PORTFOLIO_DATA: SuggestedPortfolioData = {
  investmentAsset: null,
  expectedProfitability: null,
  investmentLocation: null,
  initial: 1300000,
  monthly: 500000,
  period: 3,
};

export const PortfolioAssetTraduction: Record<PortfolioAssetCategory, string> =
  {
    [PortfolioAssetCategory.REAL_ESTATE]: "Inmobiliario",
    [PortfolioAssetCategory.SUSTAINABLE]: "Sustentable",
    [PortfolioAssetCategory.MAIN_COMPANIES]: "Principales Empresas",
    [PortfolioAssetCategory.DEBT]: "Deuda",
    [PortfolioAssetCategory.MIXED]: "De todo un poco",
    [PortfolioAssetCategory.SINGULAR]: "De todo un poco",
  };

export const PortfolioLocationTraduction: Record<
  PortfolioLocationCategory,
  string
> = {
  [PortfolioLocationCategory.CHILE_AND_WORLD]: "En Chile y el mundo",
  [PortfolioLocationCategory.OUTSIDE_CHILE]: "Fuera de Chile",
  [PortfolioLocationCategory.CHILE]: "En Chile",
};
