export const BANK_OPTIONS = [
  "Banco BCI",
  "Banco de Chile - Edwards",
  "Banco Itaú Chile",
  "Banco Bice",
  "Banco Santander",
  "Banco Security",
  "Banco Falabella",
  "Scotiabank Chile",
  "Banco Internacional",
  "Corpbanca",
  "HSBC Bank (Chile)",
  "Deutsche Bank",
  "Banco Ripley",
  "Rabobank Chile",
  "Banco Consorcio",
  "Banco Penta",
  "Banco Paris",
  "Banco BBVA",
  "Banco BTG Pactual Chile",
].map(g => ({
  name: g,
  value: g,
}));
