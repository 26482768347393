import React, { useState, useEffect, useContext } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import {
  ButtonOutlined,
  ButtonPrimary,
  FirstFundSelector,
  GoalSelector,
  InvestPeriodRangeSelector,
  NumericInput,
  TextInput,
} from '@components';
import { navigate } from 'gatsby';
import { Goal, Route } from '@interfaces';
import { GoalsContext } from '@context';

interface EditGoalProps {
  buttonContinue: string;
  title: string;
  secondTitle?: string;
  goal?: number;
  goalId?: number;
  goalPeriod?: number;
  goalType?: string;
  allGoal?: () => Goal | undefined;
}

export const EditGoal: React.FC<EditGoalProps> = ({
  buttonContinue,
  title,
  secondTitle,
  goal,
  goalId,
  goalPeriod,
  goalType,
  allGoal,
}) => {
  const classes = useStyles();
  const [isProfile, setProfile] = useState<string>();
  const [newName, setNewName] = useState<string>(undefined);
  const [newPeriod, setNewPeriod] = useState<number>(0);
  const [newGoal, setNewGoal] = useState<number>(0);
  const { updateGoal } = useContext(GoalsContext);
  const [firstFundSelection, setFirstFundSelection] =
    useState<string>(undefined);

  useEffect(() => {
    setProfile(goalType);
    setNewGoal(goal);
    if (goalPeriod) {
      setNewPeriod(goalPeriod);
    }
    setNewName(secondTitle);
  }, [goalType]);

  const GOALS_OPTIONS = [
    'Ahorrar',
    'Pagar deudas',
    'Mejorar mi jubilación',
    'Viajar',
    'Comprar una casa',
    'Fondo de emergencia',
    'Otro',
  ].map((r) => ({
    name: r,
    value: r,
  }));

  const handleClickUpdate = () => {
    try {
      updateGoal(goalId, {
        type: isProfile,
        period: newPeriod,
        name: newName,
        goal: newGoal,
      });
      navigate(Route.investments + '/?#objectives');
    } catch (error) {
      console.log('error', error);
    }
  };

  const titleProfile =
    isProfile === 'Ahorrar'
      ? 'Para qué quieres ahorrar?'
      : isProfile === 'Pagar deudas'
      ? 'Qué deudas quieres pagar?'
      : isProfile === 'Mejorar mi jubilación'
      ? 'Cuando me retire quiero...'
      : isProfile === 'Viajar'
      ? 'Dónde quieres ir?'
      : isProfile === 'Comprar una casa'
      ? 'Dónde quieres vivir?'
      : isProfile === 'Otro'
      ? 'Sueño con...'
      : 'Nombre';

  return (
    <>
      <div className={classes.children}>
        <section className={classes.container}>
          <div className={classes.goal}>
            <section>
              <div className={classes.headerLayout2}>
                <div className={classes.txts}>
                  <span className={classes.title}>
                    {title}
                    {secondTitle}
                  </span>{' '}
                  <span className={classes.txt}>Quiero:</span>
                </div>
              </div>
            </section>
            <GoalSelector
              className={classes.input}
              options={GOALS_OPTIONS}
              value={`${isProfile}`}
              label="Elige un objetivo"
              onChange={(e) => {
                setProfile(e.target.value);
              }}
            />
          </div>

          <div className={classes.titlereactive}>
            <div className={classes.txt}>{titleProfile}</div>
            <TextInput
              label="Ponle un nombre a tu objetivo"
              className={classes.input}
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
          </div>
          <div className={classes.titlePlazo}>
            <span className={classes.txt}>Plazo</span>
            <span className={classes.subtitleText}>
              En cuánto tiempo quieres lograr esta meta
            </span>{' '}
            <div className={classes.flex}>
              <img src="https://storage.googleapis.com/rokinapp-images/investments/CreateNew/Left%20Icon.svg" />
              <InvestPeriodRangeSelector
                className={classes.investPeriod}
                name="goalTerm"
                label=""
                onChange={(e) => setNewPeriod(e.target.value)}
                value={newPeriod}
                valueLabelDisplay
                marks
                ariaLabel
              />
            </div>
          </div>
          <div className={classes.meta}>
            <section className={classes.goalSuggested}>
              <div className={classes.titleText}>Meta</div>
              <div className={classes.subtitleText}>
                Cuánto te gustaría ahorrar en total (opcional)
              </div>
              <NumericInput
                className={classes.textInput}
                name="goalAmount"
                value={newGoal}
                onChange={(e) => setNewGoal(e.target.value)}
              />
            </section>
          </div>

          <div className={classes.buttons}>
            <ButtonPrimary
              className="primary"
              text={buttonContinue}
              onClick={handleClickUpdate}
            />
            <ButtonOutlined
              className="outlined"
              onClick={() => navigate(Route.investments)}
              text={'Cancelar'}
            />
          </div>
        </section>
      </div>
    </>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  children: {},
  txts: {
    paddingTop: '1rem',
    '& span': {
      display: 'block',
    },
  },
  headerLayout2: {
    display: 'flex',
    gap: '2rem',
    alignItems: 'center',
    paddingTop: 20,
    marginBottom: '2rem',
    '& img': {
      width: 140,
      height: 140,
    },
    [theme.breakpoints.down(1000)]: {
      marginBottom: 0,
    },
  },
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    '& input': {
      width: 400,
    },
    [theme.breakpoints.down(1280)]: {
      padding: '25px',
      gap: '20px',
      paddingTop: '0',
    },
    [theme.breakpoints.down(1000)]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  txt: {
    display: 'block',
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.primary,
    paddingBottom: 5,
    textTransform: 'uppercase',
  },
  input: {
    width: '100%',
    fontSize: '0.875rem',
    textDecoration: 'none',
    marginTop: '0.4rem',
  },
  titleText: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.primary,
    paddingBottom: 5,
    textTransform: 'uppercase',
  },
  subtitleText: {
    fontFamily: 'Nunito',
    fontSize: '0.75rem',
    fontWeight: 300,
    color: theme.palette.text.secondary,
    width: 290,
  },
  goal: {
    gridArea: 'goal',
    maxWidth: 700,
    margin: '0 auto',
    '&>div': {
      width: 428,
    },
    [theme.breakpoints.down(1000)]: {
      '&>div': {
        width: '100%',
      },
    },
  },
  titlereactive: {
    gridArea: 'react',
    maxWidth: 700,
    margin: '0 auto',
  },
  titlePlazo: {
    gridArea: 'plazo',
    maxWidth: 428,
    margin: '0 auto',
    width: '100%',
  },
  meta: {
    gridArea: 'meta',
    maxWidth: 700,
    margin: '0 auto',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 24,
    fontWeight: 'bold',
    color: '#393e44',
    display: 'block',
  },
  subtitle: {
    fontFamily: 'Montserrat',
    fontWeight: 'normal',
    display: 'block',
    color: theme.palette.text.secondary,
  },
  buttons: {
    display: 'flex',
    gap: '2rem',
    alignItems: 'end',
    margin: '2rem auto!important',
    [theme.breakpoints.down(600)]: {
      display: 'grid',
      paddingTop: '2rem',
      gap: '1rem',
      width: 350,
      margin: '1rem auto',
    },
    '& button': {
      minWidth: 180,
    },
  },
  flex: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'start',
    paddingTop: '1rem',
    '& >div': {
      width: '100%',
    },
    '& div': {
      fontSize: '0.75rem',
    },
    '& img': {
      marginTop: '0.75rem',
    },
  },
  investPeriod: {
    maxWidth: 428,
  },
  textInput: {
    [theme.breakpoints.down(1000)]: {
      width: '100%',
    },
  },
}));
