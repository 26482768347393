import React from "react";
import { Select } from "@components";
import { CommonSelectorProps, JOB_TYPE_OPTIONS } from "..";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends CommonSelectorProps {}

export const JobTypeSelector: React.FC<Props> = ({
  className,
  name,
  label,
  ...props
}) => {
  return (
    <Select
      className={`${className || ""} altercap-jobType-selector`}
      name={name || "jobType"}
      label={label ?? "Tipo de trabajador"}
      options={JOB_TYPE_OPTIONS}
      {...props}
    />
  );
};
