export const budgetRecommendations = (data: any, fromFinerio: any) => {
  //TODO: Fix from line 31 to match finerioData, use both options with "if (fromFinerio)"
  // to display recommendations from Manual budget or Automatic budget with finerio

  let dataExpenses;
  let dataIncome;

  if (fromFinerio && data) {
    dataExpenses = {
      total: data?.expense ? data.expense : 0,
    };
    dataIncome = {
      total: data?.income ? data.income : 0,
    };
  } else {
    dataExpenses = data?.expenses;
    dataIncome = data?.income;
  }

  const MAX_VARIABLE_EXPENSE_PERCENTAGE = 30;
  const MAX_TAXI_UBER_EXPENSE_PERCENTAGE = 4;
  const MAX_SUBSCRIPTIONS_PERCENTAGE = 0.5;
  const MAX_TELECOMMUNICATIONS_PERCENTAGE = 6;
  const MAX_FREEWAYS_PERCENTAGE = 1.5;
  const MAX_GAS_PERCENTAGE = 3.5;
  const MAX_CREDITS_PERCENTAGE = 30;
  const MAX_MEDICINE_PERCENTAGE = 5;
  const MAX_FOOD_PERCENTAGE = getMaxFoodExpenses(dataIncome?.total);

  const listToReturn = [];
  const totalExpenses = dataExpenses?.total;
  const billsData = dataExpenses?.fixed?.[1]?.find(
    (element: any) => element?.name === "cuentas"
  );
  const incomeSpentPercentage = (
    (dataExpenses?.total / dataIncome?.total) * 100
  ).toFixed();
  const variableExpensePercentage = getPercentage(
    dataExpenses?.variable?.total,
    totalExpenses
  );
  const uberTaxiExpensePercentage = getPercentage(
    dataExpenses?.fixed?.[0]
      ?.find((element: any) => element?.name === "Transporte")
      ?.details?.find((element: any) => element?.text === "Taxi o uber")
      ?.amount,
    totalExpenses
  );
  const subscriptionsExpensePercentage = getPercentage(
    dataExpenses?.fixed?.[0]
      ?.find((element: any) => element?.name === "otros")
      ?.details?.find((element: any) => element?.text === "Suscripciones")
      ?.amount,
    totalExpenses
  );
  const freewaysExpensePercentage = getPercentage(
    billsData?.details?.find((element: any) => element?.text === "autopistas")
      ?.amount,
    totalExpenses
  );
  const gasExpensePercentage = getPercentage(
    dataExpenses?.fixed?.[0]
      ?.find((element: any) => element?.name === "Transporte")
      ?.details?.find((element: any) => element?.text === "Bencina")?.amount,
    totalExpenses
  );
  const medicineExpensePercentage = getPercentage(
    dataExpenses?.fixed?.[0]
      ?.find((element: any) => element?.name === "Salud")
      ?.details?.find((element: any) => element?.text === "Remedios constantes")
      ?.amount,
    totalExpenses
  );
  const creditsExpensePercentage = getPercentage(
    dataExpenses?.fixed?.[0]?.find(
      (element: any) => element?.name === "crédito y cuotas"
    )?.total,
    totalExpenses
  );
  const foodExpensePercentage = getPercentage(
    dataExpenses?.fixed?.[0]?.find(
      (element: any) => element?.name === "Alimentación"
    )?.total,
    totalExpenses
  );
  const telecommunicationsExpensePercentage = getPercentage(
    billsData?.details?.find((element: any) => element?.text === "celular")
      ?.amount +
      billsData?.details?.find((element: any) => element?.text === "internet")
        ?.amount +
      billsData?.details?.find((element: any) => element?.text === "tv cable")
        ?.amount,
    totalExpenses
  );
  //Rule total spent < total income
  if(incomeSpentPercentage === "Infinity") {
    listToReturn.push({
      text: `¡Ups! No tienes recomendaciones aún. Asegúrate de agregar tus ingresos y gastos para recibir tips para mejorar tus finanzas.`,
      priority: 0,
      category: "IngresoVSGasto",
    });
  }

  if (dataExpenses?.total < dataIncome?.total) {
    listToReturn.push({
      text: `Estás gastando un ${incomeSpentPercentage}% de tu ingreso todos los meses. Tienes espacio para ahorrar, te aconsejamos hacerlo al recibir tu sueldo para ser consistente.`,
      priority: 0,
      category: "IngresoVSGasto",
    });
  } else if (incomeSpentPercentage !== "Infinity") {
    listToReturn.push({
      text: `Estás gastando un ${incomeSpentPercentage}% de tu ingreso todos los meses. ¡Ojo! Tu gasto es mayor a tu ingreso, te entregaremos algunos consejos para ir mejorando de a poco tu salud financiera.`,
      priority: 0,
      category: "IngresoVSGasto",
    });
  }

  if (variableExpensePercentage > MAX_VARIABLE_EXPENSE_PERCENTAGE) {
    listToReturn.push({
      text: `Todos tus gastos variables corresponden a un ${variableExpensePercentage.toFixed()}% de tu presupuesto. Lo ideal es que no sean mayor a un ${MAX_VARIABLE_EXPENSE_PERCENTAGE.toFixed()}%. Te recomendamos elegir una categoría para trabajar en disminuir su gasto durante el siguiente mes.`, //, te aconsejamos [inserte categoría que tiene más gasto], ya que es en la que estas gastando más.`,
      priority: 1,
      category: "VariableCosts",
    });
  }

  if (uberTaxiExpensePercentage > MAX_TAXI_UBER_EXPENSE_PERCENTAGE) {
    listToReturn.push({
      text: `Revisa si algún tramo de tu trayecto es posible realizarlo en transporte público y así disminuir tu gasto en taxi o Uber.`,
      priority: 2,
      category: "Transporte",
    });
  }

  if (subscriptionsExpensePercentage > MAX_SUBSCRIPTIONS_PERCENTAGE) {
    listToReturn.push({
      text: `El gasto en suscripciones es alto, revisa cuales estas pagando y cuán importante son, y evalúa reducir las que no usas con frecuencia.`,
      priority: 3,
      category: "Subscriptions",
    });
  }

  if (telecommunicationsExpensePercentage > MAX_TELECOMMUNICATIONS_PERCENTAGE) {
    listToReturn.push({
      text: `Tu gasto en celular, internet y TV cable es alto. ¿Has revisado tus planes últimamente? Cada cierto tiempo los proveedores de estos servicios renuevan sus paquetes. Revisa si existen planes con iguales condiciones, pero más baratos.`,
      priority: 4,
      category: "InternetTvAndPhone",
    });
  }

  if (freewaysExpensePercentage > MAX_FREEWAYS_PERCENTAGE) {
    listToReturn.push({
      text: `Estás destinando un ${freewaysExpensePercentage.toFixed()}% de tu gasto en autopistas. Si no vas apurado/a, prueba utilizar la función sin TAG de Waze para disminuir tu gasto.`,
      priority: 5,
      category: "Freeway",
    });
  }

  if (gasExpensePercentage > MAX_GAS_PERCENTAGE) {
    listToReturn.push({
      text: `Revisa los descuentos que existen con tus tarjetas y medios de pago para planificar cuándo echar bencina y así ahorrar en tu gasto total mensual.`,
      priority: 6,
      category: "Gas",
    });
  }

  if (creditsExpensePercentage > MAX_CREDITS_PERCENTAGE) {
    listToReturn.push({
      text: `Estás destinando un ${creditsExpensePercentage.toFixed()}% de tu gasto a pagar la cuota de tu crédito. Para mantener tu salud financiera, el total de las cuotas de tus créditos no debería ser mayor a 30%. Te aconsejamos revisar la misión “¿Cómo se si me conviene refinanciar mi crédito?”.`,
      priority: 7,
      category: "Credits",
    });
  }

  if (medicineExpensePercentage > MAX_MEDICINE_PERCENTAGE) {
    listToReturn.push({
      text: `Observamos que tu gasto en remedios es alto, una buena opción es averiguar en qué días hay descuentos en cada farmacia y hacerte parte de su club de socios. Además, puedes revisar si existen alternativas genéricas de los medicamentos que estás tomando.`,
      priority: 8,
      category: "Medicines",
    });
  }

  if (foodExpensePercentage > MAX_FOOD_PERCENTAGE) {
    listToReturn.push({
      text: `Planifica tus compras para el mes, puedes utilizar el Observatorio de Precios del Sernac para ver en qué supermercado de tu comuna es más barato. Trata de evitar las idas al supermercado a hacer pequeñas compras e intenta preparar con anticipación lo que vas a consumir para evitar gastar en comida preparada.`,
      priority: 9,
      category: "Alimentacion",
    });
  }

  return listToReturn;
};

const getPercentage = (part: any, total: number) => {
  if (!part) {
    return 0;
  }
  return (part * 100) / total;
};

const getMaxFoodExpenses = (totalIncome: number) => {
  switch (true) {
    case totalIncome <= 370088:
      return 27;
    case totalIncome <= 627709:
      return 26;
    case totalIncome <= 885220:
      return 24;
    case totalIncome <= 1249709:
      return 20;
    default:
      return 14;
  }
};
