import TermFilterDefault from './term-filter-defaults.json';
import AllocationPercentages from './allocations-percentages.json';
import QuestionsScores from './questions-scores.json';
import ProfileScoreRanges from './profile-score-ranges.json';

import InvestTypeCategoryMapping from './invest-type-category-mapping.json';
import {
  InvestmentTermType,
  InvestorProfileType,
  InvestType,
  ProfileForm,
} from '@interfaces';
import {
  INVESTMENT_AFFIRMATION_OPTIONS,
  INVESTMENT_CONCERNS_OPTIONS,
  INVESTMENT_EXPERIENCE_OPTIONS,
  INVESTMENT_HORIZON_OPTIONS,
  INVESTMENT_LEVEL_OPTIONS,
  INVESTMENT_OBJECTIVE_OPTIONS,
  INVESTMENT_PRODUCT_OPTIONS,
  INVESTMENT_PROFESSION_OPTIONS,
  INVESTMENT_SAVINGS_OPTIONS,
} from '@components/Inputs';

export const getDefaultTermFilter = (
  investorProfile: InvestorProfileType | null,
  investType?: InvestType | null
): InvestmentTermType[] => {
  if (!investorProfile || !investType) return [];
  return TermFilterDefault[investorProfile][investType] as InvestmentTermType[];
};

export const getAllocationPercentages = (
  investorProfile: InvestorProfileType
): Record<InvestType, number> => {
  return AllocationPercentages[investorProfile];
};

type Field =
  | 'risk'
  | 'investmentExperience'
  | 'investmentConcern'
  | 'currentInvesting'
  | 'investmentDropResponse';
export const INVESTOR_PROFILE_FIELDS: Field[] = [
  'risk',
  'investmentExperience',
  'investmentConcern',
  'currentInvesting',
  'investmentDropResponse',
];
export const calculateSuggestedProfile = (
  selectedTerm: ProfileForm['investmentTerm'],
  formAnswers: Pick<ProfileForm, Field>
): { completed: boolean; profile: InvestorProfileType | null } => {
  if (!selectedTerm) return { completed: false, profile: null };
  const completed = INVESTOR_PROFILE_FIELDS.every((field) =>
    Boolean(formAnswers[field])
  );
  const score = INVESTOR_PROFILE_FIELDS.reduce(
    (acum, field) =>
      acum +
      ((QuestionsScores as Record<Field, Record<string, number>>)[field][
        formAnswers[field]
      ] ?? 0),
    0
  );

  const entry = Object.entries(ProfileScoreRanges[selectedTerm]).find(
    ([range]) => {
      const [min, max] = range.split('-').map(Number);
      return min <= score && score <= max;
    }
  ) as [string, InvestorProfileType];
  return { completed, profile: entry?.[1] ?? null };
};

const calculateTotalPoints = (
  questions: (keyof typeof jsonMapping)[],
  answers: Record<string, string>,
  jsonMapping: Record<string, Record<string, number>>
): number => {
  let totalSum = 0;

  questions.forEach((question) => {
    // Check if the question exists in the JSON
    if (question in jsonMapping) {
      const mapping = jsonMapping[question];
      const answer = answers[question];
      // Check if the answer exists in the mapping
      if (answer in mapping) {
        totalSum += mapping[answer];
      }
    }
  });

  return totalSum;
};
const determineRiskProfile = (
  value: number,
  profile: Record<string, { minimo: number; maximo: number }>
): string => {
  for (const [profileName, range] of Object.entries(profile)) {
    if (value >= range.minimo && value <= range.maximo) {
      return profileName;
    }
  }
  return 'No se pudo determinar el perfil'; // Otra categoría o mensaje de error si es necesario
};

export const calculateSuggestedProfile2 = (
  investmentObjective: string,
  investmentHorizon: string,
  product: string,
  affirmation: string,
  experience: string,
  concern: string,
  level: string,
  savings: string,
  profession: string
) => {
  if (
    !investmentObjective ||
    !investmentHorizon ||
    !product ||
    !affirmation ||
    !experience ||
    !concern ||
    !level ||
    !savings ||
    !profession
  )
    return { completed: false, profile: null };

  const totalSum = calculateTotalPoints(
    [
      'investmentObjective',
      'investmentHorizon',
      'product',
      'affirmation',
      'experience',
      'concern',
      'level',
      'savings',
      'profession',
    ],
    {
      investmentObjective: investmentObjective,
      investmentHorizon: investmentHorizon,
      product: product,
      affirmation: affirmation,
      experience: experience,
      concern: concern,
      level: level,
      savings: savings,
      profession: profession,
    },
    newProfileScore
  );
  const determinate = determineRiskProfile(totalSum, profile);

  return { completed: true, profile: determinate };
};

export const getCategoryFromInvestType = (
  investType?: InvestType | null
): string[] => {
  if (!investType) return [];
  return InvestTypeCategoryMapping[investType];
};

const newProfileScore = {
  investmentObjective: {
    [INVESTMENT_OBJECTIVE_OPTIONS[0].value]: 2.5,
    [INVESTMENT_OBJECTIVE_OPTIONS[1].value]: 5,
    [INVESTMENT_OBJECTIVE_OPTIONS[2].value]: 7.5,
    [INVESTMENT_OBJECTIVE_OPTIONS[3].value]: 10,
  },
  investmentHorizon: {
    [INVESTMENT_HORIZON_OPTIONS[0].value]: 2.5,
    [INVESTMENT_HORIZON_OPTIONS[1].value]: 5,
    [INVESTMENT_HORIZON_OPTIONS[2].value]: 7.5,
    [INVESTMENT_HORIZON_OPTIONS[3].value]: 10,
  },
  experience: {
    [INVESTMENT_EXPERIENCE_OPTIONS[0].value]: 3.8,
    [INVESTMENT_EXPERIENCE_OPTIONS[1].value]: 7.5,
    [INVESTMENT_EXPERIENCE_OPTIONS[2].value]: 11.3,
    [INVESTMENT_EXPERIENCE_OPTIONS[3].value]: 15,
  },
  product: {
    [INVESTMENT_PRODUCT_OPTIONS[0].value]: 5,
    [INVESTMENT_PRODUCT_OPTIONS[1].value]: 10,
    [INVESTMENT_PRODUCT_OPTIONS[2].value]: 15,
    [INVESTMENT_PRODUCT_OPTIONS[3].value]: 20,
  },
  affirmation: {
    [INVESTMENT_AFFIRMATION_OPTIONS[0].value]: 5,
    [INVESTMENT_AFFIRMATION_OPTIONS[1].value]: 10,
    [INVESTMENT_AFFIRMATION_OPTIONS[2].value]: 15,
    [INVESTMENT_AFFIRMATION_OPTIONS[3].value]: 20,
  },

  concern: {
    [INVESTMENT_CONCERNS_OPTIONS[0].value]: 5,
    [INVESTMENT_CONCERNS_OPTIONS[1].value]: 10,
    [INVESTMENT_CONCERNS_OPTIONS[2].value]: 15,
    [INVESTMENT_CONCERNS_OPTIONS[3].value]: 20,
  },
  level: {
    [INVESTMENT_LEVEL_OPTIONS[0].value]: 1.3,
    [INVESTMENT_LEVEL_OPTIONS[1].value]: 2.5,
    [INVESTMENT_LEVEL_OPTIONS[2].value]: 3.8,
    [INVESTMENT_LEVEL_OPTIONS[3].value]: 5,
  },
  savings: {
    [INVESTMENT_SAVINGS_OPTIONS[0].value]: 5,
    [INVESTMENT_SAVINGS_OPTIONS[1].value]: 10,
    [INVESTMENT_SAVINGS_OPTIONS[2].value]: 15,
  },
  profession: {
    [INVESTMENT_PROFESSION_OPTIONS[0].value]: 20,
    [INVESTMENT_PROFESSION_OPTIONS[1].value]: 15,
    [INVESTMENT_PROFESSION_OPTIONS[2].value]: 10,
    [INVESTMENT_PROFESSION_OPTIONS[3].value]: 5,
  },
};

const profile = {
  Conservador: {
    minimo: 0,
    maximo: 67.49,
  },
  Moderado: {
    minimo: 67.5,
    maximo: 83.74,
  },
  Agresivo: {
    minimo: 83.75,
    maximo: 135,
  },
};
