import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useStyles } from "./SearchBar.style";

type Props = {
  onChange: (e) => void;
};

export const SearchBar: React.FC<Props> = ({onChange}: Props) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.inputContainer}>
        <form>
          <input type="text" placeholder="Buscar" className={classes.inputBar} onChange={(e) => onChange(e)}/>
        </form>
      </div>
    </>
  );
};
