import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down(1239)]: {
      flexDirection: "column",
    },
  },
  leftSide: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    width: "60%",
    fontFamily: "Montserrat",
    fontSize: "1.25rem",
    fontWeight: 500,
    lineHeight: 1.9,
    textAlign: "left",
    [theme.breakpoints.down(1239)]: {
      order: 2,
      width: "100%",
      fontSize: "0.875rem",
    },
    "& .helperText": {
      color: theme.palette.primary.main,
      marginBottom: "2rem",
      [theme.breakpoints.down(1239)]: {
        margin: "2rem 1rem 2rem",
      },
    },
    "& .subtitle": {
      fontWeight: "bold",
      lineHeight: 2.2,
      color: theme.palette.text.primary,
      marginTop: "2.7rem",
    },
    "& .listItem": {
      fontSize: "1rem",
      lineHeight: 1.88,
      width: "140%",
      color: theme.palette.text.primary,
      [theme.breakpoints.down(1239)]: {
        width: "100%",
        fontSize: "0.75rem",
      },
    },
  },
  textContainer: {
    fontFamily: "Montserrat",
    fontSize: "1.25rem",
    fontWeight: 500,
    lineHeight: 1.9,
    textAlign: "left",
    color: theme.palette.text.primary,
    [theme.breakpoints.down(1239)]: {
      fontSize: "0.75rem",
      fontWeight: "normal",
      lineHeight: 1.67,
    },
  },
  rightSide: {
    width: "50%",
    marginLeft: "5rem",
    [theme.breakpoints.down(1239)]: {
      order: 1,
      width: "100%",
      margin: "0",
    },
    "& >img": {
      width: "21rem",
      margin: "auto",
      [theme.breakpoints.down(1239)]: {
        width: "48%",
        maxWidth: "15rem",
      },
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "5rem",
    [theme.breakpoints.down(1239)]: {
      justifyContent: "center",
      marginTop: "2rem",
    },
  },
}));
