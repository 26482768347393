import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    height: "100vh",
  },
  background: {
    position: "absolute",
    backgroundImage: "linear-gradient(165deg, #0a5669 3%, #00a9c2 343%)",
    height: "20rem",
    top: 0,
    width: "100%",
    zIndex: 0,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    padding: "2rem 10%",

    "& .profile": {
      fontFamily: "Montserrat",
      fontWeight: "bold",
      fontSize: "1.25rem",
      color: "#ffffff",
      lineHeight: "1.63rem",
      zIndex: 2,
    },
  },
  welcome: {
    padding: "0rem 10%",

    "& .texasContainer": {
      display: "flex",
      "& .imageContainer": {
        zIndex: 2,
      },
      "& .textContainer": {
        display: "flex",
        marginLeft: "1rem",
        flexDirection: "column",
        justifyContent: "space-around",
        fontFamily: "Montserrat",
        fontWeight: 500,
        color: "#ffffff",
        "& .welcome": {
          opacity: 0.5,
          fontSize: "0.75rem",
          zIndex: 2,
        },
        "& .name": {
          fontSize: "1rem",
          zIndex: 2,
        },
      },
    },
  },
  menuItemDisabled: {
    opacity: 0.3,
  },
  menuContainer: {
    width: "100%",
    height: "100%",
    backgroundColor: "#F4F3F3",
    borderRadius: "1.875rem",
    padding: "1.5rem",
  },
  menu: {},
  menuItem: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.primary,
    justifyContent: "space-between",
    "& .left": {
      display: "flex",
      alignItems: "center",
      "& .text": {
        marginLeft: "1.6rem",
      },
    },
  },
  separator: {
    height: "0.06rem",
    margin: "1rem 0",
    border: "solid 0.06rem #d6d6d6",
  },
  opacitySeparator: {
    opacity: 0.4,
    marginLeft: "10%",
    marginRight: "10%",
  },
  logoutButton: {},
}));
