import { ApolloClient, gql } from "@apollo/client";

const VAL_EX_RUT_QUERY = gql`
  query ValidateExistingRut($rut: String!) {
    validateExistingRut(rut: $rut)
  }
`;

type ValidateExistingRutParams = {
  rut: string;
};

type ValidateExistingRutResult = {
  validateExistingRut: boolean;
};

export async function validateExistingRut(
  rut: string,
  client: ApolloClient<object>,
): Promise<boolean> {
  const result = await client.query<
    ValidateExistingRutResult,
    ValidateExistingRutParams
  >({
    query: VAL_EX_RUT_QUERY,
    variables: { rut },
  });
  return !!result.data?.validateExistingRut;
}
