import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    cursor: "pointer",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 300,
    color: theme.palette.text.secondary,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "17.6rem",
    border: `1px solid ${theme.palette.text.disabled}`,
    borderRadius: 10,
    padding: "0.5rem 1.25rem",
    [theme.breakpoints.down(1300)]: {
      width: "100%",
      padding: "0.5rem 1.25rem",
      maxWidth: "17.6rem",
    },
    "& span": {
      display: "block",
    },
    "& svg": {
      color: theme.palette.text.disabled,
      width: "1.1rem",
    },
    "& >div": {
      lineHeight: "1.33rem",
    },
  },
  title: {
    fontWeight: "bold",
    display: "block",
    color: theme.palette.text.primary,
    [theme.breakpoints.down(1300)]: {
      fontSize: "0.875rem",
    },
  },
  disabled: {
    opacity: 0.8,
    pointerEvents: "none",
  },
}));
export default useStyles;
