import { ApolloClient, gql, useQuery } from "@apollo/client";
import { Mission } from "@interfaces";

export const GET_MISSION = gql`
  query GetMission($getMissionId: ID!) {
    getMission(id: $getMissionId) {
      description
      shortDescription
      condition
      roks
      name
      nextMission
      estimateTimeMin
      category
      subCategory
      subCategoryNumber
    }
  }
`;

type MissionParams = {
  getMissionId: number;
};

type QueryResult = {
  getMission: Mission;
}

interface HookReturn {
  missionData: Mission;
  loading: boolean;
}

export const getMission = (getMissionId: number): HookReturn => {
  const { data, loading } = useQuery<QueryResult, QueryParams>(
    GET_MISSION,
    { variables: { getMissionId }, skip: !getMissionId },
  );
  return { missionData: data ?.getMission ?? null, loading };
};
