import { FundType } from "@interfaces";
import { parseNumber, sharesRoundingDigits } from "@utils";
import { round } from "lodash";
import { calculateShares } from "utils";

interface HookReturn {
  remaining: number;
  shareValue: number;
  notValidAmount: boolean;
  helperText?: string;
}

export const useInvestmentHelperText = (
  amount: number,
  minInvest: number | null,
  shareValue: number | null,
  suggestedPortfolio?: boolean,
  fundType?: FundType,
): HookReturn => {
  if (!fundType || !amount || !shareValue)
    return {
      remaining: 0,
      shareValue: 0,
      notValidAmount: false,
      helperText: undefined,
    };

  const precision = sharesRoundingDigits(fundType);

  const boughtShares = calculateShares(amount, shareValue, fundType);

  const remaining = round(amount - shareValue * boughtShares, precision);

  const notValidAmount = Boolean(amount && minInvest && amount < minInvest);

  let helperText = "";
  if (minInvest && notValidAmount) {
    helperText = `El monto mínimo es $${parseNumber(minInvest, 0, 0)}`;
  } else if (!suggestedPortfolio) {
    helperText = `Comprarás ${parseNumber(boughtShares, 0, precision)} cuota${
      boughtShares !== 1 ? "s" : ""
    } ${
      remaining > 0
        ? `quedando $${parseNumber(remaining, 0, precision)} en caja`
        : ""
    }`;
  }

  return {
    remaining,
    shareValue,
    notValidAmount,
    helperText,
  };
};
