import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  colorGray: {
    color: theme.palette.text.secondary,
  },
  container: {
    width: "auto",
    flexGrow: 0,
    padding: "34px 68px 40px",
    maxWidth: 1140,
    boxShadow: "4px 4px 20px 2px rgba(0, 0, 0, 0.2)",
    background: "white",
    margin: "1rem auto 10rem",
    borderRadius: 30,
    color: theme.palette.text.primary,
    [theme.breakpoints.down(1241)]: {
      width: "auto",
      margin: "20px auto",
      padding: 21,
    },
    "& .simulatorTitle": {
      color: theme.palette.text.primary,
      width: 321,
      height: 29,
      fontSize: 24,
      fontWeight: "bold",
      margin: "0 0 10px",
      fontFamily: "Montserrat",
      [theme.breakpoints.down(1241)]: {
        fontSize: 16,
      },
    },
    "& .helpText": {
      margin: "0.625rem 0rem 2.1875rem 0",
      fontFamily: "Montserrat",
      fontSize: "1rem",
      textAlign: "left",
      color: theme.palette.text.secondary,
      [theme.breakpoints.down(1241)]: {
        fontSize: 12,
      },
    },
    "& .Agregar-otro": {
      width: 99,
      height: 35,
      fontSize: 16,
      fontWeight: 500,
      textAlign: "left",
    },
  },
  formControlSimulator: {
    margin: 10,
  },
  centerSection: {
    [theme.breakpoints.down(1241)]: {
      textAlign: "center",
    },
  },
  SimulatorBottomSection: {
    display: "flex",
    justifyContent: "space-between",
    "& >div": {
      [theme.breakpoints.down(1241)]: {
        margin: "10px 0",
      },
    },
    [theme.breakpoints.down(1241)]: {
      display: "inline",
      justifyContent: "center",
      flexDirection: "column",
      alignContent: "space-around",
      alignItems: "center",
    },
  },
  userIncomes: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    [theme.breakpoints.down(1241)]: {
      display: "flex",
      flexWrap: "nowrap",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  incomeHelperText: {
    width: 222,
    height: 41,
    margin: "18px 33px 0 0",
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: 300,
    textAlign: "left",
  },
  incomeImage: {
    width: 80,
    height: 80,
    margin: "4px 17px 0 0",
    borderRadius: 10,
    [theme.breakpoints.down(1241)]: {
      width: 70,
      height: 70,
    },
  },
  incomeDetail: {
    margin: 20,
    display: "grid",
    gridTemplateColumns: "80px 170px",
    gridColumnGap: 10,
    gridRowGap: 10,
    [theme.breakpoints.down(1241)]: {
      margin: "1rem auto",
    },
    "& .incomeName": {
      textTransform: "uppercase",
      width: 147,
      height: 22,
      fontFamily: "Montserrat",
      fontSize: 14,
      fontWeight: 400,
      textAlign: "left",
      color: theme.palette.text.secondary,
    },
    "& .incomeInput": {
      gridArea: "2 / 2 / 3 / 3",
      marginTop: 10,
      boxShadow: "4px 4px 8px 2px rgba(0, 0, 0, 0.1)",
      height: 40,
      "& div": {
        height: 40,
      },
    },
  },
  buttonPrimary: {
    width: 184,
    height: 53,
    alignItems: "center",
    padding: "12px 16px",
    borderRadius: 10,
    backgroundColor: "#4619b6",
    boxShadow: "2px 2px 7px 0 rgba(0, 0, 0, 0.3)",
    margin: "0 auto",
    [theme.breakpoints.down(1241)]: {
      display: "flex",
    },
  },
  addIcon: {
    color: theme.palette.primary.main,
    width: 30,
    height: 30,
  },
  greyText: {
    "& >span": {
      fontSize: "1rem",
      fontWeight: 500,
      fontFamily: "Montserrat",
      lineHeight: 2.31,
      color: theme.palette.text.primary,
      [theme.breakpoints.down(1241)]: {
        fontSize: "0.875rem",
        textAlign: "left",
        lineHeight: 1.31,
        margin: "0.5rem 0",
      },
    },
  },
  [theme.breakpoints.down(1300)]: {
    formSectiom: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& .centrator": {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "flex-start",
        "& .axis": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        },
      },
    },
  },
}));
