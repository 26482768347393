import { ApolloClient, gql } from "@apollo/client";

const SEND_CONTACT_FORM_MUTATION = gql`
  mutation CreateContactFromRefinance(
    $data: CreateContactFormRefinanceParams!
  ) {
    createContactFormRefinance(data: $data)
  }
`;

type CreateUserParams = {
  data: {
    name: string;
    email: string;
    salaryFile?: string;
    salaryAmount: string;
    creditInformation: JSON;
    debtReport?: string;
  };
};

type CreateUserResult = {
  createContactForm: boolean;
};

export async function sendContactFormRefinance(
  payload: CreateUserParams["data"],
  client: ApolloClient<object>
): Promise<boolean> {
  console.log(payload, "mmm");
  const result = await client.mutate<CreateUserResult, CreateUserParams>({
    mutation: SEND_CONTACT_FORM_MUTATION,
    variables: { data: payload },
  });
  return !!result.data?.createContactForm;
}
