import { gql, useQuery } from "@apollo/client";
import { UserTransactionsSummary } from "@interfaces";

export const GET_TRANSACTIONS_SUMMARY_QUERY = gql`
  query getUserTransactionsSummary {
    getUserTransactionsSummary {
      createdAt
      userId
      type
      portfolioId
      amount
    }
  }
`;

interface QueryResult {
  getUserTransactionsSummary: UserTransactionsSummary[];
}

interface HookReturn {
  userTransactionsSummary: UserTransactionsSummary[];
  loading: boolean;
}

export const getUserTransactionsSummary = (): HookReturn => {
  const { data, loading } = useQuery<QueryResult>(GET_TRANSACTIONS_SUMMARY_QUERY);
  return { userTransactionsSummary: data?.getUserTransactionsSummary ?? [], loading };
};
