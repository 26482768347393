import React from "react";
import useGeneralStyles from "@components/Services/styles";
import useStyles from "../../../Services/Credits/Credits/Credits/Searching/Searching.styles";

import { BACK_ARROW_ICON } from "images/GoogleCloud";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useMediaQuery } from "@material-ui/core";

interface headerProps {
  title?: string;
  steps?: string[];
  onBackClick?: () => void;
}
const HeaderServices: React.FC<headerProps> = ({
  title,
  steps,
  onBackClick,
}) => {
  const classesGeneral = useGeneralStyles();
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 1300px)");

  return (
    <div className={`${classesGeneral.safe} ${isMobile && classes.center}`}>
      <img src={BACK_ARROW_ICON} onClick={onBackClick} />
      {title && (
        <span className={`${classes.title} ${steps && "noOpacity"} `}>
          {title}
        </span>
      )}
      {!isMobile &&
        steps &&
        steps.map((step, i) => (
          <>
            {title && <ArrowForwardIosIcon />}
            <span className={`spaceCredit ${steps[i + 1] && "noOpacity"}`}>
              {step}
            </span>
          </>
        ))}
    </div>
  );
};
export default HeaderServices;
