import { ApolloClient, gql } from "@apollo/client";

const VAL_EX_EMAIL_QUERY = gql`
  query ValidateExistingEmail($email: String!) {
    validateExistingEmail(email: $email)
  }
`;

type ValidateExistingEmailParams = {
  email: string;
};

type ValidateExistingEmailResult = {
  validateExistingEmail: boolean;
};

export async function validateExistingEmail(
  email: string,
  client: ApolloClient<object>,
): Promise<boolean> {
  const result = await client.query<
    ValidateExistingEmailResult,
    ValidateExistingEmailParams
  >({
    query: VAL_EX_EMAIL_QUERY,
    variables: { email },
    fetchPolicy: "network-only",
  });

  return !!result.data?.validateExistingEmail;
}
