import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "auto",
    padding: "4rem 11.25rem 2rem",
    backgroundColor: "#F4F3F3",
    [theme.breakpoints.down(1300)]: {
      borderRadius: "30px 30px 0 0",
      padding: "0",
    },
  },
  topSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    [theme.breakpoints.down(1300)]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      height: "auto",
      marginTop: "-3rem",
      padding: "1rem 1.75rem 1.875rem",
      backgroundColor: "#ffffff",
      borderRadius: "1.875rem",
      boxShadow: "0.25rem 0.25rem 1.25rem 0.125rem rgba(0,0,0,0.2)",
      fontSize: 14,
    },
    "& .item": {
      marginRight: "5.5rem",
      [theme.breakpoints.down(1300)]: {
        margin: "1rem 0",
      },
      "& input": {
        height: "2rem",
        backgroundColor: "#ffffff",
      },
      "& .rateContainer": {
        width: "14.5rem",
        height: "3.875rem",
        padding: "0.725rem 0.9625rem 0.225rem 1.6938rem",
        borderRadius: "0.625rem",
        boxShadow: "0.25rem 0.25rem 0.25rem 0 rgba(0,0,0,0.2)",
        border: "solid 1.3px #858c94",
        backgroundColor: "#ffffff",
        fontSize: 20,
        fontWeight: 400,
        textAlign: "left",
        color: theme.palette.text.secondary,
      },
    },
  },
  middleSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "3.25rem 10rem 3.25rem 0",
    padding: "2.625rem 2.875rem 2.25rem 4.3125rem",
    borderRadius: "1.875rem",
    boxShadow: "0.25rem 0.25rem 1.25rem 0.125rem rgba(0,0,0,0.2)",
    border: "solid 2px #8352fd",
    backgroundColor: "#ffffff",
    [theme.breakpoints.down(1300)]: {
      display: "flex",

      flexDirection: "column",
      margin: "2.125rem 3rem",
    },
    [theme.breakpoints.down(500)]: {
      margin: "2.125rem 1.25rem",
    },
    "& .creditInfo": {
      display: "flex",
      flexDirection: "column",
      "& .description": {
        // height: "1.25rem",
        margin: "0 0 0.15rem",
        fontFamily: "Montserrat",
        fontSize: 16,
        textTransform: "uppercase",
        fontWeight: 400,
        textAlign: "left",
        color: theme.palette.text.secondary,
      },
      "& .value": {
        // height: "2.75rem",
        margin: "0.25rem 3.1875rem 0 0",
        fontFamily: "Montserrat",
        fontSize: 24,
        fontWeight: "bold",
        lineHeight: 1.83,
        textAlign: "left",
        color: theme.palette.text.primary,
      },
    },
    "& .centrator": {},
  },
  bottomSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: "3rem",
    [theme.breakpoints.down(1300)]: {
      flexDirection: "column",
      alignItems: "center",
      marginBottom: "0",
    },
    "& >button": {
      marginRight: "3.5rem",
      [theme.breakpoints.down(1300)]: {
        margin: "2.125rem 0 ",
        width: "16.875rem",
      },
    },
  },
  text: {
    fontFamily: "Montserrat",
    fontSize: 16,
    lineHeight: 1.88,
    textAlign: "left",
    color: theme.palette.text.secondary,
    "& .style1": {
      fontWeight: "bold",
    },
  },
  secondBottomSection: {
    width: "30rem",
    "& .textBottom": {
      fontFamily: "Montserrat",
      fontSize: 16,
      lineHeight: 1.88,
      textAlign: "left",
      color: theme.palette.text.secondary,
      "& .style1": {
        fontWeight: "bold",
      },
    },
  },
}));
