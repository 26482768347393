import React, { useCallback } from "react";
import { Select } from "@components";
import { CommonSelectorProps, CREDIT_CATEGORY_OPTIONS } from "..";

interface Props extends CommonSelectorProps {}

export const CreditCategorySelector: React.FC<Props> = ({
  className,
  name,
  label,
  disabled,
  placeholder,
  ...props
}) => {
  return (
    <Select
      className={`${className || ""} altercap-credit-category-selector`}
      name={name || "Category"}
      label={label || "Crédito de consumo"}
      disabled={disabled}
      placeholder={placeholder}
      options={CREDIT_CATEGORY_OPTIONS}
      {...props}
    />
  );
};
