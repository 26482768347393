import {
  FORM_FIELDS,
  GoalForm,
  ProfileForm,
  ProfileFormErrors,
} from "../interfaces";
import { validateEmail } from "./validations";

export const checkRequiredFields = (
  form: ProfileForm | GoalForm,
  keys?: string[],
): boolean => {
  const _keys = keys ?? FORM_FIELDS;
  return Object.entries(form)
    .filter(([key]) => _keys.includes(key))
    .every(([, value]) => Boolean(value));
};

export const checkValidForm = (
  form: ProfileForm | GoalForm,
  keys?: string[],
): { errors: ProfileFormErrors; hasError: boolean } => {
  const _keys = (keys ?? FORM_FIELDS).filter(k => k !== "wantInfo");
  const errors: ProfileFormErrors = {};
  Object.entries(form)
    .filter(([key]) => _keys.includes(key))
    .forEach(([key, value]) => {
      errors[key as keyof ProfileFormErrors] = !value;
    });
  //TODO usar la function de utils
  if (_keys.includes("email") && "email" in form)
    errors.email = !validateEmail(form.email);
  if (_keys.includes("birthdate") && "birthdate" in form)
    errors.birthdate = form.birthdate === null;
  return {
    errors,
    hasError: Object.values(errors).some(Boolean),
  };
};
