import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {},
  columnsContainer: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down(1300)]: {
      flexDirection: "column",
    },
  },
  title: {
    fontFamily: "Montserrat",
    fontSize: "2rem",
    fontWeight: "bold",
    marginLeft: "3.4rem",
    color: "#8352fd",
    [theme.breakpoints.down(1300)]: {
      marginLeft: "1.4rem",
    },
  },
  column: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down(1300)]: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      justifyContent: "flex-start",
      overflowX: "auto",
      padding: "1rem 0rem",
    },
    [theme.breakpoints.up(1300)]: {
      "& :nth-child(2)": {
        height: "12.5rem",
      },
      "& :nth-child(3)": {
        height: "12.5rem",
      },
      "& :nth-child(4)": {
        height: "14.875rem",
      },
    },
    "& .whiteBox": {
      width: "30.813rem",
      margin: "1.186rem 4.28rem 0 0",
      padding: "2.125rem 1.65rem ",
      borderRadius: "1.25rem",
      fontFamily: "Montserrat",
      boxShadow: "0.25rem 0.25rem 0.5rem 0.125rem rgba(0,0,0,0.2)",
      backgroundColor: theme.palette.background.paper,
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down(1300)]: {
        height: "24.188rem",
        padding: "1.2rem",
        margin: "0 0.4rem 0",
        alignItems: "center",
      },
      "& .title": {
        fontSize: "1rem",
        fontWeight: "bold",
        lineHeight: 1.5,
        textAlign: "left",
        textTransform: "uppercase",
        color: theme.palette.text.secondary,
        [theme.breakpoints.down(1300)]: {},
      },
      "& .text": {
        fontSize: "0.85rem",
        lineHeight: 1.43,
        textAlign: "left",
        color: theme.palette.text.primary,
        [theme.breakpoints.down(1300)]: {
          fontSize: "0.75rem",
          width: "14.1rem",
        },
      },
    },
  },
  bottomSection: {
    textAlign: "right",
    margin: "7rem 4.28rem 7rem 0",
    [theme.breakpoints.down(1300)]: {
      textAlign: "center",
      margin: "3rem auto",
    },
  },
}));
