import React, { useCallback, useEffect, useState } from "react";
import { TextField, TextFieldProps, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  COMPONENT_SIZES,
  getPaddingValue,
  getTransformYValue,
  SizeType,
} from "@styles";
import { useResponsiveInputSize } from "@hooks";
import { OnBlurEvent, OnChangeEvent } from "@interfaces";

type Props = Omit<TextFieldProps, "size"> & { size?: SizeType };

export const SizedTextField: React.FC<Props> = ({
  className = "",
  size: _size,
  onBlur,
  onChange,
  value,
  error,
  disabled,
  ...props
}) => {
  const size = useResponsiveInputSize({ size: _size });
  const classes = useStyles({ size });
  const [requiredError, setRequiredError] = useState(false);

  // Display error when no input and bluring
  const onBlurWrapper = useCallback(
    (e: OnBlurEvent) => {
      onBlur && onBlur(e);
      if (!value && !disabled) setRequiredError(true);
    },
    [onBlur, value, disabled, setRequiredError],
  );

  // Remove error when writing
  const onChangeWrapper = useCallback(
    (e: OnChangeEvent) => {
      onChange && onChange(e);
      const value = e.target.value;
      if (!value) setRequiredError(true);
      else setRequiredError(false);
    },
    [onChange, setRequiredError],
  );

  useEffect(() => {
    if (disabled) setRequiredError(false);
  }, [disabled, setRequiredError]);

  return (
    <TextField
      className={`${classes.root} ${className}`}
      variant="outlined"
      {...props}
      disabled={disabled}
      value={value}
      error={error === false ? false : requiredError}
      onBlur={onBlurWrapper}
      onChange={onChangeWrapper}
    />
  );
};

type StyleProps = { size: SizeType };

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  root: {
    height: ({ size }) => COMPONENT_SIZES[size],
    "& > .MuiInputBase-root > input": {
      paddingTop: ({ size }) => getPaddingValue(size),
      paddingBottom: ({ size }) => getPaddingValue(size),
    },
    "& > .MuiInputLabel-root": {
      "& .MuInputLabel-shrink": {
        transform: "translate(14px, -6px) scale(0.75)",
      },
      "&:not(.MuiInputLabel-shrink)": {
        transform: ({ size }) =>
          `translate(14px, ${getTransformYValue(size)}px) scale(1)`,
      },
    },
    "& > .MuiFormLabel-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.60)",
    },
    "& .MuiInputBase-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.6)" // (default alpha is 0.38)
    }
  },
}));
