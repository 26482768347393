export * from './forms';
export * from './routes';
export * from './files';
export * from './register';
export * from './register_save_by_rokin';
export * from './investor_peofile';
export * from './goal';
export * from './user';
export * from './managers';
export * from './fund';
export * from './investment';
export * from './funds_series';
export * from './filters';
export * from './plots';
export * from './suggestedPortfolioData';
export * from './portfolios';
export * from './congratsModal';
export * from './movements';
export * from './transactions';
export * from './mission';
export * from './user_mission';
export * from './termAndRate';
export * from './events';
export * from './company';
export * from './finerioAccount';
export * from './finerioTransaction';
export * from './finerioBank';
export * from './finerioGlobals';
export * from './finerioBankRequested';
export * from './finerioCredential';
export * from './finerioSubcategory';
export * from './finerioCategory';
export * from './coolebraTypeAll';
export * from './subscriptionPlans';
export * from './userSubscriptions';
export * from './userFinancialData';
export * from './financialAnalysis';

export interface SelectOption {
  group?: string;
  name: string;
  value: string | number;
  disabled?: boolean;
}

export type OnChangeType = React.ChangeEventHandler<{
  name?: string | undefined;
  value: unknown;
}>;

export type ChangeEvent = React.ChangeEvent<{
  name?: string | undefined;
  value: unknown;
}>;

export type ChangeEventTarget = React.SyntheticEvent<{
  value: string | number;
  name?: string | undefined;
}>;

export type OnKeyPressType = (
  event: React.KeyboardEvent<HTMLInputElement>
) => void;

export enum ExistenceValidationType {
  MUST_EXISTS = 'MUST_EXISTS',
  MUST_NOT_EXISTS = 'MUST_NOT_EXISTS',
}

export type OnBlurEvent = React.FocusEvent<
  HTMLInputElement | HTMLTextAreaElement
>;

export type OnChangeEvent = React.ChangeEvent<
  HTMLInputElement | HTMLTextAreaElement
>;
