import React from "react";

import { useStyles } from "../calendar.styles";

const MonthlyCalendarDays = (props: {
  day: {
    getFullYear: () => number;
    getMonth: () => number;
    toDateString: () => string;
  };
  eventDays: string[] | null;
  changeCurrentDay: (arg0: {
    currentMonth: boolean;
    date: Date;
    month: number;
    number: number;
    selected: boolean;
    year: number;
  }) => void;
  togglePage: () => void;
}) => {
  const today = new Date();

  let firstDayOfMonth = new Date(
    props.day.getFullYear(),
    props.day.getMonth(),
    1
  );
  let weekdayOfFirstDay = firstDayOfMonth.getDay();
  let currentDays = [];

  for (let day = 0; day < 42; day++) {
    if (day === 0 && weekdayOfFirstDay === 0) {
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() - 7);
    } else if (day === 0) {
      firstDayOfMonth.setDate(
        firstDayOfMonth.getDate() + (day - weekdayOfFirstDay)
      );
    } else {
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1);
    }

    let calendarDay = {
      currentMonth: firstDayOfMonth.getMonth() === props.day.getMonth(),
      date: new Date(firstDayOfMonth),
      month: firstDayOfMonth.getMonth(),
      number: firstDayOfMonth.getDate(),
      selected: firstDayOfMonth.toDateString() === props.day.toDateString(),
      year: firstDayOfMonth.getFullYear(),
    };

    currentDays.push(calendarDay);
  }

  // restar 1 dia a cada fecha de eventDays
  const eventDaysFixed = props.eventDays?.map((day) => {
    const date = new Date(day);
    date.setDate(date.getDate() - 1);
    return date.toISOString().split("T")[0];
  });

  return (
    <div className="table-content">
      {currentDays.map((day) => {
        return (
          <div
            key={day.date.toDateString()}
            className={
              "calendar-day" +
              (day.currentMonth ? " current" : "") +
              (day.selected ? " selected" : "") +
              (day.date.toDateString() === today.toDateString()
                ? " today"
                : "") +
              (eventDaysFixed?.includes(day.date.toISOString().split("T")[0])
                ? " event-day"
                : "")
            }
            onClick={() => props.changeCurrentDay(day)}
          >
            <p>{day.number}</p>
            {eventDaysFixed?.includes(day.date.toISOString().split("T")[0]) && (
              <div className="event-dot"></div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default MonthlyCalendarDays;
