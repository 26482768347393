import React from "react";
import { Phone, Not_1, Not_2, Not_3 } from "@images";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import EmailContent from "./EmailContent";

const UserRegister: React.FC = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 1100px)");

  return (
    <>
      <form className={classes.upLeft}>
        <div className={classes.containerRegister}>
          <span className={classes.registerTextUp}>
            <span className={classes.registerTextDown}>
              Aumenta tus ahorros en cada compra <br />
            </span>
            y genera impacto social
          </span>
          <div className={classes.register}>
            <span className={classes.registerTextUpInput}>
              Inscríbete para ahorrar e impactar
            </span>
            <div className={classes.emailInput}>
              <EmailContent />
            </div>
          </div>
        </div>
      </form>
      <div className={`${classes.phone}`}>
        <img className={!isMobile ? "phone" : ""} src={Phone} alt="phone" />
        {!isMobile && (
          <>
            <img className="noti" src={Not_1} alt="" />
            <img className="noti2" src={Not_2} alt="" />
            <img className="noti3" src={Not_3} alt="" />
          </>
        )}
      </div>
    </>
  );
};

export default UserRegister;

const useStyles = makeStyles(theme => ({
  phone: {
    position: "relative",
    zIndex: 2,
    marginLeft: 130,
    [theme.breakpoints.down(1100)]: {
      paddingRight: 0,
      width: "75%",
      margin: "0 auto",
    },
    "& .phone": {
      animationName: "$phone",
      animationDuration: "4s",
      animationDirection: "reverse",
      animationTimingFunction: "ease",
      animationIterationCount: "1",
      position: "relative",
      transform: "translate(0px, 0)",
    },
    "& .noti": {
      animationDuration: "2s",
      animationDirection: "normal",
      animationTimingFunction: "ease",
      animationIterationCount: "1",
      position: "absolute",
      top: 50,
      transform: "translate(0px, 0)",
      animation: "$noti 3.5s 3.8s both",
      zIndex: -1,
    },
    "& .noti2": {
      animationDuration: "2s",
      animationDirection: "normal",
      animationTimingFunction: "ease",
      animationIterationCount: "1",
      position: "absolute",
      top: 105,
      animation: "$noti 3.5s 4.2s both",
      zIndex: -1,
      transform: "translate(0px, 0)",
    },
    "& .noti3": {
      animationDuration: "2s",
      animationDirection: "normal",
      animationTimingFunction: "ease",
      animationIterationCount: "1",
      position: "absolute",
      top: 160,
      animation: "$noti 3.5s 4.6s both",
      zIndex: -1,
      transform: "translate(0px, 0)",
    },
    "@global": {
      "@keyframes noti": {
        "0%": {
          opacity: "0",
          transform: "translate(0px, 0)",
        },
        "100%": {
          transform: "translate(-180px, 0)",
        },
      },
      "@keyframes phone": {
        "0% ": {
          transform: "translate(0px, 0)",
        },
        "50%": {
          transform: "translate(-150px, 0)",
        },
        "60%": {
          opacity: 1,
        },
        "100%": {
          opacity: "0",
          transform: "translate(600px, 0)",
        },
      },
    },
    "& img": {
      width: 275,
      [theme.breakpoints.down(1100)]: {
        margin: "0 auto",
      },
    },
  },
  upLeft: {
    display: "grid",
    alignItems: "center",
    position: "relative",
    [theme.breakpoints.down(1100)]: {
      paddingLeft: 0,
    },
  },
  registerTextUp: {
    margin: "0 0 30px",
    fontFamily: "Montserrat",
    fontSize: 30,
    fontWeight: 500,
    display: "block",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#484848",
    width: 375,
    [theme.breakpoints.down(1100)]: {
      margin: "0 auto",
      textAlign: "center",
      fontSize: 26,
      width: 330,
    },
  },
  registerTextDown: {
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  registerTextUpInput: {
    margin: "56px 78px 19px 0",
    fontFamily: "Montserrat",
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1.46,
    color: theme.palette.primary.dark,
    display: "block",
    [theme.breakpoints.down(1300)]: {
      width: 340,
    },
    [theme.breakpoints.down(1100)]: {
      margin: "25px auto 19px",
    },
  },
  emailInput: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "7rem",
    maxWidth: 400,
    [theme.breakpoints.down(1210)]: {
      paddingBottom: "9rem",
    },
    [theme.breakpoints.down(1100)]: {
      padding: "0 0px 30px",
    },
    "& > div": {
      width: 230,
      [theme.breakpoints.down(1300)]: {
        width: 210,
      },
      [theme.breakpoints.down(1100)]: {
        width: 220,
      },
      [theme.breakpoints.down(500)]: {
        width: 195,
      },
    },

    "& button": {
      width: 150,
      [theme.breakpoints.down(1300)]: {
        width: 130,
      },
    },
  },
  register: {
    position: "relative",
  },
  mensaje: {
    position: "absolute",
    fontFamily: "Montserrat",
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1.46,
    color: "#625c20",
    width: 350,
    bottom: 45,
    [theme.breakpoints.down(1300)]: {
      fontSize: 16,
      width: 300,
      bottom: 60,
    },
    [theme.breakpoints.down(1210)]: {
      bottom: 90,
    },
    [theme.breakpoints.down(1100)]: {
      bottom: -25,
      width: 350,
    },
  },
  containerRegister: {
    [theme.breakpoints.down(1300)]: {
      width: 360,
    },
    [theme.breakpoints.down(500)]: {
      width: 330,
    },
  },
  part1: {
    color: theme.palette.primary.main,
  },
  part2: {
    color: "#625c20",
  },
}));
