import React from "react";
import { useAuth } from "@hooks";
import { Header } from "@components/Structural/OnBoarding/Header";
import useStyles from "./LayoutServices.style";
import { useMediaQuery } from "@material-ui/core";
interface LayoutProps {
  children: React.ReactNode;
  className?: string;
  simpleHeader?: boolean;
  impactRokin?: boolean;
  ourSolutions?: boolean;
  aboutUs?: boolean;
  helpCenter?: boolean;
  activeInvest?: boolean;
  activeBenefit?: boolean;
  activeFinance?: boolean;
  activeRokipedia?: boolean;
  state?: JSX.Element;
  subState?: string;
  onBackClick?: () => void;
  title: string;
  thirdStep?: string;
  hiddenHeader?: boolean;
  activeServices?: boolean;
}
const LayoutServices: React.FC<LayoutProps> = ({
  className,
  children,
  simpleHeader,
  activeInvest,
  activeBenefit,
  activeFinance,
  activeRokipedia,
  state,
  title,
  onBackClick,
  hiddenHeader,
  activeServices,
}) => {
  const { isLoggedIn } = useAuth();
  const isMobile = useMediaQuery("(max-width: 1280px)");

  const classes = useStyles({ state: !!state });
  return (
    <div className={`${className || ""} ${classes.container}`}>
      {hiddenHeader && (
        <Header
          simpleHeader={simpleHeader}
          activeRokipedia={activeRokipedia}
          activeInvest={activeInvest}
          activeBenefit={activeBenefit}
          activeFinance={activeFinance}
          activeRokinpedia={activeRokipedia}
          activeServices={activeServices}
        />
      )}
      <section className={classes.containerSafe}>
        {/*  <div className={classes.safe}>
          <img src={BACK_ARROW_ICON} onClick={onBackClick} />
          <span className={classes.title}>{title}</span>
          <div className={classes.RoutePath}>{state && state}</div>
          
        </div> */}
      </section>
      <div className={classes.children}>{children}</div>
    </div>
  );
};
export default LayoutServices;
