import React, { useCallback, useEffect, useState } from "react";
import MaskedInput from "react-text-mask";
import { debounce } from "lodash";
import { CommonInputProps } from "..";
import { ChangeEvent } from "@interfaces";
import { SizedTextField } from "@components";

const validate = (value: string): boolean => {
  return value?.split("\u2000").length - 1 === 0 ?? false;
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends CommonInputProps {}

export const PhoneInput: React.FC<Props> = ({
  className,
  name,
  label,
  value,
  onChange,
  error,
  ...props
}) => {
  const [_value, setValue] = useState("");
  const [innerError, setInnerError] = useState(false);

  const checkError = useCallback(
    (value: string) => {
      if (value.length && !validate(value)) {
        setInnerError(true);
      }
    },
    [setInnerError]
  );
  const debouncedCheckError = useCallback(debounce(checkError, 500), []);

  const handleChange = useCallback(
    (e) => {
      const val = e.target.value;
      setValue(val);
      setInnerError(false);
      debouncedCheckError(val);
      if (!val || validate(val)) {
        onChange({
          target: { name: name || "phone", value: val },
        } as ChangeEvent);
      }
    },
    [name, setValue, setInnerError, onChange, debouncedCheckError]
  );

  useEffect(() => setValue(value as string), [value]);

  return (
    <SizedTextField
      className={`${className || ""} altercap-phone-input altercap-input`}
      name={name || "phone"}
      label={label ?? "Teléfono"}
      {...props}
      value={_value}
      onChange={handleChange}
      InputProps={{
        inputComponent: PhoneMaskedInput as any,
      }}
      error={error || innerError}
    />
  );
};

const MASK = [
  "+",
  "5",
  "6",
  " ",
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
interface PhoneMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}
function PhoneMaskedInput(props: PhoneMaskCustomProps) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={MASK}
      placeholderChar={"\u2000"}
    />
  );
}
