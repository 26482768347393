import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from "react";
import { Checkbox } from "@material-ui/core";
import { useStyles } from "./RL2.style";
import { ButtonOutlined, ButtonPrimary } from "@components/Reusables";
import { checkIcon, warningIcon } from "@images";
import { LoadingContext } from "@context";
import { useForm } from "@hooks";
import {
  completeMissionMutation,
  sendContactFormRefinance,
  updateUser,
  useCurrentUser,
} from "@apollo";
import { useApolloClient } from "@apollo/client";
import { InformationDebt } from "@components/RefinanceCredit/Step1/InformationDebt/InformationDebt";
import moment from "moment-timezone";
import { parseNumber } from "@utils";
import { navigate } from "gatsby";
import { Route } from "@interfaces";

type Props = {
  setPage: Dispatch<SetStateAction<number>>;
};
const RefinanceLoan2: React.FC<Props> = ({ setPage }) => {
  const classes = useStyles();
  const [form] = useForm({});
  const { user: currentUser } = useCurrentUser();
  const [isCheck, setCheck] = useState(false);

  const client = useApolloClient();
  const { showLoader, hideLoader } = useContext(LoadingContext);

  const onSend = useCallback(() => {
    showLoader("contact modal");
    const name = currentUser?.name || "";
    const email = currentUser?.email || "";
    const salaryFile = form.liquidationFile || "";
    const salaryAmount = form.liquidationAmount?.toString() || "";
    const creditInformation = form.dataRefinanceCredit || "";
    const debtReport = form.debtReport || "";
    sendContactFormRefinance(
      {
        name,
        email,
        salaryFile,
        salaryAmount,
        creditInformation,
        debtReport,
      },
      client
    ).then(() => {
      hideLoader("contact modal");
    });
  }, [currentUser?.name, form.debtReport]);

  const saveData = () => {
    onSend();
    updateUser(form, true, client);
    completeMissionMutation(client, 29, {
      creditInformation: form.dataRefinanceCredit,
      debtReport: form.debtReport,
      salarySettlementAmount: form.liquidationAmount,
      salarySettlementFile: form.liquidationFile,
    });
    form.dataRefinanceCredit,
      form.debtReport,
      form.liquidationAmount,
      form.liquidationFile;
    navigate(Route.finance);
  };

  let sum = 0;
  form.dataRefinanceCredit?.map(
    (data: { share: number }) => (sum += data.share)
  );
  const dateNow = new Date().getTime();

  const SpendingPercentage = (sum / Number(form.liquidationAmount)) * 100;

  // form.dataRefinanceCredit[0].therm;


  const secondary = SpendingPercentage >= 15;
  console.log(form.dataRefinanceCredit, "_--");
  return (
    <div className={classes.container}>
      <div className={classes.topSection}>
        <div className="item">
          <div className="title">Categoría del crédito</div>
          {form.dataRefinanceCredit?.map(
            // eslint-disable-next-line @typescript-eslint/ban-types
            (data: { category: {} | null | undefined }, i: any) => (
              <div className="value" key={`${data.category}${i}`}>
                {data.category}
              </div>
            )
          )}
        </div>
        <div className="item">
          <div className="title">monto total</div>
          <div className="value">
            {form.dataRefinanceCredit?.map(
              // eslint-disable-next-line @typescript-eslint/ban-types
              (data: { amount: {} | null | undefined }, i: any) => (
                <div className="value" key={`${data.amount}${i}`}>
                  ${parseNumber(Number(data.amount))}
                </div>
              )
            )}
          </div>
        </div>
        <div className="item">
          <div className="title">cuotas por pagar</div>
          {form.dataRefinanceCredit?.map(
            // eslint-disable-next-line @typescript-eslint/ban-types
            (
              data: {
                date: string | number | Date | null | undefined;
                therm: any | number | string;
              },
              i: any
            ) => (
              <div className="value" key={`${data.date}${i}`}>
                {Number(data.therm) -
                  Number(moment(dateNow).diff(data.date, "months"))}
              </div>
            )
          )}
        </div>
        <div className="item">
          <div className="title">tu interés</div>
          {form.dataRefinanceCredit?.map(
            // eslint-disable-next-line @typescript-eslint/ban-types
            (data: { therm: {} | null | undefined }, i: any) => (
              <div className="value" key={`${data.therm}${i}`}>
                {data.therm}%
              </div>
            )
          )}
        </div>
        <div className="item">
          <div className="title">falta por pagar</div>
          <div className="value">
            {form.dataRefinanceCredit?.map(
              // eslint-disable-next-line @typescript-eslint/ban-types
              (
                data: {
                  share: {} | null | undefined;
                  therm: {} | null | undefined;
                  date: Date | {} | null | undefined;
                },
                i: any
              ) => (
                <div className="value" key={`${data.intereseRate}${i}`}>
                  $
                  {parseNumber(
                    Number(data.share) *
                      (Number(data.therm) -
                        Number(moment(dateNow).diff(data.date, "months")))
                  )}
                </div>
              )
            )}
          </div>
        </div>
      </div>
      <div className={classes.middleSection}>
        <div className={classes.firstColumn}>
          <div className="firstWhite">
            {SpendingPercentage < 15 ? (
              <span className="text">
                Estás destinando un{" "}
                <strong> {SpendingPercentage.toFixed(2)}% </strong> de tu sueldo
                mensual a pagar deudas. Podrían existir{" "}
                <strong> mejores opciones </strong>
                para tu(s) crédito(s). Evaluaremos tu caso y
                <strong>
                  {" "}
                  te contactaremos al siguiente correo: {currentUser?.email}
                </strong>
              </span>
            ) : (
              <span className="text">
                Estás destinando un{" "}
                <strong> {SpendingPercentage.toFixed(2)}% </strong> de tu sueldo
                mensual a pagar deudas. Podrían existir{" "}
                <strong> mejores opciones </strong>
                para tu(s) crédito(s). Evaluaremos tu caso y
                <strong>
                  {" "}
                  te contactaremos al siguiente correo: {currentUser?.email}
                </strong>
              </span>
            )}
            {/* <div className="mailTag">
              <img src={mailIcon} alt="mail" />
              <span className="mail">carlos@rokinapp.com</span>
            </div> */}
            <div className="checkContainer">
              <Checkbox
                name="accepted"
                checked={isCheck}
                onChange={() => setCheck(!isCheck)}
              />
              <span className="check">
                Autorizo el uso de los datos proporcionados, excepto los de
                contacto, para ser entregados a instituciones financieras con el
                fin de una evaluación respecto a mis condiciones crediticias
                actuales u otros productos financieros relacionados."
              </span>
            </div>
          </div>
          <div className="buttonsSection">
            <ButtonPrimary
              text={"Guardar y volver a Mis Finanzas"}
              disabled={!isCheck}
              onClick={() => {
                saveData();
              }}
            />
            <ButtonOutlined
              text={"Ir atras y editar"}
              onClick={() => {
                setPage(1);
              }}
            />
          </div>
        </div>
        <div className={classes.secondColumn}>
          {secondary ? (
            <div className="purpleContainer">
              <div className="purpleBox box">
                <img src={checkIcon} alt="" />
                <span className="text">
                  Es posible que hayan mejores tasas de interés
                </span>
              </div>
              <div className="purpleBox box">
                <img src={warningIcon} alt="" />
                <span className="text">Ten cuidado con adquirir más deuda</span>
              </div>
            </div>
          ) : (
            <div className="purpleBox">
              <img src={checkIcon} alt="" />
              <span className="text">
                Es posible que hayan mejores tasas de interés
              </span>
            </div>
          )}

          <InformationDebt />
        </div>
      </div>
    </div>
  );
};

export default RefinanceLoan2;
